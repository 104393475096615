import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import ImageIcon from '@mui/icons-material/Image';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';

import EnhancedSwitch from 'components/EnhancedSwitch';
import EnhancedButton from 'components/EnhancedButton';
import EnhancedTable from 'components/EnhancedTable';
import ActionDialog from 'components/ActionDialog';
import ProgressStep from 'components/ProgressStep';
import ButtonContainer from 'components/ButtonContainer';
import { decodeHistoryLog } from 'modules/historyLog';
import { firestoreListener, callFunction } from 'modules/firebase';
import LeaveType from 'enum/LeaveType';
import LeaveCertificateDialog from './LeaveCertificateDialog';

const _LeaveAllType = LeaveType.concat().filter(type => type.type === 'all');
const _LeaveType = LeaveType.concat();

function LeavesView({ currentUser, leave, isHistory, isAll, type }) {
  const { formatMessage } = useIntl()
  const userMapping = useSelector(state => state.users.data);
  const [displayAll, setDisplayAll] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [leaveBalance, setLeaveBalance] = useState({})
  const [pendingLeaves, setPendingLeaves] = useState([])
  const [dialogData, setDialogData] = useState(null);
  const leaveHistory = (leave.history || []).map(h => ({ ...h }))
  const currentStep = leaveHistory.length > 0 ? leaveHistory[leaveHistory.length - 1].step : 0
  const [openDialog, setOpenDialog] = useState(false);
  const queryKey = type === 'review' ? leave.createdBy : currentUser.key
  const currentGender =  type === 'review' ? userMapping[leave.createdBy]?.gender : currentUser.gender

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'leaveBalances',
      doc: leave.createdBy,
      onData: (data) => {
        setLeaveBalance(data)
      }
    })

    return () => unsubscribe()
  }, [queryKey]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'leaves',
      where: [['status', '==', 'pending'], ['createdBy', '==', queryKey]],
      onData: (data) => {
        setPendingLeaves(data)
      }
    })

    return () => unsubscribe()
  }, [queryKey]);

  const headerCells = [
    { text: 'type' },
    { text: 'earned' },
    { text: 'used' },
    { text: 'signing' },
    { text: 'voiding' },
    { text: 'balance' },
  ].map(c => { c.text = formatMessage({ id: `leaves.leaveForm.${c.text}` }); return c })

  const rowCells = [
    { field: 'name', textColor: true },
    { field: 'earned', textColor: true },
    { field: 'used', textColor: true },
    { field: 'signing', textColor: true },
    { field: 'voiding', textColor: true },
    { field: 'balance', textColor: true },
  ]

  function decodeModifyLog(log) {
    const fmMapping = {
      modify: 'step.action.modify',
      update: 'step.action.update',
      add: 'step.action.add',
      remove: 'step.action.remove',
      startTime: 'leaves.table.start',
      startDate: 'leaves.leaveForm.startDateTime',
      endTime: 'leaves.table.end',
      endDate: 'leaves.leaveForm.endDateTime',
      type: 'leaves.leaveForm.type',
      reason: 'leaves.leaveForm.reason',
      img: 'leaves.leaveForm.leavecertificate',
      days: 'leaves.leaveForm.leaveDays',
      hours: 'leaves.leaveForm.leaveHours',
      substitute: 'leaves.leaveForm.substitute',
    }
    return decodeHistoryLog(log, [
      { key: 'f', replacer: s => formatMessage({ id: fmMapping[s] }) },
      { key: 'i', replacer: s => userMapping[s].displayName },
      { key: 'x', replacer: s => formatMessage({ id: `leaveType.${s}` }) },
    ])
  }

  async function handleExecute(data) {
    const { action, text } = data
    setDialogData(null)
    if (['approve'].includes(action)) {
      setLoadingApprove(true)
    } else {
      setLoadingReject(true)
    }

    try {
      await callFunction('reviewLeaves', {
        id: leave.id,
        action,
        note: text
      })

      if (['approve'].includes(action)) {
        if (currentStep < leave.steps.length - 1) {
          setLoadingApprove(false)
        }
      } else {
        setLoadingReject(false)
      }
    } catch (ex) {
      setLoadingApprove(false)
      setLoadingReject(false)
      console.log(ex)
    }
  }

  function handleClose() {
    setDialogData(null)
    setOpenDialog(false)
  }

  function formatData(value) {
    const { leave } = value
    const newData = { ...value }
    if (newData) {
      let used = newData.used
      let balance = newData.balance
      if (newData.type === leave.type) {
        newData.textColor = balance >= 0 ? '#1976d2' : '#fe2851'

        if (newData.status === 'done') {
          used = newData.used + ((leave.days * 8) + leave.hours)
          balance = newData.balance - ((leave.days * 8) + leave.hours)
        }

        if ((leave.days || leave.hours) > 0) {
          newData.textColor = balance >= 0 ? '#1976d2' : '#fe2851'
        }
      }

      if (newData.type === leave.type &&
        ['business_leave',
          'bereavement_leave',
          'publicinjury_leave',
          'epidemicPreventionCare_leave',
          'vaccination_leave',
          'quarantine_leave'
        ].includes(newData.type)) {
        balance = 0;
        if ((leave.days || leave.hours) > 0) {
          newData.textColor = '#1976d2'
        }
      }

      let newEarned = newData.earned ? { days: (newData.earned - (newData.earned % 8)) / 8, hours: newData.earned % 8 } : { days: 0, hours: 0 };
      let newUsed = used ? { days: (used - (used % 8)) / 8, hours: used % 8 } : { days: 0, hours: 0 };
      let newBalance = newData.earned - used;
      let newSigning = {
        days: 0,
        hours: 0
      }
      let newVoiding = {
        days: 0,
        hours: 0
      }

      for (const s of pendingLeaves) {
        if (s.type === newData.type) {
          if (s.void) {
            newVoiding.days += s.days
            newVoiding.hours += s.hours
          } else {
            newSigning.days += s.days
            newSigning.hours += s.hours
          }
        }
      }

      const balanceCount = (balance - ((newSigning.days * 8) + newSigning.hours))
      if (['business_leave',
        'bereavement_leave',
        'publicinjury_leave',
        'epidemicPreventionCare_leave',
        'vaccination_leave',
        'quarantine_leave'
      ].includes(newData.type)) {
        newBalance = { days: 0, hours: 0 };
      } else {
        newBalance = { days: ((balanceCount - (balanceCount % 8)) / 8), hours: (balanceCount % 8) };
      }

      newData.earned = `${newEarned.days}日${newEarned.hours}小時`
      newData.used = `${newUsed.days}日${newUsed.hours}小時`
      newData.balance = `${newBalance.days}日${newBalance.hours}小時`
      newData.signing = `${newSigning.days}日${newSigning.hours}小時`
      newData.voiding = `${newVoiding.days}日${newVoiding.hours}小時`
    }

    return newData
  }

  const ls = leave.status !== 'void' ? leave.steps.slice(currentStep, leave.steps.length).map(s => ({ ...s })) : []

  if (leave.status !== 'void' && currentStep === 0) {
    ls[0].name = '編輯 / 作廢'
  }
  const steps = [...leaveHistory].concat(ls)

  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    } else if (['approve'].includes(step.action)) {
      step.labelProps = { color: 'green', success: true }
    }
  }

  return (
    <div style={{ width: '100%', }}>
      {openDialog && <LeaveCertificateDialog
        url={leave.img}
        handleClose={handleClose}
      />}
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={dialogData.action === 'reject' ? formatMessage({ id: 'leaves.detail.rejectReason' }) : formatMessage({ id: 'leaves.detail.note' })}
        action={dialogData.action}
      />}
      <Box p={0}>
        <ProgressStep
          activeStep={leave.history.length}
          steps={steps}
          title={formatMessage({ id: 'leaves.list.step' })}
        />
        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              type="text"
              label={formatMessage({ id: 'leaves.leaveForm.substitute' })}
              value={leave.substitute ? userMapping[leave.substitute]?.displayName : ''}
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          <Grid item xs={12} sm={6} md={9}>
            <TextField
              type="text"
              label={formatMessage({ id: 'leaves.leaveForm.reason' })}
              value={leave.reason}
              multiline
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          {leave.img && <Grid item xs={12} sm={6} md={3}>
            <Button
              onClick={() => setOpenDialog(true)}
            >
              <ImageIcon />
              {formatMessage({ id: 'leaves.leaveForm.leavecertificate' })}
            </Button>
          </Grid>}
          <Grid item xs={12} sm={12} md={12}>
            <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 500 }} component="div">
                <FormattedMessage id="leaves.leaveForm.currentBalances" />:
              </Typography>

              <Typography component="div" style={{ height: '100%' }}>
                <EnhancedSwitch checked={displayAll} onChange={e => setDisplayAll(!displayAll)} rightLabel="顯示所有假別" />
              </Typography>

            </div>
            <EnhancedTable
              size="small"
              headerCells={headerCells}
              rowCells={rowCells}
              tableData={
                (currentGender === 'male' ? _LeaveAllType : _LeaveType)
                  .filter(type => displayAll || ['annual_leave', 'sick_leave', 'personal_leave'].includes(type.value) || type.value === leave.leaveType)
                  .map(type => ({ id: type.value, name: type.name, ...formatData({ leave: leave, type: type.value, ...leaveBalance[type.value] }) }))
              }
            />

          </Grid>
          {type === 'review' && !isHistory && !isAll && <Grid item xs={12} sm={12} md={12}>
            <ButtonContainer>
              <EnhancedButton
                color="error"
                onClick={() => setDialogData({ action: 'reject', title: '' })}
                disabled={loadingApprove || loadingReject}
                progress={loadingReject}
                label={formatMessage({ id: 'button.reject' })}
              />
              <EnhancedButton
                color="success"
                onClick={() => setDialogData({ action: 'approve', title: '' })}
                disabled={loadingApprove || loadingReject}
                progress={loadingApprove}
                label={formatMessage({ id: 'button.approve' })}
              />
            </ButtonContainer>
          </Grid>}
        </Grid>
      </Box>
    </div>
  )
}

LeavesView.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  isHistory: PropTypes.bool,
  isAll: PropTypes.bool,
  leave: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default LeavesView;
