import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { minutesToWidth, minutesToHeight } from 'modules/time';
import { objectToArray } from 'modules/data';
import { moibleMedia } from 'constants';

const useStyles = createUseStyles({
  block: {
    display: 'flex',
    flexDirection: 'row',
    [moibleMedia]: {
      flexDirection: 'column',
    }
  },

  treatment: {
    fontSize: '15px',
    color: '#ffffff',
    height: '24px',
    padding: '0 5px',
    backgroundColor: '#d8d8d8',
    fontFamily: 'PingFang TC',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: '24px',
    textAlign: 'left',
    '&:first-child': {
      borderRadius: '4px 0 0 4px'
    },
    '&:last-child': {
      borderRadius: '0 4px 4px 0',
    },
    '&:only-child': {
      borderRadius: '4px'
    },
    [moibleMedia]: {
      width: '134px',
      borderRadius: '0!important'
    }
  }
});

function AppointmentItemBlock({ appointment }) {
  const classes = useStyles();
  const treatments = objectToArray(appointment.treatments, 'order').filter(p => !p.parent)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  return (
    <div className={classes.block}>
      {treatments.filter(t => !t.hidden).map((treatment, key) => {
        const color = treatment.type === 'post-surgery' ? '#989ba7' : '#ffffff';
        let backgroundColor = treatment.color;

        if (appointment.status === 'cancelled' || appointment.status === 'cancelanotherappointment') {
          backgroundColor = treatment.type !== 'pre-surgery' &&
              treatment.type !== 'post-surgery'
            ? '#9EA6B7' : '#e4e7eb'
        }

        return <div
          key={key}
          className={classes.treatment}
          style={{
            width: isMobile ? '134px' : minutesToWidth(treatment?.duration ?? 0),
            height: isMobile ? minutesToHeight(treatment?.duration ?? 0) : '24px',
            background: backgroundColor,
            color: color
          }}
        >
          {treatment.nickname || treatment.name}
        </div>
      })}
    </div>
  );
}

AppointmentItemBlock.propTypes = {
  appointment: PropTypes.object.isRequired,
};

export default AppointmentItemBlock;
