const i18n = {
  'refundService': {
    'add': '新增退費單',
    'table': {
      'title': '退費單列表',
      'header': {
        'sn': '退費單號',
        'date': '日期',
        'createdBy': '建立人',
        'customer': '客戶',
        'refundType': '產品類別',
        'paymentType': '退費方式',
        'refundPrice': '退費金額',
        'note': '備註'
      },
      'detail': {
        'source': '公司',
        'customer': '客戶',
        'note': '備註',
        'paymentType': '退費方式',
        'refundPrice': '退費金額',
        'refundType': {
          'root': '產品類型',
          'service': '服務',
          'merchandise': '商品'
        }
      }
    },
    'filter': {
      'sn': '退費單號',
      'date': '日期',
      'createdBy': '建立人',
      'customer': '客戶'
    },
    'product': {
      'name': '產品名稱',
      'availableAmount': '剩餘數量',
      'amount': '數量',
      'price': '單價',
      'note': '備註'
    }
  },
  'editRefundService': {
    'title': '產品列表',
    'addProduct': '新增產品',
    'removeProduct': '移除產品'
  }
}
export default i18n
