import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import TabContainer from 'containers/TabContainer/TabContainer';
import ReviewLeavesList from 'pages/Leaves/ReviewLeavesList';
import ReviewOvertimesList from 'pages/Overtimes/ReviewOvertimesList';
import ReviewPunchClockReviseList from 'pages/PunchClockRevise/ReviewPunchClockReviseList';
import ContextStore from 'modules/context';
import { useModuleMapping } from 'hooks/modules';
import { firestoreListener } from 'modules/firebase';

const Root = styled('div')(() => ({
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

function LopReview() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const [leaves, setLeaves] = useState([])
  const [overtimes, setOvertimes] = useState([])
  const [punchClockRevise, setPunchClockRevise] = useState([])
  const moduleMapping = useModuleMapping()

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'leaves',
      where: [['status', '==', 'pending']],
      onData: (data) => {
        setLeaves(data)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'overtimes',
      where: [['status', '==', 'pending']],
      onData: (data) => {
        setOvertimes(data)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'punchClockRevise',
      where: [['status', '==', 'pending']],
      onData: (data) => {
        setPunchClockRevise(data)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.leaveOvertimeWork.review' })
    }])
    return () => {
    };
  }, []);

  const leaveNotifications = leaves.filter(leave => {
    const currentStep = leave.history.length > 0 ? leave.history[leave.history.length - 1].step : 0
    return leave.steps[currentStep].users.includes(currentUser.key)
  })

  const overtimeNotifications = overtimes.filter(overtime => {
    const currentStep = overtime.history.length > 0 ? overtime.history[overtime.history.length - 1].step : 0
    return overtime.steps[currentStep].users.includes(currentUser.key)
  })

  const punchClockReviseNotifications = punchClockRevise.filter(revise => {
    const currentStep = revise.history.length > 0 ? revise.history[revise.history.length - 1].step : 0
    return revise.steps[currentStep].users.includes(currentUser.key)
  })

  const tabs = [];
  if (userRights.hasUserRight('leave-review') && moduleMapping.leaveOvertime) { // 修正權限檢查
    tabs.push({
      label: <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        color="error"
        badgeContent={leaveNotifications.length}
      >
        {formatMessage({ id: 'tab.secondaryNav.reviewLeave' })}
      </Badge>,
      component: <ReviewLeavesList />
    })
  }
  if (userRights.hasUserRight('overtime-review') && moduleMapping.leaveOvertime) { // 修正權限檢查
    tabs.push({
      label: <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        color="error"
        badgeContent={overtimeNotifications.length}
      >
        {formatMessage({ id: 'tab.secondaryNav.reviewOvertime' })}
      </Badge>,
      component: <ReviewOvertimesList />
    })
  }
  if (userRights.hasUserRight('punchClockRevise-review') && moduleMapping.punchClock) {
    tabs.push({
      label: <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        color="error"
        badgeContent={punchClockReviseNotifications.length}
      >
        {formatMessage({ id: 'tab.secondaryNav.reviewPunchClockRevise' })}
      </Badge>,
      component: <ReviewPunchClockReviseList />
    })
  }

  return (
    <Box p={3}>
      <Root>
        <TabContainer defaultSelect={0} tabs={tabs} />
      </Root>
    </Box>
  );
}

export default LopReview;
