import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import MaskInput from 'components/MaskInput';
import DatePickerField from 'components/DatePickerField';
import SelectCustomerDialog from 'components/SelectCustomerDialog';
import SelectUserDialog from 'components/SelectUserDialog';
import ButtonProgress from 'components/ButtonProgress';
import { firestoreListener, callFunction } from 'modules/firebase';

const newMapping = {
  gender: ['male', 'female'],
  countryCode: ['Taiwan', 'HK', 'China', 'USA', 'Japan', 'Singapore'],
  nationality: ['taiwan', 'hongkong', 'macao', 'china', 'other'],
  maritalStatus: ['married', 'single']
}

function EditInformationDialog({ handleClose, data, customerId }) {
  const { formatMessage } = useIntl()
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customerMapping, setCustomerMapping] = useState({})
  const users = useSelector(state => state.users.ordered).filter(u => u.active && !u.developer)
  const [openDialog, setOpenDialog] = useState(null)
  const defultData = {
    ...data,
    name: data.name || '',
  }
  const config = useSelector(state => state.config.data)
  const salesRepsDepartments = config.salesReps?.departments || []
  const [customerData, setCustomerData] = useState(defultData)
  const salesReps = users.filter(u => salesRepsDepartments.includes(u.department))
  const customerType = Object.keys(config.customerType || {}).filter(i => config.customerType[i].active).map(i => config.customerType[i])
  const customerExtTab = useSelector(state => state.customerExtTab.data)

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      array: true,
      onData: ({ mapping, data }) => {
        setCustomers(data)
        setCustomerMapping(mapping)
      }
    })

    return () => unsubscribe()
  }, []);

  const _fields = [
    { name: 'name', type: 'text', md: 6, sm: 12, required: true, order: 0 },
    { name: 'gender', type: 'text', md: 6, sm: 12, required: true, roots: true, select: true, order: 2 },
    { name: 'identityCardNumber', type: 'text', md: 6, sm: 12, order: 3 },
    // { name: 'countryCode', type: 'text', md: 6, sm: 12, roots: true, select: true, order: 3 },
    { name: 'phone', type: 'phone', md: 6, sm: 12, order: 4 },
    { name: 'birthDate', type: 'date', md: 6, sm: 12, order: 5 },
    { name: 'salesRep', type: 'salesRep', select: true, md: 6, sm: 12, order: 11 },
    { name: 'type', type: 'text', md: 6, sm: 12, roots: true, select: true, order: 7 },
    { name: 'nationality', type: 'text', md: 6, sm: 12, roots: true, select: true, order: 10 },
    { name: 'maritalStatus', type: 'text', md: 6, sm: 12, roots: true, select: true, order: 12 },
    { name: 'occupation', type: 'text', md: 6, sm: 12, order: 13 },
    { name: 'address', type: 'text', md: 6, sm: 12, order: 14 },
    { name: 'comment', multiline: true, type: 'text', md: 12, sm: 12, order: 16 },

    { name: 'blackList', type: 'check', md: 3, sm: 6, order: 17 },
    { name: 'cantWait', type: 'check', md: 3, sm: 6, order: 18 },
    { name: 'noPhone', type: 'check', md: 3, sm: 6, order: 19 },
    { name: 'noSms', type: 'check', md: 3, sm: 6, order: 20 },
    { name: 'vip', type: 'check', md: 3, sm: 6, order: 21 }
  ]

  for (const c of customerType) {
    if (customerData.type === c.id) {
      if (c.requiredItems !== 'none') {
        const data = Object.keys(customerExtTab[c.requiredItems] || {}).map(i => customerExtTab[c.requiredItems][i])
        for (const d of data) {
          _fields.push({ ...d, name: d.id, type: d.type, label: d.name, md: 6, sm: 6, required: true, order: 8 })
        }
      }

      if (c.items !== 'none') {
        const data = Object.keys(customerExtTab[c.items] || {}).map(i => customerExtTab[c.items][i])
        for (const d of data) {
          _fields.push({ ...d, name: d.id, type: d.type, label: d.name, md: 6, sm: 6, order: 9 })
        }
      }
    }
  }

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })
  async function saveCustomer() {
    setLoadingApprove(true)

    let err = {}
    let newData = customerData

    for (let field of fields) {
      if (field.required && customerData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }

    if (Object.keys(err).length > 0) {
      newData = { ...customerData, ...err }
    }
    if (Object.keys(err).length > 0) {
      setCustomerData(newData)
      return setLoadingApprove(false)
    }

    const updateData = {
      ...customerData,
      birthDate: customerData.birthDate !== '' ? dayjs(customerData.birthDate).format('YYYY-MM-DD') : '',
    }

    for (const field of Object.keys(updateData)) {
      if (updateData[field] === '') {
        delete updateData[field]
      }
    }

    try {
      await callFunction('saveCustomers', { id: customerId, ...updateData })
      setLoadingApprove(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    if (field.name === 'phone' && value !== '(   )    -    #     ' && value !== '') {
      let p = value.split('#')[0].trim()
      if (p.length < 12) return formatMessage({ id: 'form.mobile.formatError' })
    }

    return ''
  }

  function updateData(field, value) {
    let newValue = value
    let err = validateField(field, value)


    if (field.name === 'birthDate') {
      if (!value) {
        newValue = ''
      }
    }

    let newData = { ...customerData, [field.name]: newValue, [`${field.name}_err`]: err }

    setCustomerData(newData)
  }

  function handleCheckChange(event, field) {
    updateData({ name: `${field.name}` }, event.target.checked);
  };

  function createField(field) {
    let newValue = customerData[field.name] ? customerData[field.name] : ''
    let labelText = ''
    const selectFields = field.name === 'type' ? customerType : newMapping[`${field.name}`]

    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }

    if (field.name === 'identityCardNumber') {
      newValue = newValue ? newValue.toUpperCase() : ''
    }

    if (field.roots) {
      labelText = formatMessage({ id: `customer.dialog.${field.name}.roots` })
    } else if (field.label) {
      labelText = field.label
    } else {
      labelText = formatMessage({ id: `customer.dialog.${field.name}` })
    }

    if (field.type === '-') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    } else if (field.type === 'date') {
      labelText = formatMessage({ id: `customer.dialog.${field.name}` })

      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <DatePickerField
          fullWidth
          label={labelText}
          closeToolbar={false}
          value={newValue !== '' ? dayjs(newValue) : null}
          onChange={date => updateData({ name: `${field.name}` }, date)}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    } else if (field.type === 'text') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          required={field.required}
          label={labelText}
          value={newValue}
          fullWidth
          select={field.select ? field.select : null}
          size="small"
          variant="outlined"
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
          error={customerData[`${field.name}_err`] ? true : false}
          helperText={customerData[`${field.name}_err`]}
        >
          {
            field.select && selectFields.map((select, idx) => {
              if (field.name === 'type') {
                return <MenuItem key={`${select.id}${idx}`} value={select.id}>{select.name}</MenuItem>
              } else {
                return <MenuItem key={`${select}${idx}`} value={select}>{formatMessage({ id: `customer.dialog.${field.name}.${select}` })}</MenuItem>
              }
            })
          }
        </TextField>
      </Grid>
    } else if (field.type === 'salesRep') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          required={field.required}
          label={labelText}
          value={newValue}
          fullWidth
          select={field.select}
          size="small"
          variant="outlined"
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
        >
          {
            salesReps.map((user, idx) => {
              return <MenuItem key={`${user.id}${idx}`} value={user.id}>{user.displayName}</MenuItem>
            })
          }
        </TextField>
      </Grid>
    } else if (field.type === 'check') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <FormControlLabel
          control={<Checkbox
            checked={customerData[field.name]}
            onChange={e => { handleCheckChange(e, field) }}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />}
          label={labelText}
        />
      </Grid>
    } else if (field.type === 'dialog') {
      if (field.dialog === 'customers') {
        newValue = customerMapping[newValue]?.name ?? ''
      }
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          type="text"
          required={field.required}
          label={labelText}
          fullWidth
          size="small"
          variant="outlined"
          value={newValue}
          onClick={() => setOpenDialog({ type: field.dialog, field: field })}
          error={customerData[`${field.name}_err`] ? true : false}
          helperText={customerData[`${field.name}_err`]}
        />
      </Grid>
    } else if (field.type === 'select') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          type="text"
          required={field.required}
          label={labelText}
          fullWidth
          size="small"
          variant="outlined"
          value={newValue}
          select
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
          error={customerData[`${field.name}_err`] ? true : false}
          helperText={customerData[`${field.name}_err`]}
        >
          {Object.keys(field.counts).map(i => field.counts[i]).map((count, index) => {
            return <MenuItem key={`${count}${index}`} value={count}>{count}</MenuItem>
          })}
        </TextField>
      </Grid>
    } else if (['time', 'phone', 'moblie'].includes(field.type)) {
      const newMask = field.type === 'time' ? '99:99' : field.type === 'phone' ? '9999-999-999' : '(999)9999-9999#99999'
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <MaskInput
          mask={newMask}
          maskChar=" "
          onChange={e => updateData(field, e.target.value)}
          value={customerData[field.id]}
          label={labelText}
        />
      </Grid>
    }
  }

  const handleDialogSave = (data) => {
    const newValue = openDialog.type === 'customers' ? data.id : data.employeeId
    updateData({ name: openDialog.field.name }, newValue)
  }

  return (
    <>
      {openDialog && openDialog.type === 'customers' && <SelectCustomerDialog
        handleClose={() => setOpenDialog(null)}
        handleSave={handleDialogSave}
        customers={customers.filter(i => i.id !== customerId) || []}
        dialogTitle={formatMessage({ id: 'selectCustomerDialog.title' })}
      />}
      {openDialog && openDialog.type === 'users' && <SelectUserDialog
        headerCells={[{ name: 'displayName', sort: 'displayName' }, { name: 'employeeId', sort: 'employeeId' }]}
        rowCells={[{ field: 'displayName' }, { field: 'employeeId' }]}
        filterItems={[{ name: 'displayName' }, { name: 'employeeId' }]}
        dialogTitle={formatMessage({ id: 'selectEmployeeIdDialog.title' })}
        handleClose={() => setOpenDialog(null)}
        handleSave={handleDialogSave}
        items={users.filter(u => u.employeeId !== customerData.employeeId)}
      />}
      <Dialog
        fullWidth={true}
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'customer.dialog.information.edit' })}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ pt: '8px' }}>
            {fields.map(field => createField(field))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonProgress handleClose={handleClose} handleClick={() => saveCustomer()} loading={loadingApprove} buttonText='button.save' />
        </DialogActions>
      </Dialog>
    </>
  );
}

EditInformationDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  customerId: PropTypes.string.isRequired
};

export default EditInformationDialog;
