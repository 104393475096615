import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import SelectItemDialog from './SelectItemDialog';

function SelectVerdorDialog({ multiSelect, headerCells, rowCells, filterItems, items, handleSave, ...props }) {
  const { formatMessage } = useIntl()

  const _headerCells = headerCells.map(i => { i.text = formatMessage({ id: `vendor.table.header.${i.name}` }); return i })
  const _filterItems = filterItems.map(i => { i.text = formatMessage({ id: `vendor.table.header.${i.name}` }); return i })

  function applyFilter(currentFilter) {
    return currentFilter ? (items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))) : items
  }

  function onSave(selectedItems, params) {
    if (multiSelect) {
      const users = items.filter(u => selectedItems[u.id]).map(u => ({ name: u.name, nickname: u.nickname, code: u.code, id: u.id }))
      handleSave(users, params)
    } else {
      handleSave(selectedItems, params)
    }
  }

  return (
    <SelectItemDialog
      multiSelect={multiSelect}
      headerCells={_headerCells}
      rowCells={rowCells}
      filterItems={_filterItems}
      applyFilter={applyFilter}
      handleSave={onSave}
      {...props}
    />
  );
}

SelectVerdorDialog.propTypes = {
  headerCells: PropTypes.arrayOf(PropTypes.object.isRequired),
  rowCells: PropTypes.arrayOf(PropTypes.object.isRequired),
  filterItems: PropTypes.arrayOf(PropTypes.object.isRequired),
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
  handleSave: PropTypes.func.isRequired,
};

export default SelectVerdorDialog;
