import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';

function FilterMenu({ filterItems, onFilterChanged }) {
  const { formatMessage } = useIntl()
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={formatMessage({ id: 'filter' })}>
        <IconButton
          sx={{ p: '10px' }}
          aria-label="menu"
          onClick={handleMenu}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        {filterItems.map(f =>
          <MenuItem
            key={f.name}
            onClick={() => { handleClose(); onFilterChanged(f); }}
          >
            {f.text}
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

FilterMenu.propTypes = {
  filterItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  onFilterChanged: PropTypes.func.isRequired,
};

export default FilterMenu;
