const i18n = {
  'invoice': {
    'subtotal': '小計',
    'tax': '營業稅',
    'total': '總金額',
    'discount': '現金折扣',
    'shippingFee': '運費',
  },
}
export default i18n
