import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

import DatePickerField from 'components/DatePickerField';
import DelectIconButton from 'components/DelectIconButton';
import EnhancedTable from 'components/EnhancedTable';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import SelectProductDialog from 'components/SelectProductDialog';
import SelectUserDialog from 'components/SelectUserDialog';
import SelectCustomerDialog from 'components/SelectCustomerDialog';
import SelectVerdorDialog from 'components/SelectVerdorDialog';
import ContextStore from 'modules/context';
import { addError, removeError } from 'modules/editor';
import { getRandomHash, unique } from 'modules/uitls';
import { firestoreListener, callFunction } from 'modules/firebase';

function updateProductByPriceType(m, priceType) {
  let unitPrice = m.price
  const pt = m.commonPriceMapping[priceType]
  // 價格有3種格式, 一種是含有 ~ 的區間, 一種是 % 結尾的折扣, 一種是固定數值
  // 如果是區間, unitprice 可編輯
  if (pt.price.includes('~')) {
    const s = pt.price.split('~')
    m.editUnitPrice = true
    if (s[0].endsWith('%')) {
      m.lowestPrice = (unitPrice * Number(pt.price.replace(/%$/, ''))) / 100
    } else {
      m.lowestPrice = Number(s[0])
    }
    if (s[1].endsWith('%')) {
      m.highestPrice = (unitPrice * Number(pt.price.replace(/%$/, ''))) / 100
    } else if (s[1] === '') {
      m.highestPrice = -1
    } else {
      m.highestPrice = Number(s[1])
    }
    if (m.highestPrice !== -1 && m.lowestPrice > m.highestPrice) {
      unitPrice = 0
    } else if (m.highestPrice !== -1 && unitPrice > m.highestPrice) {
      unitPrice = m.highestPrice
    } else if (unitPrice < m.lowestPrice) {
      unitPrice = m.lowestPrice
    }
  } else {
    if (pt.price.endsWith('%')) {
      unitPrice = (unitPrice * Number(pt.price.replace(/%$/, ''))) / 100
    } else {
      unitPrice = Number(pt.price)
    }
    m.editUnitPrice = false
    m.unitPrice = unitPrice
  }
}

function EditSalesOrderPageWrap() {
  const productMapping = useSelector(state => state.products.data)
  const products = useSelector(state => state.products.ordered)

  return products.length ? <EditSalesOrderPage productMapping={productMapping} products={products} /> :
    <div>請先建立產品</div>
}

function EditSalesOrderPage({ productMapping, products }) {
  const { formatMessage } = useIntl()
  const userRights = useSelector(state => state.userRights)
  const prdouctOrder = useRef(0)
  const paymentOrder = useRef(0)
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const { salesOrderId } = useParams()
  const config = useSelector(state => state.config.data)
  const paymentTypes = Object.keys(config.paymentType || {}).filter(i => config.paymentType[i].active).map(i => config.paymentType[i])
  const paymentTypeMapping = paymentTypes.reduce((acc, cur) => { acc[cur.id] = cur; return acc }, {})

  const [openDialog, setOpenDialog] = useState('')
  const [loading, setLoading] = useState(false)
  // const [dateError, setDateError] = useState({})
  const userMapping = useSelector(state => state.users.data)
  const users = useSelector(state => state.users.ordered)
  const vendorMapping = useSelector(state => state.vendors.data)
  const spMapping = useSelector(state => state.internalVendors.data)
  const sps = useSelector(state => state.internalVendors.ordered)

  const [customers, setCustomers] = useState([]);
  const [customerMapping, setCustomerMapping] = useState({});
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const companyData = currentUser.company?.reduce((acc, cur) => { if (userRights['salesOrder-create'].includes(cur)) { acc = true } return acc }, false) ?
    unique(currentUser.company?.concat(userRights['salesOrder-create'])) : userRights['salesOrder-create']
  const [salesOrder, setSalesOrder] = useState({
    id: salesOrderId,
    date: new Date(),
    agent: currentUser.key,
    customer: '',
    products: [],
    payments: [],
    note: '',
    lockProduct: false,
    source: companyData[0],
  });
  const [availableStoredValue, setAvailableStoredValue] = useState({});

  const defaultCustomer = currentCustomer && customerMapping[currentCustomer] ? currentCustomer : ''
  const subtotal = salesOrder.products.reduce((acc, cur) => acc + (cur.unitPrice * cur.amount), 0)
  const collected = salesOrder.payments.reduce((acc, cur) => acc + (cur.collected && !cur.void ? parseInt(cur.collected) : 0), 0)
  const uncollected = subtotal - collected
  const lockProductTable = !!salesOrder.payments.some(i => i.lockPayment && !i.void)

  useEffect(() => {
    const breadcrumbs = [{
      link: '/sales/salesOrder',
      text: formatMessage({ id: 'sideMenu.sales.salesOrders' })
    }]
    if (salesOrderId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'salesOrder.add' }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: 'salesOrder.edit' }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const sp = new URLSearchParams(location.search)
    setCurrentCustomer(sp.get('customer'))
  }, [location.search]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      array: true,
      onData: ({ mapping, data }) => {
        setCustomers(data)
        setCustomerMapping(mapping)
      }
    })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = salesOrder.customer ? firestoreListener({
      collection: 'storedValueCard',
      doc: salesOrder.customer,
      unwrap: true,
      addDocId: false,
      onData: (data) => {
        setAvailableStoredValue(data)
      }
    }) : null
    return () => unsubscribe?.()
  }, [salesOrder.customer]);

  useEffect(() => {
    const unsubscribe = (salesOrderId !== 'new' && userRights.hasUserRight('salesOrder-edit')) ? firestoreListener({
      collection: 'salesOrders',
      doc: salesOrderId,
      onData: (data) => {
        const so = {
          id: data.id,
          date: dayjs(data.date).toDate(),
          agent: data.agent,
          customer: data.customer,
          products: Object.keys(data.products).map(i => ({ ...data.products[i], id: i })).map(i => {
            const p = productMapping[i.productId]
            const commonPrice = [...p.commonPrice]
            const originalPrice = commonPrice.find(i => i.name === '原價')
            if (!originalPrice) {
              commonPrice.unshift({ name: '原價', price: String(p.price) })
            }
            const commonPriceMapping = commonPrice.reduce((acc, cur) => {
              acc[cur.name] = cur
              return acc
            }, {})
            if (!commonPriceMapping[i.priceType]) {
              if (i.priceRule === undefined) { // 對應之前沒有存 priceRule 的營收單
                i.priceType = '原價'
              } else {
                commonPriceMapping[i.priceType] = {
                  name: i.priceType,
                  price: i.priceRule
                }
              }
            }
            const m = {
              ...i,
              table: 'products',
              purchasedAmount: i.amount,
              price: p.price,
              commonPrice,
              commonPriceMapping,
            }
            updateProductByPriceType(m, m.priceType)
            m.unitPrice = i.unitPrice // 第一次從 db 讀出來, 維持原本的 unit price
            return m
          }).sort((a, b) => a.order - b.order),
          payments: Object.keys(data.payments).map(i => ({ ...data.payments[i], id: i })).map(i => ({
            ...i,
            paymentType: i.paymentType === 'storedValue' ? `${i.paymentType}*${i.storedValueCard}` : i.paymentType,
            table: 'payments',
          })).sort((a, b) => a.order - b.order),
          note: data.note,
          source: data.source,
          referrer: data.referrer,
        }
        if (so.payments.length || so.referrer) {
          so.lockProduct = true
        }
        if (so.products?.length) {
          prdouctOrder.current = so.products[so.products.length - 1].order + 1
        }
        if (so.payments?.length) {
          paymentOrder.current = so.payments[so.payments.length - 1].order + 1
        }
        setSalesOrder(so)
      }
    }) : null

    if ((salesOrderId !== 'new' && !userRights.hasUserRight('salesOrder-edit')) ||
      !userRights.hasUserRight('salesOrder-create')) {
      navigate('/');
    }

    return () => unsubscribe?.()
  }, [salesOrderId]);

  const productHeaderCells = [
    { text: 'name' },
    { text: 'serviceProvider' },
    { text: 'priceType' },
    { text: 'unitPrice' },
    { text: 'amount' },
    { text: 'totalPrice' },
    { text: 'note' },
  ].map(c => { c.text = formatMessage({ id: `salesOrder.product.${c.text}` }); return c })

  const productRowCells = [
    { field: 'nickname', tooltip: 'name' },
    {
      field: 'serviceProvider',
      fullWidth: true,
      type: 'popup',
      label: '服務適用單位',
      getEnableStatus: getProductFieldEnableStatus,
      onClick: (product) => setOpenDialog(`verdor*${product.id}`),
    },
    {
      field: 'priceType',
      fullWidth: true,
      type: 'input-menu',
      required: true,
      label: '價格種類',
      getEnableStatus: getProductFieldEnableStatus,
      getMenuItems: getPriceTypes,
      onValueChanged: onCellValueChanged
    },
    {
      field: 'unitPrice',
      type: 'input-number',
      required: true,
      label: '單價',
      getEnableStatus: getProductFieldEnableStatus,
      onValueChanged: onCellValueChanged
    },
    {
      field: 'amount',
      type: 'input-number',
      required: true,
      label: '數量',
      getEnableStatus: getProductFieldEnableStatus,
      onValueChanged: onCellValueChanged
    },
    { field: 'totalPrice', align: 'right' },
    { field: 'note', type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged },
  ]

  const paymentHeaderCells = [
    { text: 'date' },
    { text: 'agent' },
    { text: 'paymentType' },
    { text: 'collected' },
    { text: 'note' },
  ].map(c => { c.text = formatMessage({ id: `salesOrder.payment.${c.text}` }); return c })

  const paymentRowCells = [
    { field: 'date' },
    {
      field: 'agent',
      fullWidth: true,
      type: 'popup',
      label: '收款負責人',
      getEnableStatus: getPaymentFieldEnableStatus,
      onClick: (payment) => setOpenDialog(`paymentAgent*${payment.id}`),
    },
    {
      field: 'paymentType',
      fullWidth: true,
      type: 'input-menu',
      required: true,
      label: '付款方式',
      getEnableStatus: getPaymentFieldEnableStatus,
      getMenuItems: getPaymentTypes,
      onValueChanged: onCellValueChanged
    },
    {
      field: 'collected',
      align: 'right',
      type: 'input-number',
      required: true,
      label: '金額',
      getEnableStatus: getPaymentFieldEnableStatus,
      onValueChanged: onCellValueChanged,
      onKeyDown: onCellKeyDown,
    },
    { field: 'note', type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged },
  ]

  function getProductFieldEnableStatus(data, field) {
    if (lockProductTable && ['priceType', 'unitPrice', 'amount', 'serviceProvider'].includes(field)) {
      return false
    }
    const p = productMapping[data.productId]
    if (field === 'serviceProvider' && ['merchandise', 'storedValue'].includes(p.type)) {
      return false
    }
    if (field === 'unitPrice') {
      return data.editUnitPrice
    }
    return true
  }

  function getPaymentFieldEnableStatus(data, field) {
    if (['agent', 'paymentType', 'collected'].includes(field)) {
      return !data.lockPayment
    } else {
      return true
    }
  }

  function allowCustomerType(rule, customerType) {
    const customerId = defaultCustomer || salesOrder.customer
    if (!customerId) {
      return false
    }
    const customer = customerMapping[customerId]
    if (rule && rule.startsWith('limit')) {
      return customer.type === customerType
    }
    return true
  }

  function getPriceTypes(product) {
    // 過濾掉 未上架, 已下架 和 身份限定的 價格種類
    const today = dayjs().format('YYYY-MM-DD')
    return [...product.commonPrice].filter(i => allowCustomerType(i.rule, i.customerType)).filter(i => {
      if (i.offShelfDate && i.onShelfDate) {
        if (i.offShelfDate >= today && i.onShelfDate <= today) {
          return true
        }
      } else if (i.offShelfDate && !i.onShelfDate) {
        if (i.offShelfDate >= today) {
          return true
        }
      } else if (i.onShelfDate && !i.offShelfDate) {
        if (i.onShelfDate <= today) {
          return true
        }
      } else {
        return true
      }
      return false
    }).map(i => ({ label: i.name, value: i.name }))
  }

  function getPaymentTypes() {
    const storedValueCards = []
    // 如果買的產品裡面有儲值, 就不可以以用儲值付款
    const buyStoredValueCard = salesOrder.products.some(i => productMapping[i.productId].type === 'storedValue')
    if (!buyStoredValueCard) {
      for (const k of Object.keys(availableStoredValue || {})) {
        if (productMapping[k]) {
          if ((productMapping[k].seller || []).includes(salesOrder.source)) { // 檢查一下該儲值點數是否可以用在該公司
            storedValueCards.push({
              active: true,
              id: `storedValue*${k}`,
              name: `${productMapping[k].name}(${availableStoredValue[k]})`
            })
          }
        }
      }
    }
    return paymentTypes.concat(storedValueCards).map(i => ({ label: i.name, value: i.id }))
  }

  function onCellKeyDown(cellData, field, key) {
    if (uncollected < 0 || (key !== '=' && key !== '+')) {
      return
    }
    const m = salesOrder[cellData.table].find(i => i.id === cellData.id)
    if (!m) {
      return
    }
    m.collected = String(uncollected)
    updateSalesOrderData('payments', [...salesOrder.payments])
  }

  function onCellValueChanged(cellData, field, value) {
    const m = salesOrder[cellData.table].find(i => i.id === cellData.id)
    if (!m) {
      return
    }

    if (field === 'amount') {
      m.amount = value
      if (isNaN(value) || value === '' || parseInt(value) === 0) {
        addError(m, field, '數量錯誤')
      } else if (parseInt(value) < cellData.purchasedAmount) {
        addError(m, field, '數量不得低於已購數量')
      } else {
        removeError(m, field)
      }
    } else if (field === 'unitPrice') {
      m.unitPrice = value
      if (isNaN(value) || value === '') {
        addError(m, field, '金額錯誤')
      } else if (m.highestPrice !== -1 && parseInt(value) > m.highestPrice) {
        addError(m, field, '金額高於最高價')
      } else if (parseInt(value) < m.lowestPrice) {
        addError(m, field, '金額低於最低價')
      } else {
        removeError(m, field)
      }
    } else {
      removeError(m, field)
      if (field === 'priceType') {
        updateProductByPriceType(m, value)
      }
      m[field] = value
      if (field === 'paymentType' || field === 'collected') {
        // 檢查有沒用超過儲值金
        checkStoredValueLimit()
      }
    }
    updateSalesOrderData({ name: cellData.table }, salesOrder[cellData.table]);
  }

  function checkStoredValueLimit() {
    const balance = { ...availableStoredValue }
    for (const m of salesOrder.payments) {
      if (m.lockPayment) {
        continue
      }
      if (m.paymentType.startsWith('storedValue*')) {
        const s = m.paymentType.split('*')
        const id = s[1]
        if (balance[id] !== undefined) {
          if (parseInt(m.collected) > balance[id]) {
            balance[id] = 0
            addError(m, 'collected', `儲值(${productMapping[id].name})餘額不足`)
          } else {
            balance[id] = balance[id] - parseInt(m.collected)
            if (m.collected !== '') {
              removeError(m, 'collected')
            }
          }
        } else {
          // 找不到, bug ?
        }
      } else {
        if (m.collected !== '') {
          removeError(m, 'collected')
        }
      }
    }
  }

  function onSelectProduct(products) {
    // console.log(products)
    // commonPrice 關鍵字: '原價', '員工價', '員工眷屬價'
    const customerId = defaultCustomer || salesOrder.customer
    if (!customerId) {
      return false
    }
    const customer = customerMapping[customerId]

    if (products.length) {
      for (const p of products) {
        const commonPrice = [...p.commonPrice]
        const originalPrice = commonPrice.find(i => i.name === '原價')
        if (!originalPrice) {
          commonPrice.unshift({ name: '原價', price: String(p.price) })
        }
        const commonPriceMapping = commonPrice.reduce((acc, cur) => {
          acc[cur.name] = cur
          return acc
        }, {})
        const pData = {
          table: 'products',
          id: getRandomHash(),
          productId: p.id,
          price: p.price,
          priceType: '原價',
          unitPrice: p.price,
          purchasedAmount: 0,
          amount: 1,
          note: '',
          commonPrice,
          commonPriceMapping,
          serviceProvider: [salesOrder.source],
          order: prdouctOrder.current
        }
        // 根據客戶類型選擇優先的價格種類
        const priorityItem = commonPrice.filter(i => i.customerType === customer.type).find(i => i.rule && i.rule.includes('priority'))
        if (priorityItem) {
          pData.priceType = priorityItem.name
          updateProductByPriceType(pData, priorityItem.name)
        }
        salesOrder.products.push(pData)
        prdouctOrder.current = prdouctOrder.current + 1
      }
      updateSalesOrderData('products', [...salesOrder.products])
    }

    // handleMenuClose()
  }

  function onAddPayment() {
    const pData = {
      table: 'payments',
      id: getRandomHash(),
      date: dayjs().format('YYYY-MM-DD'),
      paymentType: '',
      collected: '',
      agent: salesOrder.agent || currentUser.key,
      note: '',
      lockPayment: false,
      order: paymentOrder.current
    }
    salesOrder.payments.push(pData)
    paymentOrder.current = paymentOrder.current + 1
    updateSalesOrderData('payments', [...salesOrder.payments])
  }

  function onDeleteItem(table, item) {
    const index = salesOrder[table].findIndex(i => i.id === item.id)
    if (index !== -1) {
      if (table === 'payments' && item.lockPayment) {
        salesOrder.payments[index].void = true
        updateSalesOrderData({ name: table }, salesOrder[table]);
        return
      }
      salesOrder[table].splice(index, 1)
      updateSalesOrderData({ name: table }, salesOrder[table]);
    }
  }

  function onSelectUser(user, params) {
    if (params === 'salesOrderAgent') {
      updateSalesOrderData({ name: 'agent' }, user.id)
    } else {
      const s = params.split('*')
      const p = salesOrder.payments.find(i => i.id === s[1])
      if (p) {
        removeError(p, 'agent')
        p.agent = user.id
        updateSalesOrderData({ name: 'payments' }, salesOrder.payments);
      }
    }
  }

  function onSelectCustomer(customer, params) {
    if (params === 'customer') {
      updateSalesOrderData({ name: 'customer' }, customer.id)
    }
  }

  function validateField(field, value) {
    // if (field.required && value === '') {
    //   return formatMessage({id: 'form.field.isRequired'})
    // }
    if (field.name === 'products') {
      for (const m of value) {
        if (m.errors && m.errors.amount) {
          return m.errors.amount
        }
      }
    } else if (field.name === 'date') { // 日期驗證, 例如: 不可以選擇 3天前 或不可以選擇 未來的時間點
      if (value === null) {
        return formatMessage({ id: 'form.field.isRequired' })
      } else if (value.toString() === 'Invalid Date') {
        return formatMessage({ id: 'form.date.formatError' })
      } else {
        // if (dayjs(value).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')) {
        //   return formatMessage({ id: 'form.date.beforeToday' })
        // }
      }
    }
    return ''
  }

  function updateSalesOrderData(field, value) {
    let newValue = value
    // if (field.allowCharacter) {
    //   newValue = newValue.replace(field.allowCharacter, '')
    // }
    // if (field.maxLength) {
    //   newValue = newValue.substring(0, field.maxLength)
    // }

    let err = validateField(field, value)

    // if (field.name === 'date') {
    //   if (err) {
    //     setDateError({ error: true, helperText: err })
    //   } else {
    //     setDateError({})
    //   }
    // }

    let newData = { ...salesOrder, [field.name]: newValue, [`${field.name}_err`]: err }
    setSalesOrder(newData)
  }

  async function handleSave() {
    setLoading(true);
    let err = false;

    const fields = [
      { name: 'date' },
      { name: 'agent' },
      { name: 'products' },
      { name: 'payments' },
      { name: 'note' },
      { name: 'source' },
    ]

    for (const field of fields) {
      if (salesOrder[`${field.name}_err`] !== undefined && salesOrder[`${field.name}_err`] !== '') {
        setLoading(false);
        return
      }
    }

    // 檢查必填欄位
    const errMsg = formatMessage({ id: 'form.field.isRequired' })
    for (const payment of salesOrder.payments) {
      // if (!payment.agent) {
      //   err = true
      //   addError(payment, 'agent', errMsg)
      // }
      if (!payment.paymentType) {
        err = true
        addError(payment, 'paymentType', errMsg)
      }
      if (!payment.collected) {
        err = true
        addError(payment, 'collected', errMsg)
      }
    }

    const customer = defaultCustomer || salesOrder.customer
    if (!customer) {
      err = true
      salesOrder.customer_err = errMsg
    }

    if (err) {
      setSalesOrder({ ...salesOrder })
      setLoading(false);
      return
    }

    const data = { customer }
    for (const field of fields) {
      data[field.name] = salesOrder[field.name]
    }

    data.products = data.products.reduce((acc, cur) => {
      const p = {
        amount: parseInt(cur.amount),
        unitPrice: parseInt(cur.unitPrice),
        priceType: cur.priceType,
        productId: cur.productId,
        serviceProvider: cur.serviceProvider,
        note: cur.note,
        order: cur.order
      };
      if (cur.priceRule) {
        p.priceRule = cur.priceRule
      }
      acc[cur.id] = p
      return acc;
    }, {})
    data.payments = data.payments.reduce((acc, cur) => {
      let paymentType = cur.paymentType
      let storedValueCard = ''
      if (paymentType.startsWith('storedValue*')) {
        const s = paymentType.split('*')
        paymentType = s[0]
        storedValueCard = s[1]
      }
      const data = {
        date: cur.date,
        agent: cur.agent,
        paymentType,
        collected: parseInt(cur.collected),
        note: cur.note,
        order: cur.order,
        lockPayment: cur.lockPayment
      }
      if (cur.void) {
        data.void = true
      }
      if (storedValueCard) {
        data.storedValueCard = storedValueCard
      }
      acc[cur.id] = data
      return acc
    }, {})

    data.date = dayjs(data.date).format('YYYY-MM-DD')
    // console.log(JSON.stringify({ id: salesOrder.id, ...data }, null, 4))
    // setLoading(false);
    // return
    try {
      await callFunction('saveSalesOrder', { id: salesOrder.id, ...data })
    } catch (ex) {
      console.log(ex)
    }
    handleClose()
  }

  function handleClose() {
    navigate('/sales/salesOrders');
  }

  function formatProductData(product) {
    const newData = { ...product }
    newData.name = productMapping[newData.productId].name
    newData.nickname = productMapping[newData.productId].nickname
    newData.totalPrice = newData.unitPrice * newData.amount
    newData.serviceProvider = newData.serviceProvider.map(i => spMapping[i].nickname).join(',')
    return newData
  }

  function formatPaymentData(payment) {
    const newData = { ...payment }
    newData.agent = userMapping[newData.agent]?.displayName || '無'

    if (newData.paymentType.startsWith('storedValue*')) {
      const s = newData.paymentType.split('*')
      newData.paymentType = `儲值(${productMapping[s[1]].name})`
    } else {
      newData.paymentType = paymentTypeMapping[newData.paymentType]?.name || ''
    }
    // newData.nickname = productMapping[newData.productId].nickname
    // newData.totalPrice = newData.unitPrice * newData.amount
    return newData
  }

  function getAgents(type) {
    if (type === 'salesOrderAgent') {
      return users.filter(u => u.active && !u.developer)
    } else {
      const list = users.filter(u => u.active && !u.developer)
      list.unshift({ id: '', displayName: '無', email: 'N/A' })
      return list
    }
  }

  const getSalesOrderProduct = (id) => {
    return salesOrder.products.find(i => i.id === id)
  }

  const onServiceProviderChanged = (vendors, params) => {
    const s = params.split('*')
    const p = getSalesOrderProduct(s[1]) //salesOrder.products.find(i => i.id === s[1])
    if (p) {
      // removeError(p, 'agent')
      p.serviceProvider = vendors.map(i => i.id)
      updateSalesOrderData({ name: 'products' }, salesOrder.products);
    }
  }

  return (
    <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
      {openDialog === 'product' && <SelectProductDialog
        headerCells={[{ text: 'name', sort: 'name' }]}
        rowCells={[{ field: 'name' }]}
        filterItems={[{ name: 'name' }]}
        defaultSelectedItems={[]}
        handleClose={() => setOpenDialog('')}
        handleSave={onSelectProduct}
        items={products.filter(i => (i.seller || []).includes(salesOrder.source)) }
        onShelf
        dialogTitle={formatMessage({ id: 'selectProductDialog.title' })}
        tableTitle={formatMessage({ id: 'selectProductDialog.table.title' })}
        // isMobile={isMobile}
        productMapping={productMapping}
      />}
      {(openDialog === 'salesOrderAgent' || openDialog.startsWith('paymentAgent')) && <SelectUserDialog
        headerCells={[{ name: 'displayName', sort: 'displayName' }, { name: 'email' }]}
        rowCells={[{ field: 'displayName' }, { field: 'email' }]}
        filterItems={[{ name: 'displayName' }, { name: 'email' }]}
        dialogTitle={formatMessage({ id: 'selectUserDialog.title' })}
        tableTitle={formatMessage({ id: 'selectUserDialog.table.title' })}
        handleClose={() => setOpenDialog('')}
        handleSave={onSelectUser}
        items={getAgents(openDialog === 'salesOrderAgent' ? 'salesOrderAgent' : 'paymentAgent')}
        params={openDialog}
      />}
      {openDialog === 'customer' && <SelectCustomerDialog
        handleClose={() => setOpenDialog('')}
        handleSave={onSelectCustomer}
        customers={customers || []}
        dialogTitle={formatMessage({ id: 'selectCustomerDialog.title' })}
        params={openDialog}
      />}
      {openDialog.startsWith('verdor') && <SelectVerdorDialog
        multiSelect
        headerCells={[
          { name: 'code', sort: 'code' },
          { name: 'nickname' },
        ]}
        rowCells={[
          { field: 'code' },
          { field: 'nickname' },
        ]}
        filterItems={[{ name: 'code' },{ name: 'nickname' }]}
        dialogTitle={formatMessage({ id: 'selectServiceProviderDialog.title' })}
        tableTitle={formatMessage({ id: 'selectServiceProviderDialog.table.title' })}
        handleClose={() => setOpenDialog('')}
        handleSave={onServiceProviderChanged}
        defaultSelectedItems={getSalesOrderProduct(openDialog.split('*')[1]).serviceProvider}
        items={sps}
        params={openDialog}
      />}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            type="text"
            size="small"
            select={userRights['salesOrder-create']?.length > 1}
            disabled={!!defaultCustomer || salesOrderId !== 'new' || userRights['salesOrder-create']?.length <= 1 || salesOrder.products.length > 0}
            label={formatMessage({ id: 'salesOrder.table.detail.source' })}
            variant="outlined"
            value={userRights['salesOrder-create']?.length > 1 ? salesOrder.source : vendorMapping[salesOrder.source]?.name}
            onChange={(e) => updateSalesOrderData({ name: 'source' }, e.target.value)}
            fullWidth
          >
            {
              companyData.map(c => {
                return <MenuItem key={c} value={c}>{vendorMapping[c].name}</MenuItem>
              })
            }
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            type="text"
            size="small"
            label={formatMessage({ id: 'salesOrder.table.detail.customer' })}
            variant="outlined"
            disabled={!!defaultCustomer || salesOrderId !== 'new' || !!salesOrder.products.length}
            value={
              defaultCustomer ? customerMapping[defaultCustomer].name :
                (salesOrder.customer ? customerMapping[salesOrder.customer].name : '')
            }
            onClick={() => { if (!defaultCustomer) setOpenDialog('customer') }}
            error={salesOrder.customer_err ? true : false}
            helperText={salesOrder.customer_err}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DatePickerField
            required
            fullWidth
            disabled={salesOrderId !== 'new'}
            label={formatMessage({ id: 'salesOrder.table.detail.date' })}
            value={salesOrder.date}
            onChange={date => updateSalesOrderData({ name: 'date' }, date)}
            // {...dateError}
            // minDateMessage={formatMessage({ id: 'form.date.beforeToday' })} // 暫時開啟時間限制
            invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
            // minDate={salesOrderId === 'new' ? dayjs().add(-2, 'days') : null}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            type="text"
            size="small"
            label={formatMessage({ id: 'salesOrder.table.detail.agent' })}
            variant="outlined"
            value={salesOrder.agent ? userMapping[salesOrder.agent].displayName : ''}
            onClick={() => setOpenDialog('salesOrderAgent')}
            error={salesOrder.agent_err ? true : false}
            helperText={salesOrder.agent_err}
            fullWidth
          />
        </Grid>
      </Grid>
      <Divider style={{ margin: '8px 0px' }} />
      <EnhancedTableToolbar
        title="editSalesOrderPage.table.product"
        // selectdMessage="editSalesOrderPage.table.selected"
        // numSelected={Object.keys(selectedItems).length}
        toolbox={
          <Button
            disabled={!salesOrder.customer || salesOrder.lockProduct}
            sx={{ m: 1, whiteSpace: 'nowrap' }}
            variant="contained"
            color="primary"
            onClick={() => { setOpenDialog('product') }}
          >
            <FormattedMessage id="editSalesOrderPage.addProduct" />
          </Button>
        }
      />
      <EnhancedTable
        headerCells={productHeaderCells}
        rowCells={productRowCells}
        tableData={salesOrder.products.map(i => formatProductData(i))}
        getActionIcons={product =>
          product.purchasedAmount ? <div /> : <DelectIconButton
            text={formatMessage({ id: 'editSalesOrderPage.removeProduct' })}
            onClick={() => onDeleteItem('products', product)}
          />
        }
        extRows={
          <>
            <TableRow>
              <TableCell colSpan={3} />
              <TableCell align="right">
                <Typography variant="subtitle2">
                  <FormattedMessage id="editSalesOrderPage.subtotal" />
                </Typography>
              </TableCell>
              <TableCell align="right">{subtotal}</TableCell>
              <TableCell />
            </TableRow>
          </>
        }
      />
      <Divider style={{ margin: '8px 0px' }} />
      <EnhancedTableToolbar
        title="editSalesOrderPage.table.payment"
        // selectdMessage="editSalesOrderPage.table.selected"
        // numSelected={Object.keys(selectedItems).length}
        toolbox={
          <Button
            disabled={!salesOrder.customer || collected === subtotal}
            sx={{ m: 1, whiteSpace: 'nowrap' }}
            variant="contained"
            color="primary"
            onClick={onAddPayment}
          >
            <FormattedMessage id="editSalesOrderPage.addPayment" />
          </Button>
        }
      />
      <EnhancedTable
        headerCells={paymentHeaderCells}
        rowCells={paymentRowCells}
        tableData={salesOrder.payments.map(i => formatPaymentData(i))}
        getActionIcons={payment =>
          payment.void ? <div style={{ color: 'red' }}>作廢</div> : <DelectIconButton
            text={formatMessage({ id: 'editSalesOrderPage.removePayment' })}
            onClick={() => onDeleteItem('payments', payment)}
          />
        }
        extRows={
          <>
            <TableRow>
              <TableCell rowSpan={2} colSpan={2} />
              <TableCell align="right">
                <Typography variant="subtitle2">
                  <FormattedMessage id="editSalesOrderPage.collected" />
                </Typography>
              </TableCell>
              <TableCell align="right">{collected}</TableCell>
              <TableCell colSpan={2} />
            </TableRow>
            <TableRow>
              <TableCell align="right">
                <Typography variant="subtitle2">
                  <FormattedMessage id="editSalesOrderPage.uncollected" />
                </Typography>
              </TableCell>
              <TableCell align="right"><span style={uncollected < 0 ? { color: 'red' } : {}}>{uncollected}</span></TableCell>
              <TableCell colSpan={2} />
            </TableRow>
          </>
        }
      />
      <Divider style={{ margin: '8px 0px' }} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            type="text"
            label={formatMessage({ id: 'salesOrder.table.detail.note' })}
            variant="outlined"
            value={salesOrder.note}
            onChange={e => updateSalesOrderData({ name: 'note' }, e.target.value)}
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>
      <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
        <Button variant="contained" color="primary" onClick={handleClose}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          onClick={handleSave}
          disabled={loading || salesOrder.products.length === 0 || uncollected < 0}
          loading={loading}
          loadingPosition="start"
          loadingIndicator={<CircularProgress size={24} />}
          startIcon={<div />}
          variant="contained"
        >
          <FormattedMessage id={salesOrderId === 'new' ? 'button.submit' : 'button.save'} />
        </LoadingButton>
      </Stack>
    </Box>
  );
}

export default EditSalesOrderPageWrap;
