import { createFireStoreReducer } from 'reducer/tools';
import suppliersReducer from './suppliers';
import userRightsReducer from './userRights';

const reducer = {
  user: createFireStoreReducer({ name: 'user' }),
  users: createFireStoreReducer({ name: 'users', collection: true }),
  userRawRights: createFireStoreReducer({ name: 'userRawRights' }),
  globalRights: createFireStoreReducer({ name: 'globalRights' }),
  reviewPolicies: createFireStoreReducer({ name: 'reviewPolicies', collection: true }),
  config: createFireStoreReducer({ name: 'config', collection: true }),
  vendors: createFireStoreReducer({ name: 'vendors', collection: true }),
  internalVendors: createFireStoreReducer({ name: 'internalVendors', collection: true }),
  warehouseFlow: createFireStoreReducer({ name: 'warehouseFlow', collection: true }),
  merchandises: createFireStoreReducer({ name: 'merchandises', collection: true }),
  products: createFireStoreReducer({ name: 'products', collection: true }),
  customerExtTab: createFireStoreReducer({ name: 'customerExtTab', collection: true }),
  notifications: createFireStoreReducer({ name: 'notifications' }),
  suppliers: suppliersReducer,
  userRights: userRightsReducer,
}

export default reducer