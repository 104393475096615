const objectToArray = (obj, sortKey) => {
  let result = obj || {}
  result = Object.keys(result).map((key) => ({ ...result[key], id: key }))
  if (sortKey) {
    result = result.sort((a, b) => (a[sortKey] - b[sortKey]))
  }
  return result
}

const groupArray = (array, groupKey) => {
  return array.reduce((acc, cur) => {
    const key = cur[groupKey]
    acc[key] = acc[key] || []
    acc[key].push(cur)
    return acc
  }, {})
}

const objectToNewKey = (obj, newKey) => (
  obj && objectToArray(obj).reduce((acc, item) => {
    acc[item[newKey]] = item
    return acc
  }, {})
)

const compareObjects = (a, b) => {
  const aProps = Object.getOwnPropertyNames(a)
  const bProps = Object.getOwnPropertyNames(b)

  if (aProps.length !== bProps.length) {
    return false
  }

  aProps.map(propName => {
    if (a[propName] !== b[propName]) {
      return false
    }

    return true
  })

  return true
}

function exportFields (rows, path) {
  try {
    const content = rows.join('\n')
    const csvData = new Blob(['\uFEFF' + content], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvData)
    const aExport = document.createElement('a')
    aExport.href = csvUrl
    aExport.target = '_blank'
    aExport.download = path
    aExport.click()
  } catch (error) {
      console.error(error) // eslint-disable-line
    alert('An error occurred. Please refresh and try again.')
  }
}

export {
  objectToArray,
  groupArray,
  objectToNewKey,
  compareObjects,
  exportFields
};
