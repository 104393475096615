import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

function CustomerTypeRuleDialog({ params, handleSave, handleClose, maxWidth = 'sm' }) {
  const _s = params.split('*') // commonPrice*id*customerType*rule
  const { formatMessage } = useIntl()
  const [customerType, setCustomerType] = useState(_s[2]);
  const [rule, setRule] = useState(_s[3]);
  const config = useSelector(state => state.config.data)
  const customerTypes = Object.keys(config.customerType || {}).filter(i => config.customerType[i].active).map(i => config.customerType[i])

  const rules = [
    { label: formatMessage({ id: 'product.customerTypeRule.limit' }), value: 'limit' },
    { label: formatMessage({ id: 'product.customerTypeRule.priority' }), value: 'priority' },
    { label: formatMessage({ id: 'product.customerTypeRule.limitAndPriority' }), value: 'limitAndPriority' }
  ]

  const onApply = () => {
    handleSave(customerType, rule, params)
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title"><FormattedMessage id={'product.edit.customerTypeRule'} /></DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                select
                required
                type="text"
                size="small"
                label={formatMessage({ id: 'product.edit.customerType' })}
                variant="outlined"
                onChange={e => setCustomerType(e.target.value)}
                value={customerType}
                fullWidth
              >
                {customerTypes.map(option => <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                select
                required
                type="text"
                size="small"
                label={formatMessage({ id: 'product.edit.rule' })}
                variant="outlined"
                onChange={e => setRule(e.target.value)}
                value={rule}
                fullWidth
              >
                {rules.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
              </TextField>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button
          variant="contained"
          onClick={() => onApply()}
          color="primary"
          disabled={!customerType || !rule}
        >
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomerTypeRuleDialog;
