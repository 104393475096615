const i18n = {
  'transformService': {
    'add': '新增轉換單',
    'table': {
      'title': '轉換單列表',
      'header': {
        'sn': '轉換單號',
        'date': '日期',
        'createdBy': '建立者',
        'customerName': '客戶',
        'productName': '轉換服務',
        'transformType': '轉換類型'
      },
      'detail': {
        'source': '公司',
        'customer': '客戶',
        'selectPP': '選擇服務',
        'difference': {
          'root': '轉換差額',
          'refund': '退差額',
          'repair': '補差額'
        },
        'paymentType': {
          'refund': '退款類型',
          'repair': '付款類型'
        },
        'differencePrice': '差額金',
        'note': '備註',
      }
    },
    'service': {
      'name': '服務名稱',
      'amount': '轉換數量',
      'useAmount': '可用數量',
      'transformType': '轉換類型',
      'availableAmount': '剩餘數量',
      'proportion': '比例',
      'price': '價格',
      'note': '備註',
      'sn': '營收單號'
    },
    'filter': {
      'sn': '轉換單號',
      'date': '日期',
      'customer': '客戶',
      'createdBy': '建立者',
      'note': '轉讓單備註',
    },
  },
  'editTransformServicePage': {
    'addService': '新增服務',
    'selectService': '選擇服務',
    'removeService': '移除服務',
    'consumed': '已消耗',
    'table': {
      'title': '欲轉換服務',
      'selectPP': '轉換服務'
    }
  },
  'selectPurchasedProductDailog': {
    'title': '選擇已購買服務',
    'table': {
      'header': {
        'name': '服務名稱',
        'amount': '剩餘數量',
        'salesOrderSn': '營收單號'
      }
    }

  }
}
export default i18n
