import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import ButtonProgress from 'components/ButtonProgress';
import { callFunction } from 'modules/firebase';
import { getRandomHash } from 'modules/uitls';

const DividerText = styled('div')(() => ({
  position: 'absolute',
  top: '7px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const numberRule = /[^0-9]/g
function EditICEPage({ handleClose, data, customerId }) {
  const { formatMessage } = useIntl()
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [customerData, setCustomerData] = useState(data)

  const fields = [
    { name: 'name', type: 'text', md: 6, sm: 12, order: 0 },
    { name: 'phoneNumber', type: 'phone', md: 6, sm: 12, allowCharacter: numberRule, order: 1 },
  ].map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  async function saveCustomer() {
    setLoadingApprove(true)
    let updateData = customerData

    for (const key of Object.keys(updateData['ICE'])) {
      if (updateData['ICE'][key].name === '' && updateData['ICE'][key].phoneNumber === '') {
        delete updateData['ICE'][key]
      }
    }

    try {
      await callFunction('saveCustomers', { id: customerId, ...updateData })
      setLoadingApprove(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  function updateData(field, value, key) {
    let newValue = value
    let newData = { ...customerData }

    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }
    
    newData['ICE'][key] = { ...newData['ICE'][key], [field.name]: newValue }

    setCustomerData(newData)
  }

  function newEmergencyContact() {
    const key = getRandomHash()
    let currentICE = { ...customerData['ICE'] }

    currentICE[key] = {
      name: '',
      phoneNumber: ''
    }

    setCustomerData({ ...customerData, 'ICE': currentICE })
  }

  function createField(key, field) {
    let newValue = customerData.ICE[key][field.name] ? customerData.ICE[key][field.name] : ''
    let labelText = ''

    if (field.type === '-') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    }

    if (field.roots) {
      labelText = formatMessage({ id: `customer.dialog.${field.name}.roots` })
    } else {
      if (field.name === 'phoneNumber') {
        labelText = formatMessage({ id: 'customer.dialog.phone' })
      } else {
        labelText = formatMessage({ id: `customer.dialog.${field.name}` })
      }
    }

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        required={field.required}
        label={labelText}
        value={newValue}
        fullWidth
        size="small"
        variant="outlined"
        onChange={e => updateData(field, e.target.value, key)}
      />
    </Grid>
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Dialog
        fullWidth={true}
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'customer.dialog.editEmergencyContacts' })}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} style={{ position: 'relative', marginTop: '20px', marginBottom: '20px' }}>
              <Divider />
              <DividerText style={{ top: '-6px' }}>
                <Typography color="textSecondary" variant="caption">
                  {formatMessage({ id: 'customer.profile.emergencyContacts.root' })}
                </Typography>
                <Tooltip title={formatMessage({ id: 'button.add' })}>
                  <IconButton onClick={() => newEmergencyContact()} size="large">
                    <PersonAddIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </DividerText>
            </Grid>
            {customerData['ICE'] && Object.keys(customerData['ICE']).map(s => fields.map(field => createField(s, field)))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonProgress handleClose={handleClose} handleClick={() => saveCustomer()} loading={loadingApprove} buttonText='button.save' />
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditICEPage.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  customerId: PropTypes.string.isRequired
};

export default EditICEPage;
