import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
// import { useReactToPrint } from 'react-to-print';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import EnhancedTable from 'components/EnhancedTable';
import ActionDialog from 'components/ActionDialog';
import ProgressStep from 'components/ProgressStep';
import ContextStore from 'modules/context';
import { callFunction } from 'modules/firebase';
import { TAX_RATE } from 'constants';
// import DeliveryDetail from './DeliveryDetail';
// import ReceiptReportView from './ReceiptReportView';

function MerchandiseDetail({ items, formName }) {
  const { formatMessage } = useIntl()
  const _headerCells = [
    { field: 'amount', order: 0 },
    { field: 'expectedDate', order: 1 },
    { field: 'lotNumber', order: 2 },
  ]//.map(c => {c.text = formatMessage({id: `receipt.merchandise.${c.field}`});return c})

  const _rowCells = [
    { field: 'amount', order: 0 },
    { field: 'expectedDate', order: 1 },
    { field: 'lotNumber', order: 2 },
  ]

  const headerCells = _headerCells.map(c => {c.text = formatMessage({ id: `${formName}.merchandise.${c.field}` });return c})
  const rowCells = _rowCells

  return (
    <EnhancedTable
      size="small"
      headerCells={headerCells}
      rowCells={rowCells}
      tableData={items}
    />
  )
}

MerchandiseDetail.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
  formName: PropTypes.string.isRequired,
};

function ReceiptView({ receipt, userMapping, merchandiseMapping, supplierMapping, formName }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  // const componentRef = useRef();
  const [dialogData, setDialogData] = useState(null);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [approved, setApproved] = useState(false);
  const [deliveryInfo, setDeliveryInfo] = useState(null);

  let refSn = ''
  let refId = ''
  if (formName === 'receipt') {
    refSn = 'purchaseOrderSn'
    refId = 'purchaseOrderId'
  } else if (formName === 'borrowingReceipt') {
    refSn = 'borrowingOrderSn'
    refId = 'borrowingOrderId'
  } else if (formName === 'returnBorrowingReceipt') {
    refSn = 'borrowingOrderSn'
    refId = 'borrowingOrderId'
  }

  const receiptHistory = (receipt.history || []).map(h => ({ ...h }))
  const currentStep = receiptHistory.length > 0 ? receiptHistory[receiptHistory.length - 1].step : 0

  const _headerCells = [
    { field: refSn, sort: refSn, order: 0 },
    { field: 'code', sort: 'code', order: 1 },
    { field: 'name', order: 2 },
    // {field: 'lotNumber', align: 'right', order: 3},
    // {field: 'expectedDate', align: 'right', order: 4},
    { field: 'amount', align: 'right', order: 6 },
    { field: 'orderUnit', align: 'right', order: 8 },
    { field: 'note', align: 'right', order: 11 },
  ]

  const _rowCells = [
    { field: refSn, order: 0, type: 'info', onButtonClick: showDeliveryDetailText },
    { field: 'code', order: 1 },
    { field: 'nickname', tooltip: 'name', order: 2 },
    // {field: 'lotNumber', align: 'right', order: 3},
    // {field: 'expectedDate', align: 'right', order: 4},
    { field: 'amount', align: 'right', order: 6 },
    { field: 'orderUnit', align: 'right', order: 8 },
    { field: 'note', align: 'right', maxWidth: '120px', order: 11 },
  ]

  const headerCells = (formName === 'receipt' && (userRights.debugging || receipt.steps[0].overwrite.includes(currentUser.key)) ?
    [..._headerCells,
      { field: 'unitPrice', align: 'right', order: 5 },
      { field: 'price', align: 'right', order: 9 },
      { field: 'discount', align: 'right', order: 10 }] :
    _headerCells
  ).map(c => {c.text = formatMessage({ id: `${formName}.merchandise.${c.field}` });return c}).sort((a, b) => a.order - b.order)

  const rowCells = (formName === 'receipt' && (userRights.debugging || receipt.steps[0].overwrite.includes(currentUser.key)) ?
    [..._rowCells,
      { field: 'unitPrice', align: 'right', order: 5 },
      { field: 'price', align: 'right', type: 'calculate',calculate: 'unitPrice*finalAmount->Round', order: 9 },
      { field: 'discount', align: 'right', order: 10 }] :
    _rowCells
  ).sort((a, b) => a.order - b.order)

  const invoiceSubtotal = receipt.merchandises.map(m => {
    const newM = { ...m }
    return newM
  }).reduce((acc, cur) => {
    acc += Math.round(cur.unitPrice * cur.amount)
    return acc;
  }, 0)
  const totalDiscount = receipt.merchandises.reduce((acc, cur) => {
    acc += (cur.discount || 0)
    return acc;
  }, receipt.discount || 0)
  const invoiceSubtotalTax = Math.round(invoiceSubtotal * TAX_RATE)
  const totalDiscountTax = Math.round(totalDiscount * TAX_RATE)

  // const invoiceTaxes = (receipt.tax || receipt.tax === 0) ? receipt.tax : (receipt.taxIncluded ? 0 : Math.round(TAX_RATE * (invoiceSubtotal - totalDiscount)))
  const invoiceTaxes = (receipt.tax || receipt.tax === 0) ? receipt.tax : (invoiceSubtotalTax - totalDiscountTax)
  const invoiceTotal = invoiceTaxes + invoiceSubtotal - totalDiscount + (receipt.shippingFee || 0)

  function formatData(merchandise, index) {
    const data = {
      ...merchandise,
      key: `${merchandise.id}.${index}`,
      code: merchandiseMapping[merchandise.id].code,
      name: merchandiseMapping[merchandise.id].name,
      nickname: merchandiseMapping[merchandise.id].nickname,
      orderUnit: merchandise.orderBySku ? merchandiseMapping[merchandise.id].sku : merchandiseMapping[merchandise.id].orderUnit,
    }
    if (!data.items) {
      data.items = [{
        key: `${merchandise.id}.${index}.0`,
        id: merchandise.id,
        amount: merchandise.amount,
        expectedDate: merchandise.expectedDate,
        lotNumber: merchandise.lotNumber
      }]
    } else {
      data.items = data.items.map((i, idx) => ({
        ...i,
        key: `${merchandise.id}.${index}.${idx}`,
      }))
    }
    data.finalAmount = data.amount
    return data
  }

  function accept() {
    setDialogData({ action: 'accept', title: '' })
  }

  function handleClose() {
    setDialogData(null)
  }

  async function handleExecute(data) {
    const { action, text } = data
    setDialogData(null)
    setLoadingApprove(true)
    try {
      await callFunction('reviewReceipt', {
        id: receipt.id,
        formName,
        action,
        note: text
      })
      if (currentStep < receipt.steps.length - 1) {
        setApproved(true)
        setLoadingApprove(false)
      }
    } catch (ex) {
      setLoadingApprove(false)
      console.log(ex)
    }
  }

  function decodeModifyLog(log) {
    if (!(userRights.debugging || receipt.steps[0].overwrite.includes(currentUser.key))) {
      log = log.split('\n').filter(line => (!line.includes('f{discount}') && !line.includes('f{merchandiseDiscount}'))).join('\n')
    }
    log = log.replace(/f{modify}/g, formatMessage({ id: 'step.action.modify' }))
    log = log.replace(/f{update}/g, formatMessage({ id: 'step.action.update' }))
    log = log.replace(/f{add}/g, formatMessage({ id: 'step.action.add' }))
    log = log.replace(/f{remove}/g, formatMessage({ id: 'step.action.remove' }))
    log = log.replace(/f{note}/g, formatMessage({ id: 'receipt.table.detail.note' }))
    log = log.replace(/f{discount}/g, formatMessage({ id: 'receipt.table.detail.discount' }))
    log = log.replace(/f{invoiceNumber}/g, formatMessage({ id: 'receipt.table.detail.invoiceNumber' }))
    log = log.replace(/f{shippingFee}/g, formatMessage({ id: 'receipt.table.detail.shippingFee' }))
    log = log.replace(/f{tax}/g, formatMessage({ id: 'receipt.table.detail.tax' }))
    log = log.replace(/f{shippingOut}/g, formatMessage({ id: 'receipt.table.detail.shippingOut' }))
    log = log.replace(/f{supplier}/g, formatMessage({ id: 'receipt.table.detail.supplier' }))
    log = log.replace(/f{merchandise}/g, formatMessage({ id: 'receipt.table.detail.merchandise' }))
    log = log.replace(/f{unitPrice}/g, formatMessage({ id: 'receipt.merchandise.unitPrice' }))
    log = log.replace(/f{amount}/g, formatMessage({ id: 'receipt.merchandise.amount' }))
    log = log.replace(/f{purchaseOrderSn}/g, formatMessage({ id: 'receipt.merchandise.purchaseOrderSn' }))
    log = log.replace(/f{lotNumber}/g, formatMessage({ id: 'receipt.merchandise.lotNumber' }))
    log = log.replace(/f{expectedDate}/g, formatMessage({ id: 'receipt.merchandise.expectedDate' }))
    log = log.replace(/f{extra}/g, formatMessage({ id: 'receipt.merchandise.extra' }))
    log = log.replace(/f{merchandiseDiscount}/g, formatMessage({ id: 'receipt.merchandise.discount' }))
    log = log.replace(/f{merchandiseNote}/g, formatMessage({ id: 'receipt.merchandise.note' }))
    log = log.replace(/s{}/g, '')
    let matchs = [...new Set((log.match(/i{.+?}/g) || []).map(i => i.substring(2, i.length - 1)))]
    for (const m of matchs) {
      log = log.replace((new RegExp(`i{${m}}`, 'g')), merchandiseMapping[m].name)
    }
    let matchs2 = [...new Set((log.match(/s{.+?}/g) || []).map(i => i.substring(2, i.length - 1)))]
    for (const m of matchs2) {
      log = log.replace((new RegExp(`s{${m}}`, 'g')), supplierMapping[m].name)
    }

    return log
  }

  const steps = [...receiptHistory].concat(receipt.status !== 'void' ? receipt.steps.slice(currentStep, receipt.steps.length).map(s => ({ ...s })) : [])
  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName || '系統自動'}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    }
  }
  const lastHistory = receipt.history[receipt.history.length - 1]

  function allowEditing() {
    if (receipt.lock) {
      if (((currentStep === 1 && lastHistory.action !== 'reject') || receipt.status === 'done') && receipt.lock && receipt.steps[0].overwrite.includes(currentUser.key)) {
        return true
      }
    } else {
      if (((currentStep === 1 && lastHistory.action !== 'reject') || currentStep === 0) && receipt.steps[0].users.includes(currentUser.key) && receipt.createdBy !== 'N/A') {
        return true
      }
    }
    return false
  }

  function showDeliveryDetailText(merchandiseId, field) {
    setDeliveryInfo({
      refId: receipt[refId],
      merchandiseId,
      customerId: receipt.source,
    })
  }

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  return (
    <div style={{ flexGrow: 1, height: '100%' }}>
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` }) + (receipt.void ? formatMessage({ id: 'button.void' }) : '') + formatMessage({ id: `${formName}.name` })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'receipt.table.detail.note' })}
        action={dialogData.action}
      />}
      {/* {deliveryInfo &&
        <DeliveryDetail info={deliveryInfo} refId={refId} onClose={() => setDeliveryInfo(null)} />
      } */}
      {/* <div style={{ display: 'none' }}>
        <div ref={componentRef}>
          <ReceiptReportView receipt={receipt} merchandiseMapping={merchandiseMapping} formName={formName} />
        </div>
      </div> */}
      <Box p={0}>
        <ProgressStep activeStep={receipt.history.length} steps={steps} />
        <EnhancedTable
          size="small"
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={headerCells}
          rowCells={rowCells}
          getExpandContent={merchandise =>
            <MerchandiseDetail items={merchandise.items} formName={formName} />
          }
          tableData={receipt.merchandises.map((m, idx) => formatData(m, idx))}
          extRows={
            formName === 'receipt' && (userRights.debugging || receipt.steps[0].overwrite.includes(currentUser.key)) ?
              <>
                <TableRow>
                  <TableCell rowSpan={5} colSpan={6}/>
                  <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.discount" /></Typography></TableCell>
                  <TableCell align="right" colSpan={2}>{receipt.discount}</TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.subtotal" /></Typography></TableCell>
                  <TableCell align="right">{invoiceSubtotal}</TableCell>
                  <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>- {totalDiscount}</TableCell>
                  <TableCell align="right">{invoiceSubtotal - totalDiscount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.tax" /></Typography></TableCell>
                  <TableCell align="right">{invoiceSubtotalTax}</TableCell>
                  <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>- {totalDiscountTax}</TableCell>
                  <TableCell align="right">{invoiceTaxes}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.shippingFee" /></Typography></TableCell>
                  <TableCell colSpan={2}/>
                  <TableCell align="right">{receipt.shippingFee || 0}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.total" /></Typography></TableCell>
                  <TableCell colSpan={2}/>
                  <TableCell align="right">{invoiceTotal}</TableCell>
                </TableRow>
              </> : null
          }
        />
        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          {formName === 'receipt' && (userRights.debugging || receipt.steps[0].overwrite.includes(currentUser.key)) && <Grid item xs={12} sm={6} md={6}>
            <TextField
              type="text"
              label={formatMessage({ id: 'receipt.table.detail.invoiceNumber' })}
              value={receipt.invoiceNumber}
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>}
          {formName === 'receipt' && (userRights.debugging || receipt.steps[0].overwrite.includes(currentUser.key)) && <Grid item xs={12} sm={6} md={6}>
            <TextField
              type="text"
              label={formatMessage({ id: 'receipt.table.detail.shippingOut' })}
              value={receipt.shippingOut ? supplierMapping[receipt.shippingOut].name : ''}
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>}
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'receipt.table.detail.note' })}
              value={receipt.note}
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          <Grid item key="buttons" xs={12} sm={12} md={12}>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handlePrint}
                disabled={loadingApprove}
              >
                <FormattedMessage id="button.print" />
              </Button> */}
              {formName === 'receipt' && allowEditing() && !receipt.void &&
                <Link to={`/purchase/receipt/edit/${receipt.id}`} style={{ textDecoration: 'none', color: '#000' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loadingApprove}
                  >
                    <FormattedMessage id="button.edit" />
                  </Button>
                </Link>
              }
              {!approved && currentStep > 0 && currentStep < receipt.steps.length && receipt.steps[currentStep].users.includes(currentUser.key) && receipt.status !== 'void' &&
                <LoadingButton
                  color="success"
                  onClick={accept}
                  disabled={loadingApprove}
                  loading={loadingApprove}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.accept" />
                </LoadingButton>
              }
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

// ReceiptView.defaultProps = {

// }

ReceiptView.propTypes = {
  receipt: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
  supplierMapping: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
};

export default ReceiptView;
