import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import EditNewVendorView from './EditNewVendorView';
import { callFunction } from 'modules/firebase';

const SwitchContainer = styled('div')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: 1.75,
  letterSpacing: '0.00938em',
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
}));

function EditVendorDialog({ vendor, handleClose }) {
  const childRef = useRef()
  const [loading, setLoading] = useState(false)
  const [systemFlags, setSystemFlags] = useState(false)
  const [internalCountErr, setInternalCountErr] = useState(false)
  const config = useSelector(state => state.config.data)
  const limits = config.limits // 判斷內部廠商數量
  const vendors = useSelector(state => state.vendors.ordered)

  const handleSave = async () => {
    setLoading(true);
    const data = childRef.current.getContent()
    if (data.internal !== vendor.internal && vendors.filter(v => v.active && v.internal).length + 1 > limits.company) {
      setInternalCountErr(true)
      return setLoading(false);
    }

    if (data) {
      try {
        await callFunction('saveVendor', { id: vendor.id, ...data })
      } catch (ex) {
        console.log(ex)
      }
    } else {
      setLoading(false);
      return
    }
    // setLoading(false);
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id={`vendor.dialog.title.${vendor.id === 'new' ? 'add' : 'edit'}`} />
        <SwitchContainer>
          <div>簡易</div>
          <Switch
            checked={systemFlags}
            onChange={e => setSystemFlags(e.target.checked)}
            color="primary"
            name="editmode"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <div>進階</div>
        </SwitchContainer>
      </DialogTitle>
      <DialogContent dividers={true}>
        <EditNewVendorView ref={childRef} vendor={vendor} ignoreFields={systemFlags ? [] : ['supplier', 'internal', 'active']} />
        {internalCountErr && <Typography color="red" variant="inherit" sx={{ mt: '5px' }}>{'建立內部廠商上限'}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          onClick={handleSave}
          loading={loading}
          loadingPosition="start"
          loadingIndicator={<CircularProgress size={24} />}
          startIcon={<div />}
          variant="contained"
        >
          <FormattedMessage id="button.save" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

EditVendorDialog.propTypes = {
  vendor: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};

export default EditVendorDialog;
