import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { utils as excelUtils, writeFile } from 'xlsx';

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import Typography from '@mui/material/Typography';

import EnhancedTable from 'components/EnhancedTable';
import DateRangePickerField from 'components/DateRangePickerField';
import SearchBox from 'components/SearchBox';
import FabAdd from 'components/FabAdd';
import ContextStore from 'modules/context';
import { useModules } from 'hooks/modules';
import { firestoreListener, callFunction } from 'modules/firebase';
import ConsumedServiceView from './ConsumedServiceView';
import ActionDialog from 'components/ActionDialog';
import CompanyFilter from 'components/CompanyFilter';
import SimpleTableToolbar from 'components/SimpleTableToolbar';

function ConsumedServiceList() {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const modules = useModules()
  const userRights = useSelector(state => state.userRights)
  const { setBreadcrumbs, currentCompany } = useContext(ContextStore)
  const [consumedServiceForms, setConsumedServiceForms] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(null);
  const sourceMapping = useSelector(state => state.internalVendors.data)
  const [customerMapping, setCustomerMapping] = useState({});
  const userMapping = useSelector(state => state.users.data)
  const productMapping = useSelector(state => state.products.data)
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [openDialog, setOpenDialog] = useState(null);
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState('done');

  const filteredConsumedServiceForms = filterConsumedServiceForms()

  const _filterItems = [
    { name: 'sn' },
    { name: 'customer' },
    { name: 'createdBy' },
    { name: 'agent' },
    { name: 'service' },
    { name: 'note' },
  ]
  if (modules.includes('doctor')) {
    _filterItems.splice(4, 0, { name: 'doctor' },)
  }

  const filterItems = _filterItems.map(i => { i.text = formatMessage({ id: `consumedService.filter.${i.name}` }); return i })

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.services.consume' })
    }])

    const unsubscribe = firestoreListener({
      collection: 'consumedServiceForms',
      where: [
        ['date', '>=', startDate],
        ['date', '<=', endDate],
        ['source', '==', currentCompany]
      ],
      onData: (data) => {
        setConsumedServiceForms(data)
      }
    })

    return () => unsubscribe()

  }, [startDate, endDate, currentCompany]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      onData: (mapping) => {
        setCustomerMapping(mapping)
      }
    })
    return () => unsubscribe()
  }, []);

  const _headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'date' },
    { text: 'source' },
    { text: 'createdBy' },
    { text: 'customer' },
    { text: 'agent' },
    { text: 'status' }
  ]

  const _rowCells = [
    { field: 'sn', textColor: true },
    { field: 'date', textColor: true },
    { field: 'sourceName', textColor: true  },
    { field: 'createdBy', textColor: true },
    { field: 'customerName', textColor: true },
    { field: 'agentName', textColor: true },
    { field: 'status', textColor: true }
  ]

  const headerCells = _headerCells
    .map(c => { c.text = formatMessage({ id: `consumedService.table.header.${c.text}` }); return c })
    .sort((a, b) => a.order - b.order)
  const rowCells = _rowCells.sort((a, b) => a.order - b.order)

  function onEditClick(consumedServiceForm) {
    navigate({
      pathname: `/services/consume/edit/${consumedServiceForm.id}`,
    });
  }

  async function handleExecute(data) {
    setLoading(true)
    const consumedServiceFormId = openDialog.id

    try {
      await callFunction('consumedServiceVoid', { id: consumedServiceFormId, reason: data.text })
    } catch (ex) {
      console.log(ex)
    }
    setLoading(false)
  }

  function filterConsumedServiceForms() {
    if (!currentFilter) {
      return consumedServiceForms
    }
    let items = [...consumedServiceForms]
    if (currentFilter) {
      const lowerCaseText = currentFilter.text.toLowerCase().trim()
      if (currentFilter.name === 'customer') {
        items = items.filter(i => customerMapping[i.customer]?.name.toLowerCase().includes(lowerCaseText) || customerMapping[i.customer]?.code.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'agent') {
        items = items.filter(i => userMapping[i.agent]?.displayName.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'createdBy') {
        items = items.filter(i => userMapping[i.createdBy]?.displayName.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'service') {
        items = items.filter(i => Object.keys(i.services || {}).filter(p => productMapping[i.services[p].productId])
          .filter(p => productMapping[i.services[p].productId].name.toLowerCase().includes(lowerCaseText) ||
            (productMapping[i.services[p].productId].nickname || '').toLowerCase().includes(lowerCaseText)).length)
      } else if (currentFilter.name === 'doctor') {
        items = items.filter(i => Object.keys(i.services || {}).filter(p => i.services[p].doctor && userMapping[i.services[p].doctor])
          .filter(p => userMapping[i.services[p].doctor].displayName.toLowerCase().includes(lowerCaseText)).length)
      } else {
        items = items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
      }
    }

    return items
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function formatData(consumedServiceForm) {
    const newData = { ...consumedServiceForm }
    newData.sourceName = sourceMapping[newData.source]?.name || ''
    newData.customerName = customerMapping[newData.customer]?.name || ''
    newData.agentName = userMapping[newData.agent]?.displayName || ''
    newData.createdBy = userMapping[newData.createdBy]?.displayName || ''

    if (newData.void) {
      newData.status = formatMessage({ id: 'consumedService.table.header.void' })
      newData.textColor = '#bab7b7'
    } else {
      newData.status = formatMessage({ id: 'consumedService.table.header.done' })
    }
    return newData
  }

  async function exportConsumedService() {
    function formatServiceData(s) {
      const newData = { ...s }
      newData.productName = productMapping[s.productId]?.nickname || productMapping[s.productId]?.name || ''
      newData.agentNames = (s.agent || []).map(i => userMapping[i].displayName).join(', ')
      newData.doctorName = userMapping[s.doctor].displayName
      return newData
    }
    let res = {}
    try {
      res = await callFunction('exportConsumedService', { startDate, endDate, source: currentCompany })
    } catch (ex) {
      console.log(ex)
    }
    const list = res?.data?.data || []

    const harderRow = [['消耗單號', '日期', '營收單號', '服務', '單價', '數量', '總價', '操作人員', '醫師', '備註']]
    const fields = ['sn', 'date', 'salesOrderSn', 'productName', 'unitPrice', 'amount', 'totalPrice', 'agentNames', 'doctorName', 'note']
    const workBook = excelUtils.book_new(); //新增一個excel檔
    const pages = {}
    for (const data of list) {
      const f = formatServiceData(data)
      if (!pages[f.doctor]) {
        pages[f.doctor] = harderRow
      }
      const row = []
      for (const k of fields) {
        row.push(f[k])
      }
      pages[f.doctor].push(row)
    }

    for (const p of Object.keys(pages)) {
      const page = pages[p]
      const workSheet = excelUtils.aoa_to_sheet(page);
      const name = (userMapping[p]?.displayName ?? '未命名').replace(/[\\/*?]/g,'-').replaceAll('[','(').replaceAll(']',')')
      excelUtils.book_append_sheet(workBook, workSheet, name); //新增一個分頁
    }
    writeFile(workBook, `消耗列表-${startDate}~${endDate}.xlsx`);
  }

  return (
    <div style={{ padding: '20px 24px 80px 24px' }}>
      {userRights.hasUserRight('consumeService-create') &&
        <FabAdd to="/services/consume/edit/new" />
      }
      {loading && <Backdrop open={true} sx={{ zIndex: 2000, color: '#fff' }}>
        <CircularProgress disableShrink color="inherit" />
      </Backdrop>}
      {openDialog && <ActionDialog
        title={formatMessage({ id: 'consumedService.void.title' })}
        handleClose={() => setOpenDialog(null)}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'consumedService.void.voidReason' })}
        action={'void'}
      />}

      <SimpleTableToolbar
        title='consumedService.table.title'
        toolbox={<>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <DateRangePickerField
                startDate={startDate}
                endDate={endDate}
                onStartDateChanged={setStartDate}
                onEndDateChanged={setEndDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CompanyFilter userRight={'consumeService-view'} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                type="text"
                size="small"
                label={formatMessage({ id: 'consumedService.filter.status' })}
                variant="outlined"
                onChange={e => setStatusFilter(e.target.value)}
                value={statusFilter}
                fullWidth
              >
                {['all', 'done', 'void'].map(option => {
                  return (<MenuItem key={option} value={option}>{formatMessage({ id: `consumedService.filter.${option}` })}</MenuItem>)
                })}
              </TextField>
            </Grid>
          </Grid>
          <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} updateUrlParams />

        </>}
      />
      <EnhancedTable
        defaultOrder="asc"
        defaultOrderField="sn"
        headerCells={headerCells}
        rowCells={rowCells}
        getHeaderActionIcons={() =>
          <IconButton
            onClick={() => exportConsumedService()}
            size="large">
            <GetAppIcon></GetAppIcon>
            <Typography noWrap variant="button">{formatMessage({ id: 'button.export' })}</Typography>
          </IconButton>
        }
        getExpandContent={consumedServiceForm =>
          <ConsumedServiceView
            userRights={userRights}
            form={consumedServiceForm}
            onEditClick={onEditClick}
            onVoidClick={(consumedService) => setOpenDialog(consumedService)}
            currentCompany={currentCompany}
          />
        }
        tableData={filteredConsumedServiceForms.map(i => formatData(i)).filter(i => {
          if(statusFilter === 'all') {
            return i
          } else {
            return statusFilter === 'done' ? !i.void : i.void
          }
        })}
      />
    </div>
  );
}

export default ConsumedServiceList;
