import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import clsx from 'clsx';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { createUseStyles } from 'react-jss';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';

import DatePickerField from 'components/DatePickerField';
import { datesData } from 'modules/uitls';
import { doctortPunchCount } from 'modules/doctorModule';
import { objectToArray } from 'modules/data';
import ContextStore from 'modules/context';
import { callFunction } from 'modules/firebase';
import { firebaseV8 } from 'modules/firebaseV8';
import useViewport from 'hooks/windowSize';
import { tabletMedia } from 'constants';
import PunchClock from './PunchClock';
import ScheduleDoctorDate from './ScheduleDoctorDate';

const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
  '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
const oddShiftType = ['no', 'every_week', 'odd_weeks', 'next_weeks']
const evenShiftType = ['no', 'every_week', 'even_weeks', 'next_weeks']
const leaveType = ['leaves']

dayjs.extend(weekOfYear)
const useStyles = createUseStyles({
  scheduleTableContent: {
    width: 'calc(100% - 200px)',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: '200px',
    top: '60px',
    right: 0,
    backgroundColor: 'white',
  },
  scheduleTableColumn: {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 64px - 60px)',
    width: '100%',
    [tabletMedia]: {
      display: 'none',
    }
  },
  scheduleTableHeader: {
    height: '60px',
    backgroundColor: '#f6f6f7',
    position: 'absolute',
    left: '0px',
    top: '-60px',
    overflow: 'hidden',
    width: '100%',
    color: '#5f6578',
    fontSize: '16px',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'hidden',
    paddingRight: '20px',
    borderBottom: '1px solid #828a99',
    [tabletMedia]: {
      display: 'none'
    }
  },
  dateTable: {
    position: 'relative',
    width: '116px',
    padding: '3px',
    flexShrink: 0,
    overflowY: 'scroll',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [tabletMedia]: {
      minHeight: '100px',
      padding: 0,
      backgroundColor: '#f6f6f7',
      width: '117px',
      borderLeft: '1px solid #828a99',
      borderRight: '1px solid #828a99',
      borderBottom: '1px solid #d9dce3',
    }
  },
  dateCloumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  scheduleRow: {
    height: '100px',
    width: 'calc(100% - 200px)',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    [tabletMedia]: {
      display: 'none'
    }
  },
  scheduleRowTable: {
    position: 'relative',
    width: '116px',
    padding: '3px',
    borderBottom: '1px solid #efeff4',
    flexShrink: 0,
    overflowY: 'scroll',
  },
  scheduleRowTableOffDay: {
    position: 'relative',
    width: '116px',
    padding: '3px',
    borderBottom: '1px solid #efeff4',
    flexShrink: 0,
    overflowY: 'scroll',
    backgroundColor: '#f6f7f8'
  },
  scheduleRowCloumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  punchClock: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    padding: '20px',
  },
  punchClockText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  punchClockButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '30px',
  },
  mobileDate: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 64px - 68px - 60px - 100px - 8px)',
    borderBottom: '1px solid #828a99',
    borderTop: '1px solid #828a99',
    marginTop: '5px',
    overflow: 'scroll',
  },
  mobileTable: {
    display: 'flex',
    flexDirection: 'row',
    [tabletMedia]: {
      width: '100%',
    }
  },
  mobileRow: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    width: '100%',
    borderRight: '1px solid #828a99',
    borderBottom: '1px solid #efeff6',
    padding: '6px 3px'
  },
  mobileRowOffDay: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    width: '100%',
    borderRight: '1px solid #828a99',
    borderBottom: '1px solid #efeff4',
    backgroundColor: '#f6f7f8',
    padding: '6px 0'
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    color: 'blue'
  },
  buttonDelProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -22,
    color: 'blue'
  },
});

const dayMapping = {
  'Mo': '一',
  'Tu': '二',
  'We': '三',
  'Th': '四',
  'Fr': '五',
  'Sa': '六',
  'Su': '日'
}

function ScheduleDoctorRows({
  currentUser,
  month,
  year,
  staffData,
  staffKey,
  userRight,
  viewingStaff,
  isTablet,
  doctorShift,
  doctorSpecial,
  doctorLeave,
  punchClock,
  punchClockRevise,
  punchClockExceptions,
  onScrollRow
}) {
  const { formatMessage } = useIntl()
  const { drawerStatus, currentCompany } = useContext(ContextStore)
  const classes = useStyles();
  const { width, height } = useViewport()
  const [menuInfo, setMenuInfo] = useState(null)
  const [secondMenu, setSecondMenu] = useState(null)
  const [punchClockPathData, setPunchClockPathData] = useState([])
  const [punchClockData, setPunchClockData] = useState([])
  const [punchClockReviseOpen, setPunchClockReviseOpen] = useState(false)
  const [punchClockReviseData, setPunchClockReviseData] = useState({ hour: '09', minute: '00' })
  const [doctorShiftData, setDoctorShiftData] = useState({})
  const [doctorShiftId, setDoctorShiftId] = useState({})
  const [doctorLeaveData, setDoctorLeaveData] = useState({})
  const [doctorLeaveId, setDoctorLeaveId] = useState({})
  const [timeError, setTimeError] = useState(false)
  const menuOpen = Boolean(menuInfo);
  const headerDatesRef = useRef()
  const dates = datesData(year, month)
  const [loading, setLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [openBackdrop, setOpenBackdrop] = useState(false)

  const onEdit = (event, content, data, revise) => {
    if (content === 'punchClock') {
      let newEvent = new PunchClock(dayjs(data.date), event, revise ? revise : [])
      setPunchClockData(newEvent.list)
      setPunchClockPathData(data)
    } else {
      const startTime = event.startTime.split(':')
      const endTime = event.endTime.split(':')
      const newData = {
        repeats: event.repeats,
        startDate: dayjs(event.firstDate),
        endDate: event.lastDate ? dayjs(event.lastDate) : null,
        startHour: startTime[0],
        startMin: startTime[1],
        endHour: endTime[0],
        endMin: endTime[1],
      }

      if (content === 'editDoctorShift') {
        setDoctorShiftId(event.id)
        setDoctorShiftData(newData)
      } else {
        setDoctorLeaveId(event.id)
        setDoctorLeaveData(newData)
      }

    }

    setSecondMenu({ [content]: true })
  }

  function updateData(field, value, type) {
    if (type === 'punchClock') {
      let newValue = value
      let newData = { ...punchClockReviseData, [field.name]: newValue }

      setPunchClockReviseData(newData)
    } else {
      let newValue = value

      if (type === 'doctorShift') {
        let newData = { ...doctorShiftData, [field.name]: newValue }
        if (field.name === 'repeats') {
          if (newData.repeats === 'no') {
            newData.endDate = newData.startDate
          } else {
            newData.endDate = null
          }
        } else if (field.name === 'startDate') {
          if (newData.repeats === 'no') {
            newData.endDate = newData.startDate
          }
        }

        setDoctorShiftData(newData)
      } else {
        let newData = { ...doctorLeaveData, [field.name]: newValue }
        if (field.name === 'startDate') {
          newData.endDate = value
        }
        setDoctorLeaveData(newData)
      }
    }
  }

  async function saveDoctorLeave() {
    if (dayjs(`${dayjs(doctorLeaveData.startDate).format('YYYY-MM-DD')} ${doctorLeaveData.startHour}:${doctorLeaveData.startMin}`, 'YYYY-MM-DD HH:mm')
      .isAfter(dayjs(`${dayjs(doctorLeaveData.startDate).format('YYYY-MM-DD')} ${doctorLeaveData.endHour}:${doctorLeaveData.endMin}`, 'YYYY-MM-DD HH:mm'))) {
      setTimeError(true)
      return false
    } else {
      setTimeError(false)
    }
    setLoading(true)
    if (secondMenu.editDoctorLeave) {
      const newData = {
        doctor: menuInfo.staff.id,
        firstDate: dayjs(doctorLeaveData.startDate).format('YYYY-MM-DD'),
        lastDate: dayjs(doctorLeaveData.endDate).format('YYYY-MM-DD'),
        startTime: `${doctorLeaveData.startHour}:${doctorLeaveData.startMin}`,
        endTime: `${doctorLeaveData.endHour}:${doctorLeaveData.endMin}`,
        repeats: doctorLeaveData.repeats,
        updatedAt: new Date(),
        updatedBy: currentUser.key,
        source: currentCompany
      }

      try {
        await firebaseV8().collection('doctorLeaves').doc(doctorLeaveId).update(newData)
        setLoading(false)
        handleClose();
      } catch (ex) {
        console.log(ex)
      }
    } else {
      const newData = {
        doctor: menuInfo.staff.id,
        firstDate: dayjs(doctorLeaveData.startDate).format('YYYY-MM-DD'),
        lastDate: dayjs(doctorLeaveData.endDate).format('YYYY-MM-DD'),
        startTime: `${doctorLeaveData.startHour}:${doctorLeaveData.startMin}`,
        endTime: `${doctorLeaveData.endHour}:${doctorLeaveData.endMin}`,
        repeats: doctorLeaveData.repeats,
        createdAt: new Date(),
        createdBy: currentUser.key,
        source: currentCompany
      }

      try {
        await firebaseV8().collection('doctorLeaves').doc().set(newData)
        setLoading(false)
        handleClose();
      } catch (ex) {
        console.log(ex)
      }
    }
  }

  async function saveDoctorShift() {
    if (dayjs(`${dayjs(doctorShiftData.startDate).format('YYYY-MM-DD')} ${doctorShiftData.startHour}:${doctorShiftData.startMin}`, 'YYYY-MM-DD HH:mm')
      .isAfter(dayjs(`${dayjs(doctorShiftData.startDate).format('YYYY-MM-DD')} ${doctorShiftData.endHour}:${doctorShiftData.endMin}`, 'YYYY-MM-DD HH:mm'))) {
      setTimeError(true)
      return false
    } else {
      setTimeError(false)
    }
    setLoading(true)
    if (secondMenu.editDoctorShift) {
      if (doctorShiftData.repeats === 'no' && !doctorShiftData.endDate) {
        return false
      } else {
        const newData = {
          doctor: menuInfo.staff.id,
          firstDate: dayjs(doctorShiftData.startDate).format('YYYY-MM-DD'),
          lastDate: doctorShiftData.endDate ? dayjs(doctorShiftData.endDate).format('YYYY-MM-DD') : null,
          startTime: `${doctorShiftData.startHour}:${doctorShiftData.startMin}`,
          endTime: `${doctorShiftData.endHour}:${doctorShiftData.endMin}`,
          repeats: doctorShiftData.repeats,
          updatedAt: new Date(),
          updatedBy: currentUser.key,
          source: currentCompany
        }

        try {
          await firebaseV8().collection('doctorShifts').doc(doctorShiftId).update(newData)
          setLoading(false)
          handleClose();
        } catch (ex) {
          console.log(ex)
        }
      }
    } else {
      if (doctorShiftData.repeats === 'no' && !doctorShiftData.endDate) {
        return false
      } else {
        const newData = {
          doctor: menuInfo.staff.id,
          firstDate: dayjs(doctorShiftData.startDate).format('YYYY-MM-DD'),
          lastDate: doctorShiftData.endDate ? dayjs(doctorShiftData.endDate).format('YYYY-MM-DD') : null,
          startTime: `${doctorShiftData.startHour}:${doctorShiftData.startMin}`,
          endTime: `${doctorShiftData.endHour}:${doctorShiftData.endMin}`,
          repeats: doctorShiftData.repeats,
          createdAt: new Date(),
          createdBy: currentUser.key,
          source: currentCompany
        }

        try {
          await firebaseV8().collection('doctorShifts').doc().set(newData)
          handleClose();
          setLoading(false)
        } catch (ex) {
          console.log(ex)
        }
      }
    }
  }

  async function addPunchClockRevise() {
    setOpenBackdrop(true)
    const updateData = {
      date: punchClockPathData.date,
      staffId: punchClockPathData.staffId,
      time: `${punchClockReviseData.hour}:${punchClockReviseData.minute}`
    }

    try {
      await callFunction('saveDoctorPunchClockRevise', { type: 'add', ...updateData })
      handleClose();
    } catch (ex) {
      console.log(ex)
    }
  }

  async function deleteDoctorLeave() {
    setDeleteLoading(true)
    try {
      await firebaseV8().collection('doctorLeaves').doc(doctorLeaveId).delete();
      setDeleteLoading(false)
      handleClose();
    } catch (ex) {
      console.log(ex)
    }
  }

  async function deleteDoctorShift() {
    setDeleteLoading(true)
    try {
      await firebaseV8().collection('doctorShifts').doc(doctorShiftId).delete();
      setDeleteLoading(false)
      handleClose();
    } catch (ex) {
      console.log(ex)
    }
  }

  async function deletePunchClockRevise(data) {
    setOpenBackdrop(true)
    const updateData = {
      date: punchClockPathData.date,
      staffId: punchClockPathData.staffId,
      time: data.time
    }

    try {
      await callFunction('saveDoctorPunchClockRevise', { type: 'delete', ...updateData })
      handleClose();
    } catch (ex) {
      console.log(ex)
    }
  }

  function openAddMenu() {
    if (secondMenu.punchClock) {
      return (
        <div className={classes.punchClock}>
          {openBackdrop && <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2000 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>}
          {formatMessage({ id: 'schedule.anchor.punchClock' })}
          <Grid container spacing={1}>
            {punchClockData.map(pc => {
              return (
                <Grid item key={pc.time} xs={12} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ color: pc.revised ? 'blue' : '' }}>{pc.time}</div>
                  {pc.revised && userRight.hasUserRightForVendor('schedule-doctorPunchClock', currentCompany) &&
                    <IconButton
                      sx={{ color: 'blue' }}
                      onClick={() => deletePunchClockRevise(pc)}
                      size='medium'
                    >
                      <HighlightOffIcon></HighlightOffIcon>
                    </IconButton>
                  }
                </Grid>
              );
            })}
          </Grid>
          {punchClockReviseOpen && <Grid container spacing={1} sx={{ mt: '10px' }}>
            <Grid item xs={12} md={12} sm={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <TextField
                sx={{ mr: '10px' }}
                fullWidth
                variant="outlined"
                size="small"
                select
                onChange={(e) => updateData({ name: 'hour' }, e.target.value, 'punchClock')}
                value={punchClockReviseData.hour}
              >
                {hours.map(hour => {
                  return <MenuItem style={{ padding: '0px 0 0 5px' }} key={hour} value={hour}>{hour}</MenuItem>
                })}
              </TextField>

              <TextField
                fullWidth
                variant="outlined"
                size="small"
                select
                onChange={(e) => updateData({ name: 'minute' }, e.target.value, 'punchClock')}
                value={punchClockReviseData.minute}
              >
                {minutes.map(minute => {
                  return <MenuItem style={{ padding: '0px 0 0 5px' }} key={minute} value={minute}>{minute}</MenuItem>
                })}
              </TextField>

              <Button style={{ color: 'red', display: 'flex', justifyContent: 'flex-end' }} onClick={() => addPunchClockRevise()}>
                <AddCircleIcon></AddCircleIcon>
              </Button>
            </Grid>
          </Grid>}
          <div className={classes.punchClockButton}>
            <Button style={{ marginRight: '10px' }} variant="contained" onClick={handleClose} color="inherit">
              {formatMessage({ id: 'button.close' })}
            </Button>
            {userRight.hasUserRightForVendor('schedule-doctorPunchClock', currentCompany) && <Button variant="contained" onClick={() => setPunchClockReviseOpen(true)} color='primary'>
              {formatMessage({ id: 'button.punchClockRevise' })}
            </Button>}
          </div>
        </div>
      );
    } else {
      const dateM = dayjs(menuInfo.date);
      const weeks = dateM.week() - dayjs(dateM).startOf('month').week() + 1;

      return (
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', p: '20px', maxWidth: '400px' }}>
          <Grid item xs={12} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', fontSize: '14px' }}>
            {secondMenu.doctorShift || secondMenu.editDoctorShift ?
              formatMessage({ id: 'schedule.anchor.add_doctorShift' }) :
              formatMessage({ id: 'schedule.anchor.add_doctorLeave' })
            }
            <TextField
              multiline
              variant="outlined"
              size="small"
              disabled
              value={menuInfo.staff.displayName}
            />
          </Grid>
          <Grid item xs={12} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', fontSize: '14px' }}>

            {secondMenu.doctorShift || secondMenu.editDoctorShift ?
              formatMessage({ id: 'schedule.doctor.repeats' }) :
              formatMessage({ id: 'schedule.doctor.leaveType' })
            }
            <TextField
              multiline
              variant="outlined"
              size="small"
              select
              onChange={
                (e) => secondMenu.doctorShift || secondMenu.editDoctorShift ?
                  updateData({ name: 'repeats' }, e.target.value, 'doctorShift') :
                  updateData({ name: 'repeats' }, e.target.value, 'doctorLeave')
              }
              value={secondMenu.doctorShift || secondMenu.editDoctorShift ? doctorShiftData.repeats : doctorLeaveData.repeats}
            >
              {secondMenu.doctorShift || secondMenu.editDoctorShift ?
                weeks % 2 === 1 ? oddShiftType.map(repeat => {
                  return <MenuItem style={{ padding: '0px 0 0 5px' }} key={repeat} value={repeat}>{formatMessage({ id: `schedule.doctor.typeName.${repeat}` })}</MenuItem>
                }) :
                  evenShiftType.map(repeat => {
                    return <MenuItem style={{ padding: '0px 0 0 5px' }} key={repeat} value={repeat}>{formatMessage({ id: `schedule.doctor.typeName.${repeat}` })}</MenuItem>
                  }) :
                leaveType.map(repeat => {
                  return <MenuItem style={{ padding: '0px 0 0 5px' }} key={repeat} value={repeat}>{formatMessage({ id: `schedule.doctor.typeName.${repeat}` })}</MenuItem>
                })
              }
            </TextField>
          </Grid>
          <Grid item xs={6} md={6} sm={6} >
            <DatePickerField
              required
              fullWidth
              label={formatMessage({ id: 'schedule.doctor.startDate' })}
              value={secondMenu.doctorShift || secondMenu.editDoctorShift ? doctorShiftData.startDate : doctorLeaveData.startDate}
              onChange={
                date => secondMenu.doctorShift || secondMenu.editDoctorShift ?
                  updateData({ name: 'startDate' }, date, 'doctorShift') :
                  updateData({ name: 'startDate' }, date, 'doctorLeave')
              }
              invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
            />
          </Grid>
          <Grid item xs={3} md={3} sm={3}>
            <TextField
              variant="outlined"
              size="small"
              select
              fullWidth
              onChange={
                (e) => secondMenu.doctorShift || secondMenu.editDoctorShift ?
                  updateData({ name: 'startHour' }, e.target.value, 'doctorShift') :
                  updateData({ name: 'startHour' }, e.target.value, 'doctorLeave')
              }
              value={secondMenu.doctorShift || secondMenu.editDoctorShift ? doctorShiftData.startHour : doctorLeaveData.startHour}
            >
              {hours.map(hour => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={hour} value={hour}>{hour}</MenuItem>
              })}
            </TextField>
          </Grid>
          <Grid item xs={3} md={3} sm={3}>
            <TextField
              variant="outlined"
              size="small"
              select
              fullWidth
              onChange={
                (e) => secondMenu.doctorShift || secondMenu.editDoctorShift ?
                  updateData({ name: 'startMin' }, e.target.value, 'doctorShift') :
                  updateData({ name: 'startMin' }, e.target.value, 'doctorLeave')
              }
              value={secondMenu.doctorShift || secondMenu.editDoctorShift ? doctorShiftData.startMin : doctorLeaveData.startMin}
            >
              {minutes.map(minute => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={minute} value={minute}>{minute}</MenuItem>
              })}
            </TextField>
          </Grid>
          <Grid item xs={6} md={6} sm={6} >
            <DatePickerField
              required
              fullWidth
              disabled={
                ((doctorShiftData && doctorShiftData.repeats === 'no') ||
                  (doctorLeaveData && doctorLeaveData.repeats === 'leaves')) ? true : false
              }
              label={formatMessage({ id: 'schedule.doctor.endDate' })}
              style={{ width: '150px', marginRight: '10px' }}
              value={secondMenu.doctorShift || secondMenu.editDoctorShift ? doctorShiftData.endDate : doctorLeaveData.endDate}
              onChange={
                date => secondMenu.doctorShift || secondMenu.editDoctorShift ?
                  updateData({ name: 'endDate' }, date, 'doctorShift') :
                  updateData({ name: 'endDate' }, date, 'doctorLeave')
              }
              minDate={
                secondMenu.doctorShift || secondMenu.editDoctorShift ?
                  dayjs(doctorShiftData.startDate).toDate() :
                  dayjs(doctorLeaveData.startDate).toDate()
              }
              minDateMessage={formatMessage({ id: 'form.date.beforeStartDate' })}
              invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
            />
          </Grid>
          <Grid item xs={3} md={3} sm={3} >
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              select
              onChange={
                (e) => secondMenu.doctorShift || secondMenu.editDoctorShift ?
                  updateData({ name: 'endHour' }, e.target.value, 'doctorShift') :
                  updateData({ name: 'endHour' }, e.target.value, 'doctorLeave')
              }
              value={secondMenu.doctorShift || secondMenu.editDoctorShift ? doctorShiftData.endHour : doctorLeaveData.endHour}
            >
              {hours.map(hour => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={hour} value={hour}>{hour}</MenuItem>
              })}
            </TextField>
          </Grid>
          <Grid item xs={3} md={3} sm={3}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              select
              onChange={
                (e) => secondMenu.doctorShift || secondMenu.editDoctorShift ?
                  updateData({ name: 'endMin' }, e.target.value, 'doctorShift') :
                  updateData({ name: 'endMin' }, e.target.value, 'doctorLeave')
              }
              value={secondMenu.doctorShift || secondMenu.editDoctorShift ? doctorShiftData.endMin : doctorLeaveData.endMin}
            >
              {minutes.map(minute => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={minute} value={minute}>{minute}</MenuItem>
              })}
            </TextField>
          </Grid>
          {/* </div> */}
          {timeError && <div style={{ color: 'red' }}>{formatMessage({ id: 'form.time.formatError' })}</div>}
          <Grid item xs={12} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: '10px' }}>
            {((secondMenu.editDoctorShift && userRight.hasUserRightForVendor('schedule-doctorEdit', currentCompany)) || secondMenu.editDoctorLeave) ? <div className={classes.wrapper}>
              <Button
                variant="contained"
                onClick={() => secondMenu.doctorShift || secondMenu.editDoctorShift ? deleteDoctorShift() : deleteDoctorLeave()}
                color='error'
                disabled={loading || deleteLoading}
              >
                {formatMessage({ id: 'button.delete' })}
              </Button>
              {deleteLoading && <CircularProgress size={24} className={classes.buttonDelProgress} />}
            </div> : <div />}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button sx={{ mr: '10px' }} variant="contained" onClick={handleClose} color="inherit">
                {formatMessage({ id: 'button.close' })}
              </Button>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  onClick={() => secondMenu.doctorShift || secondMenu.editDoctorShift ? saveDoctorShift() : saveDoctorLeave()}
                  color="primary"
                  disabled={loading || deleteLoading}
                >
                  {secondMenu.editDoctorShift || secondMenu.editDoctorLeave ? formatMessage({ id: 'button.save' }) : formatMessage({ id: 'button.add' })}
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </div>
          </Grid>
        </Grid >
      );
    }
  }

  function openSecondMenu(event) {
    setSecondMenu({ [event]: true })

    if (event === 'doctorShift') {
      const newData = {
        repeats: 'no',
        startDate: dayjs(menuInfo.date),
        startHour: '00',
        startMin: '00',
        endDate: dayjs(menuInfo.date),
        endHour: '00',
        endMin: '00'
      }

      setDoctorShiftData(newData)
    } else if (event === 'doctorLeave') {
      const newData = {
        repeats: 'leaves',
        startDate: dayjs(menuInfo.date),
        startHour: '00',
        startMin: '00',
        endDate: dayjs(menuInfo.date),
        endHour: '00',
        endMin: '00'
      }

      setDoctorLeaveData(newData)
    }
  }

  const handleMenu = (event, date, staff) => {
    if(userRight.hasUserRightForVendor('schedule-doctorEdit', currentCompany)) {
      setMenuInfo({ date, staff, anchor: event.currentTarget })
    } else {
      setMenuInfo(null)
      setSecondMenu(null);
    }
  };

  function onContentScroll({ target: { scrollLeft, scrollTop } }) {
    requestAnimationFrame(() => {
      headerDatesRef.current.scrollLeft = scrollLeft
      onScrollRow(scrollTop)
    });
  }

  function filterData() {
    let filterShift = {}
    let filterSpecial = {}
    let fliterLeave = {}

    for (const s of objectToArray(doctorShift)) {
      filterShift[s.doctor] = { ...filterShift[s.doctor], [s.id]: { ...s } }
    }

    for (const s of objectToArray(doctorSpecial)) {
      filterSpecial[s.doctor] = { ...filterSpecial[s.doctor], [s.id]: { ...s } }
    }

    for (const s of objectToArray(doctorLeave)) {
      fliterLeave[s.doctor] = { ...fliterLeave[s.doctor], [s.id]: { ...s } }
    }

    return {
      filterShift,
      filterSpecial,
      fliterLeave
    }
  }

  const { filterShift, filterSpecial, fliterLeave } = filterData()

  const handleClose = () => {
    setMenuInfo(null);
    setSecondMenu(null);
    setDoctorLeaveData(null);
    setDoctorShiftData(null);
    setPunchClockReviseOpen(false);
    setTimeError(false)
    setOpenBackdrop(false)
    setPunchClockReviseData({
      hour: '09',
      minute: '00'
    })
  };

  if (isTablet && viewingStaff) {
    return (
      <div className={classes.mobileDate} style={{ maxHeight: `calc(${height}px - 290px` }}>
        {menuInfo && userRight.hasUserRightForVendor('schedule-doctorEdit', currentCompany) && <Menu
          id="menu-appbar"
          anchorEl={menuInfo.anchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: isTablet ? 'center' : 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: isTablet ? 'center' : 'top',
            horizontal: 'right',
          }}
          open={menuOpen}
          onClose={handleClose}
        >
          {!secondMenu ? <span>
            <MenuItem onClick={() => openSecondMenu('doctorLeave')}>
              {formatMessage({ id: 'schedule.anchor.add_doctorLeave' })}
            </MenuItem>
            <MenuItem onClick={() => openSecondMenu('doctorShift')}>
              {formatMessage({ id: 'schedule.anchor.add_doctorShift' })}
            </MenuItem>
          </span> : openAddMenu()}
        </Menu>}
        {dates.map(date => {
          const weekday = dayjs(date).day()
          const isDayOff = weekday === 0;
          const schedule = doctortPunchCount(date, viewingStaff, filterShift[viewingStaff], filterSpecial[viewingStaff], fliterLeave[viewingStaff])

          return <div className={classes.mobileTable} style={{ maxWidth: width }} key={date}>
            <div className={classes.dateTable}>
              <div className={classes.dateCloumn}>
                <div>{`${date.format('M/D')} (${dayMapping[date.format('dd')]})`}</div>
              </div>
            </div>
            <div className={!isDayOff ? classes.mobileRow : classes.mobileRowOffDay} onClick={(e) => handleMenu(e, date, staffData[viewingStaff])}>
              <div className={classes.scheduleRowCloumn}>
                <div>
                  <ScheduleDoctorDate
                    date={date}
                    staff={staffData[viewingStaff]}
                    doctorShift={schedule.shifts}
                    doctorLeave={schedule.leaves}
                    doctorSpecial={schedule.specials}
                    onSubmit={onEdit}
                    punchClock={punchClock}
                    punchClockRevise={punchClockRevise}
                    userRight={userRight}
                    punchClockException={punchClockExceptions}
                  />
                </div>
              </div>
            </div>
          </div>
        })}
      </div>
    );
  }

  const dateRows = staffKey.map((key, idx) => {
    return <div key={idx}>
      <div className={classes.scheduleRow}>
        {dates.map(date => {
          const weekday = dayjs(date).day()
          const isDayOff = weekday === 0;
          const schedule = doctortPunchCount(date, key, filterShift[key], filterSpecial[key], fliterLeave[key])

          return (
            <div key={date} className={!isDayOff ? classes.scheduleRowTable : classes.scheduleRowTableOffDay} onClick={(e) => handleMenu(e, date, staffData[key])}>
              <div className={classes.scheduleRowCloumn}>
                <div>
                  <ScheduleDoctorDate
                    date={date}
                    staff={staffData[key]}
                    doctorShift={schedule.shifts}
                    doctorLeave={schedule.leaves}
                    doctorSpecial={schedule.specials}
                    onSubmit={onEdit}
                    punchClock={punchClock}
                    punchClockRevise={punchClockRevise}
                    userRight={userRight}
                    punchClockException={punchClockExceptions}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  })

  return (
    <div className={classes.scheduleTableContent}>
      <div className={classes.scheduleTableColumn} onScroll={onContentScroll}>
        {menuInfo && userRight.hasUserRightForVendor('schedule-doctorEdit', currentCompany) && <Menu
          id="menu-appbar"
          anchorEl={menuInfo.anchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={menuOpen}
          onClose={handleClose}
        >
          {!secondMenu ? <span>
            <MenuItem onClick={() => openSecondMenu('doctorLeave')}>
              {formatMessage({ id: 'schedule.anchor.add_doctorLeave' })}
            </MenuItem>
            <MenuItem onClick={() => openSecondMenu('doctorShift')}>
              {formatMessage({ id: 'schedule.anchor.add_doctorShift' })}
            </MenuItem>
          </span> : openAddMenu()}
        </Menu>}
        <div className={clsx(classes.scheduleTableHeader, { [classes.drawer]: drawerStatus })} ref={headerDatesRef}>
          {dates.map(date => {
            return <div key={date} className={classes.dateTable}>
              <div className={classes.dateCloumn}>
                <div>{`${date.format('M/D')} (${dayMapping[date.format('dd')]})`}</div>
              </div>
            </div>
          })}
        </div>
        {dateRows}
      </div>
    </div>
  );
}


ScheduleDoctorRows.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  month: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  staffData: PropTypes.object.isRequired,
  staffKey: PropTypes.arrayOf(PropTypes.string.isRequired),
  userRight: PropTypes.object.isRequired,
  viewingStaff: PropTypes.string,
  isTablet: PropTypes.bool,
  doctorShift: PropTypes.object.isRequired,
  doctorSpecial: PropTypes.object.isRequired,
  doctorLeave: PropTypes.object.isRequired,
  punchClock: PropTypes.object.isRequired,
  punchClockRevise: PropTypes.object.isRequired,
  punchClockExceptions: PropTypes.array.isRequired,
  onScrollRow: PropTypes.func.isRequired
};

export default ScheduleDoctorRows;
