import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Divider from '@mui/material/Divider';

import DateRangePickerField from 'components/DateRangePickerField';
import { unwrap } from 'modules/uitls';
import ContextStore from 'modules/context';
import { firebaseV8 } from 'modules/firebaseV8';
import RequisitionList from './RequisitionList';

function RequisitionTab() {
  const { tabName } = useParams()
  const { currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)

  const [requisitions, setRequisitions] = useState([])
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  useEffect(() => {
    if (requisitions.length) setRequisitions([])
    const onSnapshot = snapshot => {
      const requisitions = []
      snapshot.forEach(doc => {
        requisitions.push({ ...unwrap(doc.data()), id: doc.id })
      });
      setRequisitions(requisitions)
    }
    const unsubscribe = tabName === 'pending' ?
      firebaseV8().collection('requisitions').where('status', '==', tabName).onSnapshot(onSnapshot, err => {}) :
      firebaseV8().collection('requisitions').where('status', '==', tabName).where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(onSnapshot, err => {})
    return () => unsubscribe()
  }, [startDate, endDate]);

  const requisitionsForTab = requisitions.filter(r =>
    userRights.debugging ||
    userRights.hasUserRightForVendor('requisition-create', r.source) ||
    userRights.hasUserRightForVendor('requisition-review', r.source) ||
    userRights.hasUserRightForVendor('requisition-overwrite', r.source)
  );

  const reqWaitingForProcess = waitingForProcess()

  function waitingForProcess() {
    if (tabName === 'pending') {
      return requisitionsForTab.filter(r => {
        const history = r.history || []
        const currentStep = history.length > 0 ? history[history.length - 1].step : 0
        if (isNaN(currentStep) || currentStep === r.steps.length) return false
        return r.steps[currentStep].users.includes(currentUser.key)
      })
    } else if (tabName === 'done') {
      return requisitionsForTab.filter(r => {
        if (!userRights.hasUserRightForVendor('purchaseOrder-create', r.source)) return false
        const merchandises = Object.keys(r.merchandises).map(k => ({ id: k, ...(r.merchandises[k]) }))
        return (merchandises.some(m => {
          if (m.shoppingCart || (!m.shoppingCart && !m.purchaseOrders)) return true
          const poIds = Object.keys(m.purchaseOrders || {})
          let unPurchasedAmount = m.amount
          for (const i of poIds) {
            if (m.purchaseOrders[i].status === 'done') unPurchasedAmount -= m.purchaseOrders[i].amount
          }
          return unPurchasedAmount > 0
        }))
      })
    } else {
      return []
    }
  }

  return (
    <div style={{ marginTop: '20px', paddingBottom: '60px' }}>
      {tabName !== 'pending' && <DateRangePickerField
        startDate={startDate}
        endDate={endDate}
        onStartDateChanged={setStartDate}
        onEndDateChanged={setEndDate}
      />}
      {reqWaitingForProcess.length > 0 && <>
        <RequisitionList requisitions={reqWaitingForProcess} title={`requisition.table.${tabName}.title`}/>
        <Divider sx={{ mx: '0px', my: '10px' }}/>
      </>}
      <RequisitionList requisitions={requisitionsForTab} title="requisition.table.title" />
    </div>
  );
}

export default RequisitionTab;
