import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';

import EnhancedTable from 'components/EnhancedTable';
import ColorDot from 'components/ColorDot';
import { ProductTypes as productTypes } from 'constants';
import { useModuleMapping } from 'hooks/modules';


const defaultFields = [
  { name: 'type', type: 'select', sm: 6, order: 0 },
  { name: 'tags', md: 9, sm: 6, order: 1 },
  { name: 'cost', sm: 6, sx: 6, order: 2 },
  { name: 'price', sm: 6, sx: 6, order: 3 },
  { name: 'duration', sm: 6, order: 4 },
  { name: 'color', type: 'color', sm: 6, order: 5 },
  { name: 'unit', sm: 6, order: 6 },
  { name: 'description', multiline: true, md: 12, sm: 12, order: 7 },
  { name: 'note', multiline: true, md: 12, sm: 12, order: 8 },
  { name: 'seller', type: 'select', md: 12, sm: 12, order: 9 },
  { name: 'onShelfDate', sm: 6, order: 10 },
  { name: 'offShelfDate', sm: 6, order: 11 },
  { name: '', type: '-', md: 12, sm: 12, order: 14 },
]

function ProductView({ userRights, product, productMapping, customerTypeMapping, onEditClick }) {
  const { formatMessage } = useIntl()
  const moduleMapping = useModuleMapping()
  const merchandiseMapping = useSelector(state => state.merchandises.data)
  const vendorMapping = useSelector(state => state.internalVendors.data)

  const _fields = [...defaultFields]
  if (moduleMapping.bonus) {
    _fields.push({ name: 'salesBonus', sm: 6, order: 12 })
    _fields.push({ name: 'operatorBonus', sm: 6, order: 13 })
  }

  const fields = _fields.map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; field.sx = field.sx || 12; return field }).sort((a, b) => a.order - b.order)

  // const p = { ...product }
  const selectMapping = {
    type: productTypes.reduce((acc, cur) => { acc[cur] = formatMessage({ id: `product.type.${cur}` }); return acc }, {}),
  }

  const _headerCells = [
    { text: 'name', order: 0 },
    { text: 'useAmount', order: 1 },
    { text: 'price', order: 2 },
    { text: 'customerTypeRule', order: 3 },
    { text: 'onShelfDate', order: 4 },
    { text: 'offShelfDate', order: 5 },
  ]

  const _rowCells = [
    { field: 'name', order: 0 },
    { field: 'useAmount', order: 1 },
    { field: 'price', order: 2 },
    { field: 'customerTypeRule', order: 3 },
    { field: 'onShelfDate', order: 4 },
    { field: 'offShelfDate', order: 5 },
  ]

  const headerCells = _headerCells.map(c => { c.text = formatMessage({ id: `product.commonPrice.${c.text}` }); return c }).sort((a, b) => a.order - b.order)
  const rowCells = _rowCells.sort((a, b) => a.order - b.order)

  const _serviceHeaderCells = [
    { text: 'name', order: 0 },
    { text: 'consumeAmount', order: 1 },
    { text: 'amount', order: 2 },
    { text: 'onShelfDate', order: 3 },
    { text: 'offShelfDate', order: 4 },
  ]

  const _serviceRowCells = [
    { field: 'nickname', tooltip: 'name', order: 0 },
    { field: 'consumeAmount', order: 1 },
    { field: 'amount', order: 2 },
    { field: 'onShelfDate', order: 3 },
    { field: 'offShelfDate', order: 4 },
  ]

  const serviceHeaderCells = _serviceHeaderCells.map(c => { c.text = formatMessage({ id: `product.transformService.${c.text}` }); return c }).sort((a, b) => a.order - b.order)
  const serviceRowCells = _serviceRowCells.sort((a, b) => a.order - b.order)

  const _merchandiseHeaderCells = [
    { text: 'name', order: 0 },
    { text: 'consumeAmount', order: 1 },
    { text: 'sku', order: 2 },
  ]

  const _merchandiseRowCells = [
    { field: 'nickname', tooltip: 'name', order: 0 },
    { field: 'consumeAmount', order: 1 },
    { field: 'sku', order: 2 },
  ]

  const merchandiseHeaderCells = _merchandiseHeaderCells.map(c => { c.text = formatMessage({ id: `product.merchandise.${c.text}` }); return c }).sort((a, b) => a.order - b.order)
  const merchandiseRowCells = _merchandiseRowCells.sort((a, b) => a.order - b.order)


  function getCustomerTypeRuleText(customerType, rule) {
    if (!customerType || !rule) {
      return ''
    }
    const text = formatMessage({ id: `product.customerTypeRule.${rule}` })
    return `${customerTypeMapping[customerType].name}(${text})`
  }

  const formatPriceData = (product) => {
    const newData = { ...product }
    newData.useAmount = newData.useAmount ? String(newData.useAmount) : ''
    newData.customerTypeRule = getCustomerTypeRuleText(newData.customerType, newData.rule)
    return newData
  }

  const formatServiceData = (product) => {
    const newData = { ...product }
    newData.name = productMapping[newData.product].name
    newData.nickname = productMapping[newData.product].nickname
    return newData
  }

  const formatMerchandiseData = (merchandise) => {
    const newData = { ...merchandise }
    newData.name = merchandiseMapping[newData.merchandise].name
    newData.nickname = merchandiseMapping[newData.merchandise].nickname
    newData.sku = merchandiseMapping[newData.merchandise].sku
    return newData
  }

  function getDisplayFields() {
    const displayFields = [...fields]
    if (product.type === 'service') {// 服務
      displayFields.splice(6, 1)
    } else {// 商品/儲值/點數
      displayFields.splice(4, 2)
      const operatorBonusIndex = displayFields.findIndex(i => i.name === 'operatorBonus')
      if (operatorBonusIndex !== -1) {
        displayFields.splice(operatorBonusIndex, 1)
      }
    }
    return displayFields
  }

  function createField(field, value, product) {
    let newValue = value ?? ''
    if (field.type === 'select') {
      if (field.name === 'type') {
        newValue = selectMapping[field.name][value]
      } else if (field.name === 'seller') {
        newValue = (value || []).map(i => vendorMapping[i].name).join(', ')
      }
    }

    if (field.type === '-') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    } else if (field.type === 'color') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={formatMessage({ id: `product.edit.${field.name}` })}
          value={newValue}
          fullWidth
          size="small"
          variant="standard"
          readOnly
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ColorDot style={{ backgroundColor: newValue }}></ColorDot>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    }
    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={formatMessage({ id: `product.edit.${field.name}` })}
        value={newValue}
        fullWidth
        size="small"
        variant="standard"
        readOnly
      />
    </Grid>
  }


  return (
    <div style={{ padding: 15 }}>
      <Grid container spacing={2}>
        {getDisplayFields().map(field => createField(field, product[field.name], product))}
      </Grid>
      {moduleMapping.sales && <Typography variant="button" component="div" style={{ marginTop: '8px' }}>
        <FormattedMessage id="product.edit.commonPrice" />:
      </Typography>}

      {moduleMapping.sales && <EnhancedTable
        size="small"
        defaultOrder="asc"
        defaultOrderField="code"
        headerCells={headerCells}
        rowCells={rowCells}
        tableData={product.commonPrice.map(m => formatPriceData(m))}
      />}

      {moduleMapping.sales && product.type === 'pointCard' && <>
        <Typography variant="button" component="div" style={{ marginTop: '8px' }}>
          <FormattedMessage id="product.edit.transformService" />:
        </Typography>
        <EnhancedTable
          size="small"
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={serviceHeaderCells}
          rowCells={serviceRowCells}
          tableData={product.transformService.map(m => formatServiceData(m))}
        />
      </>}

      {moduleMapping.sales && product.type === 'merchandise' && <>
        <Typography variant="button" component="div" style={{ marginTop: '8px' }}>
          <FormattedMessage id="product.edit.merchandise" />:
        </Typography>
        <EnhancedTable
          size="small"
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={merchandiseHeaderCells}
          rowCells={merchandiseRowCells}
          tableData={product.merchandises.map(m => formatMerchandiseData(m))}
        />
      </>}

      {userRights.hasUserRight('product-edit') &&
        <Grid container sx={{ mt: 2 }} spacing={1}>
          <Grid item key="buttons" xs={12} sm={12} md={12}>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onEditClick(product)}
              >
                <FormattedMessage id="button.edit" />
              </Button>
            </Stack>
          </Grid>
        </Grid>
      }
    </div>
  )
}

ProductView.propTypes = {
  userRights: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired
};

export default ProductView;
