import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
// import TableCell from '@mui/material/TableCell';
// import TableRow from '@mui/material/TableRow';
// import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import EnhancedTable from 'components/EnhancedTable';
import ActionDialog from 'components/ActionDialog';
import ProgressStep from 'components/ProgressStep';
import ContextStore from 'modules/context';
import { callFunction } from 'modules/firebase';

function MerchandiseDetail({ merchandiseId, items }) {
  const { formatMessage } = useIntl()
  const headerCells = [
    // { field: 'unitPrice' },
    { field: 'amount' },
    { field: 'expectedDate' },
    { field: 'lotNumber' },
    // { field: 'date' },
    // { field: 'note' },
  ].map(c => {c.text = formatMessage({ id: `salesReturnForm.merchandise.${c.field}` });return c})

  const rowCells = [
    // { field: 'unitPrice' },
    { field: 'amount' },
    { field: 'expectedDate' },
    { field: 'lotNumber' },
    // { field: 'date' },
    // { field: 'note' },
  ]

  return (
    <EnhancedTable
      size="small"
      headerCells={headerCells}
      rowCells={rowCells}
      tableData={items.map((i, idx) => ({ ...i, key: `${merchandiseId}.${idx}` })) }
    />
  )
}

MerchandiseDetail.propTypes = {
  merchandiseId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
};

function SalesReturnFormView({ salesReturnForm, userMapping, merchandiseMapping }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)
  const [dialogData, setDialogData] = useState(null);
  const [loadingApprove, setLoadingApprove] = useState(false);

  const salesReturnFormHistory = (salesReturnForm.history || []).map(h => ({ ...h }))
  const currentStep = salesReturnFormHistory.length > 0 ? salesReturnFormHistory[salesReturnFormHistory.length - 1].step : 0

  const headerCells = [
    { field: 'code', sort: 'code' },
    { field: 'name' },
    { field: 'amount', align: 'right' },
    { field: 'orderUnit', align: 'right' },
    // { field: 'price', align: 'right' },
    { field: 'note', align: 'right' }
  ].map(c => {c.text = formatMessage({ id: `salesReturnForm.merchandise.${c.field}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    { field: 'amount', align: 'right' },
    { field: 'orderUnit', align: 'right' },
    // { field: 'price', align: 'right' },
    { field: 'note', align: 'right', maxWidth: '120px' }
  ]

  // const invoiceSubtotal = salesReturnForm.merchandises.reduce((acc, cur) => {
  //   acc += cur.unitPrice * cur.amount
  //   return acc;
  // }, 0)
  // const totalDiscount = salesReturnForm.merchandises.reduce((acc, cur) => {
  //   acc += (cur.discount || 0)
  //   return acc;
  // }, salesReturnForm.discount || 0)

  // const invoiceTaxes = Math.round(TAX_RATE * (invoiceSubtotal - totalDiscount));
  // const invoiceTotal = invoiceTaxes + invoiceSubtotal - totalDiscount;
  // const invoiceTotal = salesReturnForm.merchandises.reduce((acc, cur) => {
  //   acc += cur.price
  //   return acc;
  // }, 0)

  function formatData(merchandise) {
    const m = merchandiseMapping[merchandise.id]
    return {
      ...merchandise,
      code: m.code,
      name: m.name,
      nickname: m.nickname,
      orderUnit: merchandise.orderBySku ? m.sku : m.orderUnit,
      amount: merchandise.items.reduce((acc, cur) => acc + cur.amount, 0),
      // price: merchandise.items.reduce((acc, cur) => acc + (cur.unitPrice * cur.amount), 0)
    }
  }

  function accept() {
    setDialogData({ action: 'accept', title: '' })
  }

  function handleClose() {
    setDialogData(null)
  }

  async function handleExecute(data) {
    const { action, text } = data
    setDialogData(null)
    setLoadingApprove(true)
    try {
      await callFunction('reviewSalesReturnForm', {
        id: salesReturnForm.id,
        action,
        note: text
      })
      if (currentStep < salesReturnForm.steps.length - 1) {
        setLoadingApprove(false)
      }
    } catch (ex) {
      setLoadingApprove(false)
      console.log(ex)
    }
  }

  function decodeModifyLog(log) {
    log = log.replace(/f{modify}/g, formatMessage({ id: 'step.action.modify' }))
    log = log.replace(/f{update}/g, formatMessage({ id: 'step.action.update' }))
    log = log.replace(/f{add}/g, formatMessage({ id: 'step.action.add' }))
    log = log.replace(/f{remove}/g, formatMessage({ id: 'step.action.remove' }))

    let matchs = [...new Set((log.match(/i{.+?}/g) || []).map(i => i.substring(2, i.length - 1)))]
    for (const m of matchs) {
      log = log.replace((new RegExp(`i{${m}}`, 'g')), merchandiseMapping[m].name)
    }
    return log
  }

  const steps = [...salesReturnFormHistory].concat(salesReturnForm.status !== 'void' ? salesReturnForm.steps.slice(currentStep, salesReturnForm.steps.length).map(s => ({ ...s })) : [])
  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName || '系統自動'}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    }
  }

  return (
    <div style={{ flexGrow: 1, height: '100%' }}>
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` }) + (salesReturnForm.void ? formatMessage({ id: 'button.void' }) : '') + formatMessage({ id: 'salesReturnForm.name' })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'salesReturnForm.table.detail.note' })}
        action={dialogData.action}
      />}
      <Box p={0}>
        <ProgressStep activeStep={salesReturnForm.history.length} steps={steps} />
        <EnhancedTable
          size="small"
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={headerCells}
          rowCells={rowCells}
          getExpandContent={merchandise =>
            <MerchandiseDetail
              merchandiseId={merchandise.id}
              items={merchandise.items}
            />
          }
          tableData={salesReturnForm.merchandises.map(m => formatData(m))}
          // extRows={
          //   <TableRow>
          //     <TableCell colSpan={4}/>
          //     <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.total" /></Typography></TableCell>
          //     <TableCell align="right">{invoiceTotal}</TableCell>
          //   </TableRow>
          // }
        />

        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'salesReturnForm.table.detail.note' })}
              value={salesReturnForm.note}
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          <Grid item key="buttons" xs={12} sm={12} md={12}>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
              {currentStep > 0 && currentStep < salesReturnForm.steps.length && salesReturnForm.steps[currentStep].users.includes(currentUser.key) && salesReturnForm.status !== 'void' &&
                <LoadingButton
                  color="success"
                  onClick={accept}
                  disabled={loadingApprove}
                  loading={loadingApprove}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.accept" />
                </LoadingButton>
              }
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

// SalesReturnFormView.defaultProps = {

// }

SalesReturnFormView.propTypes = {
  salesReturnForm: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
};

export default SalesReturnFormView;
