import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import EnhancedTable from 'components/EnhancedTable';
import ApplicationSent from 'components/ApplicationSent';
import DatePickerField from 'components/DatePickerField';
import AlertDialog from 'components/AlertDialog';
import ButtonProgress from 'components/ButtonProgress';
import ContextStore from 'modules/context';
import { firestoreListener, callFunction, documentId } from 'modules/firebase';
import OvertimeWorkType from 'enum/OvertimeWorkType';

const _OvertimeType = OvertimeWorkType.concat().filter(type => type.type === 'all');;
const _OvertimeNurseType = OvertimeWorkType.concat()

const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
  '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

function EditOvertimePage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const { overtimeId, userId } = useParams()
  const userMapping = useSelector(state => state.users.data);
  const navigate = useNavigate()
  const [page, setPage] = useState('edit');
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [overtimeBalance, setOvertimeBalance] = useState([])
  const [dialogOpen, setDialogOpen] = useState(null)
  const [punchClockException, setPunchClockException] = useState({})
  const [pendingOvertimes, setPendingOvertimes] = useState([])
  // const [punchClock, setPunchClock] = useState({})
  const [overtimeData, setOvertimeData] = useState({
    overtimeType: _OvertimeType[0].value,
    startDate: dayjs(),
    startHour: '18',
    startMinute: '00',
    endDate: dayjs(),
    endHour: '23',
    endMinute: '00',
    hours: 0,
    reason: '',
  })

  const _currentUser = overtimeId !== 'new' && currentUser.key !== userId ? { key: userMapping[userId]?.id, ...userMapping[userId] } : currentUser

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.leaveOvertimeWork.owner' }),
    }, {
      text: overtimeId !== 'new' ? formatMessage({ id: 'overtimes.editOvertime' }) : formatMessage({ id: 'overtimes.applyForOvertime' }),
    }])
    return () => {
    };
  }, [overtimeId]);

  const startDateM = dayjs(overtimeData.startDate).format('YYYY-MM-DD')
  // useEffect(() => {
  //   const unsubscribe = firestoreListener({
  //     collection: 'punchClock',
  //     where: [[documentId(), '==', startDateM]],
  //     onData: (data) => {
  //       let punchClock = []
  //       data.forEach(docData => {
  //         if (docData.record && docData.record[_currentUser.key]) {
  //           punchClock = punchClock.concat(docData.record[_currentUser.key])
  //         }

  //         if (docData.revise && docData.revise[_currentUser.key]) {
  //           punchClock = punchClock.concat(docData.revise[_currentUser.key])
  //         }
  //       });

  //       setPunchClock(punchClock)
  //     }
  //   })

  //   return () => unsubscribe()
  // }, [startDateM]);

  // useEffect(() => {
  //   const unsubscribe = firebase.firestore().collection('departments').doc(currentUser.department).onSnapshot(snapshot => {
  //     const snapshots = snapshot.data()
  //     const workTimeMapping = snapshots.workTime
  //     const weekDay = getWeekDay(dayjs(startDateM))

  //     let time = ''
  //     if (workTimeMapping[weekDay]) {
  //       time = workTimeMapping[weekDay].endTime
  //     } else {
  //       time = '09:00'
  //     }

  //     setOvertimeData(data => ({
  //       ...data,
  //       startHour: time.split(':')[0],
  //       startMinute: time.split(':')[1]
  //     }))
  //   })
  //   return () => unsubscribe()
  // }, [startDateM]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'punchClockExceptions',
      where: [['date', '==', startDateM]],
      onData: (data) => {
        let time = ''
        let pcE = {}
        data.forEach(docData => {
          pcE = { ...docData }
          if (docData.type === 'on') {
            time = docData.endTime
          } else {
            time = '09:00'
          }
        })

        if (time) {
          setOvertimeData(currentData => ({
            ...currentData,
            startHour: time.split(':')[0],
            startMinute: time.split(':')[1]
          }))
        }
        setPunchClockException(pcE)
      }
    })

    return () => unsubscribe()
  }, [startDateM]);

  useEffect(() => {
    const unsubscribe = overtimeId !== 'new' ? firestoreListener({
      collection: 'overtimes',
      doc: overtimeId,
      onData: (data) => {
        const startTime = String(data.startTime).split(':')
        const endTime = String(data.endTime).split(':')
        setOvertimeData({
          overtimeType: data.type,
          startDate: dayjs(data.startDate),
          startHour: startTime[0],
          startMinute: startTime[1],
          endDate: dayjs(data.endDate),
          endHour: endTime[0],
          endMinute: endTime[1],
          hours: data.hours,
          reason: data.reason,
          snapshot: data.snapshot
        })
      }
    }) : null

    return () => unsubscribe?.()
  }, [overtimeId]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'overtimeBalances',
      doc: _currentUser.key,
      onData: (data) => {
        setOvertimeBalance(data)
      }
    })

    return () => unsubscribe()
  }, [_currentUser.key]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'overtimes',
      where: [['status', '==', 'pending'], ['createdBy', '==', _currentUser.key]],
      onData: (data) => {
        setPendingOvertimes(data)
      }
    })

    return () => unsubscribe()
  }, [_currentUser.key]);

  const headerCells = [
    { text: 'periods' },
    { text: 'totalOvertime' },
    { text: 'signPay' },
    { text: 'pay' },
    { text: 'signLeave' },
    { text: 'leave' },
    { text: 'allowance' },
  ].map(c => { c.text = formatMessage({ id: `overtimes.overtimeForm.${c.text}` }); return c })

  const rowCells = [
    { field: 'periods' },
    { field: 'totalOvertime' },
    { field: 'signPay' },
    { field: 'pay' },
    { field: 'signLeave' },
    { field: 'leave' },
    { field: 'allowance' },
  ]

  function handleClose() {
    setDialogOpen(null)
  }

  async function onOvertimeSave() {
    setLoadingApprove(true)

    if (overtimeData.reason === '') {
      setLoadingApprove(false)
      return setDialogOpen({
        title: formatMessage({ id: 'overtimes.overtimeAlert.title' }),
        message: formatMessage({ id: 'overtimes.overtimeAlert.content' })
      })
    }

    // 移除加班申請檢查punchClock
    // if (punchClock.length === 0) {
    //   setLoadingApprove(false)
    //   return setDialogOpen({
    //     title: formatMessage({ id: 'overtimes.overtimeAlert.punchTitle' }),
    //     message: formatMessage({ id: 'overtimes.overtimeAlert.punchContent' })
    //   })
    // }

    const newData = {
      type: overtimeData.overtimeType,
      startDate: dayjs(overtimeData.startDate).format('YYYY-MM-DD'),
      startTime: `${overtimeData.startHour}:${overtimeData.startMinute}`,
      endDate: dayjs(overtimeData.endDate).format('YYYY-MM-DD'),
      endTime: `${overtimeData.endHour}:${overtimeData.endMinute}`,
      reason: overtimeData.reason,
      hours: hoursToPeriods / 2,
    }

    if (dayjs(overtimeData.startDate).day() === 0 || punchClockException.publicHoliday) {
      newData.publicHoliday = true
    }

    // if (newData.endTime > punchClock[punchClock.length - 1]) {
    //   setLoadingApprove(false)
    //   return setDialogOpen({
    //     title: formatMessage({ id: 'overtimes.overtimeAlert.timeTitle' }),
    //     message: formatMessage({ id: 'overtimes.overtimeAlert.timeContent' })
    //   })
    // }

    try {
      await callFunction('saveOvertimes', { id: overtimeId, ...newData })
      setLoadingApprove(false)
    } catch (ex) {
      console.log(ex)
    }

    setPage('done')
  }

  function timeRangeToPeriods() {
    const startDate = dayjs(overtimeData.startDate).format('YYYY-MM-DD')
    const startTime = `${overtimeData.startHour}:${overtimeData.startMinute}`
    const endDate = dayjs(overtimeData.endDate).format('YYYY-MM-DD')
    const endTime = `${overtimeData.endHour}:${overtimeData.endMinute}`
    const start = `${startDate} ${startTime}`;
    const end = `${endDate} ${endTime}`;
    const reg = /\d+-\d+-\d+ \d+:\d+/;

    if (reg.test(start) && reg.test(end)) {
      const startM = dayjs(start, 'YYYY-MM-DD HH:mm');
      const endM = dayjs(end, 'YYYY-MM-DD HH:mm');
      dayjs.extend(duration)
      const minutes = dayjs.duration(endM.diff(startM)).asMinutes();
      var periods = 0;
      if (minutes >= 30) {
        periods = Math.floor(minutes / 15) / 2.0;
      }
      return periods;
    }
  }

  const hoursToPeriods = timeRangeToPeriods();

  function handleDateChange(filed, date) {
    if (date === null || date.toString() === 'Invalid Date') {
      updateData(filed, '')
    } else {
      updateData(filed, date);
    }
  };

  function validateField(field, value) {
    if ((field.name === 'startDate' || field.name === 'endDate') && value === '') {
      return '無效的日期格式'
    }
    return ''
  }

  function updateData(field, value) {
    let newValue = value

    let err = validateField(field, value)

    let newData = { ...overtimeData, [field.name]: newValue, [`${field.name}_err`]: err }
    if (field.name === 'startDate') {
      newData.endDate = value
    }

    setOvertimeData(newData)
  }

  const BalanceRow = () => {
    if (overtimeBalance.pay === undefined || overtimeBalance.leave === undefined) return null;
    let signing = {
      pay: 0,
      leave: 0,
      shift: 0
    }
    for (const s of pendingOvertimes) {
      signing[s.type.replace('overtime_', '')] += s.hours
    }

    let pay = overtimeBalance.pay.balance * 2
    let leave = overtimeBalance.leave.balance * 2
    let shift = overtimeBalance.shift.balance
    let signPay = signing.pay * 2
    let signLeave = signing.leave * 2
    let periods = parseFloat(hoursToPeriods);
    let overtime = pay + leave + signPay + signLeave;

    if (overtimeData && !['approved', 'rejected'].includes(overtimeData.status)) {
      let count = 0
      if (overtimeId !== 'new' && overtimeData.snapshot) {
        count = (overtimeData.snapshot.hours * 2)
      }

      if (overtimeData.overtimeType === 'overtime_pay') {
        pay = `${pay + signPay + periods - count}`;
      } else if (overtimeData.overtimeType === 'overtime_leave') {
        leave = `${leave + signLeave + periods - count}`;
      } else if (overtimeData.overtimeType === 'overtime_shift') {
        let shiftperiods = 1
        shift = `${shift + signing.shift + shiftperiods}`;
        leave = `${leave + signLeave + periods - count}`;
      }
    }
    const data = [{
      id: 'overtime',
      periods,
      totalOvertime: overtime,
      signPay,
      pay,
      signLeave,
      leave,
      allowance: shift
    }]
    return (
      <EnhancedTable
        size="small"
        headerCells={headerCells}
        rowCells={rowCells}
        tableData={data}
      />
    )
  };

  return page === 'edit' ? (
    <div>
      {dialogOpen && <AlertDialog
        dialogTital={dialogOpen.title}
        dialogMsg={dialogOpen.message}
        handleClose={handleClose}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  select
                  type="text"
                  size="small"
                  label={formatMessage({ id: 'overtimes.overtimeForm.convert' })}
                  variant="outlined"
                  fullWidth
                  onChange={e => updateData({ name: 'overtimeType' }, e.target.value)}
                  value={overtimeData.overtimeType}
                >
                  {_currentUser.department !== 'nurse' ? _OvertimeType.map((type, idx) => {
                    return <MenuItem key={`${type.value}-${idx}`} value={type.value}>{type.name}</MenuItem>
                  }) : _OvertimeNurseType.map((type, idx) => {
                    return <MenuItem key={`${type.value}-${idx}`} value={type.value}>{type.name}</MenuItem>
                  })}
                </TextField>
              </Grid>
              <Box width="100%" />
              <Grid item xs={12} sm={6} md={6}>
                <DatePickerField
                  required
                  fullWidth
                  label={formatMessage({ id: 'overtimes.overtimeForm.startDateTime' })}
                  value={overtimeData.startDate}
                  onChange={date => handleDateChange({ name: 'startDate' }, date)}
                  invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
                  // maxDate={dayjs().subtract(1, 'day').toDate()}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <TextField
                  select
                  type="text"
                  size="small"
                  variant="outlined"
                  label={formatMessage({ id: 'overtimes.overtimeForm.overtimeHour' })}
                  fullWidth
                  value={overtimeData.startHour}
                  onChange={e => updateData({ name: 'startHour' }, e.target.value)}
                >
                  {hours.map((hour, idx) => {
                    return <MenuItem key={`${idx}`} value={hour}>{hour}</MenuItem>
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <TextField
                  select
                  type="text"
                  size="small"
                  variant="outlined"
                  label={formatMessage({ id: 'overtimes.overtimeForm.overtimeMinute' })}
                  fullWidth
                  value={overtimeData.startMinute}
                  onChange={e => updateData({ name: 'startMinute' }, e.target.value)}
                >
                  {minutes.map((minute, idx) => {
                    return <MenuItem key={`${idx}`} value={minute}>{minute}</MenuItem>
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <DatePickerField
                  required
                  fullWidth
                  label={formatMessage({ id: 'overtimes.overtimeForm.endDateTime' })}
                  value={overtimeData.endDate}
                  onChange={date => handleDateChange({ name: 'endDate' }, date)}
                  minDate={dayjs(overtimeData.startDate).toDate()}
                  minDateMessage={formatMessage({ id: 'form.date.beforeStartDate' })}
                  invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
                  // minDate={new Date()}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <TextField
                  select
                  type="text"
                  size="small"
                  variant="outlined"
                  label={formatMessage({ id: 'overtimes.overtimeForm.overtimeHour' })}
                  fullWidth
                  value={overtimeData.endHour}
                  onChange={e => updateData({ name: 'endHour' }, e.target.value)}
                >
                  {hours.map((hour, idx) => {
                    return <MenuItem key={`${idx}`} value={hour}>{hour}</MenuItem>
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <TextField
                  select
                  type="text"
                  size="small"
                  variant="outlined"
                  label={formatMessage({ id: 'overtimes.overtimeForm.overtimeMinute' })}
                  fullWidth
                  value={overtimeData.endMinute}
                  onChange={e => updateData({ name: 'endMinute' }, e.target.value)}
                >
                  {minutes.map((minute, idx) => {
                    return <MenuItem key={`${idx}`} value={minute}>{minute}</MenuItem>
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div style={{ display: 'flex' }}>
                  <div>{formatMessage({ id: 'overtimes.overtimeForm.hours' })}</div>
                  <div>{hoursToPeriods / 2}</div>
                  <div style={{ marginLeft: '10px' }}>{formatMessage({ id: 'overtimes.overtimeForm.periods' })}</div>
                  <div>{hoursToPeriods}</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  type="text"
                  size="small"
                  variant="outlined"
                  fullWidth
                  multiline
                  label={formatMessage({ id: 'overtimes.overtimeForm.reason' })}
                  value={overtimeData.reason}
                  onChange={e => updateData({ name: 'reason' }, e.target.value)}
                >
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} />
        </Grid>
        <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 500 }} component="div">
            <FormattedMessage id="overtimes.overtimeForm.currentBalances" />:
          </Typography>
        </div>
        <BalanceRow />
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <ButtonProgress
            handleClose={() => navigate('/leaveOvertimeWork/overtimes')}
            handleClick={() => onOvertimeSave()}
            loading={loadingApprove}
            buttonText={'button.submit'}
          />
        </Stack>
      </Box>
    </div>
  ) : (
    <ApplicationSent
      title={'overtimeSent.overtimeApplicationSent'}
      message={'overtimeSent.sentMessage'}
      buttonText={'overtimeSent.newBack'}
      buttonClick={'/leaveOvertimeWork/overtimes'}
    />
  );
}

export default EditOvertimePage;
