import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl'
import { createUseStyles } from 'react-jss';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '120px',
    // height: 'calc(100% - 64px)',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.9px',
    color: '#888888',
    zIndex: 1,
    paddingBottom: '32px',
    overflow: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  typeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    minHeight: '120px',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.9px',
    color: '#888888',
    borderBottom: '1px solid #dddde7',
    marginBottom: '0px',
    '& .doctors-0, .cancelled': {
      minHeight: '119px',
      maxHeight: '120px',
    },
    '& .doctors-1': {
      minHeight: '120px',
      maxHeight: '120px'
    },
    '& .doctors-2': {
      minHeight: '240px'
    },
    '& .doctors-3': {
      minHeight: '360px'
    },
    '& .doctors-4': {
      minHeight: '480px'
    },
    '& .doctors-5': {
      minHeight: '600px'
    }
  },
  doctor: {
    display: 'flex',
    minHeight: '120px',
    alignItems: 'center',
    border: '1px solid #dddde7',
    justifyContent: 'center',
    borderTop: 'none',
    borderRight: 'none',
    '&:last-child': {
      minHeight: '119px',
      borderBottom: 'none'
    }
  },
  typeLabel: {
    display: 'flex',
    minWidth: '40px',
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '120px',
    maxHeight: '120px',
    padding: '8px',
    wordBreak: 'break-all',
    borderBottom: '1px solid #dddde7',
  },
  doctorWrap: {
    flexDirection: 'column',
    minWidth: '80px',
    alignItems: 'stretch',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '120px',
    color: '#888888',
  },
  wrapperMobile: {
    width: '100vw',
    height: '60px',
    overflowY: 'hidden',
    overflowX: 'scroll',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 9,
    position: 'fixed',
    top: '90px',
    left: 0
  },
  mobileButton: {
    width: '76px',
    minWidth: '76px',
    height: '60px',
    backgroundColor: '#ffffff',
    color: '#888888',
    fontSize: '14px',
    letterSpacing: '0.9px',
    padding: '10px',
    wordWrap: 'break-word',
    textAlign: 'center',
    borderRight: '1px solid #e4e4e4'
  }
});

function SalesRepList({ doctors, salesReps, ui, rowHeights, setUiState }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const classes = useStyles();
  const cancelledRowHeight = Math.max(120, rowHeights.cancelled?.minHeight ?? 0)
  const salesRepMapping = salesReps.reduce((acc, cur) => {
    acc[cur.id] = cur.displayName
    return acc
  }, { noSalesRep: '無服務人員' })

  if (!doctors) {
    return ('Loading...')
  }
  const salesRepIds = ['noSalesRep'].concat(salesReps.map(i => i.id))

  if (isMobile) {
    const viewingSalesRep = ui.viewingSalesRep || (salesReps[0]?.id ?? undefined)
    return (
      <div className={classes.wrapperMobile}>
        {salesRepIds.map(id => (
          <div
            key={id}
            className={classes.mobileButton}
            onClick={() => setUiState({
              ...ui,
              viewingSalesRep: id
            })}
            style={{
              borderBottom: viewingSalesRep === id ? '2px solid blue' : ''
            }}
          >
            {salesRepMapping[id]}
          </div>
        )).concat([
          <div
            key='cancelled'
            className={classes.mobileButton}
            onClick={() => setUiState({
              ...ui,
              viewingSalesRep: 'cancelled'
            })}
            style={{
              borderBottom: viewingSalesRep === 'cancelled' ? '2px solid red' : ''
            }}
          >
            <FormattedMessage id='appointment.sidebar.cancelled' />
          </div>
        ])}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {salesRepIds.map(id => {
        const rowHeight = rowHeights[id]?.minHeight || 120
        return (
          <div
            key={id}
            className={classes.typeLabel}
            style={{
              minHeight: rowHeight
            }}
          >
            {salesRepMapping[id]}
          </div>
        )
      })}
      <div
        className={`${classes.typeContainer} cancelled`}
        style={{
          minHeight: `${cancelledRowHeight}px`
        }}
      >
        <div
          className={classes.typeLabel}
          style={{
            writingMode: 'unset',
            textOrientation: 'unset',
          }}
        >
          <FormattedMessage id='appointment.sidebar.cancelled' />
        </div>
      </div>
    </div>
  )
}

SalesRepList.propTypes = {
  doctors: PropTypes.arrayOf(PropTypes.object.isRequired),
  salesReps: PropTypes.arrayOf(PropTypes.object.isRequired),
  ui: PropTypes.object.isRequired,
  rowHeights: PropTypes.object.isRequired,
  setUiState: PropTypes.func.isRequired
};

export default SalesRepList;
