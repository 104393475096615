import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

const DividerText = styled('div')(() => ({
  position: 'absolute',
  top: '7px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

function ReviewStaff({ user }) {
  const { formatMessage } = useIntl()
  const vendorMapping = useSelector(state => state.vendors.data)
  const config = useSelector(state => state.config.data)
  const appointmentTypeMapping = Object.keys(config.appointmentType || {})
    .map(i => config.appointmentType[i].active && config.appointmentType[i])
    .reduce((acc, cur) => {
      acc[cur.id] = cur
      return acc
    }, {})

  const _fields = [
    { name: 'name', sm: 6, order: 0 },
    { name: 'gender', sm: 6, roots: true, order: 1 },
    { name: 'employeeId', sm: 6, order: 2 },
    { name: 'email', sm: 6, order: 3 },
    { name: 'dateline', text: ' ', type: '-', order: 4 },
    { name: 'onBoardingDate', sm: 6, order: 5 },
    { name: 'typeline', text: ' ', type: '-', order: 7 },
    { name: 'department', sm: 6, order: 8 },
    { name: 'position', sm: 6, roots: true, order: 9 },
    { name: 'company', sm: 6, order: 10 },
  ]

  if (user.department === 'doctor') {
    _fields.push({ name: 'appointmentType', sm: 6, multiline: false, md: 3, order: 11 })
  }

  if (!user.active) {
    _fields.push({ name: 'endOn', sm: 6, multiline: false, md: 3, order: 6 })
  }

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  function createField(field, value, user) {
    let newValue = value
    let labelText = ''

    if (field.name === 'department') {
      newValue = user.departmentName
    } else if (field.name === 'appointmentType') {
      newValue = appointmentTypeMapping[newValue] ? appointmentTypeMapping[newValue].name : ''
    } else if (field.name === 'company') {
      newValue = (newValue || []).map(u => vendorMapping[u]?.name).join(', ')
    }

    if (field.type === '-') {
      if (field.text) {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12} sx={{ position: 'relative' }}>
          <Divider />
          <DividerText>
            <Typography color="textSecondary" variant="caption">{field.text}</Typography>
          </DividerText>
        </Grid>
      } else {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
      }
    }



    if (field.roots) {
      labelText = formatMessage({ id: `staff.profile.${field.name}.roots` })
    } else if (field.benefit) {
      labelText = formatMessage({ id: `staff.profile.benefit.${field.name}` })
    } else {
      labelText = formatMessage({ id: `staff.profile.${field.name}` })
    }

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={labelText}
        value={newValue}
        fullWidth
        variant="standard"
        size="small"
        margin="dense"
        InputProps={{
          readOnly: true,
        }}
      />
    </Grid>
  }

  return (
    <div style={{ padding: 15 }}>
      <Grid container spacing={2}>
        {fields.map(field => createField(field, user[field.name], user))}
      </Grid>
    </div>
  );
}

ReviewStaff.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ReviewStaff;
