const i18n = {
  'setAppointment': {
    'title': '預約表',
    'treatments': {
      'table': {
        'title': '固定服務',
        'header': {
          'name': '名稱',
          'color': '顏色',
          'duration': '服務時間',
          'status': '狀態'
        },
      },
    },
    'rooms': {
      'table': {
        'title': '診間',
        'header': {
          'name': '名稱',
          'note': '說明',
          'status': '狀態'
        },
      },
    },
    'salesReps': {
      'table': {
        'title': '服務人員',
        'header': {
          'department': '部門',
          'status': '狀態'
        },
      },
    }
  },
}
export default i18n
