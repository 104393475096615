import React, { useEffect, useState, useContext, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import LoadingIndicator from 'components/LoadingIndicator';
import SelectVerdorDialog from 'components/SelectVerdorDialog';
import Editor from 'components/Editor';
import FilePicker from 'components/FilePicker';
import ContextStore from 'modules/context';
import { getStorageURL, firestoreListener, callFunction } from 'modules/firebase';

function Announcement() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const editorRef = useRef()
  const urlMappingRef = useRef({})
  const userRights = useSelector(state => state.userRights)
  const vendorMapping = useSelector(state => state.internalVendors.data)
  const vendors = useSelector(state => state.internalVendors.ordered)
  const location = useLocation()
  const { announcementId } = useParams()
  const [data, setData] = useState(null);
  const [openDialog, setOpenDialog] = useState('');
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const breadcrumbs = [{
      link: '/announcement/list',
      text: formatMessage({ id: 'sideMenu.announcement.list' })
    }]
    if (announcementId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'announcement.add' }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: 'announcement.edit' }) })
    }

    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    if (announcementId === 'new') {
      // 注意: Editor 元件的 content 只會在第一次 render 時被 assign, 之後再變更也不會更新.
      setData({
        id: announcementId,
        target: [],
        note: '',
        subject: '',
        // content: '<h2 style="text-align: center">Hey there 👋</h2><p>This is a <em>basic</em> example of <code>mui-tiptap</code>, which combines <a target="_blank" rel="noopener noreferrer nofollow" href="https://tiptap.dev/">Tiptap</a> with customizable <a target="_blank" rel="noopener noreferrer nofollow" href="https://mui.com/">MUI (Material-UI)</a> styles, plus a suite of additional components and extensions! Sure, there are <strong>all <em>kinds</em> of <s>text</s> <u>formatting</u> options</strong> you’d probably expect from a rich text editor. But wait until you see the <span data-type="mention" data-id="15" data-label="Axl Rose">@Axl Rose</span> mentions and lists:</p><ul><li><p>That’s a bullet list with one …</p></li><li><p>… or two list items.</p></li></ul><p>Isn’t that great? And all of that is editable. <strong><span style="color: #ff9900">But wait, </span><span style="color: #403101"><mark data-color="#ffd699" style="background-color: #ffd699; color: inherit">there’s more!</mark></span></strong> Let’s try a code block:</p><pre><code class="language-css">body {\n  display: none;\n}</code></pre><p></p><p>That’s only the tip of the iceberg. Feel free to add and resize images:</p><p>Organize information in tables:</p><table><tbody><tr><th colspan="1" rowspan="1"><p>Name</p></th><th colspan="1" rowspan="1"><p>Role</p></th><th colspan="1" rowspan="1"><p>Team</p></th></tr><tr><td colspan="1" rowspan="1"><p>Alice</p></td><td colspan="1" rowspan="1"><p>PM</p></td><td colspan="1" rowspan="1"><p>Internal tools</p></td></tr><tr><td colspan="1" rowspan="1"><p>Bob</p></td><td colspan="1" rowspan="1"><p>Software</p></td><td colspan="1" rowspan="1"><p>Infrastructure</p></td></tr></tbody></table><p></p><blockquote><p>Wow, that’s amazing. Good work! 👏 <br>— Mom</p></blockquote><p>Give it a try and click around!</p>',
        content: '',
        attachments: [],
      })
    }

    const unsubscribe = (announcementId !== 'new' && userRights.hasUserRight('announcement-create')) ? firestoreListener({
      collection: 'announcements',
      doc: announcementId,
      unwrap: true,
      onData: (data) => {
        // 要先確認目前使用者有編輯此公告的權限
        if (!data.steps[0].users.includes(currentUser.key)) {
          navigate('/');
        }
        const newData = { ...data }
        // 把 content 從 storage 裡讀出來.
        getStorageURL(`announcements/${newData.id}/content`).then((url) => {
          const xhr = new XMLHttpRequest();
          xhr.addEventListener('load', (e) => {
            const promises = []
            newData.content = xhr.responseText
            // 把 content 中的圖片 src 轉換成可用的圖片 url
            for(const i of newData.files) {
              promises.push(getStorageURL(`announcements/${newData.id}/${i.md5}.${i.ext}`))
            }
            Promise.all(promises).then(urls => {
              // 這裡要把 替換後的網址 和 原本的md5 作 mapping,
              // 因為編輯儲存後, 要再把 替換後的網址 換回 原本的md5
              // 此處把 '&' 替換成 '&amp;' 是因為網址進到編輯器後再次取回來時會自動作這個轉換
              for(const i in newData.files) {
                urlMappingRef.current[urls[i].replace('&', '&amp;')] = newData.files[i].md5
                newData.content = newData.content.replace(newData.files[i].md5, urls[i])
              }
              setData(newData)
            })
          });
          xhr.open('GET', url);
          xhr.send();
        })
      }
    }) : null

    return () => unsubscribe?.()
  }, [announcementId]);

  const onTargetChanged = (vendors) => {
    if (vendors.id) {
      updateData({ name: 'target' }, [vendors.id]);
    }
  }

  function updateData(field, value) {
    let newValue = value
    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }
    if (field.maxLength) {
      newValue = newValue.substring(0, field.maxLength)
    }

    if (newValue === undefined || data[field.name] === newValue) return;

    // let err = validateField(field, value)
    // let newData = { ...data, [field.name]: newValue, [`${field.name}_err`]: err }

    const newData = { ...data, [field.name]: newValue }

    setData(newData)
  }

  function handleClose() {
    navigate('/announcement');
  }

  function onRemoveFile(index) {
    const attachments = [ ...data.attachments ]
    attachments.splice(index, 1)
    updateData({ name: 'attachments' }, attachments)
  }

  function onRemoveAllFiles() {
    updateData({ name: 'attachments' }, [])
  }

  function onAddFiles(files) {
    const attachments = [ ...data.attachments ]
    const promises = []
    for (const file of files) {
      promises.push(getBase64FromFile(file))
    }
    Promise.all(promises).then(list => {
      updateData({ name: 'attachments' }, attachments.concat(list))
    })
  }

  async function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({ fileName: file.name, base64: reader.result });
      }
      reader.readAsDataURL(file);
    })
  }

  async function getBase64FromURI(uri) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.addEventListener('load', (e) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          URL.revokeObjectURL(uri);
          resolve(reader.result);
        }
        reader.readAsDataURL(xhr.response);
      });
      xhr.open('GET', uri.split('"')[1]);
      xhr.responseType = 'blob';
      xhr.send();
    })
  }

  async function handleSave() {
    setLoading(true)
    let content = editorRef.current.getContent()

    // 嘗試修正 Editor 產生的內容 - start
    // 這段不是必要的, 因為不知道這個修正會不會產生別的問題, 所以先關掉
    // content = content.replace(/style="aspect-ratio: (\d+\.\d+)"/g, (match, p1) => {
    //   const n = parseFloat(p1)
    //   if (n < 0) {
    //     return `style="aspect-ratio: ${parseFloat(p1).toFixed(6).replace(/0+$/, '')} / 1"`
    //   }
    //   return `style="aspect-ratio: ${parseFloat(p1).toFixed(5).replace(/0+$/, '')} / 1"`
    // });
    // 嘗試修正 Editor 產生的內容 - end

    // 把 替換後的網址 換回 原本的md5
    for (const i of Object.keys(urlMappingRef.current || {})) {
      content = content.replace(`src="${i}"`, `src="${urlMappingRef.current[i]}"`)
    }

    const r = /src="blob:https?:\/\/.+?"/g
    const m = content.match(r)
    const promises = []
    if (m) {
      for(const i of m) {
        promises.push(getBase64FromURI(i))
      }
    }
    const files = await Promise.all(promises)

    try {
      // console.log(JSON.stringify({ ...data, content, files }, null, 4))
      await callFunction('saveAnnouncementV2', { announcementId, ...data, content, files })
    } catch (ex) {
      console.log(ex)
    }
    handleClose()
  }

  if (!data) {
    return <LoadingIndicator />
  }

  return <Box p={2} sx={{ height: 'calc(100vh - 64px)', overflow: 'hidden', position: 'relative', pb: '64px' }}>
    {openDialog === 'target' && <SelectVerdorDialog
      multiSelect={false}
      headerCells={[
        { name: 'code', sort: 'code' },
        { name: 'name' },
        { name: 'nickname' },
        { name: 'businessNumber' }
      ]}
      rowCells={[
        { field: 'code' },
        { field: 'name' },
        { field: 'nickname' },
        { field: 'businessNumber' }
      ]}
      filterItems={[{ name: 'name' },{ name: 'nickname' },{ name: 'businessNumber' }]}
      dialogTitle={formatMessage({ id: 'selectAnnouncementTargetDialog.title' })}
      tableTitle={formatMessage({ id: 'selectAnnouncementTargetDialog.table.title' })}
      handleClose={() => setOpenDialog('')}
      handleSave={onTargetChanged}
      defaultSelectedItems={data.target}
      items={vendors.filter(i => userRights['announcement-create'].includes(i.id)) }
    />}
    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '0.5rem', 'gap': '1rem', height: '100%', overflow: 'scroll' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            disabled={announcementId !== 'new'}
            required={true}
            type="text"
            size="small"
            label={formatMessage({ id: 'announcement.table.target' })}
            variant="outlined"
            onClick={() => setOpenDialog('target')} //open popup
            value={(data.target || []).map(i => vendorMapping[i].name).join(', ')}
            error={data.target_err ? true : false}
            helperText={data.target_err}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            type="text"
            label={formatMessage({ id: 'announcement.table.subject' })}
            variant="outlined"
            value={data.subject}
            onChange={e => updateData({ name: 'subject' }, e.target.value)}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <FilePicker
            label={formatMessage({ id: 'announcement.table.attachment' })}
            value={data.attachments}
            onRemoveFile={onRemoveFile}
            onRemoveAllFiles={onRemoveAllFiles}
            onAddFiles={onAddFiles}
          />
        </Grid>
      </Grid>
      <Editor ref={editorRef} readonly={false} content={data.content} placeholder={formatMessage({ id: 'announcement.editor.placeholder' })}/>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            type="text"
            label={formatMessage({ id: 'announcement.table.note' })}
            variant="outlined"
            value={data.note}
            onChange={e => updateData({ name: 'note' }, e.target.value)}
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>

      <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
        <Button variant="contained" color="primary" onClick={() => handleClose()}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          onClick={handleSave}
          disabled={false}
          loading={loading}
          loadingPosition="start"
          loadingIndicator={<CircularProgress size={24} />}
          startIcon={<div />}
          variant="contained"
        >
          <FormattedMessage id={true ? 'button.submit' : 'button.save'} />
        </LoadingButton>
      </Stack>
    </div>
  </Box>
}

export default Announcement;