import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import EnhancedTable from 'components/EnhancedTable';
import { weekdays, weekdayName } from 'constants';

function WorktimePolicyView({ worktimePolicy, onEditClick }) {
  const { formatMessage } = useIntl()
  const userRights = useSelector(state => state.userRights)

  const headerCells = [
    { text: 'day', align: 'left' },
    { text: 'workytime' },
    { text: 'workhours' },
  ].map(c => {c.text = formatMessage({ id: `setAttendance.worktimePolicy.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'day', align: 'left' },
    { field: 'workytime' },
    { field: 'workhours' },
  ]

  const items = weekdays.map(day => {
    const dd = worktimePolicy.worktime[day]
    return {
      key: day,
      day: weekdayName[day],
      workytime: dd ? `${dd.startTime} 上班；${dd.endTime} 下班` : '休假',
      workhours: dd ? `${dd.maxHours} 小時` : '',
    }
  })

  function getPolicyData() {
    const data = {
      ...worktimePolicy,
      worktime: { ...worktimePolicy.worktime },
    }
    weekdays.forEach(d => {
      if (!data.worktime[d]) {
        data.worktime[d] = { startTime: null, endTime: null }
      }
    })
    return data
  }

  return (
    <div>
      <EnhancedTable
        size="small"
        headerCells={headerCells}
        rowCells={rowCells}
        tableData={items}
      />
      {userRights.hasUserRight('setting-edit') && <Grid container sx={{ mt: 2 }} spacing={1}>
        <Grid item key="buttons" xs={12} sm={12} md={12}>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onEditClick(getPolicyData())}
            >
              <FormattedMessage id="button.edit" />
            </Button>
          </Stack>
        </Grid>
      </Grid>}
    </div>
  )
}

WorktimePolicyView.propTypes = {
  worktimePolicy: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default WorktimePolicyView;
