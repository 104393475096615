import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import MuiFab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';

import EnhancedTable from 'components/EnhancedTable';

const Fab = styled(MuiFab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}));

function SettingMerchandisePage({ tableCells, tableName }) {
  const { formatMessage } = useIntl()
  const userRights = useSelector(state => state.userRights)
  const config = useSelector(state => state.config.data)
  const data = Object.keys(config[tableName] || {}).map(i => config[tableName][i])

  const headerCells = tableCells.reduce((acc, cur) => {
    acc.push({ text: cur, align: 'left' })

    return acc
  }, []).concat({ text: 'status', align: 'right' }).map(c => { c.text = formatMessage({ id: `settingMerchandise.${tableName}.table.${c.text}` }); return c })

  const rowCells = tableCells.reduce((acc, cur) => {
    acc.push({ field: cur, align: 'left' })
    return acc
  }, []).concat({ field: 'status', align: 'right' })

  const formatData = (data) => {
    const newData = { ...data }
    newData.key = data.id
    newData.status = data.active ? '啟用' : '停用'
    return newData
  }

  return (
    <Box style={{ marginTop: '20px' }}>
      {userRights.hasUserRight('setting-edit') &&
        <Link to={`/setting/merchandise/${tableName}/edit`}>
          <Fab color="primary">
            <EditIcon />
          </Fab>
        </Link>
      }
      <EnhancedTable
        defaultOrder="asc"
        defaultOrderField="code"
        headerCells={headerCells}
        rowCells={rowCells}
        tableData={data.map(i => formatData(i))}
      />
    </Box>
  );
}

SettingMerchandisePage.propTypes = {
  tableName: PropTypes.string.isRequired,
};

export default SettingMerchandisePage;
