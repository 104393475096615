import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-tw'

import { styled } from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import CalendarPicker from '@mui/lab/CalendarPicker';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import Menu from '@mui/material/Menu';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import ContextStore from 'modules/context';
import { moibleMedia } from 'constants';

const DateButton = styled(Button)(({ theme }) => ({
  borderLeft: '1px solid #FFF',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '4px 0px',

  [moibleMedia]: {
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: '0.75rem',
    flexDirection: 'row',
  },
  color: '#ffffff',
}));

function AppointmentDate() {
  const { uiState, setUiState } = useContext(ContextStore)
  const [dateAnchorEl, setDateAnchorEl] = useState(null)

  const handleDateClose = () => {
    setDateAnchorEl(null);
  };

  const changeDate = (event) => {
    let newDate = ''
    if (event === 'before') {
      newDate = dayjs(uiState.date).subtract(1, 'day')
    } else if (event === 'after') {
      newDate = dayjs(uiState.date).add(1, 'day')
    } else {
      newDate = dayjs(event)
      handleDateClose()
    }

    setUiState({
      ...uiState,
      date: newDate
    })
  }

  return (
    <>
      <Menu
        id="menu-date"
        sx={{ zIndex: 2100 }}
        anchorEl={dateAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!dateAnchorEl}
        onClose={handleDateClose}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} locale={'zh-tw'}>
          <CalendarPicker
            date={uiState.date}
            onChange={(event) => changeDate(event)}
          />
        </LocalizationProvider>
      </Menu>
      <ButtonGroup fw="ture" size="small" color="primary" variant="text" style={{ border: '1px solid #FFF' }} aria-label="contained primary button group">
        <DateButton onClick={() => changeDate('before')}><ChevronLeftIcon /></DateButton>
        <DateButton style={{ flexGrow: 100 }} onClick={(event) => setDateAnchorEl(event.currentTarget)}>
          <div style={{ whiteSpace: 'nowrap' }}>{dayjs(uiState.date).locale('zh-tw').format('YYYY-M-D')}</div>
          <div>{dayjs(uiState.date).locale('zh-tw').format('(ddd)')}</div>
        </DateButton>
        <DateButton onClick={() => changeDate('after')}><ChevronRightIcon /></DateButton>
        <DateButton onClick={() => setUiState({ ...uiState, date: dayjs() })}><FormattedMessage id="header.appointment.today" /></DateButton>
      </ButtonGroup>
    </>
  )
}

export default AppointmentDate;
