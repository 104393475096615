const i18n = {
  'transferService': {
    'add': '新增轉讓單',
    'table': {
      'title': '轉讓單列表',
      'header': {
        'sn': '轉讓單號',
        'date': '日期',
        'createdBy': '建立者',
        'from': '轉出',
        'to': '轉入',
      },
      'detail': {
        'source': '公司',
        'customer': '客戶',
        'newCustomer': '欲轉讓客戶',
        'createdBy': '建立者',
        'date': '日期',
        'note': '備註',
      }
    },
    'service': {
      'name': '服務名稱',
      'quantityTaken': '可用數量/購買數量',
      'sn': '營收單號',
      'amount': '轉讓數量'
    },
    'filter': {
      'sn': '轉讓單號',
      'date': '日期',
      'customer': '客戶',
      'createdBy': '建立者',
      'note': '轉讓單備註',
    },
  },
  'editTransferServicePage': {
    'addService': '新增服務',
    'removeService': '移除服務',
    'createSalesOrder': '建立營收單',
    'consumed': '已消耗',
    'table': {
      'title': '服務列表'
    }
  },
}
export default i18n
