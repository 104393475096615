import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import WorkflowSteps from 'components/WorkflowSteps';

function ReviewPolicyView({ reviewPolicy, onEditClick }) {
  const userMapping = useSelector(state => state.users.data)
  const userRights = useSelector(state => state.userRights)

  const steps = []
  for (const step of reviewPolicy.steps) {
    steps.push({
      name: step.name,
      rule: step.rule,
      users: step.users.map(u => ({ id: u, name: userMapping[u] ? userMapping[u].displayName : '' }))
    })
  }

  return (
    <div>
      <WorkflowSteps steps={steps} />
      {userRights.hasUserRight('setting-edit') && <Grid container spacing={1}>
        <Grid item key="buttons" xs={12} sm={12} md={12}>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onEditClick(reviewPolicy)}
            >
              <FormattedMessage id="button.edit" />
            </Button>
          </Stack>
        </Grid>
      </Grid>}
    </div>
  )
}

ReviewPolicyView.propTypes = {
  reviewPolicy: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default ReviewPolicyView;
