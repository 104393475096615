import React, { useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import TabContainer from 'containers/TabContainer/TabContainer';
import ReviewLeavesList from 'pages/Leaves/ReviewLeavesList';
import ReviewOvertimesList from 'pages/Overtimes/ReviewOvertimesList';
import ReviewPunchClockReviseList from 'pages/PunchClockRevise/ReviewPunchClockReviseList';
import ContextStore from 'modules/context';
import { useModuleMapping } from 'hooks/modules';

const Root = styled('div')(() => ({
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

function LopOverview() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const moduleMapping = useModuleMapping()

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.leaveOvertimeWork.overview' })
    }])
    return () => {
    };
  }, []);

  const tabs = [];
  if (userRights.hasUserRight('leaveOvertime-overview') && moduleMapping.leaveOvertime) { // 修正權限檢查
    tabs.push({ label: formatMessage({ id: 'tab.secondaryNav.allLeave' }), component: <ReviewLeavesList isAll={true} /> })
    tabs.push({ label: formatMessage({ id: 'tab.secondaryNav.allOvertime' }), component: <ReviewOvertimesList isAll={true} /> })
  }
  if (userRights.hasUserRight('punchClockRevise-overview') && moduleMapping.punchClock) { // 修正權限檢查
    tabs.push({ label: formatMessage({ id: 'tab.secondaryNav.allPunchClockRevise' }), component: <ReviewPunchClockReviseList isAll={true} /> })
  }

  return (
    <Box p={3}>
      <Root>
        <TabContainer defaultSelect={0} tabs={tabs} />
      </Root>
    </Box>
  );
}

export default LopOverview;
