import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

// import Grid from '@mui/material/Grid';

import DatePickerField from './DatePickerField';

const newStyle = { maxWidth: '154px', marginRight: '24px' }
function DateRangePickerField({ startDate, endDate, onStartDateChanged, onEndDateChanged, styles, inputFormat }) {
  const { formatMessage } = useIntl()

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flex: '2 1 100%', ...styles }}>
      <DatePickerField
        newStyle={newStyle}
        fullWidth
        inputFormat={inputFormat}
        label={formatMessage({ id: 'search.startDate' })}
        value={dayjs(startDate).toDate()}
        onChange={d => onStartDateChanged(dayjs(d).format('YYYY-MM-DD'))}
        maxDateMessage={formatMessage({ id: dayjs(endDate).toDate() < new Date() ? 'form.date.afterEndDate' : 'form.date.afterToday' })}
        invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        maxDate={dayjs(endDate).toDate() < new Date() ? dayjs(endDate).toDate() : new Date()}
      />
      <DatePickerField
        newStyle={newStyle}
        fullWidth
        inputFormat={inputFormat}
        label={formatMessage({ id: 'search.endDate' })}
        value={dayjs(endDate).toDate()}
        onChange={d => onEndDateChanged(dayjs(d).format('YYYY-MM-DD'))}
        minDateMessage={formatMessage({ id: 'form.date.beforeStartDate' })}
        maxDateMessage={formatMessage({ id: 'form.date.afterToday' })}
        invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        minDate={dayjs(startDate).toDate()}
        // maxDate={new Date()}
      />
    </div>
  );
};

DateRangePickerField.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onStartDateChanged: PropTypes.func.isRequired,
  onEndDateChanged: PropTypes.func.isRequired,
  styles: PropTypes.object,
  inputFormat: PropTypes.string
};

export default DateRangePickerField
