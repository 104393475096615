import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import EnhancedTable from 'components/EnhancedTable';
import LoadingIndicator from 'components/LoadingIndicator';
import SalesOrderView from 'pages/Sales/SalesOrderView';
import { firestoreListener } from 'modules/firebase';

function SalesOrderInfoDiablog({ so, salesOrderId, userMapping, customerMapping, sourceMapping, highlightItems = [], onClose }) {
  const { formatMessage } = useIntl()
  const [salesOrder, setSalesOrder] = useState(so)

  useEffect(() => {
    const unsubscribe = salesOrderId ? firestoreListener({
      collection: 'salesOrders',
      doc: salesOrderId,
      onData: (data) => {
        const products = Object.keys(data.products).map(k => data.products[k])
        const total = products.reduce((acc, cur) => acc + (cur.unitPrice * cur.amount), 0)
        const collected = Object.keys(data.payments || {}).map(k => data.payments[k]).filter(p => !p.void).map(p => p.collected)
          .reduce((acc, cur) => acc + cur, 0)
        if (total === collected) {
          data.status = 'fullPayment'
        } else if (collected === 0) {
          data.status = 'noPayment'
        } else {
          data.status = 'partialPayment'
        }

        if (data.void) {
          data.status = 'void'
        }
        data.uncollected = total - collected
        data.collected = collected
        setSalesOrder(data)
      }
    }) : null

    return () => unsubscribe?.()
  }, [salesOrderId]);

  const _headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'date' },
    { text: 'source' },
    { text: 'customer' },
    { text: 'agent' },
    { text: 'price' },
    { text: 'status' },
  ]

  const _rowCells = [
    { field: 'sn' },
    { field: 'date' },
    { field: 'sourceName' },
    { field: 'customerName' },
    { field: 'agentName' },
    { field: 'price' },
    { field: 'statusText' },
  ]

  const headerCells = _headerCells
    .map(c => { c.text = formatMessage({ id: `salesOrder.table.header.${c.text}` }); return c })
    .sort((a, b) => a.order - b.order)
  const rowCells = _rowCells.sort((a, b) => a.order - b.order)

  function formatData(salesOrder) {
    const newData = { ...salesOrder }
    newData.statusText = formatMessage({ id: `salesOrder.status.${newData.status}` })
    newData.sourceName = sourceMapping[newData.source]?.name || ''
    newData.customerName = customerMapping[newData.customer]?.name || ''
    newData.agentName = userMapping[newData.agent]?.displayName || ''

    return newData
  }

  return <Dialog maxWidth="md" onClose={onClose} open>
    <DialogTitle id="form-dialog-title">{formatMessage({ id: 'salesOrder.name' })}</DialogTitle>
    <DialogContent>
      {!!salesOrder ?
        <>
          <EnhancedTable
            headerCells={headerCells}
            rowCells={rowCells}
            tableData={[salesOrder].map(i => formatData(i))}
          />
          <SalesOrderView
            userRights={{}}
            salesOrder={salesOrder}
            highlightItems={highlightItems}
          />
        </> : <LoadingIndicator />}

    </DialogContent>
  </Dialog>
}


SalesOrderInfoDiablog.propTypes = {
  salesOrderId: PropTypes.string,
  userMapping: PropTypes.object.isRequired,
  customerMapping: PropTypes.object.isRequired,
  sourceMapping: PropTypes.object.isRequired,
  highlightItems: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func.isRequired,
};

export default SalesOrderInfoDiablog;
