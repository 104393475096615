import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import ContextStore from 'modules/context';
import { callFunction } from 'modules/firebase';
import EditNewMerchandiseView from './EditNewMerchandiseView';

function EditMerchandisePage() {
  const { formatMessage } = useIntl()
  const userRights = useSelector(state => state.userRights)
  const { setBreadcrumbs } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const urMerchandiseCreate = userRights.hasUserRight('merchandise-create')
  const urMerchandiseEdit = userRights.hasUserRight('merchandise-edit')

  const childRef = useRef()
  const { merchandiseId } = useParams()
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(merchandiseId === 'new' ? true : false);

  const merchandiseMapping = useSelector(state => state.merchandises.data);
  const merchandises = useSelector(state => state.merchandises.ordered);

  const [merchandiseData, setMerchandiseData] = useState({
    id: merchandiseId,
    warehousing: {}, // 入庫/不入庫
    type: '', // 正品/沙貨/正品+沙貨
    category: '',
    name: '',
    nickname: '',
    orderUnit: '',
    sku: '', // stock keeping unit
    ou2sku: '',
    suppliers: {},
    orderBySku: {},
    customers: {},
    note: '',
  });

  useEffect(() => {
    const redirect = merchandiseId === 'new' ? urMerchandiseCreate !== true : urMerchandiseEdit !== true

    if (redirect) {
      navigate('/');
    }
  }, [urMerchandiseCreate, urMerchandiseEdit]);

  useEffect(() => {
    const breadcrumbs = [{
      link: '/merchandise',
      text: formatMessage({ id: 'sideMenu.merchandise' })
    }]
    if (merchandiseId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'merchandise.dialog.title.add' }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: 'merchandise.dialog.title.edit' }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    if (merchandiseId !== 'new' && merchandises.length) {
      const merchandise = merchandiseMapping[merchandiseId]
      if (!merchandise) {
        navigate('/merchandise');
        return
      }
      setMerchandiseData({
        id: merchandiseId,
        code: merchandise.code || '',
        warehousing: merchandise.warehousing || {},
        type: merchandise.type,
        category: merchandise.category,
        name: merchandise.name,
        nickname: merchandise.nickname,
        orderUnit: merchandise.orderUnit,
        sku: merchandise.sku,
        ou2sku: merchandise.ou2sku,
        suppliers: merchandise.suppliers,
        customers: merchandise.customers,
        orderBySku: merchandise.orderBySku || {},
        note: merchandise.note,
      })
      setReady(true)
    }
  }, [merchandises.length]);

  const handleSave = async () => {
    setLoading(true);
    const data = childRef.current.getContent()
    if (data) {
      try {
        await callFunction('saveMerchandise', { id: merchandiseData.id, ...data })
      } catch (ex) {
        console.log(ex)
      }
      handleClose()
    } else {
      setLoading(false);
    }
  }

  const handleClose = () => {
    navigate('/merchandise');
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <div style={{ padding: '16px 16px 64px 16px ', minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative' }}>
        {merchandiseId !== 'new' && <>
          <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
            <FormattedMessage id="editMerchandise.title" values={{ code: merchandiseData.code }} />
          </Typography>
          <Divider style={{ margin: '12px 0px' }} />
        </>}
        {ready && <EditNewMerchandiseView
          ref={childRef}
          merchandise={merchandiseData}
          requiredFields={['name', 'nickname', 'type', 'category', 'orderUnit', 'sku', 'ou2sku', 'suppliers']}
          disabledFields={merchandiseId !== 'new' ? ['category'] : []}
        />}
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={loading}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.save" />
          </LoadingButton>
        </Stack>
      </div>
    </div>
  );
}

export default EditMerchandisePage;
