import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import DateRangePickerField from 'components/DateRangePickerField';
import { getComparator, stableSort } from 'modules/sort';
import EnhancedTableHead from './EnhancedTableHead';
import CustomTableRow from './CustomTableRow';
import SearchBox from './SearchBox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SelectItemDialog({ multiSelect = false, defaultOrder, defaultOrderField, headerCells, rowCells, filterItems, categoryFilterItems, dialogTitle, tableTitle = '', applyFilter, defaultSelectedItems = [], handleClose, handleSave, params, maxWidth = 'sm', onTimeRangeChanged, getExpandContent, onRadioButtonClick, onCheckboxClick }) {
  const [selectedItems, setSelectedItems] = useState(defaultSelectedItems.reduce((acc, cur) => { acc[cur] = true; return acc; }, {}));
  const [currentFilter, setCurrentFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(categoryFilterItems ? categoryFilterItems.reduce((acc, cur)=>{ acc[cur.name] = cur.default; return acc }, {}) : undefined);
  const filteredItems = applyFilter(currentFilter, categoryFilter)
  const filteredselection = filteredItems.filter(s => selectedItems[s.id]).reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {})
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderField);
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc'
    setOrder(newOrder);
    setOrderBy(property);
  };

  const headerProps = {}
  const rowProps = {}
  if (defaultOrder && defaultOrderField) {
    headerProps.order = order
    headerProps.orderBy = orderBy
  }
  if (multiSelect) {
    headerProps.numSelected = Object.keys(filteredselection).length
    headerProps.onCheckboxClick = handleSelectAllClick
  }
  if (getExpandContent) {
    if (multiSelect) {
      headerProps.onCheckboxClick = onCheckboxClick
    } else {
      headerProps.radioButton = true
    }
    headerProps.expandable = true
  }

  if (getExpandContent) {
    rowProps.expandable = true
    if (multiSelect && onCheckboxClick) {
      rowProps.onCheckboxClick = onCheckboxClick
    } else if (onRadioButtonClick) {
      rowProps.onRadioButtonClick = onRadioButtonClick
    }
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = filteredItems.reduce((acc, cur) => { acc[cur.id] = true; return acc }, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  };

  const handleCheckboxClick = useCallback(({ id }) => {
    setSelectedItems(selectedItems => {
      if (!!selectedItems[id]) {
        const newSelecteds = { ...selectedItems }
        delete newSelecteds[id]
        return newSelecteds
      } else {
        return { ...selectedItems, [id]: true }
      }
    })
  }, [])

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const onApply = (selectedItems) => {
    handleSave(selectedItems, params)
    handleClose()
  }

  function updateCategoryFilter(name, value) {
    const newFilter = { ...categoryFilter }
    newFilter[name] = value
    setCategoryFilter(newFilter)
  }

  const getCategoryFilter = (filter) => {
    if (filter.type === 'checkbox') {
      return <Grid item key={filter.name} xs={filter.size} sm={filter.size} md={filter.size}>
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-multiple-checkbox-label">{filter.text}</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={categoryFilter.tags}
            onChange={e => { updateCategoryFilter(filter.name, e.target.value) }}
            input={<OutlinedInput label={filter.text} />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {filter.items.map((tag) => (
              <MenuItem key={tag} value={tag}>
                <Checkbox checked={categoryFilter.tags.includes(tag)} />
                <ListItemText primary={tag} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    } else if (filter.type === 'select') {
      return <Grid item key={filter.name} xs={filter.size} sm={filter.size} md={filter.size}>
        <TextField
          select
          type="text"
          size="small"
          label={filter.text}
          variant="outlined"
          onChange={e => { updateCategoryFilter(filter.name, e.target.value) }}
          value={categoryFilter[filter.name] || ''}
          fullWidth
        >
          {filter.items.map((option, idx) => {
            return option.header ?
              (<ListSubheader key={`${option.value}-${idx}`}>{option.label}</ListSubheader>) :
              (<MenuItem key={`${option.value}-${idx}`} value={option.value}>{option.label}</MenuItem>)
          })}
        </TextField>
      </Grid>
    }
  }

  const toolbarItemCount = (!!onTimeRangeChanged ? 1 : 0) + (categoryFilterItems?.length || 0)

  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1 }}>
          <Toolbar sx={{ pl: 2, pr: 1 }}>
            <Typography sx={{ flex: '1 1 100%', flexShrink: toolbarItemCount < 2 ? 1 : 2 }} variant="h6" id="tableTitle" component="div">
              {tableTitle}
            </Typography>
            {(categoryFilterItems || !!onTimeRangeChanged) && <Grid container spacing={3}>
              {!!onTimeRangeChanged && <Grid item xs={12} sm={9} md={9}>
                <DateRangePickerField
                  startDate={startDate}
                  endDate={endDate}
                  onStartDateChanged={(v) => {
                    onTimeRangeChanged({ startDate: v, endDate })
                    setStartDate(v)
                  }}
                  onEndDateChanged={(v) => {
                    onTimeRangeChanged({ startDate, endDate: v })
                    setEndDate(v)
                  }}
                />
              </Grid>}
              {categoryFilterItems.filter(f => !f.hidden).map(f => getCategoryFilter(f))}
            </Grid>}
            <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />
          </Toolbar>

          <TableContainer component={Paper}>
            <Table>
              <EnhancedTableHead
                headerCells={headerCells}
                // numSelected={Object.keys(filteredselection).length}
                // onCheckboxClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filteredItems.length}
                {...headerProps}
              />
              <TableBody>
                {multiSelect ?
                  stableSort(filteredItems, getComparator(order, orderBy)).map(item => (
                    <CustomTableRow
                      key={item.id}
                      rowCells={rowCells}
                      cellData={item}
                      onCheckboxClick={(e) => handleCheckboxClick(item)}
                      onRowClick={(e) => handleCheckboxClick(item)}
                      selected={selectedItems[item.id] || false}
                      {...rowProps}
                    />
                  )) :
                  stableSort(filteredItems, getComparator(order, orderBy)).map(item => (
                    <CustomTableRow
                      key={item.id}
                      rowCells={rowCells}
                      cellData={item}
                      onRowClick={() => {onApply(item)}}
                      selected={defaultSelectedItems.includes(item.id)}
                      expandContent={getExpandContent ? getExpandContent(item) : undefined}
                      {...rowProps}
                    />
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        {multiSelect && <Button variant="contained" onClick={() => onApply(selectedItems)} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>}
        {!multiSelect && getExpandContent && <Button variant="contained" disabled={!defaultSelectedItems.length} onClick={() => onApply(defaultSelectedItems)} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>}
      </DialogActions>
    </Dialog>
  );
}

SelectItemDialog.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  tableTitle: PropTypes.string,
  onTimeRangeChanged: PropTypes.func,
  headerCells: PropTypes.arrayOf(PropTypes.object.isRequired),
  rowCells: PropTypes.arrayOf(PropTypes.object.isRequired),
  filterItems: PropTypes.arrayOf(PropTypes.object.isRequired),
  categoryFilterItems: PropTypes.arrayOf(PropTypes.object.isRequired),
  defaultSelectedItems: PropTypes.arrayOf(PropTypes.string.isRequired),
  applyFilter: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  getExpandContent: PropTypes.func,
  onRadioButtonClick: PropTypes.func,
  onCheckboxClick: PropTypes.func,
  params: PropTypes.any,
  maxWidth: PropTypes.string,
  multiSelect: PropTypes.bool,
};

export default SelectItemDialog;
