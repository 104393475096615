import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

function SelectTagDialog({ defaultSelectedItems, handleClose, handleSave }) {
  const { formatMessage } = useIntl()

  const tagMapping = useSelector(state => state.config.data?.productTags || {})

  const [selectedItems, setSelectedItems] = useState(defaultSelectedItems.reduce((acc, cur) => {acc[cur] = true; return acc;}, {}));

  const [currentFilterText, setCurrentFilterText] = useState('');

  const tags = Object.keys(tagMapping).map(k => ({ key: k, text: tagMapping[k] }))
  const filteredTags = currentFilterText ? (tags.filter(s => s.text.toLowerCase().includes(currentFilterText.toLowerCase()))) : tags

  function addTag() {
    const newSelecteds = { ...selectedItems, [currentFilterText.trim()]: true }
    setSelectedItems(newSelecteds);
    setCurrentFilterText('');
  }

  const handleCheckboxClick = (text) => {
    const selected = selectedItems[text] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[text]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [text]: true }
      setSelectedItems(newSelecteds);
    }
  }

  const onApply = () => {
    const selectedTags = Object.keys(selectedItems)
    handleSave(selectedTags)
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title"><FormattedMessage id="selectTagDialog.title" /></DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: '16px' }}>
            <Typography variant="h6" id="tableTitle" component="div" style={{ flexShrink: 0 }}>
              <FormattedMessage id="selectTagDialog.selectedTags" />
            </Typography>

            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', flexGrow: 1 }}>
              {Object.keys(selectedItems).map(k => <Chip key={k} label={k} style={{ margin: '2px' }} onDelete={() => handleCheckboxClick(k)} />)}
            </div>
            <div style={{ flexGrow: 2 }} />
            <TextField
              type="text"
              size="small"
              label={formatMessage({ id: 'selectTagDialog.search' })}
              variant="outlined"
              onChange={e => { setCurrentFilterText(e.target.value) }}
              value={currentFilterText}
              style={{ flexShrink: 0 }}
            />
            <Button
              disabled={!(currentFilterText.trim().length && !selectedItems[currentFilterText.trim()])}
              variant="contained"
              color="primary"
              onClick={addTag}
              style={{ flexShrink: 0, height: '40px', marginLeft: '8px' }}
            >
              <FormattedMessage id="selectTagDialog.addTag" />
            </Button>
          </div>
          <Divider />
          {filteredTags.length > 0 && <Grid container spacing={1}>
            {filteredTags.map(tag => <Grid item key={tag.key} xs={6} sm={3} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!selectedItems[tag.text]}
                    onChange={() => { handleCheckboxClick(tag.text) }}
                    color="primary"
                  />
                }
                label={tag.text}
              />
            </Grid>)}
          </Grid>}
          {filteredTags.length === 0 &&
            <Typography variant="subtitle1" id="tableTitle" component="div">
              <FormattedMessage id="selectTagDialog.noMatchedTag" />
            </Typography>
          }
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" onClick={onApply} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectTagDialog.propTypes = {
  defaultSelectedItems: PropTypes.arrayOf(PropTypes.string),
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default SelectTagDialog;
