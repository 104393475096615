import React, { useEffect, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';

import SimpleTableToolbar from 'components/SimpleTableToolbar';
import SearchBox from 'components/SearchBox';
import ExpandButton from 'components/ExpandButton';
import EnhancedTableRow from 'components/EnhancedTableRow';
import EnhancedTableHead from 'components/EnhancedTableHead';
import { objectToArray } from 'modules/data';
import { getComparator, stableSort } from 'modules/sort';
import ContextStore from 'modules/context';
import { firestoreListener } from 'modules/firebase';
import { useModuleMapping } from 'hooks/modules';
import AppointmentDate from './AppointmentDate';
import CompanyFilter from 'components/CompanyFilter';

function AppointmentList() {
  const { setBreadcrumbs, uiState, setHeaderTools, currentCompany } = useContext(ContextStore)
  const moduleMapping = useModuleMapping()
  const { formatMessage } = useIntl()
  const location = useLocation();
  const [appointments, setAppointments] = useState([])
  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('time')
  const [customerList, setCustomerList] = useState({})
  const userMapping = useSelector(state => state.users.data);
  const NaStr = formatMessage({ id: 'appointment.popover.NA' })

  useEffect(() => {
    setHeaderTools(<>
      <AppointmentDate />
      <CompanyFilter userRight='appointment-view'/>
    </>)
    return () => {
      setHeaderTools(null)
    };
  }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      onData: (data) => {
        setCustomerList(data)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      where: [
        ['date', '==', dayjs(uiState.date).format('YYYY-MM-DD')],
        ['source', '==', currentCompany]
      ],
      onData: (data) => {
        setAppointments(data)
      }
    })

    return () => unsubscribe()
  }, [uiState.date, currentCompany]);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.appointments.list' })
    }])
    return () => {
    };
  }, [location.pathname]);

  function filterByText() {
    if (currentFilter.name === 'name') {
      return appointments.filter(s => customerList[s.customer]?.name.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'doctor') {
      return appointments.filter(s => userMapping[s.doctor] && userMapping[s.doctor].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'salesRep') {
      return appointments.filter(s => userMapping[s.salesRep] && userMapping[s.salesRep].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'nurse') {
      return appointments.filter(s => userMapping[s.nurse] && userMapping[s.nurse].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  function exportProfile() {
    const rows = ['時間,姓名,醫師,服務人員,護理師,服務']
    filterAppointments.map(r => formatData(r)).forEach(appointment => {
      rows.push([
        appointment.totalTime,
        appointment.customerName,
        appointment.doctorName,
        appointment.nurseName,
        appointment.salesRepName,
        appointment.treatment
      ])
    })

    try {
      const content = rows.join('\n')
      const csvData = new Blob(['\uFEFF' + content], { type: 'text/csv' })
      const csvUrl = URL.createObjectURL(csvData)
      const aExport = document.createElement('a')
      aExport.href = csvUrl
      aExport.target = '_blank'
      aExport.download = 'AppointmentList_' + dayjs().format('YYYY-MM-DD-HHmmss') + '.csv'
      aExport.click()
    } catch (error) {
      console.error(error) // eslint-disable-line
      alert('An error occurred. Please refresh and try again.')
    }
  }

  const _headerCells = [
    { text: 'time', sort: 'time', order: 0 },
    { text: 'name', sort: 'customer', order: 1 },
    { text: 'salesRep', sort: 'salesRep', order: 3 },
    { text: 'treatment', sort: 'treatments', order: 5 },
  ]

  const _rowCells = [
    { field: 'totalTime', textColor: true, order: 0 },
    { field: 'customerName', textColor: true, order: 1 },
    { field: 'salesRepName', textColor: true, order: 3 },
    { field: 'treatment', textColor: true, order: 5 },
  ]

  if (moduleMapping.doctor) {
    _headerCells.push({ text: 'doctor', sort: 'doctor', order: 2 })
    _rowCells.push({ field: 'doctorName', textColor: true, order: 2 })
  }

  if (moduleMapping.nurse) {
    _headerCells.push({ text: 'nurse', sort: 'nurse', order: 4 })
    _rowCells.push({ field: 'nurseName', textColor: true, order: 4 })
  }

  const headerCells = _headerCells.sort((a, b) => {
    return a.order - b.order
  }).map(c => { c.text = formatMessage({ id: `appointment.list.${c.text}` }); return c })

  const rowCells = _rowCells.sort((a, b) => {
    return a.order - b.order
  })

  const _filterItems = [
    { name: 'name', order: 0 },
    { name: 'salesRep', order: 2 },
  ]

  if (moduleMapping.doctor) {
    _filterItems.push({ name: 'doctor', order: 1 })
  }

  if (moduleMapping.nurse) {
    _filterItems.push({ name: 'nurse', order: 3 })
  }
  const filterItems = _filterItems.sort((a, b) => {
    return a.order - b.order
  }).map(i => { i.text = formatMessage({ id: `appointment.list.${i.name}` }); return i })

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const formatData = (data) => {
    const newData = { ...data }
    newData.totalTime = appointmentTimeArray(newData)
    newData.treatment = treatmentsArray(newData.treatments)
    newData.customerName = customerList[newData.customer] ? customerList[newData.customer]?.name : NaStr
    newData.doctorName = userMapping[newData.doctor] ? userMapping[newData.doctor].displayName : NaStr
    newData.salesRepName = userMapping[newData.salesRep] ? userMapping[newData.salesRep].displayName : NaStr
    newData.nurseName = userMapping[newData.nurse] ? userMapping[newData.nurse].displayName : NaStr

    if (newData.status === 'cancelled') {
      newData.textColor = '#bab7b7'
    }
    return newData
  }

  function appointmentTimeArray(appointment) {
    let totalDuration = objectToArray(appointment.treatments).reduce((acc, treatment) => (
      acc + parseInt(treatment.duration || 0)
    ), 0)

    const startTime = appointment.time
    const endTime = dayjs(`${appointment.date} ${appointment.time}`)
      .add(totalDuration, 'minutes')
      .format('HH:mm')

    return startTime + '-' + endTime
  }

  function treatmentsArray(treatments) {
    let name = ''
    let count = 0;
    const treatment = objectToArray(treatments)

    for (let i = 0; i < (treatment.length - 1); i++) {
      if (treatment[i].type && treatment[i].type === 'service') {
        if (count === 0) {
          name = treatment[i].name
          count++;
        } else {
          name = name + '/' + treatment[i].name
        }
      }
    }

    return name
  }

  const filterAppointments = currentFilter && currentFilter.text ? filterByText() : appointments
  return (
    <div style={{ width: '100%', padding: '40px 20px', fontFamily: 'PingFang TC,Roboto,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif' }}>
      <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2}>
          <IconButton
            style={{ fontSize: '14px', alignSelf: 'center', color: '#666666' }}
            onClick={() => exportProfile()}
            size="large">
            <GetAppIcon />
            {formatMessage({ id: 'button.export' })}
          </IconButton>
        </Grid>
        <Grid item xs={10} sm={10} md={10}>
          <SimpleTableToolbar
            title={'appointment.title'}
            bottons={<ExpandButton open={expand} onExpandChange={setExpand} />}
            toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={filterAppointments.length}
              />
              <TableBody>
                {stableSort(filterAppointments.map(r => formatData(r)), getComparator(order, orderBy)).map(appointment => (
                  <EnhancedTableRow
                    key={appointment.id}
                    rowCells={rowCells}
                    cellData={appointment}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}

export default AppointmentList;
