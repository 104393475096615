import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import BackdropMui from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';

import EnhancedTable from 'components/EnhancedTable';
import EnhancedTableEx from 'components/EnhancedTableEx';
import ActionDialog from 'components/ActionDialog';
import SimpleTableToolbar from 'components/SimpleTableToolbar';
import DateRangePickerField from 'components/DateRangePickerField';
import ContextStore from 'modules/context';
import { firestoreListener, callFunction } from 'modules/firebase';
import LeaveType from 'enum/LeaveType';
import LeavesView from './LeavesView';

const _LeaveAllType = LeaveType.concat();

const Backdrop = styled(BackdropMui)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));

const statusColor = {
  pending: '#c83cc8',
  reject: '#fe2851',
  void: '#fe2851',
  done: '#00b900',
}

function ReviewLeavesList({ isAll }) {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const { currentUser } = useContext(ContextStore)
  const tableRef = useRef()
  const userMapping = useSelector(state => state.users.data);

  const [pendingLeaves, setPendingLeaves] = useState([])
  const [historLeaves, setHistorLeaves] = useState([])
  const [dialogData, setDialogData] = useState(null);
  const [loading, setLoading] = useState(false)

  const [startDate, setStartDate] = useState(dayjs().subtract(3, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [historyStartDate, setHistoryStartDate] = useState(dayjs().subtract(3, 'months').format('YYYY-MM') + '-01')
  const [historyEndDate, setHistoryEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  const [typeText, setTypeText] = useState('')
  const [filterText, setFilterText] = useState('')
  const [checked, setChecked] = useState([0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [typeHistoryText, setTypeHistoryText] = useState('')
  const [filterHistoryText, setFilterHistoryText] = useState('')
  const [historyChecked, setHistoryChecked] = useState([0]);
  const [historyAnchorEl, setHistoryAnchorEl] = useState(null);
  const openHistory = Boolean(historyAnchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHistoryClick = (event) => {
    setHistoryAnchorEl(event.currentTarget);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    let newText = ''
    for (const x of newChecked) {
      if (x.name) {
        newText = newText === '' ? x.name : newText + ',' + x.name
      }
    }

    setTypeText(newText)
    setChecked(newChecked);
  };

  const handleHistoryToggle = (value) => () => {
    const currentIndex = historyChecked.indexOf(value);
    const newChecked = [...historyChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    let newText = ''
    for (const x of newChecked) {
      if (x.name) {
        newText = newText === '' ? x.name : newText + ',' + x.name
      }
    }

    setTypeHistoryText(newText)
    setHistoryChecked(newChecked);
  };

  useEffect(() => {
    setPendingLeaves([])
    const unsubscribe = firestoreListener({
      collection: 'leaves',
      where: [['status', '==', 'pending']],
      filter: !isAll ? (leave) => {
        const currentStep = leave.history.length > 0 ? leave.history[leave.history.length - 1].step : 0
        return leave.steps[currentStep].users.includes(currentUser.key)
      } : null,
      onData: (data) => {
        setPendingLeaves(data)
      }
    })

    return () => unsubscribe()
  }, [location.pathname, currentUser.key, isAll]);

  useEffect(() => {
    setHistorLeaves([])

    const unsubscribeA = firestoreListener({
      collection: 'leaves',
      where: [
        ['status', 'in', ['done', 'void', 'reject']],
        ['startDate', '>=', historyStartDate],
        ['startDate', '<=', historyEndDate],
      ],
      onData: (data) => {
        setHistorLeaves(leaves => {
          const leavesMapping = leaves.reduce((acc, cur) => {
            acc[cur.id] = true
            return acc
          }, {})

          data.forEach(docData => {
            if (!leavesMapping[docData.id]) {
              leaves.push(docData)
            }
          });

          return [...leaves]
        })
      }
    })
    const unsubscribeB = firestoreListener({
      collection: 'leaves',
      where: [
        ['endDate', '>=', historyStartDate],
        ['endDate', '<=', historyEndDate],
        ['status', 'in', ['done', 'void', 'reject']]
      ],
      onData: (data) => {
        setHistorLeaves(leaves => {
          const leavesMapping = leaves.reduce((acc, cur) => {
            acc[cur.id] = true
            return acc
          }, {})

          data.forEach(docData => {
            if (!leavesMapping[docData.id]) {
              leaves.push(docData)
            }
          });

          return [...leaves]
        })
      }
    })

    return () => { unsubscribeA(); unsubscribeB() }
  }, [historyStartDate, historyEndDate]);

  if (!historLeaves || !pendingLeaves) {
    return ('Loading...')
  }

  const formatData = (leave) => {
    const newData = { ...leave }
    newData.createdByName = userMapping[newData.createdBy].displayName
    newData.start = `${newData.startDate} ${newData.startTime}`
    newData.typeName = formatMessage({ id: `leaveType.${newData.type}` })
    newData.statusName = `${formatMessage({ id: `leaveStatus.${newData.status}` })}`
    newData.end = `${newData.endDate} ${newData.endTime}`
    newData.day = `${newData.days}天${newData.hours}小時`
    newData.date = dayjs(newData.createdAt.seconds * 1000).format('YYYY-MM-DD HH:mm')
    newData.textColor = statusColor[newData.status]
    return newData
  }

  async function handleExecute(data) {
    const { text } = data
    const { filed, leaveId } = dialogData
    setLoading(true)

    try {
      await callFunction('saveLeaves', {
        ...filed,
        id: leaveId,
        note: text,
        void: true,
      })

      setLoading(false)
    } catch (ex) {
      console.log(ex)
      setLoading(false)
    }
  }

  function handleClose() {
    setDialogData(null)
  }

  function HistoryHandleChange(value) {
    setFilterHistoryText(value)
  }

  function handleChange(value) {
    setFilterText(value)
  }

  function getToolbox(isHitory) {
    return <>
      {isHitory && <DateRangePickerField
        startDate={isHitory ? historyStartDate : startDate}
        endDate={isHitory ? historyEndDate : endDate}
        onStartDateChanged={isHitory ? setHistoryStartDate : setStartDate}
        onEndDateChanged={isHitory ? setHistoryEndDate : setEndDate}
      />}
      <Grid item xs={12} sm={6} md={6} sx={{ paddingRight: '10px' }}>
        <TextField
          type="text"
          size="small"
          label={formatMessage({ id: 'searchbox.createdBy' })}
          onChange={(e) => isHitory ? HistoryHandleChange(e.target.value) : handleChange(e.target.value)}
          variant="outlined"
          fullWidth
          value={isHitory ? filterHistoryText : filterText}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          type="text"
          size="small"
          label={formatMessage({ id: 'searchbox.type' })}
          variant="outlined"
          onClick={isHitory ? handleHistoryClick : handleClick}
          value={isHitory ? typeHistoryText : typeText}
          fullWidth
        />
        <Menu
          id="fade-menu"
          anchorEl={isHitory ? historyAnchorEl : anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          open={isHitory ? openHistory : open}
          onClose={() => isHitory ? setHistoryAnchorEl(null) : setAnchorEl(null)}
          TransitionComponent={Fade}
        >
          <List>
            {_LeaveAllType.map((value, idx) => {
              return (
                <ListItem key={`${value}-${idx}`} dense onClick={isHitory ? handleHistoryToggle(value) : handleToggle(value)}>
                  <Checkbox
                    sx={{ padding: '0 4px' }}
                    edge="start"
                    size="small"
                    checked={isHitory ? historyChecked.indexOf(value) !== -1 : checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                  {value.name}
                </ListItem>
              );
            })}
          </List>
        </Menu>
      </Grid>
    </>;
  }

  const headerCells = [
    { text: 'createdByName', sort: 'createdByName' },
    { text: 'type', sort: 'type' },
    { text: 'start', sort: 'start' },
    { text: 'end', sort: 'end' },
    { text: 'days', sort: 'days' },
    { text: 'date', sort: 'date' },
    { text: 'state', sort: 'status' },
  ].map(c => { c.text = formatMessage({ id: `leaves.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'createdByName' },
    { field: 'typeName' },
    { field: 'start' },
    { field: 'end' },
    { field: 'day' },
    { field: 'date' },
    { field: 'statusName', textColor: true },
  ]

  function exportProfile() {
    tableRef.current.saveToFile(`Leaves_${dayjs().format('YYYY-MM-DD-HHmmss')}.csv`)
  }

  function filterItem(isHistory) {
    let type = [];

    if (isHistory) {
      for (const x of historyChecked) {
        if (x.value) {
          type.push(x.value)
        }
      }
      if (typeHistoryText !== '' && filterHistoryText === '') {
        return historLeaves.filter(s => type.includes(s.type));
      } else if (filterHistoryText !== '' && typeHistoryText === '') {
        return historLeaves.filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(filterHistoryText.toLowerCase()));
      } else if (typeHistoryText !== '' && filterHistoryText !== '') {
        return historLeaves.filter(s => type.includes(s.type)).filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(filterHistoryText.toLowerCase()));
      } else {
        return historLeaves
      }
    } else {
      for (const x of checked) {
        if (x.value) {
          type.push(x.value)
        }
      }
      if (typeText !== '' && filterText === '') {
        return pendingLeaves.filter(s => type.includes(s.type));
      } else if (filterText !== '' && typeText === '') {
        return pendingLeaves.filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(filterText.toLowerCase()));
      } else if (typeText !== '' && filterText !== '') {
        return pendingLeaves.filter(s => type.includes(s.type)).filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(filterText.toLowerCase()));
      } else {
        return pendingLeaves
      }
    }
  }

  const filterHistoryLeaves = filterItem(true);
  const filterLeaves = filterItem();
  return (
    <div>
      {loading && <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>}
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'leaves.detail.voidReason' })}
        action={dialogData.action}
      />}
      <Grid container spacing={1} sx={{ mt: '10px', mb: '40px' }}>
        <Grid item xs={12} sm={12} md={12}>
          <SimpleTableToolbar
            title={'leaves.table.pending'}
            toolbox={getToolbox()}
          />
          <EnhancedTable
            defaultOrder="desc"
            defaultOrderField="date"
            headerCells={headerCells}
            rowCells={rowCells}
            tableData={filterLeaves.map(r => formatData(r))}
            getExpandContent={leave =>
              <LeavesView currentUser={currentUser} leave={leave} isAll={isAll} type='review' />
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} >
        <Grid item xs={12} sm={12} md={12}>
          <SimpleTableToolbar
            title={'leaves.table.history'}
            toolbox={getToolbox(true)}
          />
          <EnhancedTableEx
            ref={tableRef}
            defaultOrder="desc"
            defaultOrderField="date"
            headerCells={headerCells}
            rowCells={rowCells}
            tableData={filterHistoryLeaves.map(r => formatData(r))}
            getHeaderActionIcons={() => isAll ? (
              <IconButton
                onClick={() => exportProfile()}
                size="large">
                <GetAppIcon></GetAppIcon>
                <Typography noWrap variant="button">{formatMessage({ id: 'button.export' })}</Typography>
              </IconButton>
            ) : null}
            getActionIcons={leave =>
              <>
                {(!leave.void || (leave.void && leave.status === 'reject')) && <span>
                  <Tooltip title={formatMessage({ id: 'button.edit' })}>
                    <IconButton
                      onClick={() => navigate(`/leaveOvertimeWork/leaves/edit/${leave.createdBy}/${leave.id}`)}
                      size="large">
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={formatMessage({ id: 'button.void' })}>
                    <IconButton
                      onClick={() => setDialogData({ action: 'void', title: '', filed: leave, leaveId: leave.id })}
                      size="large">
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </span>}
              </>
            }
            getExpandContent={leave =>
              <LeavesView currentUser={currentUser} leave={leave} isHistory={true} isAll={isAll} type='review' />
            }
          />
        </Grid>
      </Grid>
    </div >
  );
};

ReviewLeavesList.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  isAll: PropTypes.bool
};

export default ReviewLeavesList;
