import React, { useEffect, useContext, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

import TabContainer from 'containers/TabContainer/TabContainer';
import ContextStore from 'modules/context';

function SettingReport() {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { setBreadcrumbs } = useContext(ContextStore)
  const location = useLocation()
  const pathname = location.pathname
  const [activeTabIndex, setActiveTabIndex] = useState(undefined)

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.setting.root' })
    }])
  }, [pathname]);

  useEffect(() => {
    if (pathname === '/setting/report/reportCategory' && activeTabIndex !== 0) {
      setActiveTabIndex(0)
    }

    return () => {
    };
  }, [pathname]);

  const onTabSelected = (tabIndex) => {
    if (tabIndex === 0) {
      navigate('/setting/report/reportCategory');
    }
  }

  const tabs = [
    {
      label: formatMessage({ id: 'settingReport.reportCategory.title' }),
      component: <Outlet />
    },
  ]

  return (
    <div style={{ padding: '12px 24px 80px 24px' }}>
      <Toolbar sx={{ pl: 2, pr: 1 }}>
        <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="settingReport.title" />
        </Typography>
      </Toolbar>
      <TabContainer activeTabIndex={activeTabIndex} defaultSelect={0} tabs={tabs} onTabSelected={onTabSelected} />
    </div>
  );
}

export default SettingReport;
