import React from 'react';

import { styled } from '@mui/material/styles';

const Container = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-end'
}));

const ButtonContainer = ({ children }) => (
  <Container>
    {children}
  </Container>
)

export default ButtonContainer;
