import AppointmentIcon from '@mui/icons-material/Event';
import CustomersIcon from '@mui/icons-material/AssignmentInd';
import StaffIcon from '@mui/icons-material/People';
import LeaveIcon from '@mui/icons-material/EventNote';
import ScheduleIcon from '@mui/icons-material/GridOn';
import FinanceIcon from '@mui/icons-material/AttachMoney';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ProductIcon from '@mui/icons-material/ViewList';
import ServicesIcon from '@mui/icons-material/Inventory';
import SalesIcon from '@mui/icons-material/Receipt';
import VendorIcon from '@mui/icons-material/ContactMail';
import MerchandiseIcon from '@mui/icons-material/Assignment';
import ReportIcon from '@mui/icons-material/Assessment';
import PurchaseIcon from '@mui/icons-material/ShoppingCart';
import DeliveryIcon from '@mui/icons-material/LocalShipping';
import StockIcon from '@mui/icons-material/Archive';
import AnnouncementIcon from '@mui/icons-material/InfoOutlined';

function getIcon(module) {
  if (module === 'appointments') {
    return <AppointmentIcon />
  } else if (module === 'customers') {
    return <CustomersIcon />
  } else if (module === 'staff') {
    return <StaffIcon />
  } else if (module === 'leaveOvertime') {
    return <LeaveIcon />
  } else if (module === 'schedule') {
    return <ScheduleIcon />
  } else if (module === 'finance') {
    return <FinanceIcon />
  } else if (module === 'setting') {
    return <SettingsIcon />
  } else if (module === 'logout') {
    return <LogoutIcon />
  } else if (module === 'product') {
    return <ProductIcon />
  } else if (module === 'services') {
    return <ServicesIcon />
  } else if (module === 'sales') {
    return <SalesIcon />
  } else if (module === 'vendor') {
    return <VendorIcon />
  } else if (module === 'merchandise') {
    return <MerchandiseIcon />
  } else if (module === 'report') {
    return <ReportIcon />
  } else if (module === 'purchase') {
    return <PurchaseIcon />
  } else if (module === 'delivery') {
    return <DeliveryIcon />
  } else if (module === 'stock') {
    return <StockIcon />
  } else if (module === 'announcement') {
    return <AnnouncementIcon />
  }
}

export {
  getIcon
}