import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';

import AppNav from 'containers/AppNav/AppNav';
import ContextStore from 'modules/context';
import { DRAWER_WIDTH } from 'constants';
import RoutePage from './RoutePage';
import SideMenu from './SideMenu';
import './App.css'

const version = '1.0.20240903a'

const theme = createTheme({
  // palette: {
  //   mode: 'dark',
  // },
  breakpoints: {
    values: {
      mobile: 480,
      tablet: 1024,
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    height: '100vh',
    width: open ? `calc(100vw - ${DRAWER_WIDTH}px)` : '100vw',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${DRAWER_WIDTH}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

function App({ currentUser }) {
  const [headerTools, setHeaderTools] = useState(null)
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [purchaseOrderCache, setPurchaseOrderCache] = useState({});
  const [currentCompany, setCurrentCompany] = useState(currentUser?.company[0] ?? '')

  const [uiState, setUiState] = useState({
    date: dayjs(),
    noShifts: false,
    showNurse: true,
    showDoctor: true,
    showRoom: true,
    showSalesRep: true,
    showCustomerCode: true,
    showCustomerType: false,
    showBirthDate: false,
    showPunchClock: true,
    showLeave: true,
    showException: true,
    showOvertime: true,
    showShift: true,
    showComment: true,
    showWeeklyLeave: true,
  });

  const contextValue = useMemo(
    () => ({ breadcrumbs, setBreadcrumbs, purchaseOrderCache, setPurchaseOrderCache, uiState, setUiState, currentUser, headerTools, setHeaderTools, drawerStatus, currentCompany, setCurrentCompany }),
    [breadcrumbs, uiState, currentUser, drawerStatus, headerTools, purchaseOrderCache, currentCompany]
  );

  const handleDrawerOpen = () => {
    setDrawerStatus(true);
  };

  const handleDrawerClose = () => {
    setDrawerStatus(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: 'flex' }}>
        <ContextStore.Provider value={contextValue}>
          <AppNav
            isDrawerOpen={drawerStatus}
            currentUser={currentUser}
            handleDrawerOpen={handleDrawerOpen}
          />
          <Drawer
            sx={{
              width: DRAWER_WIDTH,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: DRAWER_WIDTH,
                boxSizing: 'border-box',
              },
            }}
            variant="persistent"
            anchor="left"
            open={drawerStatus}
          >
            <DrawerHeader sx={{ justifyContent: 'space-between' }}>
              <IconButton onClick={handleDrawerClose} size="large">
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <SideMenu currentUser={currentUser} />
            <div style={{ width: '100%', height: '1px', display: 'block', flexGrow: 2 }} />
            <Typography component="h1" variant="caption">
              <span style={{ paddingLeft: '4px' }}>版本 {version}</span>
            </Typography>
          </Drawer>
          <Main open={drawerStatus}>
            <DrawerHeader />
            <RoutePage currentUser={currentUser} />
          </Main>
        </ContextStore.Provider >
      </div >
    </ThemeProvider>
  );
}

App.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }),
};

export default App;
