import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import { firestoreListener } from 'modules/firebase';

const DividerText = styled('div')(() => ({
  position: 'absolute',
  top: '7px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

function ReviewCustomer({ customerData }) {
  const { formatMessage } = useIntl()
  const customerId = customerData.id;
  const productMapping = useSelector(state => state.products.data)
  const [customerMapping, setCustomerMapping] = useState(null)
  const [storedValueCard, setStoredValueCard] = useState(null)
  const customerExtTab = useSelector(state => state.customerExtTab.data)
  const config = useSelector(state => state.config.data)
  const customerType = Object.keys(config.customerType || {}).map(i => config.customerType[i])
  const customerTypeMapping = customerType.reduce((acc, cur) => {
    acc[cur.id] = cur
    return acc
  }, {})

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'storedValueCard',
      doc: customerId,
      unwrap: true,
      addDocId: false,
      onData: (data) => {
        setStoredValueCard(data)
      }
    })

    return () => unsubscribe()
  }, []);


  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      onData: (data) => {
        setCustomerMapping(data)
      }
    })

    return () => unsubscribe()
  }, []);

  if (!customerData || !customerMapping) {
    return ('Loading...')
  }

  const formatData = (customer) => {
    const newData = { ...customer }

    return newData
  }

  const customer = formatData(customerData)
  const _fields = [
    { name: 'gender', sm: 3, md: 3, roots: true, order: 1 },
    { name: 'type', sm: 3, md: 3, roots: true, order: 2 },
    { name: 'identityCardNumber', sm: 3, md: 3, order: 5 },
    { name: 'nationality', roots: true, sm: 3, md: 3, order: 6 },
    { name: 'maritalStatus', sm: 3, md: 3, roots: true, order: 8 },
    { name: 'address', sm: 12, md: 12, order: 9 },
    { name: 'comment', sm: 12, md: 12, order: 10 },
    { name: 'underLine', type: '-', sm: 12, md: 12, order: 98 },
  ]

  for (const c of customerType) {
    if (c.id === customer.type) {
      if (c.requiredItems !== 'none') {
        const data = Object.keys(customerExtTab[c.requiredItems] || {}).map(i => customerExtTab[c.requiredItems][i])
        for (const d of data) {
          if(d.id) {
            _fields.push({ ...d, name: d.id, label: d.name, md: 3, sm: 3, order: 3 })
          }
        }
      }

      if (c.items !== 'none') {
        const data = Object.keys(customerExtTab[c.items] || {}).map(i => customerExtTab[c.items][i])
        for (const d of data) {
          if(d.id) {
            _fields.push({ ...d, name: d.id, label: d.name, md: 3, sm: 3, order: 4 })
          }
        }
      }
    }
  }

  if (storedValueCard) {
    _fields.push({ name: 'storedValueCardLine', text: formatMessage({ id: 'customer.profile.storedValueBalance' }), type: '-', order: 15 })
    let newOrder = 16
    for (const product of Object.keys(storedValueCard)) {
      if(productMapping[product]) {
        _fields.push({ name: `${productMapping[product].name}`, type: 'storedValue', sm: 3, value: storedValueCard[product], order: newOrder })
        newOrder += 1
      }
    }
  }

  if (customer.cantWait) {
    _fields.push({ name: 'cantWait', color: '#ffbf38', type: 'status', sm: 3, md: 3, order: 99 })
  }
  if (customer.allergy) {
    _fields.push({ name: 'allergy', color: '#fe2851', type: 'status', sm: 3, md: 3, order: 99 })
  }
  if (customer.blackList) {
    _fields.push({ name: 'blackList', color: '#000', type: 'status', sm: 3, md: 3, order: 99 })
  }
  if (customer.noPhone) {
    _fields.push({ name: 'noPhone', color: '#a1d938', type: 'status', sm: 3, md: 3, order: 99 })
  }
  if (customer.noSms) {
    _fields.push({ name: 'noSms', color: '#2882ff', type: 'status', sm: 3, md: 3, order: 99 })
  }
  if (customer.vip) {
    _fields.push({ name: 'vip', color: '#c665c9', type: 'status', sm: 3, md: 3, order: 99 })
  }

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  function createField(field, value, customer) {
    let newValue = value
    let labelText = ''

    if (['gender', 'maritalStatus', 'nationality'].includes(field.name) && value) {
      newValue = formatMessage({ id: `customer.profile.${field.name}.${value}` })
    } else if (field.name === 'type') {
      newValue = customerTypeMapping[newValue] ? customerTypeMapping[newValue].name : ''
    }

    if (field.type === 'dialog' && field.dialog === 'customers') {
      newValue = customerMapping[newValue]?.name
    }

    if (field.type === '-') {
      if (field.text) {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12} sx={{ position: 'relative', mt: '5px' }}>
          <Divider />
          <DividerText>
            <Typography color="textSecondary" variant="caption">{field.text}</Typography>
          </DividerText>
        </Grid>
      } else {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
      }
    } else if (field.type === 'status') {
      return <Grid item key={field.name} xs={3} sm={field.sm} md={2} sx={{ position: 'relative' }}>
        <Chip sx={{ backgroundColor: field.color, color: '#fff', fontSize: '15px', minWidth: '100px' }} label={formatMessage({ id: `customer.profile.status.${field.name}` })} />
      </Grid>
    } else if (field.type === 'storedValue') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={field.name}
          value={field.value}
          variant="standard"
          fullWidth
          size="small"
          margin="dense"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    }

    if (field.roots) {
      labelText = formatMessage({ id: `customer.profile.${field.name}.roots` })
    } else if (field.label) {
      labelText = field.label
    } else {
      labelText = formatMessage({ id: `customer.profile.${field.name}` })
    }

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={labelText}
        value={newValue}
        variant="standard"
        fullWidth
        size="small"
        margin="dense"
        InputProps={{
          readOnly: true,
        }}
      />
    </Grid>
  }

  return (
    <div style={{ padding: 15 }}>
      <Grid container spacing={2}>
        {fields.map(field => createField(field, customer[field.name], customer))}
      </Grid>
    </div>
  );
}

ReviewCustomer.propTypes = {
  customerData: PropTypes.object.isRequired,
};

export default ReviewCustomer;
