import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import DateRangePickerField from 'components/DateRangePickerField';
import EnhancedTable from 'components/EnhancedTable';
import SimpleTableToolbar from 'components/SimpleTableToolbar';
import ExpandButton from 'components/ExpandButton';
import SearchBox from 'components/SearchBox';
import FabAdd from 'components/FabAdd';
import { unwrap } from 'modules/uitls';
import ContextStore from 'modules/context';
import { firebaseV8 } from 'modules/firebaseV8';
import ReturnFormView from './ReturnFormView';

function ReturnFormDetail({ userMapping, returnForm, ...props }) {
  const r = { ...returnForm }
  const keys = returnForm.merchandises ? Object.keys(returnForm.merchandises) : []
  r.merchandises = keys.map(k => {
    return {
      ...r.merchandises[k],
      id: k,
      price: r.merchandises[k].items.reduce((acc, cur) => acc + (cur.unitPrice * cur.amount), 0)
    }
  })

  r.createdBy = { id: r.createdBy, name: userMapping[r.createdBy]?.displayName }
  return (
    <div style={{ padding: 15 }}>
      <ReturnFormView userMapping={userMapping} returnForm={r} {...props} />
    </div>
  )
}

ReturnFormDetail.propTypes = {
  returnForm: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
};

function ReturnFormList() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const location = useLocation()

  const userMapping = useSelector(state => state.users.data)
  const customerMapping = useSelector(state => state.internalVendors.data)
  const supplierMapping = useSelector(state => state.suppliers.data)
  const merchandiseMapping = useSelector(state => state.merchandises.data)

  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);
  const [returnForms, setReturnForms] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.purchase.returnForm' })
    }])
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    if (returnForms.length) setReturnForms([])
    const onSnapshot = snapshot => {
      const returnForms = []
      snapshot.forEach(doc => {
        returnForms.push({ ...unwrap(doc.data()), id: doc.id })
      });
      setReturnForms(returnForms)
    }
    const unsubscribe = firebaseV8().collection('returnForms').where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(onSnapshot, err => {})
    return () => unsubscribe()
  }, [startDate, endDate]);

  const returnFormsForTab = returnForms.filter(r =>
    userRights.debugging || userRights.returnFormSource.includes(r.source)
  );

  // const filteredReturnForms = currentFilter ? (returnFormsForTab.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))) : returnFormsForTab
  const filteredReturnForms = currentFilter && currentFilter.text ? filterByText() : returnFormsForTab

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'sourceName', sort: 'sourceName' },
    { text: 'supplierName', sort: 'supplierName' },
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'date', sort: 'date' },
  ].map(c => {c.text = formatMessage({ id: `returnForm.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'sn' },
    { field: 'sourceName' },
    { field: 'supplierName' },
    { field: 'createdBy' },
    { field: 'date' },
  ]

  function filterByText() {
    const lowerCaseText = currentFilter.text.toLowerCase()
    if (currentFilter.name === 'sourceName') {
      return returnFormsForTab.filter(s => customerMapping[s.source]?.nickname.toLowerCase().includes(lowerCaseText))
    } else if (currentFilter.name === 'createdBy') {
      return returnFormsForTab.filter(s => userMapping[s.createdBy]?.displayName.toLowerCase().includes(lowerCaseText))
    } else if (currentFilter.name === 'merchandiseName') {
      return returnFormsForTab.filter(s => Object.keys(s.merchandises || {}).filter(c => merchandiseMapping[c])
        .filter(c => merchandiseMapping[c].name.toLowerCase().includes(lowerCaseText) ||
          merchandiseMapping[c].nickname.toLowerCase().includes(lowerCaseText)).length)
    } else if (currentFilter.name === 'merchandiseCode') {
      return returnFormsForTab.filter(s => Object.keys(s.merchandises || {}).filter(c => merchandiseMapping[c])
        .filter(c => merchandiseMapping[c].code.toLowerCase().includes(lowerCaseText)).length)
    } else {
      return returnFormsForTab.filter(s => s[currentFilter.name].toLowerCase().includes(lowerCaseText))
    }
  }

  const formatData = (returnForm) => {
    const newData = { ...returnForm }
    newData.sourceName = customerMapping[newData.source]?.nickname
    newData.supplierName = supplierMapping[newData.supplier]?.nickname
    newData.createdBy = userMapping[newData.createdBy]?.displayName
    return newData
  }

  const filterItems = [
    { name: 'sn' },
    { name: 'sourceName', type: 'customer' },
    { name: 'supplierName' },
    { name: 'createdBy' },
    { name: 'merchandiseName' },
    { name: 'merchandiseCode' },
    { name: 'note' },
  ].map(i => {i.text = formatMessage({ id: `returnForm.table.detail.${i.name}` });return i})

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  return (
    <div style={{ padding: '24px 24px 60px 24px' }}>
      {userRights.hasUserRight('returnForm-create') === true && <FabAdd to="/purchase/returnForm/edit/new" />}
      <DateRangePickerField
        startDate={startDate}
        endDate={endDate}
        onStartDateChanged={setStartDate}
        onEndDateChanged={setEndDate}
      />
      <SimpleTableToolbar
        title="returnForm.table.title"
        buttons={<ExpandButton open={expand} onExpandChange={setExpand} />}
        toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
      />
      <EnhancedTable
        defaultOrder="desc"
        defaultOrderField="sn"
        headerCells={headerCells}
        rowCells={rowCells}
        getExpandContent={returnForm =>
          <ReturnFormDetail
            userMapping={userMapping}
            merchandiseMapping={merchandiseMapping}
            returnForm={returnForm}
          />
        }
        tableData={filteredReturnForms.map(r => formatData(r))}
      />
    </div>
  );
}

export default ReturnFormList;
