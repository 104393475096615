import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';

import EnhancedTable from 'components/EnhancedTable';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import SelectMerchandiseDialog from 'components/SelectMerchandiseDialog';
// import SelectVerdorDialog from 'components/SelectVerdorDialog';
import { unwrap, capitalize } from 'modules/uitls';
import ContextStore from 'modules/context';
import { addError, removeError } from 'modules/editor';
import { callFunction } from 'modules/firebase';
import { firebaseV8 } from 'modules/firebaseV8';

function MerchandiseItem({ formName, lock = false, merchandiseId, items, onItemValueChanged }) {
  const { formatMessage } = useIntl()
  const headerCells = [
    { field: 'amount' },
    { field: 'lotNumber' },
    { field: 'expectedDate' },
  ].map(c => {c.text = formatMessage({ id: `${formName}.merchandise.${c.field}` });return c})

  const rowCells = lock ? [
    { field: 'amount' },
    { field: 'lotNumber', type: 'input', required: false, label: '批號', onValueChanged: onCellValueChanged },
    { field: 'expectedDate', type: 'date', required: false, label: '效期', onValueChanged: onCellValueChanged },
  ] : [
    { field: 'amount', type: 'input-number', required: true, label: '退貨數量', onValueChanged: onCellValueChanged },
    { field: 'lotNumber', type: 'input', required: false, label: '批號', onValueChanged: onCellValueChanged },
    { field: 'expectedDate', type: 'date', required: false, label: '效期', onValueChanged: onCellValueChanged },
  ]

  function onCellValueChanged(data, field, value) {
    const m = items[data.key]
    if (field === 'expectedDate') {
      m.expectedDate = value
      if (value === null) {
        removeError(m, field)
      } else if (value.toString() === 'Invalid Date') {
        addError(m, field, formatMessage({ id: 'form.date.formatError' }))
      } else {
        removeError(m, field)
      }
    } else {
      m[field] = value
    }
    onItemValueChanged(merchandiseId, field)
  }

  return (
    <EnhancedTable
      headerCells={headerCells}
      rowCells={rowCells}
      tableData={items}
    />
  )
}

MerchandiseItem.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
  onItemValueChanged: PropTypes.func.isRequired,
  merchandiseId: PropTypes.string.isRequired,
  extra: PropTypes.bool,
  lock: PropTypes.bool,
};

function EditStockRequisitionPage({ formName }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const navigate = useNavigate()
  const location = useLocation()

  const customerMapping = useSelector(state => state.internalVendors.data)
  const customers = useSelector(state => state.internalVendors.ordered)
  const filteredSources = customers.filter(c => userRights.hasUserRightForVendor(`${formName}-create`, c.id))
  const merchandiseMapping = useSelector(state => state.merchandises.data)
  const merchandises = useSelector(state => state.merchandises.ordered)
  const userMapping = useSelector(state => state.users.data)

  // const supplierMapping = useSelector(state => state.suppliers.data)
  // const suppliers = useSelector(state => state.suppliers.ordered)

  const [selectedItems, setSelectedItems] = useState({});
  const [loading, setLoading] = useState(false);
  const [lockSource, setLockSource] = useState(false);

  const [openDialog, setOpenDialog] = useState({ name: '', data: '' });
  const [inventories, setInventories] = useState({});

  const [form, setForm] = useState({
    id: 'new',
    source: '',
    date: dayjs().format('YYYY-MM-DD'),
    createdBy: currentUser.key,
    note: '',
    merchandises: [],
  });

  useEffect(() => {
    if (filteredSources.length === 1) {
      updateFormData({ name: 'source' }, filteredSources[0].id);
    }
  }, [filteredSources.length]);

  useEffect(() => {
    const unsubscribe = form.source ? firebaseV8().collection('inventories').doc(form.source)
      .onSnapshot(snapshot => {
        const data = unwrap(snapshot.data())
        setInventories(data)
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, [form.source]);

  useEffect(() => {
    setBreadcrumbs([{
      link: `/stock/${formName}/pending`,
      text: formatMessage({ id: `sideMenu.stock.${formName}` })
    },{
      text: formatMessage({ id: `${formName}.dialog.title.add` })
    }])
    return () => {
    };
  }, [location.pathname]);

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    { text: 'stock' },
    { text: 'amount' },
    { text: 'unit' },
    { text: 'lotNumber' },
    { text: 'expectedDate' },
    { text: 'note', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `${formName}.merchandise.${c.text}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    { field: 'stock' },
    { field: 'amount', type: 'input-number', required: true, label: formatMessage({ id: `${formName}.merchandise.amount` }), onValueChanged: onCellValueChanged, getEnableStatus },
    { field: 'unit',
      align: 'right',
      type: 'input-select',
      required: true,
      label: '單位',
      onValueChanged: onCellValueChanged,
      getMenuItems: getUnitList,
      minWidth: '80px',
    },
    { field: 'lotNumber', type: 'input', required: false, label: '批號', onValueChanged: onCellValueChanged, getEnableStatus },
    { field: 'expectedDate', type: 'date', required: false, label: '效期', onValueChanged: onCellValueChanged, getEnableStatus },
    { field: 'note', align: 'right', type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged },
  ]

  function getUnitList(merchandise) {
    if (merchandise.orderUnit === merchandise.sku) {
      return [{ label: merchandise.orderUnit, value: 'ou' }]
    }
    return [{ label: `${merchandise.orderUnit}(${merchandise.ou2sku}${merchandise.sku})`, value: 'ou' }, { label: merchandise.sku, value: 'sku' }]
  }

  function getEnableStatus(merchandise, field) {
    if (merchandise.items) {
      return false
    }
    return true
  }

  function formatData(merchandise) {
    const m = merchandiseMapping[merchandise.id]
    const newData = {
      ...merchandise,
      code: m.code,
      name: m.name,
      nickname: m.nickname,
      orderUnit: m.orderUnit,
      sku: m.sku,
      ou2sku: m.ou2sku,
      stock: merchandiseMapping[merchandise.id].warehousing[form.source] ? `${inventories[merchandise.id] ?? 0}${m.sku}` : '不入庫',
    }
    return newData
  }

  function onCellValueChanged(data, field, value) {
    for (let m of form.merchandises) {
      if (m.id === data.id) {
        m[field] = value
        const warehousing = merchandiseMapping[m.id].warehousing[form.source]
        if (field === 'amount') {
          const stock = (inventories[data.id] ?? 0)
          if (isNaN(value) || value === '' || value === '0') {
            addError(m, field, '數量錯誤')
          } else if (warehousing && ((data.unit === 'ou' && value * data.ou2sku > stock) || (data.unit === 'sku' && value > stock))) {
            addError(m, field, '超過庫存數量')
          } else {
            removeError(m, field)
          }
        } else if (field === 'unit') {
          const stock = (inventories[data.id] ?? 0)
          if (warehousing && ((m.unit === 'ou' && data.amount * data.ou2sku > stock) || (m.unit === 'sku' && data.amount > stock))) {
            addError(m, 'amount', '超過庫存數量')
          } else {
            removeError(m, 'amount')
          }
        } else if (field === 'expectedDate') {
          if (value === null) {
            removeError(m, field)
          } else if (value.toString() === 'Invalid Date') {
            addError(m, field, formatMessage({ id: 'form.date.formatError' }))
          } else {
            removeError(m, field)
          }
        }
        break
      }
    }
    updateFormData({ name: 'merchandises' }, form.merchandises);
  }

  function onItemValueChanged(id, field) {
    if (field === 'amount') {
      for (const m of form.merchandises) {
        if (m.id === id) {
          const warehousing = merchandiseMapping[m.id].warehousing[form.source]
          // 更新 parent 的 amount, 檢查看看有沒有超過最大值
          let total = 0
          for (const i of m.items) {
            if (isNaN(i.amount) || i.amount === '' || parseInt(i.amount) === 0) {
            } else {
              total += parseInt(i.amount)
            }
          }
          m.amount = total
          const stock = (inventories[m.id] ?? 0)
          if (total === 0) {
            addError(m, 'amount', '數量錯誤')
          } else if (warehousing && ((m.unit === 'ou' && total * m.ou2sku > stock) || (m.unit === 'sku' && total > stock))) {
            addError(m, 'amount', '超過庫存數量')
          } else {
            removeError(m, field)
          }
          break
        }
      }
    }
    updateFormData({ name: 'merchandises' }, form.merchandises)
  }

  function onAddLotNumber() {
    const merchandises = form.merchandises
    for (const m of merchandises) {
      if (selectedItems[m.id]) {
        if (!m.items) {
          m.items = [{
            key: '0',
            amount: m.amount,
            lotNumber: m.lotNumber,
            expectedDate: m.expectedDate,
          }]
          m.lotNumber = ''
          m.expectedDate = null
        }
        m.items.push({
          key: String(m.items.length),
          amount: 0,
          lotNumber: '',
          expectedDate: null,
        })
      }
    }
    setSelectedItems({});
    updateFormData({ name: 'merchandises' }, merchandises);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = form.merchandises.reduce((acc, cur) => {acc[cur.id] = true;return acc}, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  }

  function handleCheckboxClick({ id }) {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function onDeleteItems() {
    const merchandises = form.merchandises.filter(m => !selectedItems[m.id])
    if (merchandises.length !== form.merchandises.length) {
      updateFormData({ name: 'merchandises' }, merchandises);
      setSelectedItems({});
    }
  }

  const onSourceChanged = (e) => {
    updateFormData({ name: 'source' }, e.target.value);
  }

  function onMerchandiseChanged(merchandises) {
    if (merchandises.length) {
      for (let m of merchandises) {
        m.amount = '1'
        m.lotNumber = ''
        m.expectedDate = null
        m.note = ''
        m.unit = 'ou'

        const warehousing = merchandiseMapping[m.id].warehousing[form.source]
        if (warehousing) {
          const stock = (inventories[m.id] ?? 0)
          if (!stock) {
            addError(m, 'amount', '超過庫存數量')
          }
        }
      }
      updateFormData({ name: 'merchandises' }, form.merchandises.concat(merchandises));
    }
  }

  function validateField(field, value) {
    // if (field.required && value === '') {
    //   return formatMessage({id: 'form.field.isRequired'})
    // }
    if (field.name === 'merchandises') {
      for (const m of value) {
        if (m.errors && m.errors.amount) {
          return m.errors.amount
        }
      }
    } else if (field.name === 'expectedDate' && value === '') {
      return formatMessage({ id: 'form.date.formatError' })
    }
    return ''
  }

  function updateFormData(field, value) {
    let newValue = value
    if (field.uppercase) {
      newValue = newValue.toUpperCase()
    }

    let err = validateField(field, value)

    let newData = { ...form, [field.name]: newValue, [`${field.name}_err`]: err }
    setForm(newData)
  }

  async function handleSave() {
    setLoading(true);

    const fields = [
      { name: 'source' },
      { name: 'date' },
      { name: 'merchandises' },
      { name: 'note' },
      { name: 'createdBy' }
    ]

    let newData = form
    // TODO: 檢查看看有沒有紅字, 有的話就跳出去, 不用儲存
    for (const m of form.merchandises) {
      if (m.errors && Object.keys(m.errors).length > 0) {
        setLoading(false)
        return
      }
      if (m.items) {
        for (const i of m.items) {
          if (i.errors && Object.keys(i.errors).length > 0) {
            setLoading(false)
            return
          }
        }
      }
    }

    let data = { formName }
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.merchandises = data.merchandises.reduce((acc, cur) => {
      acc[cur.id] = {
        unit: cur.unit === 'ou' ? merchandiseMapping[cur.id].orderUnit : merchandiseMapping[cur.id].sku,
        unitToSku: cur.unit === 'ou' ? merchandiseMapping[cur.id].ou2sku : 1,
        expectedDate: cur.expectedDate,
        lotNumber: cur.lotNumber,
        amount: parseInt(cur.amount),
        note: cur.note,
      }

      if (cur.items) {
        acc[cur.id].items = []
        for (const item of cur.items) {
          const amount = (isNaN(item.amount) || item.amount === '' || parseInt(item.amount) === 0) ? 0 : parseInt(item.amount)
          if (amount) {
            const expectedDate = (item.expectedDate === null || item.expectedDate.toString() === 'Invalid Date') ?
              '' : dayjs(item.expectedDate).format('YYYY-MM-DD')
            acc[cur.id].items.push({
              amount,
              lotNumber: item.lotNumber || '',
              expectedDate,
            })
          }
        }
      } else {
        const expectedDate = (cur.expectedDate === null || cur.expectedDate.toString() === 'Invalid Date') ?
          '' : dayjs(cur.expectedDate).format('YYYY-MM-DD')
        acc[cur.id].items = [{
          amount: parseInt(cur.amount),
          lotNumber: cur.lotNumber || '',
          expectedDate,
        }]
        acc[cur.id].lotNumber = ''
        acc[cur.id].expectedDate = ''
      }

      return acc
    }, {})

    if (form.id === 'new') {
      try {
        await callFunction('saveStockRequisition', data)
      } catch (ex) {
        console.log(ex)
      }
    }
    handleClose()
  }

  function handleClose() {
    navigate(`/stock/${formName}/pending`);
  }

  function getSourceSelector() {
    if (userRights.onlyVendor(`${formName}-create`)) {
      return (<TextField
        disabled
        type="text"
        label={formatMessage({ id: `${formName}.table.detail.source` })}
        variant="outlined"
        value={customerMapping[userRights.onlyVendor(`${formName}-create`)].nickname}
        fullWidth
        size="small"
      />)
    } else {
      return (<TextField
        select
        required
        disabled={lockSource}
        type="text"
        label={formatMessage({ id: `${formName}.table.detail.source` })}
        variant="outlined"
        value={form.source}
        onChange={onSourceChanged}
        fullWidth
        size="small"
        error={form.source_err ? true : false}
        helperText={form.source_err}
      >
        {filteredSources.map(c => <MenuItem key={c.id} value={c.id}>
          {c.nickname}
        </MenuItem>)}
      </TextField>)
    }
  }

  const selectedMerchandises = (form.merchandises || []).reduce((acc, cur) => {
    acc[cur.id] = true
    return acc
  }, {});

  return (
    <div style={{ flexGrow: 1 }}>
      {openDialog.name === 'merchandise' && <SelectMerchandiseDialog
        multiSelect
        maxWidth="lg"
        headerCells={[{ name: 'code', sort: 'code' },{ name: 'nickname', sort: 'nickname' },{ name: 'orderUnit' },{ name: 'note' }]}
        rowCells={[{ field: 'code' },{ field: 'nickname', tooltip: 'name' },{ field: 'ou' },{ field: 'note' }]}
        filterItems={[{ name: 'nickname' },{ name: 'name' },{ name: 'code' },{ name: 'note' }]}
        dialogTitle={formatMessage({ id: 'selectMerchandiseDialog.title' })}
        tableTitle={formatMessage({ id: 'selectMerchandiseDialog.table.title' })}
        handleClose={() => setOpenDialog({ name: '', data: '' })}
        handleSave={onMerchandiseChanged}
        defaultSelectedItems={[]}
        items={merchandises.filter(m => !selectedMerchandises[m.id])}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            {getSourceSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: `${formName}.table.detail.createdBy` })}
              variant="outlined"
              value={userMapping[form.createdBy] ? userMapping[form.createdBy].displayName : ''}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: `${formName}.table.detail.date` })}
              variant="outlined"
              value={dayjs(form.date).format('YYYY-MM-DD')}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: '8px 0px' }} />
        <EnhancedTableToolbar
          title={`edit${capitalize(formName)}.table.title`}
          selectdMessage={`edit${capitalize(formName)}.table.selected`}
          numSelected={Object.keys(selectedItems).length}
          toolbox={
            <Button
              disabled={form.source === ''}
              sx={{ m: 1, whiteSpace: 'nowrap' }}
              variant="contained"
              color="primary"
              onClick={() => {
                setLockSource(true)
                setOpenDialog({ name: 'merchandise', data: '' })
              }}
            >
              <FormattedMessage id={`edit${capitalize(formName)}.selectMerchandise`} />
            </Button>
          }
          toolboxForSelection={
            <>
              <Button
                variant="contained"
                color="primary"
                style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
                onClick={onAddLotNumber}
              >
                <FormattedMessage id={`edit${capitalize(formName)}.addLotNumber`} />
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<DeleteIcon />}
                style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
                onClick={onDeleteItems}
              >
                <FormattedMessage id={`edit${capitalize(formName)}.removeMerchandise`} />
              </Button>
            </>
          }
        />
        <EnhancedTable
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={headerCells}
          rowCells={rowCells}
          onHeaderCheckboxClick={handleSelectAllClick}
          onRowCheckboxClick={handleCheckboxClick}
          getSelectionCount={() => Object.keys(selectedItems).length}
          getRowCheckBoxStatus={merchandise => selectedItems[merchandise.id] || false}
          getRowExpandedStatus={merchandise => merchandise.items ? true : false}
          forceExpanded={true}
          getExpandContent={(merchandise) =>
            merchandise.items ? <MerchandiseItem
              formName={formName}
              merchandiseId={merchandise.id}
              items={merchandise.items}
              onItemValueChanged={onItemValueChanged}
            /> : null
          }
          tableData={form.merchandises.map(m => formatData(m))}
        />
        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: `${formName}.table.detail.note` })}
              variant="outlined"
              value={form.note}
              onChange={e => updateFormData({ name: 'note' }, e.target.value)}
              fullWidth
              size="small"
              multiline
            />
          </Grid>
        </Grid>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={loading || form.merchandises.length === 0}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.submit" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}

export default EditStockRequisitionPage;
