import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloseIcon from '@mui/icons-material/Close';

import Editor from 'components/Editor';
import { getStorageURL } from 'modules/firebase';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AnnouncementFullView({ onClose, data: _data }) {
  const [data, setData] = useState(null)

  useEffect(() => {
    if (_data) {
      const newData = { ..._data }
      // 把 content 從 storage 裡讀出來.
      getStorageURL(`announcements/${newData.id}/content`).then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.addEventListener('load', (e) => {
          const promises = []
          newData.content = xhr.responseText
          // 把 content 中的圖片 src 轉換成可用的圖片 url
          for(const i of newData.files) {
            promises.push(getStorageURL(`announcements/${newData.id}/${i.md5}.${i.ext}`))
          }
          Promise.all(promises).then(urls => {
            for(const i in newData.files) {
              newData.content = newData.content.replace(newData.files[i].md5, urls[i])
            }
            setData(newData)
          })
        });
        xhr.open('GET', url, true);
        xhr.send();
      })
    } else {
      setData(null)
    }
    return () => {};
  }, [_data]);

  function downloadFile(file) {
    getStorageURL(`announcements/${data.id}/attachments/${file.md5}`).then(url => {
      var link = document.createElement('a');
      link.download = file.fileName;
      link.href = url;
      link.click();
    })
  }

  return <Dialog
    fullScreen
    open={!!data}
    onClose={onClose}
    TransitionComponent={Transition}
  >
    <AppBar sx={{ position: 'relative' }}>
      <Toolbar>
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          主旨：{data?.subject ?? ''}
        </Typography>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <Box p={2}>
      <Editor readonly={true} outlined={false} content={data?.content ?? ''} />
      {data?.attachments?.length && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 8 }}>
        <Typography variant="h6" component="div">
          附檔：
        </Typography>
        {(data?.attachments ?? []).map( i => <Chip key={i.md5} sx={{ padding: 1, cursor: 'pointer' }} color="primary" icon={<CloudDownloadIcon />} label={i.fileName} onClick={() => downloadFile(i)}/>)}
      </div>}
    </Box>
  </Dialog>
}

export default AnnouncementFullView;