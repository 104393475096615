const i18n = {
  'report': {
    'header': {
      'sales': {
        'day': '日報表',
        'interval': '區間報表',
        'year': '年報表'
      }
    },
    'table': {
      'customer': {
        'appointment': '預約人數',
        'arrived': '到客人數',
        'arrivalRate': '到客比例',
        'consumAmount': '消費人數',
        'consumRate': '成交比例'
      },
      'sales': {
        'day': '當日營收',
        'interval': '區間營收',
        'year': '年營收',
        'cash': '現金',
        'swipe': '刷卡',
        'wireTransfer': '匯款',
        'pointCard': '儲值點數',
        'uncollected': '未付清',
      },
      'service': {
        'total': '總額',
        'rate': '總比例',
      },
      'performance': {
        'edit': '編輯目標設定',
        'monthTarget': '當月業績目標',
        'revenue': '當日營收',
        'revenueTotal': '當月累積營收',
        'collected': '當月業績達成率',
        'uncollected': '當月業績未達成率',
        'dayBalance': '本月剩餘天數',
        'dayRevenue': '本月每日需達業績'
      },
      'salesOrder' : {
        'sn': '營收單號',
        'date': '日期',
        'customer': '客戶',
        'agent': '負責人',
        'price': '總金額'
      },
      'date': '選擇日期',
      'dateY': '選擇年份',
      'title': {
        'day': '日報表',
        'interval': '區間報表',
        'year': '年報表'
      }
    },
    'dialog': {
      'title': '業績目標設定',
      'source': '公司',
      'monthTarget': '業績目標設定',
    }
  },
  'settingReport': {
    'title': '報表設定',
    'reportCategory': {
      'title': '產品分類',
      'table': {
        'header': {
          'name': '名稱',
          'tags': '標籤',
          'status': '狀態',
        }
      }
    }
  }
}

export default i18n
