import dayjs from 'dayjs';

function doctortPunchCount(date, key, shift, special, leave) {
  let schedule = {};
  let shifts
  let specials = []
  let leaves
  if (special) {
    for (const time of Object.keys(special)) {
      const first = special[time].firstDate
      const last = special[time].lastDate

      if ((first === dayjs(date).format('YYYY-MM-DD') && last === dayjs(date).format('YYYY-MM-DD'))) {
        specials.push({ id: time, ...special[time] })
      }
    }
  }

  if (shift) {
    for (const time of Object.keys(shift)) {
      const repeats = shift[time].repeats
      const first = shift[time].firstDate
      const last = shift[time].lastDate
      const dateM = dayjs(date);
      const weeks = dateM.week() - dayjs(dateM).startOf('month').week() + 1;

      if (repeats === 'next_weeks') {
        if (dayjs(first).week() % 2 === dayjs(date).week() % 2 && dayjs(first).day() === dayjs(date).day() && dayjs(date).isSameOrAfter(dayjs(first))) {
          if (last) {
            if (dayjs(date).isSameOrBefore(dayjs(last))) {
              shifts = shift[time]
              shifts.id = time
            }
          } else {
            shifts = shift[time]
            shifts.id = time
          }
        }
      } else {
        if (dayjs(first).day() === dayjs(date).day() && dayjs(date).isSameOrAfter(dayjs(first))) {
          if (repeats === 'every_week') {
            if (last) {
              if (dayjs(date).isSameOrBefore(dayjs(last))) {
                shifts = shift[time]
                shifts.id = time
              }
            } else {
              shifts = shift[time]
              shifts.id = time
            }
          } else if (repeats === 'odd_weeks' && weeks % 2 === 1) {
            if (last) {
              if (dayjs(date).isSameOrBefore(dayjs(last))) {
                shifts = shift[time]
                shifts.id = time
              }
            } else {
              shifts = shift[time]
              shifts.id = time
            }
          } else if (repeats === 'even_weeks' && weeks % 2 === 0) {
            if (last) {
              if (dayjs(date).isSameOrBefore(dayjs(last))) {
                shifts = shift[time]
                shifts.id = time
              }
            } else {
              shifts = shift[time]
              shifts.id = time
            }
          }
        }
      }

    }
  }

  if (leave) {
    for (const time of Object.keys(leave)) {
      const first = leave[time].firstDate
      const last = leave[time].lastDate

      if ((first === dayjs(date).format('YYYY-MM-DD') && last === dayjs(date).format('YYYY-MM-DD'))) {
        leaves = leave[time]
        leaves.id = time
      }
    }
  }

  schedule.leaves = leaves
  schedule.shifts = shifts
  schedule.specials = specials.sort((a, b) => {
    if (a.startTime < b.startTime) {
      return -1
    } else if (a.startTime > b.startTime) {
      return 1
    } else {
      return 0
    }
  })
  return schedule
}

export {
  doctortPunchCount,
}