import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import Toolbar from '@mui/material/Toolbar';

import LoadingIndicator from 'components/LoadingIndicator';
import EnhancedSwitch from 'components/EnhancedSwitch';
import TabPanel from 'components/TabPanel';
import SelectUserDialog from 'components/SelectUserDialog';
import { getUserRightGroups } from 'modules/moduleMgr';
import { unwrap } from 'modules/uitls';
import ContextStore from 'modules/context';
import { useModules } from 'hooks/modules';
import { callFunction } from 'modules/firebase';
import { firebaseV8 } from 'modules/firebaseV8';

export default function EditUserRights() {
  const { formatMessage } = useIntl();
  const { currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const [globalRights, setGlobalRights] = useState({})
  const [userRightData, setUserRightData] = useState(null)
  const [userDialogData, setUserDialogData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const userMapping = useSelector(state => state.users.data)
  const _users = useSelector(state => state.users.ordered)

  const users = currentUser.developer ? _users.filter(u => u.active) : _users.filter(u => u.active && !u.developer)
  const modules = useModules()
  const userRightGroups = getUserRightGroups(modules)
  const filteredModules = modules.filter(m => userRightGroups[m].length)

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('userRights').onSnapshot(snapshot => {
      const newMap = {}
      snapshot.forEach(doc => {
        const data = unwrap(doc.data());
        if (doc.id === '*') {
          setGlobalRights(data)
        } else {
          for (const k in data) {
            if (data[k]) {
              if (!newMap[k]) {
                newMap[k] = []
              }
              newMap[k].push(doc.id)
            }
          }
        }
      });
      setUserRightData(newMap)
    }, err => { })

    return () => unsubscribe()
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function onUsersChanged(selectedItems, params) {
    const newUser = { ...userRightData }
    let value = []

    for (const user of selectedItems) {
      value.push(user.id)
    }

    newUser[params] = value
    setUserRightData({ ...newUser })
  }

  function showSelectUserPopup(field, data) {
    setUserDialogData({ index: field, users: data })
  }

  function updateGlobalRights(name, value) {
    if (value) {
      setGlobalRights({ ...globalRights, [name]: true })
    } else {
      const newValue = { ...globalRights }
      delete newValue[name]
      setGlobalRights(newValue)
    }
  }

  async function handleSave() {
    setLoading(true)
    const newUserRightData = {}
    for (const m of filteredModules) {
      for (const f of userRightGroups[m]) {
        const name = f.replace('*', '')
        newUserRightData[name] = userRightData[name] || []
      }
    }

    const data = {
      userRightData: newUserRightData,
      globalRightData: globalRights,
    }
    try {
      await callFunction('saveUserRights', data)
      setLoading(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  function createField(field) {
    let name = field.name
    const globalOption = field.name.startsWith('*')
    if (globalOption) {
      name = name.substring(1)
    }
    let newValue = userRightData[name] ?
      (currentUser.developer ? userRightData[name] : userRightData[name].filter(u => !(userMapping[u].developer))).map(user => userMapping[user]?.displayName).join(', ') :
      ''

    return (
      <Grid key={name} item xs={12} sm={12} md={12}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            disabled={globalRights[name]}
            multiline={field.multiline}
            type="text"
            label={formatMessage({ id: `userRights.${name}` })}
            value={globalRights[name] ? '全部使用者' : newValue}
            fullWidth
            size="small"
            variant="outlined"
            onClick={(e) => {
              if (!globalRights[name]) {
                showSelectUserPopup(name, userRightData[name] ?? [])
              }
            }}
          />
          {globalOption && <div style={{ marginLeft: '20px', width: '180px' }}>
            <EnhancedSwitch checked={!!globalRights[name]} onChange={e => updateGlobalRights(name, e.target.checked)} rightLabel="全部使用者" />
          </div>}
        </div>
      </Grid>
    )
  }

  function handleClose() {
    navigate('/setting/userRight')
  }

  return !!userRightData ? (
    <div style={{ padding: '12px 24px 80px 24px' }}>
      {userDialogData && <SelectUserDialog
        multiSelect
        headerCells={[{ name: 'displayName', sort: 'displayName' },{ name: 'email' }]}
        rowCells={[{ field: 'displayName' },{ field: 'email' }]}
        filterItems={[{ name: 'displayName' },{ name: 'email' }]}
        dialogTitle={formatMessage({ id: 'selectUserDialog.title' })}
        tableTitle={formatMessage({ id: 'selectUserDialog.table.title' })}
        handleClose={() => setUserDialogData(null)}
        handleSave={onUsersChanged}
        defaultSelectedItems={userDialogData.users ?? []}
        items={users}
        params={String(userDialogData.index)}
      />}
      <Toolbar sx={{ pl: 2, pr: 1 }}>
        <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="userRights.edit" />
        </Typography>
      </Toolbar>

      <Paper square>
        <Tabs
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={handleTabChange}
        >
          {filteredModules.map(module => <Tab key={module} label={formatMessage({ id: `modules.${module}` })} />)}
        </Tabs>
      </Paper>
      <Paper>
        <Box p={3} style={{ marginTop: '20px' }}>
          {filteredModules.map((module, idx) => (
            <TabPanel key={module} value={selectedTab} index={idx}>
              <Grid sx={{ marginBottom: '10px' }} container spacing={2}>
                {userRightGroups[module].map(field => createField({ name: field, multiline: false, md: 3 }))}
              </Grid>
            </TabPanel>
          ))}
        </Box>
      </Paper>
      <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
        <Button variant="contained" color="primary" onClick={handleClose}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          onClick={handleSave}
          disabled={loading}
          loading={loading}
          loadingPosition="start"
          loadingIndicator={<CircularProgress size={24} />}
          startIcon={<div />}
          variant="contained"
        >
          <FormattedMessage id="button.save" />
        </LoadingButton>
      </Stack>
    </div>
  ) : <LoadingIndicator />;
}
