const i18n = {
  'setAttendance': {
    'title': '出勤',
    'reviewPolicy': {
      'title': '簽核流程設定',
      'table': {
        'header': {
          'name': '名稱',
          'note': '說明',
        },
        'detail': {
          'name': '名稱',
        }
      },
      'addStep': '新增簽核步驟',
      'dialog': {
        'title': {
          'add': '新增簽核流程',
          'edit': '編輯簽核流程',
        }
      },
    },
    'worktimePolicy': {
      'title': '工時設定',
      'table': {
        'header': {
          'name': '名稱',
          'note': '說明',
          'day': ' ',
          'workytime': '上班時間',
          'workhours': '當日最大工時'
        },
        'detail': {
          'name': '名稱',
        }
      },
      'dialog': {
        'title': {
          'add': '新增工時設定',
          'edit': '編輯工時設定',
        }
      },
    },
    'weeklyPolicy': {
      'title': '本休設定',
      'table': {
        'header': {
          'name': '名稱',
          'note': '說明',
          'year': '年度',
          'day': ' ',
          'weeklymonths': '休假月份',
          'weeklydays': '當月最大天數'
        },
        'detail': {
          'name': '名稱',
        }
      },
      'dialog': {
        'title': {
          'add': '新增本休設定',
          'edit': '編輯本休設定',
        }
      },
    },
    'systemPolicy': {
      'title': '系統設定',
      'edit': '編輯其他設定',
      'table': {
        'annualLeaveType': {
          'root': '年假制度',
          'anniversary': '週年制',
          'calendar': '歷年制'
        }
      },
    },
    'editWeeklyPolicy': {
      'table': {
        'title': '本休設定',
        'detail': {
          'name': '設定名稱',
          'note': '說明',
          'year': '年度'
        }
      },
    },
    'editWorktimePolicy': {
      'table': {
        'title': '工時設定',
        'detail': {
          'name': '設定名稱',
          'note': '說明',
        }
      },
    },
    'editReviewPolicy': {
      'table': {
        'title': '簽核步驟',
        'detail': {
          'name': '流程名稱',
          'note': '說明',
          'rule': '額外規則',
          'leaveDays': '請假 {count} 天以上',
          'stepName': '步驟名稱',
          'approver': '簽核者',
        }
      },
      'deleteStep': '刪除步驟'
    }
  }
}
export default i18n
