import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';

import EnhancedTable from 'components/EnhancedTable';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import SelectVerdorDialog from 'components/SelectVerdorDialog';
import ContextStore from 'modules/context';
import { addError, removeError } from 'modules/editor';
import { firestoreListener, callFunction } from 'modules/firebase';
import SelectSourceOrderDialog from 'components/SelectSourceOrderDialog';

function MerchandiseItem({ lock = false, merchandiseId, items, onItemValueChanged }) {
  const { formatMessage } = useIntl()
  const headerCells = [
    { field: 'amount' },
    { field: 'lotNumber' },
    { field: 'expectedDate' },
  ].map(c => {c.text = formatMessage({ id: `deliveryOrder.merchandise.${c.field}` });return c})

  const rowCells = lock ? [
    { field: 'amount' },
    { field: 'lotNumber', type: 'input', required: false, label: '批號', onValueChanged: onCellValueChanged },
    { field: 'expectedDate', type: 'date', required: false, label: '效期', onValueChanged: onCellValueChanged },
  ] : [
    { field: 'amount', type: 'input-number', required: true, label: '出貨量', onValueChanged: onCellValueChanged },
    { field: 'lotNumber', type: 'input', required: false, label: '批號', onValueChanged: onCellValueChanged },
    { field: 'expectedDate', type: 'date', required: false, label: '效期', onValueChanged: onCellValueChanged },
  ]

  function onCellValueChanged(data, field, value) {
    const m = items[data.key]
    if (field === 'expectedDate') {
      m.expectedDate = value
      if (value === null) {
        removeError(m, field)
      } else if (value.toString() === 'Invalid Date') {
        addError(m, field, formatMessage({ id: 'form.date.formatError' }))
      } else {
        removeError(m, field)
      }
    } else {
      m[field] = value
    }
    onItemValueChanged(merchandiseId, field)
  }

  return (
    <EnhancedTable
      headerCells={headerCells}
      rowCells={rowCells}
      tableData={items}
    />
  )
}

MerchandiseItem.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
  onItemValueChanged: PropTypes.func.isRequired,
  merchandiseId: PropTypes.string.isRequired,
  extra: PropTypes.bool,
  lock: PropTypes.bool,
};

function EditDeliveryOrderPage({ formName }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const navigate = useNavigate()
  const location = useLocation()
  const { deliveryOrderId } = useParams()

  let refSn = ''
  let refId = ''
  let deliveryOrderSourceField = ''
  if (formName === 'deliveryOrder') {
    refSn = 'purchaseOrderSn'
    refId = 'purchaseOrderId'
    deliveryOrderSourceField = 'deliveryOrder-create'
  } else if (formName === 'borrowingDelivery') {
    refSn = 'borrowingOrderSn'
    refId = 'borrowingOrderId'
    deliveryOrderSourceField = 'borrowingDelivery-create'
  } else if (formName === 'returnBorrowingForm') {
    refSn = 'borrowingOrderSn'
    refId = 'borrowingOrderId'
    deliveryOrderSourceField = 'returnBorrowingForm-create'
  }

  const customerMapping = useSelector(state => state.internalVendors.data)
  const customers = useSelector(state => state.internalVendors.ordered)

  const filteredSources = customers.filter(c => userRights.hasUserRightForVendor(deliveryOrderSourceField, c.id))
  const merchandiseMapping = useSelector(state => state.merchandises.data)
  const userMapping = useSelector(state => state.users.data)

  const supplierMapping = useSelector(state => state.suppliers.data)
  const suppliers = useSelector(state => state.suppliers.ordered)

  const [selectedItems, setSelectedItems] = useState({});
  const [loading, setLoading] = useState(false);
  const [lockSource, setLockSource] = useState(false);
  const [lockDestination, setLockDestination] = useState(false);

  const [openDialog, setOpenDialog] = useState({ name: '', data: '' });
  const [inventories, setInventories] = useState({});
  const [sourceOrders, setSourceOrders] = useState([]);
  const [deliveryOrderItemMapping, setDeliveryOrderItemMapping] = useState({});

  let deliveryOrder = {}
  const sourceOrderIds = [...new Set(sourceOrders.map(p => p.id))]
  const sourceOrderSortString = sourceOrderIds.sort().join(',')
  const [owDeliveryOrder, setOwDeliveryOrder] = useState({});

  deliveryOrder = { ...{
    id: 'new',
    source: '',
    destination: '',
    date: dayjs().format('YYYY-MM-DD'),
    createdBy: currentUser.key,
    createdAt: Math.floor(Date.now() / 1000) * 1000,
    note: '',
    discount: '',
    merchandises: [],
  }, ...owDeliveryOrder }

  deliveryOrder.merchandises.forEach(m => {
    m.maxAmount = m.poAmount - (deliveryOrder[`${m[refId]}@${m.id}`] || 0)
    // const ou = merchandiseMapping[m.id].orderUnit
    // const sku = merchandiseMapping[m.id].sku
    // const ou2sku = merchandiseMapping[m.id].ou2sku
    // if (m.warehousing) {
    //   if (m.orderBySku) {
    //     m.stock = realtimeStock[m.id] + sku
    //   } else {
    //     m.stock = (ou !== sku ? Math.floor(realtimeStock[m.id] / ou2sku) : realtimeStock[m.id]) + ou
    //   }
    // } else {
    //   m.stock = formatMessage({ id: 'merchandise.warehousing.type2' })
    // }
  })

  const currentSourceOrder = deliveryOrder.merchandises.length ? sourceOrders.find(p => p.id === deliveryOrder.merchandises[0][refId]) : null
  const filteredSourceOrders = getFilteredSourceOrders()

  useEffect(() => {
    if (filteredSources.length === 1) {
      updateDeliveryOrderData({ name: 'source' }, filteredSources[0].id);
    }
  }, [filteredSources.length]);

  useEffect(() => {
    const unsubscribe = deliveryOrder.source ? firestoreListener({
      collection: 'inventories',
      doc: deliveryOrder.source,
      unwrap: true,
      onData: (data) => {
        setInventories(data)
      }
    }) : null

    return () => unsubscribe?.()
  }, [deliveryOrder.source]);

  useEffect(() => {
    let link = ''
    let text = ''
    if (formName === 'deliveryOrder') {
      link = '/delivery/deliveryOrder'
      text = formatMessage({ id: 'sideMenu.delivery.deliveryOrder' })
    } else if (formName === 'borrowingDelivery') {
      link = '/borrowing/borrowingDelivery'
      text = formatMessage({ id: 'sideMenu.borrowing.borrowingDelivery' })
    } else if (formName === 'returnBorrowingForm') {
      link = '/borrowing/returnBorrowingForm'
      text = formatMessage({ id: 'sideMenu.borrowing.returnBorrowingForm' })
    }
    const breadcrumbs = [{ link, text }]
    if (deliveryOrderId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: `${formName}.dialog.title.add` }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: `${formName}.dialog.title.edit` }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  // useEffect(() => {
  //   // 撈即時庫存
  //   const today = dayjs().format('YYYY-MM-DD')
  //   const unsubscribe = owDeliveryOrder.source ? firebase.firestore().collection('wh').doc(owDeliveryOrder.source).collection('logs').where('date', '==', today)
  //     .onSnapshot( snapshot => {
  //       const logs = []
  //       snapshot.forEach(doc => {
  //         const data = doc.data()
  //         if (data.valid !== false) {
  //           logs.push(data)
  //         }
  //       });
  //       setLogs(logs)
  //     }, err => {}) : null
  //   return () => {if (unsubscribe) unsubscribe()}
  // }, [owDeliveryOrder.source]);

  // useEffect(() => {
  //   // 撈即時庫存
  //   const yesterday = dayjs().subtract(1, 'days').format('YYYY-MM-DD')
  //   const unsubscribe = owDeliveryOrder.source ? firebase.firestore().collection('wh').doc(owDeliveryOrder.source).collection('dailySnapshot').doc(yesterday)
  //     .onSnapshot( snapshot => {
  //       setDailySnapshot(snapshot?.data()?.extData || {})
  //     }, err => {}) : null
  //   return () => {if (unsubscribe) unsubscribe()}
  // }, [owDeliveryOrder.source]);

  useEffect(() => {
    // NEED FIX: formName
    const months = formName === 'deliveryOrder' ? 2 : 12
    const date = dayjs().subtract(months, 'months').format('YYYY-MM')

    const unsubscribe = sourceOrderIds.length ? firestoreListener({
      collection: `${formName}s`,
      where: [['date', '>=', date]],
      unwrap: true,
      onData: (data) => {
        const itemMapping = {}
        const idField = formName === 'deliveryOrder' ? 'purchaseOrderId' : 'borrowingOrderId'

        data.forEach(docData => {
          // const r = unwrap(doc.data())
          if (docData.status !== 'void' && docData[idField] && docData.id !== deliveryOrderId && sourceOrderIds.includes(docData[idField])) {
            const keys = Object.keys(docData.merchandises)
            for (const key of keys) {
              const fullId = `${docData[idField]}@${key}`
              if (itemMapping[fullId] === undefined) {
                itemMapping[fullId] = docData.merchandises[key].amount
              } else {
                itemMapping[fullId] = itemMapping[fullId] + docData.merchandises[key].amount
              }
            }
          }
        });
        setDeliveryOrderItemMapping(itemMapping)
      }
    }) : null

    return () => {if (unsubscribe) unsubscribe()}
  }, [sourceOrderSortString]);


  useEffect(() => {
    const months = formName === 'deliveryOrder' ? 2 : 12
    const date = dayjs().subtract(months, 'months').format('YYYY-MM')
    const collectionName = formName === 'deliveryOrder' ? 'purchaseOrders' : 'borrowingOrders'

    const deliveryOrderSupplier = userRights[deliveryOrderSourceField].filter(c => customerMapping[c]) // userRights 舊寫法, 之後可能要想辦法改寫

    const unsubscribe = firestoreListener({
      collection: collectionName,
      where: [
        ['status', '==', 'done'],
        ['date', '>=', date]
      ],
      unwrap: true,
      filter: (sourceOrder) => {
        if (formName === 'returnBorrowingForm') {
          if (sourceOrder.closeout && !sourceOrder.returnAll && userRights.hasUserRightForVendor(deliveryOrderSourceField, sourceOrder.source)) {
            return true
          }
        } else {
          if (!sourceOrder.closeout && sourceOrder.deliveryOrder && deliveryOrderSupplier.includes(sourceOrder.supplier)) {
            return true
          }
        }
        return false
      },
      onData: (data) => {
        setSourceOrders(data)
      }
    })

    return () => unsubscribe()
  }, []); // NEED FIX: dep

  const _headerCells = [
    { field: 'code', sort: 'code', order: 0 },
    { field: 'name', order: 1 },
    { field: 'stock', order: 3 },
    { field: 'require', order: 4 },
    { field: 'amount', order: 5 },
    { field: 'orderUnit', order: 6 },
    { field: 'lotNumber', order: 7 },
    { field: 'expectedDate', order: 8 },
    { field: 'note', align: 'right', order: 9 },
  ]

  const _rowCells = [
    { field: 'code', order: 0 },
    { field: 'nickname', tooltip: 'name', order: 1 },
    { field: 'stock', order: 3 },
    { field: 'require', order: 4 },
    { field: 'amount', type: 'input-number', required: true, label: '出貨量', onValueChanged: onCellValueChanged, getEnableStatus, order: 5 },
    { field: 'orderUnit', order: 6 },
    { field: 'lotNumber', type: 'input', required: false, label: '批號', onValueChanged: onCellValueChanged, getEnableStatus, order: 7 },
    { field: 'expectedDate', type: 'date', required: false, label: '效期', onValueChanged: onCellValueChanged, getEnableStatus, order: 8 },
    { field: 'note', align: 'right', type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged, order: 9 },
  ]

  const headerCells = (formName === 'deliveryOrder' ?
    [..._headerCells,
      { field: 'unitPrice', order: 2 }] :
    _headerCells
  ).map(c => ({
    ...c,
    text: formatMessage({ id: `${formName}.merchandise.${c.field}` })
  })).sort((a, b) => a.order - b.order)

  const rowCells = (formName === 'deliveryOrder' ?
    [..._rowCells,
      { field: 'unitPrice', order: 2 }] :
    _rowCells
  ).sort((a, b) => a.order - b.order)

  function getEnableStatus(merchandise, field) {
    if (merchandise.items) {
      return false
    }
    return true
  }

  function getFilteredSourceOrders() {
    if (formName === 'returnBorrowingForm') {
      return currentSourceOrder ? [currentSourceOrder] : (deliveryOrder.destination !== '' ? sourceOrders.filter(p =>  p.supplier === deliveryOrder.destination) : [])
    } else {
      return currentSourceOrder ? [currentSourceOrder] : (deliveryOrder.destination !== '' ? sourceOrders.filter(p =>  p.source === deliveryOrder.destination) : [])
    }
  }

  function formatData(merchandise) {
    const m = merchandiseMapping[merchandise.id]
    const newData = {
      ...merchandise,
      code: m.code,
      name: m.name,
      nickname: m.nickname,
      orderUnit: m.orderUnit,
      sku: m.sku,
      ou2sku: m.ou2sku,
      stock: m.warehousing[deliveryOrder.source] ? `${inventories[merchandise.id] ?? 0}${m.sku}` : '不入庫',
      require: `${merchandise.maxAmount}${merchandise.orderUnit}`
    }
    return newData
  }

  function onCellValueChanged(data, field, value) {
    for (let m of deliveryOrder.merchandises) {
      if (m.id === data.id) {
        m[field] = value
        const warehousing = merchandiseMapping[m.id].warehousing[deliveryOrder.source]
        if (field === 'amount') {
          const stock = (inventories[data.id] ?? 0)
          if (isNaN(value) || value === '' || value === '0') {
            addError(m, field, '數量錯誤')
          } else if (value > data.maxAmount) {
            addError(m, field, '超過需求量')
          } else if (warehousing && ((data.unit === 'ou' && value * data.ou2sku > stock) || (data.unit === 'sku' && value > stock))) {
            addError(m, field, '超過庫存數量')
          } else {
            removeError(m, field)
          }
        } else if (field === 'unitPrice') {
          m.unitPrice = value
          if (isNaN(value) || value === '' || parseFloat(value) === 0) {
            addError(m, field, '價格錯誤')
          } else {
            removeError(m, field)
          }
        } else if (field === 'expectedDate') {
          if (value === null) {
            removeError(m, field)
          } else if (value.toString() === 'Invalid Date') {
            addError(m, field, formatMessage({ id: 'form.date.formatError' }))
          } else {
            removeError(m, field)
          }
        }
        break
      }
    }
    updateDeliveryOrderData({ name: 'merchandises' }, deliveryOrder.merchandises);
  }

  function onItemValueChanged(id, field) {
    if (field === 'amount') {
      for (const m of deliveryOrder.merchandises) {
        if (m.id === id) {
          const warehousing = merchandiseMapping[m.id].warehousing[deliveryOrder.source]
          // 更新 parent 的 amount, 檢查看看有沒有超過最大值
          let total = 0
          for (const i of m.items) {
            if (isNaN(i.amount) || i.amount === '' || parseInt(i.amount) === 0) {
            } else {
              total += parseInt(i.amount)
            }
          }
          m.amount = total
          const stock = (inventories[m.id] ?? 0)
          if (total === 0) {
            addError(m, 'amount', '數量錯誤')
          } else if (total > m.maxAmount) {
            addError(m, 'amount', '超過需求量')
          } else if (warehousing && ((m.unit === 'ou' && total * m.unitToSku > stock) || (m.unit === 'sku' && total > stock))) {
            addError(m, 'amount', '超過庫存數量')
          } else {
            removeError(m, field)
          }
          break
        }
      }
    }
    updateDeliveryOrderData({ name: 'merchandises' }, deliveryOrder.merchandises)
  }

  function onAddLotNumber() {
    const merchandises = deliveryOrder.merchandises
    for (const m of merchandises) {
      if (selectedItems[m.id]) {
        if (!m.items) {
          m.items = [{
            key: '0',
            amount: m.amount,
            lotNumber: m.lotNumber,
            expectedDate: m.expectedDate,
          }]
          m.lotNumber = ''
          m.expectedDate = null
        }
        m.items.push({
          key: String(m.items.length),
          amount: 0,
          lotNumber: '',
          expectedDate: null,
        })
      }
    }
    setSelectedItems({});
    updateDeliveryOrderData({ name: 'merchandises' }, merchandises);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = deliveryOrder.merchandises.reduce((acc, cur) => {acc[cur.id] = true;return acc}, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  }

  function handleCheckboxClick({ id }) {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function onDeleteItems() {
    const merchandises = deliveryOrder.merchandises.filter(m => !selectedItems[m.id])
    if (merchandises.length !== deliveryOrder.merchandises.length) {
      updateDeliveryOrderData({ name: 'merchandises' }, merchandises);
      setSelectedItems({});
    }
  }

  const onSourceChanged = (e) => {
    updateDeliveryOrderData({ name: 'source' }, e.target.value);
    if (e.target.value === deliveryOrder.supplier) {
      updateDeliveryOrderData({ name: 'supplier' }, '');
    }
  }

  const onSupplierChanged = (supplier) => {
    updateDeliveryOrderData({ name: 'supplier' }, supplier.id);
  }

  function onMerchandiseChanged(merchandises) {
    if (merchandises.length) {
      for (let m of merchandises) {
        // const warehousing = merchandiseMapping[m.id].warehousing
        m.expectedDate = null
        m.lotNumber = ''
        m.note = ''

        m.delivery = m.poAmount - (deliveryOrder[`${m[refId]}@${m.id}`] || 0)
        if (m.orderUnit === merchandiseMapping[m.id].orderUnit) {
          m.unit = 'ou'
          m.unitToSku = merchandiseMapping[m.id].ou2sku;
        } else {
          m.unit = 'sku'
          m.unitToSku = 1;
        }

        const warehousing = merchandiseMapping[m.id].warehousing[deliveryOrder.source]
        if (warehousing) {
          const stock = (inventories[m.id] ?? 0)
          if (!stock) {
            addError(m, 'amount', '超過庫存數量')
          }
        }
      }
      updateDeliveryOrderData({ name: 'merchandises' }, deliveryOrder.merchandises.concat(merchandises));
    }
  }

  function validateField(field, value) {
    // if (field.required && value === '') {
    //   return formatMessage({id: 'form.field.isRequired'})
    // }
    if (field.name === 'merchandises') {
      for (const m of value) {
        if (m.errors && m.errors.amount) {
          return m.errors.amount
        }
      }
    } else if (field.name === 'expectedDate' && value === '') {
      return formatMessage({ id: 'form.date.formatError' })
    }
    return ''
  }

  function updateDeliveryOrderData(field, value) {
    let newValue = value
    if (field.uppercase) {
      newValue = newValue.toUpperCase()
    }

    let err = validateField(field, value)

    let newData = { ...deliveryOrder, [field.name]: newValue, [`${field.name}_err`]: err }
    setOwDeliveryOrder(newData)
  }

  async function handleSave() {
    setLoading(true);

    const fields = [
      { name: 'source' },
      { name: 'destination' },
      { name: 'supplier' },
      { name: 'date' },
      { name: 'merchandises' },
      { name: 'note' },
      { name: 'createdBy' }
    ]

    let newData = deliveryOrder
    // TODO: 檢查看看有沒有紅字, 有的話就跳出去, 不用儲存
    for (const m of deliveryOrder.merchandises) {
      if (m.errors && Object.keys(m.errors).length > 0) {
        setLoading(false)
        return
      }
      if (m.items) {
        for (const i of m.items) {
          if (i.errors && Object.keys(i.errors).length > 0) {
            setLoading(false)
            return
          }
        }
      }
    }

    let data = {}
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.merchandises = data.merchandises.reduce((acc, cur) => {
      acc[cur.id] = {
        [refId]: cur[refId],
        [refSn]: cur[refSn],
        unit: cur.unit === 'ou' ? merchandiseMapping[cur.id].orderUnit : merchandiseMapping[cur.id].sku,
        unitToSku: cur.unit === 'ou' ? merchandiseMapping[cur.id].ou2sku : 1,
        expectedDate: cur.expectedDate,
        lotNumber: cur.lotNumber,
        amount: parseInt(cur.amount),
        note: cur.note,
      }
      if (formName === 'deliveryOrder') {
        acc[cur.id].unitPrice = parseFloat(cur.unitPrice)
      }

      if (cur.items) {
        acc[cur.id].items = []
        for (const item of cur.items) {
          const amount = (isNaN(item.amount) || item.amount === '' || parseInt(item.amount) === 0) ? 0 : parseInt(item.amount)
          if (amount) {
            const expectedDate = (item.expectedDate === null || item.expectedDate.toString() === 'Invalid Date') ?
              '' : dayjs(item.expectedDate).format('YYYY-MM-DD')
            acc[cur.id].items.push({
              amount,
              lotNumber: item.lotNumber || '',
              expectedDate,
            })
          }
        }
      } else {
        const expectedDate = (cur.expectedDate === null || cur.expectedDate.toString() === 'Invalid Date') ?
          '' : dayjs(cur.expectedDate).format('YYYY-MM-DD')
        acc[cur.id].items = [{
          amount: parseInt(cur.amount),
          lotNumber: cur.lotNumber || '',
          expectedDate,
        }]
        acc[cur.id].lotNumber = ''
        acc[cur.id].expectedDate = ''
      }

      return acc
    }, {})

    data[refId] = currentSourceOrder ? currentSourceOrder.id : ''

    if (deliveryOrder.id === 'new') {
      try {
        await callFunction('saveDeliveryOrder', { id: deliveryOrder.id, formName, ...data })
      } catch (ex) {
        console.log(ex)
      }
    }
    handleClose()
  }

  function handleClose() {
    navigate('/delivery/deliveryOrder');
  }

  function getSourceSelector() {
    if (userRights.onlyVendor('deliveryOrder-create')) {
      return (<TextField
        disabled
        type="text"
        label={formatMessage({ id: 'deliveryOrder.table.detail.source' })}
        variant="outlined"
        value={customerMapping[userRights.onlyVendor('deliveryOrder-create')].nickname}
        fullWidth
        size="small"
      />)
    } else {
      return (<TextField
        select
        required
        disabled={lockSource}
        type="text"
        label={formatMessage({ id: 'deliveryOrder.table.detail.source' })}
        variant="outlined"
        value={deliveryOrder.source}
        onChange={onSourceChanged}
        fullWidth
        size="small"
        error={deliveryOrder.source_err ? true : false}
        helperText={deliveryOrder.source_err}
      >
        {filteredSources.map(c => <MenuItem key={c.id} value={c.id}>
          {c.nickname}
        </MenuItem>)}
      </TextField>)
    }
  }

  function getDestinationSelector() {
    if (formName === 'returnBorrowingForm') {
      return (<TextField
        required
        disabled={lockDestination}
        type="text"
        size="small"
        label={formatMessage({ id: `${formName}.table.detail.destination` })}
        variant="outlined"
        onClick={() => setOpenDialog({ name: 'supplier', data: '' })} //open popup
        value={deliveryOrder.destination ? supplierMapping[deliveryOrder.destination].nickname : ''}
        error={deliveryOrder.destination_err ? true : false}
        helperText={deliveryOrder.destination_err}
        fullWidth
      />)
    } else {
      if (deliveryOrder.destination && !customerMapping[deliveryOrder.destination]) {
        return (<TextField
          required
          disabled={lockDestination}
          type="text"
          size="small"
          label={formatMessage({ id: `${formName}.table.detail.destination` })}
          variant="outlined"
          onClick={() => setOpenDialog({ name: 'supplier', data: '' })} //open popup
          value={deliveryOrder.destination ? supplierMapping[deliveryOrder.destination].nickname : ''}
          error={deliveryOrder.destination_err ? true : false}
          helperText={deliveryOrder.destination_err}
          fullWidth
        />)
      } else {
        return (<TextField
          select
          required
          disabled={lockDestination}
          type="text"
          label={formatMessage({ id: `${formName}.table.detail.destination` })}
          variant="outlined"
          value={deliveryOrder.destination}
          onChange={e => {
            if (e.target.value === 'external') {
              setOpenDialog({ name: 'supplier', data: '' }) //(!lockDestination ? { external: true } : null)
            } else {
              updateDeliveryOrderData({ name: 'destination' }, e.target.value)
            }
          }}
          fullWidth
          size="small"
          error={deliveryOrder.destination_err ? true : false}
          helperText={deliveryOrder.destination_err}
        >
          {customers.filter(c => c.id !== deliveryOrder.source).map(c => <MenuItem key={c.id} value={c.id}>
            {c.nickname}
          </MenuItem>)}
          <Divider />
          <MenuItem value={'external'}>外部廠商</MenuItem>
        </TextField>)
      }
    }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {openDialog.name === 'supplier' && <SelectVerdorDialog
        headerCells={[
          { name: 'code', sort: 'code' },
          { name: 'name' },
          { name: 'nickname' },
          { name: 'businessNumber' }
        ]}
        rowCells={[
          { field: 'code' },
          { field: 'name' },
          { field: 'nickname' },
          { field: 'businessNumber' }
        ]}
        filterItems={[{ name: 'name' },{ name: 'nickname' },{ name: 'businessNumber' }]}
        dialogTitle={formatMessage({ id: 'selectSupplierDialog.title' })}
        tableTitle={formatMessage({ id: 'selectSupplierDialog.table.title' })}
        handleClose={() => setOpenDialog({ name: '', data: '' })}
        handleSave={onSupplierChanged}
        defaultSelectedItems={[]}
        items={suppliers.filter(i => i.id !== deliveryOrder.source)}
      />}
      {openDialog.name === 'sourceOrder' && <SelectSourceOrderDialog
        sourceOrders={filteredSourceOrders}
        defaultSelectedItems={[]}
        handleClose={() => setOpenDialog({ name: '', data: '' })}
        handleSave={onMerchandiseChanged}
        ignoreIds={deliveryOrder.merchandises.map(m => `${m[refId]}@${m.id}`)}
        receiptItemMapping={deliveryOrderItemMapping}
        refId={refId}
        refSn={refSn}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            {getSourceSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {getDestinationSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'deliveryOrder.table.detail.createdBy' })}
              variant="outlined"
              value={userMapping[deliveryOrder.createdBy] ? userMapping[deliveryOrder.createdBy].displayName : ''}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'deliveryOrder.table.detail.createdAt' })}
              variant="outlined"
              value={dayjs(deliveryOrder.date).format('YYYY-MM-DD')}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: '8px 0px' }} />
        <EnhancedTableToolbar
          title="editDeliveryOrder.table.title"
          selectdMessage="editDeliveryOrder.table.selected"
          numSelected={Object.keys(selectedItems).length}
          toolbox={
            <Button
              disabled={deliveryOrder.source === '' || deliveryOrder.destination === '' || (currentSourceOrder && Object.keys(currentSourceOrder.merchandises).length === deliveryOrder.merchandises.length)}
              sx={{ m: 1, whiteSpace: 'nowrap' }}
              variant="contained"
              color="primary"
              onClick={() => {
                setLockSource(true)
                setLockDestination(true)
                setOpenDialog({ name: 'sourceOrder', data: '' })
              }}
            >
              <FormattedMessage id={formName === 'deliveryOrder' ? 'editDeliveryOrder.addFromPurchaseOrder' : 'editDeliveryOrder.addFromBorrowingOrder'} />
            </Button>
          }
          toolboxForSelection={
            <>
              <Button
                variant="contained"
                color="primary"
                style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
                onClick={onAddLotNumber}
              >
                <FormattedMessage id="editDeliveryOrder.addLotNumber" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<DeleteIcon />}
                style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
                onClick={onDeleteItems}
              >
                <FormattedMessage id="editReturnForm.removeMerchandise" />
              </Button>
            </>
          }
        />
        <EnhancedTable
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={headerCells}
          rowCells={rowCells}
          onHeaderCheckboxClick={handleSelectAllClick}
          onRowCheckboxClick={handleCheckboxClick}
          getSelectionCount={() => Object.keys(selectedItems).length}
          getRowCheckBoxStatus={merchandise => selectedItems[merchandise.id] || false}
          getRowExpandedStatus={merchandise => merchandise.items ? true : false}
          forceExpanded={true}
          getExpandContent={(merchandise) =>
            merchandise.items ? <MerchandiseItem
              merchandiseId={merchandise.id}
              items={merchandise.items}
              onItemValueChanged={onItemValueChanged}
              // lock={returnForm.lock}
            /> : null
          }
          tableData={deliveryOrder.merchandises.map(m => formatData(m))}
        />
        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'deliveryOrder.table.detail.note' })}
              variant="outlined"
              value={deliveryOrder.note}
              onChange={e => updateDeliveryOrderData({ name: 'note' }, e.target.value)}
              fullWidth
              size="small"
              multiline
            />
          </Grid>
        </Grid>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={loading || deliveryOrder.merchandises.length === 0}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.submit" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}

EditDeliveryOrderPage.propTypes = {
  formName: PropTypes.string.isRequired,
};

export default EditDeliveryOrderPage;
