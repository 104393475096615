import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';

import { calculate } from 'modules/uitls';
import { getComparator, stableSort } from 'modules/sort';
import EnhancedTable from './EnhancedTable';

const EnhancedTableEx = forwardRef(({ headerCells, rowCells, tableData, ...props }, ref) => {
  const { defaultOrder, defaultOrderField } = props
  const _order = useRef(defaultOrder)
  const _orderBy = useRef(defaultOrderField)

  useImperativeHandle(
    ref, () => ({
      saveToFile: (fileName, onFinish) => {
        return _saveToFile(fileName, onFinish)
      }
    }),
  )

  function onOrderChanged({ order, orderBy }) {
    _order.current = order
    _orderBy.current = orderBy
  }

  const getCellText = (cellData, cell) => {
    if (cell.type === 'calculate') {
      return calculate(cellData, cell.calculate)
    } else {
      // NEED FIX: input-menu 要用 cell.getMenuItems 才能得到真正的選單文字, 先不處理
      return cellData[cell.field]
    }
  }

  function _saveToFile(fileName, onFinish) {
    const rows = [headerCells.map((cell) => cell.text).join(',')]
    const rowsData = _order.current && _orderBy.current ? stableSort(tableData, getComparator(_order.current, _orderBy.current)) : tableData
    rowsData.forEach(rowData => {
      rows.push(rowCells.map(cell => getCellText(rowData, cell)).join(','))
    })
    const content = rows.join('\n')
    const csvData = new Blob([content], { type: 'text/csv;charset=utf-8;' })
    const csvUrl = URL.createObjectURL(csvData)
    const anchor = document.createElement('a')
    anchor.href = csvUrl
    anchor.setAttribute('target', '_blank')
    anchor.setAttribute('download', fileName)
    anchor.click()
    URL.revokeObjectURL(csvUrl)
    if (onFinish) {
      onFinish()
    }
  }

  return <EnhancedTable headerCells={headerCells} rowCells={rowCells} tableData={tableData} onOrderChanged={onOrderChanged} {...props} />
})

EnhancedTableEx.displayName = 'EnhancedTableEx'

EnhancedTableEx.propTypes = {
  defaultOrder: PropTypes.oneOf(['asc', 'desc']),
  defaultOrderField: PropTypes.string,
  headerCells: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    sort: PropTypes.string,
    align: PropTypes.string,
  })).isRequired,
  rowCells: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    align: PropTypes.string,
    type: PropTypes.string,
    calculate: PropTypes.string,
    label: PropTypes.string,
    onValueChanged: PropTypes.func,
  })).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EnhancedTableEx;
