import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import SelectItemDialog from './SelectItemDialog';

function SelectMerchandiseDialog({ multiSelect, headerCells, rowCells, filterItems, items, handleSave, ...props }) {
  const { formatMessage } = useIntl()
  const config = useSelector(state => state.config.data)
  const categorys = Object.keys(config.category || {}).filter(i => config.category[i].active).map(i => config.category[i])

  const _headerCells = headerCells.map(i => { i.text = formatMessage({ id: `merchandise.table.header.${i.name}` }); return i })
  const _filterItems = filterItems.map(i => { i.text = formatMessage({ id: `merchandise.table.header.${i.name}` }); return i })
  const categoryFilterItems = [
    { name: 'type', type: 'select', default: '', size: 4, items: getMenuItem('type') },
    { name: 'category', type: 'select', default: '', size: 4, items: getMenuItem('category') },
    { name: 'warehousing', type: 'select', default: '', size: 4, items: getMenuItem('warehousing') },
  ].map(i => { i.text = formatMessage({ id: `merchandise.table.detail.${i.name}` }); return i })


  function getMenuItem(name) {
    if (name === 'type') {
      return [{ value: '' }, { value: 'g' }, { value: 'i' }].map(i => {
        i.label = i.value ? formatMessage({ id: `merchandise.type.${i.value}` }) : formatMessage({ id: 'merchandise.category.all' })
        return i
      })
    } else if (name === 'category') {
      return [{ value: '' }].concat(categorys).map(i => {
        const newData = i.id ? {
          value: i.id,
          label: i.name
        } : { value: i.value, label: formatMessage({ id: 'merchandise.category.all' }) }
        // i.label = i.value ? formatMessage({ id: `merchandise.category.${i.value}` }) : formatMessage({ id: 'merchandise.category.all' })
        return newData
      })
    } else if (name === 'warehousing') {
      return [{ value: '' }, { value: 'type1' }, { value: 'type2' }].map(i => {
        i.label = i.value ? formatMessage({ id: `merchandise.warehousing.${i.value}` }) : formatMessage({ id: 'merchandise.category.all' })
        return i
      })
    }
    return []
  }

  function applyFilter(currentFilter, categoryFilter) {
    if (!currentFilter &&
      !categoryFilter.type &&
      !categoryFilter.category &&
      !categoryFilter.warehousing) {
      return items
    }

    if (categoryFilter.type) {
      if (categoryFilter.type === 'g') {
        return items.filter(i => ['G'].includes(i.type))
      } else if (categoryFilter.type === 'i') {
        return items.filter(i => ['I'].includes(i.type))
      }
    }

    if (categoryFilter.category) {
      return items.filter(i => i.category === categoryFilter.category)
    }
    if (categoryFilter.warehousing) {
      const type = categoryFilter.warehousing === 'type1'
      return items.filter(i => i.warehousing === type)
    }

    if (currentFilter) {
      return items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
    }

    return items
  }

  function onSave(selectedItems, params) {
    if (multiSelect) {
      const merchandises = items
        .filter(i => selectedItems[i.id])
        .map(i => ({
          name: i.name,
          nickname: i.nickname,
          code: i.code,
          sku: i.sku,
          orderUnit: i.orderUnit,
          ou2sku: i.ou2sku,
          orderBySku: i.orderBySku,
          id: i.id
        }))
      handleSave(merchandises, params)
    } else {
      handleSave(selectedItems, params)
    }
  }

  return (
    <SelectItemDialog
      multiSelect={multiSelect}
      headerCells={_headerCells}
      rowCells={rowCells}
      filterItems={_filterItems}
      categoryFilterItems={categoryFilterItems}
      applyFilter={applyFilter}
      handleSave={onSave}
      {...props}
    />
  );
}

SelectMerchandiseDialog.propTypes = {
  headerCells: PropTypes.arrayOf(PropTypes.object.isRequired),
  rowCells: PropTypes.arrayOf(PropTypes.object.isRequired),
  filterItems: PropTypes.arrayOf(PropTypes.object.isRequired),
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
  handleSave: PropTypes.func.isRequired,
};

export default SelectMerchandiseDialog;
