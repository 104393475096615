import React, { useEffect, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import FabAdd from 'components/FabAdd';
import EnhancedTableRow from 'components/EnhancedTableRow';
import EnhancedTableHead from 'components/EnhancedTableHead';
import SimpleTableToolbar from 'components/SimpleTableToolbar';
import ExpandButton from 'components/ExpandButton';
import SearchBox from 'components/SearchBox';
import NewCustomerDialog from 'components/NewCustomerDialog';
import ContextStore from 'modules/context';
import { objectToArray } from 'modules/data';
import { getComparator, stableSort } from 'modules/sort';
import { firestoreListener } from 'modules/firebase';
import ReviewCustomer from './ReviewCustomer';

function Customer() {
  const { setBreadcrumbs } = useContext(ContextStore)
  const { formatMessage } = useIntl()
  const navigate = useNavigate();
  const location = useLocation();
  const userRights = useSelector(state => state.userRights)
  const [customers, setCustomers] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(null)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [expand, setExpand] = useState(true);
  const userMapping = useSelector(state => state.users.data);
  const [openNewCustomerDialog, setOpenNewCustomerDialog] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [blackFilter, setBlackFilter] = useState('all')
  const [vipFilter, setVipFilter] = useState('all')

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.customers.list' })
    }])
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      onData: (data) => {
        setCustomers(objectToArray(data))
      }
    })

    return () =>  unsubscribe()
  }, []);

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name', sort: 'name' },
    { text: 'birthDate', sort: 'birthDate' },
    { text: 'phone', sort: 'phone' },
    { text: 'identityCardNumber', sort: 'identityCardNumber' },
    { text: 'salesRep', sort: 'salesRep' },
    { text: 'lastAppointmentDateTime', sort: 'lastAppointmentDateTime' },
  ].map(c => { c.text = formatMessage({ id: `customer.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'code' },
    { field: 'name' },
    { field: 'birthDate' },
    { field: 'phone' },
    { field: 'identityCardNumber' },
    { field: 'salesRepName' },
    { field: 'lastAppointmentDate' },
  ]

  const filterItems = [
    { name: 'code' },
    { name: 'name' },
    { name: 'birthDate', },
    { name: 'phone' },
    { name: 'salesRep' },
    { name: 'identityCardNumber' },
    { name: 'lastAppointmentDateTime' },
  ].map(i => { i.text = formatMessage({ id: `customer.table.${i.name}` }); return i })

  function filterByText() {
    if (currentFilter.name === 'code') {
      return customers.filter(s => s.code && s.code.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'name') {
      return customers.filter(s => s.name.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'birthDate') {
      return customers.filter(s => s.birthDate && s.birthDate.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'phone') {
      return customers.filter(s => s.phone && s.phone.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'salesRep') {
      return customers.filter(s => userMapping[s.salesRep] && userMapping[s.salesRep].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'lastAppointmentDateTime') {
      return customers.filter(s => s.lastAppointmentDateTime && s.lastAppointmentDateTime.split('_')[0].toLowerCase().toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'identityCardNumber') {
      return customers.filter(s => s.identityCardNumber && s.identityCardNumber.toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  const formatData = (customer) => {
    const newData = { ...customer }
    newData.salesRepName = newData.salesRep ? userMapping[newData.salesRep].displayName : ''
    newData.lastAppointmentDate = newData.lastAppointmentDateTime ? newData.lastAppointmentDateTime.split('_')[0] : ''

    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setPage(0)
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const handleClose = () => {
    setOpenNewCustomerDialog(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function CustomerFilter() {
    return (
      <Grid container spacing={3} sx={{ maxWidth: '480px' }}>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            select
            type="text"
            size="small"
            label={formatMessage({ id: 'customer.filter.blackList.root' })}
            variant="outlined"
            fullWidth
            onChange={e => setBlackFilter(e.target.value)}
            value={blackFilter}
          >
            {['all', 'on', 'off'].map((type, idx) => {
              return <MenuItem key={`${type}-${idx}`} value={type}>{formatMessage({ id: `customer.filter.blackList.${type}` })}</MenuItem>
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            select
            type="text"
            size="small"
            label={formatMessage({ id: 'customer.filter.vip.root' })}
            variant="outlined"
            fullWidth
            onChange={e => setVipFilter(e.target.value)}
            value={vipFilter}
          >
            {['all', 'on', 'off'].map((type, idx) => {
              return <MenuItem key={`${type}-${idx}`} value={type}>{formatMessage({ id: `customer.filter.vip.${type}` })}</MenuItem>
            })}
          </TextField>
        </Grid>
      </Grid>
    );
  }

  function filterByExtData(type, data) {
    let newData = data
    if (type === 'blackList') {
      if (blackFilter === 'on') {
        newData = newData.filter(d => d.blackList)
      } else if (blackFilter === 'off') {
        newData = newData.filter(d => !d.blackList)
      }
    } else {
      if (vipFilter === 'on') {
        newData = newData.filter(d => d.vip)
      } else if (vipFilter === 'off') {
        newData = newData.filter(d => !d.vip)
      }
    }

    return newData
  }

  let filteredCustomers = currentFilter && currentFilter.text ? filterByText() : customers
  if (blackFilter !== '') {
    filteredCustomers = filterByExtData('blackList', filteredCustomers)
  }
  if (vipFilter !== '') {
    filteredCustomers = filterByExtData('vip', filteredCustomers)
  }

  return (
    <Grid container spacing={1} sx={{ p: '40px 20px' }}>
      {openNewCustomerDialog && <NewCustomerDialog
        dialogTital={formatMessage({ id: 'customer.dialog.information.add' })}
        handleClose={handleClose}
        customers={customers}
      />}
      {userRights.hasUserRight('customer-create') && <FabAdd onClick={() => setOpenNewCustomerDialog(true)} />}
      <Grid item xs={12} sm={12} md={12}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <CustomerFilter />
          <SimpleTableToolbar
            title={'noMessage'}
            bottons={<ExpandButton open={expand} onExpandChange={setExpand} />}
            toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TableContainer component={Paper}>
          <Table
            size="small"
            aria-label="collapsible table"
          >
            <EnhancedTableHead
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredCustomers.length}
              expandable
              actionButton
            />
            <TableBody>
              {stableSort(filteredCustomers.map(r => formatData(r)), getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(customer => (
                  <EnhancedTableRow
                    key={customer.id}
                    rowCells={rowCells}
                    cellData={customer}
                    expandable
                    expandContent={<ReviewCustomer customerData={customer} />}
                    actionIcons={<Tooltip title={formatMessage({ id: 'button.info' })}>
                      <IconButton
                        onClick={() => navigate(`/customer/${customer.id}/profile`)}
                        size="large">
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ display: 'flex', justifyContent: 'center' }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={filteredCustomers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={formatMessage({ id: 'button.perPage' })}
          labelDisplayedRows={({ from, to, count }) => `第${from}至${to}列 (共${count}列)`}
        />
      </Grid>
    </Grid>
  );
}

export default Customer;
