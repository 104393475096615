import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FilterListIcon from '@mui/icons-material/FilterList';

import ContextStore from 'modules/context';

function ScheduleFilter() {
  const { uiState, setUiState  } = useContext(ContextStore)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleFilterMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const fiterCheck = (field, check) => {
    setUiState({
      ...uiState,
      [field]: check
    })
  }

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleFilterMenu}
        color="inherit"
        size="medium"
      >
        <FilterListIcon />
      </IconButton>
      <Menu
        id="menu-filter"
        sx={{ zIndex: 2100 }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleFilterClose}
      >
        <List>
          <ListItem dense>
            <FormControlLabel
              control={<Checkbox checked={uiState.showPunchClock} onChange={e => fiterCheck('showPunchClock', e.target.checked)} name="showPatientType" />}
              label={<FormattedMessage id={'header.schedule.filter.showPunchClock'} />}
            />
          </ListItem>
          <ListItem dense>
            <FormControlLabel
              control={<Checkbox checked={uiState.showLeave} onChange={e => fiterCheck('showLeave', e.target.checked)} name="showRoom" />}
              label={<FormattedMessage id={'header.schedule.filter.showLeave'} />}
            />
          </ListItem>
          <ListItem dense>
            <FormControlLabel
              control={<Checkbox checked={uiState.showOvertime} onChange={e => fiterCheck('showOvertime', e.target.checked)} name="showRoom" />}
              label={<FormattedMessage id={'header.schedule.filter.showOvertime'} />}
            />
          </ListItem>
          <ListItem dense>
            <FormControlLabel
              control={<Checkbox checked={uiState.showShift} onChange={e => fiterCheck('showShift', e.target.checked)} name="showRoom" />}
              label={<FormattedMessage id={'header.schedule.filter.showShift'} />}
            />
          </ListItem>
          <ListItem dense>
            <FormControlLabel
              control={<Checkbox checked={uiState.showWeeklyLeave} onChange={e => fiterCheck('showWeeklyLeave', e.target.checked)} name="showRoom" />}
              label={<FormattedMessage id={'header.schedule.filter.showWeeklyLeave'} />}
            />
          </ListItem>
          <ListItem dense>
            <FormControlLabel
              control={<Checkbox checked={uiState.showComment} onChange={e => fiterCheck('showComment', e.target.checked)} name="showRoom" />}
              label={<FormattedMessage id={'header.schedule.filter.showComment'} />}
            />
          </ListItem>
          <ListItem dense>
            <FormControlLabel
              control={<Checkbox checked={uiState.showException} onChange={e => fiterCheck('showException', e.target.checked)} name="showRoom" />}
              label={<FormattedMessage id={'header.schedule.filter.showException'} />}
            />
          </ListItem>
        </List>
      </Menu>
    </>
  )
}

export default ScheduleFilter;
