import InputMask from 'react-input-mask';

import TextField from '@mui/material/TextField';

export default function MaskInput({ disabled = false, required = false, mask, maskChar, onChange, value, label, helperText, ...props }) {
  return <InputMask
    mask={mask}
    maskChar={maskChar}
    onChange={onChange}
    value={value}
    disabled={disabled}
  >
    {() => <TextField
      required={required}
      disabled={disabled}
      type="text"
      size="small"
      label={label}
      variant="outlined"
      onCompositionStart={
        e => {
          e.target.addEventListener('input', e2 => {
            if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e2.data)) {
              e2.preventDefault()
              e2.stopPropagation()
              e2.stopImmediatePropagation()
            }
          }, { once: true })
        }
      }
      error={!!helperText}
      helperText={helperText}
      fullWidth
      {...props}
    />}
  </InputMask>
}
