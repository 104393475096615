import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import SimpleTableToolbar from 'components/SimpleTableToolbar';
import SelectUserDialog from 'components/SelectUserDialog';
import { getVendorUserRight } from 'modules/moduleMgr';
import { useModules } from 'hooks/modules';
import { callFunction } from 'modules/firebase';

const StepRow = styled('div')(() => ({
  width: '100%',
  height: '80px',
  backgroundColor: '#fff',
  padding: '0px 20px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}));

function EditActionStaffDialog({ vendor, workflowName, workflow: _workflow, handleClose }) {
  const { formatMessage } = useIntl()
  const modules = useModules()

  const userMapping = useSelector(state => state.users.data)
  const users = useSelector(state => state.users.ordered).filter(u => u.active)
  const customerMapping = useSelector(state => state.internalVendors.data)

  const [userDialogData, setUserDialogData] = useState(null);
  const [loading, setLoading] = useState(false);

  // const [approveSteps, setApproveSteps] = useState([]);
  const [workflow, setWorkflow] = useState(_workflow);

  const vendorUserRightMapping = getVendorUserRight(modules)
  const fields = vendorUserRightMapping[workflowName];

  function onWorkflowUsersChanged(selectedItems, index) {
    updateWorkflowUsers(fields[index], selectedItems.map(i => i.id))
  }

  function updateWorkflowUsers(field, value) {
    const newData = { ...workflow }

    newData[`${field}_err`] = ''
    newData[field] = value
    setWorkflow(newData)
  }

  function showSelectUserPopup(index) {
    const users = workflow[fields[index]]
    setUserDialogData({ index, users })
  }

  async function handleSave() {
    setLoading(true);

    const wf = { ...workflow }
    for (const field of fields) {
      delete wf[`${field}_err`]
    }

    try {
      await callFunction('updateWorkflow', {
        id: vendor.id,
        [workflowName]: {
          ...wf,
        },
      })
    } catch (ex) {
      console.log(ex)
    }
    handleClose()
  }

  return (
    <Dialog
      // ref={modalBody}
      fullWidth={true}
      maxWidth={'lg'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id={`vendor.dialog.title.${vendor.id === 'new' ? 'add' : 'edit'}`} />
      </DialogTitle>
      <DialogContent dividers={true}>
        {userDialogData && <SelectUserDialog
          multiSelect
          headerCells={[{ name: 'displayName', sort: 'displayName' },{ name: 'email' }]}
          rowCells={[{ field: 'displayName' },{ field: 'email' }]}
          filterItems={[{ name: 'displayName' },{ name: 'email' }]}
          dialogTitle={formatMessage({ id: 'selectUserDialog.title' })}
          tableTitle={formatMessage({ id: 'selectUserDialog.table.title' })}
          handleClose={() => setUserDialogData(null)}
          handleSave={onWorkflowUsersChanged}
          defaultSelectedItems={userDialogData.users ?? []}
          items={users}
          params={String(userDialogData.index)}
        />}
        <Box>
          <SimpleTableToolbar
            title={`editVendorUserRights.${workflowName}.table.title`}
            note={`(${customerMapping[vendor.id].nickname})`}
          />
          <Paper>
            {fields.map((field, index) => (<StepRow key={field}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    type="text"
                    label={formatMessage({ id: `editVendorUserRights.${workflowName}.table.detail.name` })}
                    variant="outlined"
                    value={formatMessage({ id: `editVendorUserRights.${workflowName}.table.${field}` })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    // required={field !== 'deliveryOrder'}
                    type="text"
                    label={formatMessage({ id: `editVendorUserRights.${workflowName}.table.detail.user` })}
                    variant="outlined"
                    fullWidth
                    value={(workflow[field] || []).map(s => userMapping[s].displayName).join(', ')}
                    onClick={() => showSelectUserPopup(index)}
                    error={workflow[`${field}_err`] ? true : false}
                    helperText={workflow[`${field}_err`]}
                  />
                </Grid>
              </Grid>
            </StepRow>))}
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          onClick={handleSave}
          disabled={loading}
          loading={loading}
          loadingPosition="start"
          loadingIndicator={<CircularProgress size={24} />}
          startIcon={<div />}
          variant="contained"
        >
          <FormattedMessage id="button.save" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

EditActionStaffDialog.propTypes = {
  vendor: PropTypes.object,
  workflowName: PropTypes.string.isRequired,
  workflow: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EditActionStaffDialog;
