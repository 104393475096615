const i18n = {
  'editDataType': {
    'treatments': {
      'add': '新增服務',
      'table': {
        'title': '服務設定',
        'detail': {
          'name': '服務名稱',
          'color': '顏色',
          'duration': '服務時間',
          'note': '說明',
        }
      },
    },
    'appointmentType': {
      'add': '新增科別',
      'table': {
        'title': '科別設定',
        'detail': {
          'name': '科別名稱',
          'users': '醫生',
          'note': '說明',
        }
      },
    },
    'rooms': {
      'add': '新增診間',
      'table': {
        'title': '診間設定',
        'detail': {
          'name': '診間名稱',
          'note': '說明',
        }
      },
    },
    'salesReps': {
      'add': '新增部門',
      'table': {
        'title': '服務人員設定',
        'detail': {
          'departments': '部門',
        }
      },
    },
    'paymentType': {
      'add': '新增付款方式',
      'table': {
        'title': '付款方式',
        'detail': {
          'name': '付款方式',
          'note': '說明',
        }
      },
    },
    'departments': {
      'add': '新增部門',
      'table': {
        'title': '部門設定',
        'detail': {
          'name': '部門名稱',
          'users': '部門成員',
        }
      },
    },
    'occupation': {
      'add': '新增職稱',
      'table': {
        'title': '職稱設定',
        'detail': {
          'name': '職稱名稱',
          'users': '職稱成員',
        }
      },
    },
    'category': {
      'add': '新增分類',
      'table': {
        'title': '分類設定',
        'detail': {
          'name': '分類名稱',
          'note': '說明',
        }
      },
    },
    'reportCategory': {
      'add': '新增分類',
      'table': {
        'title': '分類設定',
        'detail': {
          'name': '分類名稱',
          'tags': '標籤',
        }
      },
    },
  }
}
export default i18n
