import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs'
import clsx from 'clsx'
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { arrayMove } from '@dnd-kit/sortable';

import { useTheme, StyledEngineProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';

import TabContainer from 'containers/TabContainer/TabContainer';
import MaskInput from 'components/MaskInput';
import DatePickerField from 'components/DatePickerField';
import SelectCustomerDialog from 'components/SelectCustomerDialog';
import NewCustomerDialog from 'components/NewCustomerDialog';
import SelectProductDialog from 'components/SelectProductDialog';
import ButtonProgress from 'components/ButtonProgress';
import AlertDialog from 'components/AlertDialog';
import DndContainer from 'components/DndContainer';
import { calculateAge } from 'modules/time';
import { getRandomHash } from 'modules/uitls';
import { objectToArray } from 'modules/data';
import { firestoreListener, firestoreDeleteField, addFirestoreDoc, updateFirestoreDoc } from 'modules/firebase';
import { moibleMedia } from 'constants';
import { useModuleMapping } from 'hooks/modules';
import { firebaseV8 } from 'modules/firebaseV8';

const rowStyle = {
  display: 'flex',
  flexDirection: 'row',
  userSelect: 'none',
  zIndex: 11,
  minHeight: '40px'
}

const itemColor = {
  margin: '1px 0',
  width: '10px',
  minHeight: '100%',
  display: 'inline-flex',
  borderRadius: '5px',
  alignSelf: 'stretch'
}

const mainWrap = {
  width: '100%'
}

const itemMain = {
  minHeight: '40px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  background: '#fff',
  userSelect: 'none'
}

const itemName = {
  flex: 1,
  padding: '0 10px',
  fontSize: '15px',
  color: '#414141'
}

const itemDuration = {
  padding: '0 20px',
  fontSize: '15px',
  color: '#757575'
}

const itemEdit = {
  borderTop: '1px solid #E4E4E4',
  borderBottom: '1px solid #E4E4E4',
  padding: '0 18px 10px',
  background: '#F6F6F6',
}

const durationOptions = [
  { time: 5 }, { time: 10 }, { time: 15 }, { time: 30 }, { time: 45 }, { time: 60 }, { time: 90 },
  { time: 120 }, { time: 150 }, { time: 180 }, { time: 210 }, { time: 240 }, { time: 270 }, { time: 300 }, { time: 330 },
  { time: 360 }, { time: 390 }, { time: 420 }, { time: 450 }, { time: 480 }
]

const TreatmentItem = ({ item, index, handleTreatmentChange }) => {
  const { formatMessage } = useIntl()
  const [open, setOpen] = useState(false);
  const treatment = item
  let deleteOpen = true

  if (treatment.done) {
    deleteOpen = false
  }

  return (
    <div style={rowStyle}>
      <div style={{ ...itemColor, backgroundColor: treatment.color }} />
      <div style={mainWrap}>
        <div style={itemMain} onClick={() => setOpen(!open)}>
          <div style={itemName}>
            {treatment.name || ''}
          </div>
          <div style={itemDuration}>
            {treatment.duration || ''} <FormattedMessage id={'appointment.sidebar.minutesShort'} />
          </div>
        </div>
        <Collapse in={open} timeout="auto" unmountOnExit style={itemEdit}>
          <Grid container spacing={1} style={{ padding: '8px 0 0 0' }}>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                fullWidth
                id={'name'}
                size='small'
                disabled
                onChange={(e, val) => handleTreatmentChange(index, 'name', e.target.value)}
                label={formatMessage({ id: 'appointment.sidebar.treatment.showAs' })}
                value={treatment.name}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Autocomplete
                id="duration"
                freeSolo
                value={String(treatment.duration)}
                inputValue={String(treatment.duration)}
                onChange={(e, val) => handleTreatmentChange(index, 'duration', val ?? '')}
                options={durationOptions.map((option) => String(option.time))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size='small'
                    label={formatMessage({ id: 'appointment.sidebar.treatment.duration' })}
                    onChange={(e, val) => handleTreatmentChange(index, 'duration', e.target.value.replace(/[^0-9]/g, ''))}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <TextField
                fullWidth
                id={'nickname'}
                size='small'
                onChange={(e) => handleTreatmentChange(index, 'nickname', e.target.value)}
                label={formatMessage({ id: 'appointment.sidebar.treatment.showNickName' })}
                value={treatment.nickname || ''}
              />
            </Grid>
            {deleteOpen && <Grid item xs={4} sm={4} md={4} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <Button onClick={(e) => handleTreatmentChange(index, false, item.id)}>
                <DeleteIcon />
                <FormattedMessage id='appointment.sidebar.treatment.delete' defaultMessage='Delete' />
              </Button>
            </Grid>}
          </Grid>
        </Collapse>
      </div>
    </div >
  );
};

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '400px',
    height: '100vh',
    backgroundColor: '#ffffff',
    borderLeft: '1px solid #D4D4D4',
    zIndex: 10,
    paddingBottom: '60px',
    [moibleMedia]: {
      position: 'fixed',
      top: '100px',
      bottom: 0,
      left: 0,
      height: 'auto',
      width: '100vw',
      paddingBottom: 0
    }
  },
  form: {
    width: '100%',
    height: 'calc(100% - 60px)',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  header: {
    padding: '0 17px',
    backgroundColor: '#ffffff',
    '& h2': {
      marginBottom: '5px',
    }
  },
  dateTime: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px',
    marginTop: '20px'
  },
  datePicker: {
    width: '140px',
    display: 'inline-flex',
    marginRight: '30px'
  },
  tabContainer: {
    background: '#fff',
    padding: '0 22px'
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },

  addItemButton: {
    color: 'rgb(238,204,196) !important',
    display: 'flex !important'
  },
  footer: {
    bottom: 0,
    padding: '0 24px 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  customerInfo: {
    fontSize: '15px',
    lineHeight: '15px',
    padding: '16px 0 0',
    textAlign: 'left',
    color: '#383838',
    '& span:nth-child(2)':
    {
      display: 'inline-block',
      paddingLeft: '5px'
    },
    '& label:nth-child(2)': {
      minWidth: '170px'
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    '&:nth-child(even)': {
      margin: '0 10px 0 25px'
    }
  },
  menuIndex: {
    zIndex: 2100
  },
});

function AppointmentSidebar({ currentUser, onRequestClose, ui, currentCompany }) {
  const { formatMessage } = useIntl()
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(null)
  const currentAppointment = ui.editAppointment ? { ...ui.editAppointment } : null
  const isTreatmentDone = currentAppointment?.treatmentDone ?? false
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [customers, setCustomers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSelectCustomerDialog, setOpenSelectCustomerDialog] = useState(false)
  const [openNewCustomerDialog, setOpenNewCustomerDialog] = useState(false)
  const [showProductSelect, setShowProductSelect] = useState(false)
  const userRights = useSelector(state => state.userRights)
  const config = useSelector(state => state.config.data)
  const moduleMapping = useModuleMapping()
  const treatmentTemplates = Object.keys(config.treatments || {}).filter(i => config.treatments[i].active).map(i => config.treatments[i])
  const userMapping = useSelector(state => state.users.data)
  const users = useSelector(state => state.users.ordered).filter(u => !u.developer).filter(u => !u.developer)

  const [selectCustomerData, setSelectCustomerData] = useState({});
  const productMapping = useSelector(state => state.products.data)
  const products = useSelector(state => state.products.ordered)
  const rooms = Object.keys(config.rooms || {}).filter(i => config.rooms[i].active).map(i => config.rooms[i])
  const salesRepsDepartments = config.salesReps?.departments || []
  const appointmentTypes = [{
    id: 'normalAppointment',
    name: '一般預約'
  }].concat(Object.keys(config.appointmentType || {}).filter(i => config.appointmentType[i].active).map(i => config.appointmentType[i]))
  const menuOpen = Boolean(anchorEl);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const defultTreatment = currentAppointment ? objectToArray(currentAppointment.treatments)
    .map(t => ({ ...t })).sort((a, b) => {
      return a.order - b.order
    }) : []
  let defultData = currentAppointment ? {
    doctor: currentAppointment.doctor || '',
    nurse: currentAppointment.nurse || '',
    salesRep: currentAppointment.salesRep || '',
    infoSource: currentAppointment.infoSource || '',
    appointmentType: currentAppointment.appointmentType || '',
    room: currentAppointment.room || '',
    comment: currentAppointment.comment || '',
    date: dayjs(currentAppointment.date),
    time: currentAppointment.time,
    customer: currentAppointment.customer || '',
  } : {
    doctor: ui.clickMenuData?.doctor?.id || '',
    nurse: '',
    salesRep: ui.clickMenuData?.salesRep?.id || '',
    infoSource: '',
    appointmentType: ui.clickMenuData?.appointmentType || 'normalAppointment',
    room: '',
    comment: '',
    date: dayjs(ui.date),
    time: ui.clickMenuData ? `${String(ui.clickMenuData.hour).padStart(2, '0')}:00` : ':',
    customer: ''
  }
  const [data, setData] = useState(defultData);
  const [treatmentList, setTreatmentList] = useState(defultTreatment);
  const appointmentId = currentAppointment ? currentAppointment.id : 'new'
  const doctors = [];
  const nurses = [];
  const appTheme = useTheme();
  const isMobile = useMediaQuery(appTheme.breakpoints.down('mobile'));
  const salesReps = users.filter(i => i.active && salesRepsDepartments.includes(i.department) && i.company?.includes(currentCompany))

  for (const u of users) {
    if (u.active) {
      if (u.department === 'doctor' && u.company.includes(currentCompany)) {
        doctors.push({ ...u })
      } else if (u.department === 'nurse' && u.company.includes(currentCompany)) {
        nurses.push({ ...u })
      }
    }
  }

  const filterDoctors = doctors.filter(doctor => doctor.appointmentType === data.appointmentType)

  useEffect(() => {
    if (ui.editAppointment) {
      setData(defultData)
      setTreatmentList(defultTreatment)
    }
    return () => { }
  }, [ui.editAppointment]);

  useEffect(() => {
    const unsubscribe = data.customer ? firestoreListener({
      collection: 'customers',
      doc: data.customer,
      onData: (customerData) => {
        if (customerData) {
          let newData = data
          let newSelect = { ...customerData, name: customerData?.name ?? '' }

          if (currentAppointment && !currentAppointment.salesRep) {
            newData.salesRep = customerData.salesRep || ''
          } else if (!newData.salesRep && newSelect.salesRep) {
            newData.salesRep = newSelect.salesRep
          }

          setData(newData)
          setSelectCustomerData(newSelect)
        }
      }
    }) : null

    return () => unsubscribe?.()
  }, [data.customer]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      onData: (data) => {
        setCustomers(objectToArray(data))
      }
    })

    return () => unsubscribe()
  }, [data.customer]);

  async function saveAppointment() {
    setLoadingApprove(true)
    const newData = { ...data }

    let timeError = false

    if (newData.time) {
      const newTimeH = newData.time.split(':')[0] || undefined
      const newTimeM = newData.time.split(':')[1] || undefined
      if ((!newTimeH || !newTimeM) || (newTimeH === ' ' || newTimeM === ' ')) {
        timeError = true
      }
      if (newTimeH > '24') {
        timeError = true
      }

      if (newTimeM > '60') {
        timeError = true
      }
    } else {
      timeError = true
    }

    if (timeError) {
      setLoadingApprove(false)
      return setDialogOpen('noAppointmentTime')
    }

    if (newData.appointmentType && !['', 'normalAppointment'].includes(newData.appointmentType) && (!newData.doctor || newData.doctor === '')) {
      setLoadingApprove(false)
      return setDialogOpen('noDoctor')
    }

    newData.date = dayjs(newData.date).format('YYYY-MM-DD')
    newData.hour = parseInt(newData.time.split(':')[0])
    newData.minute = parseInt(newData.time.split(':')[1])
    newData.comment = newData.comment || ''
    if (appointmentId === 'new') {
      newData.status = 'not-confirmed'
    }

    const treatments = treatmentList.slice();

    let order = 0
    newData.treatments = treatments.reduce((acc, cur) => {
      if (cur.type === 'service') {
        const treatment = {
          duration: Number(cur.duration),
          done: cur.done ?? null,
          name: cur.name,
          type: cur.type,
          nickname: cur.nickname ?? '',
          color: cur.color,
          order: cur.parent ? 99 : order,
          quantity: 1,
          hidden: cur.hidden ?? null
        }
        if (cur.product) {
          treatment.product = cur.product
        }
        acc[cur.id] = treatment
      } else {
        acc[cur.id] = {
          color: cur.color,
          duration: Number(cur.duration),
          name: cur.name,
          nickname: cur.nickname || '',
          order
        }
      }
      order++
      return acc
    }, {})

    if (newData.doctor === '') {
      delete newData.doctor
    }

    if (newData.appointmentType === 'normalAppointment' && (newData.doctor || newData.doctor === '')) {
      newData.doctor = firestoreDeleteField()
    }

    if (appointmentId === 'new') {
      // NEED FIX:
      await addFirestoreDoc({
        collection: 'appointments',
        data: {
          createdBy: currentUser.key,
          createdAt: dayjs().valueOf(),
          source: currentCompany,
          ...newData
        }
      })
    } else {
      await updateFirestoreDoc({
        collection: 'appointments',
        doc: appointmentId,
        data: {
          updatedBy: currentUser.key,
          updatedAt: dayjs().valueOf(),
          ...newData
        }
      })
    }

    setLoadingApprove(false)
    onRequestClose()

    // try {
    //   await (firebase.functions().httpsCallable('saveAppointments'))({ id: appointmentId, ...newData })
    //   setLoadingApprove(false)
    //   onRequestClose()
    // } catch (ex) {
    //   console.log(ex)
    //   setLoadingApprove(false)
    //   onRequestClose()
    // }
  }

  function selectTreatment(treatment, isProduct) {
    if (!treatment) {
      return handleMenuClose()
    }

    const item = {
      ...treatment,
      name: treatment.name ? treatment.name : treatment.displayName,
      nickname: treatment.nickname ? treatment.nickname : treatment.displayName,
      type: isProduct ? 'product' : null,
      product: isProduct ? treatment.id : null,
      order: treatment.order,
      duration: treatment.duration,
      id: getRandomHash(),
    }

    setTreatmentList(treatmentList.concat(item))
    handleMenuClose()
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setTreatmentList((items) => {
        const oldIndex = items.findIndex(e => e.id === active.id);
        const newIndex = items.findIndex(e => e.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  function handleTreatmentChange(index, key, value) {
    let treatments = treatmentList
    if (key === false) {
      treatments.splice(index, 1)
      let newTreatment = []
      for (const s in treatments) {
        if (treatments[s].parent !== value) {
          newTreatment.push(treatments[s])
        }
      }
      treatments = newTreatment
    } else {
      treatments[index][key] = value
    }
    setTreatmentList([...treatments])
  }

  function updateData(field, value) {
    let newValue = value
    let newData = { ...data, [field.name]: newValue }

    if (field.name === 'appointmentType') {
      newData.doctor = ''
    }

    setData(newData)
  }

  function getNewCustomerData(data) {
    updateData({ name: 'customer' }, data)
  }

  const handleSelectCustomerSave = (customer) => {
    updateData({ name: 'customer' }, customer.id)
  }

  const handleSelectCustomerClose = () => {
    setOpenSelectCustomerDialog(false)
  }

  const handleClose = () => {
    setOpenNewCustomerDialog(false)
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onTabSelected = (tabIndex) => {
    setActiveTabIndex(tabIndex)
  }

  function onSelectProduct(products) {
    if (products.length) {
      setTreatmentList(treatmentList.concat(products.map(i => ({
        ...i,
        product: i.id,
        id: getRandomHash()
      }))))
    }

    handleMenuClose()
  }

  const tabs = [
    { label: formatMessage({ id: 'appointment.sidebar.tab.customerInformation' }), component: <div></div> },
    { label: formatMessage({ id: 'appointment.sidebar.tab.arrangeTreatments' }), component: <div></div> },
  ]

  return (
    <StyledEngineProvider injectFirst>
      <div className={classes.container}>
        {dialogOpen && <AlertDialog
          dialogTital={formatMessage({ id: `appointment.sidebar.alert.title.${dialogOpen}` })}
          dialogMsg={formatMessage({ id: `appointment.sidebar.alert.msg.${dialogOpen}` })}
          handleClose={() => setDialogOpen(false)}
        />}
        {showProductSelect && <SelectProductDialog
          headerCells={[{ text: 'name', sort: 'name' }]}
          rowCells={[{ field: 'name' }]}
          filterItems={[{ name: 'name' }]}
          filterSetting={{ type: { hidden: true, default: 'service' } }}
          defaultSelectedItems={[]}
          handleClose={() => setShowProductSelect(false)}
          handleSave={onSelectProduct}
          items={products}
          onShelf
          dialogTitle={formatMessage({ id: 'selectProductDialog.title' })}
          tableTitle={formatMessage({ id: 'selectProductDialog.table.title' })}
          isMobile={isMobile}
          productMapping={productMapping}
        />}
        {openNewCustomerDialog && <NewCustomerDialog
          dialogTital={formatMessage({ id: 'customer.dialog.information.add' })}
          handleClose={handleClose}
          customers={customers || []}
          getData={getNewCustomerData}
        />}
        {openSelectCustomerDialog && <SelectCustomerDialog
          handleClose={handleSelectCustomerClose}
          handleSave={handleSelectCustomerSave}
          customers={customers || []}
          isMobile={isMobile}
          dialogTitle={formatMessage({ id: 'selectCustomerDialog.title' })}
        />}
        <form className={classes.form}>
          <div className={classes.header}>
            <h2>
              <FormattedMessage id='appointment.sidebar.reservation' />
            </h2>
            <div className={classes.dateTime}>
              <DatePickerField
                required
                fullWidth
                disabled={isTreatmentDone}
                label={formatMessage({ id: 'appointment.sidebar.date' })}
                value={data.date}
                onChange={date => updateData({ name: 'date' }, date)}
                invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
              />
              <MaskInput
                required
                style={{ marginLeft: '10px' }}
                mask="99:99"
                maskChar=""
                onChange={e => updateData({ name: 'time' }, e.target.value)}
                value={data.time}
                disabled={isTreatmentDone}
                label={formatMessage({ id: 'appointment.sidebar.time' })}
                helperText={data.time_err}
              />
            </div>

            <TabContainer color="primary" activeTabIndex={activeTabIndex} defaultSelect={0} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" position="static" />
            {activeTabIndex === 0 && <div style={{ marginTop: '20px' }}>
              {(!currentAppointment || !currentAppointment.customer) && <div>
                <Button color="primary" onClick={() => setOpenSelectCustomerDialog(true)}>
                  <PersonIcon />
                  {formatMessage({ id: 'appointment.sidebar.button.selectCustomer' })}
                </Button>
                {userRights.hasUserRight('customer-create') && <Button color="primary" onClick={() => setOpenNewCustomerDialog(true)}>
                  <AddIcon />
                  {formatMessage({ id: 'appointment.sidebar.button.newCustomer' })}
                </Button>}
              </div>}

              {data.customer && <div>
                <div className={classes.customerInfo} style={{ fontWeight: '500' }}>
                  {selectCustomerData.name}
                </div>
                <div className={clsx(classes.customerInfo, classes.row)}>
                  <label>
                    {formatMessage({ id: 'appointment.sidebar.customerInfo.gender.root' })}
                    : {selectCustomerData.gender && formatMessage({ id: `appointment.sidebar.customerInfo.gender.${selectCustomerData.gender}` })}
                  </label>
                  <label>
                    {formatMessage({ id: 'appointment.sidebar.customerInfo.age' })}
                    : {calculateAge(selectCustomerData.birthDate)}
                  </label>
                </div>
                <div className={clsx(classes.customerInfo, classes.row)}>
                  <label>
                    {formatMessage({ id: 'appointment.sidebar.customerInfo.phone' })}
                    : {selectCustomerData.phone}
                  </label>
                  <label>
                    {formatMessage({ id: 'appointment.sidebar.customerInfo.birthDate' })}
                    : {selectCustomerData.birthDate}
                  </label>
                </div>
                <div className={clsx(classes.customerInfo, classes.row)}>
                  <label>
                    {formatMessage({ id: 'appointment.sidebar.customerInfo.salesRep' })}
                    : {userMapping[selectCustomerData.salesRep] && userMapping[selectCustomerData.salesRep].displayName}
                  </label>
                </div>
              </div>}
              <Grid container spacing={1} >
                {moduleMapping.doctor && <Grid item xs={12} sm={6} md={6} sx={{ mt: '20px' }}>
                  <TextField
                    select
                    type="text"
                    size="small"
                    label={formatMessage({ id: 'appointment.sidebar.customerInfo.doctor' })}
                    fullWidth
                    disabled={(data.appointmentType === 'normalAppointment' || data.appointmentType === '') ? true : false}
                    value={data.doctor}
                    onChange={e => updateData({ name: 'doctor' }, e.target.value)}
                  >
                    {
                      filterDoctors.map((doctor, idx) => {
                        return doctor.active && <MenuItem key={`${idx}`} value={doctor.id}>{doctor.displayName}</MenuItem>
                      })
                    }
                  </TextField>
                </Grid>}
                {moduleMapping.nurse && <Grid item xs={12} sm={6} md={6} sx={{ mt: '20px' }}>
                  <TextField
                    select
                    type="text"
                    size="small"
                    label={formatMessage({ id: 'appointment.sidebar.customerInfo.nurse' })}
                    fullWidth
                    value={data.nurse}
                    onChange={e => updateData({ name: 'nurse' }, e.target.value)}
                  >
                    {
                      nurses.map(nurse => {
                        return <MenuItem key={nurse.id} value={nurse.id}>{nurse.displayName}</MenuItem>
                      })
                    }
                  </TextField>
                </Grid>}
                <Grid item xs={12} sm={6} md={6} sx={{ mt: '20px' }}>
                  <TextField
                    select
                    type="text"
                    size="small"
                    label={formatMessage({ id: 'appointment.sidebar.customerInfo.infoSource.root' })}
                    fullWidth
                    value={data.infoSource}
                    onChange={e => updateData({ name: 'infoSource' }, e.target.value)}
                  >
                    {['phone', 'internets', 'salesRep'].map(s => {
                      return <MenuItem key={s} value={s}>{<FormattedMessage id={`appointment.sidebar.customerInfo.infoSource.${s}`} />}</MenuItem>
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{ mt: '20px' }}>
                  <TextField
                    select
                    type="text"
                    size="small"
                    label={formatMessage({ id: 'appointment.sidebar.customerInfo.salesRep' })}
                    fullWidth
                    value={data.salesRep}
                    onChange={e => updateData({ name: 'salesRep' }, e.target.value)}
                  >
                    {
                      salesReps.map(salesRep => {
                        return <MenuItem key={salesRep.id} value={salesRep.id}>{salesRep.displayName}</MenuItem>
                      })
                    }
                  </TextField>
                </Grid>
                {moduleMapping.doctor && <Grid item xs={12} sm={6} md={6} sx={{ mt: '20px' }}>
                  <TextField
                    select
                    type="text"
                    size="small"
                    label={formatMessage({ id: 'appointment.sidebar.appointmentType.root' })}
                    fullWidth
                    value={data.appointmentType}
                    onChange={e => updateData({ name: 'appointmentType' }, e.target.value)}
                  >
                    {
                      appointmentTypes.map((type, idx) => {
                        return <MenuItem key={`${idx}`} value={type.id}>{type.name}</MenuItem>
                      })
                    }
                  </TextField>
                </Grid>}
                <Grid item xs={12} sm={6} md={6} sx={{ mt: '20px' }}>
                  <TextField
                    select
                    type="text"
                    size="small"
                    // variant="standard"
                    label={formatMessage({ id: 'appointment.sidebar.customerInfo.room' })}
                    fullWidth
                    value={data.room}
                    onChange={e => updateData({ name: 'room' }, e.target.value)}
                  >
                    {
                      rooms.map(room => {
                        return <MenuItem key={room.id} value={room.id}>{room.name}</MenuItem>
                      })
                    }
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{ mt: '20px' }}>
                  <TextField
                    type="text"
                    size="small"
                    label={formatMessage({ id: 'appointment.sidebar.customerInfo.comment' })}
                    fullWidth
                    value={data.comment}
                    multiline
                    onChange={e => updateData({ name: 'comment' }, e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>}
            {activeTabIndex === 1 && <div>
              <div style={{ marginTop: '10px' }}>
                <Button color="primary" onClick={handleMenu}>
                  <AddIcon />
                  {formatMessage({ id: 'appointment.sidebar.button.arrangeTreatments' })}
                </Button>
                <Menu
                  id="menu-appbar"
                  className={classes.menuIndex}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={menuOpen}
                  onClose={handleMenuClose}
                >
                  {moduleMapping.product && <MenuItem onClick={() => { setShowProductSelect(true); handleMenuClose() }}>
                    <FormattedMessage id="appointment.sidebar.treatmentType.product" />
                  </MenuItem>}
                  {Object.keys(treatmentTemplates).map((type, key) => (
                    <MenuItem key={key} onClick={() => selectTreatment(treatmentTemplates[type])}>
                      {treatmentTemplates[type].name}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
              {treatmentList.length ?
                <DndContainer
                  items={treatmentList}
                  handleDragEnd={handleDragEnd}
                  ChildItem={TreatmentItem}
                  handleTreatmentChange={handleTreatmentChange}
                /> :
                <div style={{
                  height: '80px',
                  borderRadius: '4px',
                  border: '2px dashed #979797',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '15px',
                  opacity: 0.5,
                  marginTop: '5px'
                }}>
                  <FormattedMessage id={'appointment.sidebar.noTreatments'} />
                </div>}
            </div>}
          </div>
        </form>
        <div className={classes.footer}>
          <ButtonProgress
            handleClick={() => saveAppointment()}
            handleClose={onRequestClose}
            loading={loadingApprove}
          />
        </div>
      </div>
    </StyledEngineProvider>
  );
}

AppointmentSidebar.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentCompany: PropTypes.string.isRequired
};

export default AppointmentSidebar;
