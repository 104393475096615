import React, { useEffect, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import firebase from 'firebase/app';

import 'chart.js'
import Box from '@mui/material/Box';

import TabContainer from '../../containers/TabContainer/TabContainer';
import SalesReport from './SalesReport';
import ContextStore from 'modules/context';


function Reports() {
  const { formatMessage } = useIntl()
  const { tabName, dateType } = useParams()
  const { setBreadcrumbs } = useContext(ContextStore)

  const navigate = useNavigate()
  const location = useLocation()

  const [activeTabIndex, setActiveTabIndex] = useState(undefined)

  useEffect(() => {
    if (location.pathname === `/report/${tabName}/day` && activeTabIndex !== 0) {
      setActiveTabIndex(0)
    } else if (location.pathname === `/report/${tabName}/interval` && activeTabIndex !== 1) {
      setActiveTabIndex(1)
    } else if (location.pathname === `/report/${tabName}/year` && activeTabIndex !== 4) {
      setActiveTabIndex(2)
    }

    setBreadcrumbs([{
      text: formatMessage({ id: `sideMenu.report.${tabName}` }),
    }, {
      text: formatMessage({ id: `sideMenu.report.table.${dateType}` })
    }])
    return () => {
    };
  }, [location.pathname]);

  const onTabSelected = (tabIndex) => {
    if (tabIndex === 0) {
      navigate(`/report/${tabName}/day`);
    } else if (tabIndex === 1) {
      navigate(`/report/${tabName}/interval`);
    } else if (tabIndex === 2) {
      navigate(`/report/${tabName}/year`);
    }
  };

  let defaultSelect
  if (dateType === 'day') {
    defaultSelect = 0
  } else if (dateType === 'interval') {
    defaultSelect = 1
  } else if (dateType === 'year') {
    defaultSelect = 2
  }

  const salesTabs = ['day', 'interval', 'year'];

  let order = 0;
  const _tabs = salesTabs.reduce((acc, cur) => {
    acc.push({
      label: formatMessage({ id: `report.header.${tabName}.${cur}` }),
      component: <SalesReport />,
      order
    })

    order++
    return acc
  }, []);

  let tabs = [];
  if (tabName === 'sales') {
    tabs = _tabs.sort((a, b) => a.order - b.order)
  }

  return  (
    <Box p={3}>    
      <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
    </Box>
  );
}

export default Reports;
