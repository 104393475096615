import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';

function isInteractiveElement(element) {
  if (['input'].includes(element.tagName.toLowerCase())) {
    return true;
  }
  return false;
}

function isDraggerElement(element) {
  if (!['dragger'].includes(element.id)) {
    return true;
  }
  return false;
}

class InputPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown',
      handler: ({ nativeEvent: event }) => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          isInteractiveElement(event.target)
        ) {
          return false;
        }
        return true;
      },
    },
  ];
}

class DraggerPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown',
      handler: ({ nativeEvent: event }) => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          isDraggerElement(event.target)
        ) {
          return false;
        }
        return true;
      },
    },
  ];
}

function SortableItem({ ChildItem, id, ...props }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  const style = {
    transform: transform ? `translateY(${transform.y}px)` : null,
    transition,
    position: 'relative',
    zIndex: isDragging ? 2 : 1,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <ChildItem {...props} />
    </div>
  );
}

export default function DndContainer({ items, handleDragEnd, ChildItem, dargger, ...props }) {
  const sensors = useSensors(
    useSensor(InputPointerSensor, { activationConstraint: { distance: 5 } }),
  );

  const darggerSensors = useSensors(
    useSensor(DraggerPointerSensor, { activationConstraint: { distance: 5 } }),
  );

  return <DndContext
    modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    sensors={dargger ? darggerSensors : sensors}
    collisionDetection={closestCenter}
    onDragEnd={handleDragEnd}
  >
    <SortableContext
      items={items}
      strategy={verticalListSortingStrategy}
    >
      {items.map((item, index) => {
        return <SortableItem
          key={item.id}
          id={item.id}
          ChildItem={ChildItem}
          index={index}
          item={item}
          {...props}
        />
      })}
    </SortableContext>
  </DndContext>;
};