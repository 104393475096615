import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { LineChart } from 'react-chartkick'
import Chartkick from 'chartkick'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-date-fns'
import annotationPlugin from 'chartjs-plugin-annotation';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Toolbar from '@mui/material/Toolbar';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import EnhancedTable from 'components/EnhancedTable';
import DateRangePickerField from 'components/DateRangePickerField';
import SearchBox from 'components/SearchBox';
import { unwrap } from 'modules/uitls';
import { documentId, callFunction } from 'modules/firebase';
import { firebaseV8 } from 'modules/firebaseV8';
// import StockInfoDiablog from './StockInfoDiablog';

Chart.register(annotationPlugin);
Chartkick.use(Chart)

function MerchandiseDetail({ customerId, merchandise, editable, ss, todayStock = 0 }) {
  const [startDate, setStartDate] = useState(dayjs().subtract(30, 'days').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  // const [value, setValue] = React.useState([null, null]);
  const [safetyStock, setSafetyStock] = useState({ value: ss, error: '' })

  const [data, setData] = useState({});
  const max = Object.keys(data).reduce((acc, cur) => Math.max(acc, data[cur] || 0), 0)

  function onChange(e) {
    const value = e.target.value.replace(/[^0-9]/g, '')
    let error = ''
    if (isNaN(value) || value === '') {
      error = '數量錯誤'
    }
    setSafetyStock({ value: e.target.value, error })
  }

  async function handleSave() {
    await callFunction('updateSafetyStock', {
      customerId,
      merchandiseId: merchandise.id,
      value: parseInt(safetyStock.value)
    })
  }

  useEffect(() => {
    const today = dayjs().format('YYYY-MM-DD')
    const unsubscribe = startDate <= endDate ? firebaseV8().collection('wh')
      .doc(customerId).collection('dailySnapshot')
      .where(documentId(), '>=', startDate)
      .where(documentId(), '<=', endDate)
      .onSnapshot( snapshot => {
        const snapshotMapping = {}
        snapshot.forEach(doc => {
          snapshotMapping[doc.id] = doc.data().extData
        });
        const newData = {}
        for (let i = 0;; ++i) {
          const newDate = dayjs(startDate).add(i, 'days').format('YYYY-MM-DD')
          if (today === newDate) {
            newData[newDate] = todayStock
          } else {
            newData[newDate] = (snapshotMapping[newDate] && snapshotMapping[newDate][merchandise.id] ? snapshotMapping[newDate][merchandise.id] : 0)
          }
          if (newDate === endDate) break
        }
        setData(newData)
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, [startDate, endDate, customerId]);

  const options = {
    scales: {
      y: {
        ticks: {
          precision: 0,
          max: Math.max(safetyStock.value, max, 1)
        },
      },
      x: {
        time: {
          tooltipFormat: 'yyyy-MM-dd',
          displayFormats: {
            day: 'yyyy-MM-dd'
          }
        }
      }
    },
    plugins: {
      annotation: {
        annotations: safetyStock.value > 0 ? {
          line1: {
            type: 'line',
            // mode: 'horizontal',
            // scaleID: 'y-axis-0',
            yMin: safetyStock.value,
            yMax: safetyStock.value,
            borderColor: 'red',
            borderWidth: 1,
            label: {
              enabled: safetyStock.value > 0,
              content: ''
            }
          }
        } : {}
      }
    }
  }

  return (
    <div style={{ padding: 15 }}>
      <DateRangePickerField
        startDate={startDate}
        endDate={endDate}
        onStartDateChanged={setStartDate}
        onEndDateChanged={setEndDate}
      />
      <Divider style={{ margin: '8px 0px' }} />
      <LineChart
        data={data}
        library={options}
        label="庫存"
        xtitle="日期"
        ytitle="庫存"
        curve={false}
        download={`${merchandise.nickname}(${startDate}至${endDate})庫存變化.png`}
        suffix={merchandise.sku}
      />
      {editable && <Stack spacing={1} direction="row">
        <TextField
          type="text"
          label="安全庫存"
          variant="outlined"
          value={safetyStock.value}
          onChange={onChange}
          size="small"
          sx={{ mr: '8px' }}
          error={safetyStock.error ? true : false}
          helperText={safetyStock.error}
        />
        <Button variant="contained" onClick={handleSave} color="primary" disabled={!!safetyStock.error || ss === parseInt(safetyStock.value)} >
          <FormattedMessage id="button.save" />
        </Button>
      </Stack>}
    </div>
  )
}

MerchandiseDetail.propTypes = {
  ss: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
  customerId: PropTypes.string.isRequired,
  merchandise: PropTypes.object.isRequired,
  todayStock: PropTypes.number,
};

function Stock({ customerId }) {
  const { formatMessage } = useIntl()
  const merchandises = useSelector(state => state.merchandises?.ordered || []).filter(m => m.customers[customerId] && m.warehousing[customerId])
  const [logs, setLogs] = useState([]);
  const [dailySnapshot, setDailySnapshot] = useState({});
  const [safetyStock, setSafetyStock] = useState({});
  const [currentFilter, setCurrentFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState({});
  const supplierMapping = useSelector(state => state.suppliers.data)
  const [editable, setEditable] = React.useState(false);
  // const [stockInfo, setStockInfo] = useState(null);

  const filteredMerchandises = filterMerchandises()

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('safetyStock').doc(customerId).onSnapshot( snapshot => {
      setSafetyStock(unwrap(snapshot.data()) || {})
    }, err => {})
    return () => unsubscribe()
  }, [customerId]);

  useEffect(() => {
    const today = dayjs().format('YYYY-MM-DD')
    const unsubscribe = firebaseV8().collection('wh').doc(customerId).collection('logs').where('date', '==', today)
      .onSnapshot( snapshot => {
        const logs = []
        snapshot.forEach(doc => {
          const data = doc.data()
          if (data.valid !== false) {
            logs.push(data)
          }
        });
        setLogs(logs)
      }, err => {})
    return () => unsubscribe()
  }, [customerId]);

  useEffect(() => {
    const yesterday = dayjs().subtract(1, 'days').format('YYYY-MM-DD')
    const unsubscribe = firebaseV8().collection('wh').doc(customerId).collection('dailySnapshot').doc(yesterday)
      .onSnapshot( snapshot => {
        setDailySnapshot(snapshot?.data()?.extData || {})
      }, err => {})
    return () => unsubscribe()
  }, [customerId]);

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    { text: 'nickname' },
    { text: 'stock' },
  ].map(c => {c.text = formatMessage({ id: `merchandise.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'name' },
    { field: 'nickname' },
    { field: 'stock' }, // { field: 'stock', type: 'info', onButtonClick: showStockInfo },
  ]

  const merchandiseDailySnapshot = merchandises.reduce((acc, cur) => {
    if (!acc[cur.id]) acc[cur.id] = 0
    return acc
  }, { ...dailySnapshot })

  const realtimeStock = logs.reduce((acc, cur) => {
    if (acc[cur.merchandiseId] === undefined) return acc
    acc[cur.merchandiseId] += cur.quantity
    return acc
  }, { ...merchandiseDailySnapshot })

  // TODO： 在這邊多計算一組主倉庫的庫存, 或是計算出目前各分倉的庫存

  const formatData = (merchandise) => {
    const newData = { ...merchandise }
    newData.safetyStock = newData.safetyStock || 0
    newData.suppliers = Object.keys(merchandise.suppliers || [])
      .filter(id => supplierMapping[id])
      .map(id => ({
        ...merchandise.suppliers[id],
        id,
        code: supplierMapping[id].code,
        name: supplierMapping[id].name,
        nickname: supplierMapping[id].nickname
      }))
    newData.stock = `${realtimeStock[merchandise.id]}${merchandise.sku}`
    return newData
  }

  const filterItems = [
    { name: 'name' },
    { name: 'nickname' },
    { name: 'code' },
    { name: 'note' },
  ].map(i => {i.text = formatMessage({ id: `merchandise.table.detail.${i.name}` });return i})

  const categoryFilterItems = [
    { name: 'type' },
    { name: 'category' },
  ].map(i => {i.text = formatMessage({ id: `merchandise.table.detail.${i.name}` });return i})

  function filterMerchandises() {
    if (!currentFilter &&
        !categoryFilter.type &&
        !categoryFilter.category) {
      return merchandises
    }
    let items = [...merchandises]
    if (categoryFilter.type) {
      if (categoryFilter.type === 'g') {
        items = items.filter(i => ['G', 'B'].includes(i.type))
      } else if (categoryFilter.type === 'i') {
        items = items.filter(i => ['I', 'B'].includes(i.type))
      }
    }
    if (categoryFilter.category) {
      items = items.filter(i => i.category === categoryFilter.category)
    }
    if (currentFilter) {
      items = items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
    return items
  }

  // function showStockInfo(merchandiseId, field, merchandise) {
  //   setStockInfo({ customerId, merchandiseId, sku: merchandise.sku })
  // }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function getMenuItem(name) {
    if (name === 'type') {
      return [{ value: '' }, { value: 'g' }, { value: 'i' }].map(i => {
        i.label = i.value ? formatMessage({ id: `merchandise.type.${i.value}` }) : formatMessage({ id: 'merchandise.category.all' })
        return i
      })
    } else if (name === 'category') {
      return [
        { value: '' },
        { value: 'M', header: true },
        { value: 'DPO' },
        { value: 'DOI' },
        { value: 'DIV' },
        { value: 'DSC' },
        { value: 'I', header: true },
        { value: 'CIN' },
        { value: 'CPR' },
        { value: 'CBD' },
        { value: 'CIP' },
        { value: 'CCO' },
        { value: 'CWI' },
        { value: 'COT' },
      ].map(i => {
        i.label = i.value ? formatMessage({ id: `merchandise.category.${i.value}` }) : formatMessage({ id: 'merchandise.category.all' })
        return i
      })
    }
    return []
  }

  function updateCategoryFilter(name, value) {
    const newFilter = { ...categoryFilter }
    newFilter[name] = value
    setCategoryFilter(newFilter)
  }

  const getFilter = (filter) => {
    return <Grid item key={filter.name} xs={3} sm={3} md={3}>
      <TextField
        select
        type="text"
        size="small"
        label={filter.text}
        variant="outlined"
        onChange={e => {updateCategoryFilter(filter.name, e.target.value)}}
        value={categoryFilter[filter.name] || ''}
        fullWidth
      >
        {getMenuItem(filter.name).map((option, idx) => {
          return option.header ?
            (<ListSubheader key={`${option.value}-${idx}`}>{option.label}</ListSubheader>) :
            (<MenuItem key={`${option.value}-${idx}`} value={option.value}>{option.label}</MenuItem>)
        })}
      </TextField>
    </Grid>
  }
  return (
    <div style={{ paddingBottom: '60px' }}>
      {/* {!!stockInfo && <StockInfoDiablog
        customer={stockInfo.customerId}
        merchandiseId={stockInfo.merchandiseId}
        sku={stockInfo.sku}
        onClose={() => setStockInfo(null)}
      />} */}
      <Toolbar sx={{ pl: 2, pr: 1 }}>
        <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="merchandise.table.title" />
        </Typography>
        <Grid container alignItems="center" spacing={3}>
          <Grid item>
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
              <div>檢視</div>
              <Switch
                checked={editable}
                onChange={e => setEditable(e.target.checked)}
                color="primary"
                name="editmode"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <div>編輯</div>
            </div>
          </Grid>
          {categoryFilterItems.map(f => getFilter(f))}
        </Grid>
        <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />
      </Toolbar>
      <EnhancedTable
        defaultOrder="asc"
        defaultOrderField="code"
        headerCells={headerCells}
        rowCells={rowCells}
        getExpandContent={merchandise =>
          <MerchandiseDetail
            ss={safetyStock[merchandise.id] || 0}
            editable={editable}
            merchandise={merchandise}
            customerId={customerId}
            todayStock={realtimeStock[merchandise.id]}
          />
        }
        tableData={filteredMerchandises.map(m => formatData(m))}
      />
    </div>
  );
}

Stock.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default Stock;
