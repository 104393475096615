import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';

import { ColumnChart } from 'react-chartkick';
import 'chartkick/chart.js';
import 'chartjs-adapter-date-fns';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import GetAppIcon from '@mui/icons-material/GetApp';
// import MenuItem from '@mui/material/MenuItem';

import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import EnhancedTable from 'components/EnhancedTable';
import EnhancedTableEx from 'components/EnhancedTableEx';
import DatePickerField from 'components/DatePickerField';
import DateRangePickerField from 'components/DateRangePickerField';
import ContextStore from 'modules/context';
import { callFunction } from 'modules/firebase';
import { firebaseV8 } from 'modules/firebaseV8';
import SalesOrderView from 'pages/Sales/SalesOrderView';
import { getRandomHash, useFirestoreDataAndMapping, useFirestoreData } from 'modules/uitls';
import CompanyFilter from 'components/CompanyFilter';
import ButtonProgress from 'components/ButtonProgress';

const PrintGrid = styled(Grid)(() => ({
  '@media print': {
    padding: '8px'
  },
}));

const NotPrintGrid = styled(Grid)(() => ({
  '@media print': {
    display: 'none'
  },
}));

const PrintTypography = styled(Typography)(() => ({
  display: 'none',
  '@media print': {
    display: 'inline-block'
  },
}));

function PerformanceDialog({ handleClose }) {
  const { formatMessage } = useIntl()
  const [loadingApprove, setLoadingApprove] = useState(false)
  const sources = useSelector(state => state.internalVendors.ordered)

  const config = useSelector(state => state.config.data)
  const [monthTarget, setMonthTarget] = useState(config.report ? config.report.monthTarget : sources.reduce((acc, cur) => {
    acc[cur.id] = 0;
    return acc
  }, {}));

  async function handleSave() {
    setLoadingApprove(true)

    try {
      await callFunction('saveConfigurations', { type: 'report', monthTarget: monthTarget })
      setLoadingApprove(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'report.dialog.title' })}</DialogTitle>
      <DialogContent>
        <Grid container sx={{ p: 1 }} spacing={3}>
          {sources.map(s => {
            return <Grid key={s.id} item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: 'row' }}>
              <TextField
                disabled={true}
                type="text"
                size="small"
                label={formatMessage({ id: 'report.dialog.source' })}
                variant="outlined"
                value={s.name}
                fullWidth
              />
              <TextField
                sx={{ ml: '10px' }}
                type="text"
                size="small"
                label={formatMessage({ id: 'report.dialog.monthTarget' })}
                variant="outlined"
                value={monthTarget[s.id]}
                onChange={(e) => { setMonthTarget({ ...monthTarget, [s.id]: e.target.value }) }}
                fullWidth
              />
            </Grid>
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonProgress handleClose={handleClose} handleClick={() => handleSave()} loading={loadingApprove} />
      </DialogActions>
    </Dialog>
  );
}

PerformanceDialog.propTypes = {
  handleClose: PropTypes.func.isRequired
};

function SalesReport() {
  const { formatMessage } = useIntl()
  const { dateType } = useParams()
  const componentRef = useRef();
  const salesRef = useRef()
  const customerRef = useRef();
  const serviceRef = useRef();
  const performanceRef = useRef();
  const salesOrderRef = useRef();
  const dateMapping = {
    day: { start: dayjs().format('YYYY-MM-DD'), end: dayjs().format('YYYY-MM-DD') },
    interval: { start: dayjs().startOf('month').format('YYYY-MM-DD'), end: dayjs().endOf('month').format('YYYY-MM-DD') },
    year: { start: dayjs().format('YYYY-01-01'), end: dayjs().format('YYYY-12-31') },
  }

  const { currentCompany } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const userMapping = useSelector(state => state.users.data)
  const [customerMapping, setCustomerMapping] = useState({});
  const [startDate, setStartDate] = useState(dateMapping[dateType].start)
  const [endDate, setEndDate] = useState(dateMapping[dateType].end)
  const [salesOrders, setSalesOrders] = useState([]);
  const [salesOrderM, setSalesOrderM] = useState([])
  const [appointments, setAppointments] = useState([]);
  const config = useSelector(state => state.config.data)
  const paymentTypes = Object.keys(config.paymentType || {}).filter(i => config.paymentType[i].active).map(i => config.paymentType[i])
  const reportCategory = Object.keys(config.reportCategory || {}).filter(i => config.reportCategory[i].active).map(i => config.reportCategory[i])
  const productMapping = useSelector(state => state.products?.data || {})
  const [dialogOpen, setDialogOpen] = useState(false)
  const defaultMonthTarget = config?.report?.monthTarget ?? {}

  let servicesData = reportCategory.reduce((acc, cur) => { acc[cur.id] = 0; return acc }, {})
  let paymentTypeCount = paymentTypes.reduce((acc, cur) => { acc[cur.id] = 0; return acc }, {})
  const arrivedCount = appointments.reduce((acc, cur) => { if (cur.arrivetime) { acc += 1 } return acc }, 0)
  const comusterIds = appointments.reduce((acc, cur) => { acc.push(cur.customer); return acc }, [])
  const consumAmount = salesOrders.filter(s => comusterIds.includes(s.customer)).reduce((acc, cur) => { acc[cur.customer] = true; return acc }, {})
  const revenueTotal = salesOrderM.reduce((acc, cur) => { acc += cur.collected; return acc }, 0)

  const salesData = salesOrders.reduce((acc, cur) => {
    acc.revenue = acc.revenue += cur.collected
    acc.uncollected = acc.uncollected += cur.uncollected

    for (const pay of Object.keys(cur.payments)) {
      paymentTypeCount[cur.payments[pay].paymentType] += cur.payments[pay].collected
    }

    for (const p of Object.keys(cur.products)) {
      const product = productMapping[cur.products[p].productId]
      if (product) {
        reportCategory.forEach(r => {
          const result = r.tags.filter((e) => {
            return product.tags.indexOf(e) > -1
          })

          if (result.length > 0) {
            servicesData[r.id] += cur.products[p].unitPrice
          }
        })
      }
    }

    return acc
  }, { revenue: 0, uncollected: 0 })

  const serviceChartData = reportCategory.reduce((acc, cur) => {
    acc.push([cur.name, servicesData[cur.id]])

    return acc
  }, [])

  const refMapping = {
    sales: salesRef,
    customer: customerRef,
    service: serviceRef,
    performance: performanceRef,
    salesOrders: salesOrderRef
  }

  const data = {
    customer: [{
      id: getRandomHash(),
      appointment: appointments.length,
      arrived: arrivedCount,
      arrivalRate: appointments.length > 0 ? (Math.round(arrivedCount / appointments.length * 10000) / 100.00 + '%') : 0 + '%',
      consumAmount: Object.keys(consumAmount).length,
      consumRate: appointments.length > 0 ? (Math.round(Object.keys(consumAmount).length / appointments.length * 10000) / 100.00 + '%') : 0 + '%',
    }],
    sales: [{
      id: getRandomHash(),
      ...paymentTypeCount,
      revenue: salesData.revenue,
      uncollected: salesData.uncollected,
    }],
    service: [{
      id: getRandomHash(),
      ...servicesData
    }],
    performance: [{
      id: getRandomHash(),
      monthTarget: defaultMonthTarget[currentCompany] || 0,
      revenue: salesData.revenue,
      revenueTotal: revenueTotal,
      collected: (Math.round(revenueTotal / defaultMonthTarget[currentCompany] * 10000) / 100.00 + '%'),
      uncollected: (100 - (Math.round(revenueTotal / defaultMonthTarget[currentCompany] * 10000) / 100.00) + '%'),
      dayBalance: dayjs().endOf('month').format('DD') - dayjs().format('DD'),
      dayRevenue: (defaultMonthTarget[currentCompany] / Number(dayjs().endOf('month').format('DD'))).toFixed(1)
    }],
    salesOrders: salesOrders.map(s => formatData(s))
  }

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('customers').onSnapshot(snapshot => {
      // const customers = []
      const customerMapping = {}
      snapshot.forEach(doc => {
        const data = { ...doc.data(), id: doc.id }
        // customers.push(data)
        customerMapping[doc.id] = data
      });
      // setCustomers(customers)
      setCustomerMapping(customerMapping)
    }, err => { })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('appointments').where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(snapshots => {
      const newData = []
      snapshots.forEach(doc => {
        newData.push({ id: doc.id, ...doc.data() })
      })

      setAppointments(newData.filter(s => s.source === currentCompany))
    }, err => { })

    return () => unsubscribe()
  }, [startDate, endDate, currentCompany]);

  useEffect(() => {
    const onSnapshot = snapshot => {
      const salesOrders = []
      snapshot.forEach(doc => {
        const data = { ...doc.data(), id: doc.id }
        const products = Object.keys(data.products).map(k => data.products[k])
        const total = products.reduce((acc, cur) => acc + (cur.unitPrice * cur.amount), 0)
        const collected = Object.keys(data.payments || {}).map(k => data.payments[k]).filter(p => !p.void).map(p => p.collected)
          .reduce((acc, cur) => acc + cur, 0)
        if (total === collected) {
          data.status = 'fullPayment'
        } else if (collected === 0) {
          data.status = 'noPayment'
        } else {
          data.status = 'partialPayment'
        }

        if (data.void) {
          data.status = 'void'
        }
        data.uncollected = total - collected
        data.collected = collected
        salesOrders.push(data)
      });
      setSalesOrderM(salesOrders.filter(s => s.source === currentCompany))
    }
    const unsubscribe = firebaseV8().collection('salesOrders')
      .where('date', '>=', dayjs(startDate).startOf('month').format('YYYY-MM-DD'))
      .where('date', '<=', dayjs(startDate).endOf('month').format('YYYY-MM-DD'))
      .onSnapshot(onSnapshot, err => { })
    return () => unsubscribe()
  }, [startDate, currentCompany]);

  useEffect(() => {
    const onSnapshot = snapshot => {
      const salesOrders = []
      snapshot.forEach(doc => {
        const data = { ...doc.data(), id: doc.id }
        const products = Object.keys(data.products).map(k => data.products[k])
        const total = products.reduce((acc, cur) => acc + (cur.unitPrice * cur.amount), 0)
        const collected = Object.keys(data.payments || {}).map(k => data.payments[k]).filter(p => !p.void).map(p => p.collected)
          .reduce((acc, cur) => acc + cur, 0)
        if (total === collected) {
          data.status = 'fullPayment'
        } else if (collected === 0) {
          data.status = 'noPayment'
        } else {
          data.status = 'partialPayment'
        }

        if (data.void) {
          data.status = 'void'
        }
        data.uncollected = total - collected
        data.collected = collected
        salesOrders.push(data)
      });
      setSalesOrders(salesOrders.filter(s => s.source === currentCompany))
    }
    const unsubscribe = firebaseV8().collection('salesOrders').where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(onSnapshot, err => { })
    return () => unsubscribe()
  }, [startDate, endDate, currentCompany]);

  function updateDate(date) {
    if (dateType === 'day') {
      setStartDate(dayjs(date).format('YYYY-MM-DD'))
      setEndDate(dayjs(date).format('YYYY-MM-DD'))
    } else {
      setStartDate(dayjs(date).format('YYYY-01-01'))
      setEndDate(dayjs(date).format('YYYY-12-31'))
    }
  }

  function getDateComponents() {
    function dateGrid() {
      if (dateType === 'interval') {
        return <DateRangePickerField
          startDate={startDate}
          endDate={endDate}
          onStartDateChanged={setStartDate}
          onEndDateChanged={setEndDate}
        />
      } else {
        return <DatePickerField
          required
          label={formatMessage({ id: `report.table.${dateType === 'day' ? 'date' : 'dateY'}` })}
          value={dayjs(startDate)}
          onChange={date => updateDate(date)}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
          inputFormat={dateType === 'day' ? 'YYYY-MM-DD' : 'YYYY'}
        />
      }
    }
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <CompanyFilter userRight='report-view' />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        {dateGrid()}
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePrint}
        // disabled={}
        >
          <FormattedMessage id="button.print" />
        </Button>
      </Grid>
    </Grid>
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function formatData(salesOrder) {
    const newData = { ...salesOrder }
    newData.customerName = customerMapping[newData.customer]?.name || ''
    newData.agentName = userMapping[newData.agent]?.displayName || ''

    return newData
  }

  const headerCells = {
    sales: [
      { name: 'revenue', order: 0 },
      { name: 'uncollected', order: 99 },
    ].concat(paymentTypes.map((p, index) => ({ name: p.id, label: p.name, order: index + 1 })))
      .sort((a, b) => a.order - b.order)
      .map(c => { c.text = c.label ? c.label : formatMessage({ id: `report.table.sales.${c.name === 'revenue' ? dateType : c.name}` }); return c }),
    customer: [
      { name: 'appointment' },
      { name: 'arrived' },
      { name: 'arrivalRate' },
      { name: 'consumAmount' },
      { name: 'consumRate' },
    ].map(c => { c.text = formatMessage({ id: `report.table.customer.${c.name}` }); return c }),
    service: reportCategory.reduce((acc, cur) => { acc.push({ text: cur.name }); return acc }, []), // 需修改為動態
    performance: [
      { name: 'monthTarget' },
      { name: 'revenue' },
      { name: 'revenueTotal' },
      { name: 'collected' },
      { name: 'uncollected' },
      { name: 'dayBalance' },
      { name: 'dayRevenue' },
    ].map(c => { c.text = formatMessage({ id: `report.table.performance.${c.name}` }); return c }),
    salesOrder: [
      { name: 'sn' },
      { name: 'date' },
      { name: 'customer' },
      { name: 'agent' },
      { name: 'price' },
    ].map(c => { c.text = formatMessage({ id: `report.table.salesOrder.${c.name}` }); return c }),
  }
  const rowCells = {
    sales: [
      { field: 'revenue', order: 0 },
      { field: 'uncollected', order: 99 },
    ].concat(paymentTypes.map((p, index) => ({ field: p.id, order: index + 1 }))).sort((a, b) => a.order - b.order),
    customer: [
      { field: 'appointment' },
      { field: 'arrived' },
      { field: 'arrivalRate' },
      { field: 'consumAmount' },
      { field: 'consumRate' },
    ],
    service: reportCategory.reduce((acc, cur) => { acc.push({ field: cur.id }); return acc }, []),
    performance: [
      { field: 'monthTarget' },
      { field: 'revenue' },
      { field: 'revenueTotal' },
      { field: 'collected' },
      { field: 'uncollected' },
      { field: 'dayBalance' },
      { field: 'dayRevenue' },
    ],
    salesOrder: [
      { field: 'sn' },
      { field: 'date' },
      { field: 'customerName' },
      { field: 'agentName' },
      { field: 'price' },
    ]
  }

  let dateTitle = ''
  if (dateType === 'day') {
    dateTitle = startDate
  }

  function exportTable(type) {
    const dateStr = dateType === 'day' ? startDate : `${startDate}-${endDate}`

    refMapping[type].current.saveToFile(`Report_${dateStr}_${type}.csv`)
  }

  return (
    <PrintGrid container spacing={2} ref={componentRef} sx={{ marginTop: '15px', fontFamily: 'Roboto, sans-serif' }}>
      {dialogOpen && <PerformanceDialog
        handleClose={() => setDialogOpen(false)}
      />}
      <NotPrintGrid item xs={12} sm={12} md={12}>
        <EnhancedTableToolbar
          title={`report.table.title.${dateType}`}
          toolbox={getDateComponents()}
        />
      </NotPrintGrid>
      <PrintTypography sx={{ fontWeight: 500 }} component="div" style={{ fontSize: '120%', width: '100%', textAlign: 'center' }}>
        {dateTitle} {formatMessage({ id: `report.table.title.${dateType}` })}
      </PrintTypography>
      <Grid item xs={12} sm={12} md={12}>
        <div style={{ pageBreakInside: 'avoid' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography sx={{ fontWeight: 500 }} component="div">
                營收統計:
              </Typography>
              <EnhancedTableEx
                ref={refMapping.sales}
                headerCells={headerCells.sales}
                rowCells={rowCells.sales}
                tableData={data.sales}
                getHeaderActionIcons={() =>
                  <IconButton
                    onClick={() => exportTable('sales')}
                    size="large">
                    <GetAppIcon></GetAppIcon>
                    <Typography noWrap variant="button">{formatMessage({ id: 'button.export' })}</Typography>
                  </IconButton>
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography sx={{ fontWeight: 500 }} component="div">
                來客統計:
              </Typography>
              <EnhancedTableEx
                ref={refMapping.customer}
                headerCells={headerCells.customer}
                rowCells={rowCells.customer}
                tableData={data.customer}
                getHeaderActionIcons={() =>
                  <IconButton
                    onClick={() => exportTable('customer')}
                    size="large">
                    <GetAppIcon></GetAppIcon>
                    <Typography noWrap variant="button">{formatMessage({ id: 'button.export' })}</Typography>
                  </IconButton>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={{ fontWeight: 500, mt: '5px' }} component="div">
                營收類別:
              </Typography>
              <EnhancedTableEx
                ref={refMapping.service}
                headerCells={headerCells.service}
                rowCells={rowCells.service}
                tableData={data.service}
                getHeaderActionIcons={() =>
                  <IconButton
                    onClick={() => exportTable('service')}
                    size="large">
                    <GetAppIcon></GetAppIcon>
                    <Typography noWrap variant="button">{formatMessage({ id: 'button.export' })}</Typography>
                  </IconButton>
                }
                extRows={
                  <TableRow>
                    <TableCell rowSpan={2} colSpan={3} />
                    <TableCell align="left">
                      <Typography variant="subtitle2">
                        <FormattedMessage id="report.table.service.total" />
                      </Typography>
                    </TableCell>
                    <TableCell align="right">{Object.keys(servicesData).reduce((acc, cur) => {
                      acc += servicesData[cur]
                      return acc
                    }, 0)
                    }
                    </TableCell>
                  </TableRow>
                }
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ pageBreakInside: 'avoid' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <ColumnChart data={serviceChartData} dataset={{ barPercentage: 0.3 }} />
            </Grid>
          </Grid>
        </div>
        <div style={{ pageBreakInside: 'avoid' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={{ fontWeight: 500 }} component="div">
                營收單列表:
              </Typography>
              <EnhancedTable
                headerCells={headerCells.salesOrder}
                rowCells={rowCells.salesOrder}
                tableData={data.salesOrders}
                getExpandContent={salesOrder =>
                  <SalesOrderView
                    userRights={userRights}
                    salesOrder={salesOrder}
                    currentCompany={currentCompany}
                    forReport={true}
                    customerMapping={customerMapping}
                  />
                }
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ pageBreakInside: 'avoid' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={{ fontWeight: 500, mt: '5px' }} component="div">
                {userRights.hasUserRightForVendor('report-edit', currentCompany) &&
                  <Tooltip title={formatMessage({ id: 'report.table.performance.edit' })}>
                    <IconButton onClick={e => setDialogOpen(true)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>}
                當月業績目標:
              </Typography>
              <EnhancedTable
                headerCells={headerCells.performance}
                rowCells={rowCells.performance}
                tableData={data.performance}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </PrintGrid>
  );
}

export default SalesReport;
