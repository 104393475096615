import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FilterListIcon from '@mui/icons-material/FilterList';

import ContextStore from 'modules/context';
import { useModuleMapping } from 'hooks/modules';

function AppointmentFilter({ scheduleMode }) {
  const { uiState, setUiState  } = useContext(ContextStore)
  const [anchorEl, setAnchorEl] = useState(null)
  const moduleMapping = useModuleMapping()

  const handleFilterMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const fiterCheck = (field, check) => {
    setUiState({
      ...uiState,
      [field]: check
    })
  }

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleFilterMenu}
        color="inherit"
        size="medium"
      >
        <FilterListIcon />
      </IconButton>
      <Menu
        id="menu-filter"
        sx={{ zIndex: 2100 }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleFilterClose}
      >
        <List>
          {moduleMapping.doctor && <ListItem dense>
            <FormControlLabel
              control={<Checkbox checked={uiState.noShifts} onChange={e => fiterCheck('noShifts', e.target.checked)} name="noShifts" />}
              label={<FormattedMessage id={'header.appointment.filter.noShifts'} />}
            />
          </ListItem>}
          <ListItem dense>
            <FormControlLabel
              control={<Checkbox checked={uiState.showCustomerType} onChange={e => fiterCheck('showCustomerType', e.target.checked)} name="showPatientType" />}
              label={<FormattedMessage id={'header.appointment.filter.showCustomerType'} />}
            />
          </ListItem>
          <ListItem dense>
            <FormControlLabel
              control={<Checkbox checked={uiState.showBirthDate} onChange={e => fiterCheck('showBirthDate', e.target.checked)} name="showRoom" />}
              label={<FormattedMessage id={'header.appointment.filter.showBirthDate'} />}
            />
          </ListItem>
          <ListItem dense>
            <FormControlLabel
              control={<Checkbox checked={uiState.showCustomerCode} onChange={e => fiterCheck('showCustomerCode', e.target.checked)} name="showRoom" />}
              label={<FormattedMessage id={'header.appointment.filter.showCustomerCode'} />}
            />
          </ListItem>
          <ListItem dense>
            <FormControlLabel
              control={<Checkbox checked={uiState.showRoom} onChange={e => fiterCheck('showRoom', e.target.checked)} name="showRoom" />}
              label={<FormattedMessage id={'header.appointment.filter.showRoom'} />}
            />
          </ListItem>
          {moduleMapping.nurse && <ListItem dense>
            <FormControlLabel
              control={<Checkbox checked={uiState.showNurse} onChange={e => fiterCheck('showNurse', e.target.checked)} name="showNurse" />}
              label={<FormattedMessage id={'header.appointment.filter.showNurse'} />}
            />
          </ListItem>}
          {scheduleMode !== 'doctor' && moduleMapping.doctor && <ListItem dense>
            <FormControlLabel
              control={<Checkbox checked={uiState.showDoctor} onChange={e => fiterCheck('showDoctor', e.target.checked)} name="showDoctor" />}
              label={<FormattedMessage id={'header.appointment.filter.showDoctor'} />}
            />
          </ListItem>}
          {scheduleMode !== 'salesRep' && <ListItem dense>
            <FormControlLabel
              control={<Checkbox checked={uiState.showSalesRep} onChange={e => fiterCheck('showSalesRep', e.target.checked)} name="showSalesRep" />}
              label={<FormattedMessage id={'header.appointment.filter.showSalesRep'} />}
            />
          </ListItem>}
        </List>
      </Menu>
    </>
  )
}


AppointmentFilter.propTypes = {
  scheduleMode: PropTypes.string,
};

export default AppointmentFilter;
