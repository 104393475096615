const i18n = {
  'referrer': {
    'add': '新增轉介費單',
    'edit': '編輯轉介費單',
    'table': {
      'title': '轉介費單列表',
      'header': {
        'sn': '轉介費單號',
        'date': '日期',
        'source': '公司',
        'salesOrder': '營收單',
        'customer': '客戶',
        'subtotal': '總金額',
        'createdBy': '建立者'
      },
      'detail': {
        'source': '公司',
        'salesOrder': '營收單',
        'note': '備註',
      }
    },
    'filter': {
      'date': '日期',
      'createdBy': '建立人',
      'customer': '客戶'
    },
    'records': {
      'customer': '轉介人',
      'feeType': '費用類型',
      'feeRule': '費用',
      'fee': '實際金額',
      'note': '備註',
      'storedValue': '儲值',
      'cash': '現金',
    },
  },
  'editReferrer': {
    'title': '轉介費列表',
    'addRecord': '新增轉介費',
    'removeRecord': '移除轉介費',
    'totalReferralFee': '轉介費總額',
  }
}
export default i18n
