const i18n = {
  'setCustomer': {
    'title': '客戶',
    'extData': {
      'title': '擴充欄位',
      'table': {
        'header': {
          'name': '分類名稱',
          'note': '說明',
          'type': '類型',
          'status': '狀態'
        },
        'detail': {
          'name': '項目名稱',
          'type': '項目類型',
          'status': '狀態'
        }
      },
      'editCustomerExtData': {
        'title': '編輯{tabName}內容',
        'add': '新增項目',
        'editCount': '編輯選項',
        'editSelectItem': {
          'title': '編輯選項',
          'add': '新增選項'
        },
        'selectCountName': '選項{count}',
        'table': {
          'name': '項目名稱',
          'type': '項目類型',
          'selectDialog': '選單',
          'selectTabs': '項目'
        }
      }
    },
    'customerType': {
      'title': '客戶類型',
      'table': {
        'header': {
          'name': '類型名稱',
          'requiredItems': '必填關聯',
          'items': '非必填關聯',
          'status': '狀態'
        },
      },
    },
    'edit': {
      'extData': {
        'title': '編輯擴充欄位',
        'add': '新增分類',
        'addDetail': '新增內容',
        'table': {
          'name': '分類名稱',
          'detail': '內容',
          'note': '說明',
        },
        'detail': {
          'name': '項目名稱',
          'type': '項目類型'
        }
      },
      'customerType': {
        'title': '編輯客戶類型',
        'add': '新增類型',
        'table': {
          'name': '類型名稱',
          'requiredItems': '必填關聯',
          'items': '非必填關聯',
          'note': '說明',
        },
      }
    },
  }
}

export default i18n