import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import Typography from '@mui/material/Typography';

import EnhancedTableEx from 'components/EnhancedTableEx';
import DateRangePickerField from 'components/DateRangePickerField';
import SearchBox from 'components/SearchBox';
import FabAdd from 'components/FabAdd';
import ContextStore from 'modules/context';
import { firestoreListener, callFunction } from 'modules/firebase';
import SalesOrderView from './SalesOrderView';
import ActionDialog from 'components/ActionDialog';
import CompanyFilter from 'components/CompanyFilter';
import SimpleTableToolbar from 'components/SimpleTableToolbar';
import { exportFields } from 'modules/data';
import { useModules } from 'hooks/modules';

function SalesOrderList() {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const modules = useModules()
  const userRights = useSelector(state => state.userRights)
  const { setBreadcrumbs, currentCompany } = useContext(ContextStore)
  const [salesOrders, setSalesOrders] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState({ status: 'all' });
  // const [customers, setCustomers] = useState([]);
  const [customerMapping, setCustomerMapping] = useState({});
  const sourceMapping = useSelector(state => state.internalVendors.data)
  const userMapping = useSelector(state => state.users.data)
  const productMapping = useSelector(state => state.products.data)
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [openDialog, setOpenDialog] = useState(null);
  const [loading, setLoading] = useState(false);
  const config = useSelector(state => state.config.data)
  const paymentTypes = Object.keys(config.paymentType || {}).map(i => config.paymentType[i])
  const paymentTypeMapping = paymentTypes.reduce((acc, cur) => { acc[cur.id] = cur; return acc }, {})

  const filteredSalesOrders = filterSalesOrders()

  const filterItems = [
    { name: 'sn' },
    { name: 'customer' },
    { name: 'agent' },
    { name: 'product' },
    { name: 'note' },
  ].map(i => { i.text = formatMessage({ id: `salesOrder.filter.${i.name}` }); return i })

  // 要有一組日期和狀態的filter
  const categoryFilterItems = [
    { name: 'status' },
  ].map(i => { i.text = formatMessage({ id: `salesOrder.filter.${i.name}` }); return i })

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'salesOrders',
      where: [
        ['date', '>=', startDate],
        ['date', '<=', endDate],
        ['source', '==', currentCompany]
      ],
      onData: (data) => {
        for (const salesOrder of data) {
          const products = Object.keys(salesOrder.products).map(k => salesOrder.products[k])
          const total = products.reduce((acc, cur) => acc + (cur.unitPrice * cur.amount), 0)
          const collected = Object.keys(salesOrder.payments || {}).map(k => salesOrder.payments[k]).filter(p => !p.void).map(p => p.collected)
            .reduce((acc, cur) => acc + cur, 0)
          if (total === collected) {
            salesOrder.status = 'fullPayment'
          } else if (collected === 0) {
            salesOrder.status = 'noPayment'
          } else {
            salesOrder.status = 'partialPayment'
          }

          if (salesOrder.void) {
            salesOrder.status = 'void'
          }
          salesOrder.uncollected = total - collected
          salesOrder.collected = collected
        }
        setSalesOrders(data)
      }
    })

    return () => unsubscribe()
  }, [startDate, endDate, currentCompany]);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.sales.salesOrders' })
    }])

    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      onData: (data) => {
        setCustomerMapping(data)
      }
    })

    return () => unsubscribe()
  }, []);

  const _headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'date' },
    { text: 'source' },
    { text: 'customer' },
    { text: 'agent' },
    { text: 'price' },
    { text: 'status' },
  ]

  const _rowCells = [
    { field: 'sn', textColor: true },
    { field: 'date', textColor: true },
    { field: 'sourceName', textColor: true },
    { field: 'customerName', textColor: true },
    { field: 'agentName', textColor: true },
    { field: 'price', textColor: true },
    { field: 'statusText', textColor: true },
  ]

  const headerCells = _headerCells
    .map(c => { c.text = formatMessage({ id: `salesOrder.table.header.${c.text}` }); return c })
    .sort((a, b) => a.order - b.order)
  const rowCells = _rowCells.sort((a, b) => a.order - b.order)

  function onEditClick(salesOrder) {
    navigate({
      pathname: `/sales/salesOrders/edit/${salesOrder.id}`,
    });
  }

  async function handleExecute(data) {
    setLoading(true)
    const salesOrderId = openDialog.id

    try {
      await callFunction('salesOrdersVoid', { id: salesOrderId, reason: data.text })
    } catch (ex) {
      console.log(ex)
    }
    setLoading(false)
  }

  function filterSalesOrders() {
    if (!currentFilter && !categoryFilter.status) {
      return salesOrders
    }
    let items = [...salesOrders]
    if (categoryFilter.status !== 'all') {
      items = items.filter(i => i.status === categoryFilter.status)
    }
    if (currentFilter) {
      const lowerCaseText = currentFilter.text.toLowerCase()
      if (currentFilter.name === 'customer') {
        items = items.filter(i => customerMapping[i.customer]?.name.toLowerCase().includes(lowerCaseText) || customerMapping[i.customer]?.code.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'agent') {
        items = items.filter(i => userMapping[i.agent]?.displayName.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'product') {
        items = items.filter(i => Object.keys(i.products || {}).filter(p => productMapping[i.products[p].productId])
          .filter(p => productMapping[i.products[p].productId].name.toLowerCase().includes(lowerCaseText) ||
            (productMapping[i.products[p].productId].nickname || '').toLowerCase().includes(lowerCaseText)).length)
      } else {
        items = items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
      }
    }
    return items
  }

  function getMenuItem(name) {
    if (name === 'status') {
      return [
        { value: 'all' },
        { value: 'noPayment' },
        { value: 'partialPayment' },
        { value: 'fullPayment' },
      ].map(i => {
        i.label = i.value ? formatMessage({ id: `salesOrder.status.${i.value}` }) : formatMessage({ id: 'salesOrder.status.all' })
        return i
      })
    }
    return []
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function updateCategoryFilter(name, value) {
    const newFilter = { ...categoryFilter }
    newFilter[name] = value
    setCategoryFilter(newFilter)
  }

  function formatData(salesOrder) {
    const newData = { ...salesOrder }

    newData.statusText = formatMessage({ id: `salesOrder.status.${newData.status}` })
    newData.sourceName = sourceMapping[newData.source]?.name || ''
    newData.customerName = customerMapping[newData.customer]?.name || ''
    newData.agentName = userMapping[newData.agent]?.displayName || ''

    if (newData.void) {
      newData.textColor = '#bab7b7'
    }

    return newData
  }

  const getFilter = (filter) => {
    if (filter.name === 'status') {
      return (
        <TextField
          key={filter.name}
          select
          type="text"
          size="small"
          label={filter.text}
          variant="outlined"
          onChange={e => { updateCategoryFilter(filter.name, e.target.value) }}
          value={categoryFilter[filter.name] || ''}
          fullWidth
        >
          {getMenuItem(filter.name).map(option => {
            return (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
          })}
        </TextField>
      )
    }
  }

  function exportSalesOrder() {
    const rows = []
    const _rows = headerCells.concat([{ text: '產品名稱' }, { text: '單價' }, { text: '數量' }, { text: '總價' }]).reduce((acc, cur) => {
      if (!['公司', '狀態'].includes(cur.text)) {
        acc.push(cur.text)
      }
      return acc
    }, [])
    const path = `${startDate} to ${endDate}_SalesOrderList.csv`

    const paymentCount = filteredSalesOrders.reduce((acc, cur) => {
      if (acc < Object.keys(cur.payments).length) {
        acc = Object.keys(cur.payments).length
      }
      return acc
    }, 0)

    for (let c = 0; c < paymentCount; c++) {
      const index = c + 1
      _rows.push(`付款日期${index}`, `收款負責人${index}`, `付款方式${index}`, `付款金額${index}`, `備註${index}`)
    }

    rows.push(_rows.join(','))


    for (const so of filteredSalesOrders) {
      let dataRows = []
      for (const key of Object.keys(so.products)) {
        const p = productMapping[so.products[key].productId]
        dataRows.push(so.sn)
        dataRows.push(so.date)
        dataRows.push(customerMapping[so.customer].name)
        dataRows.push(userMapping[so.agent].displayName)
        dataRows.push(so.price)
        dataRows.push(p.nickname)
        dataRows.push(so.products[key].unitPrice)
        dataRows.push(so.products[key].amount)
        dataRows.push(so.products[key].unitPrice * so.products[key].amount)
      }

      Object.keys(so.payments).forEach((key, index) => {
        const payment = so.payments[key]

        dataRows.push(payment.date)
        dataRows.push(userMapping[payment.agent].displayName)
        dataRows.push(paymentTypeMapping[payment.paymentType].name)
        dataRows.push(payment.collected)
        dataRows.push(payment.note)
      })

      rows.push(dataRows.join(','))
    }

    exportFields(rows, path)
  }

  return (
    <div style={{ padding: '20px 24px 80px 24px' }}>
      {loading && <Backdrop open={true} sx={{ zIndex: 2000, color: '#fff' }}>
        <CircularProgress disableShrink color="inherit" />
      </Backdrop>}
      {openDialog && <ActionDialog
        title={formatMessage({ id: 'salesOrder.void.title' })}
        handleClose={() => setOpenDialog(null)}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'salesOrder.void.voidReason' })}
        action={'void'}
      />}
      {userRights.hasUserRight('salesOrder-create') &&
        <FabAdd to="/sales/salesOrders/edit/new" />
      }
      <SimpleTableToolbar
        title={'salesOrder.table.title'}
        toolbox={<>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <DateRangePickerField
                startDate={startDate}
                endDate={endDate}
                onStartDateChanged={setStartDate}
                onEndDateChanged={setEndDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CompanyFilter userRight='salesOrder-view' />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {categoryFilterItems.map(f => getFilter(f))}
            </Grid>
          </Grid>
          <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} updateUrlParams />
        </>}
      />
      <EnhancedTableEx
        defaultOrder="asc"
        defaultOrderField="sn"
        headerCells={headerCells}
        rowCells={rowCells}
        getHeaderActionIcons={() =>
          <IconButton
            onClick={() => exportSalesOrder()}
            size="large">
            <GetAppIcon></GetAppIcon>
            <Typography noWrap variant="button">{formatMessage({ id: 'button.export' })}</Typography>
          </IconButton>
        }
        getExpandContent={salesOrder =>
          <SalesOrderView
            modules={modules}
            userRights={userRights}
            salesOrder={salesOrder}
            onEditClick={onEditClick}
            onVoidClick={(salesOrder) => setOpenDialog(salesOrder)}
            currentCompany={currentCompany}
          />
        }
        tableData={filteredSalesOrders.map(i => formatData(i))}
      />
    </div>
  );
}

export default SalesOrderList;
