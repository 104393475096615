import React, { useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';

import MaskInput from 'components/MaskInput';
import { weekdays, weekdayName } from 'constants';

const EditWorktimePolicyView = forwardRef((props, ref) => {
  const { worktimePolicy } = props
  const { formatMessage } = useIntl()

  const [worktimePolicyData, setWorktimePolicyData] = useState({
    name: worktimePolicy.name ?? '',
    note: worktimePolicy.note ?? '',
    ...worktimePolicy.worktime,
  })

  const fields = [
    { name: 'name', required: true, md: 3 },
    { name: 'note', md: 9 }
  ]

  useImperativeHandle(
    ref, () => ({
      getContent: () => {
        return _getContent()
      }
    }),
  )

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }
    return ''
  }

  function updateWorktimePolicyData(field, value) {
    let newValue = value
    if (worktimePolicyData[field.name] === newValue) return;

    let err = validateField(field, value)

    let newData = { ...worktimePolicyData, [field.name]: newValue, [`${field.name}_err`]: err }
    setWorktimePolicyData(newData)
  }

  function _getContent() {
    let err = false

    let newData = { ...worktimePolicyData }
    for (let field of fields) {
      const errMsg = validateField(field, newData[field.name])
      if (errMsg) {
        newData[`${field.name}_err`] = errMsg
      }
    }

    for (let field of fields) {
      if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        err = true
        break
      }
    }

    for (const day of weekdays) {
      const dd = newData[day]
      const required = dd.startTime !== null || dd.endTime !== null
      if (required) {
        if (dd.startTime_err || dd.endTime_err) {
          err = true
          break
        }
      }
    }

    if (err) {
      setWorktimePolicyData(newData)
      return
    }

    return {
      name: newData.name,
      note: newData.note,
      worktime: weekdays.reduce((acc, cur) => {
        const dd = newData[cur]
        if (dd.startTime !== null && dd.endTime !== null) {
          acc[cur] = dd
        }
        return acc
      }, {})
    }
  }

  function checkTimeString(timeString) {
    const s = timeString.split(':')
    if (s.length === 2) {
      if (s[0] >= '00' && s[0] <= '23' && s[1] >= '00' && s[1] <= '59') {
        return ''
      }
    }
    return '無效的時間'
  }

  function onWorktimeChanged(day, field, value) {
    if (field === 'switch') {
      if (value) {
        updateWorktimePolicyData({ name: day }, { startTime: '09:00', endTime: '18:00', maxHours: 8 })
      } else {
        updateWorktimePolicyData({ name: day }, { startTime: null, endTime: null, maxHours: 0 })
      }
    } else if (field === 'startTime' || field === 'endTime') {
      let startTime_err = ''
      let endTime_err = ''
      let startTimeValue = null
      let endTimeValue = null
      const data = worktimePolicyData[day]

      if (field === 'startTime') {
        startTimeValue = value
        endTimeValue = data.endTime
      }

      if (field === 'endTime') {
        startTimeValue = data.startTime
        endTimeValue = value
      }

      startTime_err = checkTimeString(startTimeValue)
      endTime_err = checkTimeString(endTimeValue)

      if (startTime_err === '' && endTime_err === '') {
        if (field === 'startTime') {
          if (startTimeValue >= endTimeValue) {
            startTime_err = '上班時間須早於下班時間'
          }
        } else {
          if (startTimeValue >= endTimeValue) {
            endTime_err = '下班時間須晚於上班時間'
          }
        }
      }
      updateWorktimePolicyData({ name: day }, {
        ...data,
        [field]: value,
        startTime_err,
        endTime_err
      })
    } else {
      const data = worktimePolicyData[day]
      updateWorktimePolicyData({ name: day }, { ...data, [field]: value })
    }
  }

  function getRow(day) {
    const active = worktimePolicyData[day].startTime !== null || worktimePolicyData[day].endTime !== null
    return (
      <div key={day} style={{ margin: '16px 8px' }}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
              <div>{weekdayName[day]} 休假</div>
              <Switch
                checked={active}
                onChange={e => onWorktimeChanged(day, 'switch', e.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <div>上班</div>
            </div>
          </Grid>
          {active && <Grid item xs={3}>
            <MaskInput
              required
              mask="99:99"
              maskChar=" "
              onChange={e => onWorktimeChanged(day, 'startTime', e.target.value)}
              value={worktimePolicyData[day].startTime}
              label="上班時間"
              helperText={worktimePolicyData[day].startTime_err}
            />
          </Grid>}
          {active && <Grid item xs={3}>
            <MaskInput
              required
              mask="99:99"
              maskChar=" "
              onChange={e => onWorktimeChanged(day, 'endTime', e.target.value)}
              value={worktimePolicyData[day].endTime}
              label="下班時間"
              helperText={worktimePolicyData[day].endTime_err}
            />
          </Grid>}
          {active && <Grid item xs={3}>
            <TextField
              required
              select
              type="text"
              label="當日工時上限"
              variant="outlined"
              value={worktimePolicyData[day].maxHours}
              onChange={e => onWorktimeChanged(day, 'maxHours', e.target.value)}
              fullWidth
              size="small"
            >
              {[...Array(23).keys()].map(i => <MenuItem key={`${i}Hours`} value={i + 1}>
                {`${i + 1} 小時`}
              </MenuItem>)}
            </TextField>
          </Grid>}
        </Grid>
      </div>
    )
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        {fields.map(field => <Grid item key={field.name} xs={6} sm={12} md={field.md}>
          <TextField
            required={field.required}
            type="text"
            size="small"
            label={formatMessage({ id: `setAttendance.editReviewPolicy.table.detail.${field.name}` })}
            variant="outlined"
            value={worktimePolicyData[field.name]}
            onChange={e => updateWorktimePolicyData(field, e.target.value)}
            error={worktimePolicyData[`${field.name}_err`] ? true : false}
            helperText={worktimePolicyData[`${field.name}_err`]}
            fullWidth
          />
        </Grid>)}
      </Grid>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography sx={{ flex: '1 1 20%' }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="setAttendance.editWorktimePolicy.table.title" />
        </Typography>
      </div>
      <Paper sx={{ overflow: 'hidden' }}>
        {weekdays.map(d => getRow(d))}
      </Paper>
    </div>
  );
})

EditWorktimePolicyView.displayName = 'EditWorktimePolicyView'

EditWorktimePolicyView.propTypes = {
  worktimePolicy: PropTypes.object,
};

export default EditWorktimePolicyView;
