import React from 'react';

import { styled } from '@mui/material/styles';
import MuiCircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const CircularProgress = styled(MuiCircularProgress)(() => ({
  position: 'absolute',
  top: 'calc(50% - 12px)',
  left: 'calc(50% - 12px)',
  '&:not(:first-of-type)': {
    marginLeft: '8px'
  }
}));

const Container = styled('div')(() => ({
  position: 'relative',
  '&:not(:first-of-type)': {
    marginLeft: '8px'
  }
}));

const EnhancedButton = ({ label, progress, ...props }) => (
  <Container>
    <Button variant="contained" {...props}>{label}</Button>
    {progress && <CircularProgress size={24} />}
  </Container>
)

export default EnhancedButton;
