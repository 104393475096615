function addError(obj, field, message) {
  if (!obj.errors) {
    obj.errors = {}
  }
  obj.errors[field] = message
}

function removeError(obj, field) {
  if (obj.errors && obj.errors[field]) {
    delete obj.errors[field]
  }
}

export {
  addError,
  removeError
};
