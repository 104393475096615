import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';

import EnhancedButton from 'components/EnhancedButton';
import EnhancedTable from 'components/EnhancedTable';
import ButtonContainer from 'components/ButtonContainer';

function ReferrerView({ userRights, referrer, customerMapping, onEditClick, onVoidClick, currentCompany, forReport }) {
  const { formatMessage } = useIntl()
  const [serviceProviderInfo, setServiceProviderInfo] = useState('')
  const productMapping = useSelector(state => state.products.data)

  const records = Object.keys(referrer.records || {}).map(k => ({ ...referrer.records[k], id: k })).sort((a, b) => a.order - b.order)
  const totalReferralFee = records.reduce((acc, cur) => acc + cur.fee, 0);

  const headerCells = [
    { text: 'customer' },
    { text: 'feeType' },
    { text: 'feeRule' },
    { text: 'fee' },
    { text: 'note' },
  ].map(c => { c.text = formatMessage({ id: `referrer.records.${c.text}` }); return c })

  const rowCells = [
    { field: 'customerName' },
    { field: 'feeType' },
    { field: 'feeRule' },
    { field: 'fee' },
    { field: 'note' },
  ]

  function formatData(record) {
    const newData = { ...record }
    newData.customerName = customerMapping[newData.customer]?.name ?? ''

    if (newData.feeType === 'storedValue') {
      newData.feeType = `轉儲值(${productMapping[newData.storedValueCard].name})`
    } else {
      newData.feeType = formatMessage({ id: 'referrer.records.cash' })
    }
    if (newData.void) {
      newData.fee = `${newData.fee}(已作廢)`
    }
    return newData
  }

  return (
    <div style={{ width: '100%', }}>
      {serviceProviderInfo && <Dialog maxWidth="md" onClose={() => setServiceProviderInfo('')} open>
        <Box p={3}>
          <div style={{ whiteSpace: 'pre' }}>
            {serviceProviderInfo}
          </div>
        </Box>
      </Dialog>}
      <Box p={0}>
        <Grid container spacing={1}>
          {records.length > 0 && <Grid item xs={12} sm={12} md={12}>
            <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 500 }} component="div">
                轉介費列表:
              </Typography>
            </div>

            <EnhancedTable
              size="small"
              headerCells={headerCells}
              rowCells={rowCells}
              tableData={records.map(i => formatData(i))}
              extRows={
                <>
                  <TableRow>
                    <TableCell colSpan={2} />
                    <TableCell align="right">
                      <Typography variant="subtitle2">
                        <FormattedMessage id="editReferrer.totalReferralFee" />
                      </Typography>
                    </TableCell>
                    <TableCell align="left">{totalReferralFee}</TableCell>
                  </TableRow>
                </>
              }
            />
          </Grid>}
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'salesOrder.table.detail.note' })}
              value={referrer.note}
              multiline
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          {!referrer.void && <Grid item xs={12} sm={12} md={12}>
            <ButtonContainer>
              {onVoidClick && userRights.hasUserRightForVendor('referrer-void', currentCompany) && <EnhancedButton
                onClick={() => onVoidClick(referrer)}
                label={formatMessage({ id: 'button.void' })}
                color='error'
              />}
              {onEditClick && userRights.hasUserRightForVendor('referrer-edit', currentCompany) && <EnhancedButton
                onClick={() => onEditClick(referrer)}
                label={formatMessage({ id: 'button.edit' })}
              />}
            </ButtonContainer>
          </Grid>}
        </Grid>
      </Box>
    </div>
  )
}

ReferrerView.propTypes = {
  userRights: PropTypes.object.isRequired,
  referrer: PropTypes.object.isRequired,
  onEditClick: PropTypes.func,
  onVoidClick: PropTypes.func,
  forReport: PropTypes.bool
};

export default ReferrerView