import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Collapse from '@mui/material/Collapse';

import EnhancedTable from 'components/EnhancedTable';
import SimpleTableToolbar from 'components/SimpleTableToolbar';
import SearchBox from 'components/SearchBox';
import ExpandButton from 'components/ExpandButton';
import StockRequisitionView from './StockRequisitionView';

function StockRequisitionDetail({ userMapping, stockRequisition, ...props }) {
  const r = { ...stockRequisition }
  const keys = stockRequisition.merchandises ? Object.keys(stockRequisition.merchandises) : []
  r.merchandises = keys.map(k => ({ ...r.merchandises[k], id: k }))
  r.createdBy = { id: r.createdBy, name: userMapping[r.createdBy]?.displayName }
  return (
    <div style={{ padding: 15 }}>
      <StockRequisitionView userMapping={userMapping} stockRequisition={r} {...props} />
    </div>
  )
}

StockRequisitionDetail.propTypes = {
  stockRequisition: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
};

function StockRequisitionList({ formName, stockRequisitions, title }) {
  const { formatMessage } = useIntl()
  const userMapping = useSelector(state => state.users.data)
  const customerMapping = useSelector(state => state.internalVendors.data)
  const merchandiseMapping = useSelector(state => state.merchandises.data)

  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);
  const filteredStockRequisitions = currentFilter && currentFilter.text ? filterByText() : stockRequisitions

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'sourceName', sort: 'sourceName' },
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'createdAt', sort: 'createdAt' },
    { text: 'date', sort: 'date' },
  ].map(c => {c.text = formatMessage({ id: `${formName}.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'sn' },
    { field: 'sourceName' },
    { field: 'createdBy' },
    { field: 'createdAt' },
    { field: 'date' },
  ]

  function filterByText() {
    const lowerCaseText = currentFilter.text.toLowerCase()
    if (currentFilter.name === 'sourceName') {
      return stockRequisitions.filter(s => customerMapping[s.source]?.nickname.toLowerCase().includes(lowerCaseText))
    } else if (currentFilter.name === 'createdBy') {
      return stockRequisitions.filter(s => userMapping[s.createdBy]?.displayName.toLowerCase().includes(lowerCaseText))
    } else if (currentFilter.name === 'merchandiseName') {
      return stockRequisitions.filter(s => Object.keys(s.merchandises || {}).filter(c => merchandiseMapping[c])
        .filter(c => merchandiseMapping[c].name.toLowerCase().includes(lowerCaseText) ||
          merchandiseMapping[c].nickname.toLowerCase().includes(lowerCaseText)).length)
    } else if (currentFilter.name === 'merchandiseCode') {
      return stockRequisitions.filter(s => Object.keys(s.merchandises || {}).filter(c => merchandiseMapping[c])
        .filter(c => merchandiseMapping[c].code.toLowerCase().includes(lowerCaseText)).length)
    } else {
      return stockRequisitions.filter(s => s[currentFilter.name].toLowerCase().includes(lowerCaseText))
    }
  }

  const formatData = (stockRequisition) => {
    const newData = { ...stockRequisition }
    newData.sourceName = customerMapping[newData.source]?.nickname
    newData.createdBy = userMapping[newData.createdBy]?.displayName
    newData.createdAt = dayjs(newData.createdAt.seconds * 1000).format('YYYY-MM-DD')
    return newData
  }

  const filterItems = [
    { name: 'sn' },
    { name: 'sourceName', type: 'customer' },
    { name: 'createdBy' },
    { name: 'date' },
    { name: 'merchandiseName' },
    { name: 'merchandiseCode' },
    { name: 'note' },
  ].map(i => {i.text = formatMessage({ id: `${formName}.table.detail.${i.name}` });return i})

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  return (
    <div>
      <SimpleTableToolbar
        title={title}
        buttons={<ExpandButton open={expand} onExpandChange={setExpand} />}
        toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
      />
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <EnhancedTable
          defaultOrder="desc"
          defaultOrderField="sn"
          headerCells={headerCells}
          rowCells={rowCells}
          getExpandContent={stockRequisition =>
            <StockRequisitionDetail
              userMapping={userMapping}
              merchandiseMapping={merchandiseMapping}
              stockRequisition={stockRequisition}
              formName={formName}
            />
          }
          tableData={filteredStockRequisitions.map(r => formatData(r))}
        />
      </Collapse>
    </div>
  );
}

StockRequisitionList.propTypes = {
  stockRequisitions: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  title: PropTypes.string.isRequired,
};

export default StockRequisitionList;
