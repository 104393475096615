import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';

import SalesOrderInfoDiablog from 'components/SalesOrderInfoDiablog';
import SelectCustomerDialog from 'components/SelectCustomerDialog';
import SelectProductDialog from 'components/SelectProductDialog';
import SelectItemDialog from 'components/SelectItemDialog';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import EnhancedTable from 'components/EnhancedTable';
import DelectIconButton from 'components/DelectIconButton';
import ContextStore from 'modules/context';
import { getRandomHash, unique } from 'modules/uitls';
import { addError, removeError } from 'modules/editor';
import { firestoreListener, callFunction } from 'modules/firebase';

function SelectPurchasedProductDailog({ multiSelect, headerCells, rowCells, filterItems, items, handleSave, ...props }) {
  const { formatMessage } = useIntl()

  const _headerCells = headerCells.map(c => { c.text = formatMessage({ id: `selectPurchasedProductDailog.table.header.${c.name}` }); return c })
  const _filterItems = filterItems.map(i => { i.text = formatMessage({ id: `selectPurchasedProductDailog.table.header.${i.name}` }); return i })

  function applyFilter(currentFilter) {
    return currentFilter ? (items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))) : items
  }

  function onSave(selectedItems, params) {
    if (multiSelect) {
      const products = items.filter(u => selectedItems[u.id]).map(u => ({ name: u.name, id: u.id }))
      handleSave(products, params)
    } else {
      handleSave(selectedItems, params)
    }
  }

  return (
    <SelectItemDialog
      multiSelect={multiSelect}
      headerCells={_headerCells}
      rowCells={rowCells}
      filterItems={_filterItems}
      applyFilter={applyFilter}
      handleSave={onSave}
      {...props}
    />
  );
}

SelectPurchasedProductDailog.propTypes = {
  headerCells: PropTypes.arrayOf(PropTypes.object.isRequired),
  rowCells: PropTypes.arrayOf(PropTypes.object.isRequired),
  filterItems: PropTypes.arrayOf(PropTypes.object.isRequired),
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
  handleSave: PropTypes.func.isRequired,
};

function EditTransformServicePage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const { formId } = useParams()
  const location = useLocation()
  const userRights = useSelector(state => state.userRights)
  const [loading, setLoading] = useState(false)
  const [availableStoredValue, setAvailableStoredValue] = useState(null);
  const productMapping = useSelector(state => state.products.data)
  const products = useSelector(state => state.products.ordered)
  const vendorMapping = useSelector(state => state.vendors.data)
  const userMapping = useSelector(state => state.users.data)
  const sourceMapping = useSelector(state => state.internalVendors.data)

  const [salesOrderInfo, setSalesOrderInfo] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [customerMapping, setCustomerMapping] = useState({});
  const [ppData, setPPData] = useState([])
  const [openDialog, setOpenDialog] = useState('')
  const companyData = currentUser.company?.reduce((acc, cur) => { if (userRights['transformService-create'].includes(cur)) { acc = true } return acc }, false) ?
    unique(currentUser.company?.concat(userRights['transformService-create'])) : userRights['transformService-create']
  const [form, setForm] = useState({
    id: formId,
    customer: '',
    selectPP: null,
    note: '',
    services: [],
    difference: 'refund',
    paymentType: '',
    differencePrice: '',
    source: companyData[0]
  });

  const selectProduct = form.selectPP ? Object.keys(form.selectPP || {}).reduce((acc, cur) => {
    const product = productMapping[form.selectPP.product]
    if (product) {
      acc = product
    } else {
      acc = null
    }
    return acc
  }, {}) : null

  const storedValueCards = []
  if(availableStoredValue) {
    for (const k of Object.keys(availableStoredValue || {})) {
      if (productMapping[k]) {
        storedValueCards.push({
          active: true,
          id: `storedValue*${k}`,
          name: `${productMapping[k].name}(${availableStoredValue[k]})`,
          amount: availableStoredValue[k]
        })
      }
    }
  } else {
    products.filter(p => p.type === 'storedValue').forEach(product => {
      storedValueCards.push({
        active: true,
        name: `${product.name}(0)`,
        id: `storedValue*${product.id}`,
        amount: 0
      })
    })
  }

  useEffect(() => {
    const breadcrumbs = [{
      link: '/services/transform',
      text: formatMessage({ id: 'sideMenu.services.transform' })
    }]
    if (formId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'transformService.add' }) })
    }

    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const unsubscribe = form.customer ? firestoreListener({
      collection: 'storedValueCard',
      doc: form.customer,
      unwrap: true,
      addDocId: false,
      onData: (data) => {
        setAvailableStoredValue(data)
      }
    }) : null
    return () => unsubscribe?.()
  }, [form.customer]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      array: true,
      onData: ({ mapping, data }) => {
        setCustomers(data)
        setCustomerMapping(mapping)
      }
    })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = form.customer ? firestoreListener({
      collection: 'purchasedProducts',
      where: [
        ['customer', '==', form.customer],
        ['available', '==', true]
      ],
      onData: (pp) => {
        setPPData(pp.filter(p => p.available))
      }
    }) : null
    return () => unsubscribe?.()
  }, [form.customer]);

  function formatData(data) {
    const newData = { ...data }
    const product = productMapping[newData.product]
    if (product) {
      newData.name = product.name
      newData.nickname = product.nickname
      newData.price = product.price
    }
    newData.transformType = selectProduct && selectProduct.type === 'pointCard' ? '點數轉換' : '服務轉換'
    newData.availableAmount = newData.quantity - newData.taken

    if (newData.availableAmount === 0) {
      newData.textColor = '#bab7b7'
    }

    return newData
  }

  function formatFormData(data) {
    const newData = { ...data }
    newData.name = productMapping[newData.productId].name
    newData.nickname = productMapping[newData.productId].nickname
    newData.price = newData.unitPrice * newData.amount

    return newData
  }

  function formatPPData(pp) {
    const newData = { ...pp }
    newData.name = productMapping[newData.product].name
    newData.amount = newData.quantity - newData.taken

    return newData
  }

  function onSelectProduct(products) {
    if (products.length) {
      for (const p of products) {
        let data = {}
        if (selectProduct && selectProduct.type === 'pointCard') {
          const newAmount = selectProduct.transformService.reduce((acc, cur) => {
            if (p.id === cur.product) {
              acc = cur.amount
            }
            return acc
          }, 0)

          data.amount = newAmount
          data.proportion = `1:${newAmount}`
        } else {
          data.amount = 1
        }

        const pData = {
          id: getRandomHash(),
          productId: p.id,
          unitPrice: p.price,
          ...data,
          note: '',
        }

        form.services.push(pData)
      }
      updateData({ name: 'services' }, [...form.services])
    }
  }

  function updateData(field, value) {
    let newValue = value
    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }

    let err = validateField(field, value)
    let newData = { ...form, [field.name]: newValue, [`${field.name}_err`]: err }

    if (field.name === 'differencePrice' && form.difference === 'repair') {
      if (form.paymentType.startsWith('storedValue*')) {
        const amount = storedValueCards.filter(s => s.id === form.paymentType)[0].amount
        if (newData.differencePrice > amount) {
          newData[`${field.name}_err`] = formatMessage({ id: 'form.field.notEnoughBalance' })
        }
      }
    }
    if (field.name === 'customer') {
      newData.selectPP = null
      newData.services = []
    } else if (field.name === 'selectPP') {
      newData.selectPP = ppData.filter(p => p.id === newValue).reduce((acc, cur) => {
        acc = { ...cur, useAmount: productMapping[cur.product] && productMapping[cur.product].type === 'pointCard' ? 0 : 1 }
        return acc
      }, {})

      newData.services = []
    } else if (field === 'services' || field.name === 'services') {
      if (selectProduct && selectProduct.type === 'pointCard') {
        newData.selectPP.useAmount = newData.services.reduce((acc, cur) => {
          acc += selectProduct && selectProduct.transformService.reduce((tAcc, tCur) => {
            if (cur.productId === tCur.product) {
              if (parseInt(cur.amount) % parseInt(tCur.amount) === 0) {
                tAcc += parseInt(cur.amount) / parseInt(tCur.amount)
              }
            }
            return tAcc
          }, 0)

          return acc
        }, 0)
      }

      if (newData.selectPP.useAmount > (form.selectPP.quantity - form.selectPP.taken)) {
        addError(newData.selectPP, 'useAmount', '不得大於剩餘點數')
      } else {
        removeError(newData.selectPP, 'useAmount')
      }
    }

    setForm(newData)
  }

  function updateServiceData(field, value, table) {
    let newValue = value

    let err = validateField(field, value)
    let newData = { ...form[table], [field.name]: newValue, [`${field.name}_err`]: err }

    setForm({ ...form, [table]: newData })
  }


  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    return ''
  }

  function handleClose() {
    navigate('/services/transform');
  }

  async function handleSave() {
    setLoading(true)
    let err = false;

    const _fields = [
      { name: 'customer' },
    ]

    if (selectProduct && selectProduct.type !== 'pointCard') {
      _fields.push({ name: 'difference' })
      _fields.push({ name: 'paymentType' })
      _fields.push({ name: 'differencePrice' })
    }

    const fields = _fields

    for (const field of fields) {

      if (form[field.name] === '' || form[field.name].length === 0 ) {
        form[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
        err = true
      } else if (form[`${field.name}_err`]) {
        err = true
      }
    }

    if (!form.differencePrice_err && form.difference === 'repair' && form.paymentType && form.paymentType.startsWith('storedValue*')) {
      const storedValue = form.paymentType.split('*')[1]
      const maxAmount = (availableStoredValue ?? {})[storedValue] ?? 0
      if(parseInt(form.differencePrice) > maxAmount) {
        form.differencePrice_err = formatMessage({ id: 'form.field.notEnoughBalance' })
        err = true
      }
    }

    if (form.selectPP.errors && Object.keys(form.selectPP.errors).length > 0) {
      err = true
    }

    for(const s of form.services) {
      if(s.errors && Object.keys(s.errors).length > 0) {
        err = true
      }
    }

    if (err) {
      setForm({ ...form })
      setLoading(false);
      return
    }

    const updateData = {
      customer: form.customer,
      note: form.note,
      purchasedProduct: form.selectPP.id,
      amount: parseInt(form.selectPP.useAmount),
      product: selectProduct.id,
      source: form.source
    }

    updateData.services = form.services.reduce((acc, cur) => {
      acc[cur.id] = {
        amount: parseInt(cur.amount),
        productId: cur.productId,
        note: cur.note,
      };
      return acc;
    }, {})

    let paymentType = form.paymentType
    if (paymentType.startsWith('storedValue*')) {
      const s = paymentType.split('*')
      updateData.paymentType = s[0]
      updateData.storedValueCard = s[1]
    } else {
      updateData.paymentType = paymentType
    }

    if (selectProduct && selectProduct.type !== 'pointCard') {
      updateData.difference = form.difference
      updateData.differencePrice = parseInt(form.differencePrice)
    }

    try {
      await callFunction('saveTransformService', { ...updateData })
    } catch (ex) {
      console.log(ex)
    }
    handleClose()
  }

  function onDeleteItem(item) {
    const index = form.services.findIndex(i => i.id === item.id)
    form.services.splice(index, 1)
    updateData({ name: 'services' }, form.services);
  }

  function getPointCardProduct() {
    if (selectProduct && selectProduct.type === 'pointCard') {
      const productIds = selectProduct.transformService.reduce((acc, cur) => {
        acc.push(cur.product)

        return acc
      }, [])

      return products.filter(p => productIds.includes(p.id))
    } else {
      return products.filter(p => selectProduct && selectProduct.product !== p.id)
    }
  }

  function onCellValueChanged(cellData, field, value) {
    if (field === 'useAmount') {
      const m = form.selectPP
      if (isNaN(value) || value === '' || parseInt(value) === 0) {
        addError(m, field, '數量錯誤')
      } else if (parseInt(value) > (parseInt(m.quantity) - parseInt(m.taken))) {
        addError(m, field, '不得大於剩餘數量')
      } else {
        removeError(m, field)
      }
      updateServiceData({ name: field }, value, 'selectPP');
    } else {
      const m = form.services.find(i => i.id === cellData.id)
      if (!m) {
        return
      }

      if (field === 'amount') {
        m.amount = value
        if (isNaN(value) || value === '' || parseInt(value) === 0) {
          addError(m, field, '數量錯誤')
        } else if (selectProduct && selectProduct.type === 'pointCard') {
          const minAmount = selectProduct.transformService.reduce((acc, cur) => {
            if (m.productId === cur.product) { acc += cur.amount }
            return acc
          }, 0)

          if (parseInt(value) % minAmount !== 0) {
            addError(m, field, '轉換比例錯誤')
          } else {
            removeError(m, field)
          }
        } else {
          removeError(m, field)
        }
      } else {
        removeError(m, field)
        m[field] = value
      }

      updateData({ name: 'services' }, form.services);
    }
  }

  function getEnableStatus(data, field) {
    return selectProduct && selectProduct.type !== 'pointCard'
  }

  const headerTransformCells = [
    { text: 'name' },
    { text: 'availableAmount' },
    { text: 'useAmount' },
    { text: 'transformType' },
    { text: 'price' },
    { text: 'sn' },
  ].map(c => { c.text = formatMessage({ id: `transformService.service.${c.text}` }); return c })

  const rowTransformCells = [
    { field: 'nickname', tooltip: 'name' },
    { field: 'availableAmount', textColor: true },
    {
      field: 'useAmount',
      type: 'input-number',
      required: true,
      label: '數量',
      onValueChanged: onCellValueChanged,
      getEnableStatus: getEnableStatus,
    },
    { field: 'transformType' },
    { field: 'price' },
    { field: 'salesOrderSn', type: 'info', onButtonClick: showSalesOrder },
  ];

  const _headerCells = [
    { text: 'name', order: 0 },
    { text: 'amount', order: 2 },
    { text: 'price', order: 3 },
    { text: 'note', order: 4 }
  ]

  const _rowCells = [
    { field: 'nickname', tooltip: 'name', order: 0 },
    {
      field: 'amount',
      type: 'input-number',
      required: true,
      label: '數量',
      onValueChanged: onCellValueChanged,
      order: 2
    },
    { field: 'price', order: 3 },
    { field: 'note', type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged, order: 4 },
  ];

  if (selectProduct && selectProduct.type === 'pointCard') {
    _headerCells.push({ text: 'proportion', order: 1 })
    _rowCells.push({ field: 'proportion', order: 1 })
  }

  const headerCells = _headerCells.map(c => { c.text = formatMessage({ id: `transformService.service.${c.text}` }); return c }).sort((a, b) => a.order - b.order)
  const rowCells = _rowCells.sort((a, b) => a.order - b.order)
  const filterProducts = getPointCardProduct()

  function showSalesOrder(key, field, data) {
    setSalesOrderInfo(data)
  }

  return (
    <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
      {openDialog === 'customer' && <SelectCustomerDialog
        handleClose={() => setOpenDialog('')}
        handleSave={customer => updateData({ name: openDialog }, customer.id)}
        customers={openDialog === 'customer' ? customers : customers.filter(c => c.id !== form.customer) || []}
        dialogTitle={formatMessage({ id: 'selectCustomerDialog.title' })}
      />}
      {openDialog === 'selectPP' && <SelectPurchasedProductDailog
        headerCells={[{ name: 'name', sort: 'name' }, { name: 'amount', sort: 'amount' }, { name: 'salesOrderSn', sort: 'salesOrderSn' }]}
        rowCells={[{ field: 'name' }, { field: 'amount' }, { field: 'salesOrderSn', type: 'info', onButtonClick: showSalesOrder }]}
        filterItems={[{ name: 'name' }, { name: 'salesOrderSn' }]}
        dialogTitle={formatMessage({ id: 'selectPurchasedProductDailog.title' })}
        handleClose={() => setOpenDialog('')}
        handleSave={data => updateData({ name: 'selectPP' }, data.id)}
        items={ppData.map(pp => formatPPData(pp))}
      />}
      {openDialog === 'product' && <SelectProductDialog
        headerCells={[   { text: 'name', sort: 'name' }]}
        rowCells={[{ field: 'name' }]}
        filterItems={[ { name: 'name' }]}
        filterSetting={{ type: { hidden: true, default: 'service' } }}
        defaultSelectedItems={[]}
        handleClose={() => setOpenDialog('')}
        handleSave={onSelectProduct}
        items={filterProducts}
        onShelf
        dialogTitle={formatMessage({ id: 'selectProductDialog.title' })}
        tableTitle={formatMessage({ id: 'selectProductDialog.table.title' })}
        // isMobile={isMobile}
        productMapping={productMapping}
      />}
      {salesOrderInfo && <SalesOrderInfoDiablog
        salesOrderId={salesOrderInfo.salesOrder}
        highlightItems={[salesOrderInfo.salesOrderItem]}
        userMapping={userMapping}
        customerMapping={customerMapping}
        sourceMapping={sourceMapping}
        onClose={() => setSalesOrderInfo(null)}
      />}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            type="text"
            size="small"
            select={userRights['transformService-create']?.length > 1}
            disabled={userRights['transformService-create']?.length <= 1}
            label={formatMessage({ id: 'transformService.table.detail.source' })}
            variant="outlined"
            value={userRights['transformService-create']?.length > 1 ? form.source : vendorMapping[form.source]?.name}
            onChange={(e) => updateData({ name: 'source' }, e.target.value)}
            fullWidth
          >
            {
              companyData.map(c => {
                return <MenuItem key={c} value={c}>{vendorMapping[c].name}</MenuItem>
              })
            }
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            type="text"
            size="small"
            label={formatMessage({ id: 'transformService.table.detail.customer' })}
            variant="outlined"
            value={form.customer ? customerMapping[form.customer].name : ''}
            onClick={() => setOpenDialog('customer')}
            error={form.customer_err ? true : false}
            helperText={form.customer_err}
            fullWidth
          />
        </Grid>
        {selectProduct && selectProduct.type === 'service' && <>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required
              type="text"
              size="small"
              select
              label={formatMessage({ id: 'transformService.table.detail.difference.root' })}
              variant="outlined"
              disabled={!form.customer}
              value={form.difference}
              error={form.difference_err ? true : false}
              helperText={form.difference_err}
              onChange={e => updateData({ name: 'difference' }, e.target.value)}
              fullWidth
            >
              {
                ['refund', 'repair'].map(type => {
                  return <MenuItem key={type} value={type}>{formatMessage({ id: `transformService.table.detail.difference.${type}` })}</MenuItem>
                })
              }
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required
              type="text"
              size="small"
              select
              label={!form.difference ? '' : formatMessage({ id: `transformService.table.detail.paymentType.${form.difference}` })}
              variant="outlined"
              disabled={!form.customer || !form.difference}
              value={form.paymentType}
              error={form.paymentType_err ? true : false}
              helperText={form.paymentType_err}
              onChange={(e) => updateData({ name: 'paymentType' }, e.target.value)}
              fullWidth
            >
              {storedValueCards.map(payment => {
                return <MenuItem key={payment.id} value={payment.id}>{payment.name}</MenuItem>
              })}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required
              type="text"
              size="small"
              label={formatMessage({ id: 'transformService.table.detail.differencePrice' })}
              variant="outlined"
              onChange={(e) => updateData({ name: 'differencePrice', allowCharacter: /[^0-9]/g }, e.target.value)}
              disabled={!form.customer || !form.difference}
              value={form.differencePrice}
              error={form.differencePrice_err ? true : false}
              helperText={form.differencePrice_err}
              fullWidth
            />
          </Grid>
        </>}
      </Grid>
      <Divider style={{ margin: '8px 0px' }} />
      <EnhancedTableToolbar
        title="editTransformServicePage.table.selectPP"
        toolbox={
          <Button
            disabled={!form.customer}
            sx={{ m: 1, whiteSpace: 'nowrap' }}
            variant="contained"
            color="primary"
            onClick={() => { setOpenDialog('selectPP') }}
          >
            <FormattedMessage id="editTransformServicePage.selectService" />
          </Button>
        }
      />
      <EnhancedTable
        headerCells={headerTransformCells}
        rowCells={rowTransformCells}
        tableData={(form.selectPP ? [{ ...form.selectPP }] : []).map(i => formatData(i))}
      />
      <Divider style={{ margin: '8px 0px' }} />
      <EnhancedTableToolbar
        title="editTransformServicePage.table.title"
        toolbox={
          <Button
            disabled={!form.customer}
            sx={{ m: 1, whiteSpace: 'nowrap' }}
            variant="contained"
            color="primary"
            onClick={() => { setOpenDialog('product') }}
          >
            <FormattedMessage id="editTransformServicePage.addService" />
          </Button>
        }
      />
      <EnhancedTable
        headerCells={headerCells}
        rowCells={rowCells}
        tableData={form.services.map(i => formatFormData(i))}
        getActionIcons={product =>
          <DelectIconButton
            text={formatMessage({ id: 'editTransformServicePage.removeService' })}
            onClick={() => onDeleteItem(product)}
          />
        }
      />
      <Divider style={{ margin: '8px 0px' }} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            type="text"
            label={formatMessage({ id: 'consumedService.table.detail.note' })}
            variant="outlined"
            value={form.note}
            onChange={e => updateData({ name: 'note' }, e.target.value)}
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>
      <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
        <Button variant="contained" color="primary" onClick={handleClose}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          onClick={handleSave}
          disabled={loading || form.services.length === 0 || (!form.customer || !form.selectPP)}
          loading={loading}
          loadingPosition="start"
          loadingIndicator={<CircularProgress size={24} />}
          startIcon={<div />}
          variant="contained"
        >
          <FormattedMessage id="button.submit" />
        </LoadingButton>
      </Stack>
    </Box>
  )

}

export default EditTransformServicePage
