import React, { useState, useEffect, useContext } from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Toolbar from '@mui/material/Toolbar';

import EnhancedTable from 'components/EnhancedTable';
import SearchBox from 'components/SearchBox';
import FabAdd from 'components/FabAdd';
import ContextStore from 'modules/context';
import EditVendorDialog from './EditVendorDialog';
import VendorView from './VendorView';

function VendorList() {
  const { formatMessage } = useIntl()
  const userRights = useSelector(state => state.userRights)
  const { setBreadcrumbs } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const vendorMapping = useSelector(state => state.vendors.data)
  const vendors = useSelector(state => state.vendors.ordered)

  const [selectedVendor, setSelectedVendor] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [flagFilter, setFlagFilter] = useState({});

  const filteredVendors = filterVendors() //currentFilter && currentFilter.text ? filterByText() : suppliers

  const filterItems = [
    { name: 'name' },
    { name: 'nickname' },
    { name: 'note' },
    { name: 'businessNumber' },
    { name: 'contactName' },
    { name: 'code' },
  ].map(i => {i.text = formatMessage({ id: `vendor.table.detail.${i.name}` });return i})

  const flagFilterItems = [
    { name: 'supplier' },
    { name: 'internal' },
    { name: 'active' },
  ].map(i => {i.text = formatMessage({ id: `vendor.table.detail.${i.name}` });return i})

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.vendor' })
    }])
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const sp = new URLSearchParams(location.search)

    let needUpdate = false
    let newFilter = {}
    for (const f of flagFilterItems) {
      const value = sp.get(f.name)
      if (value && value !== flagFilter[f.name]) {
        newFilter[f.name] = value
        needUpdate = true
      } else if (!value && flagFilter[f.name]) {
        needUpdate = true
      }
    }
    if (needUpdate) {
      setFlagFilter(newFilter)
    }
  }, [location.search]);

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'nickname' },
    { text: 'contact.name' },
    { text: 'contact.phone' },
    { text: 'contact.mobile' },
  ].map(c => {c.text = formatMessage({ id: `vendor.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'nickname' },
    { field: 'contactName' },
    { field: 'contactPhone' },
    { field: 'contactMobile' },
  ]

  const onEditClick = async (vendor) => {
    const newData = { ...vendor }
    newData.shippingOut = Object.keys(newData.shippingOut || {}).map(k => ({
      name: vendorMapping[k].name,
      nickname: vendorMapping[k].nickname,
      id: vendorMapping[k].id,
    }))
    setSelectedVendor(newData)
  }

  const formatData = (vendor) => {
    const newData = { ...vendor }
    newData.contactName = newData.contacts && newData.contacts.length ? newData.contacts[0].contactName : ''
    newData.contactPhone = (newData.contacts && newData.contacts.length ? newData.contacts[0].contactPhone : '').replace(/\s/g, '').replace(/#$/,'')
    newData.contactMobile = newData.contacts && newData.contacts.length ? newData.contacts[0].contactMobile : ''
    // newData.shippingOut = Object.keys(newData.shippingOut || {}).map(k => vendorMapping[k].name).join(', ')
    return newData
  }

  const getNewVendor = () => {
    return {
      id: 'new',
      name: '',
      nickname: '',
      businessNumber: '',
      phone: '',
      fax: '',
      address: '',
      shippingOut: [],
      email: '',
      basicPurchaseAmount: '',
      freeShippingAmount: '',
      ownerName: '',
      ownerPhone: '',
      ownerMobile: '',
      billRule: '',
      note: '',
      contacts: [],
      supplier: true,
      internal: false,
      active: true,
    }
  }
  function filterVendors() {
    if (!currentFilter &&
        !flagFilter.supplier &&
        !flagFilter.internal &&
        !flagFilter.active) {
      return vendors
    }
    let items = [...vendors]

    if (flagFilter.supplier) {
      const type = flagFilter.supplier === 'type1'
      items = items.filter(i => i.supplier === type)
    }
    if (flagFilter.internal) {
      const type = flagFilter.internal === 'type1'
      items = items.filter(i => i.internal === type)
    }
    if (flagFilter.active) {
      const type = flagFilter.active === 'type1'
      items = items.filter(i => i.active === type)
    }
    if (currentFilter) {
      if (currentFilter.name === 'contactName') {
        items = items.filter(s => s.contacts.find(c => c.contactName.toLowerCase().includes(currentFilter.text.toLowerCase())))
      } else {
        items = items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
      }
    }
    return items
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function getMenuItem(name) {
    return [{ value: '' }, { value: 'type1' }, { value: 'type2' }].map(i => {
      i.label = i.value ? formatMessage({ id: `vendor.${name}.${i.value}` }) : formatMessage({ id: 'vendor.flag.all' })
      return i
    })
  }

  function updateFlagFilter(name, value) {
    const newFilter = { ...flagFilter }
    newFilter[name] = value
    setFlagFilter(newFilter)
    const sp = new URLSearchParams(location.search)
    const str1 = sp.toString()
    if (value === '') {
      sp.delete(name)
    } else {
      sp.set(name, value)
    }
    const str2 = sp.toString()
    if (str1 !== str2) {
      navigate({ pathname: location.pathname, search: `?${str2}` }, { replace: true });
    }
  }

  const getFilter = (filter) => {
    return <Grid item key={filter.name} xs={3} sm={3} md={3}>
      <TextField
        select
        type="text"
        size="small"
        label={filter.text}
        variant="outlined"
        onChange={e => {updateFlagFilter(filter.name, e.target.value)}}
        value={flagFilter[filter.name] || ''}
        fullWidth
      >
        {getMenuItem(filter.name).map((option, idx) => {
          return option.header ?
            (<ListSubheader key={`${option.value}-${idx}`}>{option.label}</ListSubheader>) :
            (<MenuItem key={`${option.value}-${idx}`} value={option.value}>{option.label}</MenuItem>)
        })}
      </TextField>
    </Grid>
  }

  return (
    <div style={{ padding: '12px 24px 80px 24px' }}>
      {selectedVendor &&
        <EditVendorDialog
          vendor={selectedVendor}
          handleClose={() => setSelectedVendor(null)}
        />
      }
      {userRights.hasUserRight('vendor-create') &&
        <FabAdd onClick={() => setSelectedVendor(getNewVendor())} />
      }
      <Toolbar sx={{ pl: 2, pr: 1 }}>
        <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="vendor.table.title" />
        </Typography>
        <Grid container spacing={3}>
          {flagFilterItems.map(f => getFilter(f))}
        </Grid>
        <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} updateUrlParams />
      </Toolbar>
      <EnhancedTable
        defaultOrder="asc"
        defaultOrderField="code"
        headerCells={headerCells}
        rowCells={rowCells}
        getExpandContent={vendor =>
          <VendorView
            vendor={vendor}
            vendorMapping={vendorMapping}
            // workflow={workflow}
            handleEditClick={() => onEditClick(vendor)}
          />
        }
        tableData={filteredVendors.map(i => formatData(i))}
      />
    </div>
  );
}

// VendorList.propTypes = {
//   workflow: PropTypes.shape({
//     lock: PropTypes.bool,
//   }).isRequired,
// };

export default VendorList;
