const i18n = {
  'selectCustomerDialog': {
    'title': '選擇客戶',
  },
  'selectEmployeeIdDialog': {
    'title': '選擇員工編號',
  },
  'selectSalesOrderDialog': {
    'title': '選擇營收單',
  },
  'selectMedicineDialog': {
    'title': '選擇藥品',
  },
  'selectDepartmentDialog': {
    'title': '選擇部門',
    'table': {
      'title': '部門列表',
      'header': {
        'name': '名稱'
      }
    }
  },
  'cancelled': {
    'cancelled': '確定取消此預約？',
    'cancelanotherappointment': '確定取消此預約並重新預約？',
    'cancelYes': '取消此預約',
    'cancelNo': '保留預約'
  },
  'recordDialog': {
    'title': {
      'merchandise': '商品修改紀錄',
      'product': '療程修改紀錄'
    },
    'table': {
      'create': '{name} 於 {time} 建立了 {item} ',
      'edit': '{name} 於 {time} 將 {item} 的{type}從 [{old}] 修改為 [{new}]',
      'type': {
        'merchandise': {
          'name': '名稱',
          'nickname': '簡稱',
          'note': '備註',
          'type': '分類1'
        },
        'product': {
          'cost': '成本',
          'name': '名稱',
          'note': '備註',
          'staffRelativesPrice': '員工眷屬價',
          'staffprice': '員工價',
          'vipprice': 'VIP價'
        }
      }

    }
  },
  'selectPurchaseOrderDialog': {
    'title': '待核對的採購單',
    'merchandise': {
      'code': '品號',
      'nickname': '簡稱',
      'name': '品名',
      'require': '需求量',
      'unitPrice': '單價',
      'orderUnit': '單位',
      'amount': '採購數量',
      'delivery': '已到貨數量',
      'price': '金額',
    }
  },
  'selectPurchasedServiceDialog': {
    'title': '選擇已購買的服務',
    'table': {
      'header': {
        'sn': '營收單號',
        'date': '日期',
        'quantity': '購買數量',
        'amount': '剩餘數量',
        'refund': '退還數量',
        'consume': '消耗數量'
      },
      'totalrefund': '總退還數量',
      'totalconsume': '總消耗數量'
    }
  },
}
export default i18n
