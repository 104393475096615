import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Popover from '@mui/material/Popover';
import InputAdornment from '@mui/material/InputAdornment';

import DelectIconButton from 'components/DelectIconButton';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import DatePickerField from 'components/DatePickerField';
import EnhancedTable from 'components/EnhancedTable';
import SelectProductDialog from 'components/SelectProductDialog';
import SelectVerdorDialog from 'components/SelectVerdorDialog';
import ColorPicker from 'components/ColorPicker';
import ColorDot from 'components/ColorDot';
import { getRandomHash } from 'modules/uitls';
import { addError, removeError } from 'modules/editor';
import { callFunction } from 'modules/firebase';
import { ColorPickerDefaultColors as defaultColors } from 'constants';
import { useModuleMapping } from 'hooks/modules';
import SelectTagDialog from 'components/SelectTagDialog';
import CustomerTypeRuleDialog from './CustomerTypeRuleDialog';
import SelectMerchandiseDialog from 'components/SelectMerchandiseDialog';

const numberRule = /[^0-9]/g
const floatingPointNumberRule = /[^0-9.]/g
const percentageNumber = /[^0-9%]/g
const percentageNumberWithStar = /[^*0-9%]/g

function EditProductDialog({ product, products, productMapping, handleClose }) {
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const prices = Object.values(useSelector(state => state.config.data?.productPrices || {})).sort()
  const config = useSelector(state => state.config.data)
  const customerTypes = Object.keys(config.customerType || {}).filter(i => config.customerType[i].active).map(i => config.customerType[i])
  const customerTypeMapping = customerTypes.reduce((acc, cur) => { acc[cur.id] = cur; return acc }, {})
  const moduleMapping = useModuleMapping()
  const vendorMapping = useSelector(state => state.internalVendors.data)
  const vendors = useSelector(state => state.internalVendors.ordered)
  const merchandiseMapping = useSelector(state => state.merchandises.data)
  const merchandises = useSelector(state => state.merchandises.ordered)

  const defaultState = {
    ...product,
    unit: product.unit || '',
    color: product.color || '#FF00FF',
    onShelfDate: product.onShelfDate ? dayjs(product.onShelfDate) : null,
    offShelfDate: product.offShelfDate ? dayjs(product.offShelfDate) : null,
    commonPrice: product.commonPrice.map(i => ({
      ...i,
      useAmount: i.useAmount ? String(i.useAmount) : '',
      customerType: i.customerType || '',
      rule: i.customerType && i.rule ? i.rule : '',
      customerTypeRule: getCustomerTypeRuleText(i.customerType, i.rule),
      onShelfDate: i.onShelfDate ? dayjs(i.onShelfDate) : null,
      offShelfDate: i.offShelfDate ? dayjs(i.offShelfDate) : null,
      table: 'commonPrice',
    })),
    transformService: (product.transformService || []).map(i => ({
      ...i,
      serviceName: productMapping[i.product].name,
      serviceNickname: productMapping[i.product].nickname,
      consumeAmount: i.consumeAmount ? String(i.consumeAmount) : '',
      amount: i.amount ? String(i.amount) : '',
      onShelfDate: i.onShelfDate ? dayjs(i.onShelfDate) : null,
      offShelfDate: i.offShelfDate ? dayjs(i.offShelfDate) : null,
      table: 'transformService',
    })),
    merchandises: (product.merchandises || []).map(i => ({
      ...i,
      name: merchandiseMapping[i.merchandise].name,
      nickname: merchandiseMapping[i.merchandise].nickname,
      sku: merchandiseMapping[i.merchandise].sku,
      consumeAmount: i.consumeAmount ? String(i.consumeAmount) : '',
      table: 'merchandises',
    })),
  }
  const [productData, setProductData] = useState(defaultState);
  const [openDialog, setOpenDialog] = useState('');

  const _fields = [
    { name: 'name', type: 'text', required: true, sm: 6, order: 0 },
    { name: 'nickname', type: 'text', sm: 6, order: 1 },
    { name: 'type', type: 'select', required: true, sm: 3, order: 2 },
    { name: 'tags', type: 'popup', sm: 9, onClick: () => setOpenDialog('tag'), order: 3 },
    { name: 'cost', type: 'text', sm: 3, allowCharacter: floatingPointNumberRule, order: 4 }, // 成本
    { name: 'price', type: 'text', sm: 3, allowCharacter: floatingPointNumberRule, order: 5 }, // 售價
    { name: 'duration', type: 'text', sm: 3, allowCharacter: numberRule, order: 6 }, // 服務時間
    { name: 'color', type: 'color', sm: 3, order: 7 },
    { name: 'unit', type: 'text', sm: 3, order: 8 },
    { name: 'line1', type: '-', order: 9 },
    { name: 'seller', type: 'popup', sm: 12, onClick: () => setOpenDialog('seller'), order: 10 },
    { name: 'onShelfDate', type: 'onTime', sm: 3, order: 11 },
    { name: 'offShelfDate', type: 'offTime', sm: 3, order: 12 },
    { name: 'line2', type: '-', order: 15 },
    { name: 'description', type: 'multiline', sm: 12, order: 16 },
    { name: 'note', type: 'text', sm: 12, order: 17 }, // 備註
  ]
  if (moduleMapping.sales) {
    _fields.push({ name: 'line3', type: '-', order: 17 })
  }
  if (moduleMapping.bonus) {
    _fields.push({ name: 'salesBonus', type: 'text', sm: 3, allowCharacter: percentageNumberWithStar, order: 13 })
    _fields.push({ name: 'operatorBonus', type: 'text', sm: 3, allowCharacter: percentageNumber, order: 14 })
  }
  const fields = _fields.sort((a, b) => a.order - b.order)

  const selectedMerchandises = (productData.merchandises || []).reduce((acc, cur) => {
    acc[cur.id] = true
    return acc
  }, {});

  const headerCells = [
    { text: 'name' },
    { text: 'useAmount' },
    { text: 'price' },
    { text: 'customerTypeRule' },
    { text: 'onShelfDate' },
    { text: 'offShelfDate' },
  ].map(c => { c.text = formatMessage({ id: `product.commonPrice.${c.text}` }); return c })

  const rowCells = [
    {
      field: 'name',
      fullWidth: true,
      align: 'left',
      style: { minWidth: '140px' },
      type: 'autocomplete',
      label: formatMessage({ id: 'product.commonPrice.name' }),
      required: true,
      getOptions: () => prices,
      onValueChanged: onCellValueChanged
    },
    {
      field: 'useAmount',
      fullWidth: true,
      align: 'left',
      style: { maxWidth: '110px' },
      type: 'input-number',
      label: formatMessage({ id: 'product.commonPrice.useAmount' }),
      onValueChanged: onCellValueChanged
    },
    {
      field: 'price',
      fullWidth: true,
      align: 'left',
      style: { maxWidth: '200px' },
      type: 'input-number',
      inputRule: /[^0-9%~]/g,
      required: true,
      label: formatMessage({ id: 'product.commonPrice.price' }),
      onValueChanged: onCellValueChanged
    },
    {
      field: 'customerTypeRule',
      fullWidth: true,
      align: 'left',
      style: { minWidth: '220px' },
      type: 'popup',
      label: formatMessage({ id: 'product.commonPrice.customerTypeRule' }),
      onClick: (commonPrice) => setOpenDialog(`commonPrice*${commonPrice.id}*${commonPrice.customerType}*${commonPrice.rule}`),
    },
    {
      field: 'onShelfDate',
      fullWidth: true,
      align: 'left',
      style: { maxWidth: '150px' },
      type: 'date',
      label: formatMessage({ id: 'product.commonPrice.onShelfDate' }),
      onValueChanged: onCellValueChanged
    },
    {
      field: 'offShelfDate',
      fullWidth: true,
      align: 'left',
      style: { maxWidth: '150px' },
      type: 'date',
      label: formatMessage({ id: 'product.commonPrice.offShelfDate' }),
      onValueChanged: onCellValueChanged
    },
  ];

  const serviceHeaderCells = [
    { text: 'product' },
    { text: 'consumeAmount' },
    { text: 'amount' },
    { text: 'onShelfDate' },
    { text: 'offShelfDate' },
  ].map(c => { c.text = formatMessage({ id: `product.transformService.${c.text}` }); return c })

  const serviceRowCells = [
    { field: 'serviceNickname', tooltip: 'serviceName' },
    {
      field: 'consumeAmount',
      fullWidth: true,
      align: 'left',
      style: { maxWidth: '140px' },
      type: 'input-number',
      required: true,
      label: formatMessage({ id: 'product.transformService.consumeAmount' }),
      onValueChanged: onCellValueChanged
    },
    {
      field: 'amount',
      fullWidth: true,
      align: 'left',
      style: { maxWidth: '140px' },
      type: 'input-number',
      required: true,
      label: formatMessage({ id: 'product.transformService.amount' }),
      onValueChanged: onCellValueChanged
    },
    {
      field: 'onShelfDate',
      fullWidth: true,
      align: 'left',
      style: { maxWidth: '150px' },
      type: 'date',
      label: formatMessage({ id: 'product.transformService.onShelfDate' }),
      onValueChanged: onCellValueChanged
    },
    {
      field: 'offShelfDate',
      fullWidth: true,
      align: 'left',
      style: { maxWidth: '150px' },
      type: 'date',
      label: formatMessage({ id: 'product.transformService.offShelfDate' }),
      onValueChanged: onCellValueChanged
    },
  ];

  const merchandiseHeaderCells = [
    { text: 'merchandise' },
    { text: 'consumeAmount' },
    { text: 'sku' },
    // { text: 'onShelfDate' },
    // { text: 'offShelfDate' },
  ].map(c => { c.text = formatMessage({ id: `product.merchandise.${c.text}` }); return c })

  const merchandiseRowCells = [
    { field: 'nickname', tooltip: 'name' },
    {
      field: 'consumeAmount',
      fullWidth: true,
      align: 'left',
      style: { maxWidth: '140px' },
      type: 'input-number',
      required: true,
      label: formatMessage({ id: 'product.merchandise.consumeAmount' }),
      onValueChanged: onCellValueChanged
    },
    { field: 'sku' },
  ];

  function getDisplayFields() {
    const displayFields = [...fields]
    if (productData.type === 'service') {// 服務
      displayFields.splice(8, 1)
    } else {// 商品/儲值/點數
      displayFields.splice(6, 2)
      const operatorBonusIndex = displayFields.findIndex(i => i.name === 'operatorBonus')
      if (operatorBonusIndex !== -1) {
        displayFields.splice(operatorBonusIndex, 1)
      }
    }
    return displayFields
  }

  function onCellValueChanged(cellData, field, value) {
    const m = productData[cellData.table].find(i => i.id === cellData.id)
    if (!m) {
      return
    }

    m[field] = value
    if (['name', 'price', 'amount', 'consumeAmount'].includes(field) && (value.trim() === '' || value === null)) {
      addError(m, field, formatMessage({ id: 'form.field.isRequired' }))
    } else if (field === 'amount' || field === 'consumeAmount') {
      if (isNaN(value) || value === '' || parseInt(value) === 0) {
        addError(m, field, '數量錯誤')
      } else {
        removeError(m, field)
      }
    } else if (field === 'price') {
      // 有幾種格式 n, n%, n~m, n%~m%, n%~m, n~m%, n~, n%~
      const v = value.trim().split('~')
      let err = false
      if (v.length === 1) {
        if (!(/^[0-9]+%?$/).test(v[0])) {
          err = true
        }
      } else if (v.length === 2) {
        const n = (/^[0-9]+%?$/).test(v[0])
        const m = (/^[0-9]+%?$/).test(v[1])
        if ((n && m) || (n && v[1] === '')) {
        } else {
          err = true
        }
      } else {
        err = true
      }
      if (err) {
        addError(m, field, formatMessage({ id: 'form.price.formatError' }))
      } else {
        removeError(m, field)
      }
    } else {
      removeError(m, field)
    }
    updateProductData({ name: cellData.table }, [...productData[cellData.table]]);
  }

  function onAddPriceItem() {
    updateProductData({ name: 'commonPrice' }, (productData.commonPrice || []).concat({
      id: getRandomHash(),
      name: '',
      useAmount: '',
      price: '',
      customerType: '',
      rule: '',
      customerTypeRule: '',
      onShelfDate: null,
      offShelfDate: null,
      table: 'commonPrice',
    }));
  }

  function onSelectProduct(products) {
    if (products.length) {
      for (const p of products) {
        const pData = {
          id: getRandomHash(),
          product: p.id,
          serviceName: p.name,
          serviceNickname: p.nickname,
          consumeAmount: '1',
          amount: '1',
          onShelfDate: null,
          offShelfDate: null,
          table: 'transformService',
        }
        productData.transformService.push(pData)
      }
      updateProductData({ name: 'transformService' }, [...productData.transformService])
    }
  }

  function onMerchandiseAdded(merchandises) {
    if (merchandises.length) {
      for (const m of merchandises) {
        const mData = {
          id: getRandomHash(),
          merchandise: m.id,
          name: m.name,
          nickname: m.nickname,
          consumeAmount: '1',
          sku: m.sku,
          table: 'merchandises',
        }

        productData.merchandises.push(mData)
      }
      updateProductData({ name: 'merchandises' }, [...productData.merchandises])
    }
  }

  function onDeleteItem(table, item) {
    const index = productData[table].findIndex(i => i.id === item.id)
    if (index !== -1) {
      productData[table].splice(index, 1)
      updateProductData({ name: table }, [...productData[table]]);
    }
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }
    if (value !== '') {
      if (field.name === 'salesBonus' && !(/^\*?[0-9]+%?$/).test(value)) {
        return formatMessage({ id: 'form.field.formatError' })
      }
      if (field.name === 'operatorBonus' && !(/^[0-9]+%?$/).test(value)) {
        return formatMessage({ id: 'form.field.formatError' })
      }
    }
    return ''
  }

  function onTagChanged(tags) {
    updateProductData({ name: 'tags' }, tags);
  }

  const onSellerChanged = (vendors) => {
    updateProductData({ name: 'seller' }, vendors.map(i => i.id));
  }

  function getCustomerTypeRuleText(customerType, rule) {
    if (!customerType || !rule) {
      return ''
    }
    const text = formatMessage({ id: `product.customerTypeRule.${rule}` })
    return `${customerTypeMapping[customerType].name}(${text})`
  }

  function onCustomerTypeRuleChanged(customerType, rule, params) {
    const s = params.split('*')
    const p = productData.commonPrice.find(i => i.id === s[1])
    if (p) {
      p.customerType = customerType
      p.rule = rule
      p.customerTypeRule = getCustomerTypeRuleText(customerType, rule)
      // 相同的客戶類型不可以有一個以上的優先

      const mapping = {}
      for (const i of productData.commonPrice) {
        removeError(i, 'customerTypeRule')
      }
      for (const idx in productData.commonPrice) {
        const i = productData.commonPrice[idx]
        if (i.customerType && (i.rule === 'priority' || i.rule === 'limitAndPriority')) {
          if (mapping[i.customerType] === undefined) {
            mapping[i.customerType] = Number(idx)
          } else {
            const ii = productData.commonPrice[mapping[i.customerType]]
            addError(ii, 'customerTypeRule', '重複的優先設定')
            addError(i, 'customerTypeRule', '重複的優先設定')
          }
        }
      }
      updateProductData({ name: 'commonPrice' }, productData.commonPrice);
    }
  }

  function updateProductData(field, value) {
    let newValue = value
    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }
    if (field.maxLength) {
      newValue = newValue.substring(0, field.maxLength)
    }

    if (newValue === undefined || productData[field.name] === newValue) return;

    let err = validateField(field, value)

    let newData = { ...productData, [field.name]: newValue, [`${field.name}_err`]: err }

    setProductData(newData)
  }

  function getMenuItem(field) {
    if (field === 'type') {
      return [
        { value: 'merchandise' },
        { value: 'service' },
        { value: 'storedValue' },
        { value: 'pointCard' },
      ].map(i => ({ ...i, label: formatMessage({ id: `product.type.${i.value}` }) }))
    }
    return []
  }

  function createField(field) {
    if (field.type === 'text') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          disabled={field.disabled}
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `product.edit.${field.name}` })}
          variant="outlined"
          onChange={e => { updateProductData(field, e.target.value) }}
          value={productData[field.name] ?? ''}
          error={productData[`${field.name}_err`] ? true : false}
          helperText={productData[`${field.name}_err`]}
          fullWidth
        />
      </Grid>
    } else if (field.type === 'select') {
      const menuItems = getMenuItem(field.name)
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <TextField
          required={field.required}
          disabled={field.disabled || menuItems.length === 0 || product.id !== 'new'}
          select
          type="text"
          size="small"
          label={formatMessage({ id: `product.edit.${field.name}` })}
          variant="outlined"
          onChange={e => { updateProductData(field, e.target.value) }}
          value={productData[field.name]}
          error={productData[`${field.name}_err`] ? true : false}
          helperText={productData[`${field.name}_err`]}
          fullWidth
        >
          {menuItems.map((option) => {
            return option.header ?
              (<ListSubheader disabled key={option.value}>{option.label}</ListSubheader>) :
              (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
          })}
        </TextField>
      </Grid>
    } else if (field.type === 'onTime') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <DatePickerField
          fullWidth
          label={formatMessage({ id: `product.edit.${field.name}` })}
          value={productData[field.name]}
          onChange={date => updateProductData(field, date)}
          minDateMessage={formatMessage({ id: 'form.date.beforeStartDate' })}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    } else if (field.type === 'offTime') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <DatePickerField
          fullWidth
          label={formatMessage({ id: `product.edit.${field.name}` })}
          value={productData[field.name]}
          minDate={dayjs(productData.onShelfDate).toDate()}
          onChange={date => updateProductData(field, date)}
          minDateMessage={formatMessage({ id: 'form.date.beforeStartDate' })}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    } else if (field.type === 'popup') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          disabled={field.disabled}
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `product.edit.${field.name}` })}
          variant="outlined"
          onClick={field.onClick} //open popup
          value={(productData[field.name] || []).map(s => field.name === 'tags' ? s : vendorMapping[s].name).join(', ')}
          error={productData[`${field.name}_err`] ? true : false}
          helperText={productData[`${field.name}_err`]}
          fullWidth
        />
      </Grid>
    } else if (field.type === 'multiline') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}>
        <TextField
          multiline
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `product.edit.${field.name}` })}
          variant="outlined"
          onChange={e => { updateProductData(field, e.target.value) }}
          value={productData[field.name]}
          fullWidth
        />
      </Grid>
    } else if (field.type === 'color') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          disabled={field.disabled}
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `product.edit.${field.name}` })}
          variant="outlined"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          value={productData[field.name]}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ColorDot style={{ backgroundColor: productData[field.name] }}></ColorDot>
              </InputAdornment>
            ),
          }}
          helperText={productData[`${field.name}_err`]}
          fullWidth
        />
      </Grid>
    } else if (field.type === '-') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    }
  }

  const handleSave = async () => {
    setLoading(true);

    // 檢查有沒有欄位有錯誤
    // 檢查必填的欄位是否都有值
    let err = {}
    for (let field of fields) {
      if (field.required && productData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }

    let commonPriceError = false
    for (const p of productData.commonPrice) {
      for (const field of rowCells) {
        if (field.required && (p[field.field] === null || p[field.field].trim() === '')) {
          addError(p, field.field, formatMessage({ id: 'form.field.isRequired' }))
          commonPriceError = true
        }
      }
    }

    let transformServiceError = false
    if (productData.type === 'pointCard') {
      for (const p of productData.transformService) {
        for (const field of serviceRowCells) {
          if (field.required && (p[field.field] === null || p[field.field].trim() === '')) {
            addError(p, field.field, formatMessage({ id: 'form.field.isRequired' }))
            transformServiceError = true
          }
        }
      }
    }

    let merchandiseError = false
    if (productData.type === 'merchandise') {
      for (const p of productData.merchandises) {
        for (const field of merchandiseRowCells) {
          if (field.required && (p[field.field] === null || p[field.field].trim() === '')) {
            addError(p, field.field, formatMessage({ id: 'form.field.isRequired' }))
            merchandiseError = true
          }
        }
      }
    }

    let newData = {
      ...productData,
      commonPrice: productData.commonPrice.map(i => ({ ...i })),
      ...err
    }
    if (commonPriceError) {
      newData = { ...newData, commonPrice: [...newData.commonPrice] }
    }
    if (transformServiceError) {
      newData = { ...newData, transformService: [...newData.transformService] }
    }
    if (merchandiseError) {
      newData = { ...newData, merchandises: [...newData.merchandises] }
    }
    if (Object.keys(err).length > 0 || commonPriceError || transformServiceError) {
      setProductData(newData)
    }

    for (const p of newData.commonPrice) {
      for (const field of rowCells) {
        if (p.errors && p.errors[field.field] !== undefined && p.errors[field.field] !== '') {
          setLoading(false)
          return
        }
      }
    }

    if (productData.type === 'pointCard') {
      for (const p of newData.transformService) {
        for (const field of serviceRowCells) {
          if (p.errors && p.errors[field.field] !== undefined && p.errors[field.field] !== '') {
            setLoading(false)
            return
          }
        }
      }
    }

    if (productData.type === 'merchandise') {
      for (const p of newData.merchandises) {
        for (const field of merchandiseRowCells) {
          if (p.errors && p.errors[field.field] !== undefined && p.errors[field.field] !== '') {
            setLoading(false)
            return
          }
        }
      }
    }

    for (const field of fields) {
      if (field.type === '-') {
        continue
      }
      if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        setLoading(false)
        return
      }
    }

    // 移除 error 欄位 - start
    for (const field of fields) {
      delete newData[`${field.name}_err`]
    }
    newData.commonPrice = newData.commonPrice.map(i => {
      const data = { ...i }
      delete data.errors
      delete data.customerTypeRule
      delete data.table
      data.useAmount = Number(data.useAmount)
      if (data.onShelfDate) {
        data.onShelfDate = dayjs(data.onShelfDate).format('YYYY-MM-DD')
      }
      if (data.offShelfDate) {
        data.offShelfDate = dayjs(data.offShelfDate).format('YYYY-MM-DD')
      }
      return data
    })
    delete newData.commonPrice_err

    if (newData.type === 'pointCard') {
      newData.transformService = newData.transformService.map(i => {
        const data = { ...i }
        delete data.errors
        delete data.serviceName
        delete data.serviceNickname
        delete data.table
        data.amount = Number(data.amount)
        data.consumeAmount = Number(data.consumeAmount)
        if (data.onShelfDate) {
          data.onShelfDate = dayjs(data.onShelfDate).format('YYYY-MM-DD')
        }
        if (data.offShelfDate) {
          data.offShelfDate = dayjs(data.offShelfDate).format('YYYY-MM-DD')
        }
        return data
      })
      delete newData.transformService_err
    }

    if (['merchandise', 'service'].includes(newData.type)) {
      newData.merchandises = newData.merchandises.reduce((acc, cur) => {
        acc.push({
          id: cur.id,
          merchandise: cur.merchandise,
          consumeAmount:  Number(cur.consumeAmount),
        })
        return acc
      }, [])
    }
    // 移除 error 欄位 - end

    // 調整格式 - start
    newData.cost = Number(newData.cost)
    newData.price = Number(newData.price)
    newData.duration = Number(newData.duration)
    if (newData.onShelfDate) {
      newData.onShelfDate = dayjs(newData.onShelfDate).format('YYYY-MM-DD')
    }
    if (newData.offShelfDate) {
      newData.offShelfDate = dayjs(newData.offShelfDate).format('YYYY-MM-DD')
    }
    // 調整格式 - end

    if (newData) {
      try {
        await callFunction('saveProduct', { id: product.id, ...newData })
      } catch (ex) {
        console.log(ex)
      }
    } else {
      setLoading(false);
      return
    }
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      {openDialog === 'product' && <SelectProductDialog
        headerCells={[   { text: 'name', sort: 'name' }]}
        rowCells={[{ field: 'name' }]}
        filterItems={[ { name: 'name' }]}
        filterSetting={{ type: { hidden: true, default: 'service' } }}
        defaultSelectedItems={[]}
        handleClose={() => setOpenDialog('')}
        handleSave={onSelectProduct}
        items={products}
        onShelf
        dialogTitle={formatMessage({ id: 'selectProductDialog.title' })}
        tableTitle={formatMessage({ id: 'selectProductDialog.table.title' })}
        // isMobile={isMobile}
        productMapping={productMapping}
      />}
      {openDialog === 'tag' && <SelectTagDialog
        defaultSelectedItems={productData.tags}
        handleClose={() => setOpenDialog('')}
        handleSave={onTagChanged}
      />}
      {openDialog === 'seller' && <SelectVerdorDialog
        multiSelect
        headerCells={[
          { name: 'code', sort: 'code' },
          { name: 'name' },
          { name: 'nickname' },
          { name: 'businessNumber' }
        ]}
        rowCells={[
          { field: 'code' },
          { field: 'name' },
          { field: 'nickname' },
          { field: 'businessNumber' }
        ]}
        filterItems={[{ name: 'name' },{ name: 'nickname' },{ name: 'businessNumber' }]}
        dialogTitle={formatMessage({ id: 'selectSellerDialog.title' })}
        tableTitle={formatMessage({ id: 'selectSellerDialog.table.title' })}
        handleClose={() => setOpenDialog('')}
        handleSave={onSellerChanged}
        defaultSelectedItems={productData.seller}
        items={vendors}
      />}
      {openDialog === 'merchandise' && <SelectMerchandiseDialog
        multiSelect
        maxWidth="lg"
        headerCells={[{ name: 'code', sort: 'code' }, { name: 'nickname', sort: 'nickname' }, { name: 'sku' }, { name: 'note' }]}
        rowCells={[{ field: 'code' }, { field: 'nickname', tooltip: 'name' }, { field: 'sku' }, { field: 'note' }]}
        filterItems={[{ name: 'nickname' }, { name: 'name' }, { name: 'code' }, { name: 'note' }]}
        dialogTitle={formatMessage({ id: 'selectMerchandiseDialog.title' })}
        tableTitle={formatMessage({ id: 'selectMerchandiseDialog.table.title' })}
        handleClose={() => setOpenDialog('')}
        handleSave={onMerchandiseAdded}
        defaultSelectedItems={[]}
        ignoreIds={productData.merchandises.map(m => m.id)}
        items={merchandises.filter(m => !selectedMerchandises[m.id])}
      />}
      {openDialog.startsWith('commonPrice') && <CustomerTypeRuleDialog
        // defaultSelectedItems={productData.tags}
        handleClose={() => setOpenDialog('')}
        handleSave={onCustomerTypeRuleChanged}
        params={openDialog}
      />}
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ColorPicker
          colors={defaultColors}
          value={productData.color}
          onChangeComplete={(color) => {
            updateProductData({ name: 'color' }, color);
          }}
        />
      </Popover>
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id={`product.dialog.title.${product.id === 'new' ? 'add' : 'edit'}`} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={3}>
          {getDisplayFields().map(field => createField(field))}
        </Grid>
        {moduleMapping.sales && <div style={{ margin: '10px 0' }}>
          <EnhancedTableToolbar
            title="product.commonPrice.title"
            toolbox={
              <Button
                disabled={false}
                sx={{ m: 1, whiteSpace: 'nowrap' }}
                variant="contained"
                color="primary"
                onClick={onAddPriceItem}
              >
                <FormattedMessage id={'product.edit.addCommonPrice'} />
              </Button>
            }
          />
          <EnhancedTable
            headerCells={headerCells}
            rowCells={rowCells}
            tableData={productData.commonPrice}
            getActionIcons={item =>
              <DelectIconButton text={formatMessage({ id: 'product.edit.removeCommonPrice' })} onClick={() => onDeleteItem('commonPrice', item)} />
            }
          />
        </div>}
        {moduleMapping.sales && ['merchandise', 'service'].includes(productData.type) && <div style={{ margin: '10px 0' }}>
          <EnhancedTableToolbar
            title="product.merchandise.title"
            toolbox={
              <Button
                disabled={false}
                sx={{ m: 1, whiteSpace: 'nowrap' }}
                variant="contained"
                color="primary"
                onClick={() => setOpenDialog('merchandise')}
              >
                <FormattedMessage id={'product.edit.addMerchandise'} />
              </Button>
            }
          />
          <EnhancedTable
            headerCells={merchandiseHeaderCells}
            rowCells={merchandiseRowCells}
            tableData={productData.merchandises}
            getActionIcons={item =>
              <DelectIconButton text={formatMessage({ id: 'product.edit.removeMerchandise' })} onClick={() => onDeleteItem('merchandises', item)} />
            }
          />
        </div>}
        {moduleMapping.sales && productData.type === 'pointCard' && <div style={{ margin: '10px 0' }}>
          <EnhancedTableToolbar
            title="product.transformService.title"
            toolbox={
              <Button
                disabled={false}
                sx={{ m: 1, whiteSpace: 'nowrap' }}
                variant="contained"
                color="primary"
                onClick={() => setOpenDialog('product')}
              >
                <FormattedMessage id={'product.edit.addService'} />
              </Button>
            }
          />
          <EnhancedTable
            headerCells={serviceHeaderCells}
            rowCells={serviceRowCells}
            tableData={productData.transformService}
            getActionIcons={item =>
              <DelectIconButton text={formatMessage({ id: 'product.edit.removeService' })} onClick={() => onDeleteItem('transformService', item)} />
            }
          />
        </div>}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          onClick={handleSave}
          loading={loading}
          loadingPosition="start"
          loadingIndicator={<CircularProgress size={24} />}
          startIcon={<div />}
          variant="contained"
        >
          <FormattedMessage id="button.save" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

EditProductDialog.propTypes = {
  product: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};

export default EditProductDialog;
