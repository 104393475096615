import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';

import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';

import EnhancedTableEx from 'components/EnhancedTableEx';
import SearchBox from 'components/SearchBox';
import FabAdd from 'components/FabAdd';
import ContextStore from 'modules/context';
import ProductView from './ProductView';
import EditProductDialog from './EditProductDialog.js';
import ExportProductListDialog from './ExportProductListDialog';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ProductList() {
  const { formatMessage } = useIntl()
  const userRights = useSelector(state => state.userRights)
  const { setBreadcrumbs } = useContext(ContextStore)
  // const [products, setProducts] = useState([]);
  // const products = useFirestoreData('products')
  const config = useSelector(state => state.config.data)
  const customerTypes = Object.keys(config.customerType || {}).filter(i => config.customerType[i].active).map(i => config.customerType[i])
  const customerTypeMapping = customerTypes.reduce((acc, cur) => {acc[cur.id] = cur; return acc}, {})
  const productMapping = useSelector(state => state.products.data)
  const products = useSelector(state => state.products.ordered)

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState({ type: '', tags: [] });
  const tags = Object.values(useSelector(state => state.config.data?.productTags || {})).sort()
  const [openDialog, setOpenDialog] = useState(false)

  const filteredProducts = filterProducts()

  const filterItems = [
    { name: 'name' },
    { name: 'nickname' },
    { name: 'description' },
    { name: 'note' },
  ].map(i => { i.text = formatMessage({ id: `product.edit.${i.name}` }); return i })

  const categoryFilterItems = [
    { name: 'type' },
    { name: 'tags' },
  ].map(i => { i.text = formatMessage({ id: `product.edit.${i.name}` }); return i })

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.products.list' })
    }])
    // listType.current = ''
    // const onSnapshot = snapshot => {
    //   const products = []
    //   snapshot.forEach(doc => {
    //     products.push({ ...doc.data(), id: doc.id })
    //   });
    //   // listType.current = type
    //   setProducts(products)
    // }
    // const unsubscribe = firebase.firestore().collection('products').onSnapshot(onSnapshot, err => { })
    // return () => unsubscribe()
  }, []);

  const _headerCells = [
    { text: 'name', align: 'left', sort: 'code', order: 0 },
    { text: 'nickname', align: 'left', order: 1 },
    { text: 'description', align: 'left', order: 2 },
  ]

  const _rowCells = [
    { field: 'name', align: 'left', order: 0 },
    { field: 'nickname', align: 'left', order: 1 },
    { field: 'description', align: 'left', order: 2 },
  ]

  const headerCells = _headerCells
    .map(c => { c.text = formatMessage({ id: `product.table.header.${c.text}` }); return c })
    .sort((a, b) => a.order - b.order)
  const rowCells = _rowCells.sort((a, b) => a.order - b.order)

  const onEditClick = async (product) => {
    const newData = { ...product }
    setSelectedProduct(newData)
  }

  function filterProducts() {
    if (!currentFilter && !categoryFilter.type && categoryFilter.tags.length === 0) {
      return products
    }
    let items = [...products]
    if (categoryFilter.type) {
      items = items.filter(i => i.type === categoryFilter.type)
    }
    if (categoryFilter.tags.length > 0) {
      items = items.filter(i => categoryFilter.tags.every(tag => (i.tags || []).includes(tag)))
    }
    if (currentFilter) {
      items = items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
    return items
  }

  function getMenuItem(name) {
    if (name === 'type') {
      return [
        { value: '' },
        { value: 'merchandise' },
        { value: 'service' },
        { value: 'storedValue' },
        { value: 'pointCard' },
      ].map(i => {
        i.label = i.value ? formatMessage({ id: `product.type.${i.value}` }) : formatMessage({ id: 'product.type.all' })
        return i
      })
    }
    return []
  }

  function getNewProductSetting() {
    return {
      id: 'new',
      name: '',
      nickname: '',
      tags: [],
      type: 'service',
      cost: 0,
      price: 0,
      duration: 30,
      color: '#FF00FF',
      note: '',
      unit: '',
      description: '',
      onShelfDate: null,
      offShelfDate: null,
      commonPrice: [],
    }
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function updateCategoryFilter(name, value) {
    const newFilter = { ...categoryFilter }
    newFilter[name] = value
    setCategoryFilter(newFilter)
  }

  const getFilter = (filter) => {
    if (filter.name === 'tags') {
      return <Grid item key={filter.name} xs={6} sm={6} md={6}>
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-multiple-checkbox-label">{formatMessage({ id: 'product.edit.tags' })}</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={categoryFilter.tags}
            onChange={e => { updateCategoryFilter(filter.name, e.target.value) }}
            input={<OutlinedInput label={formatMessage({ id: 'product.edit.tags' })} />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {tags.map((tag) => (
              <MenuItem key={tag} value={tag}>
                <Checkbox checked={categoryFilter.tags.includes(tag)} />
                <ListItemText primary={tag} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    } else {
      return <Grid item key={filter.name} xs={6} sm={6} md={6}>
        <TextField
          select
          type="text"
          size="small"
          label={filter.text}
          variant="outlined"
          onChange={e => { updateCategoryFilter(filter.name, e.target.value) }}
          value={categoryFilter[filter.name] || ''}
          fullWidth
        >
          {getMenuItem(filter.name).map((option, idx) => {
            return option.header ?
              (<ListSubheader key={`${option.value}-${idx}`}>{option.label}</ListSubheader>) :
              (<MenuItem key={`${option.value}-${idx}`} value={option.value}>{option.label}</MenuItem>)
          })}
        </TextField>
      </Grid>
    }
  }

  return (
    <div style={{ padding: '12px 24px 80px 24px' }}>
      {openDialog &&
        <ExportProductListDialog
          products={products}
          handleClose={() => setOpenDialog(false)}
        />
      }
      {selectedProduct &&
        <EditProductDialog
          productMapping={productMapping}
          products={products}
          product={selectedProduct}
          handleClose={() => setSelectedProduct(null)}
        />
      }
      {userRights.hasUserRight('product-create') &&
        <FabAdd onClick={() => setSelectedProduct(getNewProductSetting())} />
      }
      <Toolbar sx={{ pl: 2, pr: 1 }}>
        <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id={'product.table.title'} />
        </Typography>
        <Grid container spacing={3}>
          {categoryFilterItems.map(f => getFilter(f))}
        </Grid>
        <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} updateUrlParams />
      </Toolbar>
      <EnhancedTableEx
        defaultOrder="asc"
        defaultOrderField="code"
        headerCells={headerCells}
        rowCells={rowCells}
        getHeaderActionIcons={() =>
          <IconButton
            onClick={() => setOpenDialog(true)}
            size="large">
            <GetAppIcon></GetAppIcon>
            <Typography noWrap variant="button">{formatMessage({ id: 'button.export' })}</Typography>
          </IconButton>
        }
        getExpandContent={product =>
          <ProductView
            userRights={userRights}
            product={product}
            productMapping={productMapping}
            customerTypeMapping={customerTypeMapping}
            onEditClick={onEditClick}
            handleEditClick={() => onEditClick(product)}
          />
        }
        tableData={filteredProducts}
      />
    </div>
  );
}

export default ProductList;
