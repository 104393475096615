import React, { useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';

import EnhancedTableEx from 'components/EnhancedTableEx';
import ActionDialog from 'components/ActionDialog';
import ProgressStep from 'components/ProgressStep';
import ContextStore from 'modules/context';
import { callFunction } from 'modules/firebase'

const _headerCells = [
  { field: 'code', sort: 'code' },
  { field: 'name' },
  { field: 'expectedAmount', align: 'right' },
  { field: 'currentAmount', align: 'right' },
  { field: 'adjust', align: 'right' },
  { field: 'note', align: 'right' },
]

const _rowCells = [
  { field: 'code' },
  { field: 'nickname', tooltip: 'name' },
  { field: 'expectedAmount', align: 'right' },
  { field: 'currentAmount', align: 'right' },
  { field: 'adjust', align: 'right' },
  { field: 'note', align: 'right' },
]

function InventoryCheckView({ inventoryCheck, userMapping, merchandiseMapping, editable = false, hideHistory = false }) {
  const { formatMessage } = useIntl()
  const tableRef = useRef()
  const { currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const navigate = useNavigate()
  const [dialogData, setDialogData] = useState(null);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);

  const inventoryCheckHistory = (inventoryCheck.history || []).map(h => ({ ...h }))
  const currentStep = inventoryCheckHistory.length > 0 ? inventoryCheckHistory[inventoryCheckHistory.length - 1].step : 0

  const headerCells = _headerCells.map(c => {c.text = formatMessage({ id: `inventoryCheck.merchandise.${c.field}` });return c})
  const rowCells = _rowCells

  function formatData(merchandise) {
    const sku = merchandiseMapping[merchandise.id].sku
    const expectedAmount = merchandise.expectedAmount
    const currentAmount = merchandise.currentAmount
    const adjust = currentAmount - expectedAmount

    return {
      ...merchandise,
      code: merchandiseMapping[merchandise.id].code,
      name: merchandiseMapping[merchandise.id].name,
      nickname: merchandiseMapping[merchandise.id].nickname,
      // sku: merchandiseMapping[merchandise.id].sku,
      expectedAmount: `${expectedAmount}${sku}`,
      currentAmount: `${currentAmount}${sku}`,
      adjust: adjust === 0 ? '正常' : (adjust > 0 ? `盤盈(${adjust})` : `盤虧(${Math.abs(adjust)})`),
    }
  }

  function edit() {
    navigate(`/stock/inventoryCheck/edit/${inventoryCheck.id}`);
  }

  function submit() {
    setDialogData({ action: 'submit', title: '' })
  }

  function reject() {
    setDialogData({ action: 'reject', title: '' })
  }

  function approve() {
    setDialogData({ action: 'approve', title: '' })
  }

  async function unlock() {
    setLoadingApprove(true)
    try {
      await callFunction('unlockInventoryCheck', { id: inventoryCheck.id })
      setLoadingApprove(false)
    } catch (ex) {
      setLoadingApprove(false)
      setLoadingReject(false)
      console.log(ex)
    }
    // setDialogData({action: 'confirm', title: ''})
  }

  function handleClose() {
    setDialogData(null)
  }

  async function handleExecute(data) {
    const { action, text } = data
    setDialogData(null)
    if (['approve', 'reject', 'confirm', 'submit'].includes(action)) {
      if (['approve', 'confirm', 'submit'].includes(action)) {
        setLoadingApprove(true)
      } else {
        setLoadingReject(true)
      }
      try {
        await callFunction('reviewInventoryCheck', {
          id: inventoryCheck.id,
          action,
          note: text
        })
        if (['approve', 'confirm'].includes(action)) {
          if (currentStep < inventoryCheck.steps.length - 1) {
            setLoadingApprove(false)
          }
        } else if (action === 'reject') {
          setLoadingReject(false)
        }
      } catch (ex) {
        setLoadingApprove(false)
        setLoadingReject(false)
        console.log(ex)
      }
    }
  }

  function decodeModifyLog(log) {
    log = log.replace(/f{modify}/g, formatMessage({ id: 'step.action.modify' }))
    log = log.replace(/f{update}/g, formatMessage({ id: 'step.action.update' }))
    log = log.replace(/f{add}/g, formatMessage({ id: 'step.action.add' }))
    log = log.replace(/f{remove}/g, formatMessage({ id: 'step.action.remove' }))
    log = log.replace(/f{note}/g, formatMessage({ id: 'inventoryCheck.table.detail.note' }))
    log = log.replace(/f{merchandise}/g, formatMessage({ id: 'inventoryCheck.table.detail.merchandise' }))
    log = log.replace(/f{amount}/g, formatMessage({ id: 'inventoryCheck.merchandise.amount' }))

    let matchs = [...new Set((log.match(/i{.+?}/g) || []).map(i => i.substring(2, i.length - 1)))]
    for (const m of matchs) {
      log = log.replace((new RegExp(`i{${m}}`, 'g')), merchandiseMapping[m].name)
    }
    return log
  }

  const ls = inventoryCheck.status !== 'void' ? inventoryCheck.steps.slice(currentStep, inventoryCheck.steps.length).map(s => ({ ...s })) : []
  if (inventoryCheck.status !== 'void' && inventoryCheck.lock && currentStep === 0) {
    ls[0].name = '確認修改內容'
  }
  const steps = [...inventoryCheckHistory].concat(ls)
  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    }
  }

  function allowEditing() {
    if (inventoryCheck.lock) {
      if (currentStep === 1 && inventoryCheck.steps[0].users.includes(currentUser.key)) {
        return true
      }
    } else {
      if (currentStep === 1 && inventoryCheck.steps[0].users.includes(currentUser.key)) {
        return true
      }
    }
    return false
  }

  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMessage(false);
  };

  const handleMessageExited = () => {
    setMessageInfo(undefined);
  };

  function exportForm() {
    tableRef.current.saveToFile(`盤點單-${inventoryCheck.sn}-${inventoryCheck.sourceName}.csv`)
  }

  return (
    <div style={{ flexGrow: 1, height: '100%' }}>
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` }) + (inventoryCheck.void ? formatMessage({ id: 'button.void' }) : '') + formatMessage({ id: 'inventoryCheck.name' })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'inventoryCheck.table.detail.note' })}
        action={dialogData.action}
      />}

      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openMessage}
        autoHideDuration={2000}
        onClose={handleMessageClose}
        TransitionProps={{ onExited: handleMessageExited }}
        message={messageInfo ? messageInfo.message : undefined}
      />

      <Box p={0}>
        {!hideHistory && <ProgressStep activeStep={inventoryCheck.history.length} steps={steps} />}
        <EnhancedTableEx
          ref={tableRef}
          size="small"
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={headerCells}
          rowCells={rowCells}
          tableData={inventoryCheck.merchandises.map(m => formatData(m))}
        />

        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label="備註"
              value={inventoryCheck.note}
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          <Grid item key="buttons" xs={12} sm={12} md={12}>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
              {userRights['inventoryChange-view'] &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => exportForm()}
                >
                  <FormattedMessage id="button.export" />
                </Button>
              }
              {((allowEditing() && inventoryCheck.status === 'processing') || editable) &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={edit}
                  disabled={loadingApprove || loadingReject}
                >
                  <FormattedMessage id="button.edit" />
                </Button>
              }
              {currentStep < inventoryCheck.steps.length && inventoryCheck.steps[currentStep].users.includes(currentUser.key) && inventoryCheck.status === 'processing' &&
                <LoadingButton
                  color="success"
                  onClick={submit}
                  disabled={loadingApprove || loadingReject}
                  loading={loadingApprove}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.submit" />
                </LoadingButton>
              }
              {currentStep > 1 && currentStep < inventoryCheck.steps.length && inventoryCheck.steps[currentStep].users.includes(currentUser.key) && inventoryCheck.status === 'pending' &&
                <LoadingButton
                  color="error"
                  onClick={reject}
                  disabled={loadingApprove || loadingReject}
                  loading={loadingReject}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.reject" />
                </LoadingButton>
              }
              {currentStep > 1 && currentStep < inventoryCheck.steps.length && inventoryCheck.steps[currentStep].users.includes(currentUser.key) && inventoryCheck.status === 'pending' &&
                <LoadingButton
                  color="success"
                  onClick={approve}
                  disabled={loadingApprove || loadingReject}
                  loading={loadingApprove}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.approve" />
                </LoadingButton>
              }
              {userRights.hasUserRightForVendor('inventoryCheck-unlock', inventoryCheck.source) && inventoryCheck.lock && inventoryCheck.status === 'done' &&
                <LoadingButton
                  color="error"
                  onClick={unlock}
                  disabled={loadingApprove || loadingReject}
                  loading={loadingApprove}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.unlock" />
                </LoadingButton>
              }
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

// InventoryCheckView.defaultProps = {

// }

InventoryCheckView.propTypes = {
  inventoryCheck: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  hideHistory: PropTypes.bool,
};

export default InventoryCheckView;
