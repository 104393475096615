import { getVendorUserRight } from 'modules/moduleMgr';
import { rnsData } from 'constants/index';

function addUserRight(obj, name) {
  obj[name] = true
}

function addUserRightForVendor(obj, name, vendor) {
  obj[name] = obj[name] || {}
  obj[name][vendor] = true
}

function loadUserRights({ userId, customerId, warehouseFlow, key, userRights, overWrite, unlock, source = true, salesReturn, sr }) {
  const steps = warehouseFlow[`${key}Steps`] || []

  for (const i in steps) {
    const step = steps[i]
    if (Number(i) === 0) {
      if (step.users.includes(userId)) {
        if (source) {
          addUserRightForVendor(sr, `${key}-create`, customerId)
        }
        addUserRight(userRights, `${key}-view`)
      }
      if (overWrite && step.overwrite.includes(userId)) {
        addUserRight(userRights, `${key}-view`)
        addUserRightForVendor(sr, `${key}-overwrite`, customerId)
      }
      if (unlock && step.unlock.includes(userId)) {
        addUserRight(userRights, `${key}-view`)
        addUserRightForVendor(sr, `${key}-unlock`, customerId)
      }
      if (salesReturn && step.salesReturn.includes(userId)) {
        addUserRight(userRights, 'salesReturnForm-view')
        addUserRightForVendor(sr, 'deliveryOrder-review', customerId)
        addUserRightForVendor(sr, 'salesReturnForm-review', customerId)
      }
    } else {
      if (step.users.includes(userId)) {
        addUserRight(userRights, `${key}-view`)
        addUserRightForVendor(sr, `${key}-review`, customerId)
      }
    }
  }
}

function getReviewRights(users, reviewPolicyMapping, currentUser, userRights) {
  const reviewRights = {}
  for (const i of users) {
    if (i.department === 'doctor' || i.developer) {
      continue
    }
    if (!reviewRights['leave-review']) {
      const leaveRpSteps = reviewPolicyMapping[i.reviewLeavePolicy]?.steps || []
      const leaveRpUsers = [...new Set(leaveRpSteps.reduce((acc, cur) => acc.concat(cur.users), []))]
      if (leaveRpUsers.includes(currentUser.key)) {
        reviewRights['leave-review'] = true
      }
    }

    if (!reviewRights['overtime-review']) {
      const OvertimeRpSteps = reviewPolicyMapping[i.reviewOvertimePolicy]?.steps || []
      const OvertimeRpUsers = [...new Set(OvertimeRpSteps.reduce((acc, cur) => acc.concat(cur.users), []))]
      if (OvertimeRpUsers.includes(currentUser.key)) {
        reviewRights['overtime-review'] = true
      }
    }

    if (!reviewRights['punchClockRevise-review']) {
      const reviewPunchClockRpSteps = reviewPolicyMapping[i.reviewPunchClockPolicy]?.steps || []
      const reviewPunchClockRpUsers = [...new Set(reviewPunchClockRpSteps.reduce((acc, cur) => acc.concat(cur.users), []))]
      if (reviewPunchClockRpUsers.includes(currentUser.key)) {
        reviewRights['punchClockRevise-review'] = true
      }
    }
    if (reviewRights['leave-review'] && reviewRights['overtime-review'] && reviewRights['punchClockRevise-review']) {
      break
    }
  }
  if (reviewRights['leave-review'] || reviewRights['overtime-review'] || reviewRights['punchClockRevise-review']) {
    reviewRights['any-review'] = true
  }

  if (!currentUser.developer && currentUser.department !== 'doctor') {
    reviewRights['leaveOvertime-view'] = true
  }
  if (currentUser.developer) {
    reviewRights['debugging'] = true
  }
  return reviewRights
}

function getAllUserRights({ customers, warehouseFlow, userRights, globalRights, currentUser, users, reviewPolicyMapping, modules }) {
  const reviewRights = getReviewRights(users, reviewPolicyMapping, currentUser, userRights)

  const userId = currentUser.key
  const sr = {}
  const stockQuerySource = {}
  const returnFormSource = {}
  const extUserRights = {}

  for (const customer of customers) { // vendors
    loadUserRights({ userId, customerId: customer.id, warehouseFlow: warehouseFlow[customer.id], key: 'announcement', userRights: reviewRights, overWrite: true, sr })
    loadUserRights({ userId, customerId: customer.id, warehouseFlow: warehouseFlow[customer.id], key: 'requisition', userRights: reviewRights, overWrite: true, sr })
    loadUserRights({ userId, customerId: customer.id, warehouseFlow: warehouseFlow[customer.id], key: 'purchaseOrder', userRights: reviewRights, overWrite: true, sr })
    loadUserRights({ userId, customerId: customer.id, warehouseFlow: warehouseFlow[customer.id], key: 'receipt', userRights: reviewRights, overWrite: true, sr })
    loadUserRights({ userId, customerId: customer.id, warehouseFlow: warehouseFlow[customer.id], key: 'deliveryOrder', userRights: reviewRights, salesReturn: true, source: true, sr })
    loadUserRights({ userId, customerId: customer.id, warehouseFlow: warehouseFlow[customer.id], key: 'stockRequisition', userRights: reviewRights, source: true, sr })
    loadUserRights({ userId, customerId: customer.id, warehouseFlow: warehouseFlow[customer.id], key: 'scrapForm', userRights: reviewRights, source: true, sr })
    loadUserRights({ userId, customerId: customer.id, warehouseFlow: warehouseFlow[customer.id], key: 'inventoryChange', userRights: reviewRights, source: true, sr })
    loadUserRights({ userId, customerId: customer.id, warehouseFlow: warehouseFlow[customer.id], key: 'inventoryCheck', userRights: reviewRights, unlock: true, source: true, sr })

    const whQuery = warehouseFlow[customer.id]?.stock?.query || []
    if (whQuery.includes(userId)) {
      reviewRights['stock-query'] = true
      stockQuerySource[customer.id] = true
    }
    const whReturn = warehouseFlow[customer.id]?.stock?.returnForm || []
    if (whReturn.includes(userId)) {
      reviewRights['returnForm-view'] = true
      reviewRights['returnForm-create'] = true
      returnFormSource[customer.id] = true
    }

    for (const rn of rnsData) {
      const vendorUserRightMapping = getVendorUserRight(modules)
      const actions = vendorUserRightMapping[rn]
      for (const action of actions) {
        const r = warehouseFlow[customer.id]?.[rn]?.[action] || []
        if (r.includes(userId)) {
          addUserRightForVendor(extUserRights, `${rn}-${action}`, customer.id)
        }
      }
    }
  }
  // 只要屬於該公司, 就能看該公司已發佈的公告
  sr['announcement-view'] = {}
  for (const company of currentUser.company) {
    addUserRightForVendor(sr, 'announcement-view', company)
  }

  for (const k of Object.keys(extUserRights)) {
    extUserRights[k] = Object.keys(extUserRights[k])
  }

  if (extUserRights['leaveOvertime-overview']?.length || extUserRights['punchClockRevise-overview']?.length) {
    extUserRights['any-overview'] = true
  }

  for (const k of Object.keys(sr)) {
    sr[k] = Object.keys(sr[k])
  }

  const fullUserRights = {
    ...userRights,
    ...extUserRights,
    ...reviewRights,
    ...globalRights,
    ...sr,
    stockQuerySource: Object.keys(stockQuerySource),
    returnFormSource: Object.keys(returnFormSource),
  }

  // console.log(fullUserRights)
  return fullUserRights
}

// 回傳 true/false
function hasUserRight(name) {
  return this[name] === true || (this[name] || []).length > 0
}

// 回傳 true/false
function hasUserRightForVendor(name, vendor) {
  return (this[name] || []).includes(vendor)
}

// 如果此權限只有1個 vendor, 回傳 vendor id, 否則回傳 ''
function onlyVendor(name) {
  return (this[name] || []).length === 1 ? this[name][0] : ''
}

export {
  getAllUserRights,
  hasUserRight,
  hasUserRightForVendor,
  onlyVendor
}
