import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';

import TabPanel from 'components/TabPanel';

export default function TabContainer({ tabs, onTabSelected, defaultSelect, activeTabIndex, orien = 'horizontal', position = 'sticky' }) {
  const [value, setValue] = useState(defaultSelect);

  useEffect(() => {
    if (activeTabIndex !== undefined) {
      setValue(activeTabIndex)
    }
  }, [activeTabIndex]);

  const handleChange = (event, newValue) => {
    if (onTabSelected) {
      onTabSelected(newValue, tabs[newValue].name)
    }
    setValue(newValue);
  };

  return (
    <>
      <AppBar position={position} sx={{ boxShadow: 'none' }} >
        <Paper square>
          <Tabs
            orientation={orien}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            {tabs.map((tab, idx) => <Tab key={`tab-${idx}`} label={tab.label} />)}
          </Tabs>
        </Paper>
      </AppBar>
      {tabs.map((tab, idx) => <TabPanel key={`tabPanel-${idx}`} value={value} index={idx}>{tab.component}</TabPanel>)}
    </>
  );
}

TabContainer.defaultProps = {
  defaultSelect: 0
}

TabContainer.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.node.isRequired,
    component: PropTypes.element.isRequired,
  })),
  onTabSelected: PropTypes.func,
  defaultSelect: PropTypes.number,
  activeTabIndex: PropTypes.number,
  orien: PropTypes.string,
  position: PropTypes.string
};
