import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { utils as excelUtils, writeFile } from 'xlsx';
import dayjs from 'dayjs';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
// import IconButton from '@mui/material/IconButton';
// import GetAppIcon from '@mui/icons-material/GetApp';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import FabAdd from 'components/FabAdd';
import SalesOrderInfoDiablog from 'components/SalesOrderInfoDiablog';
import ConsumedServiceFormInfoDiablog from 'components/ConsumedServiceFormInfoDiablog';
import EnhancedTable from 'components/EnhancedTable';
// import EnhancedTableEx from 'components/EnhancedTableEx';
import DatePickerField from 'components/DatePickerField';
import ButtonProgress from 'components/ButtonProgress';
import { firestoreListener, callFunction } from 'modules/firebase';

const Spacer = styled('div')(() => ({
  height: '8px',
  width: '100%',
  display: 'block',
}));

const PrintGrid = styled(Grid)(() => ({
  '@media print': {
    padding: '8px'
  },
}));

const NotPrintGrid = styled(Grid)(() => ({
  '@media print': {
    display: 'none'
  },
}));

const PrintTypography = styled(Typography)(() => ({
  display: 'none',
  '@media print': {
    display: 'inline-block'
  },
}));

function BonusDialog({ customers, handleClose }) {
  const { formatMessage } = useIntl()
  const [loadingApprove, setLoadingApprove] = useState(false)
  const [monthTime, setMonthTime] = useState(dayjs().format('YYYY-MM'));
  const [source, setSource] = useState('');

  async function handleSave() {
    setLoadingApprove(true)
    try {
      await callFunction('createBonusList', { monthTime, source })
      setLoadingApprove(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'finance.bonus.dialog.title' })}</DialogTitle>
      <DialogContent>
        <Grid container sx={{ p: 1 }} spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <DatePickerField
              required
              label={formatMessage({ id: 'finance.bonus.dialog.month' })}
              value={dayjs(monthTime)}
              inputFormat="YYYY-MMM"
              onChange={date => setMonthTime(dayjs(date).format('YYYY-MM'))}
              invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              required
              type="text"
              size="small"
              select={true}
              label={formatMessage({ id: 'finance.bonus.dialog.source' })}
              variant="outlined"
              value={source}
              onChange={(e) => {setSource(e.target.value)}}
              fullWidth
            >
              {
                customers.map(c => {
                  return <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                })
              }
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonProgress
          handleClose={handleClose}
          handleClick={() => handleSave()}
          loading={loadingApprove}
          disabledField={!source}
        />
      </DialogActions>
    </Dialog>
  );
}

BonusDialog.propTypes = {
  handleClose: PropTypes.func.isRequired
};

function BonusDetail({ bonus: { sourceName, yearMonth, bonusData }, userMapping, productMapping, customerMapping, sourceMapping }) {
  const { formatMessage } = useIntl()
  const [salesOrderInfo, setSalesOrderInfo] = useState(null);
  const [consumedServiceFormInfo, setConsumedServiceFormInfo] = useState(null);
  const componentRef = useRef();

  const currentData = Object.keys(bonusData).reduce((acc, cur) => {
    acc.push({ id: cur, ...bonusData[cur] })
    return acc
  }, []).sort((a, b) => { // 排序, 避免每次打開時順序都不同.
    if (a.id < b.id) {
      return -1
    } else if (a.id > b.id) {
      return 1
    } else {
      return 0
    }
  })

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const formatSoItemtData = (data, index) => {
    const newData = { ...data }
    newData.id = index
    const p = productMapping[newData.product]
    newData.product = p.nickname ?? p.name ?? ''
    newData.customer = customerMapping[newData.customer].name
    return newData
  }

  const formatCsfItemtData = (data, index) => {
    const newData = { ...data }
    newData.id = index
    const p = productMapping[newData.product]
    newData.product = p.nickname ?? p.name ?? ''
    newData.customer = customerMapping[newData.customer].name
    return newData
  }

  const soHeaderCells = [
    { text: 'salesOrder' },
    { text: 'customer' },
    { text: 'product' },
    { text: 'price' },
    { text: 'bonus', align: 'right' },
    // { text: 'note' },
  ].map(c => { c.text = formatMessage({ id: `finance.bonus.detail.soItem.${c.text}` }); return c })

  const soRowCells = [
    { field: 'salesOrderSn', type: 'info', onButtonClick: showSalesOrder },
    { field: 'customer' },
    { field: 'product' },
    { field: 'price' },
    { field: 'bonus', align: 'right' },
    // { field: 'note' },
  ]

  const csfHeaderCells = [
    { text: 'consumedServiceForm' },
    { text: 'customer' },
    { text: 'product' },
    { text: 'amount' },
    { text: 'bonus', align: 'right' },
    // { text: 'note' },
  ].map(c => { c.text = formatMessage({ id: `finance.bonus.detail.scfItem.${c.text}` }); return c })

  const csfRowCells = [
    { field: 'consumedServiceFormSn', type: 'info', onButtonClick: showConsumedServiceForm },
    { field: 'customer' },
    { field: 'product' },
    { field: 'amount' },
    { field: 'bonus', align: 'right' },
    // { field: 'note' },
  ]

  const handleExport = () => {
    const soHeaderRow = soHeaderCells.map( i => i.text);
    const csfHeaderRow = csfHeaderCells.map( i => i.text);

    const workBook = excelUtils.book_new(); //新增一個excel檔
    for (const u of currentData) {
      const rows = []
      if (u.soItems.length > 0) {
        rows.push(soHeaderRow)
        for (const i of u.soItems) {
          const t = formatSoItemtData(i, 0)
          rows.push(soRowCells.map(j => t[j.field]))
        }
        rows.push(['', '', '', formatMessage({ id: 'finance.bonus.detail.salesBonus' }), u.salesBonus])
      }
      rows.push(['', '', '', '', ''])
      if (u.csfItems.length > 0) {
        rows.push(csfHeaderRow)
        for (const i of u.csfItems) {
          const t = formatCsfItemtData(i, 0)
          rows.push(csfRowCells.map(j => t[j.field]))
        }
        rows.push(['', '', '', formatMessage({ id: 'finance.bonus.detail.operatorBonus' }), u.operatorBonus])
      }
      const workSheet = excelUtils.aoa_to_sheet(rows);
      const name = (userMapping[u.id].displayName ?? '-').replace(/[\\/*?]/g,'-').replaceAll('[','(').replaceAll(']',')')
      excelUtils.book_append_sheet(workBook, workSheet, name); //新增一個分頁
    }

    writeFile(workBook, `${sourceName}-${yearMonth}-獎金.xlsx`);
  };

  function showSalesOrder(key, field, data) {
    setSalesOrderInfo(data.salesOrder)
  }

  function showConsumedServiceForm(key, field, data) {
    setConsumedServiceFormInfo(data.consumedServiceForm)
  }

  return (
    <div>
      {salesOrderInfo && <SalesOrderInfoDiablog
        salesOrderId={salesOrderInfo}
        userMapping={userMapping}
        customerMapping={customerMapping}
        sourceMapping={sourceMapping}
        onClose={() => setSalesOrderInfo(null)}
      />}

      {consumedServiceFormInfo && <ConsumedServiceFormInfoDiablog
        consumedServiceFormId={consumedServiceFormInfo}
        userMapping={userMapping}
        customerMapping={customerMapping}
        sourceMapping={sourceMapping}
        onClose={() => setConsumedServiceFormInfo(null)}
      />}

      <PrintGrid container spacing={1} ref={componentRef}>
        <PrintTypography sx={{ fontWeight: 500 }} component="div" style={{ fontSize: '120%', width: '100%', textAlign: 'center' }}>
          {`${sourceName} ${yearMonth} 獎金列表`}
        </PrintTypography>
        {currentData.map(u =>
          <Grid key={u.id} item xs={12} sm={12} md={12} sx={{ mt: '10px' }}>
            {/* 避免人名和下面的 table 被換頁分開, 所以用一個 div 單獨包起來 */}
            <div style={{ pageBreakInside: 'avoid' }}>
              <Typography sx={{ fontWeight: 500 }} component="div">
                {userMapping[u.id].displayName}
              </Typography>
              {u.soItems.length > 0 && <EnhancedTable
                size="small"
                containerStyle={{ pageBreakInside: 'avoid' }}
                headerCells={soHeaderCells}
                rowCells={soRowCells}
                tableData={u.soItems.map((c, idx) => formatSoItemtData(c, idx))}
                extRows={
                  <>
                    <TableRow>
                      <TableCell rowSpan={1} colSpan={3} />
                      <TableCell align="right">
                        <Typography sx={{ fontWeight: 500 }} component="div">
                          <FormattedMessage id="finance.bonus.detail.salesBonus" />
                        </Typography>
                      </TableCell>
                      <TableCell align="right">{u.salesBonus}</TableCell>
                    </TableRow>
                  </>
                }
              />}
            </div>
            {u.soItems.length > 0 && u.csfItems.length > 0 && <Spacer />}
            {u.csfItems.length > 0 && <EnhancedTable
              size="small"
              containerStyle={{ pageBreakInside: 'avoid' }}
              headerCells={csfHeaderCells}
              rowCells={csfRowCells}
              tableData={u.csfItems.map((c, idx) => formatCsfItemtData(c, idx))}
              extRows={
                <>
                  <TableRow>
                    <TableCell rowSpan={1} colSpan={3} />
                    <TableCell align="right">
                      <Typography variant="subtitle2">
                        <FormattedMessage id="finance.bonus.detail.operatorBonus" />
                      </Typography>
                    </TableCell>
                    <TableCell align="right">{u.operatorBonus}</TableCell>
                  </TableRow>
                </>
              }
            />}
            <Divider sx={{ mt: '8px', mb: '8px' }} />
          </Grid>
        )}
        <NotPrintGrid item key="buttons" xs={12} sm={12} md={12}>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleExport}
            >
              <FormattedMessage id="button.export" />
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrint}
            >
              <FormattedMessage id="button.print" />
            </Button>
          </Stack>
        </NotPrintGrid>
      </PrintGrid>
    </div>
  );
}

BonusDetail.propTypes = {
  bonus: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  productMapping: PropTypes.object.isRequired,
  customerMapping: PropTypes.object.isRequired,
  sourceMapping: PropTypes.object.isRequired,
};

function BonusPage() {
  const { formatMessage } = useIntl()
  const [bonusList, setBonusList] = useState([])
  const [openDailog, setOpenDailog] = useState(false)
  const userMapping = useSelector(state => state.users.data);
  const productMapping = useSelector(state => state.products.data);
  const sourceMapping = useSelector(state => state.internalVendors.data)
  const sources = useSelector(state => state.internalVendors.ordered)

  const [customerMapping, setCustomerMapping] = useState({})

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      onData: (data) => {
        setCustomerMapping(data)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'bonusList',
      onData: (data) => {
        setBonusList(data)
      }
    })

    return () => unsubscribe()
  }, []);

  const formatData = (data) => {
    const newData = { ...data }
    newData.sourceName = sourceMapping[newData.source].name
    newData.createdAt = dayjs(newData.createdAt.seconds * 1000).format('YYYY-MM-DD')
    newData.createdBy = userMapping[newData.createdBy].displayName
    return newData
  }

  const headerCells = [
    { text: 'yearMonth', sort: 'yearMonth' },
    { text: 'source', sort: 'source' },
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'createdAt', sort: 'createdAt' },
  ].map(c => { c.text = formatMessage({ id: `finance.bonus.header.${c.text}` }); return c })

  const rowCells = [
    { field: 'yearMonth' },
    { field: 'sourceName' },
    { field: 'createdBy' },
    { field: 'createdAt' },
  ]

  return (
    <div style={{ marginTop: '20px', paddingBottom: '60px' }}>
      <Grid container spacing={1} sx={{ marginTop: '10px' }}>
        {/* // TODO: 這個 FabAdd 按鈕是不是需要權限 */}
        <FabAdd onClick={() => setOpenDailog(true)} />
        {openDailog && <BonusDialog
          customers={sources}
          handleClose={() => setOpenDailog(false)}
        />}
        <Grid item xs={12} sm={12} md={12} sx={{ mt: '4px' }}>
          <EnhancedTable
            defaultOrder="asc"
            defaultOrderField="date"
            headerCells={headerCells}
            rowCells={rowCells}
            tableData={bonusList.map(s => formatData(s))}
            getExpandContent={bonus => <BonusDetail
              bonus={bonus}
              userMapping={userMapping}
              productMapping={productMapping}
              customerMapping={customerMapping}
              sourceMapping={sourceMapping}
            />}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default BonusPage;
