import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';

import EnhancedButton from 'components/EnhancedButton';
import ActionDialog from 'components/ActionDialog';
import ProgressStep from 'components/ProgressStep';
import ButtonContainer from 'components/ButtonContainer';
import { decodeHistoryLog } from 'modules/historyLog';
import { callFunction } from 'modules/firebase';

function PunchClockReviseListView({ punchClockRevise, isHistory, isAll, type }) {
  const { formatMessage } = useIntl()
  const userMapping = useSelector(state => state.users.data);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const punchClockReviseHistory = (punchClockRevise.history || []).map(h => ({ ...h }))
  const currentStep = punchClockReviseHistory.length > 0 ? punchClockReviseHistory[punchClockReviseHistory.length - 1].step : 0

  function decodeModifyLog(log) {
    const fmMapping = {
      modify: 'step.action.modify',
      update: 'step.action.update',
      add: 'step.action.add',
      remove: 'step.action.remove',

      date: 'punchClockRevise.table.date',
      reason: 'punchClockRevise.table.reason',
      time: 'punchClockRevise.table.time',
    }
    return decodeHistoryLog(log, [
      { key: 'f', replacer: s => formatMessage({ id: fmMapping[s] }) },
      { key: 'i', replacer: s => formatMessage({ id: `overtimeType.${s}` }) },
    ])
  }

  async function handleExecute(data) {
    const { action, text } = data
    setDialogData(null)
    if (['approve'].includes(action)) {
      setLoadingApprove(true)
    } else {
      setLoadingReject(true)
    }

    try {
      await callFunction('reviewPunchClockRevise', {
        id: punchClockRevise.id,
        action,
        note: text
      })

      if (['approve'].includes(action)) {
        if (currentStep < punchClockRevise.steps.length - 1) {
          setLoadingApprove(false)
        }
      } else {
        setLoadingReject(false)
      }
    } catch (ex) {
      setLoadingApprove(false)
      setLoadingReject(false)
      console.log(ex)
    }
  }

  function handleClose() {
    setDialogData(null)
  }

  const ls = punchClockRevise.status !== 'void' ? punchClockRevise.steps.slice(currentStep, punchClockRevise.steps.length).map(s => ({ ...s })) : []
  if (punchClockRevise.status !== 'void' && currentStep === 0) {
    ls[0].name = '編輯 / 作廢'
  }
  const steps = [...punchClockReviseHistory].concat(ls)

  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    } else if (['approve'].includes(step.action)) {
      step.labelProps = { color: 'green', success: true }
    }
  }

  return (
    <div style={{ width: '100%' }}>
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={dialogData.action === 'reject' ? formatMessage({ id: 'punchClockRevise.detail.rejectReason' }) : formatMessage({ id: 'punchClockRevise.detail.note' })}
        action={dialogData.action}
      />}
      <Box p={0}>
        <ProgressStep
          activeStep={punchClockRevise.history.length}
          steps={steps}
          title={formatMessage({ id: 'punchClockRevise.list.step' })}
        />
        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'leaves.leaveForm.reason' })}
              value={punchClockRevise.reason}
              multiline
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          {type === 'review' && !isHistory && !isAll && <Grid item xs={12} sm={12} md={12}>
            <ButtonContainer>
              <EnhancedButton
                color="error"
                onClick={() => setDialogData({ action: 'reject', title: '' })}
                disabled={loadingApprove || loadingReject}
                progress={loadingReject}
                label={formatMessage({ id: 'button.reject' })}
              />
              <EnhancedButton
                color="success"
                onClick={() => setDialogData({ action: 'approve', title: '' })}
                disabled={loadingApprove || loadingReject}
                progress={loadingApprove}
                label={formatMessage({ id: 'button.approve' })}
              />
            </ButtonContainer>
          </Grid>}
        </Grid>
      </Box>
    </div>
  )
}

PunchClockReviseListView.propTypes = {
  isAll: PropTypes.bool,
  isHistory: PropTypes.bool,
  punchClockRevise: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default PunchClockReviseListView;
