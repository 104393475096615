import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingWrap = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '30px',
  margin: '16px',
}));

export default function LoadingIndicator() {
  return <LoadingWrap><FormattedMessage id="loading" /><CircularProgress sx={{ ml: 2 }} size={20} /></LoadingWrap>
};

LoadingIndicator.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string
};
