import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export default function AlertDialog (props) {
  const { dialogMsg, dialogTital, handleClose } = props

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">{dialogTital}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialog.propTypes = {
  dialogMsg: PropTypes.string,
  dialogTital: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};
