const i18n = {
  'returnForm': {
    'name': '退貨單',
    'table': {
      'title': '退貨單列表',
      'selected': '已選擇 {count} 筆退貨單',
      'header': {
        'sn': '退貨單號',
        'source': '退貨單位',
        'sourceName': '退貨單位',
        'supplier': '原供應商',
        'supplierName': '原供應商',
        'createdBy': '退貨人',
        'date': '退貨日期',
      },
      'detail': {
        'sn': '退貨單號',
        'source': '退貨單位',
        'sourceName': '退貨單位',
        'supplier': '原供應商',
        'supplierName': '原供應商',
        'createdBy': '退貨人',
        'date': '退貨日期',
        'note': '備註',
        'merchandise': '商品',
        'merchandiseName': '商品名稱',
        'merchandiseCode': '商品品號',
      },
    },
    'dialog': {
      'title': {
        'add': '新增退貨單',
      }
    },
    'merchandise': {
      'code': '品號',
      'name': '品名',
      'nickname': '簡稱',
      'require': '需求量',
      'unitPrice': '單價',
      'amount': '退貨數量',
      'unit': '單位',
      // 'returnAmount': '退貨數量',
      'orderUnit': '單位',
      'sku': '單位',
      'price': '金額',
      'lotNumber': '批號',
      'date': '入庫日期',
      'expectedDate': '效期',
      'statusText': '狀態',
      'note': '備註',
      'stock': '即時庫存',
      'delivery': ' 出貨量',
    }
  },
  'editReturnForm': {
    'table': {
      'title': '商品清單',
      'selected': '已選擇 {count} 個項目',
    },
    'selectMerchandise': '選擇商品',
    'addLotNumber': '新增批號',
    'removeMerchandise': '移除商品',
    'merchandise': {
      'code': '品號',
      'nickname': '簡稱',
      'name': '品名',
      'orderUnit': '單位',
      'note': '備註',
      'receiptDate': '入庫日期',
      'expectedDate': '效期',
      'lotNumber': '批號',
      'unitPrice': '單價',
      'receiptAmount': '進貨數量',
      'currentAmount': '主倉庫剩餘數量',
      'amount': '欲退貨數量',
    }
  }
}
export default i18n
