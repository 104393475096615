import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import EnhancedTable from 'components/EnhancedTable';
import EnhancedTableHead from 'components/EnhancedTableHead';
import EnhancedTableRow from 'components/EnhancedTableRow';
import ProgressStep from 'components/ProgressStep';

function MerchandiseDetail({ mid, items }) {
  const { formatMessage } = useIntl()
  const headerCells = [
    // { field: 'date' },
    // { field: 'unitPrice' },
    { field: 'amount' },
    { field: 'expectedDate' },
    { field: 'lotNumber' },
  ].map(c => {c.text = formatMessage({ id: `returnForm.merchandise.${c.field}` });return c})

  const rowCells = [
    // { field: 'date' },
    // { field: 'unitPrice' },
    { field: 'amount' },
    { field: 'expectedDate' },
    { field: 'lotNumber' },
  ]

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="collapsible table">
        <EnhancedTableHead
          headerCells={headerCells}
          rowCount={items.length}
        />
        <TableBody>
          {items.map((i, idx) => (
            <EnhancedTableRow
              key={i.id || `${mid}@${idx}`}
              rowCells={rowCells}
              cellData={i}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

MerchandiseDetail.propTypes = {
  mid: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
};

function ReturnFormView({ returnForm, userMapping, merchandiseMapping }) {
  const { formatMessage } = useIntl()

  const returnFormHistory = (returnForm.history || []).map(h => ({ ...h }))
  // const currentStep = returnFormHistory.length > 0 ? returnFormHistory[returnFormHistory.length - 1].step : 0

  const headerCells = [
    { field: 'code', sort: 'code' },
    { field: 'name' },
    { field: 'amount', align: 'right' },
    { field: 'orderUnit', align: 'right' },
    // { field: 'price', align: 'right' },
    { field: 'note', align: 'right' }
  ].map(c => {c.text = formatMessage({ id: `returnForm.merchandise.${c.field}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    { field: 'amount', align: 'right' },
    { field: 'unit', align: 'right' },
    // { field: 'price', align: 'right' },
    { field: 'note', align: 'right', maxWidth: '120px' }
  ]

  // const invoiceSubtotal = returnForm.merchandises.reduce((acc, cur) => {
  //   acc += cur.unitPrice * cur.amount
  //   return acc;
  // }, 0)
  // const totalDiscount = returnForm.merchandises.reduce((acc, cur) => {
  //   acc += (cur.discount || 0)
  //   return acc;
  // }, returnForm.discount || 0)

  // const invoiceTaxes = Math.round(TAX_RATE * (invoiceSubtotal - totalDiscount));
  // const invoiceTotal = invoiceTaxes + invoiceSubtotal - totalDiscount;
  // const invoiceTotal = returnForm.merchandises.reduce((acc, cur) => {
  //   acc += cur.price
  //   return acc;
  // }, 0)

  function formatData(merchandise) {
    const m = merchandiseMapping[merchandise.id]
    return {
      ...merchandise,
      code: m.code,
      name: m.name,
      nickname: m.nickname,
      // price: merchandise.items.reduce((acc, cur) => acc + (cur.unitPrice * cur.amount), 0)
    }
  }

  function decodeModifyLog(log) {
    log = log.replace(/f{modify}/g, formatMessage({ id: 'step.action.modify' }))
    log = log.replace(/f{update}/g, formatMessage({ id: 'step.action.update' }))
    log = log.replace(/f{add}/g, formatMessage({ id: 'step.action.add' }))
    log = log.replace(/f{remove}/g, formatMessage({ id: 'step.action.remove' }))

    let matchs = [...new Set((log.match(/i{.+?}/g) || []).map(i => i.substring(2, i.length - 1)))]
    for (const m of matchs) {
      log = log.replace((new RegExp(`i{${m}}`, 'g')), merchandiseMapping[m].name)
    }
    return log
  }

  const steps = [...returnFormHistory]
  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    }
  }

  return (
    <div style={{ flexGrow: 1, height: '100%' }}>
      <Box p={0}>
        <ProgressStep activeStep={returnForm.history.length} steps={steps} />
        <EnhancedTable
          size="small"
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={headerCells}
          rowCells={rowCells}
          getExpandContent={merchandise =>
            <MerchandiseDetail mid={merchandise.id} items={merchandise.items} />
          }
          tableData={returnForm.merchandises.map(m => formatData(m))}
          // extRows={
          //   <TableRow>
          //     <TableCell colSpan={4}/>
          //     <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.total" /></Typography></TableCell>
          //     <TableCell align="right">{invoiceTotal}</TableCell>
          //   </TableRow>
          // }
        />

        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'returnForm.table.detail.note' })}
              value={returnForm.note}
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

// ReturnFormView.defaultProps = {

// }

ReturnFormView.propTypes = {
  returnForm: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
};

export default ReturnFormView;
