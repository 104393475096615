const i18n = {
  'modules': {
    'setting': '設定',
    'appointment': '預約表',
    'schedule': '班表',
    'user': '職員',
    'leaveOvertime': '請假與加班',
    'finance': '財務',
    'product': '產品',
    'sales': '銷售', // 銷售單, 退費單
    'service': '服務', // 消耗, 轉讓, 轉換
    'customer': '客戶',
    'report': '報表',
    'punchClock': '打卡',
    'vendor': '廠商',
    'merchandise': '商品',
    'purchase': '採購',
  }
}

export default i18n
