import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';

import TabContainer from 'containers/TabContainer/TabContainer';
import FabAdd from 'components/FabAdd';
import ContextStore from 'modules/context';
import ReceiptTab from './ReceiptTab';

function Receipt({ formName }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const navigate = useNavigate()
  const location = useLocation()
  const [activeTabIndex, setActiveTabIndex] = useState(undefined);
  const { tabName } = useParams()

  let newFormLink = ''
  let pageTitle = ''
  let pendingPath = ''
  let donePath = ''
  if (formName === 'receipt') {
    newFormLink = '/purchase/receipt/edit/new'
    pageTitle = formatMessage({ id: 'sideMenu.purchase.receipt' })
    pendingPath = '/purchase/receipt/pending'
    donePath = '/purchase/receipt/done'
  } else if (formName === 'borrowingReceipt') {
    newFormLink = '/borrowing/borrowingReceipt/edit/new'
    pageTitle = formatMessage({ id: 'sideMenu.borrowing.borrowingReceipt' })
    pendingPath = '/borrowing/borrowingReceipt/pending'
    donePath = '/borrowing/borrowingReceipt/done'
  } else if (formName === 'returnBorrowingReceipt') {
    newFormLink = '/borrowing/returnBorrowingReceipt/edit/new'
    pageTitle = formatMessage({ id: 'sideMenu.borrowing.returnBorrowingReceipt' })
    pendingPath = '/borrowing/returnBorrowingReceipt/pending'
    donePath = '/borrowing/returnBorrowingReceipt/done'
  }

  useEffect(() => {
    setBreadcrumbs([{
      text: pageTitle
    }])
    const pathname = location.pathname
    if (pathname === pendingPath && activeTabIndex !== 0) {
      setActiveTabIndex(0)
    } else if (pathname === donePath && activeTabIndex !== 1) {
      setActiveTabIndex(1)
    // } else if (pathname === '/purchase/receipt/void' && activeTabIndex !== 2) {
    //   setActiveTabIndex(2)
    }
    return () => {
    };
  }, [location.pathname]);

  function checkUserRight() {
    // 目前 銷貨單, 借貨出貨單 共用權限
    if (formName === 'receipt') {
      return userRights.hasUserRight('receipt-create')
    } else if (formName === 'borrowingReceipt') {
      return userRights.hasUserRight('borrowingReceipt-create')
    } else if (formName === 'returnBorrowingReceipt') {
      return false
    }
  }

  const onTabSelected = (tabIndex) => {
    if (tabIndex === 0) {
      navigate(pendingPath);
    } else if (tabIndex === 1) {
      navigate(donePath);
    }
  };

  const tabs = [
    { label: formatMessage({ id: 'receipt.tab.pending' }), component: <ReceiptTab formName={formName} extFilter={formName === 'receipt'} /> },
    { label: formatMessage({ id: 'receipt.tab.done' }), component: <ReceiptTab formName={formName} extFilter={formName === 'receipt'} /> },
    // {label: formatMessage({id: 'receipt.tab.void'}), component: <ReceiptTab />},
  ]
  const defaultSelect = tabName === 'void' ? 2 : (tabName === 'done' ? 1 : 0)

  return (
    <Box p={3}>
      {checkUserRight() === true && <FabAdd to={newFormLink} />}
      <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} />
    </Box>
  );
}

Receipt.propTypes = {
  formName: PropTypes.string.isRequired,
};

export default Receipt;
