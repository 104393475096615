const i18n = {
  'login': {
    'form': {
      'email': 'Email 地址',
      'password': '密碼',
      'login': '登入',
      'submit': '送出',
      'resetPassword': '重設密碼',
      'forgotPassword': '忘記密碼？',
      'loginPage': '登入頁面',
      'resetPasswordMessage': '請至信箱收信並重設密碼',
    },
    'error': {
      'email': '帳號錯誤',
      'password': '密碼錯誤'
    }
  }
}

i18n.login.welcome = '允鼎系統管理介面'

export default i18n
