import React from 'react';

import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const Container = styled('div')(() => ({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
}));

const EnhancedSwitch = ({ leftLabel = '', rightLabel = '', ...props }) => (
  <Container>
    {leftLabel}
    <Switch
      color="primary"
      {...props}
    />
    {rightLabel}
  </Container>
)

export default EnhancedSwitch;
