export const moibleMedia = '@media screen and (max-width: 479px)';
export const tabletMedia = '@media screen and (max-width: 1023px)';
export const TAX_RATE = 0.05 // 5%
export const DRAWER_WIDTH = 240;

export const reviewPolicyRules = [
  { id: 'none', text: '無' },
  { id: '2', text: '請假 2 天以上' },
  { id: '3', text: '請假 3 天以上' },
  { id: '4', text: '請假 4 天以上' },
  { id: '5', text: '請假 5 天以上' },
]

export const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const weekdayName = {
  Sun: '週日',
  Mon: '週一',
  Tue: '週二',
  Wed: '週三',
  Thu: '週四',
  Fri: '週五',
  Sat: '週六',
}

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export const monthName = {
  Jan: '一月',
  Feb: '二月',
  Mar: '三月',
  Apr: '四月',
  May: '五月',
  Jun: '六月',
  Jul: '七月',
  Aug: '八月',
  Sep: '九月',
  Oct: '十月',
  Nov: '十一月',
  Dec: '十二月'
}

export const ProductTypes = [
  'merchandise', 'service', 'storedValue', 'pointCard'
]

export const ColorPickerDefaultColors = [
  '#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3',
  '#EB144C', '#F78DA7', '#9900EF'
]

export const CustomerExtDataType = ['text', 'select', 'number', 'select-check', 'date', 'time', 'check', 'dialog', 'phone', 'moblie']
export const CustomerExtDataTypeMapping = {
  'text': '文字',
  'select': '單選',
  'number': '數字',
  'select-check': '複選',
  'date': '日期',
  'time': '時間',
  'dialog': '選單',
  'check': '勾選',
  'phone': '手機',
  'moblie': '電話'
}

export const SelectDialogType = ['customers', 'users']
export const SelectDialogTypeMapping = {
  'customers': '客戶',
  'users': '員工'
}

export const rnsData = [
  'appointment',
  'finance',
  'leaveOvertime',
  'salesOrder',
  'refund',
  'referrer',
  'consumeService',
  'transferService',
  'transformService',
  'punchClockRevise',
  'schedule',
  'report'
]
