import React from 'react';
import PropTypes from 'prop-types';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { reviewPolicyRules } from 'constants';

export default function WorkflowSteps({ steps, small }) {
  const reviewPolicyRuleMapping = reviewPolicyRules.reduce((acc, cur) => {
    acc[cur.id] = cur
    return acc
  }, {})
  return (
    <Stepper activeStep={0} sx={{ p: small ? '0px' : '24px' }} alternativeLabel>
      {steps.map((step, idx) => (
        <Step key={`${step.name}_${idx}`} active={true}>
          <StepLabel sx={{ whiteSpace: 'pre-line' }}>
            {`${step.name}${step.rule === 'none' ? '' : '(' + reviewPolicyRuleMapping[step.rule].text + ')'}\n簽核人: ${step.users.map(s => s.name).join(' / ')}`}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

WorkflowSteps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  })).isRequired,
  small: PropTypes.bool,
};
