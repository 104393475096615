import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { utils as excelUtils, writeFile } from 'xlsx';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import Typography from '@mui/material/Typography';

import FabAdd from 'components/FabAdd';
import EnhancedTableEx from 'components/EnhancedTableEx';
import DateRangePickerField from 'components/DateRangePickerField';
import SearchBox from 'components/SearchBox';
import ActionDialog from 'components/ActionDialog';
import CompanyFilter from 'components/CompanyFilter';
import SimpleTableToolbar from 'components/SimpleTableToolbar';
import ContextStore from 'modules/context';
import { firestoreListener, callFunction } from 'modules/firebase';
import ReferrerView from './ReferrerView';

function ReferrerList() {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { setBreadcrumbs, currentCompany } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const sourceMapping = useSelector(state => state.internalVendors.data)
  const productMapping = useSelector(state => state.products.data)
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [referrers, setReferrers] = useState([])
  const [currentFilter, setCurrentFilter] = useState(null);
  const [openDialog, setOpenDialog] = useState(null);
  const [loading, setLoading] = useState(false);
  const userMapping = useSelector(state => state.users.data)
  const [customerMapping, setCustomerMapping] = useState({});

  const filteredCurrentData = filterCurrentData()

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'referrers',
      where: [
        ['date', '>=', startDate],
        ['date', '<=', endDate],
        ['source', '==', currentCompany]
      ],
      onData: (data) => {
        setReferrers(data)
      }
    })
    return () => unsubscribe()
  }, [startDate, endDate, currentCompany]);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.sales.referrer' })
    }])

    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      onData: (data) => {
        setCustomerMapping(data)
      }
    })
    return () => unsubscribe()
  }, []);

  const filterItems = [
    { name: 'date' },
    { name: 'customer' },
    { name: 'createdBy' },
  ].map(i => { i.text = formatMessage({ id: `referrer.filter.${i.name}` }); return i })

  const headerCells = [
    { text: 'sn' },
    { text: 'date' },
    { text: 'source' },
    { text: 'createdBy' },
    { text: 'salesOrder' },
    { text: 'customer' },
    { text: 'subtotal' },
  ].map(c => { c.text = formatMessage({ id: `referrer.table.header.${c.text}` }); return c })

  const rowCells = [
    { field: 'sn', textColor: true  },
    { field: 'date', textColor: true  },
    { field: 'sourceName', textColor: true  },
    { field: 'createdBy', textColor: true  },
    { field: 'salesOrderSn', textColor: true  },
    { field: 'customerName', textColor: true  },
    { field: 'subtotal', textColor: true  },
  ]

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function filterCurrentData() {
    if (!currentFilter) {
      return referrers
    }

    let items = [...referrers]
    if (currentFilter) {
      const lowerCaseText = currentFilter.text.toLowerCase()
      if (currentFilter.name === 'createdBy') {
        items = items.filter(i => userMapping[i.createdBy]?.displayName.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'date') {
        items = items.filter(i => i.date.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'customer') {
        items = items.filter(i => customerMapping[i.customer].name.toLowerCase().includes(lowerCaseText) || customerMapping[i.customer]?.code.toLowerCase().includes(lowerCaseText))
      }
    }

    return items
  }

  function formatData(data) {
    const newData = { ...data }
    newData.createdBy = userMapping[newData.createdBy].displayName
    newData.customerName = customerMapping[newData.customer] ? customerMapping[newData.customer].name : ''
    newData.sourceName = sourceMapping[newData.source]?.name || ''
    newData.subtotal = Object.keys(data.records).map(k => data.records[k]).reduce((acc, cur) => acc + cur.fee, 0)
    if (newData.void) {
      newData.textColor = '#bab7b7'
    }

    return newData
  }

  function formatDataRecord(record) {
    const newData = { ...record }
    newData.customerName = customerMapping[newData.customer]?.name ?? ''

    if (newData.feeType === 'storedValue') {
      newData.feeType = `轉儲值(${productMapping[newData.storedValueCard].name})`
    } else {
      newData.feeType = formatMessage({ id: 'referrer.records.cash' })
    }
    if (newData.void) {
      newData.fee = `${newData.fee}(已作廢)`
    }
    return newData
  }

  function onEditClick(referrer) {
    navigate({
      pathname: `/sales/referrer/edit/${referrer.id}`,
    });
  }

  async function handleExecute(data) {
    setLoading(true)
    const referrerId = openDialog.id

    try {
      // console.log(JSON.stringify({ id: referrerId, reason: data.text }, null, 4))
      await callFunction('referrerVoid', { id: referrerId, reason: data.text })
    } catch (ex) {
      console.log(ex)
    }
    setLoading(false)
  }

  function exportList() {
    const harderRow = [['轉介費單號', '日期', '公司', '營收單', '客戶', '轉介人', '費用類型', '金額', '備註']]
    const workBook = excelUtils.book_new(); //新增一個excel檔
    const pages = {}
    for (const data of filteredCurrentData) {
      if (data.void) {
        continue
      }
      const f = formatData(data)
      if (!pages[f.source]) {
        pages[f.source] = harderRow
      }
      for (const k of Object.keys(f.records).map(i => f.records[i])) {
        const r = formatDataRecord(k)
        const row = [f.sn, f.date, f.sourceName, f.salesOrderSn, f.customerName, r.customerName, r.feeType, r.fee, r.note]
        pages[f.source].push(row)
      }
    }

    for (const p of Object.keys(pages)) {
      const page = pages[p]
      const workSheet = excelUtils.aoa_to_sheet(page);
      const name = (sourceMapping[p]?.name ?? '未命名').replace(/[\\/*?]/g,'-').replaceAll('[','(').replaceAll(']',')')
      excelUtils.book_append_sheet(workBook, workSheet, name); //新增一個分頁
    }
    writeFile(workBook, `轉介費列表-${startDate}~${endDate}.xlsx`);
  }

  return <div style={{ padding: '20px 24px 80px 24px', width: '100%' }}>
    {loading && <Backdrop open={true} sx={{ zIndex: 2000, color: '#fff' }}>
      <CircularProgress disableShrink color="inherit" />
    </Backdrop>}

    {userRights.hasUserRight('referrer-create') && <FabAdd to="/sales/referrer/edit/new" />}
    {openDialog && <ActionDialog
      title={formatMessage({ id: 'salesOrder.void.title' })}
      handleClose={() => setOpenDialog(null)}
      handleExecute={handleExecute}
      textFieldLabel={formatMessage({ id: 'salesOrder.void.voidReason' })}
      action={'void'}
    />}
    <SimpleTableToolbar
      title='referrer.table.title'
      toolbox={<>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={9}>
            <DateRangePickerField
              startDate={startDate}
              endDate={endDate}
              onStartDateChanged={setStartDate}
              onEndDateChanged={setEndDate}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CompanyFilter userRight='referrer-view'/>
          </Grid>
        </Grid>
        <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} updateUrlParams />
      </>}
    />
    <EnhancedTableEx
      defaultOrder="asc"
      defaultOrderField="code"
      headerCells={headerCells}
      rowCells={rowCells}
      getHeaderActionIcons={() =>
        <IconButton
          onClick={() => exportList()}
          size="large">
          <GetAppIcon></GetAppIcon>
          <Typography noWrap variant="button">{formatMessage({ id: 'button.export' })}</Typography>
        </IconButton>
      }
      getExpandContent={referrer =>
        <ReferrerView
          userRights={userRights}
          referrer={referrer}
          customerMapping={customerMapping}
          onEditClick={onEditClick}
          onVoidClick={(salesOrder) => setOpenDialog(salesOrder)}
          currentCompany={currentCompany}
        />
      }
      tableData={filteredCurrentData.map(i => formatData(i))}
    />
  </div>

}

export default ReferrerList
