const i18n = {
  'merchandise': {
    'table': {
      'title': '商品列表',
      'selected': '已選擇 {count} 項商品',
      'header': {
        'code': '品號',
        'name': '名稱',
        'nickname': '簡稱',
        'price': '售價',
        'sku': '庫存單位(SKU)',
        'stock': '即時庫存',
        'note': '備註',
        'orderUnit': '訂貨單位(OU)',
      },
      'detail': {
        'warehousing': '入庫',
        'type': '分類1',
        'category': '分類2',
        'code': '品號',
        'name': '名稱',
        'nickname': '簡稱',
        'price': '售價',
        'cost': '成本',
        'suppliers': '供應商',
        'customers': '客戶',
        'orderBySku': '以SKU購買',
        'note': '備註',
        'orderUnit': '訂貨單位(OU)',
        'sku': '庫存單位(SKU)',
        'ou2sku': 'OU轉SKU數量',
        'safetyStock': '安全庫存量(SS)',
        'stock': '庫存',
        'suppiler': {
          'code': '編號',
          'name': '簡稱',
          'moq': '最小訂貨量(MOQ)',
          'price': '進貨單價',
        }
      },
    },
    'edit': {
      'warehousing': '入庫',
      'type': '分類1',
      'category': '分類2',
      'code': '品號',
      'name': '名稱',
      'nickname': '簡稱',
      'price': '售價',
      'cost': '成本',
      'suppliers': '供應商',
      'customers': '客戶',
      'orderBySku': '以SKU購買',
      'note': '備註',
      'orderUnit': '訂貨單位(OU)',
      'sku': '庫存單位(SKU)',
      'ou2sku': 'OU轉SKU數量',
      'safetyStock': '安全庫存量(SS)',
    },
    'dialog': {
      'title': {
        'add': '新增商品',
        'edit': '編輯商品',
      }
    },
    'warehousing': {
      'type1': '入庫',
      'type2': '不入庫',
    },
    'category': {
      'all': '全部',
      'M': '藥品',
      'DPO': '口服藥',
      'DOI': '藥膏',
      'DIV': '針劑',
      'DSC': '保養品/保健品',
      'I': '耗材',
      'CIN': '填充注射',
      'CPR': '電音波類(探頭)',
      'CBD': '體雕(點數卡)',
      'CIP': '植入物',
      'CCO': '消耗醫材',
      'CWI': '線材',
      'COT': '其他',
    },
    'type': {
      'g': '非領用',
      'i': '領用',
      'b': '正品+沙貨',
    }
  },
  'editMerchandise': {
    'title': '品號 {code}',
    'table': {
      'title': '供應商',
    },
    'addSupplier': '新增供應商',
    'removeSupplier': '移除供應商',
  },
  'selectMerchandiseDialog': {
    'title': '新增商品',
    'table': {
      'title': '商品列表',
      'detail': {
        'code': '品號',
        'nickname': '簡稱',
        'price': '售價',
        'note': '備註',
        'orderUnit': '訂貨單位',
        'mainWareroomStock': '主倉庫庫存',
        'stock': '庫存',
      },
    },
  },
  'settingMerchandise': {
    'title': '商品設定',
    'category': {
      'title': '商品分類',
      'table': {
        'name': '名稱',
        'status': '狀態'
      }
    }
  }
}
export default i18n
