import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import MuiFab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';

import TabPanel from 'components/TabPanel';
import { getUserRightGroups } from 'modules/moduleMgr';
import ContextStore from 'modules/context';
import { unwrap } from 'modules/uitls';
import { firebaseV8 } from 'modules/firebaseV8';
import { useModules } from 'hooks/modules';

const Fab = styled(MuiFab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}));

function UserRights() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const [globalRights, setGlobalRights] = useState({})
  const [userRightData, setUserRightData] = useState({})
  const [selectedTab, setSelectedTab] = useState(0);
  const userMapping = useSelector(state => state.users.data);
  const modules = useModules()
  const userRightGroups = getUserRightGroups(modules)
  const filteredModules = modules.filter(m => userRightGroups[m].length)

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.setting.root' })
    }])

    const unsubscribe = firebaseV8().collection('userRights').onSnapshot(snapshot => {
      const newMap = {}
      snapshot.forEach(doc => {
        const data = unwrap(doc.data());
        if (doc.id === '*') {
          setGlobalRights(data)
        } else {
          for (const k in data) {
            if (data[k]) {
              if (!newMap[k]) {
                newMap[k] = []
              }
              newMap[k].push(doc.id)
            }
          }
        }
      });
      setUserRightData(newMap)
    }, err => { })

    return () => unsubscribe()
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function createField(field) {
    let name = field.name
    const globalOption = field.name.startsWith('*')
    if (globalOption) {
      name = name.substring(1)
    }

    let newValue = userRightData[name] ?
      (currentUser.developer ? userRightData[name] : userRightData[name].filter(u => !(userMapping[u].developer))).map(user => userMapping[user]?.displayName).join(', ') :
      ''

    return (
      <Grid key={name} item xs={12} sm={12} md={12}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={formatMessage({ id: `userRights.${name}` })}
          value={globalRights[name] ? '全部使用者' : (newValue ? newValue : '未指定使用者')}
          fullWidth
          size="small"
          variant="standard"
        />
      </Grid>
    )
  }

  return (
    <div style={{ padding: '12px 24px 80px 24px' }}>
      {userRights.hasUserRight('userRights-edit') && <Link to="/setting/userRight/edit">
        <Fab color="primary">
          <EditIcon />
        </Fab>
      </Link>}
      <Toolbar sx={{ pl: 2, pr: 1 }}>
        <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="userRights.title" />
        </Typography>
      </Toolbar>
      <Paper square>
        <Tabs
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={handleTabChange}
        >
          {filteredModules.map(module => <Tab key={module} label={formatMessage({ id: `modules.${module}` })} />)}
        </Tabs>
      </Paper>
      <Paper>
        <Box p={3} style={{ marginTop: '20px' }}>
          {filteredModules.map((module, idx) => (
            <TabPanel key={module} value={selectedTab} index={idx}>
              <Grid sx={{ marginBottom: '10px' }} container spacing={2}>
                {userRightGroups[module].map(field => createField({ name: field, multiline: false, md: 3 }))}
              </Grid>
            </TabPanel>
          ))}
        </Box>
      </Paper>
    </div>
  );
}

export default UserRights;
