import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import MuiFab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

const Fab = styled(MuiFab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}));

export default function FabAdd({ to, onClick, icon = 'add' }) {
  if (to) {
    return <Link to={to}>
      <Fab color="primary">
        {icon === 'add' ? <AddIcon /> : <EditIcon />}
      </Fab>
    </Link>
  } else {
    return <Fab color="primary" onClick={onClick}>
      {icon === 'add' ? <AddIcon /> : <EditIcon />}
    </Fab>
  }
};

FabAdd.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string
};
