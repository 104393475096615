import { createReducer } from '@reduxjs/toolkit';

const reducer = createReducer({ ready: false, data: {}, ordered:[] }, (builder) => {
  builder.addCase('SET_SUPPLIERS', (state, action) => {
    const ordered = (action.payload.ordered || []).filter(i => i.supplier)
    const data = ordered.reduce((acc, cur) => {
      acc[cur.id] = cur
      return acc
    }, {})
    return { ready: true, data, ordered };
  });
  builder.addCase('ADD_SUPPLIER', (state, action) => {
    if (action.payload.data.supplier) {
      const _data = action.payload.data
      const item =  { id: _data.id, ..._data }
      const data = { ...state.data, [_data.id]: item }
      const ordered = [ ...state.ordered ]
      ordered.push(item)
      return { ready: true, data, ordered };
    } else {
      return state
    }
  });
  builder.addCase('REMOVE_SUPPLIER', (state, action) => {
    if (action.payload.data.supplier) {
      const _data = action.payload.data
      const data = { ...state.data }
      delete data[_data.id]
      const ordered = [ ...state.ordered ]
      const index = ordered.findIndex(i => i.id === _data.id)
      if (index !== -1) {
        ordered.splice(index, 1)
      }
      return { ready: true, data, ordered };
    } else {
      return state
    }
  });
  builder.addCase('MODIFY_SUPPLIER', (state, action) => {
    const _data = action.payload.data
    const oldItem = state.data[_data.id]
    if (_data.supplier) {
      const item =  { id: _data.id, ..._data }
      const data = { ...state.data, [_data.id]: item }
      const ordered = [ ...state.ordered ]
      if (oldItem) {
        const index = ordered.findIndex(i => i.id === _data.id)
        if (index !== -1) {
          ordered.splice(index, 1, item)
        }
      } else {
        ordered.push(item)
      }
      return { data, ordered };
    } else if (oldItem) {
      const data = { ...state.data }
      delete data[_data.id]
      const ordered = [ ...state.ordered ]
      const index = ordered.findIndex(i => i.id === _data.id)
      if (index !== -1) {
        ordered.splice(index, 1)
      }
      return { ready: true, data, ordered };
    }
    return state
  });

  builder.addCase('RESET_SUPPLIERS', (state, action) => {
    state = { ready: false, data: {}, ordered:[] }
    return state
  });
})

export default reducer;
