import React, { useEffect, useState, useMemo } from 'react';
import ReactDOM from 'react-dom/client';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector, Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';

import Snackbar from '@mui/material/Snackbar';

import LoadingIndicator from 'components/LoadingIndicator';
import LoginForm from 'pages/LoginForm/LoginForm';
import ResetPassword from 'pages/LoginForm/ResetPassword';
import { getCurrentUser } from 'modules/user';
import RootContextStore from 'modules/rootContext';
import { useAuth } from 'hooks/firestore';
import { getAllUserRights } from 'modules/userRights';
import { store, setDataListeners } from 'store';
import { useModules } from 'hooks/modules';

import App from './App';
import i18n from './i18n/i18n'

const cache = createIntlCache()
const intl = createIntl({
  locale: 'zh-TW',
  messages: i18n
}, cache)

function AppWraper({ currentUser, userRights, globalRights }) {
  // 在這邊注入 debugger, userRight-edit ,所有人都有的權限
  const dispatch = useDispatch()
  const users = useSelector(state => state.users?.ordered)
  const reviewPolicyMapping = useSelector(state => state.reviewPolicies?.data)
  const ur = useSelector(state => state.userRights)
  const customers = useSelector(state => state.internalVendors?.ordered)
  const warehouseFlow = useSelector(state => state.warehouseFlow?.data)
  const modules = useModules()

  const fullUserRights = getAllUserRights({
    customers,
    warehouseFlow,
    userRights,
    globalRights,
    currentUser,
    users,
    reviewPolicyMapping,
    modules
  })

  const fullUserRightsKey = JSON.stringify(fullUserRights)

  useEffect(() => {
    dispatch({ type: 'SET_USER_RIGHTS', payload: fullUserRights })
    return () => {}
  }, [fullUserRightsKey]);

  return ur ? <App currentUser={currentUser} userRights={fullUserRights} /> : <LoadingIndicator />
}

function AuthLoaded({ auth }) {

  useEffect(() => {
    setDataListeners(auth.uid)
    return () => {}
  }, []);

  const config = useSelector(state => state.config);
  const userRights = useSelector(state => state.userRawRights);
  const globalRights = useSelector(state => state.globalRights);
  const user = useSelector(state => state.user);
  const users = useSelector(state => state.users);
  const reviewPolicies = useSelector(state => state.reviewPolicies);
  const customers = useSelector(state => state.internalVendors);
  const warehouseFlow = useSelector(state => state.warehouseFlow);

  if (!user || !config?.ready || !userRights || !globalRights || !users?.ready || !reviewPolicies?.ready || !customers?.ready || !warehouseFlow?.ready) {
    return (<div className='loginWithGoogle'>
      <LoadingIndicator />
    </div>)
  }

  const currentUser = getCurrentUser({
    ...user,
    key: auth.uid,
  });

  return (
    <AppWraper currentUser={currentUser} userRights={userRights || {}} globalRights={globalRights || {}}/>
  )
}

// UserIsLoaded.propTypes = {
//   auth: PropTypes.object,
// };

function NoAuth() {
  return (
    <Routes>
      <Route exact path="/resetPassword" element={<ResetPassword />} />
      <Route path="*" element={<LoginForm />} />
    </Routes>
  );
}

function AppRoot() {
  const [snackPack, setSnackPack] = useState([]);
  const [openMessage, setOpenMessage] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  const contextValue = useMemo(
    () => ({
      addMessage: (message) => {
        setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
      }
    }),
    []
  );

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpenMessage(true);
    } else if (snackPack.length && messageInfo && openMessage) {
      // Close an active snack when a new one is added
      setOpenMessage(false);
    }
  }, [snackPack, messageInfo, openMessage]);


  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMessage(false);
  };

  const handleMessageExited = () => {
    setMessageInfo(undefined);
  };

  const user = useAuth()
  if (user === null) {
    return (
      <div className='loginWithGoogle'>
        <LoadingIndicator />
      </div>
    )
  } else {
    return (
      <RootContextStore.Provider value={{ ...contextValue }}>
        <Snackbar
          key={messageInfo ? messageInfo.key : undefined}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={openMessage}
          autoHideDuration={4000}
          onClose={handleMessageClose}
          TransitionProps={{ onExited: handleMessageExited }}
          message={messageInfo ? messageInfo.message : undefined}
          ContentProps={{ sx: { display: 'flex', justifyContent: 'center' } }}
        />
        {user.uid === '' ? <NoAuth /> : <AuthLoaded auth={user} />}
      </RootContextStore.Provider>
    )
  };
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <RawIntlProvider value={intl}>
      <BrowserRouter>
        <AppRoot />
      </BrowserRouter>
    </RawIntlProvider>
  </Provider>
  // </React.StrictMode>
);
