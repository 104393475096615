import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import EnhancedTable from 'components/EnhancedTable';

function TransferServiceView({ form }) {
  const { formatMessage } = useIntl()
  const productMapping = useSelector(state => state.products.data)

  const products = Object.keys(form.products).map(k => ({ ...form.products[k], id: k }))

  const productHeaderCells = [
    { text: 'name' },
    { text: 'amount' },
  ].map(c => { c.text = formatMessage({ id: `transferService.service.${c.text}` }); return c })

  const productRowCells = [
    { field: 'name' },
    { field: 'amount' },
  ]

  function formatData(product) {
    const newData = { ...product }
    newData.name = productMapping[product.productId].name
    return newData
  }

  return (
    <div style={{ width: '100%', }}>
      <Box p={0}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 500 }} component="div">
                服務轉讓列表:
              </Typography>
            </div>

            <EnhancedTable
              size="small"
              headerCells={productHeaderCells}
              rowCells={productRowCells}
              tableData={products.map(i => formatData(i))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'transferService.table.detail.note' })}
              value={form.note}
              multiline
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

TransferServiceView.propTypes = {
  form: PropTypes.object.isRequired,
};

export default TransferServiceView