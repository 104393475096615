import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';

import TabContainer from 'containers/TabContainer/TabContainer';
import ContextStore from 'modules/context';
import SalesReturnFormTab from './SalesReturnFormTab';

function SalesReturnForm() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const [activeTabIndex, setActiveTabIndex] = useState(undefined);
  const { tabName } = useParams()

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.delivery.salesReturnForm' })
    }])

    const pathname = location.pathname
    if (pathname === '/delivery/salesReturnForm/pending' && activeTabIndex !== 0) {
      setActiveTabIndex(0)
    } else if (pathname === '/delivery/salesReturnForm/done' && activeTabIndex !== 1) {
      setActiveTabIndex(1)
    }
    return () => {
    };
  }, [location.pathname]);

  const onTabSelected = (tabIndex) => {
    if (tabIndex === 0) {
      navigate('/delivery/salesReturnForm/pending');
    } else if (tabIndex === 1) {
      navigate('/delivery/salesReturnForm/done');
    }
  };

  const tabs = [
    { label: formatMessage({ id: 'salesReturnForm.tab.pending' }), component: <SalesReturnFormTab /> },
    { label: formatMessage({ id: 'salesReturnForm.tab.done' }), component: <SalesReturnFormTab /> },
  ]
  const defaultSelect = tabName === 'void' ? 2 : (tabName === 'done' ? 1 : 0)

  return (
    <Box p={3}>
      <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} />
    </Box>
  );
}

export default SalesReturnForm;
