const i18n = {
  'consumedService': {
    'name': '消耗單',
    'add': '新增消耗單',
    'edit': '編輯消耗單',
    'table': {
      'title': '消耗單列表',
      'header': {
        'sn': '消耗單號',
        'date': '日期',
        'source': '公司',
        'createdBy': '建立者',
        'agent': '負責人',
        'customer': '客戶',
        'status': '狀態',
        'done': '開立',
        'void': '作廢'
      },
      'detail': {
        'customer': '客戶',
        'createdBy': '建立者',
        'date': '日期',
        'agent': '負責人',
        'note': '備註',
        'status': '狀態',
        'source': '公司'
      }
    },
    'service': {
      'name': '名稱',
      'amount': '數量',
      'availableAmount': '可消耗數量',
      'agent': '操作人員',
      'doctor': '醫師',
      'note': '備註',
    },
    'merchandise': {
      'merchandise': '名稱',
      'name': '名稱',
      'consumeAmount': '消耗數量',
      'sku': '消耗單位(SKU)'
    },
    'filter': {
      'sn': '消耗單號',
      'customer': '客戶',
      'createdBy': '建立者',
      'agent': '負責人',
      'doctor': '醫師',
      'service': '服務',
      'note': '消耗單備註',
      'status': '消耗單狀態',
      'all': '全部',
      'done': '已開立',
      'void': '已作廢'
    },
    'void': {
      'title': '消耗單作廢',
      'voidReason': '作廢原因'
    }
  },
  'editConsumedServicePage': {
    'addService': '新增服務',
    'removeService': '移除服務',
    'addMerchandise': '新增消耗商品',
    'removeMerchandise': '刪除消耗商品',
    'createSalesOrder': '建立營收單',
    'consumed': '已消耗',
    'fixed': '固定消耗',
    'table': {
      'title': '服務列表',
      'merchandiseTitle': '消耗商品列表'
    }
  },
}
export default i18n
