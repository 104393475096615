import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import SelectItemDialog from 'components/SelectItemDialog';
import { firestoreListener } from 'modules/firebase';
import SalesOrderView from './SalesOrderView';

function SelectSalesOrderDialog({ headerCells, rowCells, filterItems, ignoreVoid, handleSave, filterSetting = {}, source, ...props }) {
  const { formatMessage } = useIntl()
  const [customerMapping, setCustomerMapping] = useState({});
  const sourceMapping = useSelector(state => state.internalVendors.data)
  const userMapping = useSelector(state => state.users.data)
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [items, setItems] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  const _headerCells = headerCells.map(c => { c.text = formatMessage({ id: `salesOrder.table.header.${c.field}` }); return c })
  const _filterItems = filterItems.map(i => { i.text = formatMessage({ id: `salesOrder.table.header.${i.name}` }); return i })

  const categoryFilterItems = [
    { name: 'status', type: 'select', default: 'all', size: 3, items: getMenuItem() },
  ].map(i => { i.text = formatMessage({ id: `salesOrder.filter.${i.name}` }); return i })

  categoryFilterItems.forEach(i => {
    if (filterSetting[i.name]) {
      i.default = filterSetting[i.name].default
      i.hidden = filterSetting[i.name].hidden
    }
  })

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'salesOrders',
      where: [
        ['date', '>=', startDate],
        ['date', '<=', endDate],
        ['source', '==', source]
      ],
      onData: (data) => {
        for (const salesOrder of data) {
          const products = Object.keys(salesOrder.products).map(k => salesOrder.products[k])
          const total = products.reduce((acc, cur) => acc + (cur.unitPrice * cur.amount), 0)
          const collected = Object.keys(salesOrder.payments || {}).map(k => salesOrder.payments[k]).filter(p => !p.void).map(p => p.collected)
            .reduce((acc, cur) => acc + cur, 0)
          if (total === collected) {
            salesOrder.status = 'fullPayment'
          } else if (collected === 0) {
            salesOrder.status = 'noPayment'
          } else {
            salesOrder.status = 'partialPayment'
          }

          if (salesOrder.void) {
            salesOrder.status = 'void'
          }
          salesOrder.uncollected = total - collected
          salesOrder.collected = collected
        }
        setItems(data.filter(i => !i.referrer))
      }
    })

    return () => unsubscribe()
  }, [startDate, endDate, source]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      onData: (data) => {
        setCustomerMapping(data)
      }
    })
    return () => unsubscribe()
  }, []);

  function getMenuItem() {
    return [
      { value: 'all' },
      { value: 'noPayment' },
      { value: 'partialPayment' },
      { value: 'fullPayment' },
    ].map(i => {
      i.label = i.value ? formatMessage({ id: `salesOrder.status.${i.value}` }) : formatMessage({ id: 'salesOrder.status.all' })
      return i
    })
  }

  function onTimeRangeChanged({ startDate: newStartDate, endDate: newEndDate }) {
    if (newStartDate !== startDate) {
      setStartDate(newStartDate)
    }
    if (newEndDate !== endDate) {
      setEndDate(newEndDate)
    }
  }

  function applyFilter(currentFilter, categoryFilter) {
    let newItems = [...items]
    if (ignoreVoid) {
      newItems = newItems.filter(i => !i.void)
    }
    if (categoryFilter.status !== 'all') {
      newItems = items.filter(i => i.status === categoryFilter.status)
    }
    if (currentFilter) {
      newItems = newItems.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
    return newItems.map(i => formatData(i))
  }

  function formatData(salesOrder) {
    const newData = { ...salesOrder }

    newData.statusText = formatMessage({ id: `salesOrder.status.${newData.status}` })
    newData.sourceName = sourceMapping[newData.source]?.name || ''
    newData.customerName = customerMapping[newData.customer]?.name || ''
    newData.agentName = userMapping[newData.agent]?.displayName || ''

    // if (newData.void) {
    //   newData.textColor = '#bab7b7'
    // }
    return newData
  }

  function onSave(ids) {
    if (ids.length > 0) {
      const id = ids[0];
      const item = items.find(i => i.id === id)
      handleSave(item)
    }
  }

  return (
    <SelectItemDialog
      headerCells={_headerCells}
      rowCells={rowCells}
      tableTitle={formatMessage({ id: 'salesOrder.table.title' })}
      filterItems={_filterItems}
      categoryFilterItems={categoryFilterItems}
      defaultSelectedItems={selectedItems}
      applyFilter={applyFilter}
      handleSave={onSave}
      getExpandContent={salesOrder => <SalesOrderView
        userRights={{}}
        salesOrder={salesOrder}
      />}
      onRadioButtonClick={(i) => setSelectedItems([i.id])}
      maxWidth="lg"
      onTimeRangeChanged={onTimeRangeChanged}
      {...props}
    />
  );
}

SelectSalesOrderDialog.propTypes = {
  filterSetting: PropTypes.object,
  handleSave: PropTypes.func.isRequired,
  headerCells: PropTypes.arrayOf(PropTypes.object.isRequired),
  rowCells: PropTypes.arrayOf(PropTypes.object.isRequired),
  filterItems: PropTypes.arrayOf(PropTypes.object.isRequired),
};

export default SelectSalesOrderDialog;
