import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { arrayMove } from '@dnd-kit/sortable';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import ColorPicker from '../../components/ColorPicker';
import Popover from '@mui/material/Popover';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';

import DndContainer from 'components/DndContainer';
import DragHandle from 'components/DragHandle';
import ColorDot from 'components/ColorDot';
import SelectTagDialog from 'components/SelectTagDialog';
import { ColorPickerDefaultColors as defaultColors } from 'constants';
import ContextStore from 'modules/context';
import { callFunction } from 'modules/firebase';
import { getRandomHash } from 'modules/uitls';

const newItemMapping = {
  treatments: { name: '新服務', duration: 30, color: '' },
  appointmentType: { name: '新科別' },
  rooms: { name: '新診間', note: '' },
  paymentType: { name: '新付款方式' },
  departments: { name: '新部門', users: [] },
  occupation: { name: '新職稱', users: [] },
  category:  { name: '新分類', note: '' },
  reportCategory:  { name: '新分類', tags: [] },
}

function getFields(config, tableName) {

  return {
    treatments: [
      { name: 'name', type: 'text', xs: 3, required: true },
      { name: 'duration', type: 'number', xs: 3, required: true },
      { name: 'color', type: 'text-color', xs: 3, required: true }
    ],
    appointmentType: [
      { name: 'name', type: 'text', xs: 3, required: true },
      { name: 'users', type: 'text-user', xs: 6, required: true }
    ],
    rooms: [
      { name: 'name', type: 'text', xs: 3, required: true },
      { name: 'note', type: 'text', xs: 6 }
    ],
    paymentType: [
      { name: 'name', type: 'text', xs: 3, required: true },
      { name: 'space', type: 'space', xs: 6, required: true },
    ],
    departments: [
      { name: 'name', type: 'text', xs: 3, required: true },
      { name: 'users', type: 'text-user', xs: 6, required: true }
    ],
    occupation: [
      { name: 'name', type: 'text', xs: 3, required: true },
      { name: 'users', type: 'text-user', xs: 6, required: true }
    ],
    category: [
      { name: 'name', type: 'text', xs: 3, required: true },
      { name: 'note', type: 'text', xs: 6 }
    ],
    reportCategory: [
      { name: 'name', type: 'text', xs: 3, required: true },
      { name: 'tags', type: 'popup', sm: 6 },
    ]
  }[tableName]
}

const rowStyle = {
  borderTop: 'solid 1px #efefef',
  width: '100%',
  height: '80px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#fff',
  padding: '0px 16px 0px 8px',
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
  zIndex: 10000,
}

const cellStyle = {
  marginLeft: '8px',
  marginRight: '8px',
  flexGrow: 2
}

function SortableItem({ item, index, onChange, fields }) {
  const { formatMessage } = useIntl()
  const [colorPicker, serColorPicker] = useState({})
  const { tableName } = useParams()
  const [openDialog, setOpenDialog] = useState('');
  const numberRule = /[^0-9]/g

  function onTagChanged(tags) {
    onChange(index, { name: 'tags' }, tags)
  }

  function createField(field) {
    if (field.type === 'text') {
      return (
        <Grid item key={field.name} xs={field.xs}>
          <TextField
            required={field.required}
            type="text"
            size="small"
            label={formatMessage({ id: `editDataType.${tableName}.table.detail.${field.name}` })}
            variant="outlined"
            value={item[field.name]}
            onChange={e => onChange(index, { name: field.name, required: field.required }, e.target.value)}
            error={item[`${[field.name]}_err`] ? true : false}
            helperText={item[`${[field.name]}_err`]}
            fullWidth
          />
        </Grid>
      );
    } else if (field.type === 'text-color') {
      return (
        <Grid item key={field.name} xs={field.xs}>
          <Popover
            open={!!colorPicker.anchorEl}
            anchorEl={colorPicker.anchorEl}
            onClose={() => serColorPicker({})}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <ColorPicker
              colors={defaultColors}
              value={item[field.name]}
              onChangeComplete={(color) => {
                onChange(index, { name: field.name, required: field.required }, color)
              }}
            />
          </Popover>
          <TextField
            required={field.required}
            type="text"
            size="small"
            label={formatMessage({ id: `editDataType.${tableName}.table.detail.${field.name}` })}
            variant="outlined"
            onClick={(e) => serColorPicker({ index, anchorEl: e.currentTarget })}
            value={item[field.name]}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ColorDot style={{ backgroundColor: item[field.name] }}></ColorDot>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>
      );
    } else if (field.type === 'number') {
      return (
        <Grid item key={field.name} xs={field.xs}>
          <TextField
            required={field.required}
            type="text"
            size="small"
            label={formatMessage({ id: `editDataType.${tableName}.table.detail.${field.name}` })}
            variant="outlined"
            value={item[field.name]}
            onChange={e => onChange(index, { name: field.name, required: field.required, allowCharacter: numberRule }, e.target.value)}
            error={item[`${[field.name]}_err`] ? true : false}
            helperText={item[`${[field.name]}_err`]}
            fullWidth
          />
        </Grid>
      );
    } else if (field.type === 'text-user') {
      return (
        <Grid item key={field.name} xs={field.xs}>
          <TextField
            type="text"
            size="small"
            label={formatMessage({ id: `editDataType.${tableName}.table.detail.${field.name}` })}
            variant="standard"
            disabled
            value={item[field.name]}
            fullWidth
          />
        </Grid>
      );
    } else if (field.type === 'text-select') {
      return (
        <Grid item key={field.name} xs={field.xs}>
          <TextField
            select
            type="text"
            size="small"
            label={formatMessage({ id: `editDataType.${tableName}.table.detail.${field.name}` })}
            variant="outlined"
            fullWidth
            onChange={e => onChange(index, { name: field.name, required: field.required }, e.target.value)}
            value={item[field.name]}
          >
            {field.selectFields.map(select => {
              return <MenuItem key={select.id} disabled={select.disabled} value={select.id}>{select.name}</MenuItem>
            })}
          </TextField>
        </Grid>
      );
    } else if (field.type === 'space') {
      return <Grid item key={field.name} xs={field.xs}></Grid>
    } else if (field.type === 'popup') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          disabled={field.disabled}
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `editDataType.${tableName}.table.detail.${field.name}` })}
          variant="outlined"
          onClick={() => setOpenDialog(field.name)} //open popup
          value={item[field.name].map(s => field.name === 'tags' ? s : s.name).join(', ')}
          error={item[`${field.name}_err`] ? true : false}
          helperText={item[`${field.name}_err`]}
          fullWidth
        />
      </Grid>
    }
  }

  return (
    <div style={rowStyle}>
      <DragHandle />
      <div style={cellStyle}>
        <Grid container spacing={3}>
          {openDialog === 'tags' && <SelectTagDialog
            defaultSelectedItems={item?.tags ?? []}
            handleClose={() => setOpenDialog('')}
            handleSave={onTagChanged}
          />}
          {fields.map(field => createField(field))}
          <Grid item xs={3}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
              <div>停用</div>
              <Switch
                checked={item.active}
                onChange={e => onChange(index, { name: 'active' }, e.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <div>啟用</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

SortableItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired
};

function EditDataType() {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { tableName, sideName } = useParams()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const location = useLocation()
  const pathname = location.pathname
  const config = useSelector(state => state.config.data)
  const [currentData, setCurrentData] = useState(Object.keys(config[tableName] || {}).map(i => ({ ...config[tableName][i] })))
  const [loading, setLoading] = useState(false)
  const users = useSelector(state => state.users.ordered).filter(u => u.active && (currentUser.developer || !u.developer))

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.setting.root' })
    }])
  }, [pathname]);

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = currentData.findIndex(e => e.id === active.id);
      const newIndex = currentData.findIndex(e => e.id === over.id);
      setCurrentData(arrayMove(currentData, oldIndex, newIndex))
    }
  }

  const fields = getFields(config, tableName)

  function updateCurrentData(index, field, value) {
    let newValue = value
    const data = fields.reduce((acc, cur) => {
      acc[cur.name] = currentData[index][cur.name]
      return acc
    }, { active: currentData[index].active, id: currentData[index].id, })

    if (field.allowCharacter) {
      newValue = Number(newValue.replace(field.allowCharacter, ''))
    }

    if (field.required && value.trim() === '') {
      data[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
    }

    data[field.name] = newValue
    const newData = [...currentData]
    newData[index] = data

    setCurrentData(newData)
  }

  function addItems() {
    let newItem = {
      id: getRandomHash(),
      active: true,
      ...newItemMapping[tableName]
    }
    setCurrentData([...currentData, newItem])
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }
    return ''
  }

  async function handleSave() {
    setLoading(true)

    let err = false

    for (const data of currentData) {
      for (let field of fields) {
        const errMsg = validateField(field, data[field.name])
        if (errMsg) {
          data[`${field.name}_err`] = errMsg
          err = true
        }
      }
    }

    if (err) {
      setCurrentData([...currentData])
      setLoading(false)
      return
    }

    let newData = []
    for (const data of currentData) {
      if (data.users) {
        delete data.users
      }
      if (data.space) {
        delete data.space
      }
      newData.push(data)
    }

    try {
      await callFunction('saveConfigurations', { type: tableName, ...newData })
    } catch (ex) {
      console.log(ex)
    }
    handleClose()
  }

  function handleClose() {
    navigate(`/setting/${sideName}/${tableName}`);
  }

  const formatData = (data) => {
    const newData = { ...data }

    if (['appointmentType', 'occupation'].includes(tableName)) {
      newData.users = users.filter(u => u[tableName] === data.id).map(u => u.displayName).join(', ')
    }

    if (tableName === 'departments') {
      newData.users = users.filter(u => u.department === data.id).map(u => u.displayName).join(', ')
    }

    return newData
  }

  function getToolbox() {
    return (
      <div>
        <Button
          sx={{ m: 1, whiteSpace: 'nowrap' }}
          variant="contained"
          color="primary"
          onClick={() => addItems()}
        >
          <FormattedMessage id={`editDataType.${tableName}.add`} />
        </Button>
      </div>
    )
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography sx={{ flex: '1 1 20%' }} variant="h6" id="tableTitle" component="div">
            <FormattedMessage id={`editDataType.${tableName}.table.title`} />
          </Typography>
          {getToolbox()}
        </div>
        <Paper sx={{ overflow: 'hidden' }}>
          <DndContainer
            dargger
            items={currentData.map(i => formatData(i))}
            handleDragEnd={handleDragEnd}
            ChildItem={SortableItem}
            onChange={updateCurrentData}
            fields={fields}
          />
        </Paper>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={loading}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.save" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}

export default EditDataType;
