import React, { useRef } from 'react';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';

function FilePicker({ label, value, onRemoveFile, onRemoveAllFiles, onAddFiles }) {
  const filepickerRef = useRef()

  function onClick() {
    filepickerRef.current.click()
  }

  function onDelete(index) {
    onRemoveFile(index)
  }

  function onChange(e) {
    onAddFiles(e.target.files)
    e.target.value = ''
  }

  function onDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    if(e.dataTransfer && e.dataTransfer.files.length !== 0){
      onAddFiles(e.dataTransfer.files)
    }
  }

  function removeAllFile(e) {
    e.preventDefault()
    e.stopPropagation()
    onRemoveAllFiles()
  }

  return <>
    <input
      ref={filepickerRef}
      style={{ width: '0px', height: '0px', opacity: 0, position: 'absolute' }}
      type="file"
      multiple
      onChange={onChange}
    ></input>
    <Autocomplete
      multiple
      value={value}
      options={[]}
      defaultValue={[]}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          // console.log(option)
          const { key, onDelete: _onDelete, ...tagProps } = getTagProps({ index });
          return (
            <Chip variant="outlined" size="small" label={option.fileName} key={key} onDelete={() => onDelete(index)} {...tagProps} />
          );
        })
      }
      renderInput={(params) => {
        params.InputProps.onClick = onClick
        params.InputProps.onDrop = onDrop
        delete params.inputProps.onChange
        // 不知道怎麼改成只有 hover 和 focus 後才顯示
        params.InputProps.endAdornment = (<InputAdornment classes={{ root: '' }} position="end">
          <IconButton
            style={{ position: 'absolute' }}
            size="small"
            onClick={removeAllFile}
            edge="end"
          >
            <ClearIcon />
          </IconButton>
        </InputAdornment>)
        return <TextField
          {...params}
          size="small"
          variant="outlined"
          label={label}
          value={''}
        />
      }}
    />
  </>
}

/*
@media (pointer: fine) {
    .css-1h51icj-MuiAutocomplete-root:hover .MuiAutocomplete-clearIndicator {
        visibility: visible;
    }
}
*/

export default FilePicker;