import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';

import TabContainer from 'containers/TabContainer/TabContainer';
import FabAdd from 'components/FabAdd';
import ContextStore from 'modules/context';
import InventoryCheckTab from './InventoryCheckTab';

const paths = [
  '/stock/inventoryCheck/processing',
  '/stock/inventoryCheck/pending',
  '/stock/inventoryCheck/done',
  // '/stock/inventoryCheck/void'
]

function InventoryCheck() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const navigate = useNavigate()
  const location = useLocation()
  const [activeTabIndex, setActiveTabIndex] = useState(undefined);
  const { tabName } = useParams()

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.stock.inventoryCheck' })
    }])
    const pathname = location.pathname

    for (const i in paths) {
      if (pathname === paths[i] && activeTabIndex !== i) {
        setActiveTabIndex(parseInt(i))
        break
      }
    }
    return () => {
    };
  }, [location.pathname]);

  const onTabSelected = (tabIndex) => {
    for (const i in paths) {
      if (tabIndex === parseInt(i)) {
        navigate(paths[i]);
        break
      }
    }
  };

  const tabs = [
    { label: formatMessage({ id: 'inventoryCheck.tab.processing' }), component: <InventoryCheckTab /> },
    { label: formatMessage({ id: 'inventoryCheck.tab.pending' }), component: <InventoryCheckTab /> },
    { label: formatMessage({ id: 'inventoryCheck.tab.done' }), component: <InventoryCheckTab /> },
    // {label: formatMessage({id: 'inventoryCheck.tab.void'}), component: <InventoryCheckList />},
  ]
  // const defaultSelect = tabName === 'void' ? 2 : (tabName === 'done' ? 1 : 0)
  const defaultSelect = tabName === 'done' ? 2 : (tabName === 'pending' ? 1 : 0)

  return (
    <Box p={3}>
      {userRights.hasUserRight('inventoryCheck-create') && <FabAdd to="/stock/inventoryCheck/edit/new" />}
      <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} />
    </Box>
  );
}

export default InventoryCheck;
