const i18n = {
  'tab': {
    'secondaryNav': {
      'leave': '我的休假',
      'overtime': '我的加班',
      'punchClockRevise': '忘刷卡申請',
      'reviewLeave': '簽核休假申請',
      'reviewOvertime': '簽核加班申請',
      'reviewPunchClockRevise': '簽核忘刷卡申請',
      'allLeave': '全部休假申請',
      'allOvertime': '全部加班申請',
      'allPunchClockRevise': '全部忘刷卡申請',
    },
  }
}

export default i18n