const i18n = {
  'punchClockException': {
    'table': {
      'date': '日期',
      'time': '時間',
      'type': '類型',
      'source': '公司',
      'reason': '原因',
      'department': '部門'
    },
    'dialog': {
      'normalHoliday': '一般假日',
      'publicHoliday': '例假日',
      'deleteTitle': '刪除',
      'deleteMsg': '確認刪除 {date} 補班/休假?',
      'title': '例外排程',
      'date': '日期',
      'startDate': '開始日期',
      'endDate': '結束日期',
      'startTime': '開始時間',
      'source': '公司',
      'endTime': '結束時間',
      'type': {
        'roots': '類型',
        'on': '補班',
        'off': '補假'
      },
      'reason': '原因',
      'department': '部門',
      'all': '全部',
    }
  }
}

export default i18n