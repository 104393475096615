import React from 'react'
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { blue } from '@mui/material/colors';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

import iconPaper from 'static/il-sent.svg';

const Root = styled('div')(({ theme }) => ({
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '50px'
}));

const BlueButton = styled(Button)(({ theme }) => ({
  backgroundColor: blue[500],
  '&:hover': {
    backgroundColor: blue[700],
    '@media (hover: none)': {
      backgroundColor: blue[500]
    }
  },
  color: '#fff'
}));

export default function ApplicationSent({ title, message, buttonText, buttonClick, icon = true }) {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  return (
    <Root>
      {icon ? <img alt='' src={iconPaper} style={{ display: 'inline-block', width: '88px', height: '74px', }} /> :
        <SentimentSatisfiedAltIcon sx={{ width: '88px', height: '74px' }} />
      }
      <h1>{formatMessage({ id: title })}</h1>
      <p>{formatMessage({ id: message })}</p>
      <BlueButton
        variant="contained"
        color="primary"
        onClick={() => navigate(buttonClick)}
      >
        {formatMessage({ id: buttonText })}
      </BlueButton>
    </Root>
  )
};

ApplicationSent.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonClick: PropTypes.string.isRequired,
  limit: PropTypes.bool
};
