const i18n = {
  'setStaff': {
    'title': '職員',
    'departments': {
      'title': '部門',
      'table': {
        'header': {
          'name': '名稱',
          'users': '成員',
          'status': '狀態'
        },
        'detail': {
          'name': '名稱',
        }
      },
    },
    'occupation': {
      'title': '職稱',
      'table': {
        'header': {
          'name': '名稱',
          'users': '成員',
          'status': '狀態'
        },
      },
    },
    'appointmentType': {
      'table': {
        'title': '醫師科別',
        'header': {
          'name': '名稱',
          'users': '醫生',
          'status': '狀態'
        },
      },
    },
  }
}
export default i18n
