import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';

import FabAdd from 'components/FabAdd';
import EnhancedTable from 'components/EnhancedTable';
import DateRangePickerField from 'components/DateRangePickerField';
import SearchBox from 'components/SearchBox';
import ContextStore from 'modules/context';
import { firebaseV8 } from 'modules/firebaseV8';
import TransformServiceView from './TransformServiceView';
import CompanyFilter from 'components/CompanyFilter';
import SimpleTableToolbar from 'components/SimpleTableToolbar';

function TransformServiceList() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentCompany } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [transformServices, setTransformServices] = useState([])
  const [currentFilter, setCurrentFilter] = useState(null);
  const userMapping = useSelector(state => state.users.data)
  const [customerMapping, setCustomerMapping] = useState({});
  const productMapping = useSelector(state => state.products.data)

  const filteredCurrentData = filterCurrentData()

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('transformServices').where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(snapshot => {
      const newData = []
      snapshot.forEach(doc => {
        const data = { ...doc.data(), id: doc.id }
        newData.push(data)
      });

      setTransformServices(newData.filter(t => t.source === currentCompany))
    }, err => { })
    return () => unsubscribe()
  }, [startDate, endDate, currentCompany]);

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('customers').onSnapshot(snapshot => {
      const customerMapping = {}
      snapshot.forEach(doc => {
        const data = { ...doc.data(), id: doc.id }
        customerMapping[doc.id] = data
      });
      setCustomerMapping(customerMapping)
    }, err => { })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.services.transform' })
    }])

  }, []);

  const filterItems = [
    { name: 'sn' },
    { name: 'date' },
    { name: 'createdBy' },
    { name: 'customer' },
  ].map(i => { i.text = formatMessage({ id: `transformService.filter.${i.name}` }); return i })

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'date', sort: 'date' },
    { text: 'createdBy' },
    { text: 'customerName' },
    { text: 'transformType' },
  ].map(c => { c.text = formatMessage({ id: `transformService.table.header.${c.text}` }); return c })

  const rowCells = [
    { field: 'sn' },
    { field: 'date' },
    { field: 'createdBy' },
    { field: 'customerName' },
    { field: 'transformType' },
  ]

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function filterCurrentData() {
    if (!currentFilter) {
      return transformServices
    }

    let items = [...transformServices]
    if (currentFilter) {
      const lowerCaseText = currentFilter.text.toLowerCase()
      if (currentFilter.name === 'sn') {
        items = items.filter(i => i.sn.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'createdBy') {
        items = items.filter(i => userMapping[i.createdBy]?.displayName.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'date') {
        items = items.filter(i => i.date.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'customer') {
        items = items.filter(i => customerMapping[i.customer]?.name.toLowerCase().includes(lowerCaseText) || customerMapping[i.customer]?.code.toLowerCase().includes(lowerCaseText))
      }
    }

    return items
  }

  function formatData(data) {
    const newData = { ...data }
    newData.createdBy = userMapping[newData.createdBy].displayName
    newData.customerName = customerMapping[newData.customer] ? customerMapping[newData.customer].name : ''
    if (productMapping[newData.product]) {
      newData.productName = productMapping[newData.product].name
      newData.transformType = productMapping[newData.product].type === 'service' ? '服務轉換' : '點數轉換'
    }

    return newData
  }

  return <div style={{ padding: '20px 24px 80px 24px' }}>
    {userRights.hasUserRight('transformService-create') && <FabAdd to="/services/transform/edit/new" />}
    <SimpleTableToolbar
      title='transformService.table.title'
      toolbox={<>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={9}>
            <DateRangePickerField
              startDate={startDate}
              endDate={endDate}
              onStartDateChanged={setStartDate}
              onEndDateChanged={setEndDate}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CompanyFilter userRight='transformService-view'/>

          </Grid>

        </Grid>
        <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} updateUrlParams />
      </>}
    />
    <EnhancedTable
      defaultOrder="asc"
      defaultOrderField="code"
      headerCells={headerCells}
      rowCells={rowCells}
      getExpandContent={transformService =>
        <TransformServiceView
          form={transformService}
          showExtData={productMapping[transformService.product] && productMapping[transformService.product].type === 'service' ? true : false}
        />
      }
      tableData={filteredCurrentData.map(i => formatData(i))}
    />
  </div>

}

export default TransformServiceList
