import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import EnhancedTableRow from 'components/EnhancedTableRow';
import EnhancedTableHead from 'components/EnhancedTableHead';
import { getComparator, stableSort } from 'modules/sort';

function CustomerTabPage({ currentData, userMapping, tabName, currentCells, mappingData }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')

  const headerCells = currentCells.reduce((acc, cur) => {
    acc.push({ text: cur, sort: cur })
    return acc
  }, []).map(c => { c.text = formatMessage({ id: `customer.detail.${tabName}.${c.text}` }); return c })

  const rowCells = currentCells.reduce((acc, cur) => {
    acc.push({ field: cur })
    return acc
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const formatData = (appointment) => {
    const newData = { ...appointment }

    if (tabName === 'appointment') {
      let totalDuration = 0
      if (newData.treatments) {
        for (const key of Object.keys(newData.treatments)) {
          totalDuration += newData.treatments[key].duration
        }
      }
      const endTime = dayjs(`${newData.date} ${newData.time}`, 'YYYY-MM-DD HH:mm').add(totalDuration, 'minute').format('HH:mm')
      newData.arrangeTreatments = `${newData.time}-${endTime}`
      newData.doctor = userMapping[newData.doctor] ? userMapping[newData.doctor].displayName : ''
      newData.nurse = userMapping[newData.nurse] ? userMapping[newData.nurse].displayName : ''
      newData.salesRep = userMapping[newData.salesRep] ? userMapping[newData.salesRep].displayName : ''
    } else if (tabName === 'purchasedProduct') {
      newData.date = dayjs(newData.createdAt.seconds * 1000).format('YYYY-MM-DD');
      newData.quantityAvailable = `${newData.taken}/${newData.quantity}`
      newData.productName = mappingData.product[newData.product] ? mappingData.product[newData.product].name : ''
      newData.salesOrderSN = mappingData.salesOrder[newData.salesOrder] ? mappingData.salesOrder[newData.salesOrder].sn : ''
      newData.status = !newData.available ? '已消耗完' : `剩餘數量(${Number(newData.quantity) - Number(newData.taken)})`
      newData.source = newData.transfer ? '轉讓' : newData.transform ? '轉換' : '營收單'
    } else if (tabName === 'consumption') {
      if (newData.dataType === 'consume') {
        newData.typeName = '一般消耗'
      } else if (newData.dataType === 'transfer') {
        newData.typeName = `轉讓給${mappingData.customer[newData.newCustomer].name}`
      } else if (newData.dataType === 'refund') {
        newData.typeName = '退費消耗'
      } else if (newData.dataType === 'transform') {
        newData.typeName = '轉換消耗'
      }

      newData.amount = newData.productData.amount
      newData.productName = mappingData.product[newData.productData.productId] ? mappingData.product[newData.productData.productId].name : ''
    }

    return newData
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12}>
        <div style={{ marginTop: '20px', width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif' }}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={currentData.length}
              />
              <TableBody>
                {stableSort(currentData.map(r => formatData(r)), getComparator(order, orderBy)).map(data => (
                  <EnhancedTableRow
                    key={data.id}
                    rowCells={rowCells}
                    cellData={data}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>
    </Grid>
  );
}

CustomerTabPage.propTypes = {
  currentData: PropTypes.arrayOf(PropTypes.object.isRequired),
  userMapping: PropTypes.object.isRequired,
  currentCells: PropTypes.array.isRequired,
  tabName: PropTypes.string.isRequired,
  mappingData: PropTypes.object
};

export default CustomerTabPage;
