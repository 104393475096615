import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import { firestoreListener } from 'modules/firebase';

function CustomerExtTab({ tab, customerId, customerMapping }) {
  const customerExtTab = useSelector(state => state.customerExtTab.data);
  const userMapping = useSelector(state => state.users.data)
  const fieldData = Object.keys(customerExtTab[tab.id] || {}).map(i => customerExtTab[tab.id][i])
  const defaultData = fieldData.reduce((acc, cur) => {
    let newFields = []
    if (cur.check) {
      newFields = Object.keys(customerExtTab[cur.check] || {}).map(i => customerExtTab[cur.check][i])
    }

    if (cur.type === 'select-check') {
      acc[cur.id] = []
    } else if (cur.type === 'check') {
      acc[cur.id] = false
    } else {
      acc[cur.id] = ''
    }

    newFields.forEach(f => {
      if (f.type === 'select-check') {
        acc[f.id] = []
      } else if (f.type === 'check') {
        acc[f.id] = false
      } else {
        acc[f.id] = ''
      }
    })
    return acc
  }, {})
  const [customerData, setCustomerData] = useState(defaultData)

  const fields = fieldData.reduce((acc, cur) => {
    let newFields = []
    if (customerData[cur.id]) {
      newFields = Object.keys(customerExtTab[cur.check] || {}).map(i => customerExtTab[cur.check][i])
    }

    acc.push({ md: 3, ...cur })

    newFields.forEach(f => {
      acc.push({ md: 3, ...f })
    })
    return acc
  }, [])

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customerExtData',
      doc: customerId,
      unwrap: true,
      onData: (data) => {
        let newData = {}
        if (data && data[tab.id]) {
          newData = data[tab.id]
        }
        setCustomerData({ ...customerData, ...newData }) // NEED FIX
      }
    })

    return () => unsubscribe()
  }, []);

  function createField(field) {
    let newValue = customerData[field.id] ? customerData[field.id] : ''

    if (field.type === 'dialog') {
      if (field.dialog === 'users') {
        newValue = userMapping[newValue] ? userMapping[newValue].displayName : ''
      } else {
        newValue = customerMapping[newValue] ? customerMapping[newValue].name : ''
      }
    } else if (field.type === 'check') {
      newValue = newValue ? '是' : '否'
    }

    return <Grid item key={field.id} xs={12} sm={field.sm} md={field.md}>
      <TextField
        type="text"
        label={field.name}
        value={newValue}
        fullWidth
        size="small"
        variant="standard"
        margin="dense"
        InputProps={{
          readOnly: true,
        }}
      />
    </Grid>
  }

  return (
    <div style={{ padding: 15 }}>
      <Grid container spacing={2}>
        {fields.map(field => createField(field))}
      </Grid>
    </div>
  );
}

CustomerExtTab.propTypes = {
  tab: PropTypes.object.isRequired,
  customerId: PropTypes.string.isRequired,
  customerMapping: PropTypes.object
};

export default CustomerExtTab;
