const i18n = {
  'filter': '過濾條件',
  'condition': '條件',
  'searchbox': {
    'label': '依{filter}搜尋',
    'type': '依假別搜尋',
    'createdBy': '依申請人搜尋'
  },
  'search': {
    'startDate': '搜尋範圍-開始日期',
    'endDate': '搜尋範圍-結束日期',
  },
  'company': {
    'label': '公司'
  }
}

export default i18n