import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import LeaveIcon from '@mui/icons-material/EventNote';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Chip from '@mui/material/Chip';

import { tabletMedia, moibleMedia } from 'constants';

const AccountButton = styled('div')(({ theme }) => ({
  marginTop: '5px',
  [tabletMedia]: {
    marginTop: 0
  },
  [moibleMedia]: {
    marginRight: 0
  }
}));

const list = [
  { name: 'leaves', path: '/leaveOvertimeWork/review' },
  { name: 'overtimes', path: '/leaveOvertimeWork/review' },
  { name: 'punchClockRevise', path: '/leaveOvertimeWork/review' },
  { name: 'requisitions', path: '/purchase/requisition/pending' },
  { name: 'readyToBuy', path: '/purchase/requisition/done' },
  { name: 'shoppingCart', path: '/user/shoppingCart' },
  { name: 'purchaseOrders', path: '/purchase/purchaseOrder/pending' },
  { name: 'receipts', path: '/purchase/receipt/pending' },
  { name: 'stockRequisitions', path: '/stock/stockRequisition/pending' },
  { name: 'scrapForms', path: '/stock/scrapForm/pending' },
  { name: 'salesReturnForms', path: '/delivery/salesReturnForm/pending' },
  { name: 'inventoryChanges', path: '/stock/inventoryChange/pending' },
  { name: 'inventoryChecks', path: '/stock/inventoryCheck/pending' },
  { name: 'announcements', path: '/announcement' },
]

function Notification() {
  const navigate = useNavigate()
  const notifications = useSelector(state => state.notifications) || {};

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const notificationCount = Object.keys(notifications).reduce((acc, cur) => acc + notifications[cur], 0);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function gotoPath(path) {
    navigate(path)
    handleClose()
  };

  return (
    <AccountButton>
      {notificationCount > 0 && <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        color="error"
        badgeContent={notificationCount}
        sx={{ mr: isMobile ? '5px' : '10px', mt: '5px' }}
      >
        {!isMobile && !isTablet ?
          <Button
            sx={{ color: '#fff', textTransform: 'none' }}
            size='small'
            onClick={handleMenu}
          >
            <FormattedMessage id={'header.notice.pending'} />
          </Button> :
          <IconButton
            sx={{ color: '#fff', textTransform: 'none' }}
            size='small'
            onClick={handleMenu}
          >
            <LeaveIcon />
          </IconButton>}
      </Badge >}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        {list.filter(i => notifications[i.name]).map(i => <MenuItem key={i.name} onClick={() => gotoPath(i.path)}>
          <FormattedMessage id={`header.notice.${i.name}`} />
          <Chip sx={{ ml: '8px' }} size="small" color="primary" label={notifications[i.name]} />
        </MenuItem>)}
      </Menu>
    </AccountButton>
  )
}

export default Notification
