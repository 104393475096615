const i18n = {
  'homePage': {
    'setting': {
      'root': '設定',
      'staff': '職員',
      'attendance': '出勤',
      'userRight': '權限管理',
      'salesOrder': '營收單',
      'merchandise': '商品',
      'appointment': '預約表',
      'report': '報表',
      'customer': '客戶',
      'home': '首頁'
    },
    'appointments': {
      'root': '預約管理',
      'schedule': '預約表',
      'list': '預約列表',
      'editList': '預約編輯紀錄',
      'calendar': '會議室/車位'
    },
    'announcement': '公告',
    'customers': {
      'root': '客戶',
      'list': '客戶列表'
    },
    'products': {
      'root': '產品',
      'list': '產品列表'
    },
    'sales': {
      'root': '銷售',
      'salesOrders': '營收單',
      'refund': '退費單',
    },
    'services': {
      'root': '服務',
      'consume': '消耗單',
      'transfer': '轉讓單',
      'transform': '轉換單',
    },
    'schedule': '員工班表',
    'staffManagement': {
      'root': '職員管理',
      'staff': '職員',
      'salary': '薪資管理',
      'punchClockException': '補班和補假管理'
    },
    'finance': '財務管理',
    'leaveOvertimeWork': {
      'root': '請假與加班',
      'owner': '個人請假加班',
      'review': '簽核請假加班',
      'overview': '全部請假加班'
    },
    'report': {
      'root': '報表',
      'sales': '營收報表'
    },
    'purchase': {
      'root': '採購',
      'requisition': '請購單',
      'purchaseOrder': '採購單',
      'receipt': '進貨單',
      'returnForm': '退貨單',
    },
    'stock': {
      'root': '庫存管理',
      'query': '庫存查詢',
      'inventoryCheck': '盤點單',
      'scrapForm': '報廢出庫單',
      'stockRequisition': '領用單',
      'inventoryChange': '庫存異動單',
    },
    'delivery': {
      'root': '銷貨',
      'deliveryOrder': '銷貨單',
      'salesReturnForm': '銷貨退回單',
    },
    'vendor': '廠商',
    'merchandise': '商品',
  },
  'settingHome': {
    'title': '首頁',
    'homePage': {
      'title': '首頁設定',
      'table': {
        'header': {
          'name': '名稱',
          'status': '狀態'
        }
      },
      'edit': {
        'title': '編輯首頁設定'
      }
    }
  }
}

i18n.homePage.welcome = '出勤管理介面'

export default i18n