import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ScheduleTabContainer from 'containers/TabContainer/ScheduleTabContainer';
import TabContainer from 'containers/TabContainer/TabContainer';
import ContextStore from 'modules/context';
import { firebaseV8 } from 'modules/firebaseV8';
import { useDepartments } from 'hooks/departments';
import { tabletMedia } from 'constants';
import ScheduleTable from './ScheduleTable';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    backgroundColor: '#fff',
    height: '100%',
    width: '100%',
    // backgroundColor: 'green',
    [tabletMedia]: {
      display: 'block',
      maxHeight: '68px',
      width: '100vw',
      padding: '10px',
    }
  },
});

function ScheduleSidebar() {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const userRights = useSelector(state => state.userRights)
  const { currentUser, currentCompany } = useContext(ContextStore)

  const [activeTabIndex, setActiveTabIndex] = useState(undefined)
  const [worktimePolicyMapping, setWorktimePolicyMapping] = useState({})
  const tabName = location.pathname
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const departments = useDepartments()

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('worktimePolicies').onSnapshot(snapshot => {
      let policies = {}
      snapshot.forEach(doc => {
        policies[doc.id] = { id: doc.id, ...doc.data() }
      })
      setWorktimePolicyMapping(policies)
    }, err => { })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const pathname = location.pathname
    departments.forEach((department, index) => {
      if (pathname === `/schedule/${department.id}` && activeTabIndex !== index) {
        setActiveTabIndex(index)
      }
    })
    return () => {
    };
  }, [location.pathname]);

  if (departments.length === 0) {
    return ('Loading...')
  }

  let defaultSelect = Math.max(departments.findIndex(cur => {
    return tabName === `/schedule/${cur.id}`
  }), 0)

  const onTabSelected = (tabIndex) => {
    navigate(`/schedule/${departments[tabIndex].id}`);
    // departments.forEach((department, index) => {
    //   if (tabIndex === index) {
    //     navigate(`/schedule/${department.id}`);
    //   }
    // })
  };

  const tabs = departments.map(department => ({
    label: department.name,
    component: <ScheduleTable
      currentUser={currentUser}
      departmentId={department.id}
      staffType={department.id}
      workTimes={{}}
      policyMapping={worktimePolicyMapping}
      userRight={userRights}
      isTablet={isTablet}
      currentCompany={currentCompany}
    />
  }));

  if (isTablet) {
    return (
      <div className={classes.root}>
        <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <ScheduleTabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} orien="vertical" />
      </div>
    );
  }
}

export default ScheduleSidebar;
