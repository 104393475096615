import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Divider from '@mui/material/Divider';

import DateRangePickerField from 'components/DateRangePickerField';
import { unwrap } from 'modules/uitls';
import ContextStore from 'modules/context';
import { firebaseV8 } from 'modules/firebaseV8';
import PurchaseOrderList from './PurchaseOrderList';

function PurchaseOrderTab() {
  const { tabName } = useParams()
  const { currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)

  const [purchaseOrders, setPurchaseOrders] = useState([])
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  useEffect(() => {
    if (purchaseOrders.length) setPurchaseOrders([])
    const onSnapshot = snapshot => {
      const purchaseOrders = []
      snapshot.forEach(doc => {
        purchaseOrders.push({ ...unwrap(doc.data()), id: doc.id })
      });
      setPurchaseOrders(purchaseOrders)
    }
    const unsubscribe = tabName === 'pending' ?
      firebaseV8().collection('purchaseOrders').where('status', '==', tabName).onSnapshot(onSnapshot, err => {}) :
      firebaseV8().collection('purchaseOrders').where('status', '==', tabName).where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(onSnapshot, err => {})
    return () => unsubscribe()
  }, [startDate, endDate]);

  const purchaseOrdersForTab = purchaseOrders.filter(r => r.status === tabName)
    .filter(r =>
      userRights.debugging ||
      userRights.hasUserRightForVendor('purchaseOrder-create', r.source) ||
      userRights.hasUserRightForVendor('purchaseOrder-review', r.source) ||
      userRights.hasUserRightForVendor('purchaseOrder-overwrite', r.source)
    );

  const waitingApproving = waitingForProcess()

  function waitingForProcess() {
    if (tabName === 'pending') {
      return purchaseOrdersForTab.filter(r => {
        const history = r.history || []
        const currentStep = history.length > 0 ? history[history.length - 1].step : 0
        if (isNaN(currentStep) || currentStep === r.steps.length) return false
        return r.steps[currentStep].users.includes(currentUser.key)
      })
    } else if (tabName === 'done') {
      return purchaseOrdersForTab.filter(r => {
        return userRights.hasUserRightForVendor('purchaseOrder-create', r.source) && !r.closeout
      })
    } else {
      return []
    }
  }

  return (
    <div style={{ marginTop: '20px', paddingBottom: '60px' }}>
      {tabName !== 'pending' && <DateRangePickerField
        startDate={startDate}
        endDate={endDate}
        onStartDateChanged={setStartDate}
        onEndDateChanged={setEndDate}
      />}
      {waitingApproving.length > 0 && <>
        <PurchaseOrderList purchaseOrders={waitingApproving} title={`purchaseOrder.table.${tabName}.title`}/>
        <Divider sx={{ my: '10px', mx: '0px' }}/>
      </>}
      <PurchaseOrderList purchaseOrders={purchaseOrdersForTab} title="purchaseOrder.table.title" />
    </div>
  );
}

export default PurchaseOrderTab;
