import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import WorkflowView from './WorkflowView';

const _fields = [
  // {name: 'code', sm: 6},
  { name: 'name', sm: 6 },
  { name: 'nickname', sm: 6 },
  { name: 'businessNumber', sm: 6 },
  { name: 'phone', sm: 6 },
  { name: 'fax', sm: 6 },
  { name: 'billRule', sm: 6 },
  { name: 'basicPurchaseAmount', sm: 6 },
  { name: 'freeShippingAmount', sm: 6 },
  { name: 'email', sm: 12, md: 6 },
  { name: 'address', sm: 12, md: 6 },
  { name: 'contact', type: '-', reference: 'contacts' },
  { name: 'contacts' },
  { name: 'owner', type: '-' },
  { name: 'ownerName', sm: 6 },
  { name: 'ownerPhone', sm: 6 },
  { name: 'ownerMobile', sm: 6 },
  { name: 'shipping', type: '-' },
  { name: 'shippingOut', sm: 12, md: 12 },
  { name: 'other', type: '-' },
  { name: 'note', multiline: true, sm: 6, md: 6 },
].map(field => {field.multiline = field.multiline || false; field.md = field.md || 3; return field})

function VendorView({ vendor, vendorMapping, handleEditClick }) {
  const { formatMessage } = useIntl()
  const userRights = useSelector(state => state.userRights)
  const [warehouseflowView, setWarehouseflowView] = useState(false)

  const billRuleMapping = {
    '30': formatMessage({ id: 'vendor.billRule.rule1' }),
    '60': formatMessage({ id: 'vendor.billRule.rule2' }),
    '90': formatMessage({ id: 'vendor.billRule.rule3' }),
    'cashOnDelivery': formatMessage({ id: 'vendor.billRule.rule4' }),
    'ttInAdvance': formatMessage({ id: 'vendor.billRule.rule5' }),
  }

  const shippingOut = Object.keys(vendor.shippingOut || []).filter(c => vendorMapping[c]).map(c => vendorMapping[c]?.name || '').join(', ')

  const fields = []
  for (const field of _fields) {
    if (field.name === 'contacts') {
      for (const i in vendor.contacts) {
        fields.push({ name: 'contacts', subField: 'contactName', index: parseInt(i), sm: 6, md: 3 })
        fields.push({ name: 'contacts', subField: 'contactPhone', index: parseInt(i), sm: 6, md: 3 })
        fields.push({ name: 'contacts', subField: 'contactMobile', index: parseInt(i), sm: 6, md: 3 })
        fields.push({ name: 'contacts', subField: 'contactNote', index: parseInt(i), sm: 6, md: 3 })
      }
    } else {
      fields.push({ ...field, required: field.required || false, md: field.md || 3 })
    }
  }

  function createField(field) {
    const value = field.subField ? (vendor[field.name][field.index][field.subField]) : vendor[field.name]
    let newValue = value
    if (field.name === 'billRule') {
      newValue = billRuleMapping[value]
    }

    if (field.type === '-') {
      return field.reference && vendor[field.reference].length === 0 ? null : <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    }

    if (['phone', 'fax', 'contactPhone', 'ownerPhone'].includes(field.name) || (field.name === 'contacts' && ['contactPhone', 'contactMobile'].includes(field.subField)) ) {
      newValue = newValue.replace(/\s/g, '').replace(/#$/,'')
    }

    return <Grid item key={`${field.name}.${field.index || 0}.${field.subField || 0}`} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={formatMessage({ id: `vendor.table.detail.${field.subField || field.name}` })}
        value={field.name === 'shippingOut' ? shippingOut : newValue}
        fullWidth
        size="small"
        variant="standard"
        readOnly
      />
    </Grid>
  }

  return (
    <div style={{ flexGrow: 1, height: '100%' }}>
      <Grid container spacing={2}>
        {fields.map(field => createField(field))}
        {(userRights.hasUserRight('workflow-edit') || userRights.hasUserRight('vendor-edit')) && <Grid item key="buttons" xs={12} sm={6} md={6}>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
            {vendor.internal && !warehouseflowView && userRights.hasUserRight('workflow-edit') &&
              <Button
                variant="contained"
                onClick={() => setWarehouseflowView(true)}
                color="primary"
              >
                <FormattedMessage id="vendor.showWorkflow" />
              </Button>
            }
            {userRights.hasUserRight('vendor-edit') && <Button variant="contained" onClick={() => handleEditClick()} color="primary">
              <FormattedMessage id="button.edit" />
            </Button>}
          </Stack>
        </Grid>}
        {vendor.internal && warehouseflowView && <WorkflowView
          vendor={vendor}
          editable={userRights.hasUserRight('vendor-edit')}
        />}
      </Grid>
    </div>
  )
}

VendorView.propTypes = {
  vendor: PropTypes.object.isRequired,
  vendorMapping: PropTypes.object.isRequired,
  handleEditClick: PropTypes.func.isRequired,
};

export default VendorView;
