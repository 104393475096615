import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';

import SelectProductDialog from 'components/SelectProductDialog';
import SelectCustomerDialog from 'components/SelectCustomerDialog';
import DelectIconButton from 'components/DelectIconButton';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import EnhancedTable from 'components/EnhancedTable';
import ContextStore from 'modules/context';
import { getRandomHash, unique } from 'modules/uitls';
import { addError, removeError } from 'modules/editor';
import { firestoreListener, callFunction } from 'modules/firebase';
import SelectPurchasedServiceDialog from 'components/SelectPurchasedServiceDialog';

function EditRefundPage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const navigate = useNavigate()
  const { refundId } = useParams()
  const location = useLocation()
  const config = useSelector(state => state.config.data)
  const paymentTypes = Object.keys(config.paymentType || {}).filter(i => config.paymentType[i].active).map(i => config.paymentType[i])
  const [loading, setLoading] = useState(false)
  const productMapping = useSelector(state => state.products.data)
  const products = useSelector(state => state.products.ordered)
  const vendorMapping = useSelector(state => state.vendors.data)
  const userMapping = useSelector(state => state.users.data);
  const sourceMapping = useSelector(state => state.internalVendors.data)
  const [availableStoredValue, setAvailableStoredValue] = useState({});
  const [customers, setCustomers] = useState([]);
  const [customerMapping, setCustomerMapping] = useState({});
  const [openDialog, setOpenDialog] = useState(null)
  const [ppData, setPPData] = useState([]);
  const companyData = currentUser.company?.reduce((acc, cur) => { if (userRights['refund-create'].includes(cur)) { acc = true } return acc }, false) ?
    unique(currentUser.company?.concat(userRights['refund-create'])) : userRights['refund-create']
  const [data, setData] = useState({
    id: refundId,
    customer: '',
    note: '',
    paymentType: '',
    refundPrice: '',
    products: [],
    source: companyData[0]
  });
  const selectedstoredValueCards = data.products.map(i => i.productId)

  useEffect(() => {
    const breadcrumbs = [{
      link: '/sales/refund',
      text: formatMessage({ id: 'sideMenu.sales.refund' })
    }]
    if (refundId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'refundService.add' }) })
    }

    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const unsubscribe = (refundId !== 'new' && userRights.hasUserRight('refund-edit')) ? firestoreListener({
      collection: 'refundServices',
      doc: refundId,
      onData: (data) => {
        const newData = {
          customer: data.customer,
          note: data.note,
          refundPrice: data.refundPrice,
          products: Object.keys(data.products).map(i => ({
            id: i,
            ...data.products[i],
            pp: Object.keys(data.products[i].purchasedProducts || {}).map(k => ({
              id: k,
              selectCount: data.products[i].purchasedProducts[k]
            }))
          }))
        }

        if (data.paymentType === 'storedValue') {
          newData.paymentType = `${data.paymentType}*${data.storedValueCard}`
        } else {
          newData.paymentType = data.paymentType
        }

        setData({ ...data, ...newData })
      }
    }) : null

    if ((refundId !== 'new' && !userRights.hasUserRight('refund-edit')) ||
      !userRights.hasUserRight('refund-create')) {
      navigate('/');
    }
    return () => unsubscribe?.()
  }, [refundId]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      array: true,
      onData: ({ mapping, data }) => {
        setCustomers(data)
        setCustomerMapping(mapping)
      }
    })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = data.customer ? firestoreListener({
      collection: 'storedValueCard',
      doc: data.customer,
      unwrap: true,
      addDocId: false,
      onData: (data) => {
        setAvailableStoredValue(data || {})
      }
    }) : null
    return () => unsubscribe?.()
  }, [data.customer]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'purchasedProducts',
      where: [
        ['customer', '==', data.customer],
        ['source', '==', data.source],
        ['available', '==', true]
      ],
      onData: (data) => {
        setPPData(data)
      }
    })
    return () => unsubscribe()
  }, [data.customer, data.source]);

  function formatData(currentData) {
    const newData = { ...currentData }
    const product = productMapping[newData.productId]

    newData.name = product ? product.name : ''
    newData.nickname = product ? product.nickname : ''
    newData.price = product ? product.price : 0

    if (['service', 'pointCard'].includes(newData.type)) {
      newData.availableAmount = ppData.filter(pp => pp.product === newData.productId).reduce((acc, cur) => {
        acc += cur.quantity - cur.taken
        return acc
      }, 0)
    } else if (newData.type === 'storedValue') {
      newData.availableAmount = availableStoredValue[newData.productId] || 0
    } else {
      newData.availableAmount = '--'
    }

    if (newData.availableAmount === 0) {
      newData.textColor = '#fe2851'
    }

    return newData
  }

  function updateData(field, value) {
    setData( data => {
      let newValue = value
      if (field.allowCharacter) {
        newValue = newValue.replace(field.allowCharacter, '')
      }
      let err = validateField(field, value)
      let newData = { ...data, [field.name]: newValue, [`${field.name}_err`]: err }

      if (field.name === 'customer') {
        newData.products = []
      }
      return newData
    })
  }

  function updateDataFields(fields, values) {
    let newData = { ...data }

    for (const field of fields) {
      let newValue = values[field.name]
      if (field.allowCharacter) {
        newValue = newValue.replace(field.allowCharacter, '')
      }
      let err = validateField(field, values[field.name])
      newData[field.name] = newValue
      newData[`${field.name}_err`] = err
      // let newData = { ...data, [field.name]: newValue, [`${field.name}_err`]: err }
      if (field.name === 'customer') {
        newData.products = []
      }

      setData(newData)
    }
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    return ''
  }

  function handleClose() {
    navigate('/sales/refund');
  }

  async function handleSave() {
    setLoading(true)
    let err = false;

    const fields = [
      { name: 'customer' },
      { name: 'paymentType' },
      { name: 'refundPrice' },
    ]

    for (const field of fields) {
      if (data[field.name] === '') {
        data[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
        err = true
      }
    }

    for (const product of data.products) {
      if (product.amount === '') {
        addError(product, 'amount', formatMessage({ id: 'form.field.isRequired' }))
      }
    }

    // 如果有數量上的錯誤, 不不可以儲存
    for (const product of data.products) {
      if (product.errors && product.errors.amount) {
        err = true
        break;
      }
    }

    if (err) {
      setData({ ...data })
      setLoading(false);
      return
    }

    const newProducts = data.products.reduce((acc, cur) => {
      if (cur.amount) {
        const p = {
          productId: cur.productId,
          amount: parseInt(cur.amount),
          lock: true
        }
        if (cur.pp) {
          p.purchasedProducts = cur.pp.reduce((acc, cur) => {
            acc[cur.id] = cur.selectCount
            return acc
          }, {})
        }
        acc[cur.id] = p
      }

      return acc
    }, {})

    const updateData = {
      customer: data.customer,
      note: data.note,
      refundPrice: parseInt(data.refundPrice),
      products: newProducts,
      source: data.source
    }

    let paymentType = data.paymentType
    if (paymentType.startsWith('storedValue*')) {
      const s = paymentType.split('*')
      updateData.paymentType = s[0]
      updateData.storedValueCard = s[1]
    } else {
      updateData.paymentType = paymentType
    }

    try {
      await callFunction('saveRefundService', { id: refundId, ...updateData })
    } catch (ex) {
      console.log(ex)
    }
    handleClose()
  }

  function onSelectProduct(products) {
    if (products.length) {
      for (const p of products) {
        const pData = {
          id: getRandomHash(),
          productId: p.id,
          price: p.price,
          amount: ['service', 'pointCard'].includes(p.type) ? '' : 1,
          note: '',
          type: p.type,
        }

        data.products.push(pData)
      }
      const selectedProducts = [...data.products];
      for (const p of selectedProducts) {
        if (p.type === 'storedValue') {
          if (data.paymentType === `storedValue*${p.productId}`) {
            updateData({ name: 'paymentType' }, '');
            break
          }
        }
      }
      updateData({ name: 'products' }, selectedProducts)
    }
  }

  function onDeleteItem(item) {
    const index = data.products.findIndex(i => i.id === item.id)
    data.products.splice(index, 1)
    updateData({ name: 'products' }, data.products);
  }

  function onCellValueChanged(cellData, field, value) {
    const m = data.products.find(i => i.id === cellData.id)
    if (!m) {
      return
    }

    if (field === 'amount') {
      m.amount = value
      // 如果是服務或儲值, 要限制數量?
      let error = false
      if (isNaN(value) || value === '') {
        error = true
        addError(m, field, '數量錯誤')
      }
      if (!error) {
        // if (['service', 'pointCard'].includes(m.type)) {
        //   const availableAmount = ppData.filter(pp => pp.product === m.productId).reduce((acc, cur) => {
        //     acc += cur.quantity - cur.taken
        //     return acc
        //   }, 0)
        //   if (availableAmount < parseInt(value)) {
        //     error = true
        //     addError(m, field, '超過剩餘數量')
        //   }
        // } else
        if (m.type === 'storedValue') {
          const availableAmount = availableStoredValue[m.productId] || 0
          if (availableAmount < parseInt(value)) {
            error = true
            addError(m, field, '超過剩餘數量')
          }
        }
      }

      if (!error) {
        removeError(m, field)
      }
    } else {
      removeError(m, field)
      m[field] = value
    }
    updateData({ name: cellData.table }, data[cellData.table]);
  }

  const headerCells = [
    { text: 'name' },
    { text: 'availableAmount' },
    { text: 'amount' },
    { text: 'price' },
    { text: 'note' },
  ].map(c => { c.text = formatMessage({ id: `refundService.product.${c.text}` }); return c })

  const rowCells = [
    { field: 'nickname', tooltip: 'name' },
    { field: 'availableAmount', textColor: true },
    {
      field: 'amount',
      type: 'input-number',
      required: true,
      label: '數量',
      enabledClick: enabledClickEvent,
      onClick: setSelectPP,
      onValueChanged: onCellValueChanged,
      getEnableStatus: getEnableStatus,
    },
    { field: 'price' },
    { field: 'note', type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged },
  ];

  const storedValueCards = products.filter(i => i.type === 'storedValue' && !selectedstoredValueCards.includes(i.id) && (i.seller || []).includes(data.source)).map(i => ({
    active: true,
    id: `storedValue*${i.id}`,
    name: i.name
  }))

  function enabledClickEvent(data, field) {
    return ['service', 'pointCard'].includes(data.type)
  }

  function getEnableStatus(data, field) {
    if (field === 'amount' && data.lock) {
      return false
    }
    return true
  }

  function onSelectSource(e) {
    const source = e.target.value
    const fields = [{ name: 'source' }]
    const values = { source: e.target.value }
    if (data.paymentType && data.paymentType.includes('*')) {
      const s = data.paymentType.split('*')
      if (!(productMapping[s[1]].seller || []).includes(source)) {
        fields.push({ name: 'paymentType' })
        values.paymentType = ''
      }
    }
    updateDataFields(fields, values)
  }

  function setSelectPP(product) {
    setOpenDialog({
      name: 'purchasedService',
      customer: data.customer,
      id: product.id,
      ppData: ppData.filter(i => i.product === product.productId).map(i => {
        const m = /SO(\d{4})(\d{2})(\d{2})(?:\d{4})/.exec(i.salesOrderSn)
        const selectedPP = (product.pp || []).find(j => j.id === i.id)
        const selectCount = selectedPP ? String(selectedPP.selectCount) : ''
        const pp = {
          date: `${m[1]}-${m[2]}-${m[3]}`,
          salesOrder: i.salesOrder,
          salesOrderSn: i.salesOrderSn,
          salesOrderItem: i.salesOrderItem,
          serviceProvider: i.serviceProvider,
          quantity: i.quantity,
          amount: i.quantity - i.taken,
          id: i.id,
          selectCount,
        }
        return pp
      }),
      productId: product.productId,
    })
  }

  function onSelectPurchasedService(params, items) {
    const m = data.products.find(i => i.id === params)
    m.amount = items.reduce((acc, cur) => acc + cur.selectCount, 0)
    m.pp = items
    updateData({ name: 'products' }, [...data.products])
  }

  return (
    <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
      {openDialog?.name === 'customer' && <SelectCustomerDialog
        handleClose={() => setOpenDialog(null)}
        handleSave={customer => updateData({ name: openDialog?.name }, customer.id)}
        customers={openDialog?.name === 'customer' ? customers : customers.filter(c => c.id !== data.customer) || []}
        dialogTitle={formatMessage({ id: 'selectCustomerDialog.title' })}
      />}
      {openDialog?.name === 'product' && <SelectProductDialog
        headerCells={[{ text: 'name', sort: 'name' }]}
        rowCells={[{ field: 'name' }]}
        filterItems={[{ name: 'name' }]}
        filterSetting={{ type: { hidden: true, default: '' } }}
        defaultSelectedItems={[]}
        handleClose={() => setOpenDialog(null)}
        handleSave={onSelectProduct}
        // items={products.filter(p => ['service', 'merchandise', 'storedValue'].includes(p.type))} // 點數卡不能退費?
        items={products}
        dialogTitle={formatMessage({ id: 'selectProductDialog.title' })}
        tableTitle={formatMessage({ id: 'selectProductDialog.table.title' })}
        productMapping={productMapping}
      />}
      {openDialog?.name === 'purchasedService' && <SelectPurchasedServiceDialog
        handleClose={() => setOpenDialog(null)}
        handleSave={onSelectPurchasedService}
        customer={openDialog.customer}
        productId={openDialog.productId}
        ppData={openDialog.ppData}
        userMapping={userMapping}
        sourceMapping={sourceMapping}
        customerMapping={customerMapping}
        amountFieldLabel='refund'
        params={openDialog.id}
      />}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            type="text"
            size="small"
            select={userRights['refund-create']?.length > 1}
            disabled={refundId !== 'new' || userRights['refund-create']?.length <= 1}
            label={formatMessage({ id: 'refundService.table.detail.source' })}
            variant="outlined"
            value={userRights['refund-create']?.length > 1 ? data.source : vendorMapping[data.source]?.name}
            onChange={onSelectSource}
            fullWidth
          >
            {
              companyData.map(c => {
                return <MenuItem key={c} value={c}>{vendorMapping[c].name}</MenuItem>
              })
            }
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            type="text"
            size="small"
            label={formatMessage({ id: 'refundService.table.detail.customer' })}
            variant="outlined"
            value={data.customer && customerMapping[data.customer] ? customerMapping[data.customer].name : ''}
            onClick={() => setOpenDialog({ name:'customer' })}
            error={data.customer_err ? true : false}
            helperText={data.customer_err}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            select
            type="text"
            size="small"
            disabled={!data.customer}
            onChange={(e) => updateData({ name: 'paymentType' }, e.target.value)}
            label={formatMessage({ id: 'refundService.table.detail.paymentType' })}
            variant="outlined"
            value={data.paymentType}
            error={data.paymentType_err ? true : false}
            helperText={data.paymentType_err}
            fullWidth
          >
            {paymentTypes.concat(storedValueCards).map(payment => {
              return <MenuItem key={payment.id} value={payment.id}>{payment.name}</MenuItem>
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            type="text"
            size="small"
            disabled={!data.customer || !data.paymentType}
            onChange={(e) => updateData({ name: 'refundPrice', allowCharacter: /[^0-9]/g }, e.target.value)}
            label={formatMessage({ id: 'refundService.table.detail.refundPrice' })}
            variant="outlined"
            value={data.refundPrice}
            error={data.refundPrice_err ? true : false}
            helperText={data.refundPrice_err}
            fullWidth
          />
        </Grid>
      </Grid>
      <Divider style={{ margin: '8px 0px' }} />
      <EnhancedTableToolbar
        title={'editRefundService.title'}
        toolbox={
          <Button
            disabled={!data.customer}
            sx={{ m: 1, whiteSpace: 'nowrap' }}
            variant="contained"
            color="primary"
            onClick={() => { setOpenDialog({ name: 'product' }) }}
          >
            <FormattedMessage id={'editRefundService.addProduct'} />
          </Button>
        }
      />
      <EnhancedTable
        headerCells={headerCells}
        rowCells={rowCells}
        tableData={data.products.map(i => formatData(i))}
        getActionIcons={product =>
          !product.lock ? <DelectIconButton
            text={formatMessage({ id: 'editRefundService.removeProduct' })}
            onClick={() => onDeleteItem(product)}
          /> : <div></div>
        }
      />
      <Divider style={{ margin: '8px 0px' }} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            type="text"
            label={formatMessage({ id: 'refundService.table.detail.note' })}
            variant="outlined"
            value={data.note}
            onChange={e => updateData({ name: 'note' }, e.target.value)}
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>
      <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
        <Button variant="contained" color='inherit' onClick={handleClose}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          disabled={loading || data.products.length === 0 || !data.customer}
          onClick={handleSave}
          loading={loading}
          loadingPosition="start"
          loadingIndicator={<CircularProgress size={24} />}
          startIcon={<div />}
          variant="contained"
        >
          <FormattedMessage id="button.submit" />
        </LoadingButton>
      </Stack>
    </Box>
  )
}

export default EditRefundPage
