import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';
import MuiList from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import MuiDivider from '@mui/material/Divider';

import { logout } from 'store';
import { useModuleMapping } from 'hooks/modules';
import { getIcon } from 'enum/iconModules';

const List = styled(MuiList)(({ theme }) => ({
  '& [mainmenuitem=true]+hr': {
    display: 'block',
  }
}));

const Divider = styled(MuiDivider)(({ theme }) => ({
  display: 'none',
}));

function SideMenu({ currentUser }) {
  const { formatMessage } = useIntl()
  const userRights = useSelector(state => state.userRights)
  const moduleMapping = useModuleMapping()
  const [expend, setExpend] = useState({});

  const menuItems = filterByModules(filterByUserRights([
    {
      text: 'appointments', icon: getIcon('appointments'), path: 'appointments', userRight: 'appointment-view', modules: ['appointment'], subMenuItems: filterByModules(filterByUserRights([
        { text: 'appointments.schedule', path: 'schedule' },
        { text: 'appointments.list', path: 'list' },
        { text: 'appointments.editList', path: 'editList' },
      ]))
    },
    {
      text: 'customers', icon: getIcon('customers'), path: 'customers', modules: ['customer'], simplify: true, subMenuItems: filterByModules(filterByUserRights([
        { text: 'customers.list', path: 'list', userRight: 'customer-view' },
      ]))
    },
    { text: 'schedule', icon: getIcon('schedule'), path: 'schedule/normal', modules: ['schedule'] },
    {
      text: 'staffManagement', icon: getIcon('staff'), path: 'staffManagement', subMenuItems: filterByModules(filterByUserRights([
        { text: 'staffManagement.staff', path: 'staff', userRight: 'user-view' },
        { text: 'staffManagement.punchClockException', path: 'punchClockException', userRight: 'schedule-exception', modules: ['schedule'] },
      ]))
    },
    {
      text: 'leaveOvertimeWork', icon: getIcon('leaveOvertime'), path: 'leaveOvertimeWork', subMenuItems: filterByModules(filterByUserRights([
        { text: 'leaveOvertimeWork.owner', path: 'leaves', userRight: 'leaveOvertime-view', modules: ['leaveOvertime', 'punchClock'] },
        { text: 'leaveOvertimeWork.review', path: 'review', userRight: 'any-review', modules: ['leaveOvertime', 'punchClock'] },
        { text: 'leaveOvertimeWork.overview', path: 'overview', userRight: 'any-overview', modules: ['leaveOvertime', 'punchClock'] },
      ]))
    },
    {
      text: 'products', icon: getIcon('product'), path: 'products', modules: ['product'], simplify: true, subMenuItems: filterByModules(filterByUserRights([
        { text: 'products.list', path: 'list', userRight: 'product-view' },
      ]))
    },
    {
      text: 'sales', icon: getIcon('sales'), path: 'sales', modules: ['sales'], subMenuItems: filterByModules(filterByUserRights([
        { text: 'sales.salesOrders', path: 'salesOrders', userRight: 'salesOrder-view' },
        { text: 'sales.refund', path: 'refund', userRight: 'refund-view' },
        { text: 'sales.referrer', path: 'referrer', userRight: 'referrer-view' },
        { text: 'sales.query', path: 'query', userRight: 'salesOrder-view' },
      ]))
    },
    {
      text: 'services', icon: getIcon('services'), path: 'services', modules: ['service'], subMenuItems: filterByModules(filterByUserRights([
        { text: 'services.consume', path: 'consume', userRight: 'consumeService-view' },
        { text: 'services.transfer', path: 'transfer', userRight: 'transferService-view' },
        { text: 'services.transform', path: 'transform', userRight: 'transformService-view' },
      ]))
    },
    { text: 'finance', icon: getIcon('finance'), path: 'finance', userRight: 'finance-view', modules: ['finance'] },
    {
      text: 'report', icon: getIcon('report'), path: 'report', subMenuItems: filterByModules(filterByUserRights([
        { text: 'report.sales', path: 'sales/day', userRight: 'report-view', modules: ['report'] },
      ]))
    },
    {
      text: 'announcement', icon: getIcon('announcement'), path: 'announcement', modules: ['announcement'] , simplify: true, subMenuItems: filterByModules(filterByUserRights([
        { text: 'announcement.list', path: 'list', userRight: 'announcement-view' },
        { text: 'announcement.review', path: 'review', userRight: 'announcement-review' },
      ]))
    },

    { text: '-' },
    {
      text: 'purchase', icon: getIcon('purchase'), path: 'purchase', modules: ['purchase', 'warehouse'], subMenuItems: filterByModules(filterByUserRights([
        { text: 'purchase.requisition', path: 'requisition/pending', userRight: 'requisition-view' },
        { text: 'purchase.purchaseOrder', path: 'purchaseOrder/pending', userRight: 'purchaseOrder-view' },
        { text: 'purchase.receipt', path: 'receipt/pending', userRight: 'receipt-view' },
        { text: 'purchase.returnForm', path: 'returnForm', userRight: 'returnForm-view' },
      ]))
    },
    {
      text: 'delivery', icon: getIcon('delivery'), path: 'delivery', modules: ['warehouse'], subMenuItems: filterByModules(filterByUserRights([
        { text: 'delivery.deliveryOrder', path: 'deliveryOrder', userRight: 'deliveryOrder-view' },
        { text: 'delivery.salesReturnForm', path: 'salesReturnForm/pending', userRight: 'deliveryOrder-view' },
      ]))
    },
    {
      text: 'stock', icon:  getIcon('stock'), path: 'stock', modules: ['warehouse'], subMenuItems: filterByUserRights([
        { text: 'stock.query', path: 'query', userRight: 'stock-query' },
        // { text: 'stock.arrangeStockForm', path: 'arrangeStockForm', userRight: 'whInventory-view' },
        { text: 'stock.stockRequisition', path: 'stockRequisition/pending', userRight: 'stockRequisition-view' },
        { text: 'stock.scrapForm', path: 'scrapForm/pending', userRight: 'scrapForm-view' },
        { text: 'stock.inventoryChange', path: 'inventoryChange/pending', userRight: 'inventoryChange-view' },
        { text: 'stock.inventoryCheck', path: 'inventoryCheck/processing', userRight: 'inventoryCheck-view' },
      ])
    },
    { text: 'vendor', icon: getIcon('vendor'), path: 'vendor', userRight: 'vendor-view' },
    { text: 'merchandise', icon: getIcon('merchandise'), path: 'merchandise', userRight: 'merchandise-view' },
    { text: '-' },
    {
      text: 'setting', icon: getIcon('setting'), path: 'setting', subMenuItems: filterByModules(filterByUserRights([
        { text: 'setting.appointment', path: 'appointment/treatments', userRight: 'setting-edit', modules: ['appointment'] },
        { text: 'setting.customer', path: 'customer/extData', userRight: 'setting-edit', modules: ['customer'] },
        { text: 'setting.staff', path: 'staff/departments', userRight: 'setting-edit', modules: ['user'] },
        { text: 'setting.attendance', path: 'attendance/reviewPolicy', userRight: 'setting-edit', modules: ['schedule', 'leaveOvertime', 'punchClock'] },
        { text: 'setting.salesOrder', path: 'salesOrder', userRight: 'setting-edit', modules: ['sales'] },
        { text: 'setting.merchandise', path: 'merchandise/category', userRight: 'setting-edit', modules: ['merchandise'] },
        { text: 'setting.report', path: 'report/reportCategory', userRight: 'setting-edit', modules: ['report'] },
        { text: 'setting.home', path: 'home', userRight: 'setting-edit' },
        { text: 'setting.userRight', path: 'userRight', userRight: 'userRights-edit' },
      ]))
    },
    { text: 'logout', icon: getIcon('logout'), path: 'logout', isClick: true },
  ]));

  function filterByModules(items) {
    return items.filter(i => (!i.modules && (!i.subMenuItems || (i.subMenuItems && i.subMenuItems.length))) || i.modules.some(m => moduleMapping[m]))
  }

  function filterByUserRights(items) {
    return items.filter(i => (!i.userRight && (!i.subMenuItems || (i.subMenuItems && i.subMenuItems.length))) || userRights.hasUserRight(i.userRight))
  };

  const getMenuItem = ({ text, icon, path, subMenuItems, isClick }, index) => {
    if (text === '-') {
      return <Divider style={{ marginTop: '5px' }} key={`-${index}`} />
    } else {
      return (
        !!subMenuItems ? (
          <div mainmenuitem="true" key={text}>
            <ListItem onClick={() => setExpend({ ...expend, [text]: !expend[text] })}>
              <ListItemIcon style={{ minWidth: '40px' }}>{icon}</ListItemIcon>
              <ListItemText primary={formatMessage({ id: `sideMenu.${text}.root` })} />
              {expend[text] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse key={`${text}+`} in={expend[text]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {subMenuItems.map(({ text, path: subPath }) => (
                  <Link key={`/${path}/${subPath}`} to={`/${path}/${subPath}`} style={{ textDecoration: 'none', color: '#000' }}>
                    <ListItem style={{ paddingLeft: '72px' }} >
                      {/* <ListItemIcon><StarBorder /></ListItemIcon> */}
                      <ListItemText primary={formatMessage({ id: `sideMenu.${text}` })} />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Collapse>
          </div>
        ) : (
          <Link mainmenuitem="true" key={path} to={`/${path}`} style={{ textDecoration: 'none', color: '#000' }}>
            <ListItem key={text} onClick={() => isClick && onLogout()}>
              <ListItemIcon style={{ minWidth: '40px' }}>{icon}</ListItemIcon>
              <ListItemText primary={formatMessage({ id: `sideMenu.${text}` })} />
            </ListItem>
          </Link>
        )
      )
    }
  }

  function onLogout() {
    logout();
  }

  return (
    <List>
      {menuItems.map(item => {
        if (item.simplify && item.subMenuItems && item.subMenuItems.length === 1) {
          const subMenuItem = item.subMenuItems[0]
          return { text: subMenuItem.text, icon: item.icon, path: `${item.path}/${subMenuItem.path}`, modules: [...new Set(...(item.modules ?? []), ...(subMenuItem.modules ?? []))], userRight: subMenuItem.userRight }
        }
        return item
      }).map((item, index) => getMenuItem(item, index))}
    </List>
  )
}

export default SideMenu
