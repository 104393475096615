import React, { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';

import AppointmentItem from './AppointmentItem';
import ContextStore from 'modules/context';
import { calculateStartTop, calculateStartLeft } from 'modules/appointment';
import { moibleMedia } from 'constants';
import { useModuleMapping } from 'hooks/modules';

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '120px',
    color: '#373737',
    borderBottom: '1px solid #D4D4D4',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#eee',
    [moibleMedia]: {
      margin: '20px 0 0 0',
      flexDirection: 'column',
      width: '100%',
      minHeight: '960px !important',
      background: 'transparent',
      backgroundColor: '#eee',
      overflow: 'visible',
    }
  },
  normalAppointment: {
    backgroundColor: '#fff',
  },
  salesRepMode: {
    backgroundColor: '#fff',
  },
  cancelled: {
    opacity: 0.5
  },
  hourSlot: {
    position: 'relative',
    width: '280px',
    borderLeft: '1px solid #D4D4D4',
    '&:after': {
      content: '""',
      display: 'block',
      width: '1px',
      height: '100%',
      background: '#f3f3f6',
      position: 'absolute',
      left: '139px',
    },
    [moibleMedia]: {
      width: '100%',
      minHeight: '80px',
      borderBottom: '1px solid #D4D4D4',
      borderLeft: 'none',
      '&:after': {
        width: '100%',
        height: '1px',
        background: '#f3f3f6',
        position: 'absolute',
        top: '40px',
        left: 0
      }
    }
  },
  hourSlots: {
    alignItems: 'stretch',
    alignSelf: 'stretch',
    display: 'flex',
    [moibleMedia]: {
      flexDirection: 'column',
    }
  },
  shift: {
    backgroundColor: '#fff',
    display: 'flex',
    position: 'absolute',
    top: 0,
    height: '100%',
    [moibleMedia]: {
      height: '80px',
      width: '100%',
      left: '0 !important',
      backgroundColor: '#fff'
    }
  },
  leaves: {
    backgroundImage: 'repeating-linear-gradient(45deg, #F2F2F2 0, #F2F2F2 1px, transparent 0, transparent 50%)',
    backgroundSize: '25px 25px',
    backgroundColor: '#d4d4d4',
    display: 'flex',
    position: 'absolute',
    top: 0,
    height: '100%',
    [moibleMedia]: {
      height: '80px',
      width: '100%',
      left: '0 !important',
      backgroundColor: '#d4d4d4',
      backgroundImage: 'repeating-linear-gradient(45deg, #F2F2F2 0, #F2F2F2 1px, transparent 0, transparent 50%)',
      backgroundSize: '25px 25px',
    }
  },
  currentTime: {
    position: 'absolute',
    height: '100%',
    width: '2px',
    backgroundColor: '#ff0039',
    zIndex: 2,
  },
  comment: {
    backgroundColor: 'rgba(255, 193, 7, 0.08)',
    borderLeft: 'solid 2px #ffc107',
    borderRight: 'solid 2px #ffc107',
    display: 'flex',
    position: 'absolute',
    top: 0,
    height: '100%',
    [moibleMedia]: {
      height: '80px',
      width: '100%',
      left: '0 !important',
      border: 'solid 2px #ffc107',
      borderLeft: 'none',
      borderRight: 'none'
    }
  }
});

function ScheduleRow({
  appointments,
  appointmentType,
  doctor,
  salesRep,
  doctorShifts,
  doctorLeaves,
  firstHour,
  lastHour,
  selectAppointment,
  selectComment,
  selectHour,
  customerMapping,
  currentTimePosition,
  rowHeights,
  appointmentPosition,
  isMobile,
  scheduleMode,
  comments }) {
  const { uiState } = useContext(ContextStore)
  const classes = useStyles();
  const rowRef = useRef()
  let weekday = dayjs(uiState.date).weekday()
  const totalHours = lastHour + 1 - firstHour
  const appointmentElements = positionAppointments(true)
  const moduleMapping = useModuleMapping()

  useEffect(() => {
    positionAppointments()
    return () => {
      positionAppointments()
    };
  }, [appointments]);

  function positionAppointments(withElements) {
    const elements = appointments.map((appointment, i) => {
      if (withElements) {
        return <AppointmentItem
          key={i}
          ui={uiState}
          top={appointmentPosition[appointment.id]?.top}
          left={appointmentPosition[appointment.id]?.left}
          appointment={appointment}
          selectAppointment={selectAppointment}
          customerMapping={customerMapping}
          firstHour={firstHour}
          scheduleMode={scheduleMode}
        />
      }

      return null
    })

    const rowsCount = scheduleMode === 'doctor'
      ? (rowHeights[(doctor && doctor.id) || appointmentType]?.rowsCount ?? 0)
      : (rowHeights[(salesRep && salesRep.id) || appointmentType]?.rowsCount ?? 0)

    if (rowRef.current) {
      const minHeight = Math.max(120, ((rowsCount + 1) * 60))
      const minWidth = isMobile ? (rowsCount + 2) * 136 : (lastHour + 1 - firstHour) * 280 + 20

      rowRef.current.style.minHeight = `${minHeight}px`
      rowRef.current.style.minWidth = `${minWidth}px`
    }

    return elements
  }

  function highlighGrid(hour, type, menuId, id) {
    if (
      uiState.showClickMenu &&
      uiState.clickMenuType === 'hour' &&
      uiState.clickMenuData &&
      uiState.clickMenuData.hour === hour &&
      uiState.clickMenuData.appointmentType === type &&
      (!menuId || menuId === id)
    ) {
      return true
    }
    return false
  }

  function getRowClick(event, hour, appointmentType, doctor, salesRep) {
    if ((moduleMapping.doctor && scheduleMode === 'doctor') || !moduleMapping.doctor) {
      return selectHour({ event, hour, appointmentType, doctor, salesRep })
    } else {
      return null
    }
  }

  return (
    <div ref={rowRef} className={clsx(classes.row, {
      [classes.cancelled]: appointmentType === 'cancelled',
      [classes.normalAppointment]: (weekday !== 0 && ['noSalesRep', 'normalAppointment'].includes(appointmentType)),
      [classes.salesRepMode]: scheduleMode === 'salesRep' && appointmentType !== 'cancelled'
    })}>
      {!isMobile && <div
        className={classes.currentTime}
        style={{
          left: currentTimePosition,
          display: currentTimePosition ? 'block' : 'none',
        }}
      />}
      <div className={classes.hourSlots}>
        {doctorShifts && doctorShifts.map((shift, i) =>
          <div
            className={classes.shift}
            key={i}
            style={{
              top: `${isMobile
                ? calculateStartTop(shift.startHour, shift.startMinute, firstHour)
                : 0}px`,
              left: `${isMobile
                ? '0'
                : calculateStartLeft(shift.startHour, shift.startMinute, firstHour)}px`,
              width: isMobile
                ? '100%'
                : `${(calculateStartLeft(shift.endHour, shift.endMinute, firstHour) - calculateStartLeft(shift.startHour, shift.startMinute, firstHour))}px`,
              height: isMobile
                ? `${(calculateStartTop(shift.endHour, shift.endMinute, firstHour) - calculateStartTop(shift.startHour, shift.startMinute, firstHour))}px`
                : '100%'
            }}
          />
        )}

        {doctorLeaves && doctorLeaves.map((leave, i) =>
          <div
            className={classes.leaves}
            key={i}
            style={{
              top: `${isMobile
                ? calculateStartTop(leave.startHour, leave.startMinute, firstHour)
                : 0}px`,
              left: `${isMobile
                ? '0'
                : calculateStartLeft(leave.startHour, leave.startMinute, firstHour)}px`,
              width: isMobile
                ? '100%'
                : `${(calculateStartLeft(leave.endHour, leave.endMinute, firstHour) - calculateStartLeft(leave.startHour, leave.startMinute, firstHour))}px`,
              height: isMobile
                ? `${(calculateStartTop(leave.endHour, leave.endMinute, firstHour) - calculateStartTop(leave.startHour, leave.startMinute, firstHour))}px`
                : '100%'
            }}
          />
        )}

        {Array.from(Array(totalHours)).map((o, i) => {
          let style = {}
          if (scheduleMode === 'doctor' && highlighGrid(firstHour + i, appointmentType, uiState.clickMenuData?.doctor?.id, doctor?.id)) {
            style.border = '2px solid #ff0039'
            style.background = 'rgba(0, 0, 0, 0.02)'
          } else if (scheduleMode === 'salesRep' && highlighGrid(firstHour + i, appointmentType, uiState.clickMenuData?.salesRep?.id, salesRep?.id)) {
            style.border = '2px solid #ff0039'
            style.background = 'rgba(0, 0, 0, 0.02)'
          }
          return <div
            onClick={(event) => getRowClick(event, firstHour + i, appointmentType, doctor, salesRep)}
            style={style}
            className={classes.hourSlot} key={i}
          />
        })}
      </div>
      {comments.map((comment, i) =>
        <div
          key={i}
          className={classes.comment}
          onClick={(e) => selectComment(e, comment)}
          style={{
            top: `${isMobile
              ? calculateStartTop(comment.startHour, comment.startMinute, firstHour)
              : 0}px`,
            left: `${isMobile
              ? '0'
              : calculateStartLeft(comment.startHour, comment.startMinute, firstHour)}px`,
            width:
              isMobile
                ? '100%'
                : `${(calculateStartLeft(comment.endHour, comment.endMinute, firstHour) - calculateStartLeft(comment.startHour, comment.startMinute, firstHour))}px`,
            height:
              isMobile
                ? `${(calculateStartTop(comment.endHour, comment.endMinute, firstHour) - calculateStartTop(comment.startHour, comment.startMinute, firstHour))}px`
                : '100%'
          }}
        />
      )}
      {appointmentElements}
    </div>
  );
}

ScheduleRow.propTypes = {
  appointments: PropTypes.arrayOf(PropTypes.object.isRequired),
  appointmentType: PropTypes.string,
  doctor: PropTypes.object,
  salesRep: PropTypes.object,
  doctorShifts: PropTypes.arrayOf(PropTypes.object.isRequired),
  doctorLeaves: PropTypes.arrayOf(PropTypes.object.isRequired),
  firstHour: PropTypes.number,
  lastHour: PropTypes.number,
  selectHour: PropTypes.func,
  selectAppointment: PropTypes.func,
  selectComment: PropTypes.func,
  customerMapping: PropTypes.object.isRequired,
  currentTimePosition: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  currentTimeDisplay: PropTypes.string,
  rowHeights: PropTypes.object.isRequired,
  appointmentPosition: PropTypes.object.isRequired,
  comments: PropTypes.arrayOf(PropTypes.object.isRequired),
  isMobile: PropTypes.bool.isRequired,
  scheduleMode: PropTypes.string.isRequired,
};

export default ScheduleRow;
