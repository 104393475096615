import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const StyledStepLabel = styled(StepLabel, {
  shouldForwardProp: (prop) => prop !== 'success',
})(({ success, theme }) => ({
  ...(success && {
    // '& .Mui-completed': { // change label text color
    //   color: `${theme.palette.success.main} !important`,
    // },
    '& .MuiSvgIcon-root.Mui-completed': {
      color: `${theme.palette.success.main} !important`,
    },
  })
}));

export default function ProgressStep({ activeStep, steps }) {
  const [stepDetailText, setStepDetailText] = useState('')

  return (<>
    {stepDetailText &&
      <Dialog onClose={() => setStepDetailText('')} open>
        <Box p={3}>
          <div style={{ whiteSpace: 'pre-line' }}>
            {stepDetailText}
          </div>
        </Box>
      </Dialog>
    }
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((step, idx) => (
        <Step key={`${step.name}_${idx}`} {...step.stepProps}>
          <StyledStepLabel {...step.labelProps} sx={{ whiteSpace: 'pre-line' }}>
            {step.hint ? <Tooltip title={step.hint}><span>{step.text}</span></Tooltip> : step.text}
            {step.detail && <Button color="primary" onClick={() => setStepDetailText(step.detail)}>查看修改內容</Button>}
          </StyledStepLabel>
        </Step>
      ))}
    </Stepper>
  </>);
}

ProgressStep.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    stepProps: PropTypes.object,
    labelProps: PropTypes.object,
    text: PropTypes.string.isRequired,
    detail: PropTypes.string,
  })).isRequired,
};
