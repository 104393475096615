import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { createUseStyles } from 'react-jss';

import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

import StatusIndicator from 'components/StatusIndicator';
import { objectToArray } from 'modules/data';
import { calculateAge } from 'modules/time';
import { firestoreListener } from 'modules/firebase';

const appointmentStatusStrings = [
  'not-confirmed',
  'profile-needed',
  'ready',
  'arrived',
  'late',
  'cancelled',
  'complete',
  'cancelanotherappointment',
  'not-arrived',
]

const useStyles = createUseStyles({
  popover: {
    width: '760px',
    height: '430px',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 12px 0 rgba(130, 210, 220, 0.3)',
    padding: '20px 20px 0 20px',
    overflow: 'hidden'
  },
  wrapper: {
    flexDirection: 'row',
    display: 'flex',
    minHeight: '323px',
    maxHeight: '323px'
  },
  left: {
    marginTop: '-16px',
    flex: '1 0 0',
    paddingRight: '25px',
    minWidth: '50%',
    maxWidth: '50%'
  },
  right: {
    flex: '1 0 0',
    borderLeft: '1px solid #f0f0f0',
    paddingLeft: '17px',
    overflowY: 'scroll',
    '& h3': {
      marginTop: 0,
      color: '#7A7A7A',
      fontSize: '15px'
    }
  },
  statusSelect: {
    height: 'auto !important',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px'
  },
  statusAndRoom: {
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      display: 'inline-flex',
      alignItems: 'center',
      flex: '1 1 0',
    },
    '& div': {
      margin: '0 10px 0 0'
    },
    '& label': {
      color: '#A6A6A6',
      fontSize: '12px',
      flex: 0,
      paddingRight: '10px'
    }
  },
  customerDetails: {
    marginTop: '15px',
  },
  name: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#000000'
  },
  dob: {
    color: '#5D5D5D',
    fontSize: '13px',
    margin: '8px 0'
  },
  people: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  person: {
    color: '#A8A8A8',
    fontSize: '12px'
  },
  personRow: {
    fontSize: '15px',
    color: '#444444'
  },
  status: {
    width: '12px',
    height: '12px',
    background: '#79db0e',
    borderRadius: '12px',
    marginRight: '4px'
  },
  time: {
    fontSize: '13px',
    color: '#3B3B3B'
  },
  buttonsBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '50px',
  },
  buttonsRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttonsLeft: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  menuIndex: {
    zIndex: 2100
  },
});

function StatusLabel({ appointment }) {
  return (
    <div  style={{ whiteSpace: 'nowrap' }}>
      <StatusIndicator status={appointment.status} position={''} />
      <FormattedMessage id={`appointment.popover.customerStatus.${appointment.status}`} />
    </div>
  )
}

function StatusMenu({ appointment, handleChange }) {
  const classes = useStyles();

  return (
    <div className={classes.statusSelect}>
      <FormControl>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          variant="standard"
          value={appointment.status}
          onChange={(e) => handleChange(appointment, 'status', e.target.value)}
          disableUnderline
        >
          {appointmentStatusStrings.map((status, i) => {
            return <MenuItem key={status} value={status} >
              <StatusIndicator status={status} position={''} />
              <FormattedMessage id={`appointment.popover.customerStatus.${status}`} />
            </MenuItem >
          })}
        </Select>
      </FormControl>
    </div>
  );
}

StatusMenu.propTypes = {
  appointment: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

function TreatmentsList({ treatment }) {
  const rowStyle = {
    display: 'flex',
    flexDirection: 'row',
    userSelect: 'none',
    zIndex: 11,
    minHeight: '40px',
  }

  const mainWrap = {
    width: '100%'
  }

  const itemColor = {
    margin: '1px 0',
    width: '10px',
    minHeight: '100%',
    display: 'inline-flex',
    borderRadius: '5px',
    alignSelf: 'stretch'
  }

  const itemMain = {
    minHeight: '40px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    userSelect: 'none',
  }

  const itemName = {
    flex: 1,
    padding: '0 10px',
    fontSize: '15px',
    color: '#414141'
  }

  const itemDuration = {
    padding: '0 20px',
    fontSize: '15px',
    color: '#757575'
  }

  return (
    <div style={rowStyle}>
      <div style={{ ...itemColor, backgroundColor: treatment.color }} />
      <div style={mainWrap}>
        <div style={itemMain}>
          <div style={itemName}>
            {treatment.name}
          </div>
          <div style={itemDuration}>
            {treatment.duration} <FormattedMessage id={'appointment.sidebar.minutesShort'} />
          </div>
        </div>
      </div>
    </div>
  );
}

TreatmentsList.propTypes = {
  treatment: PropTypes.object.isRequired,
};

function AppointmentPopover({ ui, handleAppointmentChange, handlePopoverClose, onEditAppointment }) {
  const classes = useStyles();
  const appointment = { ...ui.selectedAppointment }
  const menuOpen = Boolean(appointment);
  const userMapping = useSelector(state => state.users.data);
  const userRights = useSelector(state => state.userRights)
  const config = useSelector(state => state.config.data)
  const roomMapping = Object.keys(config.rooms || {}).reduce((acc, cur) => {acc[config.rooms[cur].id] = config.rooms[cur]; return acc}, {})
  const [customer, setCustomer] = useState(null)
  const naString = <FormattedMessage id='appointment.popover.NA' />
  const doctor = userMapping[appointment.doctor] || null
  const nurse = userMapping[appointment.nurse] || null
  const salesRep = userMapping[appointment.salesRep] || null
  const treatments = objectToArray(appointment.treatments, 'order').filter(p => !p.parent)
  const totalDuration = treatments.reduce((acc, treatment) => (
    acc + parseInt(treatment.duration || 0)
  ), 0)
  let endTime = dayjs(`${appointment.date} ${appointment.time}`).add(totalDuration, 'minute').format('HH:mm')

  useEffect(() => {
    const unsubscribe = appointment.customer ?
      firestoreListener({
        collection: 'customers',
        doc: appointment.customer,
        onData: (data) => {
          setCustomer(data)
        }
      }) : null

    return () => unsubscribe?.()
  }, [appointment.customer]);

  return (
    <div>
      <Popover
        id="menu-appbar"
        anchorEl={ui.selectedAppointmentEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handlePopoverClose}
      >
        <div className={classes.popover}>
          <div className={classes.wrapper}>
            <div className={classes.left}>
              <div className={classes.statusAndRoom}>
                <div>
                  <label><FormattedMessage id='appointment.popover.customerStatus.root' /></label>
                  {!userRights.hasUserRightForVendor('appointment-edit', appointment.source) && <StatusLabel appointment={appointment} />}
                  {userRights.hasUserRightForVendor('appointment-edit', appointment.source) && <StatusMenu appointment={appointment} handleChange={handleAppointmentChange} />}
                </div>
                <div style={{ fontSize: '14px' }}>
                  <label><FormattedMessage id='appointment.popover.room' /></label>
                  <div>{roomMapping[appointment.room] ? roomMapping[appointment.room].name : <FormattedMessage id='appointment.noRoom' />}</div>
                </div>
              </div>
              <div className={classes.customerDetails}>
                <div className={classes.name}>{customer ? customer?.name : '未選擇客戶'}{customer && customer.customercode ? customer.customercode : ''}</div>
                <div className={classes.dob}>
                  {(customer && customer.gender) && <FormattedMessage id={`appointment.popover.gender.${customer.gender}`} />}
                  {customer && calculateAge(customer.birthDate)} {customer && <FormattedMessage id={'appointment.popover.yearsOld'} />}
                  {(customer && customer.birthDate) && <span><FormattedMessage id={'appointment.popover.birth'} /> : {customer.birthDate || naString}</span>}
                </div>
                <div className={classes.dob}>
                  {(customer && customer.phone) && <span><FormattedMessage id={'appointment.popover.phone'} />: {customer.phone || naString}</span>}
                </div>
                <div className={classes.dob}><FormattedMessage id={'appointment.popover.createdAt'} />: {dayjs(appointment.createdAt).format('YYYY-MM-DD HH:mm')}
                </div>
              </div>
              <div className={classes.people}>
                <div className={classes.person}>
                  <FormattedMessage id='appointment.popover.doctor' />{doctor ? <div className={classes.personRow}>{doctor.displayName}</div> : <div>{naString}</div>}
                </div>
                <div className={classes.person}>
                  <FormattedMessage id='appointment.popover.nurse' /> {nurse ? <div className={classes.personRow}>{nurse.displayName}</div> : <div>{naString}</div>}
                </div>
                <div className={classes.person}>
                  <FormattedMessage id='appointment.popover.salesRep' /> {salesRep ? <div className={classes.personRow}>{salesRep.displayName}</div> : <div>{naString}</div>}
                </div>
              </div>
              <TextField
                fullWidth
                disabled
                style={{
                  marginTop: '10px'
                }}
                label={<FormattedMessage id='appointment.popover.comment' />}
                variant="outlined"
                value={appointment.comment}
                multiline
                minrows={5}
              />
            </div>
            <div className={classes.right}>
              <h3><FormattedMessage id='appointment.popover.arrangeTreatments' /> </h3>
              <div className={classes.time}>{appointment.time}</div>
              <div style={{ margin: '20px 0' }}>{treatments.map((treatment, i) => <TreatmentsList key={i} treatment={treatment} />)}</div>
              <div className={classes.time}>{endTime}</div>
            </div>
          </div>
          {userRights.hasUserRightForVendor('appointment-edit', appointment.source) && <div className={classes.buttonsBody}>
            <div className={classes.buttonsRight}>
              <Button
                sx={{ lineHeight: 1, }}
                color='primary'
                variant='contained'
                onClick={() => onEditAppointment(appointment, 'edit')}
                autoFocus
              >
                <FormattedMessage id={'appointment.popover.button.edit'} />
              </Button>
            </div>
          </div>}
        </div>
      </Popover>
    </div>
  );
}

AppointmentPopover.propTypes = {
  ui: PropTypes.object.isRequired,
  handleAppointmentChange: PropTypes.func.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  handleDialogOpen: PropTypes.func.isRequired,
  onEditAppointment: PropTypes.func.isRequired,
};

export default AppointmentPopover;
