import React, { useState, useEffect, useContext } from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
// import Dialog from '@mui/material/Dialog';

import EnhancedTable from 'components/EnhancedTable';
import DatePickerField from 'components/DatePickerField';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import { unwrap } from 'modules/uitls';
import ContextStore from 'modules/context';
import { addError, removeError } from 'modules/editor';
import { callFunction } from 'modules/firebase';
import { firebaseV8 } from 'modules/firebaseV8';
import StockLogDetails from './StockLogDetails';

function EditInventoryCheckPage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const navigate = useNavigate()
  const location = useLocation()
  const { inventoryCheckId } = useParams()
  const checkpoint = useSelector(state => unwrap(state.config.data?.checkpoint || {}))

  const customerMapping = useSelector(state => state.internalVendors.data)
  const customers = useSelector(state => state.internalVendors.ordered)
  const filteredCustomers = customers.filter(c => userRights.hasUserRightForVendor('inventoryCheck-create', c.id))
  const merchandiseMapping = useSelector(state => state.merchandises.data)
  const merchandises = useSelector(state => state.merchandises.ordered)
  const userMapping = useSelector(state => state.users.data)

  const [loading, setLoading] = useState(false)
  const [loadingInventory, setLoadingInventory] = useState(false)
  const [lockSource, setLockSource] = useState(inventoryCheckId !== 'new')
  const [customerId, setCustomerId] = useState('')
  const [logs, setLogs] = useState(null);
  const [dailySnapshot, setDailySnapshot] = useState(null);

  const [stockLogs, setStockLogs] = useState(null);
  const [modification, setModification] = useState(false);

  const [dateError, setDateError] = useState({})

  const handleDateChange = (date) => {
    updateInventoryCheckData({ name: 'baseDate' }, dayjs(date).format('YYYY-MM-DD'))
  };

  const [rawInventoryCheck, setRawInventoryCheck] = useState({
    id: inventoryCheckId,
    source: '',
    createdBy: currentUser.key,
    date: dayjs().format('YYYY-MM-DD'),
    // baseDateTime: dayjs().format('YYYY-MM-DD HH:mm'),
    baseDate: dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
    note: '',
    // merchandises: [],
  });

  const [owInventoryCheck, setOwInventoryCheck] = useState({});
  let inventoryCheck = {}

  useEffect(() => {
    const today = dayjs().format('YYYY-MM-DD')
    const unsubscribe = customerId !== '' ? firebaseV8().collection('wh').doc(customerId).collection('logs').where('date', '==', today)
      .onSnapshot( snapshot => {
        const logs = []
        snapshot.forEach(doc => {
          const data = doc.data()
          if (data.valid !== false) {
            logs.push(data)
          }
        });
        setLogs(logs)
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, [customerId]);

  useEffect(() => {
    const yesterday = dayjs().subtract(1, 'days').format('YYYY-MM-DD')
    const unsubscribe = customerId !== '' ? firebaseV8().collection('wh').doc(customerId).collection('dailySnapshot').doc(yesterday)
      .onSnapshot( snapshot => {
        setDailySnapshot(snapshot?.data()?.extData || {})
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, [customerId]);

  useEffect(() => {
    if (filteredCustomers.length === 1 && inventoryCheckId === 'new') {
      updateInventoryCheckData({ name: 'source' }, filteredCustomers[0].id);
    }
  }, [filteredCustomers.length]);

  useEffect(() => {
    const breadcrumbs = [{
      link: '/stock/inventoryCheck/processing',
      text: formatMessage({ id: 'sideMenu.stock.inventoryCheck' })
    }]
    if (inventoryCheckId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'inventoryCheck.dialog.title.add' }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: 'inventoryCheck.dialog.title.edit' }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    if (inventoryCheckId !== 'new') { // edit
      const unsubscribe = firebaseV8().collection('inventoryChecks').doc(inventoryCheckId)
        .onSnapshot(snapshot => {
          const data = unwrap(snapshot.data())
          if (data.lock && data.status === 'done') {
            navigate('/stock/inventoryChanges');
          } else {
            setCustomerId(data.source)
            const raw = { id: snapshot.id, ...data }
            setRawInventoryCheck(raw)
            //把上次盤點的結果讀出來, call loadInventory 時傳入, 以保留盤點結果
            const amountMapping = {}
            const mIds = Object.keys(data.merchandises)
            for (const mId of mIds) {
              amountMapping[mId] = data.merchandises[mId].currentAmount
              // for (const item of data.merchandises[mId].items) {
              // amountMapping[item.id] = item.currentAmount
              // }
            }
            loadInventory(data.source, data.baseDate, amountMapping, raw)
          }
        }, err => {})
      return () => unsubscribe()
    } else {
      return () => {};
    }
  }, []);

  if (inventoryCheckId !== 'new' && !rawInventoryCheck.merchandises) {
    return ('Loading...')
  }

  function getRealtimeStock(l, ds) {
    const merchandiseDailySnapshot = merchandises.reduce((acc, cur) => {
      if (!acc[cur.id]) acc[cur.id] = 0
      return acc
    }, ds ? { ...ds } : {})

    return (l || []).reduce((acc, cur) => {
      if (acc[cur.merchandiseId] === undefined) return acc
      acc[cur.merchandiseId] += cur.quantity
      return acc
    }, { ...merchandiseDailySnapshot })
  }
  // newData.stock = `${realtimeStock[merchandise.id]}${merchandise.sku}`

  const realtimeStock = getRealtimeStock(logs, dailySnapshot)

  const merchandiseKeys = Object.keys(rawInventoryCheck.merchandises || {}).filter(m => merchandiseMapping[m])
  inventoryCheck = { ...{
    id: inventoryCheckId,
    source: rawInventoryCheck.source,
    createdBy: rawInventoryCheck.createdBy,
    date: rawInventoryCheck.date,
    baseDate: rawInventoryCheck.baseDate,
    note: rawInventoryCheck.note,
    status: rawInventoryCheck.status,
    // merchandises: [],
    merchandises: merchandiseKeys.map(m => merchandiseMapping[m]).map(m => ({
      code: m.code,
      // orderUnit: m.orderUnit,
      name: m.name,
      nickname: m.nickname,
      id: m.id,
      checkAmount: m.checkAmount || 0,
      expectedAmount: m.expectedAmount || 0,
      currentAmount: m.currentAmount || 0,
      note: rawInventoryCheck.merchandises[m.id].note,
      // items: rawInventoryCheck.merchandises[m.id].items,
    }))
  }, ...owInventoryCheck }

  const disableSubmitButton = !(inventoryCheck.source !== '' && userRights.hasUserRightForVendor('requisition-create', inventoryCheck.source))

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    { text: 'amount', align: 'right' },
    { text: 'stock', align: 'right' },
    { text: 'checkAmount', align: 'right' },
    { text: 'balance', align: 'right' },
    { text: 'note', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `inventoryCheck.merchandise.${c.text}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    { field: 'amount', align: 'right', type: 'info', onButtonClick: showStockLogs },
    { field: 'stock', align: 'right', type: 'info', onButtonClick: showStockLogs },
    { field: 'checkAmount', type: 'input-number', required: true, label: '盤點數量', onValueChanged: onCellValueChanged },
    { field: 'balance', align: 'right', textColor: true },
    { field: 'note', align: 'right', type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged },
  ]

  function showStockLogs(merchandiseId, field) {
    if (field === 'stock') {
      const data = {
        merchandiseId,
        customerId,
        startDateTime: dayjs(inventoryCheck.baseDate).add(1, 'days').format('YYYY-MM-DD') + ' 00:00:00',
        // endDateTime: inventoryCheck.date + ' 23:59:59', // 只撈到建單日期可能會有問題.
        endDateTime: dayjs().format('YYYY-MM-DD') + ' 23:59:59', // 改成撈到今天.
      }
      if (inventoryCheckId !== 'new') {
        data.ignoreCheckpoint = inventoryCheckId
      }
      setStockLogs(data)
    } else if (field === 'amount') {
      const data = {
        merchandiseId,
        customerId,
        startDateTime: `${(checkpoint[inventoryCheck.source] || '2019-12-31')} 23:59:59`,
        endDateTime: dayjs(inventoryCheck.baseDate).format('YYYY-MM-DD') + ' 23:59:59',
      }
      if (inventoryCheckId !== 'new') {
        data.ignoreCheckpoint = inventoryCheckId
      }
      setStockLogs(data)
    }
  }

  function onCellValueChanged(data, field, value) {
    for (let m of inventoryCheck.merchandises) {
      if (m.id === data.id) {
        m[field] = value
        if (field === 'checkAmount') {
          if (isNaN(value) || value === '') {
            addError(m, field, '數量錯誤')
          } else {
            removeError(m, field)
          }
        }
        if (!modification) {
          setModification(true)
        }
        break
      }
    }
    updateInventoryCheckData({ name: 'merchandises' }, inventoryCheck.merchandises);
  }

  function validateField(field, value) {
    // if (field.required && value === '') {
    //   return formatMessage({id: 'form.field.isRequired'})
    // }
    if (field.name === 'merchandises') {
      for (const m of value) {
        if (m.errors && m.errors.amount) {
          return m.errors.amount
        }
      }
    } else if (field.name === 'baseDate') {
      if (value === null) {
        return formatMessage({ id: 'form.field.isRequired' })
      } else if (value.toString() === 'Invalid Date') {
        return formatMessage({ id: 'form.date.formatError' })
      } else {
        if (dayjs(value).format('YYYY-MM-DD') > dayjs().format('YYYY-MM-DD')) {
          return formatMessage({ id: 'form.date.afterToday' })
        }
      }
    }
    return ''
  }

  function updateInventoryCheckData(field, value) {
    let newValue = value
    let err = validateField(field, value)

    if (field.name === 'baseDate') {
      if (err) {
        setDateError({ error: true, helperText: err })
      } else {
        setDateError({})
      }
    }

    let newData = { ...inventoryCheck, [field.name]: newValue, [`${field.name}_err`]: err }
    setOwInventoryCheck(newData)
  }

  async function handleSave() {
    setLoading(true);

    const fields = [
      { name: 'source' },
      // {name: 'createdBy'},
      { name: 'date' },
      { name: 'baseDate' },
      { name: 'merchandises' },
      { name: 'note' },
    ]

    let newData = inventoryCheck
    for (let field of fields) {
      if (field.name === 'merchandises') {
        for (const merchandise of newData.merchandises) {
          if (merchandise.errors && Object.keys(merchandise.errors).length) {
            setLoading(false);
            return
          }
        }
      } else if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        setLoading(false);
        return
      }
    }

    let data = {}
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.merchandises = data.merchandises.reduce((acc, cur) => {
      acc[cur.id] = {
        expectedAmount: cur.expectedAmount,
        currentAmount: parseInt(cur.checkAmount),
        note: cur.note
      };
      return acc;
    }, {})
    data.baseDate = newData.baseDate
    setModification(false)

    try {
      await callFunction('saveInventoryCheck', { id: inventoryCheck.id, ...data })
      if (inventoryCheckId === 'new') {
        handleClose()
      } else {
        setLoading(false);
      }
    } catch (ex) {
      console.log(ex)
    }
    // handleClose() // 儲存後不要立刻關閉.
  }

  function handleClose() {
    if (!modification || window.confirm('有更動尚未儲存，確定關閉盤點單嗎？')) {
      if (inventoryCheck.status === 'done') {
        navigate('/stock/inventoryChanges');
      } else {
        navigate('/stock/inventoryCheck/processing');
      }
    }
  }

  function formatData(merchandise) {
    const m = merchandiseMapping[merchandise.id]
    const newData = { ...merchandise }
    newData.amount = `${merchandise.expectedAmount}${m.sku}`
    newData.stock = realtimeStock[merchandise.id] + m.sku
    const balance = newData.checkAmount - merchandise.expectedAmount
    newData.balance = balance > 0 ? `+${balance}` : `${balance}`
    newData.textColor = newData.balance !== '0' ? '#fe2851' : '#000000'
    return newData
  }

  function loadInventory(customer, baseDate, amountMapping, raw) {
    firebaseV8().collection('wh').doc(customer).collection('dailySnapshot').doc(baseDate)
      .get().then(snapshot => {
        const bds = snapshot?.data()?.extData || {}
        const merchandises = []
        const merchandiseIds = Object.keys(bds)
        for (const id of merchandiseIds) {
          const merchandise = merchandiseMapping[id]
          // amountMapping ? amountMapping[doc.id] : amount
          merchandises.push({
            code: merchandise.code,
            unit: merchandise.sku,
            name: merchandise.name,
            nickname: merchandise.nickname,
            id: id,
            note: inventoryCheckId === 'new' ? '' : raw.merchandises[id].note,
            currentAmount: bds[id],
            expectedAmount: bds[id],
            checkAmount: amountMapping ? amountMapping[id] : bds[id],
          })
        }
        setLoadingInventory(false)
        updateInventoryCheckData({ name: 'merchandises' }, merchandises)

      }, err => {
        console.log(err)
      })
  }

  function getToolbox() {
    return inventoryCheck.merchandises.length === 0 ? (
      <LoadingButton
        sx={{ m: 1, whiteSpace: 'nowrap' }}
        color="primary"
        onClick={() => {
          setLockSource(true)
          setCustomerId(inventoryCheck.source)
          setLoadingInventory(true)
          loadInventory(inventoryCheck.source, inventoryCheck.baseDate)
        }}
        disabled={inventoryCheck.source === '' || !!inventoryCheck.baseDate_err || loadingInventory}
        loading={loadingInventory}
        loadingPosition="start"
        loadingIndicator={<CircularProgress size={24} />}
        startIcon={<ArchiveIcon />}
        variant="contained"
      >
        <FormattedMessage id="editInventoryCheck.loadInventory" />
      </LoadingButton>
    ) : null
  }

  function getSourceSelector() {
    if (userRights.onlyVendor('inventoryCheck-create') || inventoryCheckId !== 'new') {
      const value = inventoryCheckId !== 'new' ?
        (customerMapping[inventoryCheck.source].nickname ? customerMapping[inventoryCheck.source].nickname : '') :
        (customerMapping[userRights.onlyVendor('inventoryCheck-create')] ? customerMapping[userRights.onlyVendor('inventoryCheck-create')].nickname : '')

      return (<TextField
        disabled
        type="text"
        label={formatMessage({ id: 'inventoryCheck.table.detail.source' })}
        variant="outlined"
        value={value}
        fullWidth
        size="small"
      />)
    } else {
      return (<TextField
        select
        required
        disabled={lockSource}
        type="text"
        label={formatMessage({ id: 'inventoryCheck.table.detail.source' })}
        variant="outlined"
        value={inventoryCheck.source}
        onChange={e => updateInventoryCheckData({ name: 'source' }, e.target.value)}
        fullWidth
        size="small"
        error={inventoryCheck.source_err ? true : false}
        helperText={inventoryCheck.source_err}
      >
        {filteredCustomers.map(c => <MenuItem key={c.id} value={c.id}>
          {c.nickname}
        </MenuItem>)}
      </TextField>)
    }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {stockLogs &&
        <StockLogDetails info={stockLogs} onClose={() => setStockLogs(null)} />
      }
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            {getSourceSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'inventoryCheck.table.detail.createdBy' })}
              variant="outlined"
              value={userMapping[inventoryCheck.createdBy] ? userMapping[inventoryCheck.createdBy].displayName : ''}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'inventoryCheck.table.detail.date' })}
              variant="outlined"
              value={inventoryCheck.date}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePickerField
              required
              fullWidth
              disabled={lockSource}
              label={formatMessage({ id: 'inventoryCheck.table.detail.baseDate' })}
              value={dayjs(inventoryCheck.baseDate)}
              onChange={handleDateChange}
              {...dateError}
              minDate={inventoryCheck.source && checkpoint[inventoryCheck.source] ? dayjs(checkpoint[inventoryCheck.source]).add(1, 'days').toDate() : null}
              minDateMessage={formatMessage({ id: 'form.date.beforeLastCheckpoint' })}
              maxDate={dayjs().subtract(1, 'days').toDate()}
              maxDateMessage={formatMessage({ id: 'form.date.afterYesterday' })}
              invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: '8px 0px' }} />
        <EnhancedTableToolbar
          title="editInventoryCheck.table.title"
          selectdMessage="editInventoryCheck.table.selected"
          numSelected={0}
          toolbox={getToolbox()}
          // toolboxForSelection={getToolboxForSelection()}
        />
        {inventoryCheck.merchandises.length > 0 && <EnhancedTable
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={headerCells}
          rowCells={rowCells}
          tableData={inventoryCheck.merchandises.map(m => formatData(m))}
        />}

        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'inventoryCheck.table.detail.note' })}
              variant="outlined"
              value={inventoryCheck.note}
              onChange={e => updateInventoryCheckData({ name: 'note' }, e.target.value)}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id={modification || inventoryCheckId === 'new' ? 'button.cancel' : 'button.close'} />
          </Button>
          {inventoryCheck.status !== 'done' &&
            <LoadingButton
              color="primary"
              onClick={handleSave}
              disabled={disableSubmitButton || loading || !lockSource || inventoryCheck.merchandises.length === 0}
              loading={loading}
              loadingPosition="start"
              loadingIndicator={<CircularProgress size={24} />}
              startIcon={<div />}
              variant="contained"
            >
              <FormattedMessage id="button.save" />
            </LoadingButton>
          }
          {inventoryCheck.status === 'done' &&
            <LoadingButton
              color="success"
              onClick={handleSave}
              disabled={disableSubmitButton || loading}
              loading={loading}
              loadingPosition="start"
              loadingIndicator={<CircularProgress size={24} />}
              startIcon={<div />}
              variant="contained"
            >
              <FormattedMessage id="button.submit" />
            </LoadingButton>
          }
        </Stack>
      </Box>
    </div>
  );
}

export default EditInventoryCheckPage;
