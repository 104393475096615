import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

import EnhancedTable from 'components/EnhancedTable';

function TransformServiceView({ form, showExtData }) {
  const { formatMessage } = useIntl()
  const productMapping = useSelector(state => state.products.data)
  const services = Object.keys(form.services).map(k => ({ ...form.services[k], id: k }))
  const selectPP = [{
    id: form.id,
    name: form.productName,
    amount: form.amount,
  }]

  const ppHeaderCells = [
    { text: 'name' },
    { text: 'amount' },
  ].map(c => { c.text = formatMessage({ id: `transformService.service.${c.text}` }); return c })

  const ppRowCells = [
    { field: 'name' },
    { field: 'amount' },
  ]

  const productHeaderCells = [
    { text: 'name' },
    { text: 'amount' },
    { text: 'note' },
  ].map(c => { c.text = formatMessage({ id: `transformService.service.${c.text}` }); return c })

  const productRowCells = [
    { field: 'name' },
    { field: 'amount' },
    { field: 'note' },
  ]

  function formatData(product) {
    const newData = { ...product }
    newData.name = productMapping[product.productId].name
    return newData
  }

  const paymentType = form.paymentType && form.paymentType === 'storedValue' ?
    productMapping[form.storedValueCard].name : ''

  return (
    <div style={{ width: '100%', }}>
      <Box p={0}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 500 }} component="div">
                原服務:
              </Typography>
            </div>

            <EnhancedTable
              size="small"
              headerCells={ppHeaderCells}
              rowCells={ppRowCells}
              tableData={selectPP}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'center', mt: '10px' }}>
            <KeyboardDoubleArrowDownIcon />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 500 }} component="div">
                轉換為:
              </Typography>
            </div>

            <EnhancedTable
              size="small"
              headerCells={productHeaderCells}
              rowCells={productRowCells}
              tableData={services.map(i => formatData(i))}
            />
          </Grid>
          {showExtData && <>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                type="text"
                label={formatMessage({ id: 'transformService.table.detail.difference.root' })}
                value={formatMessage({ id: `transformService.table.detail.difference.${form.difference}` })}
                multiline
                fullWidth
                size="small"
                variant="standard"
                readOnly
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                type="text"
                label={formatMessage({ id: `transformService.table.detail.paymentType.${form.difference}` })}
                value={paymentType}
                multiline
                fullWidth
                size="small"
                variant="standard"
                readOnly
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                type="text"
                label={formatMessage({ id: 'transformService.table.detail.differencePrice' })}
                value={form.differencePrice}
                multiline
                fullWidth
                size="small"
                variant="standard"
                readOnly
              />
            </Grid>
          </>}
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'transformService.table.detail.note' })}
              value={form.note}
              multiline
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

TransformServiceView.propTypes = {
  form: PropTypes.object.isRequired,
};

export default TransformServiceView