import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';

import SelectDepartmentDialog from 'components/SelectDepartmentDialog';
import ContextStore from 'modules/context';
import { callFunction } from 'modules/firebase';
import { useDepartments } from 'hooks/departments';

function EditSalesRepsPage() {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const location = useLocation()
  const pathname = location.pathname
  const config = useSelector(state => state.config.data)
  const [currentData, setCurrentData] = useState(config.salesReps || {})
  const [dialogData, setDialogData] = useState(null);
  const [loading, setLoading] = useState(false)
  const departments = useDepartments()
  const departmentMapping = departments.reduce((acc, cur) => {
    acc[cur.id] = cur
    return acc
  }, {})

  const fields = [
    { name: 'departments', required: true, selectFields: departments },
  ]

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.setting.root' })
    }])
  }, [pathname]);

  function createField(field) {
    return (
      <Grid item key={field.name} xs={12} sm={12} md={12}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={formatMessage({ id: 'editDataType.salesReps.table.detail.departments' })}
          value={(currentData[field.name] || []).filter(i => departmentMapping[i]).map(i => departmentMapping[i].name)}
          fullWidth
          size="small"
          variant="outlined"
          onClick={(e) => {
            setDialogData({ index: field, departments: currentData.departments })
          }}
        />
      </Grid>
    );
  }

  function onDepartmentsChanged(selectedItems) {
    const departments = selectedItems.map(i => i.id)
    setCurrentData({ departments: departments })
  }

  async function handleSave() {
    setLoading(true)

    try {
      await callFunction('saveConfigurations', { type: 'salesReps', departments: currentData.departments })
    } catch (ex) {
      console.log(ex)
    }
    handleClose()
  }

  function handleClose() {
    navigate('/setting/appointment/salesReps');
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {dialogData && <SelectDepartmentDialog
        multiSelect
        headerCells={[{ name: 'name' }]}
        rowCells={[{ field: 'name' }]}
        filterItems={[{ name: 'name' }]}
        dialogTitle={formatMessage({ id: 'selectDepartmentDialog.title' })}
        tableTitle={formatMessage({ id: 'selectDepartmentDialog.table.title' })}
        handleClose={() => setDialogData(null)}
        handleSave={onDepartmentsChanged}
        defaultSelectedItems={dialogData.departments ?? []}
        items={departments.filter(u => u.active)}
        // params={String(ialogData.index)}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <div style={{ height: '52px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography sx={{ flex: '1 1 20%' }} variant="h6" id="tableTitle" component="div">
            <FormattedMessage id={'editDataType.salesReps.table.title'} />
          </Typography>
        </div>
        <Paper>
          <Box p={3} style={{ marginTop: '20px' }}>
            <Grid container spacing={2}>
              {fields.map(field => createField(field))}
            </Grid>
          </Box>
        </Paper>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={loading}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.save" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}

export default EditSalesRepsPage;
