import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(duration)

const anyIntersection = (array1, array2) => {
  for (const i of array1) {
    if (array2.includes(i)) return true
  }
  return false
}

const getIntersection = (array1, array2) => {
  const intersection = []
  for (const i of array1) {
    if (array2.includes(i)) intersection.push(i)
  }
  return intersection
}

const maxTime = (date, timeA, timeB) => {
  if (compareTime(date, timeA, timeB) === 1) return timeA;
  return timeB;
}

const compareTime = (date, timeA, timeB) => {
  if (timeA === '-' && timeB === '-') {
    return 0;
  } else if (timeA === '-' && timeB !== '-') {
    return -1;
  } else if (timeA !== '-' && timeB === '-') {
    return 1;
  } else if (timeA !== '-' && timeB !== '-') {
    const timeAM = dayjs(`${date} ${timeA}`, 'YYYY-MM-DD HH:mm');
    const timeBM = dayjs(`${date} ${timeB}`, 'YYYY-MM-DD HH:mm');
    if (timeAM.isBefore(timeBM)) {
      return -1;
    } else if (timeAM.isAfter(timeBM)) {
      return 1;
    } else {
      return 0;
    }
  }
}

const mergeTimeArray = (timeArray) => {
  let arr = timeArray.map(t => Object.assign({}, t));
  if (arr.length < 2) {
    return arr;
  }

  for (let i = 1; i < arr.length; ++i) {
    let date = arr[i].date;
    const endTimeA = arr[i - 1].endTimeS || arr[i - 1].endTime;
    const startTimeB = arr[i].startTimeS || arr[i].startTime;

    let result = compareTime(date, endTimeA, startTimeB);
    if (result === 0 || result === 1) {
      const endTimeB = arr[i].endTimeS || arr[i].endTime;
      const newEndTime = maxTime(date, endTimeA, endTimeB);
      if (newEndTime === '-') {
        arr[i - 1].endTimeS = '-';
        delete arr[i - 1].endTime;
      } else {
        arr[i - 1].endTime = newEndTime;
        if (arr[i - 1].endTimeM) arr[i - 1].endTimeM = dayjs(`${date} ${newEndTime}`, 'YYYY-MM-DD HH:mm');
      }
      arr.splice(i, 1);
      --i;
    }
  }
  return arr;
}

const splitShiftByLeaves = (shift, leaves) => {
  if (!leaves || leaves.length === 0) return [shift];
  let shifts = [];

  for (let tmpLeave of leaves) {
    let date = tmpLeave.startDate || tmpLeave.date;
    let tmpStart = tmpLeave.startTimeS || tmpLeave.startTime;
    let tmpEnd = tmpLeave.endTimeS || tmpLeave.endTime;
    if (tmpStart === '-' && tmpEnd === '-') {
      return [];
    } else if (tmpStart === '-') {
      let tmpEndM = dayjs(`${date} ${tmpEnd}`, 'YYYY-MM-DD HH:mm');
      if (tmpEndM.isSameOrAfter(shift.endTimeM)) {
        return shifts;
      } else if (tmpEndM.isSameOrBefore(shift.startTimeM)) {
        continue;
      } else {
        shift.startTime = tmpEnd;
        shift.startTimeM = dayjs(`${date} ${tmpEnd}`, 'YYYY-MM-DD HH:mm');
        continue;
      }
    } else if (tmpEnd === '-') {
      let tmpStartM = dayjs(`${date} ${tmpStart}`, 'YYYY-MM-DD HH:mm');
      if (tmpStartM.isSameOrBefore(shift.startTimeM)) {
        return shifts;
      } else if (tmpStartM.isSameOrAfter(shift.endTimeM)) {
        continue;
      } else {
        shift.endTime = tmpStart;
        shift.endTimeM = dayjs(`${date} ${tmpStart}`, 'YYYY-MM-DD HH:mm');
        continue;
      }
    } else {
      let tmpStartM = dayjs(`${date} ${tmpStart}`, 'YYYY-MM-DD HH:mm');
      let tmpEndM = dayjs(`${date} ${tmpEnd}`, 'YYYY-MM-DD HH:mm');
      if (tmpStartM.isSameOrBefore(shift.startTimeM) && tmpEndM.isSameOrAfter(shift.endTimeM)) {
        // if (!tmpLeave.type) {
        //   continue;
        // } else {
        return [];
        // }
      } else if (tmpStartM.isSameOrBefore(shift.startTimeM) && tmpEndM.isSameOrBefore(shift.startTimeM)) {
        continue;
      } else if (tmpStartM.isSameOrAfter(shift.endTimeM) && tmpEndM.isSameOrAfter(shift.endTimeM)) {
        continue;
      } else if (tmpStartM.isSameOrBefore(shift.startTimeM)) {
        shift.startTime = tmpEnd;
        shift.startTimeM = dayjs(`${date} ${tmpEnd}`, 'YYYY-MM-DD HH:mm');
        continue;
      } else if (tmpEndM.isSameOrAfter(shift.endTimeM)) {
        shift.endTime = tmpStart;
        shift.endTimeM = dayjs(`${date} ${tmpStart}`, 'YYYY-MM-DD HH:mm');
        continue;
      } else {
        shifts.push({
          startTime: shift.startTime,
          endTime: tmpStart,
          startTimeM: shift.startTimeM,
          endTimeM: tmpStartM
        });
        shift.startTime = tmpEnd;
        shift.startTimeM = tmpEndM;
      }
    }
  }
  if (shift.startTime !== shift.endTime) {
    shifts.push(shift);
  }
  return shifts;
}

const findNextPunchIn = (punchClock, date, time) => {
  if (punchClock.length === 0) {
    return {
      punchInTime: '-',
      punchInError: true
    }
  }
  let punchInTime = punchClock[0];
  if (time === '-') {
    return {
      punchInTime: '-',
      punchInError: false
    }
  }
  let timeM = dayjs(date.format('YYYY-MM-DD') + ' ' + time, 'YYYY-MM-DD HH:mm');
  let punchInTimeM = dayjs(date.format('YYYY-MM-DD') + ' ' + punchInTime, 'YYYY-MM-DD HH:mm');
  if (punchInTimeM.isAfter(timeM)) {
    return {
      punchInTime,
      punchInError: true,
    }
  } else {
    punchClock.shift();
    return {
      punchInTime,
      punchInError: false,
    }
  }
}

const findNextPunchOut = (punchClock, date, time, last, checkNext) => {
  if (punchClock.length === 0) {
    return {
      punchOutTime: '-',
      punchOutError: true
    }
  }
  if (time === '-') {
    return {
      punchOutTime: '-',
      punchOutError: false
    }
  }
  let punchClockTemp = punchClock.concat();
  let timeM = dayjs(date.format('YYYY-MM-DD') + ' ' + time, 'YYYY-MM-DD HH:mm');
  if (last) {
    let punchOutTime = punchClock[punchClock.length - 1];
    let punchOutTimeM = dayjs(date.format('YYYY-MM-DD') + ' ' + punchOutTime, 'YYYY-MM-DD HH:mm');
    return {
      punchOutTime,
      punchOutError: punchOutTimeM.isBefore(timeM),
    }
  } else {
    do {
      let punchOutTime = punchClockTemp.shift();
      let punchOutTimeM = dayjs(date.format('YYYY-MM-DD') + ' ' + punchOutTime, 'YYYY-MM-DD HH:mm');
      if (punchOutTimeM.isSameOrAfter(timeM)) {
        if (checkNext && punchClockTemp.length) {
          let nextTimeM = timeM.add(3, 'minute');
          if ((dayjs(date.format('YYYY-MM-DD') + ' ' + punchClockTemp[0], 'YYYY-MM-DD HH:mm')).isBefore(nextTimeM)) {
            punchClockTemp.shift();
          }
        }
        return {
          punchOutTime: punchOutTime,
          punchOutError: false,
          nextPunchClock: punchClockTemp
        }
      }
    } while (punchClockTemp.length)
    return {
      punchOutTime: punchClock[punchClock.length - 1],
      punchOutError: true,
    }
  }
}

const sortTimeArray = (timeArray) => {
  return timeArray.sort((a, b) => {
    const startTimeA = a.startTimeS || a.startTime;
    const startTimeB = b.startTimeS || b.startTime;
    const result = compareTime(a.date, startTimeA, startTimeB);
    if (result === 0) {
      const endTimeA = a.endTimeS || a.endTime;
      const endTimeB = b.endTimeS || b.endTime;
      return compareTime(a.date, endTimeA, endTimeB);
    } else {
      return result;
    }
  });
}

const datesData = (year, month) => {
  const dates = [];
  const startM = dayjs().year(year).month(month - 1).startOf('month');
  const days = startM.daysInMonth();
  for (let i = 0; i < days; i++) {
    dates.push(startM.add(i, 'day'));
  }
  return dates;
}

const leaveTimeRangeCount = (data, roldType, workTime, pcE, currentUser) => {
  const startDate = dayjs(data.startDate).format('YYYY-MM-DD')
  const endDate = dayjs(data.endDate).format('YYYY-MM-DD')
  const startTime = `${data.startHour}:${data.startMinute}`
  const endTime = `${data.endHour}:${data.endMinute}`
  const start = `${startDate} ${startTime}`;
  const end = `${endDate} ${endTime}`;
  const reg = /\d+-\d+-\d+ \d+:\d+/;
  const newStartWt = roldType === 'shift' ? workTime[`${dayjs(start).format('YYYY-MM-DD')}_${getWeekDay(dayjs(start))}`] : workTime[getWeekDay(dayjs(start))]
  const newEndWt = roldType === 'shift' ? workTime[`${dayjs(end).format('YYYY-MM-DD')}_${getWeekDay(dayjs(end))}`] : workTime[getWeekDay(dayjs(end))]

  let startWorkT = newStartWt ? newStartWt.startTime : null
  let endWorkT = newEndWt ? newEndWt.endTime : null

  const currentPCE = pcE[dayjs(startDate).format('YYYY-MM-DD')]
  if (currentPCE) {
    if (currentPCE.type === 'on' && currentPCE.department.includes(currentUser.department)) {
      startWorkT = currentPCE.startTime
      endWorkT = currentPCE.endTime
    }
  }

  if (!startWorkT || !endWorkT) {
    return false
  }

  if (endTime < startTime) {
    return false
  }

  // if (startWorkT && endWorkT) {
  //   if (startTime < startWorkT || endTime > endWorkT) {
  //     return false
  //   }
  // }

  if (reg.test(start) && reg.test(end)) {
    const startD = dayjs(start, 'YYYY-MM-DD HH:mm');
    const endD = dayjs(end, 'YYYY-MM-DD HH:mm');
    let weekday = roldType === 'shift' ? ['Sun'] : Object.keys(workTime).reduce((acc, cur) => {
      acc = acc.filter(i => i !== cur)
      return acc
    }, ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'])
    let minutes = dayjs.duration(endD.diff(startD)).asMinutes()
    let minute = (minutes % 60)
    let hours = (minutes - (minutes % 60)) / 60
    let days = Number(dayjs(data.endDate).startOf('date').diff(dayjs(data.startDate).startOf('date'), 'day'))
    let startDate = dayjs(data.startDate)
    let endDate = dayjs(data.endDate)
    let currentException = {}

    for (; ;) {
      currentException = pcE ? pcE[dayjs(startDate).format('YYYY-MM-DD')] : {}
      if (startDate > endDate) {
        break;
      }

      const weeks = getWeekDay(startDate)
      if (weekday.includes(weeks)) {
        days -= 1
      }

      if (data.leaveType !== 'maternity_leave') {
        if (data.leaveType === 'weekly_leave') {
          if(currentException?.department.includes(currentUser.department)) {
            if (currentException?.type === 'off' && weekday.includes(weeks)) {
              days -= 1
            } else if (currentException?.type === 'on') {
              days += 1
            }
          }
        } else {
          if(currentException?.department.includes(currentUser.department)) {
            if (currentException?.type === 'off' && !weekday.includes(weeks)) {
              days -= 1
            } else if (currentException?.type === 'on' && weekday.includes(weeks)) {
              days += 1
            }
          }
        }
      }

      startDate = startDate.add(1, 'days')
    }

    if (minute > 0) {
      if (minute <= 30) {
        hours += 0.5
      } else {
        hours += 1
      }
    }

    if (hours < 8) {
      return { hour: hours, day: days }
    } else if (hours >= 8) {
      days += 1
      return { hour: 0, day: days }
    }
  } else {
    return
  }
}

// const formatName = (name) => {
//   return name.replace(/\\u{(.*?)}/g, (match, code) => {
//     return String.fromCodePoint(parseInt(code, 16))
//   });
// }

function getVacationDate(date, pcException, department, worktimeType, workTime) {
  const pcE = pcException[date] || null
  if (pcE) {
    if (pcE.type === 'off' && pcE.department.includes(department)) {
      return true
    }
  } else {
    const weekday = worktimeType === 'shift' ? ['Sun'] : Object.keys(workTime).reduce((acc, cur) => {
      acc = acc.filter(i => i !== cur)
      return acc
    }, ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'])

    if (weekday.includes(getWeekDay(dayjs(date)))) {
      return true
    }
  }
}

function getWeekDay(date) {
  const weekDayMapping = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const dateIndex = dayjs(date).weekday()

  return weekDayMapping[dateIndex]
}

function calculate(data, expression) {
  let calculateExpression = expression
  let postfix = null
  if (expression.includes('->')) {
    const variable = expression.split('->')
    postfix = variable[1]
    calculateExpression = variable[0]
  }
  if (calculateExpression.includes('*')) {
    const variable = calculateExpression.split('*')
    if (isNaN(data[variable[0]]) || isNaN(data[variable[1]])) {
      return 0
    }
    const result = parseFloat(data[variable[0]] || 0) * parseFloat(data[variable[1]] || 0)
    if (postfix === 'Round') {
      return Math.round(result)
    } else {
      return result
    }
  }
}

const sortByField = (field) => {
  return (a, b) => {
    if (a[field] > b[field]) return 1
    else if (a[field] < b[field]) return -1
    return 0
  }
}

function unwrap(data) { // NEED FIX: 搬到 firebase.js 裡面
  if (data) {
    const { extData, ...rootData } = data
    return { ...rootData, ...extData }
  } else {
    return data
  }
}

function getRandomHash() {
  let hashCode = '';
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  for (let i = 0; i < 20; ++i) {
    const r = Math.floor((Math.random() * 62));
    const char = characters.charAt(r);
    hashCode += char;
  }
  return hashCode;
}

function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1);
}

function unique(array) {
  return [...new Set(array)]
}

function numFormat(num) {
  num = num.toString().split('.');  // 分隔小数点
  let arr = num[0].split('').reverse();  // 转换成字符数组并且倒序排列
  let res = [];
  for (let i = 0, len = arr.length; i < len; i++) {
    if (i % 3 === 0 && i !== 0) {
      res.push(',');   // 添加分隔符
    }
    res.push(arr[i]);
  }
  res.reverse(); // 再次倒序成为正确的顺序
  if (num[1]) {  // 如果有小数的话添加小数部分
    res = res.join('').concat('.' + num[1]);
  } else {
    res = res.join('');
  }
  return res;
}

export {
  anyIntersection,
  getIntersection,
  mergeTimeArray,
  splitShiftByLeaves,
  sortTimeArray,
  findNextPunchIn,
  findNextPunchOut,
  datesData,
  leaveTimeRangeCount,
  // formatName,
  getVacationDate,
  getWeekDay,
  calculate,
  sortByField,
  unwrap,
  getRandomHash,
  capitalize,
  unique,
  numFormat,
};
