import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import FabAdd from 'components/FabAdd';
import EnhancedTable from 'components/EnhancedTable';
import DateRangePickerField from 'components/DateRangePickerField';
import SearchBox from 'components/SearchBox';
import ContextStore from 'modules/context';
import { firestoreListener } from 'modules/firebase';
import RefundView from './RefundView';
import CompanyFilter from 'components/CompanyFilter';
import SimpleTableToolbar from 'components/SimpleTableToolbar';

function RefundList() {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { setBreadcrumbs, currentCompany } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [refundServices, setRefundServices] = useState([])
  const [currentFilter, setCurrentFilter] = useState(null);
  const userMapping = useSelector(state => state.users.data)
  const [customerMapping, setCustomerMapping] = useState({});
  const productMapping = useSelector(state => state.products.data)
  const config = useSelector(state => state.config.data)
  const paymentTypes = Object.keys(config.paymentType || {}).map(i => config.paymentType[i])
  const paymentTypeMapping = paymentTypes.reduce((acc, cur) => { acc[cur.id] = cur; return acc }, {})


  const filteredCurrentData = filterCurrentData()

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'refundServices',
      where: [
        ['date', '>=', startDate],
        ['date', '<=', endDate],
        ['source', '==', currentCompany]
      ],
      onData: (data) => {
        setRefundServices(data)
      }
    })
    return () => unsubscribe()
  }, [startDate, endDate, currentCompany]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      onData: (data) => {
        setCustomerMapping(data)
      }
    })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.sales.refund' })
    }])

  }, []);

  const filterItems = [
    { name: 'sn' },
    { name: 'date' },
    { name: 'customer' },
    { name: 'createdBy' },
  ].map(i => { i.text = formatMessage({ id: `refundService.filter.${i.name}` }); return i })

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'date', sort: 'date' },
    { text: 'customer' },
    { text: 'createdBy' },
    { text: 'refundPrice' },
    { text: 'paymentType' },
  ].map(c => { c.text = formatMessage({ id: `refundService.table.header.${c.text}` }); return c })

  const rowCells = [
    { field: 'sn' },
    { field: 'date' },
    { field: 'customer' },
    { field: 'createdBy' },
    { field: 'refundPrice' },
    { field: 'paymentType' },
  ]

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function filterCurrentData() {
    if (!currentFilter) {
      return refundServices
    }

    let items = [...refundServices]
    if (currentFilter) {
      const lowerCaseText = currentFilter.text.toLowerCase()
      if (currentFilter.name === 'sn') {
        items = items.filter(i => i.sn.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'createdBy') {
        items = items.filter(i => userMapping[i.createdBy]?.displayName.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'date') {
        items = items.filter(i => i.date.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'customer') {
        items = items.filter(i => customerMapping[i.customer].name.toLowerCase().includes(lowerCaseText) || customerMapping[i.customer]?.code.toLowerCase().includes(lowerCaseText))
      }
    }

    return items
  }

  function formatData(data) {
    const newData = { ...data }
    newData.createdBy = userMapping[newData.createdBy].displayName
    newData.customer = customerMapping[newData.customer] ? customerMapping[newData.customer].name : ''

    if (newData.paymentType === 'storedValue') {
      newData.paymentType = `儲值(${productMapping[newData.storedValueCard].name})`
    } else {
      newData.paymentType = paymentTypeMapping[newData.paymentType]?.name || ''
    }

    return newData
  }

  function onEditClick(refund) {
    navigate({
      pathname: `/sales/refund/edit/${refund.id}`,
    });
  }

  return <div style={{ padding: '20px 24px 80px 24px', width: '100%' }}>
    {userRights.hasUserRight('refund-create') && <FabAdd to="/sales/refund/edit/new" />}

    <SimpleTableToolbar
      title='refundService.table.title'
      toolbox={<>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={9}>
            <DateRangePickerField
              startDate={startDate}
              endDate={endDate}
              onStartDateChanged={setStartDate}
              onEndDateChanged={setEndDate}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CompanyFilter userRight='refund-view'/>
          </Grid>
        </Grid>
        <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} updateUrlParams />
      </>}
    />
    <EnhancedTable
      defaultOrder="asc"
      defaultOrderField="code"
      headerCells={headerCells}
      rowCells={rowCells}
      getExpandContent={refundService =>
        <RefundView
          userRights={userRights}
          data={refundService}
          onEditClick={onEditClick}
          currentCompany={currentCompany}
        />
      }
      tableData={filteredCurrentData.map(i => formatData(i))}
    />
  </div>

}

export default RefundList
