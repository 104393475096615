import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';

import TabContainer from 'containers/TabContainer/TabContainer';
import FabAdd from 'components/FabAdd';
import ContextStore from 'modules/context';
import InventoryChangeTab from './InventoryChangeTab';

function InventoryChange() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const userRights = useSelector(state => state.userRights)
  const [activeTabIndex, setActiveTabIndex] = useState(undefined)
  const { tabName } = useParams()

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.stock.inventoryChange' })
    }])
    const pathname = location.pathname
    if (pathname === '/stock/inventoryChange/pending' && activeTabIndex !== 0) {
      setActiveTabIndex(0)
    } else if (pathname === '/stock/inventoryChange/done' && activeTabIndex !== 1) {
      setActiveTabIndex(1)
    } else if (pathname === '/stock/inventoryChange/void' && activeTabIndex !== 2) {
      setActiveTabIndex(2)
    }
    return () => {
    };
  }, [location.pathname]);

  const onTabSelected = (tabIndex) => {
    if (tabIndex === 0) {
      navigate('/stock/inventoryChange/pending');
    } else if (tabIndex === 1) {
      navigate('/stock/inventoryChange/done');
    } else {
      navigate('/stock/inventoryChange/void');
    }
  };

  const tabs = [
    { label: formatMessage({ id: 'inventoryChange.tab.pending' }), component: <InventoryChangeTab /> },
    { label: formatMessage({ id: 'inventoryChange.tab.done' }), component: <InventoryChangeTab /> },
    { label: formatMessage({ id: 'inventoryChange.tab.void' }), component: <InventoryChangeTab /> },
  ]
  const defaultSelect = tabName === 'void' ? 2 : (tabName === 'done' ? 1 : 0)

  return (
    <Box p={3}>
      {userRights.hasUserRight('inventoryChange-create') && <FabAdd to={'/stock/inventoryChange/edit/new'} />}
      <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} />
    </Box>
  );
}

export default InventoryChange;
