import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import reducers from 'reducer';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, verifyPasswordResetCode as _verifyPasswordResetCode, confirmPasswordReset as _confirmPasswordReset, signOut } from 'firebase/auth';
import { getFirestore, doc, collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

import { unwrap } from 'modules/firebase';
import middleware from './middleware';
import { getConfig } from './configMapping';

let config = getConfig(process.env.BRANCH_ENV)

const app = initializeApp(config)
const reducer = combineReducers({ ...reducers });

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const store = configureStore({ reducer, middleware });
const subs = []

// global.store = store; don't mount to window

const toActionName = camel => camel.replace(/[A-Z]/g, m => '_' + m.toLowerCase()).toUpperCase()
const addListener = ({ collection: collectionId, doc: docId, where: querys, orderBy: order, storeAs, unwrap: unwraper, addDocIdToArrayData = true }) => {
  if (docId !== undefined) {
    const unsub = onSnapshot(doc(db, collectionId, docId), (doc) => {
      let data = doc.data() ?? {}
      if (unwraper) {
        data = unwrap(data)
      }
      store.dispatch({
        type: `SET_${toActionName(storeAs)}`,
        payload: data
      })
    },(error) => {
      console.log(error)
    })
    subs.push(() => {
      store.dispatch({
        type: `RESET_${toActionName(storeAs)}`,
        payload: {}
      })
      unsub()
    })
  } else {
    const params = []
    if (querys) {
      params.push(where(...querys))
    }
    if (order) {
      params.push(orderBy(...order))
    }
    const q = query(collection(db, collectionId), ...params)
    const unsub = onSnapshot(q, (snapshot) => {
      const ready = store.getState()[storeAs]?.ready

      if (!ready) {
        const docs = [];
        const mapping = {}
        snapshot.forEach((doc) => {
          let data = addDocIdToArrayData ? { ...doc.data(), id: doc.id } : { ...doc.data() }
          if (unwraper) {
            data = unwrap(data)
          }
          docs.push(data)
          mapping[doc.id] = data
        });
        store.dispatch({
          type: `SET_${toActionName(storeAs)}`,
          payload: { ready: true, ordered: docs, data: mapping }
        })
      } else {
        snapshot.docChanges().forEach((change) => {
          let data = addDocIdToArrayData ? { ...change.doc.data(), id: change.doc.id } : { ...change.doc.data() }
          if (unwraper) {
            data = unwrap(data)
          }
          if (change.type === 'added') {
            store.dispatch({
              type: `ADD_${toActionName(storeAs)}`,
              payload: { newIndex: change.newIndex, data, id: addDocIdToArrayData ? data.id : change.doc.id }
            })
          } else if (change.type === 'modified') {
            store.dispatch({
              type: `MODIFY_${toActionName(storeAs)}`,
              payload: { oldIndex: change.oldIndex, newIndex: change.newIndex, data, id: addDocIdToArrayData ? data.id : change.doc.id }
            })
          } else if (change.type === 'removed') {
            store.dispatch({
              type: `REMOVE_${toActionName(storeAs)}`,
              payload: { oldIndex: change.oldIndex, data, id: addDocIdToArrayData ? data.id : change.doc.id }
            })
          }
        });
      }
    }, (error) => {
      console.log(error)
    });
    subs.push(() => {
      store.dispatch({
        type: `RESET_${toActionName(storeAs)}`,
        payload: {}
      })
      unsub()
    })
  }
}

function setDataListeners(uid) {
  if (subs.length) {
    return
  }
  addListener({ collection: 'users', doc: uid, storeAs: 'user', defaultData: null })
  addListener({ collection: 'users', storeAs: 'users' })
  addListener({ collection: 'userRights', doc: uid, storeAs: 'userRawRights', unwrap: true, defaultData: null })
  addListener({ collection: 'userRights', doc: '*', storeAs: 'globalRights', unwrap: true, defaultData: null })
  addListener({ collection: 'reviewPolicies', storeAs: 'reviewPolicies' })
  addListener({ collection: 'configuration', storeAs: 'config', unwrap: true, addDocIdToArrayData: false })
  addListener({ collection: 'vendors', storeAs: 'vendors' })
  addListener({ collection: 'vendors', where: ['internal', '==', true], storeAs: 'internalVendors' })
  addListener({ collection: 'warehouseFlow', storeAs: 'warehouseFlow', unwrap: true })
  addListener({ collection: 'merchandises', storeAs: 'merchandises' }) // TODO: 之後要改成, 有點選到 warehouse 相關功能才載入這個 collection
  addListener({ collection: 'products', storeAs: 'products' })
  addListener({ collection: 'customerExtTab', storeAs: 'customerExtTab', unwrap: true, addDocIdToArrayData: false })
  addListener({ collection: 'notifications', doc: uid, storeAs: 'notifications', defaultData: null })
}

function unsetDataListener() {
  subs.forEach(sub => {
    sub()
  })
  subs.length = 0
}

function login({ email, password }) {
  return signInWithEmailAndPassword(auth, email, password)
}

function logout() {
  unsetDataListener();
  signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
  });
}

function resetPassword(email) {
  return sendPasswordResetEmail(auth, email)
}

function verifyPasswordResetCode(code) {
  return _verifyPasswordResetCode(auth, code)
}

function confirmPasswordReset(token, password) {
  return _confirmPasswordReset(auth, token, password)
}

export {
  store,
  app,
  auth,
  db,
  storage,
  functions,
  setDataListeners,
  login,
  logout,
  resetPassword,
  verifyPasswordResetCode,
  confirmPasswordReset
};
