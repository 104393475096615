import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Collapse from '@mui/material/Collapse';

import EnhancedTable from 'components/EnhancedTable';
import SimpleTableToolbar from 'components/SimpleTableToolbar';
import SearchBox from 'components/SearchBox';
import ExpandButton from 'components/ExpandButton';
import RequisitionView from './RequisitionView';

function RequisitionDetail({ userMapping, requisition, ...props }) {
  const r = { ...requisition }
  const keys = requisition.merchandises ? Object.keys(requisition.merchandises) : []
  r.merchandises = keys.map(k => ({ ...r.merchandises[k], id: k }))
  r.createdBy = { id: r.createdBy, name: userMapping[r.createdBy]?.displayName }
  return (
    <div style={{ padding: 15 }}>
      <RequisitionView userMapping={userMapping} requisition={r} {...props} />
    </div>
  )
}

RequisitionDetail.propTypes = {
  requisition: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
};

function RequisitionList({ requisitions, title }) {
  const { formatMessage } = useIntl()

  const userMapping = useSelector(state => state.users.data)
  const customerMapping = useSelector(state => state.internalVendors.data)
  const merchandiseMapping = useSelector(state => state.merchandises.data)

  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);
  const filteredRequisitions = currentFilter && currentFilter.text ? filterByText() : requisitions

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'sourceName', sort: 'sourceName' },
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'date', sort: 'createdAt' },
    { text: 'expectedDate', sort: 'expectedDate' },
  ].map(c => {c.text = formatMessage({ id: `requisition.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'sn' },
    { field: 'sourceName' },
    { field: 'createdBy' },
    { field: 'date' },
    { field: 'expectedDate' },
  ]

  function filterByText() {
    const lowerCaseText = currentFilter.text.toLowerCase()
    if (currentFilter.name === 'sourceName') {
      return requisitions.filter(s => customerMapping[s.source]?.nickname.toLowerCase().includes(lowerCaseText))
    } else if (currentFilter.name === 'createdBy') {
      return requisitions.filter(s => userMapping[s.createdBy]?.displayName.toLowerCase().includes(lowerCaseText))
    } else if (currentFilter.name === 'merchandiseName') {
      return requisitions.filter(s => Object.keys(s.merchandises || {}).filter(c => merchandiseMapping[c])
        .filter(c => merchandiseMapping[c].name.toLowerCase().includes(lowerCaseText) ||
          merchandiseMapping[c].nickname.toLowerCase().includes(lowerCaseText)).length)
    } else if (currentFilter.name === 'merchandiseCode') {
      return requisitions.filter(s => Object.keys(s.merchandises || {}).filter(c => merchandiseMapping[c])
        .filter(c => merchandiseMapping[c].code.toLowerCase().includes(lowerCaseText)).length)
    } else {
      return requisitions.filter(s => s[currentFilter.name].toLowerCase().includes(lowerCaseText))
    }
  }

  const formatData = (requisition) => {
    const newData = { ...requisition }
    newData.sourceName = customerMapping[newData.source]?.nickname
    newData.createdBy = userMapping[newData.createdBy]?.displayName
    return newData
  }

  const filterItems = [
    { name: 'sn' },
    { name: 'sourceName', type: 'customer' },
    { name: 'createdBy' },
    { name: 'date' },
    { name: 'merchandiseName' },
    { name: 'merchandiseCode' },
    { name: 'note' },
  ].map(i => {i.text = formatMessage({ id: `requisition.table.detail.${i.name}` });return i})

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  return (
    <div>
      <SimpleTableToolbar
        title={title}
        buttons={<ExpandButton open={expand} onExpandChange={setExpand} />}
        toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
      />
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <EnhancedTable
          defaultOrder="desc"
          defaultOrderField="sn"
          headerCells={headerCells}
          rowCells={rowCells}
          getExpandContent={requisition =>
            <RequisitionDetail
              userMapping={userMapping}
              merchandiseMapping={merchandiseMapping}
              requisition={requisition}
            />
          }
          tableData={filteredRequisitions.map(r => formatData(r))}
        />
      </Collapse>
    </div>
  );
}

RequisitionList.propTypes = {
  requisitions: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  title: PropTypes.string.isRequired,
};

export default RequisitionList;
