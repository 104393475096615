import { createContext } from 'react';
import dayjs from 'dayjs';

const ContextStore = createContext({
  isMobile: false,
  isTablet: false,
  breadcrumbs: [],
  headerTools: null,
  setHeaderTools: () => { },
  setBreadcrumbs: () => { },
  drawerStatus: false,
  setDrawerStatus: () => { },
  uiState: {
    date: dayjs(),
    noShifts: false,
    showNurse: true,
    showRoom: true,
    showSalesRep: true,
    showCustomerCode: true,
    showCustomerType: false,
    showBirthDate: false,
    showPunchClock: true,
    showLeave: true,
    showException: true,
    showOvertime: true,
    showShift: true,
    showComment: true,
    showWeeklyLeave: true,
  },
  setUiState: () => { },
  purchaseOrderCache: {},
  setPurchaseOrderCache: () => { },
  modules: [],
  currentUser: null,
  currentCompany: '',
  setCurrentCompany: () => { },
})


export default ContextStore
