import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import MaskInput from 'components/MaskInput';
import DatePickerField from 'components/DatePickerField';
import ButtonProgress from 'components/ButtonProgress';
import ContextStore from 'modules/context';
import { unique } from 'modules/uitls';
import { callFunction } from 'modules/firebase';

const pcExceptionType = ['off', 'on']

function EditPunchClockExceptionDialog({ handleClose, data, type, departmentMapping, departments: dep, currentCompany }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)
  const [loading, setLoading] = useState(false)
  const vendorMapping = useSelector(state => state.vendors.data)
  const userRights = useSelector(state => state.userRights)
  const punchClockExceptionId = !data.id ? 'new' : data.id
  const defultPcExceptionData = punchClockExceptionId === 'new' ? {
    date: dayjs(),
    startTime: '',
    endTime: '',
    reason: '',
    type: 'on',
    source: [],
    department: [],
    publicHoliday: false
  } : {
    date: data.date,
    startTime: data.startTime,
    endTime: data.endTime,
    reason: data.reason,
    type: data.type,
    source: data.source,
    department: data.department,
    publicHoliday: data.publicHoliday
  }
  const [pcExceptionData, setpcExceptionData] = useState(defultPcExceptionData)
  const [typeText, setTypeText] = useState(data.departmentName || '')
  const [checked, setChecked] = useState(data.department || [0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const departments = ['all'].concat(dep.map(i => i.id))
  const companyData = currentUser.company?.reduce((acc, cur) => { if (userRights['schedule-exception'].includes(cur)) { acc = true } return acc }, false) ?
    unique(currentUser.company?.concat(userRights['schedule-exception'])) : userRights['schedule-exception']

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function updateData(field, value) {
    let newValue = value

    let err = validateField(field, value)
    let newData = { ...pcExceptionData, [field.name]: newValue, [`${field.name}_err`]: err }

    if (field.name === 'startDate') {
      newData.endDate = newData.startDate
    }

    setpcExceptionData(newData)
  }

  const handleSave = async () => {
    setLoading(true)
    let err = {}
    let newData = pcExceptionData

    for (let field of fields) {
      if (field.required && (pcExceptionData[field.name] === '' || pcExceptionData[field.name].length === 0)) {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }

    if (Object.keys(err).length > 0) {
      newData = { ...pcExceptionData, ...err }
    }
    if (Object.keys(err).length > 0) {
      setpcExceptionData(newData)
      return setLoading(false)
    }

    let departments = []
    for (let d of checked) {
      if (d !== 0 && d !== 'add') {
        departments.push(d)
      }
    }

    let updateData = {}
    if (punchClockExceptionId === 'new') {
      updateData = {
        startDate: dayjs(newData.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(newData.endDate).format('YYYY-MM-DD'),
        startTime: newData.startTime,
        endTime: newData.endTime,
        reason: newData.reason,
        source: newData.source,
        department: departments,
        type: newData.type,
      }
    } else {
      updateData = {
        date: dayjs(newData.date).format('YYYY-MM-DD'),
        startTime: newData.startTime,
        endTime: newData.endTime,
        reason: newData.reason,
        department: departments,
        type: newData.type
      }
    }

    if (newData.type === 'off') {
      updateData.publicHoliday = newData.publicHoliday
    }

    try {
      await callFunction('savePunchClockException', { id: punchClockExceptionId, ...updateData })
      setLoading(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      if (value === 'all') {
        for (const d of departments) {
          newChecked.push(d)
        }
      } else {
        newChecked.push(value);
      }
    } else {
      if (value === 'all') {
        newChecked.splice(newChecked)
      } else {
        newChecked.splice(currentIndex, 1);
      }
    }

    let newText = ''
    const newFilter = newChecked.filter(s => s !== 'all' && departments.includes(s))
    newText = newFilter.map(x => x === 'all' ?
      formatMessage({ id: 'punchClockException.dialog.all' }) :
      departmentMapping[x].name
    ).join(', ')

    if (type === 'edit') {
      updateData({ name: 'department', newChecked })
    }

    setTypeText(newText)
    setChecked(newChecked);
  };

  function onPCExceptionChanged(field, value) {
    updateData({ name: `${field.name}` }, value);
  }

  const _fields = [
    { name: 'startTime', sm: 6, md: 3, order: 2, type: 'time', mask: '99:99' },
    { name: 'endTime', sm: 6, md: 3, order: 3, type: 'time', mask: '99:99' },
    { name: 'source', sm: 6, md: 3, select: true, required: true, order: 4 },
    { name: 'type', sm: 6, md: 3, select: true, roots: true, order: 5 },
    { name: 'reason', sm: 12, md: 6, order: 6, required: true },
    { name: 'department', sm: 12, md: 12, type: 'department', order: 7 },
  ]

  if (punchClockExceptionId === 'new') {
    _fields.push({ name: 'startDate', sm: 6, md: 3, type: 'date', order: 0 })
    _fields.push({ name: 'endDate', sm: 6, md: 3, type: 'endDate', order: 1 })
  } else {
    _fields.push({ name: 'date', sm: 6, md: 3, type: 'date', order: 0 })
  }

  const fields = _fields.map(field => {
    field.multiline = field.multiline || false; field.md = field.md || 3; return field
  }).sort((a, b) => a.order - b.order)

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    if (field.name === 'startTime' || field.name === 'endTime') {
      const newTime = String(value).split(':')
      if (newTime[0] > '24' || newTime[1] > '60') {
        return formatMessage({ id: 'form.date.formatError' })
      }
    }

    if (field.name === 'endTime' && pcExceptionData.startTime && pcExceptionData.startTime > value && value !== ':') {
      return formatMessage({ id: 'form.date.formatError' })
    }

    return ''
  }

  function createField(field) {
    let newValue = pcExceptionData[field.name]
    let labelText = ''
    let selectFields;

    if (field.type === '-') {
      return <Grid item key={field.order} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    } else if (field.type === 'date') {
      labelText = formatMessage({ id: `punchClockException.dialog.${field.name}` })

      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <DatePickerField
          required
          fullWidth
          label={labelText}
          value={dayjs(newValue)}
          onChange={date => updateData({ name: `${field.name}` }, date)}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    } else if (field.type === 'endDate') {
      labelText = formatMessage({ id: `punchClockException.dialog.${field.name}` })

      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <DatePickerField
          required
          fullWidth
          label={labelText}
          value={dayjs(newValue)}
          onChange={date => updateData({ name: `${field.name}` }, date)}
          minDateMessage={formatMessage({ id: 'form.date.beforeStartDate' })}
          maxDateMessage={formatMessage({ id: 'form.date.afterToday' })}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
          minDate={dayjs(pcExceptionData.startDate).toDate()}
        />
      </Grid>
    } else if (field.type === 'department') {
      labelText = formatMessage({ id: 'punchClockException.dialog.department' })

      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={labelText}
          value={typeText}
          fullWidth
          size="small"
          variant="outlined"
          onClick={handleClick}
        />
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          open={open}
          onClose={() => setAnchorEl(null)}
          TransitionComponent={Fade}
        >
          <List>
            {departments.map((value, idx) => {
              return (
                <ListItem key={`${value}-${idx}`} dense onClick={handleToggle(value)}>
                  <Checkbox
                    sx={{ padding: '0 4px' }}
                    edge="start"
                    size="small"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                  {value === 'all' ? formatMessage({ id: 'punchClockException.dialog.all' }) :
                    departmentMapping[value].name}
                </ListItem>
              );
            })}
          </List>
        </Menu>
      </Grid>
    } else if (field.type === 'time') {
      labelText = formatMessage({ id: `punchClockException.dialog.${field.name}` })

      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <MaskInput
          mask={field.mask}
          maskChar=" "
          onChange={e => { onPCExceptionChanged(field, e.target.value) }}
          value={pcExceptionData[field.name]}
          label={labelText}
          helperText={pcExceptionData[`${field.name}_err`]}
        />
      </Grid>
    }

    if (field.roots) {
      labelText = formatMessage({ id: `punchClockException.dialog.${field.name}.roots` })
    } else {
      labelText = formatMessage({ id: `punchClockException.dialog.${field.name}` })
    }

    if (field.name === 'type') {
      selectFields = pcExceptionType
    } else if (field.name === 'source') {
      selectFields = companyData
    }

    if (field.name === 'source') {
      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
        <FormControl
          size="small"
          required={field.required}
          error={pcExceptionData[`${field.name}_err`] ? true : false}
          helperText={pcExceptionData[`${field.name}_err`]}
          fullWidth
        >
          <InputLabel id="demo-multiple-checkbox-label">{labelText}</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            value={pcExceptionData[field.name]}
            multiple
            onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
            input={<OutlinedInput label={labelText} />}
            renderValue={(selected) => selected.map(s => vendorMapping[s].name).join(', ')}
          >
            {selectFields.map(select => {
              return < MenuItem key={select} value={select} >
                <Checkbox checked={pcExceptionData[field.name].includes(select)} />
                <ListItemText primary={vendorMapping[select].name} />
              </MenuItem>
            })}
          </Select>
          <FormHelperText sx={{ color: 'red' }} id="component-error-text">{pcExceptionData[`${field.name}_err`]}</FormHelperText>
        </FormControl>
      </Grid>
    }

    return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={labelText}
        required={field.required}
        value={newValue}
        fullWidth
        select={field.select ? field.select : null}
        size="small"
        variant="outlined"
        onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
        error={pcExceptionData[`${field.name}_err`] ? true : false}
        helperText={pcExceptionData[`${field.name}_err`]}
      >
        {
          field.select && selectFields.map((selects, idx) => {
            return <MenuItem key={`${idx}`} value={selects}>{formatMessage({ id: `punchClockException.dialog.${field.name}.${selects}` })}</MenuItem>
          })
        }
      </TextField>
    </Grid>
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        {formatMessage({ id: 'punchClockException.dialog.title' })}
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          {pcExceptionData.type === 'off' && <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
            {formatMessage({ id: 'punchClockException.dialog.normalHoliday' })}
            <Switch
              checked={pcExceptionData.publicHoliday}
              onChange={e => updateData({ name: 'publicHoliday' }, e.target.checked)}
              color="primary"
              name="active"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            {formatMessage({ id: 'punchClockException.dialog.publicHoliday' })}
          </Grid>}
          {fields.map(field => createField(field))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonProgress handleClose={handleClose} handleClick={handleSave} loading={loading} buttonText='button.save' />
      </DialogActions>
    </Dialog>
  );
}

EditPunchClockExceptionDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  departmentMapping: PropTypes.object.isRequired,
  departments: PropTypes.arrayOf(PropTypes.object.isRequired),
  currentCompany: PropTypes.string.isRequired
};

export default EditPunchClockExceptionDialog;
