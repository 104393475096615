import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';

import EnhancedTable from 'components/EnhancedTable';
import DateRangePickerField from 'components/DateRangePickerField';
import SimpleTableToolbar from 'components/SimpleTableToolbar';
import ExpandButton from 'components/ExpandButton';
import SearchBox from 'components/SearchBox';
import FabAdd from 'components/FabAdd';
import ContextStore from 'modules/context';
import { firestoreListener } from 'modules/firebase';
import DeliveryOrderView from './DeliveryOrderView';

function DeliveryOrderDetail({ userMapping, deliveryOrder, ...props }) {
  const r = { ...deliveryOrder }
  const keys = deliveryOrder.merchandises ? Object.keys(deliveryOrder.merchandises) : []
  r.merchandises = keys.map(k => {
    return { ...r.merchandises[k], id: k }
  })

  r.createdBy = { id: r.createdBy, name: userMapping[r.createdBy]?.displayName }
  return (
    <div style={{ padding: 15 }}>
      <DeliveryOrderView userMapping={userMapping} deliveryOrder={r} {...props} />
    </div>
  )
}

DeliveryOrderDetail.propTypes = {
  deliveryOrder: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
};

function DeliveryOrderList({ formName }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const location = useLocation()

  const userMapping = useSelector(state => state.users.data)
  const customerMapping = useSelector(state => state.internalVendors.data)
  const customers = useSelector(state => state.internalVendors.ordered)
  const vendorMapping = useSelector(state => state.vendors.data)
  const merchandiseMapping = useSelector(state => state.merchandises.data)

  const [currentFilter, setCurrentFilter] = useState(null)
  const [sourceFilter, setSourceFilter] = useState('')
  const [destinationFilter, setDestinationFilter] = useState('')
  const [expand, setExpand] = useState(true);
  const [deliveryOrders, setDeliveryOrders] = useState([])
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  const extFilter = formName === 'deliveryOrder'
  let newFormLink = ''
  if (formName === 'deliveryOrder') {
    newFormLink = '/delivery/deliveryOrder/edit/new'
  } else if (formName === 'borrowingDelivery') {
    newFormLink = '/borrowing/borrowingDelivery/edit/new'
  } else if (formName === 'returnBorrowingForm') {
    newFormLink = '/borrowing/returnBorrowingForm/edit/new'
  }

  useEffect(() => {
    let title = ''
    if (formName === 'deliveryOrder') {
      title = formatMessage({ id: 'sideMenu.delivery.deliveryOrder' })
    } else if (formName === 'borrowingDelivery') {
      title = formatMessage({ id: 'sideMenu.borrowing.borrowingDelivery' })
    } else if (formName === 'returnBorrowingForm') {
      title = formatMessage({ id: 'sideMenu.borrowing.returnBorrowingForm' })
    }
    setBreadcrumbs([{
      text: title
    }])
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    if (deliveryOrders.length) setDeliveryOrders([])

    const unsubscribe = firestoreListener({
      collection: `${formName}s`,
      where: [
        ['date', '>=', startDate],
        ['date', '<=', endDate]
      ],
      unwrap: true,
      onData: (data) => {
        setDeliveryOrders(data)
      }
    })

    return () => unsubscribe()
  }, [startDate, endDate, formName]);

  const deliveryOrdersForTab = deliveryOrders.filter(r =>
    userRights.debugging || userRights.hasUserRightForVendor(`${formName}-create`, r.source)
  );

  const filteredDeliveryOrders = filterDeliveryOrders()

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'sourceName', sort: 'sourceName' },
    { text: 'destinationName', sort: 'destinationName' },
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'date', sort: 'date' },
  ].map(c => {c.text = formatMessage({ id: `${formName}.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'sn' },
    { field: 'sourceName' },
    { field: 'destinationName' },
    { field: 'createdBy' },
    { field: 'date' },
  ]

  function checkUserRight() {
    // 目前 銷貨單, 借貨出貨單 共用權限
    if (formName === 'deliveryOrder') {
      return userRights.hasUserRight('deliveryOrder-create')
    } else if (formName === 'borrowingDelivery') {
      return userRights.hasUserRight('borrowingDelivery-create')
    } else if (formName === 'returnBorrowingForm') {
      return userRights.hasUserRight('returnBorrowingForm-create')
    }
  }

  function filterDeliveryOrders() {
    let items = [...deliveryOrdersForTab]
    if (sourceFilter) {
      items = items.filter(i => i.source === sourceFilter)
    }
    if (destinationFilter) {
      items = items.filter(i => i.destination === destinationFilter)
    }

    if (currentFilter && currentFilter.text) {
      const lowerCaseText = currentFilter.text.toLowerCase()
      if (currentFilter.name === 'sourceName') {
        return items.filter(s => customerMapping[s.source]?.nickname.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'destinationName') {
        return items.filter(s => customerMapping[s.destination]?.nickname.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'createdBy') {
        return items.filter(s => userMapping[s.createdBy]?.displayName.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'merchandiseName') {
        return items.filter(s => Object.keys(s.merchandises || {}).filter(c => merchandiseMapping[c])
          .filter(c => merchandiseMapping[c].name.toLowerCase().includes(lowerCaseText) ||
            merchandiseMapping[c].nickname.toLowerCase().includes(lowerCaseText)).length)
      } else if (currentFilter.name === 'merchandiseCode') {
        return items.filter(s => Object.keys(s.merchandises || {}).filter(c => merchandiseMapping[c])
          .filter(c => merchandiseMapping[c].code.toLowerCase().includes(lowerCaseText)).length)
      } else {
        return items.filter(s => s[currentFilter.name].toLowerCase().includes(lowerCaseText))
      }
    }
    return items
  }

  const formatData = (deliveryOrder) => {
    const newData = { ...deliveryOrder }
    newData.sourceName = customerMapping[newData.source]?.nickname
    newData.destinationName = vendorMapping[newData.destination]?.nickname
    newData.createdBy = userMapping[newData.createdBy]?.displayName
    // newData.date = deliveryOrder.lock ? deliveryOrder.date : 'N/A'
    return newData
  }

  const filterItems = [
    { name: 'sn' },
    { name: 'sourceName', type: 'customer' },
    { name: 'destinationName', type: 'customer' },
    { name: 'createdBy' },
    { name: 'merchandiseName' },
    { name: 'merchandiseCode' },
    { name: 'note' },
  ].map(i => {i.text = formatMessage({ id: `${formName}.table.detail.${i.name}` });return i})

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function getToolbox() {
    // NEED FIX: 這邊之後會改成可以出貨給外部廠商, 所以要 destination 要可以選非診所的廠商
    if (extFilter) {
      return <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              select
              type="text"
              label={formatMessage({ id: `${formName}.table.detail.source` })}
              variant="outlined"
              value={sourceFilter}
              onChange={e => setSourceFilter(e.target.value)}
              fullWidth
              size="small"
            >
              {[{ id: '', nickname: '全部' }, ...customers].map(c => <MenuItem key={c.id || 'all'} value={c.id}>
                {c.nickname}
              </MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              select
              type="text"
              label={formatMessage({ id: `${formName}.table.detail.destination` })}
              variant="outlined"
              value={destinationFilter}
              onChange={e => setDestinationFilter(e.target.value)}
              fullWidth
              size="small"
            >
              {[{ id: '', nickname: '全部' }, ...customers].map(c => <MenuItem key={c.id || 'all'} value={c.id}>
                {c.nickname}
              </MenuItem>)}
            </TextField>
          </Grid>
        </Grid>
        <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />
      </>
    } else {
      return <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />
    }
  }

  return (
    <div style={{ padding: '24px 24px 60px 24px' }}>
      {checkUserRight() && <FabAdd to={newFormLink} />}
      <DateRangePickerField
        startDate={startDate}
        endDate={endDate}
        onStartDateChanged={setStartDate}
        onEndDateChanged={setEndDate}
      />
      <SimpleTableToolbar
        title={`${formName}.table.title`}
        buttons={<ExpandButton open={expand} onExpandChange={setExpand} />}
        toolbox={getToolbox()}
      />
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <EnhancedTable
          defaultOrder="desc"
          defaultOrderField="sn"
          headerCells={headerCells}
          rowCells={rowCells}
          getExpandContent={deliveryOrder =>
            <DeliveryOrderDetail
              userMapping={userMapping}
              merchandiseMapping={merchandiseMapping}
              deliveryOrder={deliveryOrder}
              formName={formName}
            />
          }
          tableData={filteredDeliveryOrders.map(r => formatData(r))}
        />
      </Collapse>
    </div>
  );
}

DeliveryOrderList.propTypes = {
  formName: PropTypes.string.isRequired,
};

export default DeliveryOrderList;
