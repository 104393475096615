const i18n = {
  'announcement': {
    'name': '公告',
    'add': '新增公告',
    'edit': '編輯公告',
    'title': '公告列表',
    'pending': '待簽核的公告',
    'release': '已發佈的公告',
    'upload': {
      'title': '公告上傳',
      'subject': '主旨',
      'source': '公司'
    },
    'table': {
      'date': '日期',
      'subject': '主旨',
      'target': '公告對象',
      'createby': '發件人',
      'attachment': '附件',
      'contentMD5': '公告內容',
      'note': '備註',
    },
    'error': '未上傳PDF',
    'void': {
      'title' : '公告作廢',
      'message': '確認作廢公告?'
    },
    'editor': {
      'placeholder': '輸入公告內容'
    },
    'view': '檢視公告內容'
  }
}
export default i18n