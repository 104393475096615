import { useSelector } from 'react-redux';

const useDepartments = () => {
  const config = useSelector(state => state.config.data)
  const departments = Object.keys(config.departments || {}).filter(i => config.departments[i].active).map(i => config.departments[i])
  if (config.modules.nurse) {
    departments.push({
      active: true,
      id: 'nurse',
      name: '護理部'
    })
  }

  if (config.modules.doctor) {
    departments.push({
      active: true,
      id: 'doctor',
      name: '醫師'
    })
  }

  return departments
}

const useDepartmentMapping = () => {
  const config = useSelector(state => state.config.data)
  const departmentMapping = Object.keys(config.departments || {}).reduce((acc, cur) => {
    acc[config.departments[cur].id] = config.departments[cur];
    return acc
  }, {})
  if (config.modules.nurse) {
    departmentMapping.nurse = {
      active: true,
      id: 'nurse',
      name: '護理部'
    }
  }

  if (config.modules.doctor) {
    departmentMapping.doctor = {
      active: true,
      id: 'doctor',
      name: '醫師'
    }
  }

  return departmentMapping
}

export {
  useDepartments,
  useDepartmentMapping,
};
