const i18n = {
  'salesOrder': {
    'name': '營收單',
    'add': '新增營收單',
    'edit': '編輯營收單',
    'addPayment': '新增付款',
    'table': {
      'title': '營收單列表',
      'header': {
        'sn': '營收單號',
        'date': '日期',
        'source': '公司',
        'customer': '客戶',
        'status': '狀態',
        'agent': '負責人',
        'price': '總金額',
        'salesOrderTotalAmount': '總消費金額'
      },
      'detail': {
        'customer': '客戶',
        'date': '日期',
        'agent': '營收單負責人',
        'note': '備註',
        'status': '狀態',
        'source': '公司'
      }
    },
    'product': {
      'name': '簡稱',
      'serviceProvider': '服務適用單位',
      'priceType': '價格種類',
      'unitPrice': '單價',
      'amount': '數量',
      'totalPrice': '總價',
      'note': '備註',
    },
    'payment': {
      'date': '日期',
      'paymentType': '付款方式',
      'collected': '付款金額',
      'agent': '收款負責人',
      'note': '備註',
    },
    'filter': {
      'sn': '營收單號',
      'customer': '客戶',
      'agent': '負責人',
      'product': '產品',
      'note': '營收單備註',
      'status': '狀態',
      'salesOrderTotalAmount': '總消費金額範圍',
      'amount': '金額'
    },
    'status': {
      'all': '全部',
      'noPayment': '未付款',
      'partialPayment': '部份付款',
      'fullPayment': '付清',
      'void': '作廢'
    },
    'void': {
      'title': '營收單作廢',
      'voidReason': '作廢原因'
    },
    'query': {
      'product': '產品'
    }
  },
  'editSalesOrderPage': {
    'addProduct': '新增產品',
    'removeProduct': '移除產品',
    'addPayment': '新增付款',
    'removePayment': '刪除付款',
    'voidPayment': '作廢付款',
    'subtotal': '總金額',
    'collected': '已收金額',
    'uncollected': '未收金額',
    'table': {
      'product': '產品列表',
      'payment': '付款列表',
      'selected': '已選擇 {count} 個項目',
    },
  },
  'settingSalesOrder': {
    'title': '營收單設定',
    'paymentType': {
      'title': '付款方式',
      'table': {
        'header': {
          'name': '付款方式',
          'status': '狀態'
        }
      }
    }
  }
}
export default i18n
