const i18n = {
  'vendor': {
    'table': {
      'title': '廠商列表',
      'selected': '已選擇 {count} 筆廠商資料',
      'header': {
        'code': '編號',
        'name': '全名',
        'nickname': '簡稱',
        'businessNumber': '統編',
        'contact.name': '聯絡人',
        'contact.phone': '電話',
        'contact.mobile': '行動電話',
      },
      'detail': {
        'code': '編號',
        'nickname': '簡稱',
        'businessNumber': '統編',
        'name': '全名',
        'phone': '電話',
        'fax': '傳真',
        'address': '地址',
        'email': '電子郵件地址',
        'contactName': '聯絡人-姓名',
        'contactPhone': '聯絡人-專線#分機',
        'contactMobile': '聯絡人-行動電話',
        'contactNote': '聯絡人-備註',
        'ownerName': '負責人-姓名',
        'ownerPhone': '負責人-專線#分機',
        'ownerMobile': '負責人-行動電話',
        'billRule': '付款結帳規則',
        'basicPurchaseAmount': '最低採購金額',
        'freeShippingAmount': '免運金額',
        'shippingOut': '出貨廠商',
        'note': '備註',
        'supplierType': '供應商',
        'internalType': '內部廠商',
        'activeType': '啟用',
        'supplier': '類型',
        'internal': '內/外部廠商',
        'active': '狀態',
      }
    },
    'contact': {
      'contactName': '聯絡人-姓名',
      'contactPhone': '專線#分機',
      'contactMobile': '行動電話',
      'contactNote': '備註',
    },
    'dialog': {
      'title': {
        'add': '新增廠商',
        'edit': '編輯廠商資料',
      }
    },
    'billRule': {
      'rule1': '月結30天',
      'rule2': '月結60天',
      'rule3': '月結90天',
      'rule4': '貨到付款',
      'rule5': '付款後出貨',
    },
    'supplier': {
      'type1': '供應商',
      'type2': '非供應商',
    },
    'internal': {
      'type1': '內部廠商',
      'type2': '外部廠商',
    },
    'active': {
      'type1': '啟用',
      'type2': '停用',
    },
    'flag': {
      'all': '全部'
    },
    'showWorkflow': '顯示流程權限',
    'announcementSteps': {
      'title': '公告流程',
      'edit': '編輯公告流程',
    },
    'requisitionSteps': {
      'title': '請購流程',
      'edit': '編輯請購流程',
    },
    'purchaseOrderSteps': {
      'title': '採購流程',
      'edit': '編輯採購流程',
    },
    'receiptSteps': {
      'title': '進貨流程',
      'edit': '編輯進貨流程',
    },
    'deliveryOrderSteps': {
      'title': '銷貨流程',
      'edit': '編輯銷貨流程',
    },
    'inventoryCheckSteps': {
      'title': '盤點流程',
      'edit': '編輯盤點流程',
    },
    'stockRequisitionSteps': {
      'title': '領用流程',
      'edit': '編輯領用流程',
    },
    'scrapFormSteps': {
      'title': '報廢出庫流程',
      'edit': '編輯報廢出庫流程',
    },
    'inventoryChangeSteps': {
      'title': '庫存異動流程',
      'edit': '編輯庫存異動流程',
    },
    'newSupplierSteps': {
      'title': '新供應商申請流程',
      'edit': '編輯新供應商申請流程',
    },
    'newMerchandiseSteps': {
      'title': '新商品申請流程',
      'edit': '編輯新商品申請流程',
    },
    'newProductSteps': {
      'title': '新療程申請流程',
      'edit': '編輯新療程申請流程',
    },
    'editBorrowingStaff': {
      'title': '借貨流程',
      'edit': '編輯借貨流程',
    },
    'editWarehouseStaff': {
      'title': '倉管人員',
      'edit': '編輯倉管人員',
    },
    'editAppointmentUserRight': {
      'title': '預約表',
      'edit': '編輯預約表使用者',
    },
    'editFinanceUserRight': {
      'title': '財務',
      'edit': '編輯財務使用者',
    },
    'editLeaveOvertimeUserRight': {
      'title': '請假加班',
      'edit': '編輯請假加班使用者',
    },
    'editPunchClockReviseUserRight': {
      'title': '打卡',
      'edit': '編輯打卡使用者',
    },
    'editScheduleUserRight': {
      'title': '班表',
      'edit': '編輯班表使用者',
    },
    'editReportUserRight': {
      'title': '報表',
      'edit': '編輯報表使用者',
    },
    'editSalesOrderUserRight': {
      'title': '營收單',
      'edit': '編輯營收單使用者',
    },
    'editRefundUserRight': {
      'title': '退費單',
      'edit': '編輯退費單使用者',
    },
    'editReferrerUserRight': {
      'title': '轉介費單',
      'edit': '編輯轉介費單使用者',
    },
    'editConsumeServiceUserRight': {
      'title': '消耗單',
      'edit': '編輯消耗單使用者',
    },
    'editTransferServiceUserRight': {
      'title': '轉讓單',
      'edit': '編輯轉讓單使用者',
    },
    'editTransformServiceUserRight': {
      'title': '轉換單',
      'edit': '編輯轉換單使用者',
    },
  },
  'selectVendorDialog': {
    'title': '選擇廠商',
    'table': {
      'title': '廠商列表'
    }
  },
  'selectSupplierDialog': {
    'title': '選擇供應商',
    'table': {
      'title': '供應商列表'
    }
  },
  'selectCustomerDialog': {
    'title': '選擇客戶',
    'table': {
      'title': '客戶列表'
    }
  },
  'selectServiceProviderDialog': {
    'title': '選擇服務提供商',
    'table': {
      'title': '服務提供商列表'
    }
  },
  'selectSellerDialog': {
    'title': '選擇銷售單位',
    'table': {
      'title': '銷售單位列表'
    }
  },
  'selectAnnouncementTargetDialog': {
    'title': '選擇公告對象',
    'table': {
      'title': '公告對象列表'
    }
  },
}
export default i18n
