import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from '@mui/material/FormControlLabel';

import MaskInput from 'components/MaskInput';
import DatePickerField from 'components/DatePickerField'
import ButtonProgress from 'components/ButtonProgress';
import SelectCustomerDialog from 'components/SelectCustomerDialog';
import SelectUserDialog from 'components/SelectUserDialog';
import { firestoreListener, callFunction } from 'modules/firebase';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EditHistoryPage({ handleClose, tab, customerId, customerMapping }) {
  const { formatMessage } = useIntl()
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [openDialog, setOpenDialog] = useState(null)
  const userMapping = useSelector(state => state.users.data)
  const users = useSelector(state => state.users.ordered)

  const customerExtTab = useSelector(state => state.customerExtTab.data)
  const fieldData = Object.keys(customerExtTab[tab.id] || {}).map(i => customerExtTab[tab.id][i])
  const defaultData = fieldData.reduce((acc, cur) => {
    let newFields = []
    if (cur.check) {
      newFields = Object.keys(customerExtTab[cur.check] || {}).map(i => customerExtTab[cur.check][i])
    }

    if (cur.type === 'select-check') {
      acc[cur.id] = []
    } else if (cur.type === 'check') {
      acc[cur.id] = false
    } else {
      acc[cur.id] = ''
    }

    newFields.forEach(f => {
      if (f.type === 'select-check') {
        acc[f.id] = []
      } else if (f.type === 'check') {
        acc[f.id] = false
      } else {
        acc[f.id] = ''
      }
    })
    return acc
  }, {})
  const [customerData, setCustomerData] = useState(defaultData)

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customerExtData',
      doc: customerId,
      unwraper: true,
      onData: (data) => {
        let newData = {}
        if (data && data[tab.id]) { // NEED FIX: tab.id
          newData = data[tab.id]
        }
        setCustomerData({ ...customerData, ...newData })
      }
    })

    return () => unsubscribe()
  }, []);

  const fields = fieldData.reduce((acc, cur) => {
    let newFields = []
    if (customerData[cur.id]) {
      newFields = Object.keys(customerExtTab[cur.check] || {}).map(i => customerExtTab[cur.check][i])
    }

    acc.push({ md: 3, ...cur })

    newFields.forEach(f => {
      acc.push({ md: 3, ...f })
    })
    return acc
  }, [])

  async function saveCustomerHistory() {
    setLoadingApprove(true)

    try {
      await callFunction('saveCustomerExtData', { id: customerId, tabId: tab.id, ...customerData })
      setLoadingApprove(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  function updateData(field, value) {
    let newValue = value

    if (field.type === 'number') {
      newValue = Number(newValue)
    } else if (field.type === 'date') {
      newValue = dayjs(newValue).format('YYYY-MM-DD')
    }

    let newData = { ...customerData, [field.id]: newValue }

    setCustomerData(newData)
  }

  if (!customerData) {
    return 'Loading...'
  }

  function createField(field) {
    if (['text', 'number'].includes(field.type)) {
      return <Grid item key={field.id} xs={12} sm={field.sm} md={field.md}>
        <TextField
          type="text"
          required={field.required}
          label={field.name}
          value={customerData[field.id]}
          fullWidth
          size="small"
          variant="outlined"
          onChange={e => updateData(field, e.target.value)}
        />
      </Grid>
    } else if (field.type === 'select') {
      return <Grid item key={field.id} xs={12} sm={field.sm} md={field.md}>
        <TextField
          type="text"
          required={field.required}
          label={field.name}
          fullWidth
          size="small"
          variant="outlined"
          value={customerData[field.id]}
          select
          onChange={e => updateData(field, e.target.value)}
        >
          {Object.keys(field.counts).map(i => field.counts[i]).map((count, index) => {
            return <MenuItem key={index} value={count}>{count}</MenuItem>
          })}
        </TextField>
      </Grid>
    } else if (field.type === 'select-check') {
      return <Grid item key={field.id} xs={12} sm={field.md} md={field.md}>
        <FormControl size="small" fullWidth >
          <InputLabel id="demo-multiple-checkbox-label">{field.name}</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={customerData[field.id] ? customerData[field.id] : []}
            onChange={e => { updateData(field, e.target.value) }}
            input={<OutlinedInput label={field.name} />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {Object.keys(field.counts).map(i => field.counts[i]).map((count, index) => {
              return < MenuItem key={index} value={count} >
                <Checkbox checked={(customerData[field.id] ? customerData[field.id] : []).includes(count)} />
                <ListItemText primary={count} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid >
    } else if (field.type === 'date') {
      return <Grid item key={field.id} xs={12} sm={field.sm} md={field.md}>
        <DatePickerField
          fullWidth
          closeToolbar={true}
          label={field.name}
          value={customerData[field.id] !== '' ? dayjs(customerData[field.id]) : null}
          onChange={date => updateData(field, date)}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    } else if (['time', 'phone', 'moblie'].includes(field.type)) {
      const newMask = field.type === 'time' ? '99:99' : field.type === 'phone' ? '9999-999-999' : '(999)9999-9999#99999'
      return <Grid item key={field.id} xs={12} sm={field.sm} md={field.md}>
        <MaskInput
          mask={newMask}
          maskChar=" "
          onChange={e => updateData(field, e.target.value)}
          value={customerData[field.id]}
          label={field.name}
        />
      </Grid>
    } else if (field.type === 'dialog') {
      let newValue = customerData[field.id]
      if (field.dialog === 'users') {
        newValue = userMapping[newValue]?.displayName ?? ''
      } else if (field.dialog === 'customers') {
        newValue = customerMapping[newValue]?.name ?? ''
      }
      return <Grid item key={field.id} xs={12} sm={field.sm} md={field.md}>
        <TextField
          type="text"
          required={field.required}
          label={field.name}
          fullWidth
          size="small"
          variant="outlined"
          value={newValue}
          onClick={() => setOpenDialog({ type: field.dialog, field: field })}
        />
      </Grid>
    } else if (field.type === 'check') {
      return <Grid key={field.name} item xs={12} sm={field.sm} md={field.md}>
        <FormControlLabel
          control={<Checkbox
            checked={customerData[field.id]}
            onChange={e => updateData(field, e.target.checked)}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />}
          label={field.name}
        />
      </Grid>
    }
  }

  function handleDialogSave(data) {
    updateData(openDialog.field, data.id)
  }

  return (
    <>
      {openDialog && openDialog.type === 'customers' && <SelectCustomerDialog
        handleClose={() => setOpenDialog(null)}
        handleSave={handleDialogSave}
        customers={Object.keys(customerMapping || []).map(i => { return { id: i, ...customerMapping[i] } })}
        dialogTitle={formatMessage({ id: 'selectCustomerDialog.title' })}
      />}
      {openDialog && openDialog.type === 'users' && <SelectUserDialog
        headerCells={[{ name: 'displayName', sort: 'displayName' }, { name: 'employeeId', sort: 'employeeId' }]}
        rowCells={[{ field: 'displayName' }, { field: 'employeeId' }]}
        filterItems={[{ name: 'displayName' }, { name: 'employeeId' }]}
        dialogTitle={formatMessage({ id: 'selectUserDialog.title' })}
        handleClose={() => setOpenDialog(null)}
        handleSave={handleDialogSave}
        items={users.filter(u => u.active && !u.developer && u.employeeId !== customerData.employeeId)}
      />}
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'customer.customerExtData.edit.title' }, { name: tab.name })}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ pt: '8px' }}>
            {fields.map(field => createField(field))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonProgress handleClose={handleClose} handleClick={() => saveCustomerHistory()} loading={loadingApprove} buttonText={'button.save'} />
        </DialogActions>
      </Dialog>
    </>
  );
}

EditHistoryPage.propTypes = {
  handleClose: PropTypes.func.isRequired,
  tab: PropTypes.object,
  customerId: PropTypes.string.isRequired,
  customerMapping: PropTypes.object
};

export default EditHistoryPage;
