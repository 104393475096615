import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import MaskInput from 'components/MaskInput';
import EnhancedTable from 'components/EnhancedTable';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import SelectMerchandiseDialog from 'components/SelectMerchandiseDialog';
import SelectSourceOrderDialog from 'components/SelectSourceOrderDialog';
import { getIntersection, unwrap } from 'modules/uitls';
import ContextStore from 'modules/context';
import { addError, removeError } from 'modules/editor';
import { callFunction } from 'modules/firebase';
import { firebaseV8 } from 'modules/firebaseV8';

const numberRule = /[^-.0-9]/g

function MerchandiseItem({ lock = false, extra = false, merchandiseId, items, onItemValueChanged }) {
  const { formatMessage } = useIntl()
  const headerCells = [
    { field: 'amount' },
    { field: 'lotNumber' },
    { field: 'expectedDate' },
  ].map(c => {c.text = formatMessage({ id: `receipt.merchandise.${c.field}` });return c})

  const rowCells = lock ? [
    { field: 'amount' },
    { field: 'lotNumber', type: 'input', required: false, label: '批號', onValueChanged: onCellValueChanged },
    { field: 'expectedDate', type: 'date', required: false, label: '效期', onValueChanged: onCellValueChanged },
  ] : [
    { field: 'amount', type: 'input-number', required: true, label: '到貨數量', onValueChanged: onCellValueChanged },
    { field: 'lotNumber', type: 'input', required: false, label: '批號', onValueChanged: onCellValueChanged },
    { field: 'expectedDate', type: 'date', required: false, label: '效期', onValueChanged: onCellValueChanged },
  ]

  // if (isNaN(value) || value === '' || value === '0') {
  //   addError(m, field, '數量錯誤')
  // } else if (value > data.maxAmount) {
  //   addError(m, field, '超過庫存數量')
  // } else {
  //   removeError(m, field)
  // }


  function onCellValueChanged(data, field, value) {
    const m = items[data.key]
    if (field === 'expectedDate') {
      m.expectedDate = value
      if (value === null) {
        removeError(m, field)
      } else if (value.toString() === 'Invalid Date') {
        addError(m, field, formatMessage({ id: 'form.date.formatError' }))
      } else {
        removeError(m, field)
      }
    } else {
      m[field] = value
    }
    onItemValueChanged(merchandiseId, field, extra)
  }

  return (
    <EnhancedTable
      headerCells={headerCells}
      rowCells={rowCells}
      tableData={items}
    />
  )
}

MerchandiseItem.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
  onItemValueChanged: PropTypes.func.isRequired,
  merchandiseId: PropTypes.string.isRequired,
  extra: PropTypes.bool,
  lock: PropTypes.bool,
};

function EditReceiptPage({ formName }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const navigate = useNavigate()
  const location = useLocation()
  const { receiptId } = useParams()

  let refSn = ''
  let refId = ''
  let receiptSourceField = ''
  if (formName === 'receipt') {
    refSn = 'purchaseOrderSn'
    refId = 'purchaseOrderId'
    receiptSourceField = 'receipt-create'
  } else if (formName === 'borrowingReceipt') {
    refSn = 'borrowingOrderSn'
    refId = 'borrowingOrderId'
    receiptSourceField = 'borrowingReceipt-create'
  } else if (formName === 'returnBorrowingReceipt') {
    refSn = 'borrowingOrderSn'
    refId = 'borrowingOrderId'
    receiptSourceField = 'returnBorrowingReceipt-create'
  }

  const customerMapping = useSelector(state => state.internalVendors.data)
  const customers = useSelector(state => state.internalVendors.ordered)
  const supplierMapping = useSelector(state => state.suppliers.data)

  const filteredCustomers = customers.filter(c => userRights.hasUserRightForVendor(receiptSourceField, c.id))
  const merchandiseMapping = useSelector(state => state.merchandises.data)
  const merchandises = useSelector(state => state.merchandises.ordered)

  const userMapping = useSelector(state => state.merchandises.data)

  const [selectedItems, setSelectedItems] = useState({});
  const [selectedExtraItems, setSelectedExtraItems] = useState({});

  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [openMerchandiseDialog, setOpenMerchandiseDialog] = useState(false);
  const [sourceOrderDialog, setSourceOrderDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lockSource, setLockSource] = useState(false);

  const [sourceOrders, setSourceOrders] = useState([]);
  const [receiptItemMapping, setReceiptItemMapping] = useState({});

  const [rawReceipt, setRawReceipt] = useState({
    id: 'new',
    source: '',
    createdBy: currentUser.key,
    createdAt: { seconds: Math.floor(Date.now() / 1000) },
    date: dayjs().format('YYYY-MM-DD'),
    approvedBy: 'N/A',
    approveDate: 'N/A',
    note: '',
    invoiceNumber: '',
    shippingOut: '',
    // purchaseOrders: [],
    [refId]: '',
    discount: '',
    shippingFee: '',
    supplier: '',
    tax: '',
    // merchandises: [],
    // extraMerchandises: [],
  });

  const [owReceipt, setOwReceipt] = useState({
  });

  let receipt = {}

  const sourceOrderIds = [...new Set(sourceOrders.map(p => p.id).concat(rawReceipt[refId] ? rawReceipt[refId] : []))]
  const sourceOrderSortString = sourceOrderIds.sort().join(',')

  useEffect(() => {
    if (filteredCustomers.length === 1 && receiptId === 'new') {
      updateReceiptData({ name: 'source' }, filteredCustomers[0].id);
    }
  }, [filteredCustomers.length]);

  useEffect(() => {
    let link = ''
    let text = ''
    if (formName === 'receipt') {
      link = '/purchase/receipt/pending'
      text = formatMessage({ id: 'sideMenu.purchase.receipt' })
    } else if (formName === 'borrowingReceipt') {
      link = '/borrowing/borrowingReceipt/pending'
      text = formatMessage({ id: 'sideMenu.borrowing.borrowingReceipt' })
    } else if (formName === 'returnBorrowingReceipt') {
      link = '/borrowing/returnBorrowingReceipt/pending'
      text = formatMessage({ id: 'sideMenu.borrowing.returnBorrowingReceipt' })
    }
    const breadcrumbs = [{ link, text }]
    if (receiptId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: `${formName}.dialog.title.add` }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: `${formName}.dialog.title.edit` }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    if (receiptId !== 'new') { // edit
      const unsubscribe = firebaseV8().collection(`${formName}s`).doc(receiptId)
        .onSnapshot(snapshot => {
          const data = unwrap(snapshot.data())
          setRawReceipt({ id: snapshot.id, ...data })
        }, err => {})
      return () => unsubscribe()
    } else {
      return () => {};
    }
  }, []);

  useEffect(() => {
    const months = formName === 'receipt' ? 5 : 12
    const date = dayjs().subtract(months, 'months').format('YYYY-MM')
    const unsubscribe = sourceOrderIds.length ? firebaseV8().collection(`${formName}s`).where('date', '>=', date)
      .onSnapshot(snapshot => {
        const itemMapping = {}
        snapshot.forEach(doc => {
          const r = unwrap(doc.data())
          if (r[refId] && doc.id !== receiptId && sourceOrderIds.includes(r[refId])) {
            const keys = Object.keys(r.merchandises)
            for (const key of keys) {
              const fullId = `${r[refId]}@${key}`
              if (itemMapping[fullId] === undefined) {
                itemMapping[fullId] = r.merchandises[key].amount
              } else {
                itemMapping[fullId] = itemMapping[fullId] + r.merchandises[key].amount
              }
            }
          }
        });
        setReceiptItemMapping(itemMapping)
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, [sourceOrderSortString]);

  useEffect(() => {
    // 撈最近3個月的採購單
    // const months = formName === 'receipt' ? 2 : 12
    // const date = dayjs().subtract(months, 'months').format('YYYY-MM')
    // const collectionName = formName === 'receipt' ? 'purchaseOrders' : 'borrowingOrders'
    // const unsubscribe = firebase.firestore().collection(collectionName).where('status', '==', 'done').where('date', '>=', date)

    // 不限時間, 撈還沒結單的採購單
    const collectionName = formName === 'receipt' ? 'purchaseOrders' : 'borrowingOrders'
    const unsubscribe = firebaseV8().collection(collectionName).where('status', '==', 'done').where('closeout', '==', false).where('deliveryOrder', '==', false)
      .onSnapshot( snapshot => {
        const sourceOrderList = []
        snapshot.forEach(doc => {
          const sourceOrder = unwrap(doc.data())
          if (userRights.hasUserRightForVendor(receiptSourceField, sourceOrder.source)) {
            sourceOrderList.push({ id: doc.id, ...sourceOrder })
          }
        });
        setSourceOrders(sourceOrderList)
      }, err => {})
    return () => unsubscribe()
  }, []);

  if (receiptId !== 'new' && !rawReceipt.merchandises) {
    return ('Loading...')
  }

  const merchandiseKeys = Object.keys(rawReceipt.merchandises || {}).filter(m => merchandiseMapping[m])
  const extraMerchandiseKeys = Object.keys(rawReceipt.extraMerchandises || {}).filter(m => merchandiseMapping[m])
  receipt = { ...{
    id: receiptId,
    source: rawReceipt.source,
    createdBy: rawReceipt.createdBy,
    createdAt: rawReceipt.createdAt.seconds * 1000,
    date: rawReceipt.date,
    approveDate: rawReceipt.approveDate,
    approvedBy: rawReceipt.approvedBy,
    note: rawReceipt.note,
    invoiceNumber: rawReceipt.invoiceNumber || '',
    shippingOut: rawReceipt.shippingOut || '',
    lock: rawReceipt.lock,
    discount: rawReceipt.discount,
    shippingFee: rawReceipt.shippingFee || '',
    supplier: rawReceipt.supplier,
    tax: rawReceipt.tax ?? '',
    merchandises: merchandiseKeys.map(m => merchandiseMapping[m]).map(m => ({
      orderUnit: m.orderUnit,
      id: m.id,
      items: rawReceipt.merchandises[m.id].items ? rawReceipt.merchandises[m.id].items.map((i, idx) => ({
        key: String(idx),
        amount: i.amount,
        expectedDate: i.expectedDate ? dayjs(i.expectedDate).toDate() : null,
        lotNumber: i.lotNumber,
      })) : undefined,
      poAmount: rawReceipt.merchandises[m.id].poAmount,
      amount: rawReceipt.merchandises[m.id].amount,
      expectedDate: rawReceipt.merchandises[m.id].expectedDate ? dayjs(rawReceipt.merchandises[m.id].expectedDate).toDate() : null,
      lotNumber: rawReceipt.merchandises[m.id].lotNumber,
      [refId]: rawReceipt[refId],
      [refSn]: rawReceipt.merchandises[m.id][refSn],
      unitPrice: rawReceipt.merchandises[m.id].unitPrice,
      discount: rawReceipt.merchandises[m.id].discount || '',
      warehousing: rawReceipt.merchandises[m.id].warehousing,
      note: rawReceipt.merchandises[m.id].note,
    })),
    extraMerchandises: extraMerchandiseKeys.map(m => merchandiseMapping[m]).map(m => ({
      code: m.code,
      orderUnit: m.orderUnit,
      name: m.name,
      nickname: m.nickname,
      id: m.id,
      items: rawReceipt.extraMerchandises[m.id].items ? rawReceipt.extraMerchandises[m.id].items.map((i, idx) => ({
        key: String(idx),
        amount: i.amount,
        expectedDate: i.expectedDate ? dayjs(i.expectedDate).toDate() : null,
        lotNumber: i.lotNumber,
      })) : undefined,
      amount: rawReceipt.extraMerchandises[m.id].amount,
      expectedDate: rawReceipt.extraMerchandises[m.id].expectedDate ? dayjs(rawReceipt.extraMerchandises[m.id].expectedDate).toDate() : null,
      lotNumber: rawReceipt.extraMerchandises[m.id].lotNumber,
      unitPrice: rawReceipt.extraMerchandises[m.id].unitPrice,
      warehousing: rawReceipt.extraMerchandises[m.id].warehousing,
      note: rawReceipt.extraMerchandises[m.id].note,
    }))
  }, ...owReceipt }
  receipt.merchandises.forEach(m => {
    m.maxAmount = m.poAmount - (receiptItemMapping[`${m[refId]}@${m.id}`] || 0)
  })

  let availableSuppliers = []
  if (receipt.lock && !rawReceipt.supplier) {
    const mm = receipt.extraMerchandises.map(m => merchandiseMapping[m.id])
    let supplierList = []
    for (const i in mm) {
      const m = mm[i]
      if (Number(i) === 0) {
        supplierList = Object.keys(m.suppliers)
      } else {
        supplierList = getIntersection(supplierList, Object.keys(m.suppliers))
      }
    }
    availableSuppliers = supplierList.map(s => supplierMapping[s])
  }

  const currentSourceOrder = receipt.merchandises.length ? sourceOrders.find(p => p.id === receipt.merchandises[0][refId]) : null
  const filteredSourceOrders = currentSourceOrder ? [currentSourceOrder] : (receipt.source !== '' ? sourceOrders.filter(p =>  p.source === receipt.source) : [])

  const getShippingOutList = () => {
    if (receipt.supplier) {
      return Object.keys(supplierMapping[receipt.supplier].shippingOut).map(s => supplierMapping[s])
    } else {
      return []
    }
  }

  const shippingOutList = getShippingOutList()

  // 預設一定會有的 header row 欄位
  const _headerCells = [
    { field: refSn, order: 0 },
    { field: 'code', sort: 'code', order: 1 },
    { field: 'name', order: 2 },
    { field: 'amount', align: 'right', order: 4 },
    { field: 'orderUnit', align: 'right', order: 5 },
    { field: 'note', align: 'right', order: 10 },
  ]//.map(c => {c.text = formatMessage({id: `${formName}.merchandise.${c.text}`});return c})

  // receipt.lock -> 可編輯
  // receipt.createdBy !== 'N/A' -> 由出貨單自動產生的進貨單
  const headerCells = (receipt.lock ?
    [..._headerCells,
      ...(receipt.createdBy !== 'N/A' ? [
        { field: 'unitPrice', align: 'right', order: 3 },
        { field: 'price', align: 'right', order: 6 },
        { field: 'discount', align: 'right', order: 7 },
        { field: 'lotNumber', align: 'right', order: 8 },
        { field: 'expectedDate', align: 'right', order: 9 }
      ] : [
        { field: 'unitPrice', align: 'right', order: 3 },
        { field: 'price', align: 'right', order: 6 }
      ])
    ] :
    [..._headerCells,
      { field: 'lotNumber', align: 'right', order: 8 },
      { field: 'expectedDate', align: 'right', order: 9 },
    ]
  ).map(c => {c.text = formatMessage({ id: `${formName}.merchandise.${c.field}` });return c}).sort((a, b) => a.order - b.order)

  // 預設一定會有的 body row 欄位
  const _rowCells = [
    { field: refSn, order: 0 },
    { field: 'code', order: 1 },
    { field: 'nickname', order: 2, tooltip: 'name' },
    { field: 'orderUnit', align: 'right', order: 5 },
    { field: 'note', align: 'right', order: 10, type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged },
  ]

  // receipt.lock -> 可編輯
  // receipt.createdBy !== 'N/A' -> 由出貨單自動產生的進貨單
  const rowCells = (receipt.lock ?
    [..._rowCells,
      ...(receipt.createdBy !== 'N/A' ? [
        { field: 'unitPrice', align: 'right', order: 3 },
        { field: 'amount', align: 'right', order: 4 },
        { field: 'price', align: 'right', order: 6, type: 'calculate', calculate: 'unitPrice*amount' },
        { field: 'discount', align: 'right', order: 7, type: 'input-number', inputRule: /[^0-9.]/g, required: false, label: '商品折扣', onValueChanged: onCellValueChanged },
        { field: 'lotNumber', align: 'right', order: 8, type: 'input', required: false, label: '批號', onValueChanged: onCellValueChanged, getEnableStatus: getEnableStatus },
        { field: 'expectedDate', align: 'right', order: 9, type: 'date', required: false, label: '效期', onValueChanged: onCellValueChanged, getEnableStatus: getEnableStatus }
      ] : [
        { field: 'unitPrice', align: 'right', order: 3 },
        { field: 'amount', align: 'right', order: 4 },
        { field: 'price', align: 'right', order: 6, type: 'calculate', calculate: 'unitPrice*amount' }
      ])
    ] :
    [..._rowCells,
      { field: 'amount', align: 'right', order: 4, type: 'input-number', required: true, label: '到貨數量', onValueChanged: onCellValueChanged, getEnableStatus: getEnableStatus },
      { field: 'lotNumber', align: 'right', order: 8, type: 'input', required: false, label: '批號', onValueChanged: onCellValueChanged, getEnableStatus: getEnableStatus },
      { field: 'expectedDate', align: 'right', order: 9, type: 'date', required: false, label: '效期', onValueChanged: onCellValueChanged, getEnableStatus: getEnableStatus },
    ]
  ).sort((a, b) => a.order - b.order)

  const headerExCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    { text: 'amount', align: 'right' },
    { text: 'orderUnit', align: 'right' },
    { text: 'lotNumber', align: 'right' },
    { text: 'expectedDate', align: 'right' },
    { text: 'note', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `${formName}.merchandise.${c.text}` });return c})

  const _rowExCells = [
    { field: 'code', order: 0 },
    { field: 'nickname', order: 1, tooltip: 'name' },
    { field: 'lotNumber', align: 'right', order: 4, type: 'input', required: false, label: '批號', onValueChanged: onCellValueChanged, getEnableStatus },
    { field: 'expectedDate', align: 'right', order: 5, type: 'date', required: false, label: '效期', onValueChanged: onCellValueChanged, getEnableStatus },
    { field: 'note', align: 'right', order: 6, type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged },
  ]

  const rowExCells = (receipt.lock ?
    [..._rowExCells,
      { field: 'amount', align: 'right', order: 2 },
      { field: 'orderUnit', align: 'right', order: 3 }
    ] : [..._rowExCells,
      { field: 'amount', align: 'right', order: 2, type: 'input-number', required: true, label: '到貨數量', onValueChanged: onCellValueChanged, getEnableStatus },
      { field: 'orderBy',
        align: 'right',
        type: 'input-select',
        required: true,
        label: formatMessage({ id: `${formName}.merchandise.orderUnit` }),
        onValueChanged: onCellValueChanged,
        getMenuItems: getUnitList,
        minWidth: '80px',
        order: 3,
      },
    ]
  ).sort((a, b) => a.order - b.order)

  function getUnitList(merchandise) {
    if (merchandise.orderUnit === merchandise.sku) {
      return [{ label: merchandise.orderUnit, value: 'ou' }]
    }
    return [{ label: `${merchandise.orderUnit}(${merchandise.ou2sku}${merchandise.sku})`, value: 'ou' }, { label: merchandise.sku, value: 'sku' }]
  }

  function formatData(merchandise) {
    const newData = {
      ...merchandise,
      code: merchandiseMapping[merchandise.id].code,
      name: merchandiseMapping[merchandise.id].name,
      nickname: merchandiseMapping[merchandise.id].nickname
    }
    newData.require = newData.amount + newData.orderUnit
    if (newData.amount < newData.moq) newData.amount = newData.moq
    return newData
  }

  function getEnableStatus(merchandise, field) {
    if (merchandise.items) {
      return false
    }
    return true
  }

  function formatDataM(merchandise) { // 只處理要傳給 SelectMerchandiseDialog 的資料
    const newData = { ...merchandise }
    if (newData.orderBySku[receipt.source]) {
      newData.ou = newData.sku
    } else if (newData.orderUnit !== newData.sku) {
      newData.ou = `${newData.orderUnit}(${newData.ou2sku}${newData.sku})`
    } else {
      newData.ou = newData.orderUnit
    }
    return newData
  }

  function onCellValueChanged(data, field, value) {
    if (!data.extar) {
      for (let m of receipt.merchandises) {
        if (m.id === data.id && m[refId] === data[refId]) {
          if (field === 'amount') {
            m.amount = value
            if (isNaN(value) || value === '' || value === '0') {
              addError(m, field, '數量錯誤')
            } else if (value > data.maxAmount) {
              addError(m, field, '超過請購數量')
            } else {
              removeError(m, field)
            }
          } else if (field === 'expectedDate') {
            m.expectedDate = value
            if (value === null) {
              removeError(m, field)
            } else if (value.toString() === 'Invalid Date') {
              addError(m, field, formatMessage({ id: 'form.date.formatError' }))
            } else {
              removeError(m, field)
            }
          } else {
            m[field] = value
          }
          break
        }
      }
      updateReceiptData({ name: 'merchandises' }, receipt.merchandises);
    } else {
      for (let m of receipt.extraMerchandises) {
        if (m.id === data.id) {
          if (field === 'amount') {
            m.amount = value
            if (isNaN(value) || value === '' || value === '0') {
              addError(m, field, '數量錯誤')
            } else {
              removeError(m, field)
            }
          } else if (field === 'expectedDate') {
            m.expectedDate = value
            if (value === null) {
              removeError(m, field)
            } else if (value.toString() === 'Invalid Date') {
              addError(m, field, formatMessage({ id: 'form.date.formatError' }))
            } else {
              removeError(m, field)
            }
          } else {
            m[field] = value
          }
          break
        }
      }
      updateReceiptData({ name: 'extraMerchandises' }, receipt.extraMerchandises);
    }
  }

  function onItemValueChanged(id, field, extra) {
    const merchandises = extra ? receipt.extraMerchandises : receipt.merchandises
    if (field === 'amount') {
      for (const m of merchandises) {
        if (m.id === id) {
          // 更新 parent 的 amount, 檢查看看有沒有超過最大值
          let total = 0
          for (const i of m.items) {
            if (isNaN(i.amount) || i.amount === '' || parseInt(i.amount) === 0) {
            } else {
              total += parseInt(i.amount)
            }
          }
          m.amount = total
          if (!extra && total > m.maxAmount) { // 贈品就沒有最大值的限制
            if (!m.errors) {
              m.errors = {}
            }
            m.errors.amount = '超過請購數量'
          } else {
            if (m.errors && m.errors.amount) {
              delete m.errors.amount
            }
          }
          break
        }
      }
    }
    if (extra) {
      updateReceiptData({ name: 'extraMerchandises' }, merchandises)
    } else {
      updateReceiptData({ name: 'merchandises' }, merchandises)
    }
  }

  function onAddMerchandises(merchandises) {
    if (merchandises.length) {
      for (let m of merchandises) {
        m.expectedDate = null
        m.lotNumber = ''
        m.discount = ''
        m.note = ''
      }
      updateReceiptData({ name: 'merchandises' }, receipt.merchandises.concat(merchandises));
    }
  }

  function onMerchandiseChanged(merchandises) {
    if (merchandises.length) {
      for (let m of merchandises) {
        m.amount = '1'
        m.lotNumber = ''
        m.expectedDate = null
        m.note = ''
        m.orderBy = 'ou'
        m.extar = true
      }
      updateReceiptData({ name: 'extraMerchandises' }, receipt.extraMerchandises.concat(merchandises));
    }
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = receipt.merchandises.reduce((acc, cur) => {acc[cur.id] = true;return acc}, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  }

  function handleCheckboxClick({ id }) {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function onDeleteItems() {
    const merchandises = receipt.merchandises.filter(m => !selectedItems[m.id])
    if (merchandises.length !== receipt.merchandises.length) {
      updateReceiptData({ name: 'merchandises' }, merchandises);
      setSelectedItems({});
    }
  }

  function onAddLotNumber() {
    const merchandises = receipt.merchandises
    for (const m of merchandises) {
      if (selectedItems[m.id]) {
        if (!m.items) {
          m.items = [{
            key: '0',
            amount: m.amount,
            lotNumber: m.lotNumber,
            expectedDate: m.expectedDate,
          }]
          m.lotNumber = ''
          m.expectedDate = null
        }
        m.items.push({
          key: String(m.items.length),
          amount: 0,
          lotNumber: '',
          expectedDate: null,
        })
      }
    }
    setSelectedItems({});
    updateReceiptData({ name: 'merchandises' }, merchandises);
  }

  function handleSelectAllExtraClick(event) {
    if (event.target.checked) {
      const newSelecteds = receipt.extraMerchandises.reduce((acc, cur) => {acc[cur.id] = true;return acc}, {});
      setSelectedExtraItems(newSelecteds);
      return;
    }
    setSelectedExtraItems({});
  }

  function handleExtraCheckboxClick({ id }) {
    const selected = selectedExtraItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedExtraItems }
      delete newSelecteds[id]
      setSelectedExtraItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedExtraItems, [id]: true }
      setSelectedExtraItems(newSelecteds);
    }
  }

  function onAddExtraItemsLotNumber() {
    const merchandises = receipt.extraMerchandises
    for (const m of merchandises) {
      if (selectedExtraItems[m.id]) {
        if (!m.items) {
          m.items = [{
            key: '0',
            amount: m.amount,
            lotNumber: m.lotNumber,
            expectedDate: m.expectedDate,
          }]
          m.lotNumber = ''
          m.expectedDate = null
        }
        m.items.push({
          key: String(m.items.length),
          amount: 0,
          lotNumber: '',
          expectedDate: null,
        })
      }
    }
    setSelectedExtraItems({});
    updateReceiptData({ name: 'extraMerchandises' }, merchandises);
  }

  function onDeleteExtraItems() {
    const merchandises = receipt.extraMerchandises.filter(m => !selectedExtraItems[m.id])
    if (merchandises.length !== receipt.extraMerchandises.length) {
      updateReceiptData({ name: 'extraMerchandises' }, merchandises);
      setSelectedExtraItems({});
    }
  }

  function validateField(field, value) {
    // if (field.required && value === '') {
    //   return formatMessage({id: 'form.field.isRequired'})
    // }
    if (field.name === 'merchandises') {
      for (const m of value) {
        if (m.errors && m.errors.amount) {
          return m.errors.amount
        }
      }
    } else if (field.name === 'invoiceNumber' && value && value !== '  -        ' && value.trim().length !== 11) {
      return formatMessage({ id: 'form.invoiceNumber.formatError' })
    }
    return ''
  }

  function updateReceiptData(field, value) {
    let newValue = value
    if (field.uppercase) {
      newValue = newValue.toUpperCase()
    }

    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }
    // if (field.maxLength) {
    //   newValue = newValue.substring(0, field.maxLength)
    // }

    // if (supplierData[field.name] === newValue) return;

    let err = validateField(field, value)

    let newData = { ...owReceipt, [field.name]: newValue, [`${field.name}_err`]: err }
    setOwReceipt(newData)
  }

  async function handleSave() {
    setLoading(true);

    const fields = [
      { name: 'source' },
      // {name: 'createdBy'},
      { name: 'date' },
      { name: 'merchandises' },
      { name: 'extraMerchandises' },
      { name: 'note' },
      { name: 'discount' },
      { name: 'shippingFee' },
      { name: 'invoiceNumber' },
      { name: 'shippingOut' },
      { name: 'tax' }
    ]

    // 檢查是否有欄位的值標示了 error - start
    let newData = receipt
    for (const merchandise of newData.merchandises) {
      if (merchandise.items) { // 多批號
        for (const item of merchandise.items) {
          if (item.expectedDate && item.expectedDate.toString() === 'Invalid Date') {
            setLoading(false);
            return
          }
        }
      } else { // 單一批號
        if (merchandise.expectedDate && merchandise.expectedDate.toString() === 'Invalid Date') {
          setLoading(false);
          return
        }
      }
    }
    if (formName === 'receipt') { // 如果是進貨單, 檢查贈品欄位
      for (const merchandise of newData.extraMerchandises) {
        if (merchandise.items) { // 多批號
          for (const item of merchandise.items) {
            if (item.expectedDate && item.expectedDate.toString() === 'Invalid Date') {
              setLoading(false);
              return
            }
          }
        } else { // 單一批號
          if (merchandise.expectedDate && merchandise.expectedDate.toString() === 'Invalid Date') {
            setLoading(false);
            return
          }
        }
      }
    }

    for (let field of fields) {
      if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        setLoading(false);
        return
      }
    }
    // 檢查是否有欄位的值標示了 error - end

    let data = {}
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.merchandises = data.merchandises.reduce((acc, cur) => {
      acc[cur.id] = {
        warehousing: !!merchandiseMapping[cur.id].warehousing[data.source],
        [refId]: cur[refId],
        [refSn]: cur[refSn],
        poAmount: parseInt(cur.poAmount),
        amount: parseInt(cur.amount),
        note: cur.note,
      }

      if (cur.orderBySku === 'sku') {
        acc[cur.id].orderBySku = true
      }

      if (formName === 'receipt') {
        acc[cur.id].unitPrice = parseFloat(cur.unitPrice)
      }

      if (cur.items) {
        acc[cur.id].items = []
        for (const item of cur.items) {
          const amount = (isNaN(item.amount) || item.amount === '' || parseInt(item.amount) === 0) ? 0 : parseInt(item.amount)
          if (amount) {
            const expectedDate = (item.expectedDate === null || item.expectedDate.toString() === 'Invalid Date') ?
              '' : dayjs(item.expectedDate).format('YYYY-MM-DD')
            acc[cur.id].items.push({
              amount,
              lotNumber: item.lotNumber || '',
              expectedDate,
            })
          }
        }
        if (receipt.id === 'new' && acc[cur.id].items.length === 1) {
          // 如果選擇了多批號進貨, 但是最後卻只有一個批號, 那就自動以單批號的格式儲存
          acc[cur.id].lotNumber = acc[cur.id].items[0].lotNumber
          acc[cur.id].expectedDate = acc[cur.id].items[0].expectedDate
          delete acc[cur.id].items
        }
      } else {
        const expectedDate = (cur.expectedDate === null || cur.expectedDate.toString() === 'Invalid Date') ?
          '' : dayjs(cur.expectedDate).format('YYYY-MM-DD')
        acc[cur.id].lotNumber = cur.lotNumber || ''
        acc[cur.id].expectedDate = expectedDate
      }

      if (cur.discount) {
        acc[cur.id].discount = parseFloat(cur.discount)
      }
      return acc
    }, {})

    if (formName === 'receipt') { // 如果是進貨單, 處理贈品欄位
      data.extraMerchandises = data.extraMerchandises.reduce((acc, cur) => {
        const expectedDate = (cur.expectedDate === null || cur.expectedDate.toString() === 'Invalid Date') ?
          '' : dayjs(cur.expectedDate).format('YYYY-MM-DD')
        const m = merchandiseMapping[cur.id]
        acc[cur.id] = {
          warehousing: !!m.warehousing[data.source],
          amount: parseInt(cur.amount),
          orderBy: cur.orderBy,
          unitPrice: 0,
          lotNumber: cur.lotNumber || '',
          expectedDate,
          note: cur.note,
        }
        if (cur.orderBy === m.sku) {
          acc[cur.id].orderBySku = true
        }
        if (cur.items) {
          acc[cur.id].items = []
          for (const item of cur.items) {
            const amount = (isNaN(item.amount) || item.amount === '' || parseInt(item.amount) === 0) ? 0 : parseInt(item.amount)
            if (amount) {
              const expectedDate = (item.expectedDate === null || item.expectedDate.toString() === 'Invalid Date') ?
                '' : dayjs(item.expectedDate).format('YYYY-MM-DD')
              acc[cur.id].items.push({
                amount,
                lotNumber: item.lotNumber || '',
                expectedDate,
              })
            }
          }
          if (receipt.id === 'new' && acc[cur.id].items.length === 1) {
            // 如果選擇了多批號進貨, 但是最後卻只有一個批號, 那就自動以單批號的格式儲存
            acc[cur.id].lotNumber = acc[cur.id].items[0].lotNumber
            acc[cur.id].expectedDate = acc[cur.id].items[0].expectedDate
            delete acc[cur.id].items
          }
        }
        return acc
      }, {})
    } else {
      delete data.extraMerchandises
    }

    // 標記這張進貨單的上一個來源單據
    // 採購單 -> 進貨單
    // 借貨出貨單 -> 借貨入庫單
    // 借貨歸還單 -> 借貨歸還入庫單
    data[refId] = currentSourceOrder ? currentSourceOrder.id : ''
    if (currentSourceOrder) {
      // NOTE: 有一種情形可能會沒有來源單據, 就是當使用者直接建立進貨單, 然後加入贈品. 這時就不會有來源單據
      data.supplier = currentSourceOrder.supplier
    }
    if (data.tax === '') {
      delete data.tax
    }
    data.discount = parseFloat(data.discount || 0) // 現金折扣
    data.shippingFee = parseFloat(data.shippingFee || 0) // 運費
    if (data.tax) {
      data.tax = parseFloat(data.tax || 0) // 營業稅
    }

    if (receipt.lock && !rawReceipt.supplier && receipt.supplier) {
      data.supplier = receipt.supplier
    }

    try {
      await callFunction('saveReceipt', { id: receipt.id, formName, ...data })
    } catch (ex) {
      console.log(ex)
    }
    handleClose()
  }

  function handleClose() {
    if (formName === 'receipt') {
      if (receiptId === 'new') {
        navigate('/purchase/receipt/pending');
      } else {
        navigate('/purchase/receipt/done');
      }
    } else if (formName === 'borrowingReceipt') {
      navigate('/borrowing/borrowingReceipt/pending');
    } else if (formName === 'returnBorrowingReceipt') {
      navigate('/borrowing/returnBorrowingReceipt/pending');
    }
  }

  function getSourceSelector() {
    if (userRights.onlyVendor(receiptSourceField) || receiptId !== 'new') {
      const value = receiptId !== 'new' ?
        (customerMapping[receipt.source] ? customerMapping[receipt.source].nickname : '') :
        (customerMapping[userRights.onlyVendor(receiptSourceField)] ? customerMapping[userRights.onlyVendor(receiptSourceField)].nickname : '')

      return (<TextField
        disabled
        type="text"
        label={formatMessage({ id: 'receipt.table.detail.source' })}
        variant="outlined"
        value={value}
        fullWidth
        size="small"
      />)
    } else {
      return (<TextField
        select
        required
        disabled={lockSource}
        type="text"
        label={formatMessage({ id: 'receipt.table.detail.source' })}
        variant="outlined"
        value={receipt.source}
        onChange={e => updateReceiptData({ name: 'source' }, e.target.value)}
        fullWidth
        size="small"
        error={receipt.source_err ? true : false}
        helperText={receipt.source_err}
      >
        {filteredCustomers.map(c => <MenuItem key={c.id} value={c.id}>
          {c.nickname}
        </MenuItem>)}
      </TextField>)
    }
  }

  // const invoiceSubtotal = receipt.merchandises.reduce((acc, cur) => {
  //   acc += cur.unitPrice * cur.amount
  //   return acc;
  // }, 0)
  // const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  // const invoiceTotal = invoiceTaxes + invoiceSubtotal;

  const extraMerchandises = (receipt.extraMerchandises || []).reduce((acc, cur) => {
    acc[cur.id] = true
    return acc
  }, {});

  return (
    <div style={{ flexGrow: 1 }}>
      {sourceOrderDialog && <SelectSourceOrderDialog
        sourceOrders={filteredSourceOrders}
        defaultSelectedItems={[]}
        handleClose={() => setSourceOrderDialog(false)}
        handleSave={onAddMerchandises}
        ignoreIds={receipt.merchandises.map(m => `${m[refId]}@${m.id}`)}
        receiptItemMapping={receiptItemMapping}
        refId={refId}
        refSn={refSn}
      />}
      {openMerchandiseDialog && <SelectMerchandiseDialog
        multiSelect
        maxWidth="lg"
        headerCells={[{ name: 'code', sort: 'code' },{ name: 'nickname', sort: 'nickname' },{ name: 'orderUnit' },{ name: 'note' }]}
        rowCells={[{ field: 'code' },{ field: 'nickname', tooltip: 'name' },{ field: 'ou' },{ field: 'note' }]}
        filterItems={[{ name: 'nickname' },{ name: 'name' },{ name: 'code' },{ name: 'note' }]}
        dialogTitle={formatMessage({ id: 'selectMerchandiseDialog.title' })}
        tableTitle={formatMessage({ id: 'selectMerchandiseDialog.table.title' })}
        handleClose={() => setOpenMerchandiseDialog(false)}
        handleSave={onMerchandiseChanged}
        defaultSelectedItems={[]}
        items={merchandises.filter(m => !extraMerchandises[m.id]).map(m => formatDataM(m))}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            {getSourceSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'receipt.table.detail.createdBy' })}
              variant="outlined"
              value={userMapping[receipt.approvedBy] ? userMapping[receipt.approvedBy].displayName : 'N/A'}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'receipt.table.detail.createdAt' })}
              variant="outlined"
              value={dayjs(receipt.createdAt).format('YYYY-MM-DD')}
              fullWidth
              size="small"
            />
          </Grid>
          {receipt.lock && <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'receipt.table.detail.date' })}
              variant="outlined"
              value={receipt.approveDate}
              fullWidth
              size="small"
            />
          </Grid>}
          {receipt.lock && <Grid item xs={12} sm={6} md={3}>
            <MaskInput
              mask="aa-99999999"
              maskChar=" "
              onChange={e => updateReceiptData({ name: 'invoiceNumber', uppercase: true }, e.target.value)}
              value={receipt.invoiceNumber}
              label={formatMessage({ id: 'receipt.table.detail.invoiceNumber' })}
              helperText={receipt.invoiceNumber_err}
            />
          </Grid>}
          {receipt.lock && !rawReceipt.supplier && <Grid item xs={12} sm={6} md={3}>
            <TextField
              select
              disabled={availableSuppliers.length === 0}
              type="text"
              label={formatMessage({ id: 'receipt.table.detail.supplier' })}
              variant="outlined"
              value={receipt.supplier || ''}
              onChange={e => updateReceiptData({ name: 'supplier' }, e.target.value)}
              fullWidth
              size="small"
              error={receipt.supplier_err ? true : false}
              helperText={receipt.supplier_err}
            >
              {availableSuppliers.map(c => <MenuItem key={c.id} value={c.id}>
                {c.nickname}
              </MenuItem>)}
            </TextField>
          </Grid>}
          {receipt.lock && receipt.createdBy !== 'N/A' && <Grid item xs={12} sm={6} md={3}>
            <TextField
              select
              disabled={shippingOutList.length === 0}
              type="text"
              label={formatMessage({ id: 'receipt.table.detail.shippingOut' })}
              variant="outlined"
              value={receipt.shippingOut}
              onChange={e => updateReceiptData({ name: 'shippingOut' }, e.target.value)}
              fullWidth
              size="small"
              error={receipt.shippingOut_err ? true : false}
              helperText={receipt.shippingOut_err}
            >
              {shippingOutList.map(c => <MenuItem key={c.id} value={c.id}>
                {c.nickname}
              </MenuItem>)}
            </TextField>
          </Grid>}
        </Grid>
        <Divider style={{ margin: '8px 0px' }} />
        <EnhancedTableToolbar
          title="editReceipt.table.title"
          selectdMessage="editReceipt.table.selected"
          numSelected={Object.keys(selectedItems).length}
          toolbox={
            receipt.lock ? null : <Button
              disabled={receipt.source === '' || (currentSourceOrder && Object.keys(currentSourceOrder.merchandises).length === receipt.merchandises.length)}
              sx={{ margin: 1, whiteSpace: 'nowrap' }}
              variant="contained"
              color="primary"
              onClick={() => {
                setLockSource(true)
                setSourceOrderDialog(true)
              }}
            >
              <FormattedMessage id={formName === 'receipt' ? 'editReceipt.addFromPurchaseOrder' : 'editReceipt.addFromBorrowingOrder'} />
            </Button>
          }
          toolboxForSelection={
            <>
              <Button
                variant="contained"
                color="primary"
                style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
                onClick={onAddLotNumber}
              >
                <FormattedMessage id="editReceipt.addLotNumber" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<DeleteIcon />}
                style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
                onClick={onDeleteItems}
              >
                <FormattedMessage id="editReceipt.removeMerchandise" />
              </Button>
            </>
          }
        />
        <EnhancedTable
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={headerCells}
          rowCells={rowCells}
          onHeaderCheckboxClick={receipt.lock ? undefined : handleSelectAllClick}
          onRowCheckboxClick={receipt.lock ? undefined : handleCheckboxClick}
          getSelectionCount={() => Object.keys(selectedItems).length}
          getRowCheckBoxStatus={merchandise => selectedItems[merchandise.id] || false}
          getRowExpandedStatus={merchandise => merchandise.items ? true : false}
          forceExpanded={true}
          getExpandContent={(merchandise) =>
            merchandise.items && receipt.createdBy !== 'N/A' ? <MerchandiseItem
              merchandiseId={merchandise.id}
              items={merchandise.items}
              onItemValueChanged={onItemValueChanged}
              lock={receipt.lock}
            /> : null
          }
          tableData={receipt.merchandises.map(m => formatData(m))}
        />
        {formName === 'receipt' && receipt.createdBy !== 'N/A' && <>
          <Divider style={{ margin: '8px 0px' }} />
          <EnhancedTableToolbar
            title="editReceipt.table.title2"
            selectdMessage="editReceipt.table.selected"
            numSelected={Object.keys(selectedExtraItems).length}
            toolbox={
              receipt.lock ? null : <Button
                disabled={receipt.source === ''}
                sx={{ margin: 1, whiteSpace: 'nowrap' }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setLockSource(true)
                  setOpenMerchandiseDialog(true)
                }}
              >
                <FormattedMessage id="editReceipt.addExtraMerchandise" />
              </Button>
            }
            toolboxForSelection={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
                  onClick={onAddExtraItemsLotNumber}
                >
                  <FormattedMessage id="editReceipt.addExtraItemsLotNumber" />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DeleteIcon />}
                  style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
                  onClick={onDeleteExtraItems}
                >
                  <FormattedMessage id="editReceipt.removeExtraMerchandise" />
                </Button>
              </>
            }
          />
          <EnhancedTable
            defaultOrder="asc"
            defaultOrderField="code"
            headerCells={headerExCells}
            rowCells={rowExCells}
            onHeaderCheckboxClick={receipt.lock ? undefined : handleSelectAllExtraClick}
            onRowCheckboxClick={receipt.lock ? undefined : handleExtraCheckboxClick}
            getSelectionCount={() => Object.keys(selectedExtraItems).length}
            getRowCheckBoxStatus={merchandise => selectedExtraItems[merchandise.id] || false}
            getRowExpandedStatus={merchandise => merchandise.items ? true : false}
            forceExpanded={true}
            getExpandContent={(merchandise) =>
              merchandise.items && receipt.createdBy !== 'N/A' ? <MerchandiseItem
                merchandiseId={merchandise.id}
                items={merchandise.items}
                onItemValueChanged={onItemValueChanged}
                extra
                lock={receipt.lock}
              /> : null
            }
            tableData={receipt.extraMerchandises}
          />
        </>}

        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          {receipt.lock && receipt.createdBy !== 'N/A' && <Grid item xs={12} sm={6} md={3}>
            <TextField
              type="text"
              label={formatMessage({ id: 'receipt.table.detail.discount' })}
              variant="outlined"
              value={receipt.discount}
              onChange={e => updateReceiptData({ name: 'discount', allowCharacter: numberRule }, e.target.value)}
              fullWidth
              size="small"
            />
          </Grid>}
          {receipt.lock && <Grid item xs={12} sm={6} md={3}>
            <TextField
              type="text"
              label={formatMessage({ id: 'receipt.table.detail.tax' })}
              variant="outlined"
              value={receipt.tax}
              onChange={e => updateReceiptData({ name: 'tax', allowCharacter: numberRule }, e.target.value)}
              fullWidth
              size="small"
            />
          </Grid>}
          {receipt.lock && <Grid item xs={12} sm={6} md={3}>
            <TextField
              type="text"
              label={formatMessage({ id: 'receipt.table.detail.shippingFee' })}
              variant="outlined"
              value={receipt.shippingFee}
              onChange={e => updateReceiptData({ name: 'shippingFee', allowCharacter: numberRule }, e.target.value)}
              fullWidth
              size="small"
            />
          </Grid>}
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'receipt.table.detail.note' })}
              variant="outlined"
              value={receipt.note}
              onChange={e => updateReceiptData({ name: 'note' }, e.target.value)}
              fullWidth
              size="small"
              multiline
            />
          </Grid>
        </Grid>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={loading || (receipt.merchandises.length === 0 && receipt.extraMerchandises.length === 0)}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.submit" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}

EditReceiptPage.propTypes = {
  formName: PropTypes.string.isRequired,
};

export default EditReceiptPage;
