import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import ButtonProgress from 'components/ButtonProgress';
import { exportFields } from 'modules/data';

function ExportProductListDialog({ handleClose, products }) {
  const { formatMessage } = useIntl()
  const [loadingApprove, setLoadingApprove] = useState(false);
  const fields = [
    { name: 'name', sm: 3 },
    { name: 'nickname', sm: 3 },
    { name: 'tags', sm: 3, },
    { name: 'cost', sm: 3, }, // 成本
    { name: 'price', sm: 3, }, // 售價
    { name: 'duration', sm: 3 }, // 服務時間
    { name: 'unit', sm: 3 },
    { name: 'onShelfDate', sm: 3 },
    { name: 'offShelfDate', sm: 3 },
    { name: 'description', sm: 3 },
    { name: 'note', sm: 3 }, // 備註
  ]

  const [currentData, setCurrentData] = useState(fields.reduce((acc, cur) => {
    acc[cur.name] = false
    return acc
  }, {}))

  function exportProduct() {
    setLoadingApprove(true)
    const path = `${dayjs().format('YYYY-MM-DD')}_ProductList`
    const _rows = fields.reduce((acc, cur) => {
      if (currentData[cur.name]) {
        acc.push(formatMessage({ id: `product.edit.${cur.name}` }))
      }

      return acc
    }, [])
    const rows = []
    rows.push(_rows.join(','))

   
    for (const p of products) {
      let newData = []
      for (const c of Object.keys(currentData)) {
        if(currentData[c]) {
          if(c === 'tags') {
            newData.push(p[c].join('/'))
          } else {
            newData.push(p[c])
          }
        }
      }

      rows.push(newData.join(','))
    }

    exportFields(rows, path)
    setLoadingApprove(false)
    handleClose()
  }


  function updateData(field, value) {
    let newValue = value
    let newData = { ...currentData, [field.name]: newValue, }

    setCurrentData(newData)
  }

  function handleCheckChange(event, field) {
    updateData({ name: `${field.name}` }, event.target.checked);
  };

  function createField(field) {
    let labelText = formatMessage({ id: `product.edit.${field.name}` })

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <FormControlLabel
        control={<Checkbox
          checked={currentData[field.name]}
          onChange={e => { handleCheckChange(e, field) }}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />}
        label={labelText}
      />
    </Grid>
  }

  function checkField() {
    let value = true
    for(const c of Object.keys(currentData)) {
      if(currentData[c]) {
        value = false
      }
    }

    return value
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Dialog
        fullWidth={true}
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'exportDialog.title' })}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {fields.map(field => createField(field))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonProgress 
            handleClose={handleClose} 
            handleClick={() => exportProduct()} 
            loading={loadingApprove} 
            buttonText='button.confirm'
            disabledField={checkField()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

ExportProductListDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired
};

export default ExportProductListDialog;
