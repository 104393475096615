const i18n = {
  'finance': {
    'tab': {
      'purchase': '營收單',
      'salary': '薪資列表',
      'bonus': '獎金列表',
      'balance': '年假歷史紀錄'
    },
    'purchaseBonus': {
      'table': {
        'title': '營收單列表',
        'sn': '營收單號',
        'customerName': '客戶',
        'date': '日期',
        'totalCost': '總金額',
        'totalPaid': '已收金額',
        'agentName': '營收單負責人',
        'status': '付款狀態',
        'paymentType': '付款方式',
        'payment': {
          'cash': '現金',
          'visa': 'Visa',
          'master': 'Master',
          'jcb': 'JCB',
          'wireTransfer': '匯款',
          'ecPay': '綠界科技',
          'wechatPay': '微信電子錢包',
          'points': '儲值點數',
          'mktExpence': '行銷支出',
          'ae': '美國運通',
          'alipay': '支付寶',
          'unionpay': '銀聯',
          'online': '線上刷卡',
          'benefit': '員工福利點數',
          'refundPoint': '現金點數'
        },
      },
      'confirmed': '確認',
      'uncollected': '未付',
      'collected': '付清',
      'closeout': '付清餘額',
      'all': '全額',
      'partial': '部分付款',
      'detail': {
        'title': {
          'product': '服務列表',
          'purchaseBonus': '獎金類別',
          'setUp': '獎金類別設定'
        },
        'code': '品號',
        'name': '名稱',
        'amount': '數量',
        'discountPrice': '單價',
        'totalPrice': '總價',
        'bonus': {
          'typeA': 'A類',
          'typeB': 'B類',
          'typeC': 'C類',
        }
      }
    },
    'salary': {
      'title': '薪資列表',
      'header': {
        'yearMonth': '月份',
        'createdBy': '建立人員',
        'createdAt': '建立日期'
      },
      'detail': {
        'name': '姓名',
        'onBoardingDate': '到職日',
        'onBoardingCount': '在職日數',
        'basicSalary': '底薪',
        // 'fullAttendance': '全勤',
        'subsistenceAllowance': '伙食津貼',
        'jobAllowance': '職務津貼',
        'licenseAllowance': '證照津貼',
        'otherPlus': '其他(+)',
        'personalLeave': '事假',
        'sickLeave': '病假',
        'dueAmount': '應領金額',
        'healthInsurance':'健保費',
        'laborInsurance': '勞保費',
        'incomeTax': '所得稅',
        'secondGenerationNHI': '二代健保',
        'otherMinus':'其他(-)',
        'deduction': '應扣金額',
        'overtime': '加班時數',
        'employeeId': '工號',
        'actualAmount':'實發金額',
        'lateCount': '遲到早退',
        'absenteeismCount': '曠職次數',
        'reviseCount': '忘刷卡次數',
        'note': '備註',
        'identityCardNumber': '身分證號',
        'bankAccount': '銀行帳號'
      },
      'dialog': {
        'title': '建立薪資試算表',
        'month': '選擇月份'
      }
    },
    'bonus': {
      'header': {
        'yearMonth': '月份',
        'source': '公司',
        'createdBy': '建立人員',
        'createdAt': '建立日期'
      },
      'detail': {
        'soItem': {
          'salesOrder': '營收單',
          'customer': '客戶',
          'product': '產品',
          'price': '總金額',
          'bonus': '獎金',
        },
        'scfItem': {
          'consumedServiceForm': '消耗單',
          'customer': '客戶',
          'product': '產品',
          'amount': '數量',
          'bonus': '獎金',
        },
        'salesBonus': '銷售獎金',
        'operatorBonus': '操作獎金',
      },
      'dialog': {
        'title': '建立獎金試算表',
        'month': '選擇月份',
        'source': '公司'
      }
    },
    'balance': {
      'title': {
        'leave': '休假歷史紀錄',
        'overtime': '加班歷史紀錄'
      },
      'name': '姓名',
      'employeeId': '員工編號',
      'onBoardingDate': '到職日',
      'department': '部門',
      'detail': {
        'typeName': '假別',
        'balance': '剩餘時數',
        'earned': '總時數',
        'used': '以使用時數',
        'overtimeType': '兌換',
      }

    }
  }
}

export default i18n
