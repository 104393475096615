import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';

import { moibleMedia } from 'constants';

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '860px',
    height: '100vh',
    backgroundColor: '#ffffff',
    borderLeft: '1px solid #D4D4D4',
    zIndex: 10,
    paddingBottom: '60px',
    [moibleMedia]: {
      position: 'fixed',
      top: '100px',
      bottom: 0,
      left: 0,
      height: 'auto',
      width: '100vw',
      paddingBottom: 0
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '860px',
    backgroundColor: '#ffffff',
    width: '100%',
    height: 'auto',
    padding: '20px 0',
    fontFamily: 'PingFang TC,Roboto,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif'
  },
  rows: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '15px',
    color: 'rgba(0, 0, 0, 0.54)',
    borderBottom: '1px solid rgba(0,0,0,.1)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    height: '43px',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0,0,0,.1)'
  },
  nameCell: {
    width: '104px',
    overflow: 'hidden',
    paddingLeft: '23px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'rgba(0, 0, 0, 0.78)'
  },
  cell: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexBasis: 0,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '32px',
    margin: '9px 0 4px 0',
  },
  shift: {
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: '#ecf8ff',
    padding: '0 3px',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
    marginRight: '10px'
  }
});

function DoctorSchedule({ doctorWeekShifts, onRequestClose, currentCompany }) {
  const classes = useStyles();
  const users = useSelector(state => state.users.ordered);

  const doctors = users.filter(u => !u.developer && u.department === 'doctor' && u.active && u.company?.includes(currentCompany))
  const daysOfWeek = ['週日', '週一', '週二', '週三', '週四', '週五', '週六']

  return (
    <div className={classes.container}>

      <div className={classes.main}>
        <div className={classes.rows}>
          <div className={classes.header}>
            <div className={classes.nameCell} />
            {daysOfWeek.map((day, i) => (
              <div className={classes.cell} key={i}>{day}</div>
            ))}
          </div>
          {doctors.map((doctor, i) => (
            <div className={classes.row} key={i}>
              <div className={classes.nameCell}>{doctor.displayName}</div>
              {daysOfWeek.map((day, i) => (
                <div className={classes.cell} key={doctor.id + i}>
                  {
                    !doctorWeekShifts[doctor.id] ||
                      !doctorWeekShifts[doctor.id][i] ||
                      !doctorWeekShifts[doctor.id][i].length
                      ? '--'
                      : doctorWeekShifts[doctor.id][i].map((shift, index) => (
                        <div
                          key={shift.id + index}
                          className={classes.shift}
                          style={{ backgroundColor: shift.repeats === 'no' && '#fff3f2' }}
                        >
                          {shift.startTime}-{shift.endTime}
                        </div>
                      ))
                  }
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className={classes.button}>
          <Button
            variant='contained'
            color='secondary'
            sx={{ mr: '15px' }}
            onClick={() => onRequestClose()}
          >
            <FormattedMessage id={'button.cancel'} />
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => window.open('/schedule/doctor')}
          >
            <FormattedMessage id={'appointment.editSchedules'} />
          </Button>
        </div>
      </div>
    </div>
  );
}

DoctorSchedule.propTypes = {
  doctorWeekShifts: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  currentCompany: PropTypes.string.isRequired
};

export default DoctorSchedule;
