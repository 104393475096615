const i18n = {
  'form': {
    'field': {
      'isRequired': '欄位為必填',
      'notZero': '不可為零',
      'formatError': '格式錯誤',
      'amount': '不得大於最低數量或為零',
      'price': '不得大於已收金額或為空',
      'notEnoughBalance': '餘額不足'
    },
    'date': {
      'formatError': '無效的日期格式',
      'beforeStartDate': '日期不得小於開始日期',
      'afterEndDate': '日期不得大於結束日期',
      'afterToday': '日期不得大於今日',
      'afterYesterday': '必須為今日以前的日期',
      'beforeLastCheckpoint': '必須為最後盤點日之後的日期',
      'beforeToday': '日期不得早於今日',
      'leaveError': '請假天數(時數)異常或查無當日排班資訊',
    },
    'time': {
      'formatError': '無效的時間格式',
      'weeklyLeave': '本休不得超休'
    },
    'mobile': {
      'lengthError': '手機號碼長度錯誤',
      'formatError': '電話號碼格式錯誤',
    },
    'price': {
      'formatError': '格式錯誤'
    },
    'businessNumber': {
      'lengthError': '統一編號長度錯誤',
      'validateError': '統一編號驗證錯誤',
    },
    'basicPurchaseAmount': {
      'lowerThanBasicPurchaseAmount': '低於最低採購金額'
    },
    'freeShippingAmount': {
      'lowerThanFreeShippingAmount': '低於免運金額'
    },
    'invoiceNumber': {
      'formatError': '發票號碼長度錯誤'
    },
    'amount': {
      'overRequire': ' 超過需求數量'
    },
    'customer': {
      'error': '套裝療程客戶不得與療程客戶不同'
    },
    'package': {
      'error': '必須包含療程',
      'ratioError': '總營收占比不得大於100%'
    }
  }
}
export default i18n
