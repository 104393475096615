import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
// import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MenuIcon from '@mui/icons-material/Menu';

import ContextStore from 'modules/context';
import { moibleMedia, tabletMedia, DRAWER_WIDTH } from 'constants';
import Notification from './Notification';


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  zIndex: 1200,
  ...(open && {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: `${DRAWER_WIDTH}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const UserNameBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  [tabletMedia]: {
    width: '100%',
    borderBottom: '1px solid #fff',
    '& > :last-child': {
      display: 'none'
    }
  }
}));

const BreadcrumbsRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [tabletMedia]: {
    flexDirection: 'column',
  }
}));

const Breadcrumbs = styled(MuiBreadcrumbs)(({ theme }) => ({
  '& ol > :not(:last-child)': {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
  // flexGrow: 1,
}));

const ToolBar = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 6,
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginRight: '8px',
  marginLeft: '16px',
  flexWrap: 'nowrap',
  [tabletMedia]: {
    '& > :not([fw])': {
      flexShrink: 1,
      minWidth: '36px',
    },
  },
  [moibleMedia]: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > [fw]': {
      width: '100%',
    },
    // flexDirection: 'column',
    marginTop: '4px',
    marginBottom: '4px',
  }
}));

function AppNavBar({ currentUser, isDrawerOpen, handleDrawerOpen }) {
  const navigate = useNavigate()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const { breadcrumbs, headerTools } = useContext(ContextStore)

  return (
    <AppBar position="fixed" open={isDrawerOpen}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(isDrawerOpen && { display: 'none' }) }}
          size="large">
          <MenuIcon />
        </IconButton>
        {(!isTablet && !isTablet) && <IconButton
          color="inherit"
          onClick={() => navigate('/')}
          edge="start"
          sx={{
            mr: 1,
            [moibleMedia]: {
              display: 'none'
            }
          }}
          size="large">
          <HomeIcon/>
        </IconButton>}
        <BreadcrumbsRoot>
          <UserNameBlock>
            <Typography sx={{ color: '#FFF', mr: 1, textTransform: 'none' }} variant={!isTablet && !isTablet ? 'h6' : 'button'}>{currentUser.displayName}</Typography>
            <Typography sx={{ color: '#FFF', mr: 1 }} variant={!isTablet && !isTablet ? 'h6' : 'button'}>/</Typography>
          </UserNameBlock>
          <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#fff' }} fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs.map((b, index) => b.link ?
              (<Link key={`link-${index}`} sx={{ cursor: 'pointer', textDecoration: 'none' }} href={b.link} onClick={e => { e.preventDefault(); navigate(b.link); }}>
                <Typography sx={{ color: '#FFF' }} variant={!isTablet && !isTablet ? 'h6' : 'button'}>{b.text}</Typography>
              </Link>) :
              (<Typography key={`link-${index}`} sx={{ color: '#FFF' }} noWrap variant={!isTablet && !isTablet ? 'h6' : 'button'}>{b.text}</Typography>))
            }
          </Breadcrumbs>
        </BreadcrumbsRoot>
        <ToolBar>
          {headerTools}
          {isMobile && <Notification />}
        </ToolBar>
        {!isMobile && <Notification />}
      </Toolbar >
    </AppBar >
  );
}

AppNavBar.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  handleDrawerOpen: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
};

export default AppNavBar;
