import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiFab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';

import EnhancedTable from 'components/EnhancedTable';
import ContextStore from 'modules/context';

const Fab = styled(MuiFab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}));

function SettingStaffPage({ tableCells, tableName }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const config = useSelector(state => state.config.data)
  const data = Object.keys(config[tableName] || {}).map(i => config[tableName][i])
  const users = useSelector(state => state.users.ordered).filter(u => u.active && (currentUser.developer || !u.developer))

  const headerCells = tableCells.reduce((acc, cur) => {
    acc.push({ text: cur, align: 'left' })
    return acc
  }, []).concat({ text: 'status', align: 'right' }).map(c => { c.text = formatMessage({ id: `setStaff.${tableName}.table.header.${c.text}` }); return c })

  const rowCells = tableCells.reduce((acc, cur) => {
    acc.push({ field: cur, align: 'left' })
    return acc
  }, []).concat({ field: 'status', align: 'right' })

  const formatData = (data) => {
    const newData = { ...data }
    newData.key = data.id
    newData.status = data.active ? '啟用' : '停用'

    if(tableName === 'departments') {
      newData.users = users.filter(u => u.department === data.id).map(u => u.displayName).join(', ')
    } else if (tableName === 'occupation') {
      newData.users = users.filter(u => u.occupation === data.id).map(u => u.displayName).join(', ')
    } else if (tableName === 'appointmentType') {
      newData.users = users.filter(u => u.appointmentType === data.id).map(u => u.displayName).join(', ')
    }
    return newData
  }

  return (
    <Box style={{ marginTop: '20px' }}>
      {userRights.hasUserRight('setting-edit') &&
        <Link to={`/setting/staff/${tableName}/edit`}>
          <Fab color="primary">
            <EditIcon />
          </Fab>
        </Link>
      }
      <EnhancedTable
        defaultOrder="asc"
        defaultOrderField="code"
        headerCells={headerCells}
        rowCells={rowCells}
        tableData={data.map(i => formatData(i))}
      />
    </Box>
  );
}

SettingStaffPage.propTypes = {
  tableCells: PropTypes.array.isRequired,
  tableName: PropTypes.string.isRequired,
};

export default SettingStaffPage;
