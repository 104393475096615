import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import ButtonProgress from 'components/ButtonProgress';
import { callFunction } from 'modules/firebase';

function DeletePunchClockExceptionDialog({ handleClose, pcExceptionId, pcExceptionDate }) {
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)

  async function onDelete() {
    setLoading(true)
    try {
      await callFunction('savePunchClockException', { id: pcExceptionId, delete: true })
      setLoading(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'punchClockException.dialog.deleteTitle' })}</DialogTitle>
        <DialogContent sx={{ color: 'red' }}>
          {formatMessage({ id: 'punchClockException.dialog.deleteMsg' }, { date: pcExceptionDate })}
        </DialogContent>
        <DialogActions>
          <ButtonProgress handleClick={() => onDelete()} handleClose={handleClose} loading={loading} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeletePunchClockExceptionDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  pcExceptionId: PropTypes.string.isRequired,
  pcExceptionDate: PropTypes.string.isRequired
};

export default DeletePunchClockExceptionDialog;
