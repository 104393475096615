import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TabPanel from 'components/TabPanel';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  width: '100%'
}));

function ScheduleTabContainer({ tabs, onTabSelected, defaultSelect, activeTabIndex, orien }) {
  const [value, setValue] = useState(defaultSelect);

  useEffect(() => {
    if (activeTabIndex !== undefined) {
      setValue(activeTabIndex)
    }
  }, []);


  const handleChange = (event, newValue) => {
    onTabSelected(newValue)
    setValue(newValue);
  };

  return (
    <Root>
      <Tabs
        orientation={orien}
        indicatorColor="primary"
        textColor="primary"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ flexShrink: 0, width: '150px', height: '100%', borderRight: 1, borderColor: 'divider' }}
      >
        {tabs.map((tab, idx) => <Tab key={`tab-${idx}`} label={tab.label} />)}
      </Tabs>
      {tabs.map((tab, idx) => <TabPanel key={`tabPanel-${idx}`} value={value} index={idx}>{tab.component}</TabPanel>)}
    </Root>
  );
}

ScheduleTabContainer.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.node.isRequired,
    component: PropTypes.element.isRequired,
  })),
  onTabSelected: PropTypes.func.isRequired,
  defaultSelect: PropTypes.number,
  activeTabIndex: PropTypes.number,
  orien: PropTypes.string,
};

export default ScheduleTabContainer;