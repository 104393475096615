import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import ButtonProgress from 'components/ButtonProgress';
import { callFunction } from 'modules/firebase';

function EditSocialMediaPage({ handleClose, data, customerId }) {
  const { formatMessage } = useIntl()
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [customerData, setCustomerData] = useState(data)


  const _fields = [
    { name: 'email', type: 'text', md: 12, sm: 12, order: 0 },
    { name: 'line', type: 'text', md: 12, sm: 12, order: 1 },
    { name: 'weChat', type: 'text', md: 12, sm: 12, order: 2 },
    { name: 'whatsApp', type: 'text', md: 12, sm: 12, order: 3 },
  ]

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  async function saveCustomer() {
    setLoadingApprove(true)
    const updateData = {
      email: customerData.email || '',
      line: customerData.line || '',
      weChat: customerData.weChat || '',
      whatsApp: customerData.whatsApp || '',
    }

    for (const field of Object.keys(updateData)) {
      if (updateData[field] === '') {
        delete updateData[field]
      }
    }

    try {
      await callFunction('saveCustomers', { id: customerId, ...updateData })
      setLoadingApprove(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  function updateData(field, value) {
    let newValue = value
    let newData = { ...customerData, [field.name]: newValue }

    setCustomerData(newData)
  }


  function createField(field) {
    let newValue = customerData[field.name] ? customerData[field.name] : ''
    let labelText = formatMessage({ id: `customer.dialog.${field.name}` })

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        required={field.required}
        label={labelText}
        value={newValue}
        fullWidth
        size="small"
        variant="outlined"
        onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
      />
    </Grid>
  }

  return (
    <Dialog
      fullWidth={true}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'customer.dialog.information.edit' })}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {fields.map(field => createField(field))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonProgress handleClick={() => saveCustomer()} handleClose={handleClose} loading={loadingApprove} buttonText='button.save' />
      </DialogActions>
    </Dialog>
  );
}

EditSocialMediaPage.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  customerId: PropTypes.string.isRequired
};

export default EditSocialMediaPage;
