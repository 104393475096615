import { db, storage, functions } from 'store';
import { httpsCallable } from 'firebase/functions';
import {
  doc,
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  updateDoc,
  getDoc,
  getDocs,
  setDoc,
  addDoc,
  deleteDoc,
  deleteField,
  documentId as dId,
} from 'firebase/firestore';

import { uploadBytes, ref, getDownloadURL } from 'firebase/storage';

const updateFirestoreDoc = ({ collection: collectionId, doc: docId, data }) => {
  const docRef = doc(db, collectionId, docId);
  return updateDoc(docRef, data);
}

const setFirestoreDoc = ({ collection: collectionId, doc: docId, data }) => {
  const docRef = doc(db, collectionId, docId);
  return setDoc(docRef, data);
}

const addFirestoreDoc = ({ collection: collectionId, data }) => {
  return addDoc(collection(db, collectionId), data);
}

const deleteFirestoreDoc = ({ collection: collectionId, doc: docId }) => {
  return deleteDoc(doc(db, collectionId, docId));
}

const documentId = () => dId()

// const getDocId = ({ collection: collectionId }) => {
//   return doc(collection(db, collectionId)).id;
// }

const unwrap = (data) => {
  if (data) {
    const { extData, ...rootData } = data
    return { ...rootData, ...extData }
  } else {
    return data
  }
}

const firestoreListener = ({ collection: _collectionId, doc: docId, where: querys, orderBy: orders, unwrap: unwraper, mapping: useMapping, array: useArray, onSnapshot: cb, onData, onDoc, filter, onError, addDocId = true  }) => {
  const collectionId = Array.isArray(_collectionId) ? _collectionId : [_collectionId]
  if (docId !== undefined) {
    const unsub = onSnapshot(doc(collection(db, ...collectionId), docId), (doc) => {
      cb?.(doc)
      let data = addDocId ? { ...doc.data(), id: doc.id } : doc.data()
      if (unwraper) {
        data = unwrap(data)
      }
      onData?.(data)
    },(error) => {
      onError?.(error)
      console.log(error)
    })
    return unsub
  } else {
    const params = []
    if (querys) {
      for (const query of querys) {
        params.push(where(...query))
      }
    }
    if (orders) {
      for (const order of orders) {
        params.push(orderBy(...order))
      }
    }
    const q = query(collection(db, ...collectionId), ...params)
    const unsub = onSnapshot(q, (snapshot) => {
      cb?.(snapshot)
      const docs = [];
      const mapping = {}
      snapshot.forEach((doc) => {
        let data = addDocId ? { ...doc.data(), id: doc.id } : doc.data()
        if (unwraper) {
          data = unwrap(data)
        }
        onDoc?.(data)
        if (!filter || filter(data)) {
          docs.push(data)
          mapping[doc.id] = data
        }
      });
      if (useMapping && useArray) {
        onData?.({ mapping, data: docs })
      } else if (useMapping) {
        onData?.(mapping)
      } else {
        onData?.(docs)
      }
    }, (error) => {
      onError?.(error)
      console.log(error)
    });
    return unsub
  }
}

const firestoreDoc = ({ collection: _collectionId, doc: docId, where: querys, unwrap: unwraper, onSnapshot: cb, onData, onDoc, onError, addDocId = true }) => {
  const collectionId = Array.isArray(_collectionId) ? _collectionId : [_collectionId]
  if (docId !== undefined) {
    return getDoc(doc(collection(db, ...collectionId), docId)).then(doc => {
      cb?.(doc)
      let data = doc.data()
      if (data) {
        data = { ...data, id: docId }
      }
      if (unwraper) {
        data = unwrap(data)
      }
      onData?.(data)
    }).catch(error => {
      onError?.(error)
      console.log(error)
    });
  } else {
    const params = []
    if (querys) {
      for (const query of querys) {
        params.push(where(...query))
      }
    }
    const q = query(collection(db, ...collectionId), ...params)
    return getDocs(q).then(snapshot => {
      cb?.(snapshot)
      const docs = [];
      snapshot.forEach((doc) => {
        let data = addDocId ? { ...doc.data(), id: doc.id } : doc.data()
        if (unwraper) {
          data = unwrap(data)
        }
        onDoc?.(data)
        docs.push(data)
      });
      onData?.(docs)
    }).catch(error => {
      onError?.(error)
      console.log(error)
    });
  }
}

const uploadFile = async (path, file) => {
  const storageRef = ref(storage, `${path}${file.name}`);
  return uploadBytes(storageRef, file).then(snapshot => {
    return getDownloadURL(snapshot.ref)
  });
}

const getStorageURL = async (fullFilePath) => {
  // 注意: 以 getDownloadURL 這個 API 取得的 URL 是有時效性的
  return getDownloadURL(ref(storage, fullFilePath))
}

const callFunction = async (name, data) => {
  return (httpsCallable(functions, name))(data);
}

const firestoreDeleteField = () => deleteField()

export {
  firestoreListener,
  firestoreDoc,
  callFunction,
  addFirestoreDoc,
  setFirestoreDoc,
  updateFirestoreDoc,
  deleteFirestoreDoc,
  // getDocId,
  documentId,
  firestoreDeleteField,
  getStorageURL,
  uploadFile,
  unwrap,
};
