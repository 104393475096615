const i18n = {
  'sideMenu': {
    'setting': {
      'root': '設定',
      'staff': '職員',
      'attendance': '出勤',
      // 'worktimePolicy': '工時',
      // 'weeklyPolicy': '排休',
      'userRight': '權限管理',
      'salesOrder': '營收單',
      // 'system': '系統',
      'merchandise': '商品',
      'appointment': '預約表',
      'report': '報表',
      'customer': '客戶',
      'home': '首頁'
    },
    'appointments': {
      'root': '預約管理',
      'schedule': '預約表',
      'list': '預約列表',
      'editList': '預約編輯紀錄',
      // 'calendar': '會議室/車位'
    },
    'announcement': {
      'root': '公告',
      'list': '公告列表',
      'review': '簽核公告',
    },
    // 'smsList': '簡訊管理',
    'customers': {
      'root': '客戶',
      'list': '客戶列表',
    },
    'products': {
      'root': '產品',
      'list': '產品列表',
    },
    'sales': {
      'root': '銷售',
      'salesOrders': '營收單',
      'refund': '退費單',
      'referrer': '轉介費單',
      'query': '銷售查詢',
    },
    'services': {
      'root': '服務',
      'consume': '消耗單',
      'transfer': '轉讓單',
      'transform': '轉換單',
    },
    // 'merchandises': '耗材',
    'schedule': '員工班表',
    // 'analytics': '數據分析',
    'staffManagement': {
      'root': '職員管理',
      'staff': '職員',
      'salary': '薪資管理',
      'punchClockException': '補班和補假管理'
    },
    // 'manages': '銷售管理',
    'finance': '財務管理',
    'leaveOvertimeWork': {
      'root': '請假與加班',
      'owner': '個人請假加班',
      'review': '簽核請假加班',
      'overview': '全部請假加班'
    },
    'report': {
      'root': '報表',
      'sales': '營收報表',
      'table': {
        'day': '日報表',
        'interval': '區間報表',
        'year': '年報表'
      }
    },
    'purchase': {
      'root': '採購',
      'requisition': '請購單',
      'purchaseOrder': '採購單',
      'receipt': '進貨單',
      'returnForm': '退貨單',
    },
    'stock': {
      'root': '庫存管理',
      'query': '庫存查詢',
      'inventoryCheck': '盤點單',
      // 'arrangeStockForm': '庫存調撥單',
      'scrapForm': '報廢出庫單',
      'stockRequisition': '領用單',
      'inventoryChange': '庫存異動單',
    },
    'delivery': {
      'root': '銷貨',
      'deliveryOrder': '銷貨單',
      'salesReturnForm': '銷貨退回單',
    },
    'vendor': '廠商',
    'merchandise': '商品',
    'quotation': '報價單',
    'logout': '登出'
  },
}
export default i18n
