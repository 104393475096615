import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Divider from '@mui/material/Divider';

import DateRangePickerField from 'components/DateRangePickerField';
import ContextStore from 'modules/context';
import { unwrap } from 'modules/uitls';
import { firebaseV8 } from 'modules/firebaseV8';
import InventoryChangeList from './InventoryChangeList';

function InventoryChangeTab() {
  const { tabName } = useParams()
  const { currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)

  const [inventoryChanges, setInventoryChanges] = useState([])
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  useEffect(() => {
    if (inventoryChanges.length) setInventoryChanges([])
    const onSnapshot = snapshot => {
      const inventoryChanges = []
      snapshot.forEach(doc => {
        inventoryChanges.push({ ...unwrap(doc.data()), id: doc.id })
      });
      setInventoryChanges(inventoryChanges)
    }
    const unsubscribe = tabName === 'pending' ?
      firebaseV8().collection('inventoryChanges').where('status', '==', tabName).onSnapshot(onSnapshot, err => {}) :
      firebaseV8().collection('inventoryChanges').where('status', '==', tabName).where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(onSnapshot, err => {})
    return () => unsubscribe()
  }, [startDate, endDate]);

  const inventoryChangesForTab = inventoryChanges.filter(r =>
    userRights.debugging ||
    userRights.hasUserRightForVendor('inventoryChange-create', r.source) ||
    userRights.hasUserRightForVendor('inventoryChange-review', r.source)
  );

  const reqWaitingForProcess = waitingForProcess()

  function waitingForProcess() {
    if (tabName === 'pending') {
      return inventoryChangesForTab.filter(r => {
        const history = r.history || []
        const currentStep = history.length > 0 ? history[history.length - 1].step : 0
        if (isNaN(currentStep) || currentStep === r.steps.length) return false
        return r.steps[currentStep].users.includes(currentUser.key)
      })
    } else {
      return []
    }
  }

  return (
    <div style={{ marginTop: '20px', paddingBottom: '60px' }}>
      {tabName !== 'pending' && <DateRangePickerField
        startDate={startDate}
        endDate={endDate}
        onStartDateChanged={setStartDate}
        onEndDateChanged={setEndDate}
      />}
      {reqWaitingForProcess.length > 0 && <>
        <InventoryChangeList inventoryChanges={reqWaitingForProcess} title={`inventoryChange.table.${tabName}.title`} />
        <Divider sx={{ mx: '0px', my: '10px' }}/>
      </>}
      <InventoryChangeList inventoryChanges={inventoryChangesForTab} title={'inventoryChange.table.title'} />
    </div>
  );
}

export default InventoryChangeTab;
