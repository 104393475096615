import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';

import DatePickerField from 'components/DatePickerField';
import DelectIconButton from 'components/DelectIconButton';
import EnhancedTable from 'components/EnhancedTable';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import SelectProductDialog from 'components/SelectProductDialog';
import SelectUserDialog from 'components/SelectUserDialog';
import SelectCustomerDialog from 'components/SelectCustomerDialog';
import { getRandomHash, unique } from 'modules/uitls';
import ContextStore from 'modules/context';
import { useModuleMapping } from 'hooks/modules';
import { addError, removeError } from 'modules/editor';
import { firestoreListener, callFunction } from 'modules/firebase';
import SelectMerchandiseDialog from 'components/SelectMerchandiseDialog';
import SelectPurchasedServiceDialog from 'components/SelectPurchasedServiceDialog';

function EditPageWrap() {
  const productMapping = useSelector(state => state.products.data)
  const products = useSelector(state => state.products.ordered)
  return products.length ? <EditConsumedServicePage productMapping={productMapping} products={products} /> :
    <div>請先建立產品</div>
}

function EditConsumedServicePage({ productMapping, products }) {
  const { formatMessage } = useIntl()
  const userRights = useSelector(state => state.userRights)
  const serviceOrder = useRef(0)
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const { formId } = useParams()
  const moduleMapping = useModuleMapping()
  const [openDialog, setOpenDialog] = useState(null)
  const [loading, setLoading] = useState(false)
  const userMapping = useSelector(state => state.users.data)
  const sourceMapping = useSelector(state => state.internalVendors.data)
  const users = useSelector(state => state.users.ordered)
  const vendorMapping = useSelector(state => state.vendors.data)
  const [customers, setCustomers] = useState([]);
  const [customerMapping, setCustomerMapping] = useState({});
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [ppCountMapping, setPpCountMapping] = useState([]);
  const [ppData, setPPData] = useState([]);
  const companyData = currentUser.company?.reduce((acc, cur) => { if (userRights['consumeService-create'].includes(cur)) { acc = true } return acc }, false) ?
    unique(currentUser.company?.concat(userRights['consumeService-create'])) : userRights['consumeService-create']
  const [form, setForm] = useState({
    id: formId,
    date: new Date(),
    agent: currentUser.key,
    customer: '',
    services: [],
    payments: [],
    merchandises: [],
    note: '',
    source: companyData[0]
  });

  const defaultCustomer = currentCustomer && customerMapping[currentCustomer] ? currentCustomer : ''
  const merchandiseMapping = useSelector(state => state.merchandises.data)
  const merchandises = useSelector(state => state.merchandises.ordered)

  const selectedMerchandises = (form.merchandises || []).reduce((acc, cur) => {
    acc[cur.id] = true
    return acc
  }, {});
  const _products = products.reduce((acc, cur) => {
    if (ppCountMapping[cur.id]) {
      acc.push({ ...cur, availableAmount: ppCountMapping[cur.id] })
    }

    return acc
  }, [])

  useEffect(() => {
    const breadcrumbs = [{
      link: '/services/consume',
      text: formatMessage({ id: 'sideMenu.services.consume' })
    }]
    if (formId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'consumedService.add' }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: 'consumedService.edit' }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const sp = new URLSearchParams(location.search)
    setCurrentCustomer(sp.get('customer'))
  }, [location.search]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      array: true,
      onData: ({ mapping, data }) => {
        setCustomers(data)
        setCustomerMapping(mapping)
      }
    })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = form.source && form.customer ? firestoreListener({
      collection: 'purchasedProducts',
      where: [
        ['customer', '==', form.customer],
        ['available', '==', true]
      ],
      onData: (data) => {
        const newData = data.filter(i => i.serviceProvider[form.source])
        const ppCountMapping = newData.reduce((acc, cur) => {
          if (!acc[cur.product]) {
            acc[cur.product] = cur.quantity - cur.taken
          } else {
            acc[cur.product] += cur.quantity - cur.taken
          }
          return acc
        }, {})
        setPpCountMapping(ppCountMapping)
        setPPData(newData)
      }
    }) : null
    return () => unsubscribe?.()
  }, [form.customer, form.source]);

  useEffect(() => {
    if (formId !== 'new') { // edit
      if (!userRights.hasUserRight('consumeService-edit')) {
        navigate('/');
        return () => { };
      }

      const unsubscribe = firestoreListener({
        collection: 'consumedServiceForms',
        doc: formId,
        onData: (data) => {
          const so = {
            id: data.id,
            date: dayjs(data.date).toDate(),
            agent: data.agent,
            customer: data.customer,
            source: data.source,
            services: Object.keys(data.services).map(i => ({
              ...data.services[i],
              id: i,
              lock: true,
              pp: Object.keys(data.services[i].purchasedProducts).map(k => ({
                id: k,
                selectCount: data.services[i].purchasedProducts[k]
              }))
            })).sort((a, b) => a.order - b.order),
            merchandises: Object.keys(data.merchandises).map(i => ({ ...data.merchandises[i], id: i, lock: true })).sort((a, b) => a.order - b.order),
            note: data.note,
          }
          serviceOrder.current = so.services[so.services.length - 1].order + 1
          setForm(so)
        }
      })
      return () => unsubscribe()
    } else {
      if (!userRights.hasUserRight('consumeService-create')) {
        navigate('/');
      }
      return () => { };
    }
  }, [formId]);

  const _headerCells = [
    { text: 'name', order: 0 },
    { text: 'availableAmount', align: 'right', order: 1 },
    { text: 'amount', order: 2 },
    { text: 'agent', order: 3 },
    { text: 'note', order: 5 },
  ]

  const _rowCells = [
    { field: 'nickname', tooltip: 'name', order: 0 },
    { field: 'availableAmount', align: 'right', textColor: true, order: 1 },
    {
      field: 'amount',
      type: 'input-number',
      required: true,
      label: '數量',
      enabledClick: () => true,
      onClick: setSelectPP,
      getEnableStatus: getEnableStatus,
      onValueChanged: onCellValueChanged,
      order: 2
    },
    {
      field: 'agent',
      fullWidth: true,
      type: 'popup',
      // required: true,
      label: '操作人員',
      getEnableStatus: getEnableStatus,
      onClick: (service) => setOpenDialog({ name: `agent*${service.id}` }),
      order: 3
    },
    {
      field: 'note',
      type: 'input',
      required: false,
      label: '備註',
      onValueChanged: onCellValueChanged,
      order: 5
    },
  ]

  if (moduleMapping.doctor) {
    _headerCells.push({ text: 'doctor', order: 4 })
    _rowCells.push({ field: 'doctor', fullWidth: true, type: 'popup', label: '醫師', getEnableStatus: getEnableStatus, onClick: (service) => setOpenDialog({ name: `doctor*${service.id}` }), order: 4 })
  }
  const headerCells = _headerCells.sort((a, b) => a.order - b.order).map(c => { c.text = formatMessage({ id: `consumedService.service.${c.text}` }); return c })
  const rowCells = _rowCells.sort((a, b) => a.order - b.order)

  const merchandiseHeaderCells = [
    { text: 'merchandise' },
    { text: 'consumeAmount' },
    { text: 'sku' },
    // { text: 'onShelfDate' },
    // { text: 'offShelfDate' },
  ].map(c => { c.text = formatMessage({ id: `consumedService.merchandise.${c.text}` }); return c })

  const merchandiseRowCells = [
    { field: 'nickname', tooltip: 'name' },
    {
      field: 'consumeAmount',
      fullWidth: true,
      align: 'left',
      style: { maxWidth: '140px' },
      type: 'input-number',
      required: true,
      label: formatMessage({ id: 'consumedService.merchandise.consumeAmount' }),
      getEnableStatus: getEnableStatus,
      onValueChanged: onCellValueChanged
    },
    { field: 'sku' },
  ];

  const allowSubmit = () => {
    const ppTemp = { ...ppCountMapping }
    for (const item of form.services) {
      if (item.lock) {
        continue
      }
      const count = ppTemp[item.productId] || 0
      const amount = parseInt(item.amount || '0')
      if (item.amount === '' || amount === 0 || amount > count) {
        return false
      } else {
        ppTemp[item.productId] = ppTemp[item.productId] - item.amount
      }
    }
    return true
  }

  // const readyForPurchase = () => {
  //   // 全部數量都有填, 然後有紅字. 把缺的量帶到開營收單的畫面.
  //   return !allowSubmit() && form.services.every(i => parseInt(i.amount || '0'))
  // }

  function getEnableStatus(data, field) {
    return !data.lock
  }

  function onCellValueChanged(cellData, field, value) {
    const m = form[cellData.table].find(i => i.id === cellData.id)
    if (!m) {
      return
    }

    if (field === 'amount' || field === 'consumeAmount') {
      m[field] = value
      if (isNaN(value) || value === '' || parseInt(value) === 0) {
        addError(m, field, '數量錯誤')
        // } else if (parseInt(value) > cellData.availableAmount) {
        //   addError(m, field, '已購數量不足')
      } else {
        removeError(m, field)
      }

      if (cellData.table === 'services') {
        const mmData = form.merchandises.filter(f => f.serviceIndex === cellData.id)
        for (const m of mmData) {
          const _index = form.merchandises.findIndex(n => n.id === m.id)
          if (value !== '') {
            const product = productMapping[cellData.productId]
            const amount = product.merchandises.reduce((acc, cur) => {
              if(m.merchandise === cur.merchandise) {
                acc = cur.consumeAmount
              }
              return acc
            }, 0)
            form.merchandises[_index].consumeAmount = amount * value
          }
        }
        updateFormData({ name: 'merchandises' }, form.merchandises);
      }
    } else {
      removeError(m, field)
      m[field] = value
    }
    updateFormData({ name: cellData.table }, form[cellData.table]);
  }

  function onMerchandiseAdded(merchandises) {
    if (merchandises.length) {
      for (const m of merchandises) {
        const mData = {
          id: getRandomHash(),
          merchandise: m.id,
          consumeAmount: '1',
          table: 'merchandises',
          lock: false,
        }

        form.merchandises.push(mData)
      }
      updateFormData({ name: 'merchandises' }, [...form.merchandises])
    }
  }

  function onSelectProduct(products) {
    if (products.length) {
      for (const p of products) {
        const pData = {
          id: getRandomHash(),
          productId: p.id,
          amount: p.type === 'service' ? '' : 1,
          agent: [],
          note: '',
          order: serviceOrder.current,
          table: 'services',
          lock: false,
        }
        form.services.push(pData)
        serviceOrder.current = serviceOrder.current + 1

        if (p.merchandises?.length > 0) {
          for (const m of p.merchandises) {
            const mData = {
              id: getRandomHash(),
              merchandise: m.merchandise,
              consumeAmount: m.consumeAmount,
              serviceIndex: pData.id,
              lock: true,
              fixed: true
            }

            form.merchandises.push(mData)
          }
          updateFormData({ name: 'merchandises' }, [...form.merchandises])
        }

      }
      updateFormData('services', [...form.services])
    }
  }

  function setSelectPP(product) {
    setOpenDialog({
      name: 'purchasedService',
      customer: form.customer,
      id: product.id,
      ppData: ppData.filter(i => i.product === product.productId).map(i => {
        const m = /SO(\d{4})(\d{2})(\d{2})(?:\d{4})/.exec(i.salesOrderSn)
        const selectedPP = (product.pp || []).find(j => j.id === i.id)
        const selectCount = selectedPP ? String(selectedPP.selectCount) : ''
        const pp = {
          date: `${m[1]}-${m[2]}-${m[3]}`,
          salesOrder: i.salesOrder,
          salesOrderSn: i.salesOrderSn,
          salesOrderItem: i.salesOrderItem,
          serviceProvider: i.serviceProvider,
          quantity: i.quantity,
          amount: i.quantity - i.taken,
          id: i.id,
          selectCount,
        }
        return pp
      }),
      productId: product.productId,
    })
  }

  function onSelectPurchasedService(params, items) {
    const s = form.services.find(i => i.id === params)
    s.amount = items.reduce((acc, cur) => acc + cur.selectCount, 0)
    s.pp = items
    // 計算固定消耗, 並更新
    const mmData = form.merchandises.filter(f => f.serviceIndex === params)
    for (const m of mmData) {
      const _index = form.merchandises.findIndex(n => n.id === m.id)
      if (s.amount !== 0) {
        const product = productMapping[s.productId]
        const amount = product.merchandises.reduce((acc, cur) => {
          if(m.merchandise === cur.merchandise) {
            acc = cur.consumeAmount
          }
          return acc
        }, 0)
        form.merchandises[_index].consumeAmount = amount * s.amount
      }
    }
    if (mmData.length) {
      updateFormFields([
        { name: 'merchandises' },
        { name: 'services' }
      ], {
        merchandises: form.merchandises,
        services: form.services
      });
    } else {
      updateFormData({ name: 'services' }, [...form.services]);
    }
  }

  function onDeleteItem(item, type) {
    const index = form[type].findIndex(i => i.id === item.id)
    if (type === 'services') {
      const mData = form.merchandises.filter(i => i.serviceIndex === item.id)
      for (const m of mData) {
        const _index = form.merchandises.findIndex(n => n.id === m.id)
        form.merchandises.splice(_index, 1)
      }

      updateFormData({ name: 'merchandises' }, form.merchandises);
    }
    form[type].splice(index, 1)
    updateFormData({ name: type }, form[type]);
  }

  function onSelectUser(user, params) {
    if (params === 'formAgent') {
      updateFormData({ name: 'agent' }, user.id)
    } else if (params.startsWith('doctor')) {
      const s = params.split('*')
      const p = form.services.find(i => i.id === s[1])
      if (p) {
        p.doctor = user.id
        updateFormData({ name: 'services' }, form.services);
      }
    } else {
      const s = params.split('*')
      const p = form.services.find(i => i.id === s[1])
      if (p) {
        removeError(p, 'agent')
        p.agent = user.map(u => u.id)
        updateFormData({ name: 'services' }, form.services);
      }
    }
  }

  function validateField(field, value) {
    // if (field.required && value === '') {
    //   return formatMessage({id: 'form.field.isRequired'})
    // }
    if (field.name === 'products') {
      for (const m of value) {
        if (m.errors && m.errors.amount) {
          return m.errors.amount
        }
      }
    } else if (field.name === 'date') { // 日期驗證, 例如: 不可以選擇 3天前 或不可以選擇 未來的時間點
      if (value === null) {
        return formatMessage({ id: 'form.field.isRequired' })
      } else if (value.toString() === 'Invalid Date') {
        return formatMessage({ id: 'form.date.formatError' })
      } else {
        // if (dayjs(value).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')) {
        //   return formatMessage({ id: 'form.date.beforeToday' })
        // }
      }
    }
    return ''
  }

  function updateFormData(field, value) {
    let newValue = value
    let err = validateField(field, value)
    let newData = { ...form, [field.name]: newValue, [`${field.name}_err`]: err }

    setForm(newData)
  }

  function updateFormFields(fields, values) {
    setForm( currentData => {
      for (const field of fields) {
        let newValue = values[field.name]
        if (field.allowCharacter) {
          newValue = newValue.replace(field.allowCharacter, '')
        }
        let err = validateField(field, values[field.name])
        currentData[field.name] = newValue
        currentData[`${field.name}_err`] = err
      }
      return currentData;
    })
  }

  async function handleSave() {
    setLoading(true);
    let err = false;

    const fields = [
      { name: 'date' },
      { name: 'agent' },
      { name: 'services' },
      { name: 'note' },
      { name: 'merchandises' },
      { name: 'source' },
    ]

    for (const field of fields) {
      if (form[`${field.name}_err`] !== undefined && form[`${field.name}_err`] !== '') {
        setLoading(false);
        return
      }
    }

    // 檢查必填欄位
    const errMsg = formatMessage({ id: 'form.field.isRequired' })
    for (const service of form.services) {
      if (!service.amount) {
        err = true
        addError(service, 'amount', errMsg)
      }
      if (!service.agent) {
        err = true
        addError(service, 'agent', errMsg)
      }
    }

    for (const merchandise of form.merchandises) {
      if (!merchandise.consumeAmount) {
        err = true
        addError(merchandise, 'consumeAmount', errMsg)
      }
    }

    const customer = defaultCustomer || form.customer
    if (!customer) {
      err = true
      form.customer_err = errMsg
    }

    if (err) {
      setForm({ ...form })
      setLoading(false);
      return
    }

    const data = { customer }
    for (const field of fields) {
      data[field.name] = form[field.name]
    }

    data.services = data.services.reduce((acc, cur) => {
      acc[cur.id] = {
        amount: parseInt(cur.amount),
        productId: cur.productId,
        agent: cur.agent,
        note: cur.note,
        order: cur.order,
        purchasedProducts: cur.pp.reduce((acc, cur) => {
          acc[cur.id] = cur.selectCount
          return acc
        }, {}),
      };
      if (moduleMapping.doctor) {
        acc[cur.id].doctor = cur.doctor || ''
      }
      return acc;
    }, {})

    data.merchandises = data.merchandises.reduce((acc, cur) => {
      acc[cur.id] = {
        merchandise: cur.merchandise,
        consumeAmount: Number(cur.consumeAmount),
      }
      return acc
    }, {})

    data.date = dayjs(data.date).format('YYYY-MM-DD')

    try {
      await callFunction('saveConsumedServiceForm', { id: form.id, ...data })
    } catch (ex) {
      console.log(ex)
    }
    handleClose()
  }

  function handleClose() {
    navigate('/services/consume');
  }

  function formatData(product, index) {
    const newData = { ...product }
    newData.doctor = userMapping[newData.doctor]?.displayName || ''
    newData.agent = newData.agent.map(a => userMapping[a]?.displayName || '').join(', ')
    newData.name = productMapping[newData.productId].name
    newData.nickname = productMapping[newData.productId].nickname

    if (newData.lock) {
      newData.availableAmount = '--'
    } else {
      const ppTemp = { ...ppCountMapping }
      for (let i = 0; i < index; ++i) {
        const item = form.services[i]
        if (item.lock) {
          continue
        }
        const count = ppTemp[item.productId] || 0
        if (item.amount >= count) {
          ppTemp[item.productId] = 0
        } else {
          ppTemp[item.productId] = ppTemp[item.productId] - item.amount
        }
      }
      newData.availableAmount = ppTemp[product.productId] || 0
    }

    const amount = parseInt(product.amount || '0')
    if (newData.availableAmount === 0 || amount > newData.availableAmount) {
      newData.textColor = '#fe2851'
    }
    return newData
  }

  function formatMerchandiseData(merchandise, index) {
    const newData = { ...merchandise }
    newData.name = merchandiseMapping[newData.merchandise].name
    newData.nickname = merchandiseMapping[newData.merchandise].nickname
    newData.sku = merchandiseMapping[newData.merchandise].sku

    return newData
  }
  return (
    <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
      {openDialog?.name === 'product' && <SelectProductDialog
        headerCells={[{ text: 'name', sort: 'name' }, { text: 'availableAmount' }]}
        rowCells={[{ field: 'name' }, { field: 'availableAmount' }]}
        filterItems={[{ name: 'name' }]}
        filterSetting={{ type: { hidden: true, default: 'service' } }}
        defaultSelectedItems={[]}
        handleClose={() => setOpenDialog(null)}
        handleSave={onSelectProduct}
        items={_products}
        // onShelf
        dialogTitle={formatMessage({ id: 'selectProductDialog.title' })}
        tableTitle={formatMessage({ id: 'selectProductDialog.table.title' })}
        // isMobile={isMobile}
        productMapping={productMapping}
      />}
      {(openDialog?.name === 'formAgent' || openDialog?.name?.startsWith('agent') || openDialog?.name?.startsWith('doctor')) && <SelectUserDialog
        headerCells={[{ name: 'displayName', sort: 'displayName' }, { name: 'email' }]}
        rowCells={[{ field: 'displayName' }, { field: 'email' }]}
        filterItems={[{ name: 'displayName' }, { name: 'email' }]}
        dialogTitle={formatMessage({ id: 'selectUserDialog.title' })}
        tableTitle={formatMessage({ id: 'selectUserDialog.table.title' })}
        handleClose={() => setOpenDialog(null)}
        handleSave={onSelectUser}
        multiSelect={openDialog?.name?.startsWith('agent')}
        defaultSelectedItems={form.services.reduce((acc, cur) => {
          const s = openDialog.name.split('*')
          const p = form.services.find(i => i.id === s[1])

          if (p) {
            acc = p.agent
          }
          return acc
        }, [])
        }
        items={openDialog?.name?.startsWith('doctor') ? users.filter(u => u.active && u.department === 'doctor' && !u.developer) : users.filter(u => u.active && !u.developer)}
        params={openDialog.name}
      />}
      {openDialog?.name === 'customer' && <SelectCustomerDialog
        handleClose={() => setOpenDialog(null)}
        handleSave={customer => updateFormData({ name: 'customer' }, customer.id)}
        customers={customers || []}
        dialogTitle={formatMessage({ id: 'selectCustomerDialog.title' })}
      />}
      {openDialog?.name === 'merchandise' && <SelectMerchandiseDialog
        multiSelect
        maxWidth="lg"
        headerCells={[{ name: 'code', sort: 'code' }, { name: 'nickname', sort: 'nickname' }, { name: 'sku' }, { name: 'note' }]}
        rowCells={[{ field: 'code' }, { field: 'nickname', tooltip: 'name' }, { field: 'sku' }, { field: 'note' }]}
        filterItems={[{ name: 'nickname' }, { name: 'name' }, { name: 'code' }, { name: 'note' }]}
        dialogTitle={formatMessage({ id: 'selectMerchandiseDialog.title' })}
        tableTitle={formatMessage({ id: 'selectMerchandiseDialog.table.title' })}
        handleClose={() => setOpenDialog(null)}
        handleSave={onMerchandiseAdded}
        defaultSelectedItems={[]}
        ignoreIds={form.merchandises.map(m => m.id)}
        items={merchandises.filter(m => !selectedMerchandises[m.id])}
      />}
      {openDialog?.name === 'purchasedService' && <SelectPurchasedServiceDialog
        handleClose={() => setOpenDialog(null)}
        handleSave={onSelectPurchasedService}
        customer={openDialog.customer}
        productId={openDialog.productId}
        ppData={openDialog.ppData}
        userMapping={userMapping}
        sourceMapping={sourceMapping}
        customerMapping={customerMapping}
        amountFieldLabel='consume'
        params={openDialog.id}
      />}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            type="text"
            size="small"
            select={userRights['consumeService-create']?.length > 1}
            disabled={userRights['consumeService-create']?.length <= 1}
            label={formatMessage({ id: 'consumedService.table.detail.source' })}
            variant="outlined"
            value={userRights['consumeService-create']?.length > 1 ? form.source : vendorMapping[form.source]?.name}
            onChange={(e) => updateFormData({ name: 'source' }, e.target.value)}
            fullWidth
          >
            {
              companyData.map(c => {
                return <MenuItem key={c} value={c}>{vendorMapping[c].name}</MenuItem>
              })
            }
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            type="text"
            size="small"
            label={formatMessage({ id: 'consumedService.table.detail.customer' })}
            variant="outlined"
            disabled={!!defaultCustomer || formId !== 'new' || !!form.services.length}
            value={
              defaultCustomer ? customerMapping[defaultCustomer].name :
                (form.customer ? customerMapping[form.customer].name : '')
            }
            onClick={() => { if (!defaultCustomer) setOpenDialog({ name: 'customer' }) }}
            error={form.customer_err ? true : false}
            helperText={form.customer_err}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DatePickerField
            required
            fullWidth
            disabled={formId !== 'new'}
            label={formatMessage({ id: 'consumedService.table.detail.date' })}
            value={form.date}
            onChange={date => updateFormData({ name: 'date' }, date)}
            // {...dateError}
            // minDateMessage={formatMessage({ id: 'form.date.beforeToday' })} // 暫時開啟時間限制
            invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
            // minDate={formId === 'new' ? new Date() : null}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            type="text"
            size="small"
            label={formatMessage({ id: 'consumedService.table.detail.agent' })}
            variant="outlined"
            value={form.agent ? userMapping[form.agent].displayName : ''}
            onClick={() => setOpenDialog({ name:'formAgent' })}
            error={form.agent_err ? true : false}
            helperText={form.agent_err}
            fullWidth
          />
        </Grid>
      </Grid>
      <Divider style={{ margin: '8px 0px' }} />
      <EnhancedTableToolbar
        title="editConsumedServicePage.table.title"
        toolbox={
          <Button
            disabled={!form.customer}
            sx={{ m: 1, whiteSpace: 'nowrap' }}
            variant="contained"
            color="primary"
            onClick={() => { setOpenDialog({ name:'product' }) }}
          >
            <FormattedMessage id="editConsumedServicePage.addService" />
          </Button>
        }
      />
      <EnhancedTable
        headerCells={headerCells}
        rowCells={rowCells}
        tableData={form.services.map((i, index) => formatData(i, index))}
        getActionIcons={service =>
          service.lock ? <div style={{ whiteSpace: 'nowrap' }}><FormattedMessage id="editConsumedServicePage.consumed" /></div> : <DelectIconButton
            text={formatMessage({ id: 'editConsumedServicePage.removeService' })}
            onClick={() => onDeleteItem(service, 'services')}
          />
        }
      />
      <EnhancedTableToolbar
        title="editConsumedServicePage.table.merchandiseTitle"
        toolbox={
          <Button
            disabled={!form.customer}
            sx={{ m: 1, whiteSpace: 'nowrap' }}
            variant="contained"
            color="primary"
            onClick={() => { setOpenDialog({ name:'merchandise' }) }}
          >
            <FormattedMessage id="editConsumedServicePage.addMerchandise" />
          </Button>
        }
      />
      <EnhancedTable
        headerCells={merchandiseHeaderCells}
        rowCells={merchandiseRowCells}
        tableData={form.merchandises.map((i, index) => formatMerchandiseData(i, index))}
        getActionIcons={merchandise =>
          merchandise.fixed ? <div style={{ whiteSpace: 'nowrap' }}><FormattedMessage id="editConsumedServicePage.fixed" /></div> :
            merchandise.lock ? <div style={{ whiteSpace: 'nowrap' }}><FormattedMessage id="editConsumedServicePage.consumed" /></div> : <DelectIconButton
              text={formatMessage({ id: 'editConsumedServicePage.removeMerchandise' })}
              onClick={() => onDeleteItem(merchandise, 'merchandises')}
            />
        }
      />
      <Divider style={{ margin: '8px 0px' }} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            type="text"
            label={formatMessage({ id: 'consumedService.table.detail.note' })}
            variant="outlined"
            value={form.note}
            onChange={e => updateFormData({ name: 'note' }, e.target.value)}
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>
      <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
        <Button variant="contained" color="primary" onClick={handleClose}>
          <FormattedMessage id="button.cancel" />
        </Button>
        {/* {readyForPurchase() && <Button variant="contained" color="primary" onClick={handleClose}>
          <FormattedMessage id="editConsumedServicePage.createSalesOrder" />
        </Button>} */}
        <LoadingButton
          color="primary"
          onClick={handleSave}
          disabled={loading || form.services.length === 0 || !allowSubmit()}
          loading={loading}
          loadingPosition="start"
          loadingIndicator={<CircularProgress size={24} />}
          startIcon={<div />}
          variant="contained"
        >
          <FormattedMessage id="button.submit" />
        </LoadingButton>
      </Stack>
    </Box>
  );
}

// EditConsumedServicePage.defaultProps = {

// }

export default EditPageWrap;
