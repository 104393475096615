import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export default function EnhancedTableToolbar(props) {
  const { numSelected = 0, title = '', selectdMessage, toolbox, toolboxForSelection } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          <FormattedMessage id={selectdMessage} values={{ count: numSelected }} />
        </Typography>
      ) : (
        title !== '' ? <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id={title} />
        </Typography> : <div style={{ flex: '1 1 100%' }} />
      )}

      {numSelected > 0 ? (
        toolboxForSelection && toolboxForSelection
      ) : (
        toolbox && toolbox
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number,
  title: PropTypes.string.isRequired,
  selectdMessage: PropTypes.string,
  toolbox: PropTypes.element,
  toolboxForSelection: PropTypes.element,
};
