import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';

import StatusIndicator from 'components/StatusIndicator';
import { useModuleMapping } from 'hooks/modules';
import { moibleMedia } from 'constants';

const useStyles = createUseStyles({
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 5px 0',
    minHeight: '20px',
    [moibleMedia]: {
      padding: '0 2px',
      marginBottom: 0,
      maxHeight: '21px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }
  },
  customerInfo: {
    color: 'rgba(0, 0, 0, 0.7)',
    margin: '0 10px 0 0'
  },
  name: {
    color: '#828a99',
    margin: 0
  },
  room: {
    color: '#828a99',
    '&:before': {
      content: '""',
      display: 'inline-block',
      width: '4px',
      height: '4px',
      borderRadius: '4px',
      backgroundColor: '#828a99',
      verticalAlign: 'middle',
      margin: '-2px 5px 0'
    }
  },
})

function AppointmentItemLabel({ scheduleMode, comment, status, room, customer, customerTypeMapping, ui, salesRep, doctor, nurse }) {
  const classes = useStyles();
  const moduleMapping = useModuleMapping()
  const nameList = []
  if (scheduleMode !== 'salesRep' && ui.showSalesRep && salesRep) {
    nameList.push(salesRep.displayName)
  }
  if (scheduleMode !== 'doctor' && moduleMapping.doctor && ui.showDoctor && doctor) {
    nameList.push(doctor.displayName)
  }
  if (moduleMapping.nurse && ui.showNurse && nurse) {
    nameList.push(nurse.displayName)
  }

  return <div className={classes.label}>
    <StatusIndicator status={status} />
    <div className={classes.customerInfo}>
      {ui.showCustomerCode && customer.code} {ui.showBirthDate && customer.birthDate ? customer.birthDate : ''} {customer.name ? customer?.name : comment.length > 0 ?
        comment[0] : '待確認'}{ui.showCustomerType && customer.name && `(${customerTypeMapping[customer.type] ? customerTypeMapping[customer.type].name : ''})`}
    </div>

    <div className={classes.name}>
      {nameList.join(' ')}
    </div>

    {ui.showRoom && <div className={classes.room}>
      {room?.name ?? <FormattedMessage id='appointment.noRoom' />}
    </div>}
  </div>
}

AppointmentItemLabel.propTypes = {
  scheduleMode: PropTypes.string,
  comment: PropTypes.arrayOf(PropTypes.string).isRequired,
  status: PropTypes.string.isRequired,
  room: PropTypes.object,
  customerTypeMapping: PropTypes.object,
  customer: PropTypes.object,
  salesRep: PropTypes.object,
  doctor: PropTypes.object,
  nurse: PropTypes.object,
  ui: PropTypes.object,
};

export default AppointmentItemLabel;
