import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import weekday from 'dayjs/plugin/weekday';
import { createUseStyles } from 'react-jss';

import { firestoreListener } from 'modules/firebase';
import { objectToArray } from 'modules/data';
import ContextStore from 'modules/context';
import { useModuleMapping } from 'hooks/modules';
import { moibleMedia } from 'constants';
import ScheduleDay from './ScheduleDay';
import AppointmentSidebar from './AppointmentSidebar';
import CommentSidebar from './CommentSidebar';
import DoctorSchedule from './DoctorSchedule';
import AppointmentDate from './AppointmentDate';
import AppointmentFilter from './AppointmentFilter';
import AppointmentButtonRow from './AppointmentButtonRow';
import CompanyFilter from 'components/CompanyFilter';

dayjs.extend(isSameOrBefore);
dayjs.extend(weekOfYear);
dayjs.extend(weekday)

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100% - 64px)',
    width: '100%',
    justifyContent: 'flex-start',
    flexGrow: 2,
    boxSizing: 'border-box',
    overflow: 'hidden',
    position: 'relative',
    fontFamily: 'PingFang TC,Roboto,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
    [moibleMedia]: {
      top: '94px',
      height: 'calc(100% - 150px)',
    }
  },
});

const APPOINTMENT_TYPE_ALWAYS_SHOW = ['normalAppointment']

function getDoctorShifts(allShifts, currentdate, startOfWeek, endOfWeek, currentDayOfWeek) {
  const shifts = {}
  const doctorWeekShifts = {}

  for (const shift of allShifts) {
    shifts[shift.doctor] = shifts[shift.doctor] || []
    doctorWeekShifts[shift.doctor] = doctorWeekShifts[shift.doctor] || [
      [], [], [], [], [], [], []
    ]
    const repeats = shift.repeats
    let dateM = dayjs(shift.firstDate)
    while (dateM.isSameOrBefore(dayjs(shift.lastDate || endOfWeek, 'YYYY-MM-DD'))) {
      if (dateM.isBefore(dayjs(startOfWeek, 'YYYY-MM-DD'))) {
        dateM = dateM.add(1, 'week')
        continue
      }
      const weeks = dateM.week() - dayjs(dateM).startOf('month').week() + 1

      if (['no', 'every_week', 'next_weeks'].includes(repeats) ||
        (repeats === 'even_weeks' && weeks % 2 === 0) ||
        (repeats === 'odd_weeks' && weeks % 2 === 1)
      ) {
        shift.startHour = parseInt(shift.startTime.split(':')[0], 10)
        shift.startMinute = parseInt(shift.startTime.split(':')[1], 10)
        shift.endHour = parseInt(shift.endTime.split(':')[0], 10)
        shift.endMinute = parseInt(shift.endTime.split(':')[1], 10)
        const dayOfWeek = dateM.weekday()
        doctorWeekShifts[shift.doctor][dayOfWeek].push(shift)
        if (dayOfWeek === currentDayOfWeek) {
          shifts[shift.doctor].push(shift)
        }
      }
      dateM = dateM.add(1, 'week')
    }
  }
  return [{ date: currentdate || '', data: shifts }, doctorWeekShifts]
}

function Appointments({ currentUser }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, setUiState, uiState, setHeaderTools, currentCompany } = useContext(ContextStore)
  const classes = useStyles();
  const users = useSelector(state => state.users.ordered).filter(u => !u.developer)

  const location = useLocation();
  const [doctorLeaves, setDoctorLeaves] = useState({})
  const [commentData, setCommentData] = useState({})
  const currentDateM = dayjs(uiState.date)
  const currentDayOfWeek = currentDateM.day()
  const startOfWeek = currentDateM.startOf('week').format('YYYY-MM-DD')
  const endOfWeek = currentDateM.endOf('week').format('YYYY-MM-DD')
  const [appointmentData, setAppointmentData] = useState({})
  const [customerMapping, setCustomerMapping] = useState({})
  const userRights = useSelector(state => state.userRights)
  const config = useSelector(state => state.config.data)
  const salesRepsDepartments = []// config.salesReps ? config.salesReps.departments : []
  const salesReps = users.filter(i => i.active && salesRepsDepartments.includes(i.department) && i?.includes(currentCompany))
  const doctors = users.filter(i => i.active && i.department === 'doctor' && i.company?.includes(currentCompany))

  const appointmentType = ['normalAppointment'].concat(Object.keys(config.appointmentType || {}).filter(i => config.appointmentType[i].active).map(i => config.appointmentType[i].id))
  const moduleMapping = useModuleMapping()
  const defaultMode = moduleMapping.doctor ? 'doctor' : 'salesRep'
  const scheduleMode = uiState.scheduleMode || defaultMode

  const [repeatShifts, setRepeatShifts] = useState([])
  const [singleShifts, setSingleShifts] = useState([])
  const [doctorShifts, doctorWeekShifts] = getDoctorShifts(repeatShifts.concat(singleShifts), uiState.date, startOfWeek, endOfWeek, currentDayOfWeek)
  const { newDoctors, newDoctorShifts, newDoctorLeaves, newDoctorWeekShifts } = filteredDoctorsAndDoctorShifts()

  const filteredAppointmentTypes = appointmentType.filter(type =>
    APPOINTMENT_TYPE_ALWAYS_SHOW.includes(type) || doctors.find(doctor => {
      return doctor.appointmentType === type && ((doctorShifts.data[doctor.id] && doctorShifts.data[doctor.id].length) || uiState.noShifts)
    })
  )

  useEffect(() => {
    setHeaderTools(<>
      <AppointmentDate />
      <AppointmentFilter scheduleMode={scheduleMode} />
      <CompanyFilter userRight='appointment-view' />
      <AppointmentButtonRow />
    </>)
    return () => {
      setHeaderTools(null)
    };
  }, [scheduleMode]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      onData: (data) => {
        setCustomerMapping(data)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'comments',
      where: [
        ['date', '==', dayjs(uiState.date).format('YYYY-MM-DD')],
        ['source', '==', currentCompany]
      ],
      mapping: true,
      onData: (data) => {
        setCommentData(data)
      }
    })

    return () => unsubscribe()
  }, [uiState.date, currentCompany]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'appointments',
      where: [
        ['date', '==', dayjs(uiState.date).format('YYYY-MM-DD')],
        ['source', '==', currentCompany]
      ],
      mapping: true,
      onData: (data) => {
        setAppointmentData(data)
      }
    })

    return () => unsubscribe()
  }, [uiState.date, currentCompany]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'doctorShifts',
      where: [['repeats', '!=', 'no']],
      onData: (data) => {
        const shifts = data.filter(i =>
          !(i.lastDate && i.lastDate < startOfWeek) &&
          !(i.firstDate && i.firstDate > endOfWeek) &&
          i.source === currentCompany
        )
        setRepeatShifts(shifts)
      }
    })

    return () => unsubscribe()
  }, [currentCompany]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'doctorShifts',
      where: [
        ['firstDate', '>=', startOfWeek],
        ['firstDate', '<=', endOfWeek],
        ['repeats', '==', 'no']
      ],
      onData: (data) => {
        const shifts = data.filter(i => i.source === currentCompany)
        setSingleShifts(shifts)
      }
    })

    return () => unsubscribe()
  }, [startOfWeek, endOfWeek, currentCompany]);

  useEffect(() => {
    if (doctors) {
      const unsubscribe = firestoreListener({
        collection: 'doctorLeaves',
        where: [['firstDate', '==', dayjs(uiState.date).format('YYYY-MM-DD')]],
        mapping: true,
        onData: (data) => {
          const leaves = {}
          const startOfWeek = currentDateM.startOf('week').format('YYYY-MM-DD')
          const endOfWeek = currentDateM.endOf('week').format('YYYY-MM-DD')

          for (const leave of objectToArray(data)) {
            leaves[leave.doctor] = leaves[leave.doctor] || []

            const repeats = leave.repeats
            let dateM = dayjs(leave.firstDate)
            while (dateM.isSameOrBefore(dayjs(leave.lastDate || endOfWeek, 'YYYY-MM-DD'))) {
              if (dateM.isBefore(dayjs(startOfWeek, 'YYYY-MM-DD'))) {
                dateM = dateM.add(1, 'week')
                continue
              }

              if (repeats === 'leaves') {
                leave.startHour = parseInt(leave.startTime.split(':')[0], 10)
                leave.startMinute = parseInt(leave.startTime.split(':')[1], 10)
                leave.endHour = parseInt(leave.endTime.split(':')[0], 10)
                leave.endMinute = parseInt(leave.endTime.split(':')[1], 10)
                const dayOfWeek = dateM.weekday()

                if (dayOfWeek === currentDayOfWeek) {
                  if (leave.source === currentCompany) {
                    leaves[leave.doctor].push(leave)
                  }
                }
              }
              dateM = dateM.add(1, 'week')
            }
          }

          setDoctorLeaves(leaves)
        }
      })

      return () => unsubscribe()
    }
  }, [uiState.date, currentCompany]);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.appointments.schedule' })
    }])
    return () => {
    };
  }, [location.pathname]);

  function filteredDoctorsAndDoctorShifts() {
    if (!doctors) {
      return {}
    }

    const applyFilter = currentUser.department === 'doctor' && !userRights.debugging
    const newDoctorShifts = applyFilter ? { [currentUser.key]: doctorShifts.data[currentUser.key] } : doctorShifts.data
    const newDoctors = applyFilter ? doctors.filter(d => d.id === currentUser.key) : doctors
    const newDoctorWeekShifts = applyFilter ? { [currentUser.key]: doctorWeekShifts[currentUser.key] } : doctorWeekShifts
    const newDoctorLeaves = applyFilter ? { [currentUser.key]: doctorLeaves[currentUser.key] } : doctorLeaves

    return {
      newDoctorShifts,
      newDoctors,
      newDoctorLeaves,
      newDoctorWeekShifts
    }
  }

  function onCommentClose() {
    setUiState({
      ...uiState,
      showCommentSidebar: false,
      editComment: null
    })
  }

  function onRequestClose() {
    setUiState({
      ...uiState,
      showAppointmentSidebar: false,
      selectedAppointment: false,
      selectedAppointmentEl: {},
      clickMenuData: null,
      editAppointment: null
    })
  }

  function onSchedduleClose() {
    setUiState({
      ...uiState,
      showDoctorSchedule: false,
    })
  }

  return (
    <div className={classes.root}>
      {uiState.showAppointmentSidebar && <AppointmentSidebar
        onRequestClose={onRequestClose}
        ui={uiState}
        currentUser={currentUser}
        currentCompany={currentCompany}
      />}
      {uiState.showCommentSidebar && <CommentSidebar
        onRequestClose={onCommentClose}
        ui={uiState}
        appointmentTypes={filteredAppointmentTypes}
        doctors={newDoctors}
        currentCompany={currentCompany}
      />}
      {moduleMapping.doctor && uiState.showDoctorSchedule && <DoctorSchedule
        doctorWeekShifts={newDoctorWeekShifts}
        onRequestClose={onSchedduleClose}
        currentCompany={currentCompany}
      />}
      <ScheduleDay
        scheduleMode={scheduleMode}
        appointmentTypes={filteredAppointmentTypes}
        salesReps={salesReps}
        doctors={newDoctors}
        doctorShifts={newDoctorShifts}
        doctorLeaves={newDoctorLeaves}
        appointmentData={appointmentData}
        customerMapping={customerMapping}
        commentData={commentData || {}}
      />
    </div>
  );
}

Appointments.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }),
};

export default Appointments;
