import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import { getComparator, stableSort } from 'modules/sort';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableRow from './EnhancedTableRow';

// NOTE: 使用 CustomTableRow 的話就不要套用這個元件
function EnhancedTable({ size, containerStyle = {}, tableData, defaultOrder, defaultOrderField, headerCells, rowCells, extRows = null, getHeaderActionIcons, getActionIcons, getExpandContent, getSelectionCount, getRowExpandedStatus, forceExpanded, getRowCheckBoxStatus, onRadioButtonClick, onHeaderCheckboxClick, onRowCheckboxClick, onOrderChanged, onRowClick }) {
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderField);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc'
    setOrder(newOrder);
    setOrderBy(property);
    if (onOrderChanged) {
      onOrderChanged({ order: newOrder, orderBy })
    }
  };

  const tableProps = {}

  if (size) {
    tableProps.size = size
  }

  const headerProps = {
    headerCells,
    onRequestSort: handleRequestSort,
    rowCount: tableData.length
  }

  if (getExpandContent) {
    headerProps.expandable = true
    if (getHeaderActionIcons) {
      headerProps.headerActionButton = getHeaderActionIcons()
    }
    if (getActionIcons) {
      headerProps.actionButton = true
    }
  } else {
    if (getActionIcons) {
      headerProps.actionButton = getHeaderActionIcons?.() ?? true
    } else if (getHeaderActionIcons) {
      headerProps.expandable = true
      headerProps.headerActionButton = getHeaderActionIcons()
    }
  }

  if (onRadioButtonClick) {
    headerProps.radioButton = true
  }

  if (defaultOrder && defaultOrderField) {
    headerProps.order = order
    headerProps.orderBy = orderBy
  }
  if (onHeaderCheckboxClick) {
    headerProps.onCheckboxClick = onHeaderCheckboxClick
  }
  if (getSelectionCount) {
    headerProps.numSelected = getSelectionCount()
  }

  function getRow(rowData) {
    const rowProps = {
      rowCells,
      cellData: rowData,
    }

    if (getExpandContent) {
      rowProps.expandable = true
      rowProps.expandContent = getExpandContent(rowData)
    } else {
      if (getHeaderActionIcons) {
        rowProps.expandable = true
      }
    }

    if (getActionIcons) {
      rowProps.actionIcons = getActionIcons(rowData)
    }

    if (onRadioButtonClick) {
      rowProps.onRadioButtonClick = () => onRadioButtonClick(rowData)
    }

    if (onRowCheckboxClick) {
      rowProps.onCheckboxClick = () => onRowCheckboxClick(rowData)
    }

    if (getRowCheckBoxStatus) {
      rowProps.selected = getRowCheckBoxStatus(rowData)
    }

    if (getRowExpandedStatus) {
      if (forceExpanded) {
        rowProps.forceExpanded = !!forceExpanded
      }
      rowProps.expanded = getRowExpandedStatus(rowData)
    }

    if(onRowClick) {
      rowProps.onRowClick = () => onRowClick(rowData)
    }

    return <EnhancedTableRow
      key={rowData.key || rowData.id}
      {...rowProps}
    />
  }

  return <TableContainer style={containerStyle} component={Paper}>
    <Table {...tableProps}>
      <EnhancedTableHead {...headerProps} />
      <TableBody>
        {stableSort(tableData, getComparator(order, orderBy)).map(rowData => getRow(rowData))}
        {extRows}
      </TableBody>
    </Table>
  </TableContainer>
}

EnhancedTable.propTypes = {
  defaultOrder: PropTypes.oneOf(['asc', 'desc']),
  defaultOrderField: PropTypes.string,
  headerCells: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    sort: PropTypes.string,
    align: PropTypes.string,
  })).isRequired,
  rowCells: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    align: PropTypes.string,
    type: PropTypes.string,
    calculate: PropTypes.string,
    label: PropTypes.string,
    onValueChanged: PropTypes.func,
  })).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRadioButtonClick: PropTypes.func,
  onHeaderCheckboxClick: PropTypes.func,
  onRowCheckboxClick: PropTypes.func,
  forceExpanded: PropTypes.bool,
  getRowExpandedStatus: PropTypes.func,
  getRowCheckBoxStatus: PropTypes.func,
  getActionIcons: PropTypes.func,
  getHeaderActionIcons: PropTypes.func,
  getExpandContent: PropTypes.func,
  getSelectionCount: PropTypes.func,
  onOrderChanged: PropTypes.func,
  size: PropTypes.string,
  containerStyle: PropTypes.object,
  extRows: PropTypes.node,
  onRowClick: PropTypes.func,
};

export default EnhancedTable;
