import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';

import ActionDialog from 'components/ActionDialog';
import ProgressStep from 'components/ProgressStep';
import { callFunction } from 'modules/firebase';

function AnnouncementView({ userMapping, announcement, currentUser, onView, showActions, ...props }) {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const [dialogData, setDialogData] = useState(null);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [loadingVoid, setLoadingVoid] = useState(false);

  const announcementHistory = (announcement.history || []).map(h => ({ ...h }))
  const currentStep = announcementHistory.length > 0 ? announcementHistory[announcementHistory.length - 1].step : 0

  function decodeModifyLog(log) {
    log = log.replace(/f{modify}/g, formatMessage({ id: 'step.action.modify' }))
    log = log.replace(/f{update}/g, formatMessage({ id: 'step.action.update' }))
    log = log.replace(/f{add}/g, formatMessage({ id: 'step.action.add' }))
    log = log.replace(/f{remove}/g, formatMessage({ id: 'step.action.remove' }))
    log = log.replace(/f{note}/g, formatMessage({ id: 'announcement.table.note' }))
    log = log.replace(/f{subject}/g, formatMessage({ id: 'announcement.table.subject' }))
    log = log.replace(/f{attachment}/g, formatMessage({ id: 'announcement.table.attachment' }))
    log = log.replace(/f{contentMD5}/g, formatMessage({ id: 'announcement.table.contentMD5' }))

    return log
  }

  const ls = announcement.status !== 'void' ? announcement.steps.slice(currentStep, announcement.steps.length).map(s => ({ ...s })) : []
  if (announcement.status !== 'void' && announcement.lock && currentStep === 0) {
    ls[0].name = '確認修改內容'
  }
  const steps = [...announcementHistory].concat(ls)
  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    }
  }
  const lastHistory = announcement.history[announcement.history.length - 1]

  function allowEditing() {
    if (announcement.lock) {
      if ((currentStep === 0 || announcement.status === 'done') && announcement.lock && announcement.steps[0].overwrite.includes(currentUser.key)) {
        return true
      }
    } else {
      if (((currentStep === 1 && lastHistory.action !== 'reject') || currentStep === 0) && announcement.steps[0].users.includes(currentUser.key)) {
        return true
      }
    }
    return false
  }

  function edit() {
    navigate(`/announcement/edit/${announcement.id}`);
  }

  function reject() {
    setDialogData({ action: 'reject', title: '' })
  }

  function approve() {
    setDialogData({ action: 'approve', title: '' })
  }

  function abandon() {
    setDialogData({ action: 'void', title: '' })
  }

  function handleClose() {
    setDialogData(null)
  }

  async function handleExecute(data) {
    const { action, text } = data
    setDialogData(null)
    if (['approve', 'reject', 'void'].includes(action)) {
      if (action === 'approve') {
        setLoadingApprove(true)
      } else if (action === 'reject') {
        setLoadingReject(true)
      } else if (action === 'void') {
        setLoadingVoid(true)
      }
      try {
        // console.log(JSON.stringify({
        //   id: announcement.id,
        //   action: action,
        //   note: text
        // }, null, 4))
        await callFunction('reviewAnnouncement', {
          id: announcement.id,
          action: action,
          note: text
        })
        if (['approve', 'confirm'].includes(action)) {
          if (currentStep < announcement.steps.length - 1) {
            setLoadingApprove(false)
          }
        } else {
          setLoadingReject(false)
        }
      } catch (ex) {
        setLoadingApprove(false)
        setLoadingReject(false)
        console.log(ex)
      }
    }
  }

  function getEditButtons() {
    return <>
      <Button
        variant="contained"
        color="primary"
        onClick={edit}
        disabled={loadingApprove || loadingReject || loadingVoid}
      >
        <FormattedMessage id="button.edit" />
      </Button>
      <LoadingButton
        color="error"
        onClick={abandon}
        disabled={loadingApprove || loadingReject || loadingVoid}
        loading={loadingVoid}
        loadingPosition="start"
        loadingIndicator={<CircularProgress size={24} />}
        startIcon={<div />}
        variant="contained"
      >
        <FormattedMessage id="button.void" />      </LoadingButton>
    </>
  }

  return <div style={{ paddingTop: '8px' }}>
    <Box p={0}>

      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` }) + (announcement.void ? formatMessage({ id: 'button.void' }) : '') + formatMessage({ id: 'announcement.name' })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'announcement.table.note' })}
        action={dialogData.action}
      />}
      <ProgressStep activeStep={announcement.history.length} steps={steps} />
      <Divider style={{ margin: '8px 0px' }} />
      <Grid container spacing={1}>
        <Grid item key="buttons" xs={12} sm={12} md={12}>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
            {!showActions && allowEditing() && !announcement.void &&
              getEditButtons()
            }
            <Button
              variant="contained"
              color="primary"
              onClick={onView}
            >
              <FormattedMessage id="announcement.view" />
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {showActions && <>
        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item key="buttons" xs={12} sm={12} md={12}>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
              {allowEditing() && !announcement.void &&
                getEditButtons()
              }
              {currentStep > 0 && currentStep < announcement.steps.length && announcement.steps[currentStep].users.includes(currentUser.key) && !announcement.void && !announcement.lock &&
                <LoadingButton
                  color="error"
                  onClick={reject}
                  disabled={loadingApprove || loadingReject || loadingVoid}
                  loading={loadingReject}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.reject" />
                </LoadingButton>
              }
              {currentStep > 0 && currentStep < announcement.steps.length && announcement.steps[currentStep].users.includes(currentUser.key) && announcement.status !== 'void' && !announcement.lock &&
                <LoadingButton
                  color="success"
                  onClick={approve}
                  disabled={loadingApprove || loadingReject || loadingVoid}
                  loading={loadingApprove}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.approve" />
                </LoadingButton>
              }
            </Stack>
          </Grid>
        </Grid>
      </>}
    </Box>
  </div>

}

AnnouncementView.propTypes = {
  announcement: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
};

export default AnnouncementView;