const i18n = {
  'editWorkflow': {
    'addStep': '新增簽核步驟',
    'deleteStep': '刪除此步驟',
    'announcementSteps': {
      'table': {
        'title': '公告流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '公告者',
          'approver': '簽核人',
        }
      },
      'firstStep': '送出公告',
      'finalStep': '下架公告',
    },
    'inventoryCheckSteps': {
      'table': {
        'title': '庫存盤點流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '盤點人',
          'approver': '簽核人',
        }
      },
      'firstStep': '建立盤點單',
      'finalStep': '解鎖盤點單',
    },
    'requisitionSteps': {
      'table': {
        'title': '請購流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '請購人',
          'approver': '簽核人',
          'skipCategories': '略過商品分類',
        }
      },
      'firstStep': '送出請購單',
      'finalStep': '編輯已完成的請購單',
    },
    'purchaseOrderSteps': {
      'table': {
        'title': '採購流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '採購人',
          'approver': '簽核人',
          'skipCategories': '略過商品分類',
        }
      },
      'firstStep': '送出採購單',
      'finalStep': '手動結單',
    },
    'receiptSteps': {
      'table': {
        'title': '進貨流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '進貨者',
          'approver': '簽核人',
          'skipCategories': '略過商品分類',
        }
      },
      'firstStep': '送出進貨單',
      'finalStep': '編輯已完成的進貨單',
    },
    'deliveryOrderSteps': {
      'table': {
        'title': '銷貨流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '出貨者',
          'approver': '簽核人',
        }
      },
      'firstStep': '建立銷貨單',
      'finalStep': '確認銷貨退回單',
    },
    'newSupplierSteps': {
      'table': {
        'title': '新供應商申請流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '申請人',
          'approver': '簽核人',
        }
      },
      'firstStep': '送出新供應商申請單',
      'finalStep': '通知建立資料',
    },
    'newMerchandiseSteps': {
      'table': {
        'title': '新商品申請流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '申請人',
          'approver': '簽核人',
        }
      },
      'firstStep': '送出新商品申請單',
      'finalStep': '通知建立資料',
    },
    'newProductSteps': {
      'table': {
        'title': '新療程申請流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '申請人',
          'approver': '簽核人',
        }
      },
      'firstStep': '送出新療程申請單',
      'finalStep': '通知建立資料',
    },
    'stockRequisitionSteps': {
      'table': {
        'title': '領用流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '申請人',
          'approver': '簽核人',
          'skipCategories': '略過商品分類',
        }
      },
      'firstStep': '送出領用單',
    },
    'scrapFormSteps': {
      'table': {
        'title': '報廢出庫流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '申請人',
          'approver': '簽核人',
        }
      },
      'firstStep': '送出報廢出庫單',
    },
    'inventoryChangeSteps': {
      'table': {
        'title': '庫存異動流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '申請人',
          'approver': '簽核人',
        }
      },
      'firstStep': '送出庫存異動單',
    },
  },
  'editBorrowingStaff': {
    'table': {
      'title': '借貨流程',
      'borrowingOrder': '建立借貨單',
      'borrowingDelivery': '建立借貨出貨單',
      'borrowingReceipt': '建立借貨入庫單',
      'returnBorrowingForm': '建立借貨歸還單',
      'returnBorrowingReceipt': '驗收借貨歸還入庫單',
      'detail': {
        'name': '步驟名稱',
        'user': '使用者',
      }
    },
  },
  'editVendorUserRights': {
    'stock': {
      'table': {
        'title': '倉管流程',
        'query': '庫存查詢',
        'inventory': '庫存盤點',
        'deliveryOrder': '建立銷貨單',
        'returnForm': '建立退貨單',
        'detail': {
          'name': '步驟名稱',
          'user': '使用者',
        }
      },
    },
    'appointment': {
      'table': {
        'title': '預約表',
        'view': '查看預約表',
        'edit': '編輯預約表',
        'detail': {
          'name': '名稱',
          'user': '使用者',
        }
      },
    },
    'finance': {
      'table': {
        'title': '財務管理',
        'view': '查看財務管理',
        'edit': '編輯財務管理',
        'salary': '編輯薪資管理',
        'announcement': '編輯公告',
        'detail': {
          'name': '名稱',
          'user': '使用者',
        }
      },
    },
    'leaveOvertime': {
      'table': {
        'title': '請假加班',
        'overview': '查看全部請假加班',
        'detail': {
          'name': '名稱',
          'user': '使用者',
        }
      },
    },
    'punchClockRevise': {
      'table': {
        'title': '打卡',
        'overview': '查看全部補打卡',
        'detail': {
          'name': '名稱',
          'user': '使用者',
        }
      },
    },
    'schedule': {
      'table': {
        'title': '班表',
        'view': '查看班表',
        'edit': '編輯班表',
        'exception': '編輯補班補假',
        'doctorEdit': '編輯醫師班表',
        'doctorPunchClock': '補登醫師打卡',
        'detail': {
          'name': '名稱',
          'user': '使用者',
        }
      },
    },
    'report': {
      'table': {
        'title': '報表',
        'edit': '編輯報表',
        'view': '查看報表',
        'detail': {
          'name': '名稱',
          'user': '使用者',
        }
      },
    },
    'salesOrder': {
      'table': {
        'title': '營收單',
        'create': '新增營收單',
        'edit': '編輯營收單',
        'view': '查看營收單',
        'void': '作廢營收單',
        'detail': {
          'name': '名稱',
          'user': '使用者',
        }
      },
    },
    'refund': {
      'table': {
        'title': '退費單',
        'create': '新增退費單',
        'edit': '編輯退費單',
        'view': '查看退費單',
        'detail': {
          'name': '名稱',
          'user': '使用者',
        }
      },
    },
    'referrer': {
      'table': {
        'title': '轉介費單',
        'create': '新增轉介費單',
        'edit': '編輯轉介費單',
        'view': '查看轉介費單',
        'void': '作廢轉介費單',
        'detail': {
          'name': '名稱',
          'user': '使用者',
        }
      },
    },
    'consumeService': {
      'table': {
        'title': '消耗單',
        'create': '新增消耗單',
        'edit': '編輯消耗單',
        'view': '查看消耗單',
        'void': '作廢消耗單',
        'detail': {
          'name': '名稱',
          'user': '使用者',
        }
      },
    },
    'transferService': {
      'table': {
        'title': '轉讓單',
        'create': '新增轉讓單',
        'view': '查看轉讓單',
        'detail': {
          'name': '名稱',
          'user': '使用者',
        }
      },
    },
    'transformService': {
      'table': {
        'title': '轉換單',
        'create': '新增轉換單',
        'view': '查看轉換單',
        'detail': {
          'name': '名稱',
          'user': '使用者',
        }
      },
    },
  },
}
export default i18n
