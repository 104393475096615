const i18n = {
  'header': {
    'appointment': {
      'today': '今天',
      'NewReservation': '新增預約',
      'NewComment': '新增註記',
      'toggleMode': '切換模式',
      'doctorSchedule': '醫師班表',
      // 'newBooking': '會議室/車位',
      'filter': {
        'noShifts': '顯示未值班醫師',
        'showNurse': '顯示護理師',
        'showRoom': '顯示診間',
        'showSalesRep': '顯示服務人員',
        'showDoctor': '顯示醫師',
        'showCustomerType': '顯示客戶類型',
        'showBirthDate': '顯示生日',
        'showCustomerCode': '顯示客戶編號'
      }
    },
    'schedule': {
      'filter': {
        'showPunchClock': '顯示打卡時間',
        'showLeave': '顯示請假',
        'showWeeklyLeave': '顯示本休',
        'showException': '顯示補班補假',
        'showOvertime': '顯示加班',
        'showShift': '顯示排班',
        'showComment': '顯示備註',
      }
    },
    'dialog': {
      'user': {
        'selectUserDialog': '選擇使用者',
        'title': '使用者列表',
        'root': '使用者',
        'displayName': '姓名',
        'email': 'Email'
      }
    },
    'notice': {
      'pending': '待處理的事項',
      'leaves': '待簽核的請假單',
      'overtimes': '待簽核的加班單',
      'punchClockRevise': '待簽核的忘刷卡單',
      'requisitions': '待處理的請購單',
      'readyToBuy': '待採購的請購單',
      'shoppingCart': '採購清單中的商品',
      'purchaseOrders': '待處理的採購單',
      'receipts': '待驗收的進貨單',
      'stockRequisitions': '待處理的領用單',
      'scrapForms': '待處理的報廢出庫單',
      'salesReturnForms': '待驗收的銷貨退回單',
      'inventoryChanges': '待處理的庫存異動單',
      'inventoryChecks': '待處理的盤點單',
      'announcements': '新公告',
    }
  }
}

export default i18n