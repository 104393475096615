const i18n = {
  'product': {
    'table': {
      'title': '產品列表',
      'header': {
        'name': '名稱',
        'nickname': '簡稱',
        'description': '描述',
        'availableAmount': '剩餘數量'
      },
    },
    'dialog' : {
      'title' : {
        'add': '新增產品',
        'edit': '編輯產品'
      },
    },
    'edit': {
      'name': '名稱',
      'nickname': '簡稱',
      'tags': '標籤',
      'type': '類型',
      'seller': '銷售單位',
      'onShelfDate': '上架時間',
      'offShelfDate': '下架時間',
      'salesBonus': '銷售獎金',
      'operatorBonus': '操作獎金',
      'cost': '成本',
      'price': '售價',
      'duration': '服務時間',
      'color': '預約表顏色',
      'unit': '單位',
      'note': '備註',
      'description': '描述',
      'commonPrice': '常用價格',
      'transformService': '可轉換服務',
      'addCommonPrice': '新增常用價格',
      'removeCommonPrice': '刪除常用價格',
      'addService': '新增可轉換服務',
      'removeService': '刪除可轉換服務',
      'merchandise': '消耗商品',
      'addMerchandise': '新增消耗商品',
      'removeMerchandise': '刪除消耗商品',
      'customerTypeRule': '設定客戶類型規則',
      'customerType': '客戶類型',
      'rule': '規則',
    },
    'commonPrice': {
      'title': '常用價格列表',
      'name': '名稱',
      'customerTypeRule': '客戶類型規則',
      'useAmount': '使用次數',
      'price': '價格',
      'onShelfDate': '上架日期',
      'offShelfDate': '下架日期',
    },
    'merchandise': {
      'title': '消耗商品列表',
      'merchandise': '名稱',
      'name': '名稱',
      'consumeAmount': '消耗數量',
      'sku': '消耗單位(SKU)'
    },
    'transformService': {
      'title': '可轉換服務列表',
      'product': '服務',
      'name': '服務',
      'consumeAmount': '消耗數量',
      'amount': '轉換數量',
      'onShelfDate': '上架日期',
      'offShelfDate': '下架日期',
    },
    'type': {
      'all': '全部',
      'merchandise': '商品',
      'service': '服務',
      'storedValue': '儲值',
      'pointCard': '點數',
    },
    'customerTypeRule': {
      'limit': '限定',
      'priority': '優先',
      'limitAndPriority': '限定且優先',
    }
  },
  'selectTagDialog': {
    'title': '選擇標籤',
    'selectedTags': '已選擇的標籤: ',
    'search': '搜尋標籤',
    'addTag': '新增標籤',
    'noMatchedTag': '沒有符合的標籤',
  },
  'selectProductDialog': {
    'title': '選擇產品',
    'table': {
      'title': '產品列表'
    }
  },
  'exportDialog': {
    'title': '匯出產品列表設定'
  }
}
export default i18n
