import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import SalesOrderInfoDiablog from 'components/SalesOrderInfoDiablog';
import EnhancedTable from 'components/EnhancedTable';
import { addError, removeError } from 'modules/editor';

function SelectPurchasedServiceDialog({ handleClose, handleSave, userMapping, sourceMapping, customerMapping, ppData, amountFieldLabel, params }) {
  const { formatMessage } = useIntl()
  const [items, setItems] = useState(ppData.map(i => ({ ...i })));
  const [salesOrderInfo, setSalesOrderInfo] = useState(null);
  const totalCount = items.reduce((acc, cur) => acc + parseInt(cur.selectCount || '0'), 0)
  const error = items.some(i => i.errors?.selectCount)

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'date', sort: 'date' },
    { text: 'amount', sort: 'amount' },
    { text: 'quantity', sort: 'quantity' },
    { text: amountFieldLabel },
  ].map(c => {c.text = formatMessage({ id: `selectPurchasedServiceDialog.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'salesOrderSn', type: 'info', onButtonClick: showSalesOrder },
    { field: 'date' },
    { field: 'amount' },
    { field: 'quantity' },
    {
      field: 'selectCount',
      type: 'input-number',
      label: formatMessage({ id: `selectPurchasedServiceDialog.table.header.${amountFieldLabel}` }),
      onValueChanged: onCellValueChanged,
    },
  ]

  function onCellValueChanged(cellData, field, value) {
    const m = items.find(i => i.id === cellData.id)
    if (!m) {
      return
    }

    if (field === 'selectCount') {
      m.selectCount = value
      // 如果是服務或儲值, 要限制數量?
      let error = false
      if (isNaN(value)) {
        error = true
        addError(m, field, '數量錯誤')
      }
      if (!error) {
        if (m.amount < parseInt(value)) {
          error = true
          addError(m, field, '超過剩餘數量')
        }
      }

      if (!error) {
        removeError(m, field)
      }

    } else {
      removeError(m, field)
      m[field] = value
    }

    setItems( items => {
      return [...items]
    })
  }

  const onApply = () => {
    handleSave(params, items.map(i => ({ ...i, selectCount: parseInt(i.selectCount || '0') }) ).filter(i => i.selectCount > 0))
    handleClose()
  }

  function showSalesOrder(key, field, data) {
    setSalesOrderInfo(data)
  }

  return (
    <>
      {salesOrderInfo && <SalesOrderInfoDiablog
        salesOrderId={salesOrderInfo.salesOrder}
        highlightItems={[salesOrderInfo.salesOrderItem]}
        userMapping={userMapping}
        customerMapping={customerMapping}
        sourceMapping={sourceMapping}
        onClose={() => setSalesOrderInfo(null)}
      />}
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title"><FormattedMessage id={'selectPurchasedServiceDialog.title'} /></DialogTitle>
        <DialogContent dividers={true}>
          <div style={{ flexGrow: 1 }}>
            <EnhancedTable
              defaultOrder="asc"
              defaultOrderField="supplier"
              headerCells={headerCells}
              rowCells={rowCells}
              tableData={items}
              extRows={
                <>
                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Typography variant="subtitle2">
                        <FormattedMessage id={`selectPurchasedServiceDialog.table.total${amountFieldLabel}`} />
                      </Typography>
                    </TableCell>
                    <TableCell align="left">{totalCount}</TableCell>
                    <TableCell />
                  </TableRow>
                </>
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary">
            <FormattedMessage id="button.cancel" />
          </Button>
          <Button variant="contained" onClick={onApply} color="primary" disabled={totalCount === 0 || error}>
            <FormattedMessage id="button.ok" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

SelectPurchasedServiceDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  userMapping: PropTypes.object.isRequired,
  sourceMapping: PropTypes.object.isRequired,
  customerMapping: PropTypes.object.isRequired,
  ppData: PropTypes.arrayOf(PropTypes.object).isRequired,
  amountFieldLabel: PropTypes.string.isRequired,
};

export default SelectPurchasedServiceDialog;
