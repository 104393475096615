import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import SalaryIcon from '@mui/icons-material/AttachMoney';

import FabAdd from 'components/FabAdd';
import SimpleTableToolbar from 'components/SimpleTableToolbar';
import SearchBox from 'components/SearchBox';
import ExpandButton from 'components/ExpandButton';
import EnhancedTableRow from 'components/EnhancedTableRow';
import EnhancedTableHead from 'components/EnhancedTableHead';
import { getComparator, stableSort } from 'modules/sort';
import ContextStore from 'modules/context';
import { useDepartmentMapping } from 'hooks/departments';
import ReviewStaff from './ReviewStaff';

function Staff() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate();
  const location = useLocation();
  const userRights = useSelector(state => state.userRights)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('status')
  const users = useSelector(state => state.users.ordered).filter(u => currentUser.developer || !u.developer)
  const config = useSelector(state => state.config.data)
  const occupationMaaping = Object.keys(config.occupation || {}).map(i => config.occupation[i]).reduce((acc, cur) => { acc[cur.id] = cur; return acc }, {})
  const depMapping = useDepartmentMapping()

  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);
  const filteredUsers = currentFilter && currentFilter.text ? filterByText() : users

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.staffManagement.staff' })
    }])
    return () => {
    };
  }, [location.pathname]);

  const formatData = (user) => {
    const newData = { ...user }
    newData.name = newData.displayName
    newData.status = newData.active ? 'active' : 'inactive'
    newData.statusName = newData.active ? '啟用' : '停用'
    newData.departmentName = depMapping[newData.department]?.name ?? ''
    newData.position = newData.isManagement ? '主管' : '職員'
    newData.gender = formatMessage({ id: `staff.profile.gender.${newData.gender}` })
    newData.occupation = occupationMaaping[newData.occupation]?.name ?? ''

    if (!newData.active) {
      newData.textColor = '#bab7b7'
    }

    return newData
  }

  function filterByText() {
    if (currentFilter.name === 'name') {
      return users.filter(s => s.displayName && s.displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'email') {
      return users.filter(s => s.email.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'department') {
      return users.filter(s => {
        return depMapping[s.department].name.toLowerCase().includes(currentFilter.text.toLowerCase())
      })
    } else if (currentFilter.name === 'onBoardingDate') {
      return users.filter(s => s.onBoardingDate.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else {
      return users.filter(s => formatMessage({ id: `staff.table.${s.active ? 'active' : 'inactive'}` }).toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  const headerCells = [
    { text: 'name', sort: 'name' },
    { text: 'email', sort: 'email' },
    { text: 'department', sort: 'department' },
    { text: 'occupation', sort: 'occupation' },
    { text: 'onBoardingDate', sort: 'onBoardingDate' },
    { text: 'status', sort: 'active' },
  ].map(c => { c.text = formatMessage({ id: `staff.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'name', textColor: true, account: true },
    { field: 'email', textColor: true },
    { field: 'departmentName', textColor: true },
    { field: 'occupation', textColor: true },
    { field: 'onBoardingDate', textColor: true },
    { field: 'statusName', textColor: true },
  ]

  const filterItems = [
    { name: 'name' },
    { name: 'email', },
    { name: 'department' },
    { name: 'onBoardingDate' },
    { name: 'status' },
  ].map(i => { i.text = formatMessage({ id: `staff.table.${i.name}` }); return i })

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  return (
    <div style={{ padding: '12px 24px 80px 24px' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <SimpleTableToolbar
            title={'noMessage'}
            bottons={<ExpandButton open={expand} onExpandChange={setExpand} />}
            toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={filteredUsers.length}
                expandable
                actionButton
              />
              <TableBody>
                {stableSort(filteredUsers.map(r => formatData(r)), getComparator(order, orderBy)).map(user => (
                  <EnhancedTableRow
                    key={user.id}
                    rowCells={rowCells}
                    cellData={user}
                    expandable
                    expandContent={<ReviewStaff user={user} />}
                    actionIcons={<>
                      {/* 修正權限檢查 */}
                      {userRights.hasUserRight('finance-salary') ? <Tooltip title={formatMessage({ id: 'button.salary' })}>
                        <IconButton
                          onClick={() => navigate(`/staffManagement/staff/editSalary/${user.id}`)}
                          size="large">
                          <SalaryIcon />
                        </IconButton>
                      </Tooltip> : null}
                      {userRights.hasUserRight('user-edit') ? <Tooltip title={formatMessage({ id: 'button.edit' })}>
                        <IconButton
                          onClick={() => navigate(`/staffManagement/staff/edit/${user.id}`)}
                          size="large">
                          <EditIcon />
                        </IconButton>
                      </Tooltip> : null}
                    </>
                    }
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {userRights.hasUserRight('user-edit') && <div>
          <FabAdd onClick={() => navigate('/staffManagement/staff/edit/new')} />
        </div>}
      </Grid>
    </div>
  );
}

export default Staff;
