import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

function SupplierInfoDiablog({ supplier, onClose }) {
  const { formatMessage } = useIntl()

  const billRuleMapping = {
    '30': formatMessage({ id: 'vendor.billRule.rule1' }),
    '60': formatMessage({ id: 'vendor.billRule.rule2' }),
    '90': formatMessage({ id: 'vendor.billRule.rule3' }),
    'cashOnDelivery': formatMessage({ id: 'vendor.billRule.rule4' }),
    'ttInAdvance': formatMessage({ id: 'vendor.billRule.rule5' }),
  }

  const getSupplierInfo = (supplier, billRuleMapping) => {
    if (!supplier) return ''
    const supplierFields = ['code', 'name', 'businessNumber', 'billRule', 'phone', 'fax', 'address', 'email', 'contacts', 'note']
    const contactFields = ['contactName', 'contactPhone', 'contactMobile', 'contactNote']
    let info = []

    for (const f of supplierFields) {
      if (supplier[f]) {
        if (f === 'contacts') {
          for (const contact of supplier.contacts) {
            let contactText = []
            for (const cf of contactFields) {
              let text = contact[cf]
              if (cf === 'contactPhone') {
                text = text.replace(/\s/g, '').replace(/#$/,'')
              }
              contactText.push(`${formatMessage({ id: `vendor.contact.${cf}` })}: ${text}`)
            }
            info.push(contactText.join('  '))
          }
        } else if (f === 'billRule') {
          info.push(billRuleMapping[supplier.billRule])
        }
        else {
          let text = supplier[f]
          if (f === 'phone' || f === 'fax') {
            text = text.replace(/\s/g, '').replace(/#$/,'')
          }
          info.push(`${formatMessage({ id: `vendor.table.detail.${f}` })}: ${text}`)
        }
      }
    }
    return info.join('\n')
  }

  return <Dialog maxWidth="md" onClose={onClose} open>
    <Box p={3}>
      <div style={{ whiteSpace: 'pre' }}>
        {getSupplierInfo(supplier, billRuleMapping)}
      </div>
    </Box>
  </Dialog>
}


SupplierInfoDiablog.propTypes = {
  supplier: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SupplierInfoDiablog;
