import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { arrayMove } from '@dnd-kit/sortable';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import DndContainer from 'components/DndContainer';
import DragHandle from 'components/DragHandle';
import ButtonProgress from 'components/ButtonProgress';
import { CustomerExtDataType, CustomerExtDataTypeMapping, SelectDialogType, SelectDialogTypeMapping } from 'constants';
import { callFunction } from 'modules/firebase';
import { getRandomHash } from 'modules/uitls';

const rowStyle = {
  borderTop: 'solid 1px #efefef',
  width: '100%',
  height: '80px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#fff',
  padding: '0px 16px 0px 8px',
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
  zIndex: 10000,
}

const cellStyle = {
  marginLeft: '8px',
  marginRight: '8px',
  flexGrow: 2
}

function SelectItemDialog({ handleClose, onChange, index, rowData }) {
  const { formatMessage } = useIntl()
  const [fields, setFields] = useState(rowData.counts)

  const addSelectItem = () => {
    const newData = { ...fields, [getRandomHash()]: '' }

    setFields(newData)
  }

  function upadteItem(field, value) {
    let newData = { ...fields, [field]: value }

    setFields(newData)
  }

  function itemSave() {
    onChange(index, { name: 'counts' }, fields)
    handleClose()
  }

  function itemDelete(field) {
    let newData = Object.keys(fields).reduce((acc, cur) => {
      if (cur !== field) {
        acc[cur] = fields[cur]
      }

      return acc
    }, {})

    setFields(newData)
    onChange(index, { name: 'counts' }, newData)
  }

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      maxWidth='xs'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
    >
      <Box p={2} sx={{ minHeight: '400px', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography sx={{ flex: '1 1 20%' }} variant="h6" id="tableTitle" component="div">
            {formatMessage({ id: 'setCustomer.extData.editCustomerExtData.editSelectItem.title' })}
          </Typography>
          <Button
            sx={{ m: 1, whiteSpace: 'nowrap' }}
            variant='text'
            color="primary"
            onClick={() => addSelectItem()}
          >
            <FormattedMessage id="setCustomer.extData.editCustomerExtData.editSelectItem.add" />
          </Button>
        </div>
        <Grid container spacing={3}>
          {Object.keys(fields).map((field, index) => {
            return <Grid key={field} item xs={12} >
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                <TextField
                  type="text"
                  size="small"
                  label={formatMessage({ id: 'setCustomer.extData.editCustomerExtData.selectCountName' }, { count: index + 1 })}
                  variant="outlined"
                  value={rowData.counts[field]}
                  onChange={e => upadteItem(field, e.target.value)}
                  fullWidth
                />
                <Button color='error' onClick={(e) => itemDelete(field)}>
                  <DeleteIcon />
                </Button>
              </div>
            </Grid>
          })}
        </Grid>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <ButtonProgress handleClick={() => itemSave()} handleClose={() => handleClose()} loading={false} />
        </Stack>
      </Box>
    </Dialog>
  );
}

SelectItemDialog.propTypes = {
  rowData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

function SortableItem({ item, index, onChange }) {
  const { formatMessage } = useIntl()
  const [openDialog, setOpenDialog] = useState(false)
  const config = useSelector(state => state.config.data)
  const tabs = Object.keys(config?.customerExtTab || {}).map(p => config.customerExtTab[p].active && config.customerExtTab[p])
  const newTabs = tabs.filter(f => f.related)

  const handleClose = () => {
    setOpenDialog(false)
  }

  function getTypeField() {
    if (['select', 'select-check'].includes(item.type)) {
      return <>
        {openDialog && <SelectItemDialog
          handleClose={handleClose}
          onChange={onChange}
          index={index}
          rowData={item}
        />}
        <Grid item xs={3}>
          <Button onClick={(event) => setOpenDialog(true)}>
            <EditIcon />
            <FormattedMessage id={'setCustomer.extData.editCustomerExtData.editCount'} />
          </Button>
        </Grid>
      </>
    } else if (['dialog'].includes(item.type)) {
      return <Grid item xs={3}>
        <TextField
          type="text"
          size="small"
          label={formatMessage({ id: 'setCustomer.extData.editCustomerExtData.table.selectDialog' })}
          variant="outlined"
          value={item.dialog}
          required
          onChange={e => onChange(index, { name: 'dialog', required: true }, e.target.value)}
          fullWidth
          select
        >
          {SelectDialogType.map(select => {
            return <MenuItem key={select} value={select}>{SelectDialogTypeMapping[select]}</MenuItem>
          })}
        </TextField>
      </Grid>
    } else if (['check'].includes(item.type)) {
      return <Grid item xs={3}>
        <TextField
          type="text"
          size="small"
          label={formatMessage({ id: 'setCustomer.extData.editCustomerExtData.table.selectTabs' })}
          variant="outlined"
          value={item.check}
          required
          onChange={e => onChange(index, { name: 'check', required: true }, e.target.value)}
          fullWidth
          select
        >
          {[{ name: '無', id: 'none' }].concat(newTabs).map(select => {
            return <MenuItem key={select.id} value={select.id}>{select.name}</MenuItem>
          })}
        </TextField>
      </Grid>
    } else {
      return <Grid item xs={3} />
    }
  }

  return (
    <div style={rowStyle}>
      <DragHandle />
      <div style={cellStyle}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              required
              type="text"
              size="small"
              label={formatMessage({ id: 'setCustomer.extData.editCustomerExtData.table.name' })}
              variant="outlined"
              value={item.name}
              onChange={e => onChange(index, { name: 'name', required: true }, e.target.value)}
              error={item.name_err ? true : false}
              helperText={item.name_err}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              required
              type="text"
              select
              label={formatMessage({ id: 'setCustomer.extData.editCustomerExtData.table.type' })}
              value={item.type}
              fullWidth
              variant="outlined"
              size="small"
              onChange={e => onChange(index, { name: 'type', required: true }, e.target.value)}
              error={item.type_err ? true : false}
              helperText={item.type_err}
            >
              {CustomerExtDataType.map(select => {
                return <MenuItem key={select} value={select}>{CustomerExtDataTypeMapping[select]}</MenuItem>
              })}
            </TextField>
          </Grid>
          {getTypeField()}
          <Grid item xs={3}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
              <div>停用</div>
              <Switch
                checked={item.active}
                onChange={e => onChange(index, { name: 'active' }, e.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <div>啟用</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

SortableItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default function EditCustomerExtData({ handleClose, data, tabId, tabName }) {
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)
  const [currentData, setCurrentData] = useState(data)

  function updateCurrentData(index, field, value) {
    let newValue = value

    const data = {
      name: currentData[index].name,
      type: currentData[index].type,
      counts: currentData[index].counts,
      id: currentData[index].id,
      check: currentData[index].check,
      dialog: currentData[index].dialog,
      active: currentData[index].active,
    }

    if (field.required && value.trim() === '') {
      data[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
    }

    if (field.allowCharacter) {
      newValue = Number(newValue.replace(field.allowCharacter, ''))
    }

    data[field.name] = newValue

    const newData = [...currentData]
    newData[index] = data


    setCurrentData(newData)
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = currentData.findIndex(e => e.id === active.id);
      const newIndex = currentData.findIndex(e => e.id === over.id);
      setCurrentData(arrayMove(currentData, oldIndex, newIndex))
    }
  }

  function addExtData() {
    const newData = [...currentData, {
      name: '新項目',
      type: '',
      counts: {},
      dialog: '',
      check: 'none',
      id: getRandomHash(),
      active: true,
    }]
    setCurrentData(newData)
  }

  function getToolbox() {
    return (
      <div>
        <Button
          sx={{ m: 1, whiteSpace: 'nowrap' }}
          variant="contained"
          color="primary"
          onClick={() => addExtData()}
        >
          <FormattedMessage id="setCustomer.extData.editCustomerExtData.add" />
        </Button>
      </div>
    )
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }
    return ''
  }

  async function handleSave() {
    setLoading(true);

    const fields = [
      { name: 'name', required: true },
      { name: 'type', required: true },
    ]

    let err = false

    for (const data of currentData) {
      for (let field of fields) {
        const errMsg = validateField(field, data[field.name])
        if (errMsg) {
          data[`${field.name}_err`] = errMsg
          err = true
        }
      }
    }

    if (err) {
      setCurrentData([...currentData])
      setLoading(false)
      return
    }


    const upadteData = {
      id: tabId,
      ...currentData
    }

    try {
      await callFunction('saveCustomerExtTab', upadteData)
      setLoading(false)
    } catch (ex) {
      setLoading(false)
      console.log(ex)
    }

    handleClose()
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Dialog
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        maxWidth='md'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography sx={{ flex: '1 1 20%' }} variant="h6" id="tableTitle" component="div">
              {formatMessage({ id: 'setCustomer.extData.editCustomerExtData.title' }, { tabName })}
            </Typography>
            {getToolbox()}
          </div>
          <Paper sx={{ overflow: 'hidden' }}>
            <DndContainer
              dargger
              items={currentData}
              handleDragEnd={handleDragEnd}
              ChildItem={SortableItem}
              onChange={updateCurrentData}
            />
          </Paper>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>

            <ButtonProgress handleClick={() => handleSave()} handleClose={() => handleClose()} loading={loading} />
          </Stack>

        </Box>
      </Dialog>
    </div>
  );
}

EditCustomerExtData.propTypes = {
  tabId: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  tabName: PropTypes.string.isRequired
};
