import leaves from './leaves';
import header from './header';
import login from './login';
import resetPassword from './resetPassword';
import overtimes from './overtimes';
import form from './form';
import button from './button';
import tab from './tab';
import homePage from './homePage';
import stepActions from './stepActions';
import dateTime from './dateTime';
import filterText from './filterText';
import schedule from './schedule';
import staff from './staff';
import sideMenu from './sideMenu';
import punchClockRevise from './punchClockRevise';
import punchClockException from './punchClockException';
import dialogs from './dialogs';
import finance from './finance';
import modules from './module';
import setStaff from './setStaff';
import userRights from './userRights';
import product from './product';
import salesOrder from './salesOrder';
import consumedService from './consumedService';
import transferService from './transferService';
import transformService from './transformService'
import customers from './customers';
import appointment from './appointment';
import setAppointment from './setAppointment';
import setCustomer from './setCustomer';
import editDataType from './editDataType';
import setAttendance from './setAttendance';
import refundService from './refundService';
import referrer from './referrer';
import merchandise from './merchandise';
import vendor from './vendor';
import editWarehouseFlow from './editWarehouseFlow';
import requisition from './requisition';
import purchaseOrder from './purchaseOrder';
import receipt from './receipt';
import returnForm from './returnForm';
import deliveryOrder from './deliveryOrder';
import shoppingCart from './shoppingCart';
import invoice from './invoice';
import limit from './limit';
import report from './report';
import stockRequisition from './stockRequisition';
import inventoryCheck from './inventoryCheck';
import salesReturnForm from './salesReturnForm';
import scrapForm from './scrapForm';
import inventoryChange from './inventoryChange';
import announcement from './announcement';

function flattenObject(ob, prefix = '') {
  const toReturn = {}
  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue

    if ((typeof ob[i]) === 'object') {
      const flatObject = flattenObject(ob[i], prefix + i + '.');
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue
        toReturn[x] = flatObject[x];
      }
    } else {
      toReturn[prefix + i] = ob[i]
    }
  }
  return toReturn
}

const i18n = {
  'noMessage': ' ',
  'loading': '載入中...',
  ...punchClockRevise,
  ...staff,
  ...sideMenu,
  ...schedule,
  ...tab,
  ...leaves,
  ...overtimes,
  ...header,
  ...login,
  ...resetPassword,
  ...form,
  ...button,
  ...homePage,
  ...stepActions,
  ...dateTime,
  ...filterText,
  ...punchClockException,
  ...dialogs,
  ...finance,
  ...modules,
  ...setStaff,
  ...userRights,
  ...product,
  ...salesOrder,
  ...consumedService,
  ...transferService,
  ...transformService,
  ...merchandise,
  ...vendor,
  ...editWarehouseFlow,
  ...requisition,
  ...purchaseOrder,
  ...invoice,
  ...customers,
  ...appointment,
  ...setAppointment,
  ...setCustomer,
  ...editDataType,
  ...setAttendance,
  ...refundService,
  ...referrer,
  ...limit,
  ...report,
  ...shoppingCart,
  ...receipt,
  ...returnForm,
  ...deliveryOrder,
  ...stockRequisition,
  ...inventoryCheck,
  ...salesReturnForm,
  ...scrapForm,
  ...inventoryChange,
  ...announcement,
};

export default flattenObject(i18n)
