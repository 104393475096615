import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';

import { moibleMedia } from 'constants';
import AppointmentItemLabel from './AppointmentItemLabel';
import AppointmentItemBlock from './AppointmentItemBlock';

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    height: '60px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5px',
    [moibleMedia]: {
      padding: 0,
      height: 'auto',
      width: '134px'
    }
  },
});

function AppointmentItem({ appointment, left, top, customerMapping, ui, selectAppointment, scheduleMode }) {
  const classes = useStyles();
  const rowRef = useRef()
  const userMapping = useSelector(state => state.users.data);
  const customer = customerMapping[appointment.customer] || {}
  const config = useSelector(state => state.config.data)
  const roomMapping = Object.keys(config.rooms || {}).reduce((acc, cur) => {acc[config.rooms[cur].id] = config.rooms[cur]; return acc}, {})
  const customerTypeMapping = Object.keys(config.customerType || {}).reduce((acc, cur) => {acc[config.customerType[cur].id] = config.customerType[cur]; return acc}, {})
  const salesRep = userMapping[appointment.salesRep] || {}
  const nurse = userMapping[appointment.nurse] || {}
  const doctor = userMapping[appointment.doctor] || {}
  const comment = String(appointment.comment || '').split('\n')

  return (
    <div
      ref={rowRef}
      className={classes.container}
      style={{
        left: `${left}px`,
        top: `${top}px`,
      }}
      onClick={(e) => selectAppointment(e, appointment)}
    >
      <AppointmentItemLabel
        scheduleMode={scheduleMode}
        comment={comment}
        status={appointment.status}
        room={roomMapping[appointment.room]}
        customerTypeMapping={customerTypeMapping}
        customer={customer}
        ui={ui}
        salesRep={salesRep}
        doctor={doctor}
        nurse={nurse}
      />
      <AppointmentItemBlock appointment={appointment} />
    </div>
  );
}

AppointmentItem.propTypes = {
  appointment: PropTypes.object.isRequired,
  left: PropTypes.number,
  top: PropTypes.number,
  customerMapping: PropTypes.object.isRequired,
  ui: PropTypes.object,
  selectAppointment: PropTypes.func,
  scheduleMode: PropTypes.string,
};

export default AppointmentItem;
