const i18n = {
  'userRights': {
    'title': '權限列表',
    'edit': '編輯權限',
    'root': '權限名稱',
    'setting-edit': '修改系統設定',
    'editUserRight': '編輯使用者權限',
    'customer-add': '新增客戶',
    'user-edit': '編輯職員',
    'user-view': '查看職員管理',
    'manage-view': '查看銷售管理',
    'customer-create': '新建客戶',
    'customer-edit': '編輯客戶',
    'customer-view': '查看客戶',
    'product-create': '新建產品',
    'product-edit': '編輯產品',
    'product-view': '查看產品',
    'vendor-create': '新建廠商',
    'vendor-edit': '編輯廠商',
    'vendor-view': '查看廠商',
    'workflow-edit': '編輯流程及權限',
    'workflow-view': '查看流程及權限',
    'merchandise-create': '新建商品',
    'merchandise-edit': '編輯商品',
    'merchandise-view': '查看商品',
  }
}

export default i18n