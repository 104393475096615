import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import FabAdd from 'components/FabAdd';
import TabContainer from 'containers/TabContainer/TabContainer';
import LeaveList from 'pages/Leaves/LeavesList';
import OvertimeList from 'pages/Overtimes/OvertimeList';
import PunchClockReviseList from 'pages/PunchClockRevise/PunchClockReviseList';
import ContextStore from 'modules/context';
import { useModuleMapping } from 'hooks/modules';

const Root = styled('div')(() => ({
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

function SecondaryNav() {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const { currentUser } = useContext(ContextStore)
  const [activeTabIndex, setActiveTabIndex] = useState(undefined)
  const moduleMapping = useModuleMapping()
  const pathname = location.pathname

  const tabs = []
  if (moduleMapping.leaveOvertime) {
    tabs.push({ name: 'leaves', label: formatMessage({ id: 'tab.secondaryNav.leave' }), component: <LeaveList /> })
    tabs.push({ name: 'overtimes', label: formatMessage({ id: 'tab.secondaryNav.overtime' }), component: <OvertimeList /> })
  }
  if (moduleMapping.punchClock) {
    tabs.push({ name: 'punchClockRevise', label: formatMessage({ id: 'tab.secondaryNav.punchClockRevise' }), component: <PunchClockReviseList /> })
  }

  useEffect(() => {
    let tabName = ''
    if (pathname === '/leaveOvertimeWork/leaves') {
      tabName = 'leaves'
    } else if (pathname === '/leaveOvertimeWork/overtimes') {
      tabName = 'overtimes'
    } else if (pathname === '/leaveOvertimeWork/punchClockRevise') {
      tabName = 'punchClockRevise'
    }
    let index = tabs.findIndex(i => i.name === tabName)
    if (index === -1) {
      index = 0
      navigate(`/leaveOvertimeWork/${tabs[0].name}`);
    }
    if (activeTabIndex !== index) {
      setActiveTabIndex(index)
    }
    return () => {
    };
  }, [pathname]);

  const onTabSelected = (tabIndex, tabName) => {
    if (tabName === 'leaves') {
      navigate('/leaveOvertimeWork/leaves');
    } else if (tabName === 'overtimes') {
      navigate('/leaveOvertimeWork/overtimes');
    } else if (tabName === 'punchClockRevise') {
      navigate('/leaveOvertimeWork/punchClockRevise');
    }
  };

  return (
    <Root>
      <FabAdd
        onClick={() => {
          if (activeTabIndex === 0) {
            navigate(`/leaveOvertimeWork/leaves/edit/${currentUser.key}/new`)
          } else if (activeTabIndex === 1) {
            navigate(`/leaveOvertimeWork/overtimes/edit/${currentUser.key}/new`)
          } else if (activeTabIndex === 2) {
            navigate(`/leaveOvertimeWork/punchClockRevise/edit/${currentUser.key}/new`)
          }
        }}
      />
      <TabContainer activeTabIndex={activeTabIndex} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
    </Root>
  );
}

export default SecondaryNav;
