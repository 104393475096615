import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import WarehouseFlowSteps from 'components/WarehouseFlowSteps';
import { unwrap } from 'modules/uitls';
import { firebaseV8 } from 'modules/firebaseV8';
import EditWorkflowDialog from './EditWorkflowDialog';
import EditActionStaffDialog from './EditActionStaffDialog';
import { useModules } from 'hooks/modules';
import { rnsData } from 'constants/index';

const DividerText = styled('div')(({ readOnly }) => ({
  position: 'absolute',
  top: readOnly ? '14px' : '8px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

function WorkflowView({ vendor, editable }) {
  const { formatMessage } = useIntl()
  const userMapping = useSelector(state => state.users.data)

  const [workflow, setWorkflow] = useState(null)
  const [editDialog, setEditDialog] = useState('')
  const modules = useModules()

  const wflist = []
  const stepers = [
    { name: 'requisitionSteps', finalStepText: '編輯已完成的請購單', finalStepField: 'overwrite', module: 'purchase' },
    { name: 'purchaseOrderSteps', finalStepText: '手動結單', finalStepField: 'overwrite', module: 'purchase' },
    { name: 'receiptSteps', finalStepText: '編輯已完成的進貨單', finalStepField: 'overwrite', module: 'warehouse' },
    { name: 'deliveryOrderSteps', finalStepText: '確認銷貨退回單', finalStepField: 'salesReturn', module: 'warehouse' },
    { name: 'inventoryCheckSteps', finalStepText: '解鎖盤點單', finalStepField: 'unlock', module: 'warehouse' },
    { name: 'stockRequisitionSteps', module: 'warehouse' },
    { name: 'scrapFormSteps', module: 'warehouse' },
    { name: 'announcementSteps', finalStepText: '下架公告', finalStepField: 'overwrite', module: 'announcement' },
    // { name: 'requisitionSteps', finalStepText: '編輯已完成的請購單', finalStepField: 'overwrite', module: 'purchase' },
    // { name: 'newSupplierSteps', finalStepText: '通知建立資料', finalStepField: 'overwrite' },
    // { name: 'newMerchandiseSteps', finalStepText: '通知建立資料', finalStepField: 'overwrite' },
    // { name: 'newProductSteps', finalStepText: '通知建立資料', finalStepField: 'overwrite' },
  ]
  if (workflow) {
    for (const steper of stepers) {
      if (workflow[steper.name].length > 0) {
        const steps = workflow[steper.name]
        const approveSteps = steps.map(s => ({
          name: s.name,
          users: s.users.map(u => ({ id: u, name: userMapping[u]?.displayName || '' })),
          active: true
        }))
        if (steper.finalStepField) {
          approveSteps.push({
            name: steper.finalStepText,
            users: steps[0][steper.finalStepField].map(u => ({ id: u, name: userMapping[u]?.displayName || '' })),
          })
        }
        if (modules.includes(steper.module)) {
          wflist.push({ name: steper.name, steps: approveSteps, type: 'steper' })
        }
      }
    }

    // wflist.push({ name: 'editBorrowingStaff', steps: [
    //   { field: 'borrowingOrder', value: vendor.borrowing.borrowingOrder.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
    //   { field: 'borrowingDelivery', value: vendor.borrowing.borrowingDelivery.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
    //   { field: 'borrowingReceipt', value: vendor.borrowing.borrowingReceipt.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
    //   { field: 'returnBorrowingForm', value: vendor.borrowing.returnBorrowingForm.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
    //   { field: 'returnBorrowingReceipt', value: vendor.borrowing.returnBorrowingReceipt.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
    // ], type: 'action' })
    if (modules.includes('warehouse')) {
      wflist.push({
        name: 'editWarehouseStaff', key: 'stock', steps: [
          { field: 'query', value: workflow.stock.query.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
          { field: 'inventory', value: workflow.stock.inventory.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
          { field: 'returnForm', value: workflow.stock.returnForm.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
        ], type: 'action'
      })
    }
    if (modules.includes('appointment')) {
      wflist.push({
        name: 'editAppointmentUserRight', key: 'appointment', steps: [
          { field: 'view', value: workflow.appointment.view.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
          { field: 'edit', value: workflow.appointment.edit.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
        ], type: 'action'
      })
    }

    if (modules.includes('finance')) {
      wflist.push({
        name: 'editFinanceUserRight', key: 'finance', steps: [
          { field: 'view', value: workflow.finance.view.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
          { field: 'edit', value: workflow.finance.edit.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
          { field: 'salary', value: workflow.finance.salary.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
        ], type: 'action'
      })
    }
    if (modules.includes('leaveOvertime') && modules.includes('punchClock')) {
      wflist.push({
        name: 'editLeaveOvertimeUserRight', key: 'leaveOvertime', steps: [
          { field: 'overview', value: workflow.leaveOvertime.overview.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
        ], type: 'action'
      })
    }
    if (modules.includes('punchClock')) {
      wflist.push({
        name: 'editPunchClockReviseUserRight', key: 'punchClockRevise', steps: [
          { field: 'overview', value: workflow.punchClockRevise.overview.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
        ], type: 'action'
      })
    }
    if (modules.includes('schedule')) {
      if (modules.includes('doctor')) {
        wflist.push({
          name: 'editScheduleUserRight', key: 'schedule', steps: [
            { field: 'view', value: workflow.schedule.view.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
            { field: 'edit', value: workflow.schedule.edit.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
            { field: 'exception', value: workflow.schedule.exception.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
            { field: 'doctorEdit', value: workflow.schedule.doctorEdit.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
            { field: 'doctorPunchClock', value: workflow.schedule.doctorPunchClock.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
          ], type: 'action'
        })
      } else {
        wflist.push({
          name: 'editScheduleUserRight', key: 'schedule', steps: [
            { field: 'view', value: workflow.schedule.view.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
            { field: 'edit', value: workflow.schedule.edit.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
            { field: 'exception', value: workflow.schedule.exception.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
          ], type: 'action'
        })
      }
    }

    if (modules.includes('report')) {
      wflist.push({
        name: 'editReportUserRight', key: 'report', steps: [
          { field: 'view', value: workflow.report.view.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
          { field: 'edit', value: workflow.report.edit.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
        ], type: 'action'
      })
    }
    if (modules.includes('sales')) {
      wflist.push({
        name: 'editSalesOrderUserRight', key: 'salesOrder', steps: [
          { field: 'view', value: workflow.salesOrder.view.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
          { field: 'create', value: workflow.salesOrder.create.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
          { field: 'edit', value: workflow.salesOrder.edit.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
          { field: 'void', value: workflow.salesOrder.void.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
        ], type: 'action'
      })
      wflist.push({
        name: 'editRefundUserRight', key: 'refund', steps: [
          { field: 'view', value: workflow.refund.view.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
          { field: 'create', value: workflow.refund.create.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
          { field: 'edit', value: workflow.refund.edit.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
        ], type: 'action'
      })
    }
    if (modules.includes('referrer')) {
      wflist.push({
        name: 'editReferrerUserRight', key: 'referrer', steps: [
          { field: 'view', value: (workflow.referrer?.view ?? []).filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') ?? '', },
          { field: 'create', value: (workflow.referrer?.create ?? []).filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') ?? '', },
          { field: 'edit', value: (workflow.referrer?.edit ?? []).filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') ?? '', },
          { field: 'void', value: (workflow.referrer?.void ?? []).filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') ?? '', },
        ], type: 'action'
      })
    }
    if (modules.includes('service')) {
      wflist.push({
        name: 'editConsumeServiceUserRight', key: 'consumeService', steps: [
          { field: 'view', value: workflow.consumeService.view.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
          { field: 'create', value: workflow.consumeService.create.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
          { field: 'edit', value: workflow.consumeService.edit.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
          { field: 'void', value: workflow.consumeService.void.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
        ], type: 'action'
      })
      wflist.push({
        name: 'editTransferServiceUserRight', key: 'transferService', steps: [
          { field: 'view', value: workflow.transferService.view.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
          { field: 'create', value: workflow.transferService.create.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
        ], type: 'action'
      })
      wflist.push({
        name: 'editTransformServiceUserRight', key: 'transformService', steps: [
          { field: 'view', value: workflow.transformService.view.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
          { field: 'create', value: workflow.transformService.create.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', '), },
        ], type: 'action'
      })
    }
  }

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('warehouseFlow').doc(vendor.id).onSnapshot(snapshot => {
      setWorkflow(unwrap(snapshot.data()))
    }, err => { })
    return () => unsubscribe()
  }, []);

  function handleEditClick(e, wf) {
    setEditDialog(wf)
  }

  return <>
    {editDialog &&
      (rnsData.concat(['stock']).includes(editDialog) ?
        <EditActionStaffDialog
          vendor={vendor}
          workflowName={editDialog}
          workflow={workflow[editDialog] || {}}
          handleClose={() => setEditDialog('')}
        /> :
        <EditWorkflowDialog
          vendor={vendor}
          workflowName={editDialog}
          workflow={workflow[editDialog]}
          handleClose={() => setEditDialog('')}
        />
      )}
    {wflist.map(wf => (<React.Fragment key={wf.name}>
      <Grid item xs={12} sm={12} md={12} style={{ position: 'relative' }} >
        <Divider sx={{ mt: '8px', mb: '8px' }} />
        <DividerText readOnly={!editable}>
          <Typography color="textSecondary" variant="caption">
            {formatMessage({ id: `vendor.${wf.name}.title` })}
          </Typography>
          {editable && <Tooltip title={formatMessage({ id: `vendor.${wf.name}.edit` })}>
            <IconButton onClick={e => handleEditClick(e, wf.key || wf.name)}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>}
        </DividerText>
      </Grid>
      {workflow && wf.type === 'steper' ? <Grid item xs={12} sm={12} md={12}><WarehouseFlowSteps steps={wf.steps} finalStep={wf} small /></Grid> : (
        wf.steps.map(s => <React.Fragment key={`${wf.name}-${s.field}`}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              type="text"
              size="small"
              label={formatMessage({ id: `editVendorUserRights.${wf.key}.table.${s.field}` })}
              value={s.value}
              fullWidth
              variant="standard"
              readOnly
            />
          </Grid>
        </React.Fragment>)
      )}
    </React.Fragment>))}
  </>
}

WorkflowView.displayName = 'WorkflowView'

WorkflowView.propTypes = {
  vendor: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default WorkflowView;
