import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import RootContextStore from 'modules/rootContext';
import { verifyPasswordResetCode, confirmPasswordReset } from 'store';

const LoginPaper = styled('div')(({ theme }) => ({
  margin: theme.spacing(8, 4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const FormMui = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}));

function ResetPassword() {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('oobCode')
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [page, setPage] = useState('loading');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState({});
  const { addMessage } = useContext(RootContextStore);

  const passwordError = error.password ?? '';
  const confirmPasswordError = error.confirmPassword ?? '';

  useEffect(() => {
    if (token) {
      verifyPasswordResetCode(token).then(function(email) {
        setPage('resetPassword');
        // Display a "new password" form with the user's email address
      }).catch((error) => {
        if (error.code === 'auth/invalid-action-code') {
          setPage('invalidToken');
        }
        console.log(error)
        // Invalid code
      })
    } else {
      setPage('invalidToken');
    }
  }, [token]);

  async function onSubmit() {
    if (page === 'resetPassword') {
      confirmPasswordReset(token, password).then(() => {
        // Success
        addMessage(formatMessage({ id: 'resetPassword.form.success' }));
        navigate('/', { replace: true });
      }).catch(error => {
        console.log(error)
        setPage('invalidToken');
        // setPage('tokenExpired');
      })
    }
  }

  const buttonStatus = () => {
    if (page === 'resetPassword') {
      return (password !== '' &&
        !passwordError &&
        confirmPassword !== '' &&
        !confirmPasswordError);
    }
    return false;
  };

  return (
    <Grid container component="main" justifyContent="center" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={0} square>
        <LoginPaper>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {['resetPassword', 'loading'].includes(page) ?
              <FormattedMessage id={`resetPassword.form.${page}`} /> :
              <FormattedMessage id={`resetPassword.error.${page}`} />
            }
          </Typography>
          <FormMui noValidate>
            {page === 'resetPassword' && <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={formatMessage({ id: 'resetPassword.form.password' })}
              type="password"
              id="password"
              onChange={e => {
                if (e.target.value === '') {
                  setError(e => ({ ...e, password: formatMessage({ id: 'resetPassword.error.emptyPassword' }) }));
                } else if (passwordError !== '') {
                  setError(e => ({ ...e, password: '' }));
                }
                setPassword(e.target.value);
              }}
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  onSubmit();
                }
              }}
              error={!!passwordError}
              helperText={passwordError}
            />}
            {page === 'resetPassword' && <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label={formatMessage({ id: 'resetPassword.form.confirmPassword' })}
              type="password"
              id="confirmPassword"
              onChange={e => {
                if (e.target.value !== password) {
                  setError(e => ({ ...e, confirmPassword: formatMessage({ id: 'resetPassword.error.confirmPassword' }) }));
                } else {
                  setError(e => ({ ...e, confirmPassword: '' }));
                }
                setConfirmPassword(e.target.value);
              }}
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  onSubmit();
                }
              }}
              error={!!confirmPasswordError}
              helperText={confirmPasswordError}
            />}
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              onClick={onSubmit}
              disabled={!buttonStatus()}
            >
              <FormattedMessage id={`resetPassword.form.${page}`} />
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/" variant="body2">
                  <FormattedMessage id="resetPassword.form.loginPage" />
                </Link>
              </Grid>
              <Grid item>
              </Grid>
            </Grid>
            <Box mt={5}>
              {/* <Copyright /> */}
            </Box>
          </FormMui>
        </LoginPaper>
      </Grid>
    </Grid>
  );
}

export default ResetPassword;
