import React, { useState, useEffect, useContext } from 'react';
// import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';

import EnhancedTable from 'components/EnhancedTable';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import SelectMerchandiseDialog from 'components/SelectMerchandiseDialog';
import ContextStore from 'modules/context';
import { addError, removeError } from 'modules/editor';
import { callFunction } from 'modules/firebase';
import { firestoreListener } from 'modules/firebase';

function EditInventoryChangePage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const navigate = useNavigate()
  const location = useLocation()

  const customerMapping = useSelector(state => state.internalVendors.data)
  const customers = useSelector(state => state.internalVendors.ordered)
  const filteredSources = customers.filter(c => userRights.hasUserRightForVendor('inventoryChange-create', c.id))
  const merchandiseMapping = useSelector(state => state.merchandises.data)
  const merchandises = useSelector(state => state.merchandises.ordered)
  const userMapping = useSelector(state => state.users.data)

  // const supplierMapping = useSelector(state => state.suppliers.data)
  // const suppliers = useSelector(state => state.suppliers.ordered)

  const [selectedItems, setSelectedItems] = useState({});
  const [loading, setLoading] = useState(false);
  const [lockSource, setLockSource] = useState(false);

  const [openDialog, setOpenDialog] = useState({ name: '', data: '' });
  const [inventories, setInventories] = useState({});

  const [form, setForm] = useState({
    id: 'new',
    source: '',
    date: dayjs().format('YYYY-MM-DD'),
    createdBy: currentUser.key,
    note: '',
    merchandises: [],
  });

  useEffect(() => {
    if (filteredSources.length === 1) {
      updateFormData({ name: 'source' }, filteredSources[0].id);
    }
  }, [filteredSources.length]);

  useEffect(() => {
    const unsubscribe = form.source ? firestoreListener({
      collection: 'inventories',
      doc: form.source,
      unwrap: true,
      addDocId: false,
      onData: (data) => {
        setInventories(data || {})
      }
    }) : null
    return () => unsubscribe?.()
  }, [form.source]);

  useEffect(() => {
    setBreadcrumbs([{
      link: '/stock/inventoryChange/pending',
      text: formatMessage({ id: 'sideMenu.stock.inventoryChange' })
    },{
      text: formatMessage({ id: 'inventoryChange.dialog.title.add' })
    }])
    return () => {
    };
  }, [location.pathname]);

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    { text: 'stock' },
    { text: 'changeType' },
    { text: 'amount' },
    { text: 'unit' },
    { text: 'note', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `inventoryChange.merchandise.${c.text}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    { field: 'stock' },
    { field: 'changeType',
      align: 'right',
      type: 'input-select',
      required: true,
      label: '增減',
      onValueChanged: onCellValueChanged,
      getMenuItems: getTypeList,
      minWidth: '80px',
    },
    { field: 'amount', type: 'input-number', required: true, label: formatMessage({ id: 'inventoryChange.merchandise.amount' }), onValueChanged: onCellValueChanged, getEnableStatus },
    { field: 'sku' },
    { field: 'note', align: 'right', type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged },
  ]

  function getTypeList() {
    return [{ label: '增加', value: 'plus' }, { label: '減少', value: 'minus' }]
  }

  function getEnableStatus(merchandise, field) {
    if (merchandise.items) {
      return false
    }
    return true
  }

  function formatData(merchandise) {
    const m = merchandiseMapping[merchandise.id]
    const stock = inventories[merchandise.id] ?? 0

    const newData = {
      ...merchandise,
      code: m.code,
      name: m.name,
      nickname: m.nickname,
      orderUnit: m.orderUnit,
      sku: m.sku,
      ou2sku: m.ou2sku,
      stock: `${stock}${m.sku}`,
    }
    return newData
  }

  function onCellValueChanged(data, field, value) {
    for (let m of form.merchandises) {
      if (m.id === data.id) {
        m[field] = value
        if (field === 'amount') {
          if (isNaN(value) || value === '') {
            addError(m, field, '數量錯誤')
          } else if (parseInt(value) === 0) {
            addError(m, field, '無異動')
          } else {
            removeError(m, field)
          }
        } else if (field === 'expectedDate') {
          if (value === null) {
            removeError(m, field)
          } else if (value.toString() === 'Invalid Date') {
            addError(m, field, formatMessage({ id: 'form.date.formatError' }))
          } else {
            removeError(m, field)
          }
        } else if (field === 'changeType') {
          removeError(m, field)
        }
        break
      }
    }
    updateFormData({ name: 'merchandises' }, form.merchandises);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = form.merchandises.reduce((acc, cur) => {acc[cur.id] = true;return acc}, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  }

  function handleCheckboxClick({ id }) {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function onDeleteItems() {
    const merchandises = form.merchandises.filter(m => !selectedItems[m.id])
    if (merchandises.length !== form.merchandises.length) {
      updateFormData({ name: 'merchandises' }, merchandises);
      setSelectedItems({});
    }
  }

  const onSourceChanged = (e) => {
    updateFormData({ name: 'source' }, e.target.value);
  }

  function onMerchandiseChanged(merchandises) {
    if (merchandises.length) {
      for (let m of merchandises) {
        m.amount = 0
        m.note = ''
        m.unit = 'ou'
        m.changeType = ''
      }
      updateFormData({ name: 'merchandises' }, form.merchandises.concat(merchandises));
    }
  }

  function validateField(field, value) {
    // if (field.required && value === '') {
    //   return formatMessage({id: 'form.field.isRequired'})
    // }
    if (field.name === 'merchandises') {
      for (const m of value) {
        if (m.errors && m.errors.amount) {
          return m.errors.amount
        }
      }
    } else if (field.name === 'expectedDate' && value === '') {
      return formatMessage({ id: 'form.date.formatError' })
    }
    return ''
  }

  function updateFormData(field, value) {
    let newValue = value
    if (field.uppercase) {
      newValue = newValue.toUpperCase()
    }

    let err = validateField(field, value)

    let newData = { ...form, [field.name]: newValue, [`${field.name}_err`]: err }
    setForm(newData)
  }

  async function handleSave() {
    setLoading(true);
    let err = false;

    const fields = [
      { name: 'source' },
      { name: 'date' },
      { name: 'merchandises' },
      { name: 'note' },
      { name: 'createdBy' }
    ]

    let newData = form
    // TODO: 檢查看看有沒有紅字, 有的話就跳出去, 不用儲存
    for (const m of form.merchandises) {
      if (m.errors && Object.keys(m.errors).length > 0) {
        setLoading(false)
        return
      }
      if (m.items) {
        for (const i of m.items) {
          if (i.errors && Object.keys(i.errors).length > 0) {
            setLoading(false)
            return
          }
        }
      }
    }

    for (const merchandise of form.merchandises) {
      const amount = parseInt(merchandise.amount)
      if (amount === 0) {
        err = true
        addError(merchandise, 'amount', '無異動')
      }
      if (merchandise.changeType === '') {
        err = true
        addError(merchandise, 'changeType', formatMessage({ id: 'form.field.isRequired' }))
      }
    }

    if (err) {
      setForm({ ...form })
      setLoading(false);
      return
    }

    let data = {}
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.merchandises = data.merchandises.reduce((acc, cur) => {
      acc[cur.id] = {
        unit: merchandiseMapping[cur.id].sku,
        unitToSku: 1,
        changeType: cur.changeType,
        amount: parseInt(cur.amount),
        note: cur.note,
      }
      return acc
    }, {})

    if (form.id === 'new') {
      try {
        await callFunction('saveInventoryChange', data)
      } catch (ex) {
        console.log(ex)
      }
    }
    handleClose()
  }

  function handleClose() {
    navigate('/stock/inventoryChange/pending');
  }

  function getSourceSelector() {
    if (userRights.onlyVendor('inventoryChange-create')) {
      return (<TextField
        disabled
        type="text"
        label={formatMessage({ id: 'inventoryChange.table.detail.source' })}
        variant="outlined"
        value={customerMapping[userRights.onlyVendor('inventoryChange-create')].nickname}
        fullWidth
        size="small"
      />)
    } else {
      return (<TextField
        select
        required
        disabled={lockSource}
        type="text"
        label={formatMessage({ id: 'inventoryChange.table.detail.source' })}
        variant="outlined"
        value={form.source}
        onChange={onSourceChanged}
        fullWidth
        size="small"
        error={form.source_err ? true : false}
        helperText={form.source_err}
      >
        {filteredSources.map(c => <MenuItem key={c.id} value={c.id}>
          {c.nickname}
        </MenuItem>)}
      </TextField>)
    }
  }

  const selectedMerchandises = (form.merchandises || []).reduce((acc, cur) => {
    acc[cur.id] = true
    return acc
  }, {});

  return (
    <div style={{ flexGrow: 1 }}>
      {openDialog.name === 'merchandise' && <SelectMerchandiseDialog
        multiSelect
        maxWidth="lg"
        headerCells={[{ name: 'code', sort: 'code' },{ name: 'nickname', sort: 'nickname' },{ name: 'note' }]}
        rowCells={[{ field: 'code' },{ field: 'nickname', tooltip: 'name' },{ field: 'note' }]}
        filterItems={[{ name: 'nickname' },{ name: 'name' },{ name: 'code' },{ name: 'note' }]}
        dialogTitle={formatMessage({ id: 'selectMerchandiseDialog.title' })}
        tableTitle={formatMessage({ id: 'selectMerchandiseDialog.table.title' })}
        handleClose={() => setOpenDialog({ name: '', data: '' })}
        handleSave={onMerchandiseChanged}
        defaultSelectedItems={[]}
        items={merchandises.filter(m => !selectedMerchandises[m.id] && m.warehousing[form.source] )}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            {getSourceSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'inventoryChange.table.detail.createdBy' })}
              variant="outlined"
              value={userMapping[form.createdBy] ? userMapping[form.createdBy].displayName : ''}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'inventoryChange.table.detail.date' })}
              variant="outlined"
              value={dayjs(form.date).format('YYYY-MM-DD')}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: '8px 0px' }} />
        <EnhancedTableToolbar
          title={'editInventoryChange.table.title'}
          selectdMessage={'editInventoryChange.table.selected'}
          numSelected={Object.keys(selectedItems).length}
          toolbox={
            <Button
              disabled={form.source === ''}
              sx={{ m: 1, whiteSpace: 'nowrap' }}
              variant="contained"
              color="primary"
              onClick={() => {
                setLockSource(true)
                setOpenDialog({ name: 'merchandise', data: '' })
              }}
            >
              <FormattedMessage id={'editInventoryChange.selectMerchandise'} />
            </Button>
          }
          toolboxForSelection={
            <>
              <Button
                variant="contained"
                color="primary"
                startIcon={<DeleteIcon />}
                style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
                onClick={onDeleteItems}
              >
                <FormattedMessage id={'editInventoryChange.removeMerchandise'} />
              </Button>
            </>
          }
        />
        <EnhancedTable
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={headerCells}
          rowCells={rowCells}
          onHeaderCheckboxClick={handleSelectAllClick}
          onRowCheckboxClick={handleCheckboxClick}
          getSelectionCount={() => Object.keys(selectedItems).length}
          getRowCheckBoxStatus={merchandise => selectedItems[merchandise.id] || false}
          getRowExpandedStatus={merchandise => merchandise.items ? true : false}
          tableData={form.merchandises.map(m => formatData(m))}
        />
        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'inventoryChange.table.detail.note' })}
              variant="outlined"
              value={form.note}
              onChange={e => updateFormData({ name: 'note' }, e.target.value)}
              fullWidth
              size="small"
              multiline
            />
          </Grid>
        </Grid>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={loading || form.merchandises.length === 0}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.submit" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}

export default EditInventoryChangePage;
