import React, { useEffect, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import TabContainer from 'containers/TabContainer/TabContainer';
import ContextStore from 'modules/context';
import { useModuleMapping } from 'hooks/modules';
import SalaryPage from './SalaryPage';
import BonusPage from './BonusPage';
import BalanceHistotyPage from './BalanceHistotyPage';

function Finance() {
  const { setBreadcrumbs } = useContext(ContextStore)
  const { formatMessage } = useIntl()
  const location = useLocation()
  const moduleMapping = useModuleMapping()
  const userRights = useSelector(state => state.userRights)
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.finance' })
    }])
    return () => {
    };
  }, [location.pathname]);

  const onTabSelected = (tabIndex) => {
    setActiveTabIndex(tabIndex)
  };

  let defaultSelect = 0
  const _tabs = [{ label: formatMessage({ id: 'finance.tab.salary' }), component: <SalaryPage />, order: 1 }]

  // if (userRights.hasUserRight('finance-salary')) { // 修正權限檢查
  //   _tabs.push()
  // }

  if (userRights.hasUserRight('finance-salary')) { // 修正權限檢查
    if (moduleMapping.bonus) {
      _tabs.push({ label: formatMessage({ id: 'finance.tab.bonus' }), component: <BonusPage />, order: 2 })
    }
  }

  if (userRights.hasUserRight('finance-edit')) { // 修正權限檢查
    _tabs.push({ label: formatMessage({ id: 'finance.tab.balance' }), component: <BalanceHistotyPage />, order: 3 })
  }

  const tabs = _tabs.sort((a, b) => a.order - b.order)

  return (
    <div style={{ width: '100%', fontFamily: 'Roboto, sans-serif' }}>
      <div style={{ padding: '40px 20px' }}>
        <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
      </div>
    </div>
  );
}

export default Finance;
