import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';

const VisuallyHiddenSpan = styled('span')(() => ({
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1,
}));

export default function EnhancedTableHead(props) {
  const { headerCells, expandable, onCheckboxClick, order, orderBy, numSelected, rowCount, onRequestSort, headerActionButton, actionButton, radioButton } = props;

  const createSortHandler = (property) => (event) => {
    if (onRequestSort) {
      onRequestSort(event, property);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {radioButton && <TableCell padding="checkbox" />}
        {onCheckboxClick && <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onCheckboxClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>}
        {expandable && <TableCell sx={{ width: '40px' }} >
          {headerActionButton ?? null}
        </TableCell>}
        {headerCells.map((cell) => (
          <TableCell
            sx={cell.style}
            key={cell.text}
            align={cell.align || 'left'}
            padding={'normal'}
            sortDirection={orderBy === cell.sort ? order : false}
          >
            {cell.sort ? (
              <TableSortLabel
                active={orderBy === cell.sort}
                direction={orderBy === cell.sort ? order : 'asc'}
                onClick={createSortHandler(cell.sort)}
              >
                {cell.text}
                {orderBy === cell.sort ? (
                  <VisuallyHiddenSpan>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </VisuallyHiddenSpan>
                ) : null}
              </TableSortLabel>
            ) : (
              cell.text
            )}
          </TableCell>
        ))}
        {actionButton && (actionButton === true ? <TableCell /> : <TableCell>{actionButton}</TableCell>)}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  expandable: PropTypes.bool,
  headerCells: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    sort: PropTypes.string,
    align: PropTypes.string,
  })).isRequired,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onCheckboxClick: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number.isRequired,
  actionButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]) ,
  radioButton: PropTypes.bool
};
