import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';

import Home from './pages/Home/Home'
import Leaves from './pages/Leaves/Leaves';
import Overtimes from './pages/Overtimes/Overtimes';
import PunchClockRevise from './pages/PunchClockRevise/PunchClockRevise';
import EditPunchClockRevisePage from './pages/PunchClockRevise/EditPunchClockRevisePage';
import EditLeavePage from './pages/Leaves/EditLeavePage';
import EditOvertimePage from './pages/Overtimes/EditOvertimePage';
import PunchClockException from './pages/PunchClockExceptions/PunchClockException';
import Staff from './pages/Staffs/Staff';
import Schedule from './pages/Schedule/Schedule';
import EditStaffPage from './pages/Staffs/EditStaffPage';
import EditStaffSalaryPage from './pages/Staffs/EditStaffSalaryPage';
import Finance from './pages/Finance/Finance';
import EditUserRights from './pages/Setting/EditUserRights';
import UserRights from './pages/Setting/UserRights';
import SalesOrderList from './pages/Sales/SalesOrderList';
import SalesOrderQuery from './pages/Sales/SalesOrderQuery';
import EditSalesOrderPage from './pages/Sales/EditSalesOrderPage';
import RefundList from './pages/Sales/RefundList';
import EditRefundPage from './pages/Sales/EditRefundPage';
import ReferrerList from './pages/Sales/ReferrerList';
import EditReferrerPage from './pages/Sales/EditReferrerPage';
import ConsumedServiceList from './pages/Services/ConsumedServiceList';
import EditConsumedServicePage from './pages/Services/EditConsumedServicePage';
import TransferServiceList from './pages/Services/TransferServiceList';
import EditTransferServicePage from './pages/Services/EditTransferServicePage';
import TransformServiceList from './pages/Services/TransformServiceList';
import EditTransformServicePage from 'pages/Services/EditTransformServicePage';
import ProductList from './pages/Product/ProductList';
import Customer from './pages/Customers/Customer';
import CustomerTab from './pages/Customers/CustomerTab';
import Appointments from './pages/Appointments/Appointments';
import AppointmentList from './pages/Appointments/AppointmentList';
import AppointmentEditHistory from './pages/Appointments/AppointmentEditHistory';
import SettingCustomer from './pages/Setting/SettingCustomer';
import SettingCustomerPage from './pages/Setting/SettingCustomerPage';
import EditSetCustomer from './pages/Setting/EditSetCustomer';
import SettingSalesOrder from './pages/Setting/SettingSalesOrder';
import SettingSalesOrderPage from './pages/Setting/SettingSalesOrderPage';
import EditDataType from './pages/Setting/EditDataType';
import SettingAppointment from './pages/Setting/SettingAppointment';
import SettingAppointmentPage from './pages/Setting/SettingAppointmentPage';
import SettingStaff from './pages/Setting/SettingStaff';
import SettingStaffPage from './pages/Setting/SettingStaffPage';
import SettingAttendance from './pages/Setting/SettingAttendance';
import EditSalesRepsPage from './pages/Setting/EditSalesRepsPage';
import SalesRepsView from './pages/Setting/SalesRepsView';
import Reports from './pages/Reports/Reports';
import VendorList from './pages/Vendor/VendorList';
import Merchandise from './pages/Merchandise/Merchandise';
import EditMerchandisePage from './pages/Merchandise/EditMerchandisePage';
import Requisition from './pages/Purchase/Requisition';
import EditRequisitionPage from './pages/Purchase/EditRequisitionPage';
import PurchaseOrder from './pages/Purchase/PurchaseOrder';
import EditPurchaseOrderPage from './pages/Purchase/EditPurchaseOrderPage';
import Receipt from './pages/Purchase/Receipt';
import EditReceiptPage from './pages/Purchase/EditReceiptPage';
import ReturnForm from './pages/Purchase/ReturnForm';
import EditReturnFormPage from './pages/Purchase/EditReturnFormPage';
import DeliveryOrder from './pages/Delivery/DeliveryOrder';
import EditDeliveryOrderPage from './pages/Delivery/EditDeliveryOrderPage';
import SalesReturnForm from './pages/Delivery/SalesReturnForm';
import StockList from './pages/Stock/StockList';
import ShoppingCartList from './pages/Purchase/ShoppingCartList';
import SettingAttendancePage from './pages/Setting/SettingAttendancePage';
import LopReview from './containers/SecondaryNav/LopReview';
import LopOverview from './containers/SecondaryNav/LopOverview';
import SettingMerchandise from './pages/Setting/SettingMerchandise';
import SettingMerchandisePage from './pages/Setting/SettingMerchandisePage';
import StockRequisition from './pages/Stock/StockRequisition';
import InventoryChange from './pages/Stock/InventoryChange';
import EditInventoryChangePage from './pages/Stock/EditInventoryChangePage';
import EditStockRequisitionPage from './pages/Stock/EditStockRequisitionPage';
import EditInventoryCheckPage from './pages/Stock/EditInventoryCheckPage';
import InventoryCheck from './pages/Stock/InventoryCheck';
import SettingReport from 'pages/Setting/SettingReport';
import SettingReportPage from 'pages/Setting/SettingReportPage';
import Announcement from './pages/Announcement/Announcement';
import AnnouncementReview from './pages/Announcement/AnnouncementReview';
import EditAnnouncement from './pages/Announcement/EditAnnouncement';
import SettingHome from 'pages/Setting/SettingHome';
import SettingHomePage from 'pages/Setting/SettingHomePage';

function RoutePage({ currentUser }) {
  const userRights = useSelector(state => state.userRights)

  return (
    <Routes>
      <Route
        path="/"
        exact
        element={<Home userRights={userRights} />}
      />
      {(userRights.debugging || userRights.hasUserRight('appointment-view')) && <Route
        path="/appointments/schedule"
        exact
        element={<Appointments currentUser={currentUser} />}
      />}
      {(userRights.debugging || userRights.hasUserRight('appointment-view')) && <Route
        path="/appointments/list"
        exact
        element={<AppointmentList currentUser={currentUser} />}
      />}
      {(userRights.debugging || userRights.hasUserRight('appointment-view')) && <Route
        path="/appointments/editList"
        exact
        element={<AppointmentEditHistory currentUser={currentUser} />}
      />}
      {(userRights.debugging || userRights.hasUserRight('customer-view')) && <Route
        path="/customers/list"
        exact
        element={<Customer />}
      />}
      {(userRights.debugging || userRights.hasUserRight('customer-view')) && <Route
        path="/customer/:customerId/:tabName"
        exact
        element={<CustomerTab />}
      />}
      {(userRights.debugging || userRights.hasUserRight('setting-edit')) && <Route
        path="/setting/staff"
        exact
        element={<SettingStaff />}
      >
        <Route path="" element={<Navigate to="departments" />} />
        <Route
          path="departments"
          exact
          element={<SettingStaffPage tableCells={['name', 'users']} tableName='departments' />}
        />
        <Route
          path="occupation"
          exact
          element={<SettingStaffPage tableCells={['name', 'users']} tableName='occupation' />}
        />
        <Route
          path="appointmentType"
          exact
          element={<SettingStaffPage tableCells={['name', 'users']} tableName='appointmentType' />}
        />
      </Route>}
      {(userRights.debugging || userRights.hasUserRight('setting-edit')) && <Route
        path="/setting/appointment"
        exact
        element={<SettingAppointment />}
      >
        <Route path="" element={<Navigate to="treatments" />} />
        <Route
          path="treatments"
          exact
          element={<SettingAppointmentPage tableCells={['name', 'color', 'duration']} tableName='treatments' />}
        />
        <Route
          path="rooms"
          exact
          element={<SettingAppointmentPage tableCells={['name', 'note']} tableName='rooms' />}
        />
        <Route
          path="salesReps"
          exact
          element={<SalesRepsView />}
        />
      </Route>}
      {(userRights.debugging || userRights.hasUserRight('setting-edit')) && <Route
        path="/setting/attendance"
        exact
        element={<SettingAttendance />}
      >
        <Route path="" element={<Navigate to="reviewPolicy" />} />
        <Route
          path="reviewPolicy"
          exact
          element={<SettingAttendancePage type="review" />}
        />
        <Route
          path="worktimePolicy"
          exact
          element={<SettingAttendancePage type="worktime" />}
        />
        <Route
          path="weeklyPolicy"
          exact
          element={<SettingAttendancePage type="weekly" />}
        />
        <Route
          path="systemPolicy"
          exact
          element={<SettingAttendancePage type="system" />}
        />
      </Route>}
      {(userRights.debugging || userRights.hasUserRight('setting-edit')) && <Route
        path="/setting/customer"
        exact
        element={<SettingCustomer />}
      >
        <Route path="" element={<Navigate to="extData" />} />
        <Route
          path="extData"
          exact
          element={<SettingCustomerPage tableCells={['name', 'note', 'type']} tableName='extData' />}
        />
        <Route
          path="customerType"
          exact
          element={<SettingCustomerPage tableCells={['name', 'requiredItems', 'items']} tableName='customerType' />}
        />
      </Route>}
      {userRights.hasUserRight('setting-edit') && <Route
        path="/setting/customer/:tabName/edit"
        exact
        element={<EditSetCustomer />}
      />}
      {(userRights.debugging || userRights.hasUserRight('setting-edit')) && <Route
        path="/setting/salesOrder"
        exact
        element={<SettingSalesOrder />}
      >
        <Route path="" element={<Navigate to="paymentType" />} />
        <Route
          path="paymentType"
          exact
          element={<SettingSalesOrderPage tableCells={['name']} tableName='paymentType' />}
        />
      </Route>}
      {(userRights.debugging || userRights.hasUserRight('setting-edit')) && <Route
        path="/setting/merchandise"
        exact
        element={<SettingMerchandise />}
      >
        <Route path="" element={<Navigate to="merchandise" />} />
        <Route
          path="category"
          exact
          element={<SettingMerchandisePage tableCells={['name']} tableName='category' />}
        />
      </Route>}
      {(userRights.debugging || userRights.hasUserRight('setting-edit')) && <Route
        path="/setting/report"
        exact
        element={<SettingReport />}
      >
        <Route path="" element={<Navigate to="report" />} />
        <Route
          path="reportCategory"
          exact
          element={<SettingReportPage tableCells={['name', 'tags']} tableName='reportCategory' />}
        />
      </Route>}
      {(userRights.debugging || userRights.hasUserRight('setting-edit')) && <Route
        path="/setting/home"
        exact
        element={<SettingHome />}
      >
        <Route path="" element={<Navigate to="homePage" />} />
        <Route
          path="homePage"
          exact
          element={<SettingHomePage tableCells={['name']} tableName='homePage' />}
        />
      </Route>}
      {userRights.hasUserRight('setting-edit') && <Route
        path="/setting/appointment/salesReps/edit"
        exact
        element={<EditSalesRepsPage />}
      />}
      {userRights.hasUserRight('setting-edit') && <Route
        path="/setting/:sideName/:tableName/edit"
        exact
        element={<EditDataType />}
      />}
      {(userRights.debugging || userRights.hasUserRight('consumeService-view')) && <Route
        path="/services/consume"
        exact
        element={<ConsumedServiceList />}
      />}
      {(userRights.hasUserRight('consumeService-create') || userRights.hasUserRight('consumeService-edit')) && <Route
        path="/services/consume/edit/:formId"
        exact
        element={<EditConsumedServicePage />}
      />}
      {(userRights.debugging || userRights.hasUserRight('transferService-view')) && <Route
        path="/services/transfer"
        exact
        element={<TransferServiceList />}
      />}
      {userRights.hasUserRight('transferService-create') && <Route
        path="/services/transfer/edit/:formId"
        exact
        element={<EditTransferServicePage />}
      />}
      {(userRights.debugging || userRights.hasUserRight('transformService-view')) && <Route
        path="/services/transform"
        exact
        element={<TransformServiceList />}
      />}
      {userRights.hasUserRight('transformService-create') && <Route
        path="/services/transform/edit/:formId"
        exact
        element={<EditTransformServicePage />}
      />}
      {(userRights.debugging || userRights.hasUserRight('refund-view')) && <Route
        path="/sales/refund"
        exact
        element={<RefundList />}
      />}
      {(userRights.hasUserRight('refund-create') || userRights.hasUserRight('refund-edit')) && <Route
        path="/sales/refund/edit/:refundId"
        exact
        element={<EditRefundPage />}
      />}
      {(userRights.debugging || userRights.hasUserRight('referrer-view')) && <Route
        path="/sales/referrer"
        exact
        element={<ReferrerList />}
      />}
      {(userRights.hasUserRight('referrer-create') || userRights.hasUserRight('referrer-edit')) && <Route
        path="/sales/referrer/edit/:referrerId"
        exact
        element={<EditReferrerPage />}
      />}
      {(userRights.debugging || userRights.hasUserRight('salesOrder-view')) && <Route
        path="/sales/query"
        exact
        element={<SalesOrderQuery />}
      />}
      {(userRights.debugging || userRights.hasUserRight('salesOrder-view')) && <Route
        path="/sales/salesOrders"
        exact
        element={<SalesOrderList />}
      />}
      {(userRights.hasUserRight('salesOrder-create') || userRights.hasUserRight('salesOrder-edit')) && <Route
        path="/sales/salesOrders/edit/:salesOrderId"
        exact
        element={<EditSalesOrderPage />}
      />}
      {(userRights.debugging || userRights.hasUserRight('product-view')) && <Route
        path="/products/list"
        exact
        element={<ProductList />}
      />}
      {(userRights.debugging || userRights.hasUserRight('userRights-edit')) && <Route
        path="/setting/userRight"
        exact
        element={<UserRights />}
      />}
      {userRights.hasUserRight('userRights-edit') && <Route
        path="/setting/userRight/edit"
        exact
        element={<EditUserRights />}
      />}
      <Route
        path="/leaveOvertimeWork/leaves"  // tabName: leaves
        exact
        element={<Leaves type={'owner'} />}
      />
      <Route
        path="/leaveOvertimeWork/leaves/edit/:userId/:leaveId"
        exact
        element={<EditLeavePage />}
      />
      <Route
        path="/leaveOvertimeWork/overtimes" // tabName: overtimes
        exact
        element={<Overtimes type={'owner'} />}
      />
      <Route
        path="/leaveOvertimeWork/overtimes/edit/:userId/:overtimeId"
        exact
        element={<EditOvertimePage />}
      />
      <Route
        path="/leaveOvertimeWork/punchClockRevise" // tabName: allOvertimes
        exact
        element={<PunchClockRevise type={'owner'} />}
      />
      <Route
        path="/leaveOvertimeWork/punchClockRevise/edit/:userId/:punchClockReviseId"
        exact
        element={<EditPunchClockRevisePage />}
      />
      {userRights.hasUserRight('any-review') && <Route
        path="/leaveOvertimeWork/review" // tabName: reviewLeaves
        exact
        element={<LopReview />}
      />}
      {(userRights.debugging || userRights.hasUserRight('any-overview')) && <Route
        path="/leaveOvertimeWork/overview" // tabName: allLeaves
        exact
        element={<LopOverview />}
      />}
      <Route
        path="/schedule/:staffType"
        exact
        element={<Schedule />}
      />
      {userRights.hasUserRight('schedule-exception') && <Route
        path="/staffManagement/punchClockException"
        exact
        element={<PunchClockException />}
      />}
      {(userRights.debugging || userRights.hasUserRight('user-view')) && <Route
        path="/staffManagement/staff"
        exact
        element={<Staff />}
      />}
      {userRights.hasUserRight('user-edit') && <Route
        path="/staffManagement/staff/edit/:staffId"
        exact
        element={<EditStaffPage />}
      />}
      {(userRights.hasUserRight('finance-salary')) && <Route
        path="/staffManagement/staff/editSalary/:staffId"
        exact
        element={<EditStaffSalaryPage />}
      />}
      {(userRights.debugging || userRights.hasUserRight('finance-view')) && <Route
        path="/finance"
        exact
        element={<Finance />}
      />}
      {(userRights.debugging || userRights.hasUserRight('report-view')) && <Route
        path="/report/:tabName/:dateType"
        exact
        element={<Reports />}
      />}
      {<Route
        path="/announcement"
        exact
        element={<Announcement />}
      />}
      {<Route
        path="/announcement/list"
        exact
        element={<Announcement />}
      />}
      {<Route
        path="/announcement/review"
        exact
        element={<AnnouncementReview />}
      />}
      {<Route
        path="/announcement/edit/:announcementId"
        exact
        element={<EditAnnouncement />}
      />}
      {(userRights.debugging || userRights.hasUserRight('vendor-view')) && <Route
        path="/vendor"
        exact
        element={<VendorList />}
      />}
      {(userRights.debugging || userRights.hasUserRight('merchandise-view')) && <Route
        path="/merchandise"
        exact
        element={<Merchandise />}
      />}
      {userRights.hasUserRight('merchandise-edit') && <Route
        path="/merchandise/:merchandiseId"
        exact
        element={<EditMerchandisePage />}
      />}
      {/* TODO: 要處理一下購物車這頁的權限檢查 */}
      <Route
        path="/user/shoppingCart"
        exact
        element={<ShoppingCartList />}
      />
      {(userRights.debugging || userRights.hasUserRight('requisition-view')) && <Route
        path="/purchase/requisition/:tabName"
        exact
        element={<Requisition />}
      />}
      {(userRights.hasUserRight('requisition-create') || userRights.hasUserRight('requisition-overwrite')) && <Route
        path="/purchase/requisition/edit/:requisitionId"
        exact
        element={<EditRequisitionPage />}
      />}
      {(userRights.debugging || userRights.hasUserRight('purchaseOrder-view')) && <Route
        path="/purchase/purchaseOrder/:tabName"
        exact
        element={<PurchaseOrder />}
      />}
      <Route
        path="/purchase/purchaseOrder/edit/:purchaseOrderId"
        exact
        element={<EditPurchaseOrderPage />}
      />
      {(userRights.debugging || userRights.hasUserRight('receipt-view')) && <Route
        path="/purchase/receipt/:tabName"
        exact
        element={<Receipt formName={'receipt'} />}
      />}
      <Route
        path="/purchase/receipt/edit/:receiptId"
        exact
        element={<EditReceiptPage formName={'receipt'} />}
      />
      {(userRights.debugging || userRights.hasUserRight('returnForm-create')) && <Route
        path="/purchase/returnForm/edit/new"
        exact
        element={<EditReturnFormPage />}
      />}
      {(userRights.debugging || userRights.hasUserRight('returnForm-view')) && <Route
        path="/purchase/returnForm"
        exact
        element={<ReturnForm />}
      />}
      {(userRights.debugging || userRights.hasUserRight('deliveryOrder-create')) && <Route
        path="/delivery/deliveryOrder/edit/new"
        exact
        element={<EditDeliveryOrderPage formName={'deliveryOrder'} />}
      />}
      {(userRights.debugging || userRights.hasUserRight('deliveryOrder-view')) && <Route
        path="/delivery/deliveryOrder"
        exact
        element={<DeliveryOrder formName={'deliveryOrder'} />}
      />}
      {(userRights.debugging || userRights.hasUserRight('salesReturnForm-view')) && <Route
        path="/delivery/salesReturnForm/:tabName"
        exact
        element={<SalesReturnForm />}
      />}
      {(userRights.debugging || userRights.hasUserRight('stock-query')) && <Route
        path="/stock/query"
        exact
        element={<StockList />}
      />}
      {(userRights.debugging || userRights.hasUserRight('stock-query')) && <Route
        path="/stock/query/:customerId"
        exact
        element={<StockList />}
      />}
      {(userRights.debugging || userRights.hasUserRight('stockRequisition-view')) && <Route
        path="/stock/stockRequisition/:tabName"
        exact
        element={<StockRequisition formName="stockRequisition" />}
      />}
      {userRights.hasUserRight('stockRequisition-create') && <Route
        path="/stock/stockRequisition/edit/new"
        exact
        element={<EditStockRequisitionPage formName="stockRequisition" />}
      />}
      {(userRights.debugging || userRights.hasUserRight('scrapForm-view')) && <Route
        path="/stock/scrapForm/:tabName"
        exact
        element={<StockRequisition formName="scrapForm" />}
      />}
      {userRights.hasUserRight('scrapForm-create') && <Route
        path="/stock/scrapForm/edit/new"
        exact
        element={<EditStockRequisitionPage formName="scrapForm" />}
      />}
      {(userRights.debugging || userRights.hasUserRight('inventoryChange-view')) && <Route
        path="/stock/inventoryChange/:tabName"
        exact
        element={<InventoryChange />}
      />}
      {userRights.hasUserRight('inventoryChange-create') && <Route
        path="/stock/inventoryChange/edit/new"
        exact
        element={<EditInventoryChangePage />}
      />}
      {userRights.hasUserRight('inventoryCheck-create') && <Route
        path="/stock/inventoryCheck/edit/:inventoryCheckId"
        exact
        element={<EditInventoryCheckPage />}
      />}
      {(userRights.debugging || userRights.hasUserRight('inventoryCheck-view')) && <Route
        path="/stock/inventoryCheck/:tabName"
        exact
        element={<InventoryCheck />}
      />}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

RoutePage.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }),
};

export default RoutePage;
