const i18n = {
  'customer': {
    'table': {
      'code': '編號',
      'name': '姓名',
      'nameOrCode': '姓名/編號',
      'birthDate': '生日',
      'phone': '電話',
      'salesRep': '服務人員',
      'lastAppointmentDateTime': '最新預約',
      'identityCardNumber': 'ID',
    },
    'filter': {
      'blackList': {
        'root': '黑名單搜尋',
        'all': '全部',
        'on': '黑名單',
        'off': '非黑名單'
      },
      'vip': {
        'root': 'VIP搜尋',
        'all': '全部',
        'on': 'VIP',
        'off': '非VIP'
      }
    },
    'tab': {
      'profile': '個人資料',
      'appointment': '預約記錄',
      'purchasedProduct': '購買紀錄',
      'consumption': '消耗記錄'
    },
    'detail': {
      'appointment': {
        'date': '日期',
        'arrangeTreatments': '服務',
        'doctor': '醫師',
        'nurse': '護理師',
        'salesRep': '服務人員',
        'staytime': '停留時間'
      },
      'purchasedProduct': {
        'date': '購買日期',
        'quantityAvailable': '已用數量/購買數量',
        'productName': '服務名稱',
        'salesOrderSN': '營收單號',
        'source': '來源',
        'status': '狀態'
      },
      'consumption': {
        'date': '消耗日期',
        'productName': '服務名稱',
        'amount': '消耗數量',
        'typeName': '消耗類型'
      }
    },
    'customerExtData': {
      'title': '客戶擴充欄位',
      'edit': {
        'title': '編輯{name}'
      },
    },
    'dialog': {
      'information': {
        'add': '新建客戶',
        'edit': '編輯資訊'
      },
      'editEmergencyContacts': '編輯緊急聯絡人',
      'name': '姓名',
      'blackList': '黑名單',
      'vip': 'VIP客戶',
      'cantWait': '不耐久候',
      'noPhone': '勿電話',
      'noSms': '勿簡訊',
      'occupation': '職業',
      'address': '地址',
      'VISIA': 'VISIA',
      'comment': '註記',
      'maritalStatus': {
        'roots': '婚姻',
        'married': '已婚',
        'single': '未婚'
      },
      'nationality': {
        'roots': '國籍',
        'taiwan': '台灣',
        'hongkong': '香港',
        'macao': '澳門',
        'china': '大陸',
        'other': '其他'
      },
      'customercode': '客戶代碼',
      'infosourceOther': '其他資訊',
      'infosource': {
        'roots': '如何得知相關訊息',
        'advertising': '戶外廣告',
        'newspapermagazine': '報章雜誌',
        'officialwebsite': '官網',
        'facebook': 'Facebook官方帳號',
        'blog': '部落格',
        'google': 'Google',
        'propagandamaterial': '宣傳品(體驗卷,DM)',
        'line': '生活圈',
        'doctor': '醫師自帶',
        'instagram': 'Instagram',
        'other': '其他',
      },
      'gender': {
        'roots': '性別',
        'male': '男',
        'female': '女',
      },
      'identityCardNumber': '身份證字號',
      'countryCode': {
        'roots': '國碼',
        'Taiwan': '台灣(+886)',
        'HK': '香港(+852)',
        'China': '大陸(+86)',
        'USA': '美加(+1)',
        'Japan': '日本(+81)',
        'Singapore': '新加坡(+65)',
      },
      'phone': '電話',
      'birthDate': '生日',
      'salesRep': '負責服務人員',
      'type': {
        'roots': '客戶類型',
        'new': '新客',
        'regular': '舊客',
        'introduced': '舊帶新',
        'employee': '員工',
        'staffRelatives': '員工眷屬',
        'sales': '業務',
        'kol': '意見領袖',
        'pr': '公關客'
      },
      'referrer': '介紹人',
      'employeeId': '員工編號',
      'selectStaff': {
        'displayName': '員工姓名',
        'employeeId': '員工編號'
      },
      'email': 'Email',
      'weChat': 'WeChat',
      'whatsApp': 'WhatsApp',
      'line': 'LINE',
    },
    'profile': {
      'information': '資訊',
      'emergencyContacts': {
        'root': '緊急聯絡人',
        'name': '姓名',
        'phoneNumber': '電話',
      },
      'socialMedia': '社群媒體',
      'name': '姓名',
      'phone': '電話號碼',
      'email': 'Email',
      'line': 'LINE',
      'status': {
        'cantWait': '不耐久候',
        'blackList': '黑名單',
        'noPhone': '勿電話',
        'noSms': '勿簡訊',
        'vip': 'VIP客戶',
        'allergy': '過敏'
      },
      'gender': {
        'roots': '性別',
        'male': '男',
        'female': '女',
      },
      'address': '地址',
      'allergy': '過敏',
      'birthDate': '生日',
      'blackList': '黑名單',
      'cantWait': '不耐久候',
      'noPhone': '勿電話',
      'noSms': '勿簡訊',
      'type': {
        'roots': '客戶類型',
        'new': '新客',
        'regular': '舊客',
        'introduced': '舊帶新',
        'employee': '員工',
        'staffRelatives': '員工眷屬',
        'sales': '業務',
        'kol': '意見領袖',
        'pr': '公關客'
      },
      'employeeId': '員工編號',
      'nationality': {
        'roots': '國籍',
        'taiwan': '台灣',
        'hongkong': '香港',
        'macao': '澳門',
        'china': '大陸',
        'other': '其他'
      },
      'infosource': {
        'roots': '如何得知相關訊息',
        'advertising': '戶外廣告',
        'newspapermagazine': '報章雜誌',
        'officialwebsite': '官網',
        'facebook': 'Facebook官方帳號',
        'blog': '部落格',
        'google': 'Google',
        'propagandamaterial': '宣傳品(體驗卷,DM)',
        'line': '生活圈',
        'doctor': '醫師自帶',
        'instagram': 'Instagram',
        'other': '其他',
      },
      'referrer': '介紹人',
      'id': 'ID',
      'identityCardNumber': '身份證字號',
      'comment': '註記',
      'VISIA': 'VISIA',
      'maritalStatus': {
        'roots': '婚姻',
        'married': '已婚',
        'single': '未婚'
      },
      'customercode': '客戶代碼',
      'customerId': '客戶編號',
      'tag': '標籤',
      'occupation': '職業',
      'salesRep': '服務人員',
      'weChat': 'WeChat',
      'whatsApp': 'WhatsApp',
      'benefit': {
        'roots': '員工福利點數額度',
        'earned': '年度福利點數',
        'used': '年度已用',
        'balance': '可用總額'
      },
      'storedValueBalance': '儲值點數'
    }
  },
}

export default i18n