import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import CompanyIcon from '@mui/icons-material/AutoAwesomeMotion';
import ListItem from '@mui/material/ListItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import ContextStore from 'modules/context';

function CompanyFilter({ userRight, style = {} }) {
  const { formatMessage } = useIntl()
  const { currentCompany, setCurrentCompany, setUiState, uiState } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const [anchorEl, setAnchorEl] = useState(null)
  const vendorMapping = useSelector(state => state.vendors.data)
  const [companyData, setCompanyData] = useState([])

  useEffect(() => {
    if (userRights[userRight] && userRights[userRight].length > 0) {
      const newU = userRights[userRight].sort((a, b) => {
        return vendorMapping[a].code.replace(/[^0-9]/, '') - vendorMapping[b].code.replace(/[^0-9]/, '')
      })

      setCompanyData(newU)
      setCurrentCompany(newU[0])
    }
    return () => {
    };
  }, [userRights]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleClick(company) {
    setCurrentCompany(company)
    if (userRight.startsWith('appointment')) {
      setUiState({
        ...uiState,
        showCommentSidebar: false,
        showAppointmentSidebar: false,
        showDoctorSchedule: false
      })
    }
    handleClose()
  }

  return (
    <>
      {userRight.startsWith('appointment') || userRight.startsWith('schedule') ? <>
        {
          userRights.hasUserRight(userRight) && <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={event => setAnchorEl(event.currentTarget)}
            color="inherit"
            size="medium"
          >
            <CompanyIcon />
          </IconButton>
        }
        < Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          {companyData.map(company => (
            <ListItem dense key={company}>
              <FormControlLabel
                control={<Checkbox
                  checked={company === currentCompany}
                  onChange={e => handleClick(company)}
                />}
                label={vendorMapping[company]?.name}
              />
            </ListItem>
          ))}
        </Menu>
      </> :
        <TextField
          select={companyData.length > 1}
          disabled={companyData.length <= 1}
          type="text"
          size="small"
          fullWidth
          sx={style}
          label={formatMessage({ id: 'company.label' })}
          variant="outlined"
          onChange={e => handleClick(e.target.value)}
          value={companyData.length > 1 ? currentCompany : vendorMapping[currentCompany]?.name}
        >
          {companyData.map(company => {
            return <MenuItem disabled={currentCompany === company} key={company} value={company}>{vendorMapping[company]?.name}</MenuItem>
          })}
        </TextField>
      }
    </>
  )
}

CompanyFilter.propTypes = {
  userRight: PropTypes.string.isRequired,
  style: PropTypes.object
};


export default CompanyFilter