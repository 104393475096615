import { useEffect, useState } from 'react';
import { auth } from 'store';
import { onAuthStateChanged } from 'firebase/auth';

const useAuth = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      // user.uid = '4wAGUurJZ2ROcXFa55mSPaqnbxE3'  // use debug specify person
      if (user) {
        setUser(user)
      } else {
        setUser({ uid: '' })
      }
    });
  }, [])
  return user;
}

export {
  useAuth,
};
