import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import SelectItemDialog from './SelectItemDialog';

function SelectProductDialog({ multiSelect = true, items, headerCells, rowCells, filterItems,  productMapping, handleSave, filterSetting = {}, onShelf = false, ...props }) {
  const { formatMessage } = useIntl()
  const _tags = Object.values(useSelector(state => state.config.data?.productTags || {})).sort()
  const _items = onShelf ? getValidProducts() : items
  const tags = filterSetting.type?.default ? getValidTags() : _tags

  const _headerCells = headerCells.map(c => { c.text = formatMessage({ id: `product.table.header.${c.text}` }); return c })
  const _filterItems = filterItems.map(i => { i.text = formatMessage({ id: `product.table.header.${i.name}` }); return i })

  const categoryFilterItems = [
    { name: 'type', type: 'select', default: '', size: 6, items: getMenuItem() },
    { name: 'tags', type: 'checkbox', default: [], size: 6, items: tags },
  ].map(i => { i.text = formatMessage({ id: `product.edit.${i.name}` }); return i })

  categoryFilterItems.forEach(i => {
    if (filterSetting[i.name]) {
      i.default = filterSetting[i.name].default
      i.hidden = filterSetting[i.name].hidden
    }
  })

  function getValidProducts() {
    const today = dayjs().format('YYYY-MM-DD')
    return [ ...items ].filter(i => {
      if (i.offShelfDate && i.onShelfDate) {
        if (i.offShelfDate >= today && i.onShelfDate <= today) {
          return true
        }
      } else if (i.offShelfDate && !i.onShelfDate) {
        if (i.offShelfDate >= today) {
          return true
        }
      } else if (i.onShelfDate && !i.offShelfDate) {
        if (i.onShelfDate <= today) {
          return true
        }
      } else {
        return true
      }
      return false
    })
  }

  function getValidTags() {
    const pTags = [...new Set(_items.filter(i => i.type === filterSetting.type.default).reduce((acc, cur) => {
      return acc.concat(cur.tags)
    }, []))]
    return _tags.filter(i => pTags.includes(i))
  }

  function getMenuItem() {
    return [
      { value: '' },
      { value: 'merchandise' },
      { value: 'service' },
      { value: 'storedValue' },
      { value: 'pointCard' },
    ].map(i => {
      i.label = i.value ? formatMessage({ id: `product.type.${i.value}` }) :  formatMessage({ id: 'product.type.all' })
      return i
    })
  }

  function applyFilter(currentFilter, categoryFilter) {
    if (!currentFilter && !categoryFilter.type && categoryFilter.tags.length === 0) {
      return _items
    }
    let newItems = [..._items]
    if (categoryFilter.type) {
      newItems = newItems.filter(i => i.type === categoryFilter.type)
    }
    if (categoryFilter.tags.length > 0) {
      newItems = newItems.filter(i => categoryFilter.tags.every(tag => (i.tags || []).includes(tag)))
    }
    if (currentFilter) {
      newItems = newItems.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
    return newItems
  }

  function onSave(selectedItems, params) {
    if (multiSelect) {
      const products = _items.filter(u => selectedItems[u.id]).map(u => ({
        name: u.name,
        nickname: u.nickname,
        id: u.id,
        type: u.type,
        duration: u.duration,
        color: u.color,
        commonPrice: u.commonPrice,
        price: u.price,
        merchandises: u.merchandises
      }))
      handleSave(products, params)
    } else {
      handleSave(selectedItems, params)
    }
  }

  return (
    <SelectItemDialog
      multiSelect={multiSelect}
      headerCells={_headerCells}
      rowCells={rowCells}
      filterItems={_filterItems}
      categoryFilterItems={categoryFilterItems}
      applyFilter={applyFilter}
      handleSave={onSave}
      maxWidth="lg"
      {...props}
    />
  );
}

SelectProductDialog.propTypes = {
  onShelf: PropTypes.bool,
  filterSetting: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
  productMapping: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  headerCells: PropTypes.arrayOf(PropTypes.object.isRequired),
  rowCells: PropTypes.arrayOf(PropTypes.object.isRequired),
  filterItems: PropTypes.arrayOf(PropTypes.object.isRequired),
};

export default SelectProductDialog;
