import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import Typography from '@mui/material/Typography';

import FabAdd from 'components/FabAdd';
import EnhancedTable from 'components/EnhancedTable';
import EnhancedTableEx from 'components/EnhancedTableEx';
import DatePickerField from 'components/DatePickerField';
import ButtonProgress from 'components/ButtonProgress';
import ContextStore from 'modules/context';
import SimpleTableToolbar from 'components/SimpleTableToolbar';
import CompanyFilter from 'components/CompanyFilter';
import { firestoreListener, callFunction } from 'modules/firebase';

function SalaryDialog({ handleClose, companyData, vendorMapping }) {
  const { formatMessage } = useIntl()
  const [loadingApprove, setLoadingApprove] = useState(false)
  const [monthTime, setMonthTime] = useState(dayjs().format('YYYY-MM'));
  const [source, setSource] = useState('');

  async function handleSave() {
    setLoadingApprove(true)
    try {
      await callFunction('createSalaryList', { monthTime, source })
      setLoadingApprove(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'finance.salary.dialog.title' })}</DialogTitle>
      <DialogContent>
        <Grid container sx={{ p: 1 }} spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <DatePickerField
              required
              closeToolbar={false}
              label={formatMessage({ id: 'finance.salary.dialog.month' })}
              value={dayjs(monthTime)}
              inputFormat="YYYY-MMM"
              onChange={date => setMonthTime(dayjs(date).format('YYYY-MM'))}
              invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              required
              type="text"
              size="small"
              select={true}
              label={formatMessage({ id: 'finance.bonus.dialog.source' })}
              variant="outlined"
              value={source}
              onChange={(e) => { setSource(e.target.value) }}
              fullWidth
            >
              {
                companyData.map(c => {
                  return <MenuItem key={c} value={c}>{vendorMapping[c].name}</MenuItem>
                })
              }
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonProgress handleClose={handleClose} handleClick={() => handleSave()} loading={loadingApprove} />
      </DialogActions>
    </Dialog>
  );
}

SalaryDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  companyData: PropTypes.array.isRequired
};

function SalaryDetail({ salary: { id: yearMonth, salaryData, source }, userMapping, userRights, currentCompany, currentUser, vendorMapping }) {
  const { formatMessage } = useIntl()
  const tableRef = useRef()
  const currentData = Object.keys(salaryData).reduce((acc, cur) => {
    acc.push({ id: cur, ...salaryData[cur] })
    return acc
  }, []).filter(c => {
    if (!userRights.hasUserRightForVendor('finance-salary', currentCompany)) {
      return c.id === currentUser.key
    } else {
      return c
    }
  }).sort((a, b) => {
    const e = userMapping[a.id].employeeId.replace(/[^0-9]/ig,'')
    const e2 = userMapping[b.id].employeeId.replace(/[^0-9]/ig,'')

    if (e < e2) {
      return -1
    } else if (e > e2) {
      return 1
    } else {
      return 0
    }
  })

  const formatData = (data) => {
    const newData = { ...data }
    newData.name = userMapping[newData.id].displayName
    newData.employeeId = userMapping[newData.id].employeeId
    newData.onBoardingCount = dayjs().startOf('date').diff(dayjs(newData.onBoardingDate).startOf('date'), 'day')

    // const newPay = newData.basicSalary + newData.subsistenceAllowance + newData.jobAllowance + newData.licenseAllowance + newData.otherPlus 
    // const normalOvertime = Math.round(newPay / 240 * 4 / 3 * newData.overtimeCount?.normal)
    // const specialOvertime = Math.round(newPay / 240 * 5 / 3 * newData.overtimeCount?.special) //加班費
    newData.overtime = newData.overtimeCount ?
      `${newData.overtimeCount?.normal}h/${newData.overtimeCount?.special}h/${newData.overtimeCount?.balance}m` :
      '0(0)/0(0)/0'

    // for (const i of Object.keys(newData)) {
    //   if (!['name',
    //     'employeeId',
    //     'identityCardNumber',
    //     'bankAccount',
    //     'onBoardingDate',
    //     'onBoardingCount',
    //     'lateCount',
    //     'overtime',
    //     'absenteeismCount',
    //     'reviseCount',
    //     'note'
    //   ].includes(i)) {
    //     newData[i] = numFormat(newData[i])
    //   }
    // }
    
    return newData
  }

  const headerCells = [
    { text: 'employeeId', style: { display: 'none' } },
    { text: 'name' },
    { text: 'identityCardNumber', style: { display: 'none' } },
    { text: 'bankAccount', style: { display: 'none' } },
    { text: 'onBoardingDate' },
    { text: 'onBoardingCount' },

    { text: 'basicSalary' },
    // { text: 'fullAttendance' },
    { text: 'subsistenceAllowance' },
    { text: 'jobAllowance' },
    { text: 'licenseAllowance' },
    { text: 'otherPlus' },
    { text: 'personalLeave', style: { color: 'red' } },
    { text: 'sickLeave', style: { color: 'red' } },
    { text: 'dueAmount' },

    { text: 'healthInsurance', style: { color: 'red' } },
    { text: 'laborInsurance', style: { color: 'red' } },
    { text: 'incomeTax', style: { color: 'red' } },
    { text: 'secondGenerationNHI', style: { color: 'red' } },
    { text: 'otherMinus', style: { color: 'red' } },
    { text: 'deduction', style: { color: 'red' } },

    { text: 'actualAmount' },
    { text: 'overtime', style: { color: 'brown' } },
    { text: 'lateCount', style: { color: 'blue' } },
    { text: 'absenteeismCount', style: { color: 'blue' } },
    { text: 'reviseCount', style: { color: 'blue' } },

    { text: 'note' },
  ].map(c => { c.text = formatMessage({ id: `finance.salary.detail.${c.text}` }); return c })

  const rowCells = [
    { field: 'employeeId', style: { display: 'none' } },
    { field: 'name', tooltip: 'employeeId' },
    { field: 'identityCardNumber', style: { display: 'none' } },
    { field: 'bankAccount', style: { display: 'none' } },
    { field: 'onBoardingDate' },
    { field: 'onBoardingCount' },

    { field: 'basicSalary' },
    // { field: 'fullAttendance' },
    { field: 'subsistenceAllowance' },
    { field: 'jobAllowance' },
    { field: 'licenseAllowance' },
    { field: 'otherPlus' },
    { field: 'personalLeave', style: { color: 'red' } },
    { field: 'sickLeave', style: { color: 'red' } },
    { field: 'dueAmount', style: { fontWeight: 'bold' } },

    { field: 'healthInsurance', style: { color: 'red' } },
    { field: 'laborInsurance', style: { color: 'red' } },
    { field: 'incomeTax', style: { color: 'red' } },
    { field: 'secondGenerationNHI', style: { color: 'red' } },
    { field: 'otherMinus', style: { color: 'red' } },
    { field: 'deduction', style: { color: 'red' } },

    { field: 'actualAmount', style: { fontWeight: 'bold' } },
    { field: 'overtime', style: { fontWeight: 'bold', color: 'brown' } },
    { field: 'lateCount', style: { color: 'blue' } },
    { field: 'absenteeismCount', style: { color: 'blue' } },
    { field: 'reviseCount', style: { color: 'blue' } },

    { field: 'note' },
  ]

  function exportSalary() {
    tableRef.current.saveToFile(`Salary${yearMonth}_${vendorMapping[source].name}.csv`)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} sx={{ mt: '10px' }}>
        <EnhancedTableEx
          ref={tableRef}
          headerCells={headerCells}
          rowCells={rowCells}
          tableData={currentData.map(c => formatData(c))}
          getHeaderActionIcons={() =>
            <IconButton
              onClick={() => exportSalary()}
              size="large">
              <GetAppIcon></GetAppIcon>
              <Typography noWrap variant="button">{formatMessage({ id: 'button.export' })}</Typography>
            </IconButton>
          }
          getExpandContent={() => null}
        />
      </Grid>
    </Grid>
  );
}

SalaryDetail.propTypes = {
  salary: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
};

function SalaryPage() {
  const { formatMessage } = useIntl()
  const vendorMapping = useSelector(state => state.vendors.data);

  const { currentCompany, currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights);
  const [salaryList, setSalaryList] = useState([])
  const [openDailog, setOpenDailog] = useState(false)
  const userMapping = useSelector(state => state.users.data);
  const companyData = userRights['finance-salary'] && userRights['finance-salary'].length ? userRights['finance-salary'] : []

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'salaryList',
      where: [['source', '==', currentCompany]],
      onData: (data) => {
        setSalaryList(data)
      }
    })

    return () => unsubscribe()
  }, [currentCompany]);

  const formatData = (data) => {
    const newData = { ...data }
    newData.yearMonth = newData.id
    newData.createdAt = dayjs(newData.createdAt.seconds * 1000).format('YYYY-MM-DD')
    newData.createdBy = userMapping[newData.createdBy].displayName
    return newData
  }

  const headerCells = [
    { text: 'yearMonth', sort: 'yearMonth' },
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'createdAt', sort: 'createdAt' },
  ].map(c => { c.text = formatMessage({ id: `finance.salary.header.${c.text}` }); return c })

  const rowCells = [
    { field: 'yearMonth' },
    { field: 'createdBy' },
    { field: 'createdAt' },
  ]

  return (
    <Grid container spacing={1} sx={{ mt: '10px' }}>
      {userRights.hasUserRightForVendor('finance-salary', currentCompany) && <FabAdd onClick={() => setOpenDailog(true)} />}
      {openDailog && <SalaryDialog
        handleClose={() => setOpenDailog(false)}
        companyData={companyData}
        vendorMapping={vendorMapping}
      />}
      <Grid item xs={12} sm={12} md={12} sx={{ mt: '4px' }}>
        <SimpleTableToolbar
          title='finance.salary.title'
          toolbox={<Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={9} />
            <Grid item xs={12} sm={12} md={3}>
              <CompanyFilter userRight='finance-salary' />
            </Grid>
          </Grid>}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <EnhancedTable
          defaultOrder="asc"
          defaultOrderField="date"
          headerCells={headerCells}
          rowCells={rowCells}
          tableData={salaryList.map(s => formatData(s))}
          getExpandContent={salary => <SalaryDetail
            salary={salary}
            userMapping={userMapping}
            currentCompany={currentCompany}
            userRights={userRights}
            currentUser={currentUser}
            vendorMapping={vendorMapping}
          />}
        />
      </Grid>
    </Grid>
  );
}

export default SalaryPage;
