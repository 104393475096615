import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';

import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { StyledEngineProvider } from '@mui/material/styles';

import { callFunction } from 'modules/firebase';
import MaskInput from 'components/MaskInput';
import DatePickerField from 'components/DatePickerField';
import ButtonProgress from 'components/ButtonProgress';
import { moibleMedia } from 'constants';

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '400px',
    height: '100vh',
    backgroundColor: '#ffffff',
    borderLeft: '1px solid #D4D4D4',
    zIndex: 10,
    paddingBottom: '60px',
    [moibleMedia]: {
      position: 'fixed',
      top: '100px',
      bottom: 0,
      left: 0,
      height: 'auto',
      width: '100vw',
      paddingBottom: 0
    }
  },
  form: {
    width: '100%',
    height: 'calc(100% - 60px)',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  header: {
    padding: '0 17px',
    backgroundColor: '#ffffff',
    '& h2': {
      marginBottom: '5px',
    }
  },
  dateTime: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px',
    marginTop: '20px'
  },
  datePicker: {
    width: '140px',
    display: 'inline-flex',
    marginRight: '30px'
  },
  checkList: {
    display: 'flex',
    flexDirection: 'column',
    justifyConteant: 'flex-start',
  },
  listBody: {
    marginTop: '20px',
    // display: 'flex',
    // // flexDirection: 'column',
    // justifyContent: 'flex-start',
    // alignItems: 'center'
  },
  footer: {
    bottom: 0,
    padding: '0 24px 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -17,
    // color: 'blue'
  },
});

function CommentSidebar({ ui, onRequestClose, doctors, appointmentTypes, currentCompany }) {
  const { formatMessage } = useIntl()
  const classes = useStyles();
  const [loadingApprove, setLoadingApprove] = useState(false);
  const commentId = ui.editComment ? ui.editComment.id : 'new'
  const userMapping = useSelector(state => state.users.data);
  const config = useSelector(state => state.config.data)
  const apptypeMapping = Object.keys(config.appointmentType || {}).reduce((acc, cur) => {
    acc[config.appointmentType[cur].id] = { ...config.appointmentType[cur] }
    return acc
  }, {})
  const defultData = ui.editComment ? {
    date: dayjs(ui.editComment.date),
    startTime: ui.editComment.startTime,
    endTime: ui.editComment.endTime,
    content: ui.editComment.content
  } : {
    date: dayjs(ui.date),
    startTime: ui.clickMenuData ? `${ui.clickMenuData.hour}:00` : '',
    endTime: ui.clickMenuData ? `${ui.clickMenuData.hour + 1}:00` : '',
    content: '',
  }
  const [data, setData] = useState(defultData);
  const itemRows = appointmentTypes.concat(doctors.filter(d => d.active).map(d => d.id)).concat(['cancelled'])
  let newRows = ui.editComment ? ui.editComment.rows : {}
  itemRows.map(i => {
    if (ui.clickMenuData && ui.clickMenuData.doctor && ui.clickMenuData.doctor.id === i) {
      newRows[i] = true
    } else if (ui.clickMenuData && ui.clickMenuData.appointmentType === i && !ui.clickMenuData.doctor) {
      newRows[i] = true
    } else {
      if (!newRows[i]) {
        newRows[i] = false
      }
    }

    return newRows
  })

  const [selectItem, setSelectItem] = useState(newRows);
  const [selectAllItem, setSelectAllItem] = useState({
    notAll: false,
    isAll: false
  })

  async function saveComment() {
    setLoadingApprove(true)
    const newData = { ...data }
    const newItem = { ...selectItem }
    newData.date = dayjs(newData.date).format('YYYY-MM-DD')
    newData.startHour = parseInt(newData.startTime.split(':')[0])
    newData.startMinute = parseInt(newData.startTime.split(':')[1])
    newData.endHour = parseInt(newData.endTime.split(':')[0])
    newData.endMinute = parseInt(newData.endTime.split(':')[1])

    if(commentId === 'new') {
      newData.source = currentCompany
    }

    for (const s in newItem) {
      if (!newItem[s]) {
        delete newItem[s]
      }
    }

    newData.rows = newItem
    try {
      await callFunction('saveComment', { id: commentId, ...newData })
      setLoadingApprove(false)
      onRequestClose()
    } catch (ex) {
      console.log(ex)
      setLoadingApprove(false)
      onRequestClose()
    }
  }

  function updateData(field, value) {
    let newValue = value
    let newData = { ...data, [field.name]: newValue }

    setData(newData)
  }

  const handleToggle = (value) => () => {
    const currentValue = selectItem[value];
    const newSelectItem = { ...selectItem };
    let newItem = { ...newSelectItem, [value]: !currentValue }

    let partCheck = false
    let isAllCheck
    for (const i in newItem) {
      if (newItem[i]) {
        partCheck = true
        isAllCheck = true
      } else {
        isAllCheck = false
      }
    }

    setSelectItem(newItem)
    if (isAllCheck) {
      setSelectAllItem({ isAll: isAllCheck, notAll: false })
    } else {
      setSelectAllItem({ isAll: isAllCheck, notAll: partCheck })
    }
  };

  const selectAllCheck = (event) => {
    let newSelectItem = { ...selectItem };

    for (const i in newSelectItem) {
      newSelectItem[i] = event
    }

    setSelectItem(newSelectItem)
    setSelectAllItem({ ...selectAllItem, isAll: event })
  }

  return (
    <StyledEngineProvider injectFirst>
      <div className={classes.container}>
        <form className={classes.form}>
          <div className={classes.header}>
            <h2>
              <FormattedMessage id='appointment.sidebar.comment' />
            </h2>
            <div className={classes.dateTime}>
              <DatePickerField
                required
                fullWidth
                label={formatMessage({ id: 'appointment.sidebar.date' })}
                value={data.date}
                onChange={date => updateData({ name: 'date' }, date)}
                invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
              />
              <MaskInput
                style={{ marginLeft: '10px', width: '50%' }}
                mask="99:99"
                maskChar=" "
                onChange={e => updateData({ name: 'startTime' }, e.target.value)}
                value={data.startTime}
                label={formatMessage({ id: 'appointment.sidebar.startTime' })}
              />
              <MaskInput
                style={{ marginLeft: '5px', width: '50%' }}
                mask="99:99"
                maskChar=" "
                onChange={e => updateData({ name: 'endTime' }, e.target.value)}
                value={data.endTime}
                label={formatMessage({ id: 'appointment.sidebar.endTime' })}
              />
            </div>
          </div>
          <div className={classes.checkList}>
            <div style={{ padding: '0 20px' }}>
              <TextField
                fullWidth
                label={<FormattedMessage id='appointment.sidebar.comment' />}
                variant="outlined"
                value={data.content}
                multiline
                size='small'
                onChange={e => updateData({ name: 'content' }, e.target.value)}
              />
            </div>
            <div className={classes.listBody}>
              <div style={{ padding: '0 20px', borderBottom: '1px solid rgba(0,0,0,.1)' }}>
                <Checkbox
                  sx={{ ml: '15px' }}
                  checked={selectAllItem.isAll}
                  indeterminate={selectAllItem.notAll}
                  disableRipple
                  onClick={(e) => selectAllCheck(e.target.checked)}
                />
              </div>
              <List style={{ padding: '0 20px', backgroundColor: '#ffffff' }}>
                {itemRows.map((value, index) => {
                  let newValue = ''
                  if (appointmentTypes.concat(['cancelled']).includes(value)) {
                    if (['cancelled', 'normalAppointment'].includes(value)) {
                      newValue = formatMessage({ id: `appointment.sidebar.appointmentType.${value}` })
                    } else {
                      newValue = apptypeMapping[value].name
                    }
                  } else {
                    newValue = userMapping[value].displayName
                  }

                  return (
                    <ListItem key={index} role={undefined} dense button onClick={handleToggle(value)}>
                      <ListItemIcon>
                        <Checkbox
                          checked={selectItem[value]}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={index}
                        primary={newValue}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </div>
        </form>
        <div className={classes.footer}>
          <ButtonProgress
            handleClick={() => saveComment()}
            handleClose={onRequestClose}
            loading={loadingApprove}
          />
        </div>
      </div>
    </StyledEngineProvider>
  );
}

CommentSidebar.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
  appointmentTypes: PropTypes.array.isRequired,
  doctors: PropTypes.arrayOf(PropTypes.object.isRequired),
  currentCompany: PropTypes.string.isRequired
};

export default CommentSidebar;
