import React, { useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { arrayMove } from '@dnd-kit/sortable';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import DelectIconButton from 'components/DelectIconButton';
import SelectUserDialog from 'components/SelectUserDialog';
import DndContainer from 'components/DndContainer';
import DragHandle from 'components/DragHandle';
import { getRandomHash } from 'modules/uitls';
import { reviewPolicyRules } from 'constants';

const rowStyle = {
  borderTop: 'solid 1px #efefef',
  width: '100%',
  height: '80px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#fff',
  padding: '0px 16px 0px 8px',
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
  zIndex: 10000,
}

const cellStyle = {
  marginLeft: '8px',
  marginRight: '8px',
  flexGrow: 2
}

function StepRowItem({ item, index, updateApproveStep, showSelectUserPopup, onStepRuleChanged, onDeleteItem }) {
  const { formatMessage } = useIntl()

  return (
    <div style={rowStyle}>
      <DragHandle />
      <div style={cellStyle}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              required
              type="text"
              size="small"
              label={formatMessage({ id: 'setAttendance.editReviewPolicy.table.detail.stepName' })}
              variant="outlined"
              value={item.name}
              onChange={e => updateApproveStep(index, 'name', e.target.value)}
              error={item.name_err ? true : false}
              helperText={item.name_err}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              type="text"
              size="small"
              label={formatMessage({ id: 'setAttendance.editReviewPolicy.table.detail.approver' })}
              variant="outlined"
              value={item.users.map(s => s.name).join(', ')}
              onClick={() => showSelectUserPopup(index)}
              error={item.users_err ? true : false}
              helperText={item.users_err}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              select
              type="text"
              size="small"
              label={formatMessage({ id: 'setAttendance.editReviewPolicy.table.detail.rule' })}
              variant="outlined"
              value={item.rule}
              onChange={e => onStepRuleChanged(index, e.target.value)}
              fullWidth
            >
              {reviewPolicyRules.map(r => <MenuItem key={r.id} value={r.id}>
                {r.text}
              </MenuItem>)}
            </TextField>
          </Grid>
        </Grid>
      </div>
      <DelectIconButton text={formatMessage({ id: 'setAttendance.editReviewPolicy.deleteStep' })} onClick={onDeleteItem} />
    </div>
  )
}

StepRowItem.propTypes = {
  index: PropTypes.number.isRequired,
  updateApproveStep: PropTypes.func.isRequired,
  showSelectUserPopup: PropTypes.func.isRequired,
  onStepRuleChanged: PropTypes.func.isRequired,
};

const EditReviewPolicyView = forwardRef((props, ref) => {
  const { reviewPolicy } = props
  const { formatMessage } = useIntl()
  const userMapping = useSelector(state => state.users.data)
  const users = useSelector(state => state.users.ordered).filter(u => u.active && !u.developer)

  const [userDialogData, setUserDialogData] = useState(null);

  const [reviewPolicyData, setReviewPolicyData] = useState({
    name: reviewPolicy.name ?? '',
    note: reviewPolicy.note ?? '',
    steps: reviewPolicy.steps.map(s => ({
      id: `item-${s.id ?? getRandomHash()}` ,
      name: s.name,
      users: s.users.map(u => ({ id: u, name: userMapping[u] ? userMapping[u].displayName : '' })),
      rule: s.rule || 'none'
    }))
  })

  const fields = [
    { name: 'name', required: true, md: 3 },
    { name: 'note', md: 9 }
  ]

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = reviewPolicyData.steps.findIndex(e => e.id === active.id);
      const newIndex = reviewPolicyData.steps.findIndex(e => e.id === over.id);
      updateReviewPolicyData({ name: 'steps' },  arrayMove(reviewPolicyData.steps, oldIndex, newIndex))
    }
  }

  function onDeleteItem(index) {
    const newData = [...reviewPolicyData.steps]
    newData.splice(index, 1)
    updateReviewPolicyData({ name: 'steps' }, newData)
  }

  function onStepUsersChanged(selectedItems, params) {
    updateApproveStep(params, 'users', selectedItems)
  }

  function onStepRuleChanged(index, value) {
    updateApproveStep(index, 'rule', value)
  }

  function updateApproveStep(index, field, value) {
    // validate field
    const step = {
      id: reviewPolicyData.steps[index].id,
      name: reviewPolicyData.steps[index].name,
      users: reviewPolicyData.steps[index].users,
      rule: reviewPolicyData.steps[index].rule
    }
    if (field === 'name' && value.trim() === '') {
      step.name_err = formatMessage({ id: 'form.field.isRequired' })
    }
    else if (field === 'users' && !value.length) {
      step.users_err = formatMessage({ id: 'form.field.isRequired' })
    }

    step[field] = value
    const newData = [...reviewPolicyData.steps]
    newData[index] = step

    updateReviewPolicyData({ name: 'steps' }, newData)
  }

  function addStep() {
    const newData = [...reviewPolicyData.steps, { name: '簽核', users: [], rule: 'none', id: getRandomHash() }]
    updateReviewPolicyData({ name: 'steps' }, newData)
  }

  function showSelectUserPopup(key) {
    const index = Number(key)
    const users = (reviewPolicyData.steps[index].users || []).map(u => u.id)
    setUserDialogData({ index, users })
  }

  useImperativeHandle(
    ref, () => ({
      getContent: () => {
        return _getContent()
      }
    }),
  )

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }
    return ''
  }

  function updateReviewPolicyData(field, value) {
    let newValue = value
    if (reviewPolicyData[field.name] === newValue) return;

    let err = validateField(field, value)

    let newData = { ...reviewPolicyData, [field.name]: newValue, [`${field.name}_err`]: err }
    setReviewPolicyData(newData)
  }

  function _getContent() {
    let err = false

    let newData = { ...reviewPolicyData }
    for (let field of fields) {
      const errMsg = validateField(field, newData[field.name])
      if (errMsg) {
        newData[`${field.name}_err`] = errMsg
      }
    }

    for (let field of fields) {
      if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        err = true
      }
    }

    for (const step of newData.steps) {
      if (step.name.trim() === '') {
        step.name_err = formatMessage({ id: 'form.field.isRequired' })
        err = true
      }
      if (step.users.length === 0) {
        step.users_err = formatMessage({ id: 'form.field.isRequired' })
        err = true
      }
    }

    let newSteps = [...(newData.steps.map(i => ({ ...i })))]
    let stepFields = ['name', 'users', 'rule']
    for (const step of newSteps) {
      for (const field of stepFields) {
        if (step[`${field}_err`] !== undefined && step[`${field}_err`] !== '') {
          err = true
        }
      }
    }

    if (err) {
      setReviewPolicyData(newData)
      return
    }

    for (const step of newSteps) {
      step.users = step.users.map(s => s.id)
    }

    return {
      name: reviewPolicyData.name,
      note: reviewPolicyData.note,
      steps: newSteps,
    }
  }

  function getToolbox() {
    return (
      <div>
        <Button
          sx={{ m: 1, whiteSpace: 'nowrap' }}
          variant="contained"
          color="primary"
          onClick={() => addStep()}
        >
          <FormattedMessage id="setAttendance.reviewPolicy.addStep" />
        </Button>
      </div>
    )
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        {fields.map(field => <Grid item key={field.name} xs={6} sm={12} md={field.md}>
          <TextField
            required={field.required}
            type="text"
            size="small"
            label={formatMessage({ id: `setAttendance.editReviewPolicy.table.detail.${field.name}` })}
            variant="outlined"
            value={reviewPolicyData[field.name]}
            onChange={e => updateReviewPolicyData(field, e.target.value)}
            error={reviewPolicyData[`${field.name}_err`] ? true : false}
            helperText={reviewPolicyData[`${field.name}_err`]}
            fullWidth
          />
        </Grid>)}
      </Grid>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography sx={{ flex: '1 1 20%' }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="setAttendance.editReviewPolicy.table.title" />
        </Typography>
        {getToolbox()}
      </div>
      {userDialogData && <SelectUserDialog
        multiSelect
        headerCells={[{ name: 'displayName', sort: 'displayName' },{ name: 'email' }]}
        rowCells={[{ field: 'displayName' },{ field: 'email' }]}
        filterItems={[{ name: 'displayName' },{ name: 'email' }]}
        dialogTitle={formatMessage({ id: 'selectUserDialog.title' })}
        tableTitle={formatMessage({ id: 'selectUserDialog.table.title' })}
        handleClose={() => setUserDialogData(null)}
        handleSave={onStepUsersChanged}
        defaultSelectedItems={userDialogData.users ?? []}
        items={users}
        params={String(userDialogData.index)}
      />}
      <Paper sx={{ overflow: 'hidden' }}>
        <DndContainer
          dargger
          items={reviewPolicyData.steps}
          handleDragEnd={handleDragEnd}
          ChildItem={StepRowItem}
          onStepRuleChanged={onStepRuleChanged}
          onDeleteItem={onDeleteItem}
          showSelectUserPopup={showSelectUserPopup}
          updateApproveStep={updateApproveStep}
        />
      </Paper>
    </div>
  );
})

EditReviewPolicyView.displayName = 'EditReviewPolicyView'

EditReviewPolicyView.propTypes = {
  reviewPolicy: PropTypes.object,
};

export default EditReviewPolicyView;
