import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Divider from '@mui/material/Divider';

import DateRangePickerField from 'components/DateRangePickerField';
import ContextStore from 'modules/context';
import { unwrap } from 'modules/uitls';
import { firebaseV8 } from 'modules/firebaseV8';
import ReceiptList from './ReceiptList';

function ReceiptTab({ formName, statusFilter, extFilter = false, report = false }) {
  const { tabName: _tabName } = useParams()
  const { currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const tabName = statusFilter || _tabName

  const [receipts, setReceipts] = useState([])
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  useEffect(() => {
    if (receipts.length) setReceipts([])
    const onSnapshot = snapshot => {
      const receipts = []
      snapshot.forEach(doc => {
        receipts.push({ ...unwrap(doc.data()), id: doc.id })
      });
      setReceipts(receipts)
    }
    const unsubscribe = tabName === 'pending' ?
      firebaseV8().collection(`${formName}s`).where('status', '==', tabName).onSnapshot(onSnapshot, err => {}) :
      firebaseV8().collection(`${formName}s`).where('status', '==', tabName).where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(onSnapshot, err => {})
    return () => unsubscribe()
  }, [startDate, endDate, formName]);

  const receiptsForTab = receipts.filter(r => {
    if (userRights.debugging) {
      return true
    } else if (formName === 'receipt') {
      return userRights.hasUserRightForVendor('receipt-create', r.source) ||
      userRights.hasUserRightForVendor('receipt-review', r.source) ||
      userRights.hasUserRightForVendor('receipt-overwrite', r.source)
    } else if (formName === 'borrowingReceipt') {
      return userRights.hasUserRightForVendor('borrowingReceipt-create', r.source)
    } else if (formName === 'returnBorrowingReceipt') {
      return userRights.hasUserRightForVendor('returnBorrowingReceipt-create', r.source)
    }
    return false
  });

  const reqWaitingForProcess = waitingForProcess()

  function waitingForProcess() {
    if (tabName === 'pending') {
      return receiptsForTab.filter(r => {
        const history = r.history || []
        const currentStep = history.length > 0 ? history[history.length - 1].step : 0
        if (isNaN(currentStep) || currentStep === r.steps.length) return false
        return r.steps[currentStep].users.includes(currentUser.key)
      })
    } else {
      return []
    }
  }

  return (
    <div style={{ marginTop: '20px', paddingBottom: '60px' }}>
      {tabName !== 'pending' && <DateRangePickerField
        startDate={startDate}
        endDate={endDate}
        onStartDateChanged={setStartDate}
        onEndDateChanged={setEndDate}
      />}
      {reqWaitingForProcess.length > 0 && <>
        <ReceiptList formName={formName} receipts={reqWaitingForProcess} title={`${formName}.table.${tabName}.title`} />
        <Divider sx={{ mx: '0px', my: '10px' }}/>
      </>}
      <ReceiptList formName={formName} receipts={receiptsForTab} title={`${formName}.table.title`} extFilter={extFilter} report={report} />
    </div>
  );
}

ReceiptTab.propTypes = {
  formName: PropTypes.string.isRequired,
  statusFilter: PropTypes.string,
  extFilter: PropTypes.bool,
  report: PropTypes.bool,
};

export default ReceiptTab;
