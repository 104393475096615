const i18n = {
  'appointment': {
    'title': ' ',
    'popover': {
      'NA': '無',
      'arrangeTreatments': '服務安排',
      'birth': '生日',
      'button': {
        'cancelAppointment': '取消此預約',
        'edit': '編輯預約',
        'kardex': '查看Kardex',
      },
      'gender': {
        'male': '男',
        'female': '女'
      },
      'comment': '註記',
      'salesRep': '服務人員',
      'doctor': '主治醫師',
      'nurse': '護理師',
      'phone': '聯絡電話',
      'createdAt': '預約表建立時間',
      'room': '診間',
      'yearsOld': '歲',
      'noRoom': '診間待定',
      'customerStatus': {
        'root': '客戶狀態',
        'arrived': '抵達',
        'cancelled': '取消',
        'complete': '離開',
        'late': '遲到',
        'not-confirmed': '預約待提醒',
        'cancelanotherappointment': '取消並重新預約',
        'profile-needed': '病歷準備中',
        'ready': '準備完成',
        'not-arrived': '未出現',
      }
    },
    'sidebar': {
      'reservation': '預約',
      'comment': '註記',
      'date': '日期',
      'time': '時間',
      'startTime': '開始時間',
      'endTime': '結束時間',
      'cancelled': '取消預約',
      'treatment': {
        'showAs': '顯示名稱',
        'showNickName': '顯示簡稱',
        'duration': '時間',
        'delete': '刪除'
      },
      'tab': {
        'customerInformation': '客戶基本資料',
        'arrangeTreatments': '服務安排'
      },
      'button': {
        'newCustomer': '新建客戶',
        'selectCustomer': '選擇客戶',
        'arrangeTreatments': '服務安排'
      },
      'treatmentType': {
        'product': '服務',
        'pre-consultation': '術前諮詢',
        'pre-surgery': '術前準備',
        'post-surgery': '術後',
        'return-checkup': '回診',
      },
      'noTreatments': '尚無排程',
      'minutesShort': '分',
      'customerInfo': {
        'gender': {
          'root': '性別',
          'male': '男',
          'female': '女'
        },
        'age': '年齡',
        'phone': '電話',
        'birthDate': '生日',
        'salesRep': '服務人員',
        'doctor': '醫師',
        'nurse': '護理師',
        'infoSource': {
          'root': '預約來源',
          'phone': '電話',
          'salesRep': '服務人員',
          'internets': '網路',
        },
        'room': '診間',
        'comment': '註記',
      },
      'appointmentType': {
        'root': '服務類別',
        'normalAppointment': '一般預約',
        'cancelled': '已取消'
      },
      'alert': {
        'title': {
          'noAppointmentTime': '預約時間錯誤',
          'noDoctor': '醫師欄位錯誤',
        },
        'msg': {
          'noAppointmentTime': '請確認是否填寫預約時間',
          'noDoctor': '請選擇相關科別醫師或重新選擇一般預約',
        }
      }
    },
    'noRoom': '診間待定',
    'anchor': {
      'newReservation': '新增預約',
      'newComment': '新增註記'
    },
    'editSchedules': '編輯班表',
    'list': {
      'time': '時間',
      'name': '姓名',
      'doctor': '醫師',
      'salesRep': '服務人員',
      'nurse': '護理師',
      'treatment': '服務',
    },
    'history': {
      'customer': '客戶姓名',
      'addName': '建立人員',
      'addDate': '建立日期',
      'addTime': '建立時間',
      'editName': '修改人員',
      'editDate': '修改日期',
      'editTime': '修改時間',
      'editContent': '修改內容',
      'dailog': {
        'title': '修改內容',
        'editItem': '修改項目',
        'newValue': '更改後',
        'oldValue': '更改前',
        'item': {
          'status': '預約狀態',
          'time': '預約時間',
          'doctor': '醫師',
          'date': '預約日期',
          'comment': '註記',
          'appointmentType': '診療類別'
        }
      }
    },
  }
}

export default i18n
