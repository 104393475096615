const cloneSuppliers = store => next => action => {
  if (action.type === 'SET_VENDORS') {
    return Promise.resolve(next(action)).then(store.dispatch({ type: 'SET_SUPPLIERS', payload: { data: action.payload.data, ordered: action.payload.ordered } }))
  } else if (action.type === 'MODIFY_VENDORS') {
    return Promise.resolve(next(action)).then(store.dispatch({ type: 'MODIFY_SUPPLIERS', payload: { data: action.payload.data } }))
  } else if (action.type === 'ADD_VENDORS') {
    return Promise.resolve(next(action)).then(store.dispatch({ type: 'ADD_SUPPLIERS', payload: { data: action.payload.data } }))
  } else if (action.type === 'REMOVE_VENDORS') {
    return Promise.resolve(next(action)).then(store.dispatch({ type: 'REMOVE_SUPPLIERS', payload: { data: action.payload.data } }))
  } else if (action.type === 'RESET_VENDORS') {
    return Promise.resolve(next(action)).then(store.dispatch({ type: 'RESET_SUPPLIERS', payload: {} }))
  }
  return next(action);
}

export default cloneSuppliers
