import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';

import MaskInput from 'components/MaskInput';
import { firestoreListener, callFunction } from 'modules/firebase';
import DatePickerField from './DatePickerField';
import SelectCustomerDialog from './SelectCustomerDialog';
import SelectUserDialog from './SelectUserDialog';
import ButtonProgress from './ButtonProgress';

const newMapping = {
  gender: ['male', 'female'],
  type: ['new', 'regular', 'introduced', 'employee', 'staffRelatives', 'sales', 'kol', 'pr'],
  countryCode: ['Taiwan', 'HK', 'China', 'USA', 'Japan', 'Singapore'],
  nationality: ['taiwan', 'hongkong', 'macao', 'china', 'other']
}

export default function NewCustomerDialog({ dialogTital, handleClose, customers, getData = null, defaultData = null }) {
  const { formatMessage } = useIntl()
  const [customerData, setCustomerData] = useState({
    name: defaultData ? defaultData.name : '',
    gender: '',
    countryCode: 'Taiwan',
    phone: defaultData ? defaultData.phone : '',
    type: '',
    salesRep: '',
    birthDate: '',
    employeeId: '',
    referrer: '',
    nationality: 'taiwan',
    identityCardNumber: ''
  })
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [openDialog, setOpenDialog] = useState(null)
  const [customerMapping, setCustomerMapping] = useState({})
  const config = useSelector(state => state.config.data)
  const salesReps = config.salesReps ? config.salesReps.departments : []
  const users = useSelector(state => state.users.ordered).filter(u => !u.developer)

  const filterUser = users && users.filter(u => u.active && salesReps.includes(u.department))
  const customerType = Object.keys(config.customerType || {}).filter(i => config.customerType[i].active).map(i => config.customerType[i])
  const customerExtTab = useSelector(state => state.customerExtTab.data)
  const numberRule = /[^0-9]/g

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      onData: (data) => {
        setCustomerMapping(data)
      }
    })

    return () => unsubscribe()
  }, []);

  const _fields = [
    { name: 'name', type: 'text', md: 6, sm: 6, required: true, order: 0 },
    { name: 'gender', type: 'text', md: 6, sm: 6, required: true, roots: true, select: true, order: 1 },
    { name: 'nationality', type: 'text', md: 6, sm: 6, select: true, required: true, roots: true, order: 2 },
    { name: 'identityCardNumber', type: 'text', md: 6, sm: 6, order: 3 },
    { name: 'countryCode', type: 'text', md: 6, sm: 6, roots: true, select: true, order: 4 },
    { name: 'phone', type: 'phone', md: 6, sm: 6, order: 5, allowCharacter: numberRule, },
    { name: 'birthDate', type: 'date', md: 6, sm: 6, order: 6 },
    { name: 'salesRep', type: 'salesRep', select: true, md: 6, sm: 6, order: 7 },
    { name: 'type', type: 'text', md: 6, sm: 6, required: true, roots: true, select: true, order: 8 },
  ]

  for (const c of customerType) {
    if (customerData.type === c.id) {
      if (c.requiredItems !== 'none') {
        const data = Object.keys(customerExtTab[c.requiredItems] || {}).map(i => customerExtTab[c.requiredItems][i])
        for (const d of data) {
          if(!customerData[d.id]) {
            customerData[d.id] = ''
          }
          _fields.push({ ...d, name: d.id, type: d.type, label: d.name, md: 6, sm: 6, required: true, order: 9 })
        }
      }

      if (c.items !== 'none') {
        const data = Object.keys(customerExtTab[c.items] || {}).map(i => customerExtTab[c.items][i])
        for (const d of data) {
          if(!customerData[d.id]) {
            customerData[d.id] = ''
          }
          _fields.push({ ...d, name: d.id, type: d.type, label: d.name, md: 6, sm: 6, order: 10 })
        }
      }
    }
  }


  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  async function saveCustomer() {
    setLoadingApprove(true)

    let err = {}
    let newData = customerData

    for (let field of fields) {
      if (field.required && customerData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }

    if (Object.keys(err).length > 0) {
      newData = { ...customerData, ...err }
    }
    if (Object.keys(err).length > 0) {
      setCustomerData(newData)
      return setLoadingApprove(false)
    }

    let data = {
      ...newData,
      birthDate: newData.birthDate !== '' ? dayjs(newData.birthDate).format('YYYY-MM-DD') : null,
      phone: formatPhoneNum(newData.countryCode, newData.phone),
      salesRep: newData.salesRep.id,
    }

    for (const key of Object.keys(data)) {
      if (!data[key]) {
        delete data[key]
      }
    }

    delete data.countryCode

    try {
      const res = await callFunction('saveCustomers', { id: 'new', ...data })
      if (getData && res) {
        getData(res.data)
      }

      setLoadingApprove(false)
      handleClose()

    } catch (ex) {
      console.log(ex)
      setLoadingApprove(false)
      handleClose()
    }
  }

  function formatPhoneNum(countryCode, phone) {
    let countryCodeNum = ''
    if (countryCode === 'Taiwan') {
      countryCodeNum = '+886'
    } else if (countryCode === 'HK') {
      countryCodeNum = '+852'
    } else if (countryCode === 'China') {
      countryCodeNum = '+86'
    } else if (countryCode === 'USA') {
      countryCodeNum = '+1'
    } else if (countryCode === 'Japan') {
      countryCodeNum = '+81'
    } else if (countryCode === 'Singapore') {
      countryCodeNum = '+65'
    }

    const newPhone = phone.replaceAll('-', '')
    const firstNum = newPhone.slice(0, 1)
    const phones = firstNum === '0' ? newPhone.slice(1) : newPhone

    const value = `${countryCodeNum}${phones}`

    return value
  }

  function updateData(field, value) {
    let newValue = value

    if (field.name === 'birthDate') {
      if (!value) {
        newValue = ''
      }
    }

    let err = validateField(field, value)
    let newData = { ...customerData, [field.name]: newValue, [`${field.name}_err`]: err }

    setCustomerData(newData)
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    if (field.name === 'phone' && value !== '(   )    -    #     ' && value !== '') {
      let p = value.split('#')[0].trim()
      if (p.length < 12) return formatMessage({ id: 'form.mobile.formatError' })
    }

    return ''
  }

  function onPhoneChanged(field, value) {
    updateData({ name: `${field.name}` }, value);
  }

  function createField(field) {
    let newValue = customerData[field.name]
    let labelText = ''
    let selectFields;

    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }

    if (field.name === 'identityCardNumber') {
      newValue = newValue ? newValue.toUpperCase() : ''
    }

    if (field.roots) {
      labelText = formatMessage({ id: `customer.dialog.${field.name}.roots` })
    } else if (field.label) {
      labelText = field.label
    } else {
      labelText = formatMessage({ id: `customer.dialog.${field.name}` })
    }

    if (['gender', 'countryCode', 'nationality'].includes(field.name)) {
      selectFields = newMapping[`${field.name}`]
    } else if (field.name === 'type') {
      selectFields = customerType
    }

    if (field.type === '-') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    } else if (field.type === 'date') {
      labelText = formatMessage({ id: `customer.dialog.${field.name}` })

      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <DatePickerField
          fullWidth
          closeToolbar={false}
          label={labelText}
          value={newValue !== '' ? dayjs(newValue) : null}
          onChange={date => updateData({ name: `${field.name}` }, date)}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    } else if (field.type === 'text') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          required={field.required}
          label={labelText}
          value={newValue}
          fullWidth
          select={field.select ? field.select : null}
          size="small"
          variant="outlined"
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
          error={customerData[`${field.name}_err`] ? true : false}
          helperText={customerData[`${field.name}_err`]}
        >
          {
            field.select && selectFields.map((select, index) => {
              if (field.name === 'type') {
                return <MenuItem key={`${select.id}${index}`} value={select.id}>{select.name}</MenuItem>
              } else {
                return <MenuItem key={`${select}${index}`} value={select}>{formatMessage({ id: `customer.dialog.${field.name}.${select}` })}</MenuItem>
              }
            })
          }
        </TextField>
      </Grid>
    } else if (field.type === 'salesRep') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          required={field.required}
          label={labelText}
          value={newValue}
          fullWidth
          select={field.select}
          size="small"
          variant="outlined"
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
        >
          {
            filterUser.map((user, index) => {
              return <MenuItem key={`${user.id}${index}`} value={user}>{user.displayName}</MenuItem>
            })
          }
        </TextField>
      </Grid>
    } else if (['time', 'phone', 'moblie'].includes(field.type)) {
      const newMask = field.type === 'time' ? '99:99' : field.type === 'phone' ? '9999-999-999' : '(999)9999-9999#99999'
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <MaskInput
          mask={newMask}
          maskChar=" "
          onChange={e => { onPhoneChanged(field, e.target.value) }}
          value={customerData[field.name]}
          label={labelText}
          helperText={customerData[`${field.name}_err`]}
        />
      </Grid>
    } else if (field.type === 'dialog') {
      if (field.dialog === 'customers') {
        newValue = customerMapping[newValue]?.name ?? ''
      }
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          type="text"
          required={field.required}
          label={labelText}
          fullWidth
          size="small"
          variant="outlined"
          value={newValue}
          onClick={() => setOpenDialog({ type: field.dialog, field: field })}
          error={customerData[`${field.name}_err`] ? true : false}
          helperText={customerData[`${field.name}_err`]}
        />
      </Grid>
    } else if (field.type === 'select') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          type="text"
          required={field.required}
          label={labelText}
          fullWidth
          size="small"
          variant="outlined"
          value={newValue}
          select
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
          error={customerData[`${field.name}_err`] ? true : false}
          helperText={customerData[`${field.name}_err`]}
        >
          {Object.keys(field.counts).map(i => field.counts[i]).map((count, index) => {
            return <MenuItem key={`${count}${index}`} value={count}>{count}</MenuItem>
          })}
        </TextField>
      </Grid>
    }
  }

  const handleDialogSave = (data) => {
    const newValue = openDialog.type === 'customers' ? data.id : data.employeeId
    updateData({ name: openDialog.field.name }, newValue)
  }

  return (
    <div style={{ backgroundColor: '#f2f2f2' }}>
      {openDialog && openDialog.type === 'customers' && <SelectCustomerDialog
        handleClose={() => setOpenDialog(null)}
        handleSave={handleDialogSave}
        customers={customers}
        dialogTitle={formatMessage({ id: 'selectCustomerDialog.title' })}
      />}
      {openDialog && openDialog.type === 'users' && <SelectUserDialog
        headerCells={[{ name: 'displayName', sort: 'displayName' }, { name: 'employeeId', sort: 'employeeId' }]}
        rowCells={[{ field: 'displayName' }, { field: 'employeeId' }]}
        filterItems={[{ name: 'displayName' }, { name: 'employeeId' }]}
        dialogTitle={formatMessage({ id: 'selectEmployeeIdDialog.title' })}
        handleClose={() => setOpenDialog(null)}
        handleSave={handleDialogSave}
        items={users.filter(u => u.active && u.employeeId !== customerData.employeeId)}
      />}
      <Dialog
        fullWidth={true}
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTital}</DialogTitle>
        <DialogContent sx={{ minHeight: '410px' }}>
          <Grid sx={{ pt: '8px' }} container spacing={2}>
            {fields.map(field => createField(field))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonProgress
            handleClose={handleClose}
            handleClick={() => saveCustomer()}
            loading={loadingApprove}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

NewCustomerDialog.propTypes = {
  dialogTital: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  customers: PropTypes.arrayOf(PropTypes.object).isRequired,
  getData: PropTypes.func,
  defaultData: PropTypes.object
};
