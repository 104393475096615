import { useSelector } from 'react-redux';

const useModuleMapping = () => {
  const config = useSelector(state => state.config?.data)
  // console.log(config)
  const moduleMapping = { ...(config.modules || {}), setting: true }

  return moduleMapping
}

const useModules = () => {
  const config = useSelector(state => state.config?.data)
  // console.log(config)
  return Object.keys({ ...(config.modules || {}), setting: true }).filter(m => config.modules[m]).sort()
}

export {
  useModules,
  useModuleMapping,
};
