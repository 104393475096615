import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';

import SecondaryNav from 'containers/SecondaryNav/SecondaryNav';
import ContextStore from 'modules/context';

function Leaves({ type }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: `sideMenu.leaveOvertimeWork.${type}` })
    }])
    return () => {
    };
  }, [type]);

  return (
    <Box p={3}>
      <SecondaryNav />
    </Box>
  );
}

Leaves.propTypes = {
  type: PropTypes.string.isRequired
};

export default Leaves;
