import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Collapse from '@mui/material/Collapse';

import ActionDialog from 'components/ActionDialog';
import EnhancedTable from 'components/EnhancedTable';
import SimpleTableToolbar from 'components/SimpleTableToolbar';
import SearchBox from 'components/SearchBox';
import ExpandButton from 'components/ExpandButton';
import DelectIconButton from 'components/DelectIconButton'
import ContextStore from 'modules/context';
import { callFunction } from 'modules/firebase';
import AnnouncementFullView from './AnnouncementFullView';
import AnnouncementView from './AnnouncementView';

function AnnouncementList({ announcements, title, canExpand, showActions, showVoidButton }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const [viewData, setViewData] = useState(null)
  const [dialogData, setDialogData] = useState(null);
  const [loading, setLoading] = useState(false);

  const userMapping = useSelector(state => state.users.data)
  const vendorMapping = useSelector(state => state.internalVendors.data)

  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);
  const filteredAnnouncements = currentFilter && currentFilter.text ? filterByText() : announcements

  const headerCells = [
    { text: 'date', sort: 'date' },
    { text: 'target' },
    { text: 'subject', sort: 'subject' },
    { text: 'createby', sort: 'createby' },
  ].map(c => { c.text = formatMessage({ id: `announcement.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'date', account: true },
    { field: 'targets' },
    { field: 'subject' },
    { field: 'name' },
  ]

  function filterByText(items) {
    if (currentFilter.name === 'createby') {
      return items.filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'subject') {
      return items.filter(s => s.subject.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else {
      return items.filter(s => s.date.toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  const formatData = (user) => {
    const newData = { ...user }
    newData.name = userMapping[newData.createdBy].displayName
    newData.targets = newData.source ? vendorMapping[newData.source].name : (newData.target || []).map(i => vendorMapping[i].name).join(', ')
    return newData
  }

  const filterItems = [
    { name: 'date' },
    { name: 'subject' },
    { name: 'createby' },
  ].map(i => { i.text = formatMessage({ id: `announcement.table.${i.name}` }); return i })

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function view(announcement) {
    if (announcement.v2) {
      setViewData(announcement)
    } else {
      window.open(announcement?.url, '_blank')
    }
  }

  function revoke(announcement) {
    setDialogData({ action: 'revoke', title: '', data: announcement })
  }

  async function handleExecute(data) {
    const { action, text, id } = data
    setDialogData(null)
    setLoading(true)
    try {
      await callFunction('reviewAnnouncement', {
        id: id,
        action: action,
        note: text
      })
      setLoading(false)
    } catch (ex) {
      setLoading(false)
      console.log(ex)
    }
  }

  return (
    <div>
      <AnnouncementFullView onClose={() => setViewData(null)} data={viewData}></AnnouncementFullView>
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` }) + formatMessage({ id: 'announcement.name' })}
        handleClose={() => setDialogData(null)}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'announcement.table.note' })}
        action={dialogData.action}
        data={dialogData.data}
      />}
      <SimpleTableToolbar
        title={title}
        buttons={<ExpandButton open={expand} onExpandChange={setExpand} />}
        toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
      />
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <EnhancedTable
          defaultOrder="desc"
          defaultOrderField="sn"
          headerCells={headerCells}
          rowCells={rowCells}
          onRowClick={canExpand ? null : (announcement) => view(announcement)}
          getExpandContent={canExpand ? announcement =>
            <AnnouncementView
              currentUser={currentUser}
              userMapping={userMapping}
              // merchandiseMapping={merchandiseMapping}
              announcement={announcement}
              showActions={showActions}
              onView={() => setViewData(announcement)}
            />
            : null}
          getActionIcons={showVoidButton ? announcement =>
            announcement.status === 'done' && announcement.target.length === 1 && userRights.hasUserRightForVendor('announcement-overwrite', announcement.target[0]) ? <DelectIconButton
              disabled={loading}
              text={formatMessage({ id: 'button.revoke' })}
              onClick={() => revoke(announcement)}
            /> : <div></div>
            : null}
          tableData={filteredAnnouncements.map(r => formatData(r))}
        />
      </Collapse>
    </div>
  );
}

AnnouncementList.propTypes = {
  announcements: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  title: PropTypes.string.isRequired,
};

export default AnnouncementList;
