import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import EnhancedButton from 'components/EnhancedButton';
import EnhancedTable from 'components/EnhancedTable';
import ButtonContainer from 'components/ButtonContainer';
import { useModuleMapping } from 'hooks/modules';

function ConsumedServiceView({ userRights, form, onEditClick, onVoidClick, currentCompany  }) {
  const { formatMessage } = useIntl()
  const productMapping = useSelector(state => state.products.data)
  const merchandiseMapping = useSelector(state => state.merchandises.data)
  const userMapping = useSelector(state => state.users.data)
  const moduleMapping = useModuleMapping()

  const products = Object.keys(form.services).map(k => ({ ...form.services[k], id: k })).sort((a, b) => a.order - b.order)
  const merchandises = Object.keys(form.merchandises).map(k => ({ ...form.merchandises[k], id: k })).sort((a, b) => a.order - b.order)

  const _productHeaderCells = [
    { text: 'name', order: 0 },
    { text: 'amount', order: 1 },
    { text: 'agent', order: 2 },
    { text: 'note', order: 4 },
  ]

  const _productRowCells = [
    { field: 'name', order: 0 },
    { field: 'amount', order: 1 },
    { field: 'agent', order: 2 },
    { field: 'note', order: 4 },
  ]

  if (moduleMapping.doctor) {
    _productHeaderCells.push({ text: 'doctor', order: 3 })
    _productRowCells.push({ field: 'doctor', order: 3 })
  }
  const productHeaderCells = _productHeaderCells.sort((a, b) => a.order - b.order).map(c => { c.text = formatMessage({ id: `consumedService.service.${c.text}` }); return c })
  const productRowCells = _productRowCells.sort((a, b) => a.order - b.order)

  const merchandiseHeaderCells = [
    { text: 'name', order: 0 },
    { text: 'consumeAmount', order: 1 },
    { text: 'sku', order: 2 },
  ].map(c => { c.text = formatMessage({ id: `consumedService.merchandise.${c.text}` }); return c })


  const merchandiseRowCells = [
    { field: 'nickname', tooltip: 'name', order: 0 },
    { field: 'consumeAmount', order: 1 },
    { field: 'sku', order: 2 },
  ]

  function formatData(product) {
    const newData = { ...product }
    newData.name = productMapping[product.productId].name
    newData.agent = newData.agent.map(a => userMapping[a]?.displayName || '').join(', ')
    newData.doctor = userMapping[newData.doctor]?.displayName || ''
    return newData
  }

  function formatMerchandiseData(merchandise) {
    const newData = { ...merchandise }
    newData.name = merchandiseMapping[newData.merchandise].name
    newData.nickname = merchandiseMapping[newData.merchandise].nickname
    newData.sku = merchandiseMapping[newData.merchandise].sku

    return newData
  }

  return (
    <div style={{ width: '100%', }}>
      <Box p={0}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 500 }} component="div">
                服務列表:
              </Typography>
            </div>

            <EnhancedTable
              size="small"
              headerCells={productHeaderCells}
              rowCells={productRowCells}
              tableData={products.map(i => formatData(i))}
            />
          </Grid>
          {merchandises && merchandises.length > 0 && <Grid item xs={12} sm={12} md={12}>
            <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 500 }} component="div">
                消耗商品列表:
              </Typography>
            </div>

            <EnhancedTable
              size="small"
              headerCells={merchandiseHeaderCells}
              rowCells={merchandiseRowCells}
              tableData={merchandises.map(i => formatMerchandiseData(i))}
            />
          </Grid>}
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'consumedService.table.detail.note' })}
              value={form.note}
              multiline
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          {!form.void && <Grid item xs={12} sm={12} md={12}>
            <ButtonContainer>
              {onVoidClick && userRights.hasUserRightForVendor('consumeService-void', currentCompany) && <EnhancedButton
                onClick={() => onVoidClick(form)}
                label={formatMessage({ id: 'button.void' })}
                color='error'
              />}
              {onEditClick && userRights.hasUserRightForVendor('consumeService-edit', currentCompany) && <EnhancedButton
                onClick={() => onEditClick(form)}
                label={formatMessage({ id: 'button.edit' })}
              />}
            </ButtonContainer>
          </Grid>}
        </Grid>
      </Box>
    </div>
  )
}

ConsumedServiceView.propTypes = {
  userRights: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onEditClick: PropTypes.func,
  onVoidClick: PropTypes.func,
  currentCompany: PropTypes.string
};

export default ConsumedServiceView