import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import SimpleTableToolbar from 'components/SimpleTableToolbar';
import SearchBox from 'components/SearchBox';
import ExpandButton from 'components/ExpandButton';
import EnhancedTableRow from 'components/EnhancedTableRow';
import EnhancedTableHead from 'components/EnhancedTableHead';
import DateRangePickerField from 'components/DateRangePickerField';
import { objectToArray } from 'modules/data';
import { getComparator, stableSort } from 'modules/sort';
import ContextStore from 'modules/context';
import { firestoreListener } from 'modules/firebase';

const dailogStyle = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'red',
  width: '100%',
}

const dailogTable = {
  fontFamily: 'Roboto, sans-serif',
  fontSize: '15px'
}

function ReviewEditHistory({ data, handleClose, userMapping }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('editItem')
  const newData = []

  for (const x of Object.keys(data.newValue)) {
    newData.push({
      item: x,
      old: data.oldValue[x],
      new: data.newValue[x]
    })
  }

  const headerCells = [
    { text: 'editItem', sort: 'itemName' },
    { text: 'oldValue', sort: 'oldValue' },
    { text: 'newValue', sort: 'newValue' },

  ].map(c => { c.text = formatMessage({ id: `appointment.history.dailog.${c.text}` }); return c })

  const rowCells = [
    { field: 'itemName' },
    { field: 'oldValue' },
    { field: 'newValue' },
  ]

  const formatData = (data) => {
    const newData = { ...data }
    newData.itemName = formatMessage({ id: `appointment.history.dailog.item.${newData.item}` })

    if (newData.item === 'status') {
      newData.oldValue = formatMessage({ id: `appointment.popover.customerStatus.${newData.old}` })
      newData.newValue = formatMessage({ id: `appointment.popover.customerStatus.${newData.new}` })
    } else if (newData.item === 'appointmentType') {
      newData.oldValue = formatMessage({ id: `appointment.sidebar.appointmentType.${newData.old}` })
      newData.newValue = formatMessage({ id: `appointment.sidebar.appointmentType.${newData.new}` })
    } else if (newData.item === 'doctor') {
      newData.oldValue = userMapping[newData.old]?.displayName ?? ''
      newData.newValue = userMapping[newData.new]?.displayName ?? ''
    } else {
      newData.oldValue = newData.old
      newData.newValue = newData.new
    }


    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <div style={dailogStyle}>
      <Dialog
        fullWidth={true}
        open={true}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">{formatMessage({ id: 'appointment.history.dailog.title' })}</DialogTitle>
        <div style={dailogTable}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={newData.length}
              />
              <TableBody>
                {stableSort(newData.map(r => formatData(r)), getComparator(order, orderBy)).map((item, i) => (
                  <EnhancedTableRow
                    key={i}
                    rowCells={rowCells}
                    cellData={item}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Dialog>
    </div>
  );
}

ReviewEditHistory.propTypes = {
  data: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  userMapping: PropTypes.object
};

function AppointmentEditHistory() {
  const { setBreadcrumbs } = useContext(ContextStore);
  const { formatMessage } = useIntl();
  const location = useLocation();

  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('editDate')
  const [customerList, setCustomerList] = useState({})
  const [appointmentLists, setAppointmentLists] = useState([])
  const userMapping = useSelector(state => state.users.data);
  const NaStr = formatMessage({ id: 'appointment.popover.NA' })
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'weeks').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [dailog, setDailog] = useState(null)

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      onData: (data) => {
        setCustomerList(data)
      }
    })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'appointmentsEditHistory',
      where: [['filterDate', '>=', startDate], ['filterDate', '<=', endDate]],
      mapping: true,
      onData: (data) => {
        setAppointmentLists(data)
      }
    })

    return () => unsubscribe()
  }, [startDate, endDate]);


  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.appointments.editList' })
    }])
    return () => {
    };
  }, [location.pathname]);

  const headerCells = [
    { text: 'customer', sort: 'customer' },
    { text: 'addName', sort: 'createdBy' },
    { text: 'addDate', sort: 'createdAt' },
    { text: 'addTime', sort: 'createdAt' },
    { text: 'editName', sort: 'updatedBy' },
    { text: 'editDate', sort: 'updatedAt' },
    { text: 'editTime', sort: 'updatedAt' },

  ].map(c => { c.text = formatMessage({ id: `appointment.history.${c.text}` }); return c })

  const rowCells = [
    { field: 'customerName' },
    { field: 'addName' },
    { field: 'addDate' },
    { field: 'addTime' },
    { field: 'editName' },
    { field: 'editDate' },
    { field: 'editTime' },
  ]

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };



  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const filterItems = [
    { name: 'customer' },
    { name: 'addName' },
    { name: 'addDate' },
    { name: 'editName' },
    { name: 'editDate' },
  ].map(i => { i.text = formatMessage({ id: `appointment.history.${i.name}` }); return i })


  const formatData = (data) => {
    const newData = { ...data }

    newData.customerName = customerList[newData.customer] ? customerList[newData.customer]?.name : '待確認'
    newData.addName = userMapping[newData.createdBy] ? userMapping[newData.createdBy].displayName : NaStr
    newData.addDate = newData.createdAt ? dayjs(newData.createdAt.seconds * 1000).format('YYYY-MM-DD') : NaStr
    newData.addTime = newData.createdAt ? dayjs(newData.createdAt.seconds * 1000).format('HH:mm:ss') : NaStr
    newData.editName = userMapping[newData.updatedBy] ? userMapping[newData.updatedBy].displayName : NaStr
    newData.editDate = newData.updatedAt ? dayjs(newData.updatedAt.seconds * 1000).format('YYYY-MM-DD') : NaStr
    newData.editTime = newData.updatedAt ? dayjs(newData.updatedAt.seconds * 1000).format('HH:mm:ss') : NaStr

    return newData
  }

  const filteredCustomer = objectToArray(appointmentLists).sort((a, b) => {
    return a.hour - b.hour
  })

  const filtereAppointments = currentFilter && currentFilter.text ? filterByText() : filteredCustomer

  function filterByText() {
    if (currentFilter.name === 'customer') {
      return filteredCustomer.filter(s => customerList[s.customer] && customerList[s.customer]?.name.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'addName') {
      return filteredCustomer.filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'addDate') {
      return filteredCustomer.filter(s => dayjs(s.createdAt.seconds * 1000).format('YYYY-MM-DD').toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'editName') {
      return filteredCustomer.filter(s => userMapping[s]?.displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'editDate') {
      return filteredCustomer.filter(s => dayjs(s.updatedAt.seconds * 1000).format('YYYY-MM-DD').toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  const handleDailogClose = () => {
    setDailog(null)
  }

  return (
    <div style={{ width: '100%', padding: '40px 20px', fontFamily: 'PingFang TC,Roboto,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif' }}>
      {dailog && <ReviewEditHistory
        data={dailog.data}
        handleClose={handleDailogClose}
        userMapping={userMapping}
      />}
      <Grid container spacing={1}>
        <Grid container spacing={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ml: '20px' }}>
          <Grid item xs={6} sm={6} md={6}>
            <DateRangePickerField
              startDate={startDate}
              endDate={endDate}
              onStartDateChanged={setStartDate}
              onEndDateChanged={setEndDate}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <SimpleTableToolbar
              title={'appointment.title'}
              bottons={<ExpandButton open={expand} onExpandChange={setExpand} />}
              toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={filtereAppointments.length}
              />
              <TableBody>
                {stableSort(filtereAppointments.map(r => formatData(r)), getComparator(order, orderBy)).map(list => (
                  <EnhancedTableRow
                    key={list.id}
                    rowCells={rowCells}
                    cellData={list}
                    onRowClick={() => setDailog({ data: list })}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}

export default AppointmentEditHistory;
