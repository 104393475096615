import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

import EnhancedTable from 'components/EnhancedTable';

function SourceOrderDetail({ userMapping, merchandiseMapping, sourceOrder: rawSourceOrder, dialogName }) {
  const { formatMessage } = useIntl()
  const sourceOrder = { ...rawSourceOrder }
  const keys = rawSourceOrder.merchandises ? Object.keys(rawSourceOrder.merchandises) : []
  sourceOrder.merchandises = keys.map(k => ({ ...sourceOrder.merchandises[k], id: k }))
  sourceOrder.createdBy = { id: sourceOrder.createdBy, name: userMapping[sourceOrder.createdBy]?.displayName }

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'nickname' },
    { text: 'orderUnit', align: 'right' },
    // {text: 'unitPrice', align: 'right'},
    { text: 'amount', align: 'right' },
    { text: 'delivery', align: 'right' }
    // {text: 'price', align: 'right'},
  ].map(c => {c.text = formatMessage({ id: `${dialogName}.merchandise.${c.text}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    { field: 'orderUnit', align: 'right' },
    // {field: 'unitPrice', align: 'right'},
    { field: 'amount', align: 'right' },
    { field: 'delivery', align: 'right' }
    // {field: 'price', align: 'right', type: 'calculate', calculate: 'unitPrice*amount'},
  ]

  function formatData(merchandise, delivery) {
    const newData = {
      ...merchandise,
      code: merchandiseMapping[merchandise.id].code,
      name: merchandiseMapping[merchandise.id].name,
      nickname: merchandiseMapping[merchandise.id].nickname,
    }
    newData.require = newData.amount + newData.orderUnit
    if (newData.moq && newData.amount < newData.moq) newData.amount = newData.moq
    newData.delivery = delivery ? (delivery[merchandise.id] || 0) : 0
    return newData
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Box p={0}>
        <EnhancedTable
          size="small"
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={headerCells}
          rowCells={rowCells}
          tableData={sourceOrder.merchandises.map(m => formatData(m, sourceOrder.delivery))}
        />
      </Box>
    </div>
  );
}

SourceOrderDetail.propTypes = {
  sourceOrder: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
  dialogName: PropTypes.string.isRequired,
};

function SelectSourceOrderDialog({ defaultSelectedItem, handleClose, handleSave, sourceOrders, ignoreIds, receiptItemMapping, refId, refSn }) {
  const { formatMessage } = useIntl()

  const userMapping = useSelector(state => state.users.data)
  const customerMapping = useSelector(state => state.internalVendors.data)
  const merchandiseMapping = useSelector(state => state.merchandises.data)
  const supplierMapping = useSelector(state => state.suppliers.data)

  const [selectedItem, setSelectedItem] = useState(defaultSelectedItem || '');

  let dialogName = ''
  let sourceOrderName = ''
  if (refId === 'purchaseOrderId') {
    dialogName = 'selectPurchaseOrderDialog'
    sourceOrderName = 'purchaseOrder'
  } else {
    dialogName = 'selectBorrowingOrderDialog'
    sourceOrderName = 'borrowingOrder'
  }

  const filteredSourceOrders = sourceOrders.filter(p => {
    const keys = Object.keys(p.merchandises)
    for (const key of keys) {
      const fullId = `${p.id}@${key}`
      if (ignoreIds.includes(fullId)) continue
      const merchandise = p.merchandises[key]
      const amountInReceipt = receiptItemMapping[fullId] || 0
      if (merchandise.amount > amountInReceipt) return true
    }
    return false
  })

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'supplier', sort: 'supplier' },
    { text: 'source', sort: 'source' },
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'date', sort: 'createdAt' },
    { text: 'expectedDate', sort: 'expectedDate' },
  ].map(c => {c.text = formatMessage({ id: `${sourceOrderName}.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'sn' },
    { field: 'supplier' },
    { field: 'sourceName' },
    { field: 'createdBy' },
    { field: 'date' },
    { field: 'expectedDate' },
  ]

  const formatData = (sourceOrder) => {
    const newData = { ...sourceOrder }
    newData.sourceName = customerMapping[newData.source]?.nickname
    newData.createdBy = userMapping[newData.createdBy]?.displayName
    newData.supplier = supplierMapping[newData.supplier]?.nickname
    return newData
  }

  const handleRadioButtonClick = (id) => {
    setSelectedItem(id);
  }

  const onApply = () => {
    const merchandises = []
    const sourceOrder = filteredSourceOrders.find(p => p.id === selectedItem)

    const keys = Object.keys(sourceOrder.merchandises)
    for (const key of keys) {
      const merchandise = sourceOrder.merchandises[key]
      const amountInReceipt = receiptItemMapping[`${sourceOrder.id}@${key}`] || 0
      const maxAmount = merchandise.amount - amountInReceipt
      if (maxAmount > 0 && !ignoreIds.includes(`${sourceOrder.id}@${key}`)) {
        merchandises.push({
          id: key,
          // maxAmount,
          amount: maxAmount,
          orderBySku: merchandise.orderBySku || false,
          orderUnit: merchandise.orderUnit,
          unitPrice: merchandise.unitPrice,
          [refId]: sourceOrder.id,
          [refSn]: sourceOrder.sn,
          poAmount: merchandise.amount
        })
      }
    }

    handleSave(merchandises)
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title"><FormattedMessage id={`${dialogName}.title`} /></DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1 }}>
          <EnhancedTable
            defaultOrder="asc"
            defaultOrderField="supplier"
            headerCells={headerCells}
            rowCells={rowCells}
            tableData={filteredSourceOrders.map(p => formatData(p))}
            onRadioButtonClick={({ id }) => handleRadioButtonClick(id)}
            getRowCheckBoxStatus={sourceOrder => selectedItem === sourceOrder.id}
            getRowExpandedStatus={() => filteredSourceOrders.length === 1}
            getExpandContent={sourceOrder =>
              <SourceOrderDetail
                userMapping={userMapping}
                merchandiseMapping={merchandiseMapping}
                sourceOrder={sourceOrder}
                dialogName={dialogName}
              />
            }
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" onClick={onApply} disabled={!selectedItem} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectSourceOrderDialog.propTypes = {
  receiptItemMapping: PropTypes.object.isRequired,
  sourceOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
  ignoreIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultSelectedItem: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  refId: PropTypes.string.isRequired,
  refSn: PropTypes.string.isRequired,
};

export default SelectSourceOrderDialog;
