import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { arrayMove } from '@dnd-kit/sortable';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';

import DndContainer from 'components/DndContainer';
import DragHandle from 'components/DragHandle';
import { callFunction } from 'modules/firebase';
import { getRandomHash } from 'modules/uitls';

const rowStyle = {
  borderTop: 'solid 1px #efefef',
  width: '100%',
  height: '80px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#fff',
  padding: '0px 16px 0px 8px',
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
  zIndex: 10000,
}

const cellStyle = {
  marginLeft: '8px',
  marginRight: '8px',
  flexGrow: 2
}

function SortableItem({ item, index, onChange, fields, extData }) {
  const { formatMessage } = useIntl()
  const { tabName } = useParams()

  function createField(field, fieldType) {
    if (field.type === 'text') {
      return (
        <Grid item key={field.name} xs={field.xs}>
          <TextField
            required={field.required}
            type="text"
            size="small"
            label={formatMessage({ id: `setCustomer.edit.${tabName}.${fieldType}.${field.name}` })}
            variant="outlined"
            value={item[field.name]}
            onChange={e => onChange(index, field, e.target.value)}
            error={item[`${[field.name]}_err`] ? true : false}
            helperText={item[`${[field.name]}_err`]}
            fullWidth
          />
        </Grid>
      );
    } else if (field.type === 'select-text') {
      let newExtData = []
      if (field.name === 'requiredItems') {
        newExtData = extData.filter(i => i.id !== item.items)
      } else if (field.name === 'items') {
        newExtData = extData.filter(i => i.id !== item.requiredItems)
      }


      return (
        <Grid item key={field.name} xs={field.xs}>
          <TextField
            type="text"
            label={formatMessage({ id: `setCustomer.edit.${tabName}.${fieldType}.${field.name}` })}
            fullWidth
            size="small"
            variant="outlined"
            value={item[field.name]}
            select
            onChange={e => { onChange(index, field, e.target.value) }}
          >
            {[{ name: '無', id: 'none' }].concat(newExtData).map((data, index) => {
              return <MenuItem key={index} value={data.id}>{data.name}</MenuItem>
            })}
          </TextField>
        </Grid>
      )
    }
  }

  return (
    <div style={rowStyle}>
      <DragHandle />
      <div style={cellStyle}>
        <Grid container spacing={3}>
          {fields.map(field => createField(field, 'table'))}
          <Grid item xs={3}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
              {tabName === 'extData' && <>
                <div>一般</div>
                <Switch
                  checked={item.related}
                  onChange={e => onChange(index, { name: 'related' }, e.target.checked)}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <div>關聯</div>
              </>}
              <Switch
                checked={item.active}
                onChange={e => onChange(index, { name: 'active' }, e.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <div>啟用</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

SortableItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object.isRequired),
  extData: PropTypes.array
};

const EditSetCustomer = () => {
  const navigate = useNavigate()
  const { tabName } = useParams()
  const { formatMessage } = useIntl()
  const config = useSelector(state => state.config.data)
  const defaultData = tabName === 'extData' ?
    Object.keys(config.customerExtTab || {}).map(i => config.customerExtTab[i]) :
    Object.keys(config[tabName] || {}).map(i => config[tabName][i])
  const [currentData, setCurrentData] = useState(defaultData)
  const [loading, setLoading] = useState(false)
  const extData = Object.keys(config.customerExtTab || {}).map(i => config.customerExtTab[i].active && config.customerExtTab[i]).filter(i => i.related)

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = currentData.findIndex(e => e.id === active.id);
      const newIndex = currentData.findIndex(e => e.id === over.id);
      setCurrentData(arrayMove(currentData, oldIndex, newIndex))
    }
  }

  const fields = tabName === 'extData' ? [
    { name: 'name', type: 'text', xs: 3, required: true },
    { name: 'note', type: 'text', xs: 5 },
  ] : [
    { name: 'name', type: 'text', xs: 3, required: true },
    { name: 'requiredItems', type: 'select-text', xs: 3 },
    { name: 'items', type: 'select-text', xs: 3 },
  ]

  function updateCurrentData(index, field, value) {
    let newValue = value
    const data = tabName === 'extData' ? fields.reduce((acc, cur) => {
      acc[cur.name] = currentData[index][cur.name]
      return acc
    }, { active: currentData[index].active, id: currentData[index].id, related: currentData[index].related }) :
      fields.reduce((acc, cur) => {
        acc[cur.name] = currentData[index][cur.name]
        return acc
      }, { active: currentData[index].active, id: currentData[index].id })

    if (field.allowCharacter) {
      newValue = Number(newValue.replace(field.allowCharacter, ''))
    }

    if (field.required && value.trim() === '') {
      data[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
    }

    data[field.name] = newValue
    const newData = [...currentData]
    newData[index] = data

    setCurrentData(newData)
  }

  function addItems() {
    let newItem = {}

    newItem = tabName === 'extData' ? {
      name: '新分類',
      note: '',
      id: getRandomHash(),
      active: true,
      related: false
    } : {
      name: '新類型',
      note: '',
      requiredItems: 'none',
      items: 'none',
      id: getRandomHash(),
      active: true,
    }

    setCurrentData([...currentData, newItem])
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }
    return ''
  }

  async function handleSave() {
    setLoading(true)
    const type = tabName === 'extData' ? 'customerExtTab' : 'customerType'
    let err = false

    for (const data of currentData) {
      for (let field of fields) {
        const errMsg = validateField(field, data[field.name])
        if (errMsg) {
          data[`${field.name}_err`] = errMsg
          err = true
        }
      }
    }

    if (err) {
      setCurrentData([...currentData])
      setLoading(false)
      return
    }

    let newData = []
    for (const data of currentData) {
      newData.push(data)
    }

    try {
      await callFunction('saveConfigurations', { type: type, ...newData })
    } catch (ex) {
      console.log(ex)
    }
    handleClose()
  }

  function handleClose() {
    navigate(`/setting/customer/${tabName}`);
  }

  const formatData = (data) => {
    const newData = { ...data }

    return newData
  }

  function getToolbox() {
    return (
      <div>
        <Button
          sx={{ m: 1, whiteSpace: 'nowrap' }}
          variant="contained"
          color="primary"
          onClick={() => addItems()}
        >
          <FormattedMessage id={`setCustomer.edit.${tabName}.add`} />
        </Button>
      </div>
    )
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography sx={{ flex: '1 1 20%' }} variant="h6" id="tableTitle" component="div">
            <FormattedMessage id={`setCustomer.edit.${tabName}.title`} />
          </Typography>
          {getToolbox()}
        </div>
        <Paper sx={{ overflow: 'hidden' }}>
          <DndContainer
            dargger
            items={currentData.map(i => formatData(i))}
            handleDragEnd={handleDragEnd}
            ChildItem={SortableItem}
            onChange={updateCurrentData}
            fields={fields}
            extData={extData}
          />
        </Paper>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={loading}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.save" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}

export default EditSetCustomer;
