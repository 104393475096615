const mapping = {
  // appointment: ['*appointment-view', 'appointment-edit'],
  customer: ['customer-create', 'customer-edit', 'customer-view'],
  // finance: ['finance-view', 'finance-edit', 'salary-edit'],
  // leaveOvertime: ['leaveOvertime-overview'],
  product: ['product-create', 'product-edit', 'product-view'],
  // punchClock: ['punchClockRevise-overview'],
  // sales: [
  //   'salesOrder-create', 'salesOrder-edit', 'salesOrder-view', 'salesOrder-void',
  //   'refund-create', 'refund-edit', 'refund-view',
  // ],
  // schedule: ['exception-edit', 'schedule-edit'],
  // service: [
  //   'consumeService-create', 'consumeService-edit', 'consumeService-view', 'consumeService-void',
  //   'transferService-create', 'transferService-view',
  //   'transformService-create', 'transformService-view'
  // ],
  // report: ['report-view', 'report-edit'],
  setting: ['setting-edit'],
  user: ['user-view', 'user-edit'],
  vendor: ['vendor-create', 'vendor-edit', 'vendor-view', 'workflow-edit'],
  merchandise: ['merchandise-create', 'merchandise-edit', 'merchandise-view'],
}

function getUserRightGroups(modules) {
  const userRightGroups = modules
    .reduce((acc, cur) => {
      acc[cur] = [...mapping[cur] || []]
      return acc
    }, {})
  return userRightGroups
}

function getVendorUserRight(modules) {
  const vendorUserRightMapping = {
    stock: ['query', 'inventory', 'returnForm'],
    appointment: ['view', 'edit'],
    finance: ['view', 'edit', 'salary'],
    leaveOvertime: ['overview'],
    schedule: ['view', 'edit', 'exception'],
    salesOrder: ['view', 'create', 'edit', 'void'],
    refund: ['view', 'create', 'edit'],
    referrer: ['view', 'create', 'edit', 'void'],
    consumeService: ['view', 'create', 'edit', 'void'],
    transferService: ['view', 'create'],
    transformService: ['view', 'create'],
    punchClockRevise: ['overview'],
    report: ['view', 'edit'],
  }

  if (modules.includes('doctor')) {
    vendorUserRightMapping.schedule = vendorUserRightMapping.schedule.concat(['doctorPunchClock', 'doctorEdit'])
  }

  return vendorUserRightMapping
}

export {
  getUserRightGroups,
  getVendorUserRight,
};
