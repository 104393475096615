function getConfig(env) {
  let config = {}
  if (env === 'staging') {
    config = {
      apiKey: 'AIzaSyCm-g6Ny4eiLKU1tD12NdPydh1Avmxilcs',
      authDomain: 'jack3220-demo.firebaseapp.com',
      projectId: 'jack3220-demo',
      storageBucket: 'jack3220-demo.appspot.com',
      messagingSenderId: '510751109698',
      appId: '1:510751109698:web:8f3b847997dd3002a173db',
    // databaseURL: 'https://jack3220-demo-default-rtdb.firebaseio.com'
    }
  } else if (env === 'be-elegant') {
    config = {
      apiKey: 'AIzaSyDXVUtbrWJ5uZgvjEWbWBgEkr6rY8GqgeE',
      authDomain: 'be-elegant-clinic.firebaseapp.com',
      projectId: 'be-elegant-clinic',
      storageBucket: 'be-elegant-clinic.appspot.com',
      messagingSenderId: '697144931537',
      appId: '1:697144931537:web:88869a2a199b40330101f0',
    // databaseURL: 'https://jack3220-demo-default-rtdb.firebaseio.com'
    }
  } else if (env === 'elysee-aesthetics') {
    config = {
      apiKey: 'AIzaSyA88BvOKW8OaeteNoDnkCNMLWit0cGH6Y0',
      authDomain: 'elysee-aesthetics.firebaseapp.com',
      projectId: 'elysee-aesthetics',
      storageBucket: 'elysee-aesthetics.appspot.com',
      messagingSenderId: '998069604824',
      appId: '1:998069604824:web:3c37c2bdd42257c2ada2ad',
    }
  } else if (env === 'nu-tech-demo') {
    config = {
      apiKey: 'AIzaSyDo_KBnzOIbi7mv9CjLz5XDancuul2Kxxo',
      authDomain: 'nu-tech-demo.firebaseapp.com',
      projectId: 'nu-tech-demo',
      storageBucket: 'nu-tech-demo.appspot.com',
      messagingSenderId: '691854571834',
      appId: '1:691854571834:web:d8f544720575d9c8b47803'
    }
  }

  return config
}

export {
  getConfig
}
