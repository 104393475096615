import { createReducer } from '@reduxjs/toolkit';

const add = (state, payload) => {
  const data = payload.data
  state.data[payload.id] = data
  state.ordered.splice(payload.newIndex, 0, data)
  return state
}

const remove = (state, payload) => {
  delete state.data[payload.id]
  state.ordered.splice(payload.oldIndex, 1)
  return state
}

const modify = (state, payload) => {
  const data = payload.data
  state.data[payload.id] = data
  state.ordered.splice(payload.oldIndex, 1);
  state.ordered.splice(payload.newIndex, 0, data)
  return state
}

const toActionName = camel => camel.replace(/[A-Z]/g, m => '_' + m.toLowerCase()).toUpperCase()

const createFireStoreReducer = ({ collection, name, defaultData = { ready: false, data: {}, ordered:[] } }) => createReducer(defaultData, (builder) => {
  builder.addCase(`SET_${toActionName(name)}`, (state, action) => {
    state = { ...action.payload };
    return state
  });
  if (collection) {
    builder.addCase(`ADD_${toActionName(name)}`, (state, action) => {
      return add(state, action.payload)
    });

    builder.addCase(`REMOVE_${toActionName(name)}`, (state, action) => {
      return remove(state, action.payload)
    });

    builder.addCase(`MODIFY_${toActionName(name)}`, (state, action) => {
      return modify(state, action.payload)
    });
  }
  builder.addCase(`RESET_${toActionName(name)}`, (state, action) => {
    state = { ready: false, data: {}, ordered:[] };
    return state
  });
})

export { add, remove, modify, createFireStoreReducer }