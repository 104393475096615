import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

const ColorCell = styled('div')(() => ({
  width: '30px',
  height: '30px',
  borderRadius: '4px',
  cursor: 'pointer',
}));

const ColorInput = styled('input')(() => ({
  width: '100px',
  fontSize: '14px',
  color: '#666',
  border: '0px',
  outline: 'none',
  height: '28px',
  boxShadow: 'inset 0 0 0 1px #F0F0F0',
  boxSizing: 'content-box',
  borderRadius: '0 4px 4px 0',
  paddingLeft: '8px',
}));

const HashDiv = styled('div')(() => ({
  background: '#F0F0F0',
  height: '30px',
  width: '30px',
  borderRadius: '4px 0 0 4px',
  color: '#98A1A4',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

function ColorPicker({ width = '276px', colors = [], value, onChangeComplete }) {
  const [currentColor, setCurrentColor] = useState(value.replace('#', ''))

  function onInput(event) {
    const text = event.target.value.replace(/[^0-9a-f]/ig, '').toUpperCase()
    setCurrentColor(text)
    if ((/[0-9a-f]{6}/i).test(text)) {
      onChangeComplete(`#${text}`)
    }
  }

  function clickOnCell(color) {
    setCurrentColor(color.replace('#', ''))
    onChangeComplete(color)
  }

  return (
    <div style={{ width, padding: '15px' }}>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 30px)', gap: '6px' }}>
        {colors.map(i =>
          <ColorCell
            key={i}
            style={{ backgroundColor: i }}
            onClick={() => clickOnCell(i)}
          ></ColorCell>
        )}
        <div style={{ display: 'flex', flexDirection: 'row', gridColumnEnd: 'span 4' }}>
          <HashDiv>#</HashDiv>
          <ColorInput
            autoFocus
            autoComplete='false'
            maxLength={6}
            type="text"
            value={currentColor}
            onInput={onInput}
          />
        </div>
      </div>
    </div>
  )
}

ColorPicker.propTypes = {
  width: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string.isRequired),
  value: PropTypes.string.isRequired,
  onChangeComplete: PropTypes.func.isRequired,
};

export default ColorPicker;
