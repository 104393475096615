import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import MuiFab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { useDepartments } from 'hooks/departments';

const Fab = styled(MuiFab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}));

export default function SalesRepsView() {
  const { formatMessage } = useIntl()
  const userRights = useSelector(state => state.userRights)
  const config = useSelector(state => state.config.data)
  const data = config.salesReps || {}
  const departments = useDepartments()

  const departmentMapping = departments.reduce((acc, cur) => {
    acc[cur.id] = cur
    return acc
  }, {})

  const fields = [
    { name: 'departments', required: true, selectFields: departments },
  ]

  function createField(field) {
    return (
      <Grid item key={field.name} xs={12} sm={12} md={12}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={formatMessage({ id: 'editDataType.salesReps.table.detail.departments' })}
          value={(data[field.name]|| []).filter(i => departmentMapping[i]).map(i => departmentMapping[i].name)}
          fullWidth
          size="small"
          variant="standard"
        />
      </Grid>
    );
  }

  return (
    <Box sx={{ marginTop: '20px' }}>
      {userRights.hasUserRight('setting-edit') &&
        <Link to={'/setting/appointment/salesReps/edit'}>
          <Fab color="primary">
            <EditIcon />
          </Fab>
        </Link>
      }
      <Paper>
        <Box p={3} style={{ marginTop: '20px' }}>
          <Grid container spacing={2}>
            {fields.map(field => createField(field))}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
