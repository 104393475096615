import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import EnhancedTable from 'components/EnhancedTable';
import ContextStore from 'modules/context';
import { firebaseV8 } from 'modules/firebaseV8';

function SelectCartItemDialog({ handleClose, handleSave, customer, supplier, purchaseOrderId, adjustItems }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)

  const supplierMapping = useSelector(state => state.suppliers.data)
  const merchandiseMapping = useSelector(state => state.merchandises.data)
  const [selectedItems, setSelectedItems] = useState({});
  const [merchandises, setMerchandises] = useState([])
  const [shoppingCartRaw, setShoppingCartRaw] = useState([])
  const shoppingCartMerchandises = Object.keys(shoppingCartRaw).map(i => ({ ...(shoppingCartRaw[i]), key: i })).filter(i => i.source === customer)

  const shoppingCart = { merchandises: shoppingCartMerchandises.reduce((acc, cur) => {acc[cur.key] = cur;return acc;}, {}) }
  const shoppingCartItemString = Object.keys(shoppingCart.merchandises || []).join()

  const formatData = (merchandise) => {
    const newData = { ...merchandise }
    newData.code = merchandiseMapping[newData.id].code
    newData.name = merchandiseMapping[newData.id].name
    newData.orderUnit = newData.orderBySku ? merchandiseMapping[newData.id].sku : merchandiseMapping[newData.id].orderUnit
    const suppliers = merchandiseMapping[newData.id].suppliers
    newData.suppliers = (Object.keys(suppliers) || []).map(s => ({ id: s, ...(suppliers[s]) }))
    newData.inventory = 0
    newData.supplier = newData.supplier ? supplierMapping[newData.supplier].nickname : ''
    return newData
  }

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('shoppingCart').where('user', '==', currentUser.key).onSnapshot(snapshot => {
      const shoppingCart = {}
      snapshot.forEach(doc => {
        shoppingCart[doc.id] = doc.data()
      });
      setShoppingCartRaw(shoppingCart)
    }, err => {})
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const newMerchandises = Object.keys(shoppingCart.merchandises || [])
      .map(key => {
        const m = {
          key,
          ...(shoppingCart.merchandises[key]),
        }
        return m
      })
      .filter(m => Object.keys(merchandiseMapping[m.id].suppliers || []).includes(supplier))
      .map(m => {
        let unPurchasedAmount = m.amount
        const poIds = Object.keys(m.purchaseOrders || {})
        for (const poId of poIds) {
          if (poId === purchaseOrderId) continue
          unPurchasedAmount -= m.purchaseOrders[poId]
        }
        if (adjustItems[m.key]) {
          unPurchasedAmount -= adjustItems[m.key]
        }
        m.amount = unPurchasedAmount
        m.supplier = supplier
        return m
      })
      .filter(m => m.amount > 0)

    setMerchandises(newMerchandises);
  }, [shoppingCartItemString]);

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = merchandises.reduce((acc, cur) => {acc[cur.key] = true;return acc}, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  }

  function handleCheckboxClick({ key }) {
    const selected = selectedItems[key] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[key]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [key]: true }
      setSelectedItems(newSelecteds);
    }
  }

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    { text: 'amount', align: 'right' },
    { text: 'orderUnit', align: 'center' },
    { text: 'inventory', align: 'right' },
    { text: 'expectedDate', sort: 'expectedDate', align: 'center' },
    { text: 'supplier', sort: 'supplier', align: 'right' }
  ].map(c => {c.text = formatMessage({ id: `shoppingCart.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'name' },
    { field: 'amount', align: 'right' },
    { field: 'orderUnit', align: 'center' },
    { field: 'inventory', align: 'right' },
    { field: 'expectedDate', align: 'center' },
    { field: 'supplier', align: 'right' }
  ]

  const onApply = () => {
    // let supplier = ''
    let expectedDate
    const items = {}
    const itemKeys = {}
    for (const m of merchandises) {
      if (selectedItems[m.key]) {

        if (!expectedDate) {
          expectedDate = m.expectedDate
        } else {
          if (m.expectedDate && m.expectedDate < expectedDate) {
            expectedDate = m.expectedDate
          }
        }
        // supplier = m.supplier
        const amount = m.amount
        if (items[m.id]) {
          if (!items[m.id].note) {
            items[m.id].note = m.note
          }
          items[m.id].amount += amount
          items[m.id].keys.push(m.key)
          items[m.id].requisitions.push(m.requisitionId)
          // items[m.id].requisitionSnList.push(m.requisitionSn)
        } else {
          items[m.id] = {
            note: m.note,
            amount: amount,
            keys: [m.key],
            requisitions: [m.requisitionId],
            // requisitionSnList: [m.requisitionSn]
          }
          if (m.orderBySku) {
            items[m.id].orderBySku = true
          }
        }
        itemKeys[m.key] = amount
      }
    }

    const itemArray = Object.keys(items).map(k => ({ id: k, ...(items[k]) }))
    handleSave({ items: itemArray, itemKeys })
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title"><FormattedMessage id="selectMerchandiseDialog.title" /></DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1 }}>
          <EnhancedTable
            defaultOrder="asc"
            defaultOrderField="code"
            headerCells={headerCells}
            rowCells={rowCells}
            onHeaderCheckboxClick={handleSelectAllClick}
            onRowCheckboxClick={handleCheckboxClick}
            getSelectionCount={() => Object.keys(selectedItems).length}
            getRowCheckBoxStatus={merchandise => selectedItems[merchandise.key] || false}
            tableData={merchandises.map(m => formatData(m))}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" disabled={Object.keys(selectedItems).length === 0} onClick={onApply} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectCartItemDialog.propTypes = {
  customer: PropTypes.string.isRequired,
  supplier: PropTypes.string.isRequired,
  adjustItems: PropTypes.object.isRequired,
  purchaseOrderId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default SelectCartItemDialog;
