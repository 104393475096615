const i18n = {
  'leaves': {
    'applyForLeave': '申請休假',
    'editLeave': '編輯申請',
    'header': {
      'date': '申請日',
      'days': '天/時數',
      'end': '結束時間',
      'start': '開始時間',
      'state': '狀態',
      'type': '假別',
    },
    'list': {
      'step': '步驟',
      'balance': '剩餘額度',
    },
    'table': {
      'type': '假別',
      'start': '開始時間',
      'end': '結束時間',
      'days': '天/時數',
      'date': '申請日',
      'state': '狀態',
      'actions': '行為',
      'pending': '等候中',
      'history': '歷史紀錄',
      'createdByName': '申請人'
    },
    'leaveForm': {
      'timeOffType': '假別',
      'startDateTime': '開始日期',
      'endDateTime': '結束日期',
      'leaveDays': '天數',
      'leaveHours': '時數',
      'substitute': '代理人',
      'reason': '原因',
      'currentBalances': '剩餘額度',
      'leavecertificate': '請假證明',
      'leaveHour': '時',
      'leaveMinute': '分',
      'type': '假別',
      'voiding': '作廢簽核中時數',
      'signing': '請假簽核中時數',
      'balance': '剩餘時數',
      'used': '已用時數',
      'earned': '總時數',
    },
    'leaveAlert': {
      'insufficientLeaveBalanceTitle': '剩餘休假不足',
      'insufficientLeaveBalanceContent': '由於您沒有足夠的剩餘休假，因此無法處理您的休假申請',
      'leaveTimeAlertTitle': '未填寫休假時數',
      'leaveTimeAlertContent': '由於您未選擇的休假天數/時數，因此無法處理您的休假申請',
      'vaccinationAlert': '疫苗接種假不得大於兩天'
    },
    'detail': {
      'note': '備註',
      'rejectReason': '否決原因',
      'editReason': '修改原因',
      'voidReason': '作廢原因'
    },
  },
  'leaveSent': {
    'leaveApplicationSent': '休假申請送出',
    'sentMessage': '現正等待審核中，審核完畢後將會通知',
    'newBack': '回到「我的休假」',
  },
  'leaveType': {
    'weekly_leave': '本休',
    'sick_leave': '病假',
    'annual_leave': '特休',
    'menstrual_leave': '生理假',
    'personal_leave': '事假',
    'bereavement_leave': '喪假',
    'marriage_leave': '婚假',
    'maternity_leave': '產假',
    'reproductive_leave': '產檢假',
    'paternity_leave': '陪產假',
    'fetal_leave': '安胎假',
    'family_leave': '家庭照顧假',
    'overtime_leave': '補休假',
    'business_leave': '公假',
    'publicinjury_leave': '公傷假',
    'epidemicPreventionCare_leave': '防疫照顧假',
    'vaccination_leave': '疫苗接種假',
    'quarantine_leave': '防疫隔離假'
  },
  'leaveStatus': {
    'pending': '等候中',
    'done': '同意',
    'reject': '否決',
    'void': '作廢',
  }
}

export default i18n