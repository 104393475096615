import { createLogger } from 'redux-logger';
import cloneSuppliers from './cloneSuppliers'

const middleware = [cloneSuppliers];

if (process.env.NODE_ENV === 'development') {
  middleware.push(createLogger({ collapsed: true }));
}

export default middleware;
