const i18n = {
  'inventoryCheck': {
    'tab': {
      'processing': '盤點中',
      'pending': '等候中',
      'done': '已完成',
      'void': '已作廢',
    },
    'name': '盤點單',
    'table': {
      'processing': {
        'title': '盤點單列表 - 盤點中',
      },
      'pending': {
        'title': '盤點單列表 - 待簽核',
      },
      'title': '盤點單列表',
      'selected': '已選擇 {count} 筆盤點單',
      'header': {
        'sn': '盤點單號',
        'source': '盤點單位',
        'sourceName': '盤點單位',
        'createdBy': '盤點人',
        'date': '建立日期',
        'baseDate': '盤點日期',
      },
      'detail': {
        'sn': '盤點單號',
        'source': '盤點單位',
        'sourceName': '盤點單位',
        'createdBy': '盤點人',
        'date': '建立日期',
        'baseDate': '盤點日期',
        'note': '備註',
        'merchandise': '商品',
      },
    },
    'dialog': {
      'title': {
        'add': '新增盤點單',
        'edit': '編輯盤點單',
      }
    },
    'merchandise': {
      'code': '品號',
      'name': '品名',
      'unitPrice': '單價',
      'expectedAmount': '預期數量',
      'currentAmount': '實際數量',
      'mainAmount': '主倉庫實際數量',
      'wareroomAmount': '其他倉庫數量',
      'adjust': '結果',
      'amount': '基準日庫存',
      'stock': '即時庫存',
      'balance': '盈虧',
      'checkAmount': '盤點數量',
      'sku': '庫存單位',
      'orderUnit': '單位',
      'price': '金額',
      'note': '備註',
      'statusText': '狀態',
      'lotNumber': '批號',
      'expectedDate': '效期',
      'date': '入庫日期',
    }
  },
  'editInventoryCheck': {
    'table': {
      'title': '庫存清單',
      'selected': '已選擇 {count} 個項目',
    },
    'loadInventory': '載入庫存',
  },
}
export default i18n
