import React from 'react';

import DehazeIcon from '@mui/icons-material/Dehaze';

const DragHandle = () => (
  <div id={'dragger'} style={{ cursor: 'row-resize', zIndex: 2 }}><DehazeIcon style={{ pointerEvents: 'none' }}/></div>
)

export default DragHandle;

