import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import EnhancedTable from 'components/EnhancedTable';
import { months, monthName } from 'constants';

function WeeklyPolicyView({ weeklyPolicy, onEditClick }) {
  const { formatMessage } = useIntl()
  const userRights = useSelector(state => state.userRights)

  const headerCells = [
    { text: 'day', align: 'left' },
    { text: 'weeklymonths' },
    { text: 'weeklydays' },
  ].map(c => {c.text = formatMessage({ id: `setAttendance.weeklyPolicy.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'day', align: 'left' },
    { field: 'weeklymonths' },
    { field: 'weeklydays' },
  ]

  const items = months.map(month => {
    const dd = weeklyPolicy.earned[month]
    return {
      key: month,
      weeklymonths: monthName[month],
      weeklydays: `${dd} 天`
    }
  })

  function getPolicyData() {
    const data = {
      ...weeklyPolicy,
      earned: { ...weeklyPolicy.earned },
    }

    return data
  }

  return (
    <div>
      <EnhancedTable
        size="small"
        headerCells={headerCells}
        rowCells={rowCells}
        tableData={items}
      />
      {userRights.hasUserRight('setting-edit') && <Grid container sx={{ mt: 2 }} spacing={1}>
        <Grid item key="buttons" xs={12} sm={12} md={12}>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onEditClick(getPolicyData())}
            >
              <FormattedMessage id="button.edit" />
            </Button>
          </Stack>
        </Grid>
      </Grid>}
    </div>
  )
}

WeeklyPolicyView.propTypes = {
  weeklyPolicy: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default WeeklyPolicyView;
