import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import EnhancedTable from 'components/EnhancedTable';
import ButtonContainer from 'components/ButtonContainer';
import EnhancedButton from 'components/EnhancedButton';

function RefundView({ userRights, data, onEditClick, currentCompany }) {
  const { formatMessage } = useIntl()
  const productMapping = useSelector(state => state.products.data)
  const currentProducts = Object.keys(data.products).map(i => ({ id: i, ...data.products[i] }))

  const productHeaderCells = [
    { text: 'name' },
    { text: 'amount' },
  ].map(c => { c.text = formatMessage({ id: `salesOrder.product.${c.text}` }); return c })

  const productRowCells = [
    { field: 'name' },
    { field: 'amount' },
  ]

  function formatData(data) {
    const newData = { ...data }
    newData.name = productMapping[data.productId].name
    return newData
  }

  return (
    <div style={{ width: '100%', }}>
      <Box p={0}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 500 }} component="div">
                產品列表:
              </Typography>
            </div>

            <EnhancedTable
              size="small"
              headerCells={productHeaderCells}
              rowCells={productRowCells}
              tableData={currentProducts.map(i => formatData(i))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'salesOrder.table.detail.note' })}
              value={data.note}
              multiline
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          {userRights.hasUserRightForVendor('refund-edit', currentCompany) && <Grid item xs={12} sm={12} md={12}>
            <ButtonContainer>
              <EnhancedButton
                onClick={() => onEditClick(data)}
                label={formatMessage({ id: 'button.edit' })}
              />
            </ButtonContainer>
          </Grid>}
        </Grid>
      </Box>
    </div>
  )
}

RefundView.propTypes = {
  userRights: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  currentCompany: PropTypes.string.isRequired
};

export default RefundView