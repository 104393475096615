import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

import { calculate } from 'modules/uitls';
import DatePickerField from './DatePickerField';

export default function EnhancedTableRow(props) {
  const { cellData, selected, rowCells, expandable, expanded = false, forceExpanded = false, expandContent, onCheckboxClick, onRadioButtonClick, actionIcons, lock, onRowClick } = props;
  const [open, setOpen] = useState(expanded);
  const colSpan = rowCells.length + (onCheckboxClick ? 1 : 0) + (onRadioButtonClick ? 1 : 0) + (expandable ? 1 : 0) + (actionIcons ? 1 : 0)
  const allError = cellData.errors || {}
  const [anchorEl, setAnchorEl] = useState(null);

  const getCellElem = (cellData, cell) => {
    const disabled = lock || (cell.getEnableStatus && !cell.getEnableStatus(cellData, cell.field))
    const handleClickEvent = !disabled && cell.onClick && cell.enabledClick?.(cellData, cell.field)

    if (cell.type === 'calculate') {
      return calculate(cellData, cell.calculate)
    } else if (['input', 'input-number'].includes(cell.type)) {
      return (
        <TextField
          required={cell.required}
          disabled={disabled}
          type="text"
          label={cell.label}
          variant="outlined"
          value={cellData[cell.field]}
          onKeyDown={e => {
            if (cell.onKeyDown) {
              cell.onKeyDown(cellData, cell.field, e.key)
            }
          }}
          onClick={e => {
            if(handleClickEvent) {
              cell.onClick(cellData)
            } else {
              e.stopPropagation()
            }
          }}
          InputProps={handleClickEvent ? {
            readOnly: true,
          } : {}}
          onChange={e => {
            if (cell.type === 'input-number') {
              const inputRule = cell.inputRule || /[^0-9]/g
              const newValue = e.target.value.replace(inputRule, '')
              cell.onValueChanged(cellData, cell.field, newValue)
            } else {
              cell.onValueChanged(cellData, cell.field, e.target.value)
            }
          }}
          error={allError[cell.field] ? true : false}
          helperText={allError[cell.field]}
          size="small" />
      );
    } else if (cell.type === 'date') {
      return (<DatePickerField
        newStyle={{ maxWidth: '154px' }}
        disabled={disabled}
        required={cell.required}
        label={cell.label}
        // minDateMessage="日期不得早於今日"
        invalidDateMessage="無效的日期格式"
        value={cellData[cell.field]}
        onChange={date => {
          cell.onValueChanged(cellData, cell.field, date)
        }}
      // minDate={new Date()}
      />)
    } else if (cell.type === 'input-select') {
      return (
        <TextField
          style={{ minWidth: cell.minWidth ? '80px' : 'unset' }}
          required={cell.required}
          disabled={disabled}
          select
          type="text"
          size="small"
          label={cell.label}
          variant="outlined"
          onChange={e => {
            cell.onValueChanged(cellData, cell.field, e.target.value)
          }}
          value={cellData[cell.field]}
          error={allError[cell.field] ? true : false}
          helperText={allError[cell.field]}
          fullWidth
        >
          {cell.getMenuItems(cellData).map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )
    } else if (cell.type === 'autocomplete') {
      return (
        <Autocomplete
          freeSolo
          disabled={disabled}
          size="small"
          variant="outlined"
          value={cellData[cell.field]}
          options={cell.getOptions(cellData)}
          onInputChange={(e, v) => {
            cell.onValueChanged(cellData, cell.field, v)
          }}
          renderInput={(params) => <TextField
            {...params}
            error={allError[cell.field] ? true : false}
            helperText={allError[cell.field]}
            required={cell.required}
            label={cell.label}
          />}
        />
      )
    } else if (cell.type === 'input-menu') {
      return (<>
        <TextField
          required={cell.required}
          disabled={disabled}
          type="text"
          size="small"
          label={cell.label}
          variant="outlined"
          onClick={e => {
            if(!disabled) {
              setAnchorEl(e.currentTarget)
            }
          }}
          value={cellData[cell.field]}
          error={allError[cell.field] ? true : false}
          helperText={allError[cell.field]}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {cell.getMenuItems(cellData).map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                cell.onValueChanged(cellData, cell.field, option.value)
                setAnchorEl(null)
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </>)
    } else if (cell.type === 'popup') {
      return <TextField
        required={cell.required}
        disabled={disabled}
        type="text"
        size="small"
        label={cell.label}
        variant="outlined"
        onClick={e => {
          if(!disabled) {
            cell.onClick(cellData)
          }
        }}
        InputProps={{
          readOnly: true,
        }}
        value={cellData[cell.field]}
        error={allError[cell.field] ? true : false}
        helperText={allError[cell.field]}
        fullWidth
      />
    } else if (cell.type === 'info' && cellData[cell.field] !== 'N/A') {
      return (<div>
        <span style={{ color: '#3f51b5', cursor: 'pointer' }}
          onClick={(e) => {
            cell.onButtonClick(cellData.key || cellData.id, cell.field, cellData)
            e.stopPropagation()
          }}
        >
          {cellData[cell.field]}
        </span>
      </div>)
    } else {
      if (cell.tooltip) {
        return <Tooltip title={cellData[cell.tooltip]}><span>{cellData[cell.field]}</span></Tooltip>
      }
      if (cell.textColor) {
        return cellData.textColor ? <span style={{ color: cellData.textColor }}>{cellData[cell.field]}</span> : <span>{cellData[cell.field]}</span>
      }
      return cellData[cell.field]
    }
  }

  const sx = cellData.bgColor ? { bgcolor: cellData.bgColor } : {}

  return (
    <React.Fragment>
      <TableRow hover selected={open && expandable} sx={sx}>
        {onRadioButtonClick && <TableCell padding="checkbox">
          <Radio onChange={() => onRadioButtonClick(cellData)} checked={selected} disabled={lock} />
        </TableCell>}
        {onCheckboxClick && <TableCell padding="checkbox">
          <Checkbox onClick={onCheckboxClick} checked={selected} disabled={lock} />
        </TableCell>}
        {expandable && expandContent && <TableCell sx={{ width: '40px' }} onClick={() => setOpen(!open)}>
          <IconButton aria-label="expand row" size="small">
            {(open || forceExpanded) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>}
        {(expandable && !expandContent) && <TableCell></TableCell>}
        {rowCells.map(cell =>
          (<TableCell
            sx={cell.style}
            key={`rowCell-${cell.field}`}
            align={cell.align || 'left'}
            onClick={() => {
              if (expandable && expandContent) {
                setOpen(!open)
              } else {
                if (onRowClick && !['input', 'input-number', 'date', 'input-select', 'input-menu', 'autocomplete'].includes(cell.type)) {
                  onRowClick()
                }
              }
            }}
          >
            {getCellElem(cellData, cell)}
          </TableCell>)
        )}
        {actionIcons && <TableCell align="right" size="small">{actionIcons}</TableCell>}
      </TableRow>
      {expandable && expandContent && <TableRow>
        <TableCell sx={(open || forceExpanded) ? { borderTop: 'solid 1px #e0e0e0', paddingBottom: 0, paddingTop: 0 } : { paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
          <Collapse in={open || forceExpanded} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {expandContent}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>}
    </React.Fragment>
  );
}

EnhancedTableRow.propTypes = {
  expandable: PropTypes.bool,
  expanded: PropTypes.bool,
  forceExpanded: PropTypes.bool,
  expandContent: PropTypes.element,
  cellData: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onCheckboxClick: PropTypes.func,
  onRadioButtonClick: PropTypes.func,
  radioButtonChecked: PropTypes.bool,
  actionIcons: PropTypes.element,
  onRowClick: PropTypes.func,
  lock: PropTypes.bool,
  rowCells: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    align: PropTypes.string,
    type: PropTypes.string,
    calculate: PropTypes.string,
    label: PropTypes.string,
    onValueChanged: PropTypes.func,
    onClick: PropTypes.func,
    enabledClick: PropTypes.func,
  })).isRequired,
};
