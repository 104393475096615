import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { styled } from '@mui/material/styles';
import CircularProgressMui from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const CircularProgress = styled(CircularProgressMui)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
}));

export default function ButtonProgress({ handleClose, handleClick, loading, buttonText = 'button.confirm', disabledField, thirdButton = null, icons = null }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Button variant="contained" onClick={handleClose} color="inherit">
        <FormattedMessage id="button.cancel" />
      </Button>
      <div style={{ position: 'relative', marginLeft: '20px' }}>
        <Button variant="contained" color="primary" disabled={disabledField ? disabledField : loading} onClick={handleClick}>
          {icons}
          <FormattedMessage id={buttonText} />
        </Button>
        {loading && <CircularProgress size={24} />}
      </div>
      {thirdButton && <div style={{ position: 'relative', marginLeft: '20px' }}>
        <Button variant="contained" onClick={thirdButton.handleSave} disabled={thirdButton.loading} color="success" sx={{ ml: '20px' }}>
          <FormattedMessage id={thirdButton.text} />
        </Button>
        {thirdButton.loading && <CircularProgress size={24} />}
      </div>
      }
    </div>
  )
}

ButtonProgress.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  thirdButton: PropTypes.object, 
  icons: PropTypes.element
};