import { timeToMinutes, minutesToTop, minutesToLeft, minutesToHeight, minutesToWidth } from 'modules/time';
import { objectToArray } from 'modules/data';

function calculateStartTop(hour, minute, firstHour, shift = false) {
  return minutesToTop(
    timeToMinutes(hour, minute, firstHour), shift
  )
}

function calculateEndTop(appointment, firstHour, shift = false) {
  const treatments = objectToArray(appointment.treatments, 'order')
  const width = treatments.reduce((acc, cur) =>
    acc + minutesToHeight(cur.duration), 0)
  return calculateStartTop(appointment.hour, appointment.minute, firstHour, shift) + (width || 0)
}

function calculateStartLeft(hour, minute, firstHour) {
  return minutesToLeft(
    timeToMinutes(hour, minute, firstHour)
  )
}

function calculateEndLeft(appointment, firstHour) {
  const treatments = objectToArray(appointment.treatments, 'order')

  const width = treatments.reduce((acc, cur) =>
    acc + minutesToWidth(cur.duration), 0)
  return calculateStartLeft(appointment.hour, appointment.minute, firstHour) + width
}

function calculateRowHight(appointments, appointmentPosition, firstHour, isMobile) {

  const appointmentRowLastEndLeft = []
  for (const appointment of appointments) {

    if (isMobile) {
      const gutter = 30
      const startTop = calculateStartTop(appointment.hour, appointment.minute, firstHour, true)
      const endTop = calculateEndTop(appointment, firstHour, true)

      let indexOfLine = appointmentRowLastEndLeft.findIndex(endTop => startTop > endTop + gutter)

      if (indexOfLine > -1) {
        appointmentRowLastEndLeft[indexOfLine] = endTop
      } else {
        appointmentRowLastEndLeft.push(endTop)
        indexOfLine = appointmentRowLastEndLeft.length - 1
      }
      const left = indexOfLine * 136

      appointmentPosition[appointment.id] = {
        top: startTop,
        left: left,
        // verticalPosition: lastTop / 60
      }
    } else {
      const gutter = 100
      const startLeft = calculateStartLeft(appointment.hour, appointment.minute, firstHour)
      const endLeft = calculateEndLeft(appointment, firstHour)

      let indexOfLine = appointmentRowLastEndLeft.findIndex(endLeft => startLeft > endLeft + gutter)

      if (indexOfLine > -1) {
        appointmentRowLastEndLeft[indexOfLine] = endLeft
      } else {
        appointmentRowLastEndLeft.push(endLeft)
        indexOfLine = appointmentRowLastEndLeft.length - 1
      }
      const top = indexOfLine * 60

      appointmentPosition[appointment.id] = {
        top,
        left: startLeft,
        // verticalPosition: lastTop / 60
      }
    }
  }
  const rowsCount = appointmentRowLastEndLeft.length
  // NEED FIX: 此處算出來的 minHeight 應該只有 desktop 版有用
  //           mobile 版應該要算出 minWidth, 不然排版應該會爆掉?
  const minHeight = Math.max(120, ((rowsCount + 1) * 60))
  // const minWidth = Math.max(800, ((rowsCount + 1) * 136))

  return {
    minHeight,
    rowsCount
  }
}

export {
  calculateRowHight,
  calculateStartTop,
  calculateEndTop,
  calculateStartLeft,
  calculateEndLeft,
};
