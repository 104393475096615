import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import BackdropMui from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';

import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';

import EnhancedTable from 'components/EnhancedTable';
import EnhancedTableEx from 'components/EnhancedTableEx';
import ActionDialog from 'components/ActionDialog';
import SimpleTableToolbar from 'components/SimpleTableToolbar';
import DateRangePickerField from 'components/DateRangePickerField';
import ContextStore from 'modules/context';
import { callFunction } from 'modules/firebase';
import { firebaseV8 } from 'modules/firebaseV8';
import OvertimeWorkType from 'enum/OvertimeWorkType';
import OvertimeView from './OvertimeView';

const _OvertimeAllType = OvertimeWorkType.concat();

const Backdrop = styled(BackdropMui)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));

const statusColor = {
  pending: '#c83cc8',
  reject: '#fe2851',
  void: '#fe2851',
  done: '#00b900',
}

function ReviewOvertimesList({ isAll }) {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const tableRef = useRef()
  const userMapping = useSelector(state => state.users.data);
  const { currentUser } = useContext(ContextStore)

  const [pendingOvertimes, setPendingOvertimes] = useState([])
  const [historOvertimes, sethistorOvertimes] = useState([])
  const [dialogData, setDialogData] = useState(null);
  const [loading, setLoading] = useState(false)

  const [startDate, setStartDate] = useState(dayjs().subtract(3, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [historyStartDate, setHistoryStartDate] = useState(dayjs().subtract(3, 'months').format('YYYY-MM') + '-01')
  const [historyEndDate, setHistoryEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  const [typeText, setTypeText] = useState('')
  const [filterText, setFilterText] = useState('')
  const [checked, setChecked] = React.useState([0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [typeHistoryText, setTypeHistoryText] = useState('')
  const [filterHistoryText, setFilterHistoryText] = useState('')
  const [historyChecked, setHistoryChecked] = React.useState([0]);
  const [historyAnchorEl, setHistoryAnchorEl] = useState(null);
  const openHistory = Boolean(historyAnchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHistoryClick = (event) => {
    setHistoryAnchorEl(event.currentTarget);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    let newText = ''
    for (const x of newChecked) {
      if (x.name) {
        newText = newText === '' ? x.name : newText + ',' + x.name
      }
    }

    setTypeText(newText)
    setChecked(newChecked);
  };

  const handleHistoryToggle = (value) => () => {
    const currentIndex = historyChecked.indexOf(value);
    const newChecked = [...historyChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    let newText = ''
    for (const x of newChecked) {
      if (x.name) {
        newText = newText === '' ? x.name : newText + ',' + x.name
      }
    }

    setTypeHistoryText(newText)
    setHistoryChecked(newChecked);
  };

  useEffect(() => {
    setPendingOvertimes([])
    const unsubscribe = firebaseV8().collection('overtimes')
      .where('status', '==', 'pending').onSnapshot(snapshot => {
        const overtimes = []
        snapshot.forEach(doc => {
          overtimes.push({ ...doc.data(), id: doc.id })
        });

        const stepUserOvertimes = !isAll ? overtimes.filter(overtime => {
          const currentStep = overtime.history.length > 0 ? overtime.history[overtime.history.length - 1].step : 0
          return overtime.steps[currentStep].users.includes(currentUser.key)
        }) : overtimes

        setPendingOvertimes(stepUserOvertimes)
      }, err => { })

    return () => unsubscribe()
  }, [location.pathname]);

  useEffect(() => {
    sethistorOvertimes([])
    const unsubscribe = firebaseV8().collection('overtimes')
      .where('startDate', '>=', historyStartDate).where('startDate', '<=', historyEndDate)
      .where('status', 'in', ['done', 'void', 'reject']).onSnapshot(snapshot => {
        const overtimes = []
        snapshot.forEach(doc => {
          overtimes.push({ ...doc.data(), id: doc.id })
        });
        sethistorOvertimes(overtimes)
      }, err => { })

    return () => unsubscribe()
  }, [historyStartDate, historyEndDate]);


  const formatData = (overtime) => {
    const newData = { ...overtime }
    newData.createdByName = userMapping[newData.createdBy].displayName
    newData.start = `${newData.startDate} ${newData.startTime}`
    newData.typeName = formatMessage({ id: `overtimeType.${newData.type}` })
    newData.statusName = `${formatMessage({ id: `overtimeStatus.${newData.status}` })}`
    newData.end = `${newData.endDate} ${newData.endTime}`
    newData.hours = `${Number(newData.hours) * 2}`
    newData.textColor = statusColor[newData.status]
    return newData
  }

  async function handleExecute(data) {
    const { text } = data
    const { filed, overtimeId } = dialogData
    setLoading(true)

    try {
      await callFunction('saveOvertimes', {
        ...filed,
        id: overtimeId,
        note: text,
        void: true,
      })

      setLoading(false)
    } catch (ex) {
      console.log(ex)
      setLoading(false)
    }
  }

  function handleClose() {
    setDialogData(null)
  }

  function HistoryHandleChange(value) {
    setFilterHistoryText(value)
  }

  function handleChange(value) {
    setFilterText(value)
  }

  function getToolbox(isHitory) {
    return <>
      {isHitory && <DateRangePickerField
        startDate={isHitory ? historyStartDate : startDate}
        endDate={isHitory ? historyEndDate : endDate}
        onStartDateChanged={isHitory ? setHistoryStartDate : setStartDate}
        onEndDateChanged={isHitory ? setHistoryEndDate : setEndDate}
      />}
      <Grid item xs={12} sm={6} md={6} sx={{ pr: '10px' }}>
        <TextField
          type="text"
          size="small"
          label={formatMessage({ id: 'searchbox.createdBy' })}
          onChange={(e) => isHitory ? HistoryHandleChange(e.target.value) : handleChange(e.target.value)}
          variant="outlined"
          fullWidth
          value={isHitory ? filterHistoryText : filterText}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          type="text"
          size="small"
          label={formatMessage({ id: 'searchbox.type' })}
          variant="outlined"
          onClick={isHitory ? handleHistoryClick : handleClick}
          value={isHitory ? typeHistoryText : typeText}
          fullWidth
        />
        <Menu
          id="fade-menu"
          anchorEl={isHitory ? historyAnchorEl : anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          open={isHitory ? openHistory : open}
          onClose={() => isHitory ? setHistoryAnchorEl(null) : setAnchorEl(null)}
          TransitionComponent={Fade}
        >
          <List>
            {_OvertimeAllType.map((value, idx) => {
              return (
                <ListItem key={`${value}-${idx}`} dense onClick={isHitory ? handleHistoryToggle(value) : handleToggle(value)}>
                  <Checkbox
                    sx={{ padding: '0 4px' }}
                    edge="start"
                    size="small"
                    checked={isHitory ? historyChecked.indexOf(value) !== -1 : checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                  {value.name}
                </ListItem>
              );
            })}
          </List>
        </Menu>
      </Grid>
    </>;
  }

  const headerCells = [
    { text: 'createdByName', sort: 'createdByName' },
    { text: 'start', sort: 'start' },
    { text: 'end', sort: 'end' },
    { text: 'hours', sort: 'hours' },
    { text: 'type', sort: 'type' },
    { text: 'reason', sort: 'reason' },
    { text: 'state', sort: 'status' },
  ].map(c => { c.text = formatMessage({ id: `overtimes.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'createdByName' },
    { field: 'start' },
    { field: 'end' },
    { field: 'hours' },
    { field: 'typeName' },
    { field: 'reason' },
    { field: 'statusName', textColor: true },
  ]

  function exportProfile() {
    tableRef.current.saveToFile(`OverTimes_${dayjs().format('YYYY-MM-DD-HHmmss')}.csv`)
    // NEED FIX： 此處原本的"原因"欄位被換成津貼
    // const rows = ['申請人,開始時間,結束時間,節數,申請日,補償類型,津貼,狀態']
    // overtime.type === 'overtime_shift' && overtime.hours !== undefined ? overtime.hours / 8 : '',
  }

  function filterItem(isHistory) {
    let type = [];

    if (isHistory) {
      for (const x of historyChecked) {
        if (x.value) {
          type.push(x.value)
        }
      }
      if (typeHistoryText !== '' && filterHistoryText === '') {
        return historOvertimes.filter(s => type.includes(s.type));
      } else if (filterHistoryText !== '' && typeHistoryText === '') {
        return historOvertimes.filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(filterHistoryText.toLowerCase()));
      } else if (typeHistoryText !== '' && filterHistoryText !== '') {
        return historOvertimes.filter(s => type.includes(s.type)).filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(filterHistoryText.toLowerCase()));
      } else {
        return historOvertimes
      }
    } else {
      for (const x of checked) {
        if (x.value) {
          type.push(x.value)
        }
      }
      if (typeText !== '' && filterText === '') {
        return pendingOvertimes.filter(s => type.includes(s.type));
      } else if (filterText !== '' && typeText === '') {
        return pendingOvertimes.filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(filterText.toLowerCase()));
      } else if (typeText !== '' && filterText !== '') {
        return pendingOvertimes.filter(s => type.includes(s.type)).filter(s => userMapping[s.createdBy].displayName.toLowerCase().includes(filterText.toLowerCase()));
      } else {
        return pendingOvertimes
      }
    }
  }

  const filterHistoryOvertimes = filterItem(true);
  const filterOvertimes = filterItem();

  return (
    <div>
      {loading && <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>}
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'overtimes.detail.voidReason' })}
        action={dialogData.action}
      />}
      <Grid container spacing={1} sx={{ mt: '10px', mb: '40px' }}>
        <Grid item xs={12} sm={12} md={12} >
          <SimpleTableToolbar
            title={'overtimes.table.pending'}
            toolbox={getToolbox()}
          />
          <EnhancedTable
            defaultOrder="desc"
            defaultOrderField="start"
            headerCells={headerCells}
            rowCells={rowCells}
            tableData={filterOvertimes.map(r => formatData(r))}
            getExpandContent={overtime =>
              <OvertimeView currentUser={currentUser} overtime={overtime} isAll={isAll} type='review'/>
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <SimpleTableToolbar
            title={'overtimes.table.history'}
            toolbox={getToolbox(true)}
          />
          <EnhancedTableEx
            ref={tableRef}
            defaultOrder="desc"
            defaultOrderField="start"
            headerCells={headerCells}
            rowCells={rowCells}
            tableData={filterHistoryOvertimes.map(r => formatData(r))}
            getHeaderActionIcons={() => isAll ? (
              <IconButton
                onClick={() => exportProfile()}
                size="large">
                <GetAppIcon></GetAppIcon>
                <Typography noWrap variant="button">{formatMessage({ id: 'button.export' })}</Typography>
              </IconButton>
            ) : null}
            getActionIcons={overtime =>
              <>
                {(!overtime.void || (overtime.void && overtime.status === 'reject')) && <span>
                  <Tooltip title={formatMessage({ id: 'button.edit' })}>
                    <IconButton
                      onClick={() => navigate(`/leaveOvertimeWork/overtimes/edit/${overtime.createdBy}/${overtime.id}`)}
                      size="large">
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={formatMessage({ id: 'button.void' })}>
                    <IconButton
                      onClick={() => setDialogData({ action: 'void', title: '', filed: overtime, overtimeId: overtime.id })}
                      size="large">
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </span>}
              </>
            }
            getExpandContent={overtime =>
              <OvertimeView currentUser={currentUser} overtime={overtime} isHistory={true} isAll={isAll} type='review' />
            }
          />
        </Grid>
      </Grid>
    </div >
  );
};

ReviewOvertimesList.propTypes = {
  isAll: PropTypes.bool,
};

export default ReviewOvertimesList;
