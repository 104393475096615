import { hasUserRight, hasUserRightForVendor, onlyVendor } from 'modules/userRights';

const initState = null;

const reducer = (state = initState, action) => {
  switch (action.type) {
  case 'SET_USER_RIGHTS': {
    const ur = action.payload || {}
    ur.hasUserRight = hasUserRight.bind(ur)
    ur.hasUserRightForVendor = hasUserRightForVendor.bind(ur)
    ur.onlyVendor = onlyVendor.bind(ur)
    return ur;
  }
  default:
    return state;
  }
};

export default reducer ;
