import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Divider from '@mui/material/Divider';

import DateRangePickerField from 'components/DateRangePickerField';
import ContextStore from 'modules/context';
import { unwrap } from 'modules/uitls';
import { firebaseV8 } from 'modules/firebaseV8';
import StockRequisitionList from './StockRequisitionList';

function StockRequisitionTab({ formName }) {
  const { tabName } = useParams()
  const { currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)

  const [stockRequisitions, setStockRequisitions] = useState([])
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  useEffect(() => {
    if (stockRequisitions.length) setStockRequisitions([])
    const onSnapshot = snapshot => {
      const stockRequisitions = []
      snapshot.forEach(doc => {
        stockRequisitions.push({ ...unwrap(doc.data()), id: doc.id })
      });
      setStockRequisitions(stockRequisitions)
    }
    const unsubscribe = tabName === 'pending' ?
      firebaseV8().collection(`${formName}s`).where('status', '==', tabName).onSnapshot(onSnapshot, err => {}) :
      firebaseV8().collection(`${formName}s`).where('status', '==', tabName).where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(onSnapshot, err => {})
    return () => unsubscribe()
  }, [startDate, endDate]);

  const stockRequisitionsForTab = stockRequisitions.filter(r =>
    userRights.debugging ||
    userRights.hasUserRightForVendor(`${formName}-create`, r.source) ||
    userRights.hasUserRightForVendor(`${formName}-review`, r.source)
  );

  const reqWaitingForProcess = waitingForProcess()

  function waitingForProcess() {
    if (tabName === 'pending') {
      return stockRequisitionsForTab.filter(r => {
        const history = r.history || []
        const currentStep = history.length > 0 ? history[history.length - 1].step : 0
        if (isNaN(currentStep) || currentStep === r.steps.length) return false
        return r.steps[currentStep].users.includes(currentUser.key)
      })
    } else {
      return []
    }
  }

  return (
    <div style={{ marginTop: '20px', paddingBottom: '60px' }}>
      {tabName !== 'pending' && <DateRangePickerField
        startDate={startDate}
        endDate={endDate}
        onStartDateChanged={setStartDate}
        onEndDateChanged={setEndDate}
      />}
      {reqWaitingForProcess.length > 0 && <>
        <StockRequisitionList stockRequisitions={reqWaitingForProcess} title={`${formName}.table.${tabName}.title`} formName={formName} />
        <Divider sx={{ mx: '0px', my: '10px' }}/>
      </>}
      <StockRequisitionList stockRequisitions={stockRequisitionsForTab} title={`${formName}.table.title`} formName={formName} />
    </div>
  );
}

export default StockRequisitionTab;
