import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import DateRangePickerField from 'components/DateRangePickerField';
import { firestoreListener } from 'modules/firebase';
import SalesReturnFormList from './SalesReturnFormList';

function SalesReturnFormTab() {
  const { tabName } = useParams()
  const userRights = useSelector(state => state.userRights)

  const [salesReturnForms, setSalesReturnForms] = useState([])
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  useEffect(() => {
    if (salesReturnForms.length) setSalesReturnForms([])

    const unsubscribe = firestoreListener({
      collection: 'salesReturnForms',
      where: tabName === 'pending' ?
        [['status', '==', tabName]] :
        [['status', '==', tabName], ['date', '>=', startDate], ['date', '<=', endDate]],
      unwrap: true,
      onData: (data) => {
        setSalesReturnForms(data)
      }
    })

    return () => unsubscribe()
  }, [startDate, endDate, tabName]);

  const salesReturnFormsForTab = salesReturnForms.filter(r =>
    userRights.debugging || userRights.returnFormSource.includes(r.source)
  );

  return (
    <div style={{ marginTop: '20px', paddingBottom: '60px' }}>
      {tabName !== 'pending' && <DateRangePickerField
        startDate={startDate}
        endDate={endDate}
        onStartDateChanged={setStartDate}
        onEndDateChanged={setEndDate}
      />}
      <SalesReturnFormList salesReturnForms={salesReturnFormsForTab} title="salesReturnForm.table.title" />
    </div>
  );
}

export default SalesReturnFormTab;
