import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Box from '@mui/material/Box';

import FabAdd from 'components/FabAdd'
import DateRangePickerField from 'components/DateRangePickerField';
import ContextStore from 'modules/context';
import { anyIntersection } from 'modules/uitls';
import { firestoreListener } from 'modules/firebase';
import AnnouncementFullView from './AnnouncementFullView';
import AnnouncementList from './AnnouncementList';

function Announcement() {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { setBreadcrumbs, currentUser, currentCompany } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const location = useLocation()
  const [announcements, setAnnouncements] = useState([])
  const [viewData, setViewData] = useState(null)
  const notifications = useSelector(state => state.notifications) || {};
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'announcements',
      where: [
        ['date', '>=', startDate],
        ['date', '<=', endDate],
        // ['source', '==', currentCompany]
      ],
      unwrap: true,
      onData: (data) => {
        const newData = data.sort((a, b) => {
          const e = dayjs(a.date).format('YYYY-MM-DD')
          const e2 = dayjs(b.date).format('YYYY-MM-DD')

          if (e > e2) {
            return -1
          } else if (e < e2) {
            return 1
          } else {
            return 0
          }
        })
        // console.log(newData)
        setAnnouncements(newData)
      }
    })

    return () => unsubscribe()
  }, [startDate, endDate]);

  useEffect(() => {
    // if (notifications && notifications.announcements !== 0) {
    //   updateAnnouncementCount()
    // }

    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.announcement.list' })
    }])
    return () => {
    };
  }, [location.pathname]);

  const [announcementPending, announcementReleased] = waitingForProcess()

  function waitingForProcess() {
    return [
      announcements.filter(r => {
        if (!r.v2) return false
        const history = r.history || []
        const currentStep = history.length > 0 ? history[history.length - 1].step : 0
        if (isNaN(currentStep) || currentStep === r.steps.length) return false
        return r.steps[currentStep].users.includes(currentUser.key)
      }),
      announcements.filter(r => {
        if (!r.v2) return true
        return r.status === 'done' && anyIntersection((userRights['announcement-view'] || []) , (r.target || []))
      })]
  }

  return (
    <Box p={3}>
      <div style={{ marginTop: '20px', paddingBottom: '60px', width: '100%', height: '100%', fontFamily: 'Roboto, sans-serif' }}>
        {userRights.hasUserRight('announcement-create',) && <div>
          <FabAdd onClick={() => navigate('/announcement/edit/new')} />
        </div>}
        <DateRangePickerField
          startDate={startDate}
          endDate={endDate}
          onStartDateChanged={setStartDate}
          onEndDateChanged={setEndDate}
        />
        <AnnouncementFullView onClose={() => setViewData(null)} data={viewData}></AnnouncementFullView>

        <AnnouncementList showVoidButton={true} showActions={false} canExpand={false} announcements={announcementReleased} title="announcement.release" />

        {userRights.hasUserRight('announcement-create') &&
          <AnnouncementList showActions={false} canExpand={true} announcements={announcementPending} title="announcement.pending" />
        }
      </div>
    </Box>
  );
}

export default Announcement;
