import React, { useEffect, useContext, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

import TabContainer from 'containers/TabContainer/TabContainer';
import ContextStore from 'modules/context';
import { useModuleMapping } from 'hooks/modules';

function SettingAttendance() {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { setBreadcrumbs } = useContext(ContextStore)
  const moduleMapping = useModuleMapping()

  const location = useLocation()
  const pathname = location.pathname
  const [activeTabIndex, setActiveTabIndex] = useState(undefined)

  const tabs = []
  if (moduleMapping.leaveOvertime || moduleMapping.punchClock) {
    tabs.push({
      name: 'reviewPolicy',
      label: formatMessage({ id: 'setAttendance.reviewPolicy.title' }),
      component: <Outlet />
    })
  }
  if (moduleMapping.schedule) {
    tabs.push({
      name: 'worktimePolicy',
      label: formatMessage({ id: 'setAttendance.worktimePolicy.title' }),
      component: <Outlet />
    })
    tabs.push({
      name: 'weeklyPolicy',
      label: formatMessage({ id: 'setAttendance.weeklyPolicy.title' }),
      component: <Outlet />
    })
  }
  tabs.push({
    name: 'systemPolicy',
    label: formatMessage({ id: 'setAttendance.systemPolicy.title' }),
    component: <Outlet />
  })

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.setting.root' })
    }])
  }, [pathname]);

  useEffect(() => {
    let tabName = ''
    if (pathname === '/setting/attendance/reviewPolicy') {
      tabName = 'reviewPolicy'
    } else if (pathname === '/setting/attendance/worktimePolicy') {
      tabName = 'worktimePolicy'
    } else if (pathname === '/setting/attendance/weeklyPolicy') {
      tabName = 'weeklyPolicy'
    } else if (pathname === '/setting/attendance/systemPolicy') {
      tabName = 'systemPolicy'
    }
    let index = tabs.findIndex(i => i.name === tabName)
    if (index === -1) {
      index = 0
      navigate(`/setting/attendance/${tabs[0].name}`);
    }
    if (activeTabIndex !== index) {
      setActiveTabIndex(index)
    }
    return () => {
    };
  }, [pathname]);

  const onTabSelected = (tabIndex, tabName) => {
    if (tabName === 'reviewPolicy') {
      navigate('/setting/attendance/reviewPolicy');
    } else if (tabName === 'worktimePolicy') {
      navigate('/setting/attendance/worktimePolicy');
    } else if (tabName === 'weeklyPolicy') {
      navigate('/setting/attendance/weeklyPolicy');
    } else if (tabName === 'systemPolicy') {
      navigate('/setting/attendance/systemPolicy');
    }
  }

  return (
    <div style={{ padding: '12px 24px 80px 24px' }}>
      <Toolbar sx={{ pl: 2, pr: 1 }}>
        <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="setAttendance.title" />
        </Typography>
      </Toolbar>
      <TabContainer activeTabIndex={activeTabIndex} tabs={tabs} onTabSelected={onTabSelected} />
    </div>
  );
}

export default SettingAttendance;
