import React, { useState, useContext } from 'react'
import { useIntl, FormattedMessage } from 'react-intl';

import { styled } from '@mui/material/styles';
import AvatarMui from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import RootContextStore from 'modules/rootContext';
import { login, resetPassword } from 'store';

const LoginPaper = styled('div')(({ theme }) => ({
  margin: theme.spacing(8, 4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const Avatar = styled(AvatarMui)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}));

const FormMui = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}));

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="/">
//         XXXX CO., LTD.
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

function LoginForm() {
  const { formatMessage } = useIntl()
  const [page, setPage] = useState('login');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [password, setPassword] = useState('');
  const { addMessage } = useContext(RootContextStore);

  async function onSubmit() {
    if (page === 'login') {
      try {
        await login({ email, password })
      } catch (ex) {
        // console.log(ex)
        if (ex.code === 'auth/user-not-found') {
          setEmailError(formatMessage({ id: 'login.error.email' }))
        } else if (ex.code === 'auth/wrong-password') {
          setPasswordError(formatMessage({ id: 'login.error.password' }))
        }
      }
    } else {
      await resetPassword(email).then((res) => {
        // console.log(res)
      }).catch(error => {
        console.log(error)
      })
      addMessage(formatMessage({ id: 'login.form.resetPasswordMessage' }));
      setPage('login')
    }
  }

  function switchPage(e) {
    e.stopPropagation()
    e.preventDefault()
    setPage(page === 'login' ? 'resetPassword' : 'login')
  }

  return (
    <Grid container component="main" justifyContent="center" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={0} square>
        <LoginPaper>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            <FormattedMessage id={page === 'login' ? 'login.form.login' : 'login.form.resetPassword'} />
          </Typography>
          <FormMui noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={formatMessage({ id: 'login.form.email' })}
              name="email"
              autoComplete="email"
              onChange={(e) => {
                if (emailError !== '') {
                  setEmailError('')
                }
                setEmail(e.target.value)
              }}
              autoFocus
              error={emailError !== ''}
              helperText={emailError}
            />
            {page === 'login' && <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={formatMessage({ id: 'login.form.password' })}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => {
                if (passwordError !== '') {
                  setPasswordError('')
                }
                setPassword(e.target.value)
              }}
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  onSubmit()
                }
              }}
              error={passwordError !== ''}
              helperText={passwordError}
            />}
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={onSubmit}
            >
              <FormattedMessage id={page === 'login' ? 'login.form.login' : 'login.form.submit'} />
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/" variant="body2" onClick={switchPage}>
                  <FormattedMessage id={page === 'login' ? 'login.form.forgotPassword' : 'login.form.loginPage'} />
                </Link>
              </Grid>
              <Grid item>
              </Grid>
            </Grid>
            <Box mt={5}>
              {/* <Copyright /> */}
            </Box>
          </FormMui>
        </LoginPaper>
      </Grid>
    </Grid>
  );
}

export default LoginForm;
