import { firestoreListener, firestoreDoc, updateFirestoreDoc, setFirestoreDoc, addFirestoreDoc, deleteFirestoreDoc } from 'modules/firebase';

function firebaseOld() {
  this.collectionId = undefined
  this.docId = undefined
  this.querys = []
}

firebaseOld.prototype = {
  collection: function(collectionId) {
    if (this.collectionId && this.docId) {
      if (Array.isArray(this.collectionId)) {
        this.collectionId = [...this.collectionId, this.docId, collectionId]
      } else {
        this.collectionId = [this.collectionId, this.docId, collectionId]
      }
      this.docId = undefined
    } else {
      this.collectionId = collectionId
    }
    return this
  },

  doc: function(docId) {
    this.docId = docId
    return this
  },

  where: function() {
    this.querys.push([...arguments])
    return this
  },

  onSnapshot: function(cb) {
    // console.log(this)
    return firestoreListener({
      collection: this.collectionId,
      doc: this.docId,
      where: this.querys.length ? this.querys : null,
      onSnapshot: cb,
    })
  },

  get: async function() {
    return new Promise((resolve, reject) => {
      return firestoreDoc({
        collection: this.collectionId,
        doc: this.docId,
        where: this.querys.length ? this.querys : null,
        onSnapshot: (doc) => {
          resolve(doc)
        },
      })
    })
  },

  update: function(data) {
    return updateFirestoreDoc({ collection: this.collectionId, doc: this.docId, data })
  },

  set: function(data) {
    if (this.docId) {
      return setFirestoreDoc({ collection: this.collectionId, doc: this.docId, data })
    } else {
      return addFirestoreDoc({ collection: this.collectionId, data })
    }
  },

  delete: function() {
    return deleteFirestoreDoc({ collection: this.collectionId, doc: this.docId })
  },
}

function firebaseV8() {
  return new firebaseOld()
}

export {
  firebaseV8
};
