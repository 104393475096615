import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import MuiFab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import EnhancedTable from 'components/EnhancedTable';
import ContextStore from 'modules/context';
import { CustomerExtDataTypeMapping } from 'constants';
import EditCustomerExtData from './EditCustomerExtData';

const Fab = styled(MuiFab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}));

function CustomerExtData({ tab, ...props }) {
  const { formatMessage } = useIntl()
  const [openDialog, setOpenDialog] = useState(false)
  const customerExtTab = useSelector(state => state.customerExtTab.data)
  const data = customerExtTab ? Object.keys(customerExtTab[tab.id] || {}).map(i => customerExtTab[tab.id][i]) : []

  const headerCells = [
    { text: 'name', align: 'left' },
    { text: 'type', align: 'left' },
    { text: 'status', align: 'right' }
  ].map(c => { c.text = formatMessage({ id: `setCustomer.extData.table.detail.${c.text}` }); return c })

  const rowCells = [
    { field: 'name', align: 'left' },
    { field: 'type', align: 'left' },
    { field: 'status', align: 'right' }
  ]

  const formatData = (data) => {
    const newData = { ...data }
    newData.status = newData.active ? '啟用' : '停用'
    newData.type = CustomerExtDataTypeMapping[newData.type]
    return newData
  }

  function handleClose() {
    setOpenDialog(false)
  }

  return (
    <div>
      {openDialog && <EditCustomerExtData
        tabId={tab.id}
        handleClose={handleClose}
        data={data}
        tabName={tab.name}
      />}
      <Grid container spacing={1}>
        <Grid item key="tables" xs={12} sm={12} md={12}>
          <EnhancedTable
            defaultOrder="asc"
            defaultOrderField="code"
            headerCells={headerCells}
            rowCells={rowCells}
            tableData={data.map(i => formatData(i))}
          />
        </Grid>
        <Grid item key="buttons" xs={12} sm={12} md={12}>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(true)}
            >
              <FormattedMessage id="button.edit" />
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}

CustomerExtData.propTypes = {
  tab: PropTypes.object.isRequired,
};

function SettingCustomerPage({ tableCells, tableName }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const location = useLocation()
  const config = useSelector(state => state.config.data)
  const extDataMappoing = Object.keys(config.customerExtTab || {}).map(i => config.customerExtTab[i]).reduce((acc, cur) => { acc[cur.id] = cur; return acc }, {})
  const tabs = tableName === 'extData' ?
    Object.keys(config?.customerExtTab || {}).map(p => config.customerExtTab[p]) :
    Object.keys(config[tableName] || {}).map(p => config[tableName][p])

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.setting.root' })
    }])
  }, [location.pathname]);

  const headerCells = tableCells.reduce((acc, cur) => {
    acc.push({ text: cur, align: 'left' })

    return acc
  }, []).concat({ text: 'status', align: 'right' }).map(c => { c.text = formatMessage({ id: `setCustomer.${tableName}.table.header.${c.text}` }); return c })

  const rowCells = tableCells.reduce((acc, cur) => {
    acc.push({ field: cur, align: 'left' })
    return acc
  }, []).concat({ field: 'status', align: 'right' })

  const formatData = (data) => {
    const newData = { ...data }
    newData.status = newData.active ? '啟用' : '停用'

    if (tableName === 'extData') {
      newData.type = newData.related ? '關聯' : '一般'
    } else {
      newData.requiredItems = extDataMappoing[newData.requiredItems] ? extDataMappoing[newData.requiredItems].name : '無'
      newData.items = extDataMappoing[newData.items] ? extDataMappoing[newData.items].name : '無'
    }


    return newData
  }

  return (
    <Box style={{ marginTop: '20px' }}>
      {userRights.hasUserRight('setting-edit') &&
        <Link to={`/setting/customer/${tableName}/edit`}>
          <Fab color="primary">
            <EditIcon />
          </Fab>
        </Link>
      }
      {tableName === 'extData' ? <EnhancedTable
        defaultOrder="asc"
        defaultOrderField="code"
        headerCells={headerCells}
        rowCells={rowCells}
        tableData={tabs.map(i => formatData(i))}
        getExpandContent={tab =>
          <CustomerExtData
            tab={tab}
          />
        }
      /> : <EnhancedTable
        defaultOrder="asc"
        defaultOrderField="code"
        headerCells={headerCells}
        rowCells={rowCells}
        tableData={tabs.map(i => formatData(i))}
      />}
    </Box>
  );
}

SettingCustomerPage.propTypes = {
  tableCells: PropTypes.array.isRequired,
  tableName: PropTypes.string.isRequired,
};

export default SettingCustomerPage;
