import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { arrayMove } from '@dnd-kit/sortable';

import { styled } from '@mui/material/styles';
import MuiFab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';

import EnhancedTable from 'components/EnhancedTable';
import ButtonProgress from 'components/ButtonProgress';
import DndContainer from 'components/DndContainer';
import DragHandle from 'components/DragHandle';
import { useModuleMapping } from 'hooks/modules';
import { callFunction } from 'modules/firebase';
import { getRandomHash } from 'modules/uitls';

const Fab = styled(MuiFab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}));

const rowStyle = {
  borderTop: 'solid 1px #efefef',
  width: '100%',
  height: '80px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#fff',
  padding: '0px 16px 0px 8px',
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
  zIndex: 10000,
}

const cellStyle = {
  marginLeft: '8px',
  marginRight: '8px',
  flexGrow: 2
}

const SortableItem = ({ item, index, onChange }) => {
  return <div style={rowStyle}>
    <DragHandle />
    <div style={cellStyle}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div style={{ marginTop: '8px' }}>{item.name}</div>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
            <div>隱藏</div>
            <Switch
              checked={item.show}
              onChange={e => onChange(index, { name: 'show' }, e.target.checked)}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <div>顯示</div>
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
};

function EditHomePageDailog({ handleClose, tableName, data }) {
  const { formatMessage } = useIntl()
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [currentData, setCurrentData] = useState(data.map(i => ({ ...i, id: getRandomHash() }) ))

  async function saveSettingHomePage() {
    setLoadingApprove(true)

    try {
      await callFunction('saveConfigurations', { type: tableName, ...currentData })
    } catch (ex) {
      console.log(ex)
    }
    setLoadingApprove(false)
    handleClose()
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = currentData.findIndex(e => e.id === active.id);
      const newIndex = currentData.findIndex(e => e.id === over.id);
      setCurrentData(arrayMove(currentData, oldIndex, newIndex))
    }
  }

  const formatData = (data) => {
    const newData = { ...data }
    if(data.subItem !== 'none') {
      if(data.item === 'setting') {
        newData.name = formatMessage({ id: 'sideMenu.setting.root' })  + formatMessage({ id: `sideMenu.${data.item}.${data.subItem}` })
      } else {
        newData.name = formatMessage({ id: `sideMenu.${data.item}.${data.subItem}` })
      }
    } else {
      newData.name = formatMessage({ id: `sideMenu.${data.item}` })
    }

    return newData
  }

  function updateCurrentData(index, field, value) {
    let newValue = value

    const newData = [...currentData]
    newData[index] = { ...newData[index], [field.name]: newValue }

    setCurrentData(newData)
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Dialog
        fullWidth={true}
        open={true}
        maxWidth="lg"
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formatMessage({ id: `settingHome.${tableName}.edit.title` })}</DialogTitle>
        <DialogContent>
          <Paper sx={{ overflow: 'hidden' }}>
            <DndContainer
              dargger
              items={currentData.map(i => formatData(i)) }
              handleDragEnd={handleDragEnd}
              ChildItem={SortableItem}
              onChange={updateCurrentData}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <ButtonProgress
            handleClose={handleClose}
            handleClick={() => saveSettingHomePage()}
            loading={loadingApprove}
            buttonText='button.save'
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditHomePageDailog.prototype = {
  handleClose: PropTypes.func.isRequired,
  tableName: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired
}

function SettingHomePage({ tableCells, tableName }) {
  const { formatMessage } = useIntl()
  const userRights = useSelector(state => state.userRights)
  const [openDailog, setOpenDailog] = useState(false)
  const moduleMapping = useModuleMapping()
  const config = useSelector(state => state.config.data)
  const data = Object.keys(config[tableName] || {}).map(i => config[tableName][i])
  const filteredData = filterByModules(data)

  function filterByModules(items) {
    return items.filter(i => !i.modules || i.modules.some(m => moduleMapping[m]))
  }

  const headerCells = tableCells.reduce((acc, cur) => {
    acc.push({ text: cur, align: 'left' })

    return acc
  }, []).concat({ text: 'status', align: 'right' }).map(c => { c.text = formatMessage({ id: `settingHome.${tableName}.table.header.${c.text}` }); return c })

  const rowCells = tableCells.reduce((acc, cur) => {
    acc.push({ field: cur, align: 'left' })
    return acc
  }, []).concat({ field: 'status', textColor: true, align: 'right' })

  const formatData = (data) => {
    const newData = { ...data }

    newData.key = data.subItem !== 'none' ? `${data.item}_${data.subItem}` : data.item
    newData.status = data.show ? '顯示' : '隱藏'
    newData.textColor = data.show ? '#00b900' : '#bab7b7'

    if(data.subItem !== 'none') {
      if(data.item === 'setting') {
        newData.name = formatMessage({ id: 'sideMenu.setting.root' })  + formatMessage({ id: `sideMenu.${data.item}.${data.subItem}` })
      } else {
        newData.name = formatMessage({ id: `sideMenu.${data.item}.${data.subItem}` })
      }
    } else {
      newData.name = formatMessage({ id: `sideMenu.${data.item}` })
    }

    return newData
  }

  return (
    <Box style={{ marginTop: '20px' }}>
      {openDailog &&
        <EditHomePageDailog
          handleClose={() => setOpenDailog(false)}
          tableName={tableName}
          data={filteredData}
        />
      }
      {userRights.hasUserRight('setting-edit') &&
        <Fab onClick={() => setOpenDailog(true)} color="primary">
          <EditIcon />
        </Fab>
      }
      <EnhancedTable
        defaultOrder="asc"
        defaultOrderField="code"
        headerCells={headerCells}
        rowCells={rowCells}
        tableData={filteredData.map(i => formatData(i))}
      />
    </Box>
  );
}

SettingHomePage.propTypes = {
  tableName: PropTypes.string.isRequired,
};

export default SettingHomePage;
