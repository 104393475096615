import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

import InsertCommentIcon from '@mui/icons-material/InsertComment';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';

import ContextStore from 'modules/context';
import { useModuleMapping } from 'hooks/modules';
import { moibleMedia, tabletMedia } from 'constants';

const ComponentNone = styled('div')(({ theme }) => ({
  [tabletMedia]: {
    display: 'none'
  },
  [moibleMedia]: {
    display: 'none'
  }
}));

function AppointmentButtonRow() {
  const { uiState, setUiState, currentCompany } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const moduleMapping = useModuleMapping()
  const defaultMode = moduleMapping.doctor ? 'doctor' : 'salesRep'
  const scheduleMode = uiState.scheduleMode || defaultMode

  function getAddAppointment() {
    if (userRights.hasUserRightForVendor('appointment-edit', currentCompany) && ((moduleMapping.doctor && scheduleMode === 'doctor') || !moduleMapping.doctor)) {
      return <Button
        sx={{ color: '#fff' }}
        onClick={() => setUiState({
          ...uiState,
          clickMenuData: null,
          showAppointmentSidebar: true,
          showCommentSidebar: false,
          showDoctorSchedule: false
        })}
      >
        <AddIcon />
        <ComponentNone>
          <FormattedMessage id={'header.appointment.NewReservation'} />
        </ComponentNone>
      </Button>
    } else {
      return
    }
  }

  return (
    <>
      {getAddAppointment()}
      {userRights.hasUserRightForVendor('appointment-edit', currentCompany) && (moduleMapping.doctor && scheduleMode === 'doctor') &&<Button
        sx={{ color: '#fff' }}
        onClick={() => setUiState({
          ...uiState,
          clickMenuData: null,
          showCommentSidebar: true,
          showAppointmentSidebar: false,
          showDoctorSchedule: false
        })}
      >
        <InsertCommentIcon />
        <ComponentNone>
          <FormattedMessage id={'header.appointment.NewComment'} />
        </ComponentNone>
      </Button>}

      {moduleMapping.doctor && <Button
        sx={{ color: '#fff' }}
        onClick={() => setUiState({
          ...uiState,
          scheduleMode: uiState.scheduleMode === 'salesRep' ? 'doctor' : 'salesRep',
        })}
      >
        {uiState.scheduleMode !== 'salesRep' ? <ToggleOffIcon /> : <ToggleOnIcon />}
        <ComponentNone>
          <FormattedMessage id={'header.appointment.toggleMode'} />
        </ComponentNone>
      </Button>}

      {moduleMapping.doctor && <Button
        sx={{ color: '#fff' }}
        onClick={() => setUiState({
          ...uiState,
          clickMenuData: null,
          showCommentSidebar: false,
          showAppointmentSidebar: false,
          showDoctorSchedule: true
        })}
      >
        <ViewWeekIcon />
        <ComponentNone>
          <FormattedMessage id={'header.appointment.doctorSchedule'} />
        </ComponentNone>
      </Button>}
    </>
  )
}

export default AppointmentButtonRow;
