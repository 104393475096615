import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';

import DatePickerField from 'components/DatePickerField';
import ButtonProgress from 'components/ButtonProgress';
import ContextStore from 'modules/context';
import { callFunction } from 'modules/firebase';
import { firebaseV8 } from 'modules/firebaseV8';

function EditStaffSalaryPage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const { staffId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const userMapping = useSelector(state => state.users.data);
  const currentStaff = userMapping && (userMapping[staffId] || {})
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [currentData, setCurrentData] = useState({
    partTime: false,
    basicSalary: '',
    hourlyPay: '',
    // fullAttendance: '',
    subsistenceAllowance: '',
    jobAllowance: '',
    licenseAllowance: '',
    insuredDate: dayjs().format('YYYY-MM-DD'),
    insuredAmount: '',
    dependents: 0,
    otherPlus: '',
    incomeTax: '',
    secondGenerationNHI: '',
    otherMinus: '',
    note: ''
  })

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('userSalarys').doc(staffId).onSnapshot(snapshot => {
      setCurrentData(data => ({
        ...data,
        ...snapshot.data()
      }))
    }, err => { })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.staffManagement.salary' })
    }])
    return () => {
    };
  }, [location.pathname]);

  function updateData(field, value) {
    let newValue = value
    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }
    let err = validateField(field, value)

    let newData = { ...currentData, [field.name]: newValue, [`${field.name}_err`]: err }
    setCurrentData(newData)
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    return ''
  }

  const numberRule = /[^0-9]/g
  const _fields = [
    // { name: 'fullAttendance', type: 'text', sm: 3, md: 3, allowCharacter: numberRule, order: 1 },
    { name: 'subsistenceAllowance', type: 'text', sm: 3, md: 3, allowCharacter: numberRule, order: 2 },
    { name: 'jobAllowance', type: 'text', sm: 3, md: 3, allowCharacter: numberRule, order: 3 },
    { name: 'licenseAllowance', type: 'text', sm: 3, md: 3, allowCharacter: numberRule, order: 4 },
    { name: 'otherPlus', type: 'text', sm: 3, md: 3, allowCharacter: numberRule, order: 5 },

    { name: 'line', type: '-', sm: 12, md: 12, order: 6 },

    { name: 'insuredDate', required: true, type: 'date', sm: 3, md: 3, order: 7 },
    { name: 'insuredAmount', type: 'text', allowCharacter: numberRule, sm: 3, md: 3, order: 8 },
    { name: 'dependents', type: 'select', sm: 3, md: 3, order: 9 },

    { name: 'line2', type: '-', sm: 12, md: 12, order: 10 },

    { name: 'incomeTax', type: 'text', sm: 3, md: 3, allowCharacter: numberRule, order: 11 },
    { name: 'secondGenerationNHI', type: 'text', sm: 3, md: 3, allowCharacter: numberRule, order: 12 },
    { name: 'otherMinus', type: 'text', sm: 3, md: 3, allowCharacter: numberRule, order: 13 },

    { name: 'note', type: 'text', multiline: true, sm: 12, md: 12, order: 14 },
  ]

  if (currentData.partTime) {
    _fields.push({ name: 'hourlyPay', type: 'text', sm: 3, md: 3, allowCharacter: numberRule, order: 0 })
  } else {
    _fields.push({ name: 'basicSalary', type: 'text', sm: 3, md: 3, allowCharacter: numberRule, order: 0 })
  }

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  async function onhandleSave() {
    setLoadingApprove(true)
    let err = {}
    let newData = currentData

    for (let field of fields) {
      if (field.required && currentData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }

    if (Object.keys(err).length > 0) {
      newData = { ...currentData, ...err }
    }
    if (Object.keys(err).length > 0) {
      setCurrentData(newData)
      return setLoadingApprove(false)
    }

    const userData = {
      partTime: currentData.partTime,
      basicSalary: Number(currentData.basicSalary),
      hourlyPay: Number(currentData.hourlyPay),
      dependents: Number(currentData.dependents),
      // fullAttendance: Number(currentData.fullAttendance),
      subsistenceAllowance: Number(currentData.subsistenceAllowance),
      jobAllowance: Number(currentData.jobAllowance),
      licenseAllowance: Number(currentData.licenseAllowance),
      insuredDate: dayjs(currentData.insuredDate).format('YYYY-MM-DD'),
      insuredAmount: Number(currentData.insuredAmount),
      otherPlus: Number(currentData.otherPlus),
      incomeTax: Number(currentData.incomeTax),
      secondGenerationNHI: Number(currentData.secondGenerationNHI),
      otherMinus: Number(currentData.otherMinus),
      note: currentData.note
    }


    try {
      await callFunction('saveUserSalary', { id: staffId, ...userData })
      setLoadingApprove(false)
      navigate('/staffManagement/staff')
    } catch (ex) {
      setLoadingApprove(false)
      console.log(ex)
    }
  }

  function createField(field) {
    if (field.type === '-') {
      return <Grid item key={field.order} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    }

    let labelText = formatMessage({ id: `staff.salary.${field.name}` })
    let newValue = currentData[field.name]
    const selectFields = {
      dependents: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    };

    if (field.type === 'text') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={labelText}
          value={newValue}
          required={field.required}
          fullWidth
          size="small"
          margin="dense"
          variant="outlined"
          onChange={e => updateData(field, e.target.value)}
          error={currentData[`${field.name}_err`] ? true : false}
          helperText={currentData[`${field.name}_err`]}
        />
      </Grid>
    } else if (field.type === 'date') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md} sx={{ mt: '3px' }}>
        <DatePickerField
          fullWidth
          margin="dense"
          label={labelText}
          value={newValue !== '' ? dayjs(newValue) : null}
          onChange={date => updateData(field, date)}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    } else if (field.type === 'select') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={labelText}
          value={newValue}
          required={field.required}
          fullWidth
          select
          size="small"
          margin="dense"
          variant="outlined"
          onChange={e => updateData(field, e.target.value)}
          error={currentData[`${field.name}_err`] ? true : false}
          helperText={currentData[`${field.name}_err`]}
        >
          {
            selectFields[field.name].map((select, idx) => {
              return <MenuItem key={select} value={select}>{select}</MenuItem>
            })
          }
        </TextField>
      </Grid>
    }
  }

  return (
    <Grid container spacing={2} sx={{ p: '40px 20px' }}>
      <Grid item xs={12} sm={12} md={12} >
        <div style={{ fontSize: '18px' }}>
          {formatMessage({ id: 'staff.profile.salaryInformation' }, { name: currentStaff.displayName })}
        </div>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {formatMessage({ id: 'staff.salary.fullTime' })}
        <Switch
          checked={currentData.partTime}
          onChange={e => updateData({ name: 'partTime' }, e.target.checked)}
          color="primary"
          name="partTime"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
        {formatMessage({ id: 'staff.salary.partTime' })}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
          {fields.map(field => createField(field))}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}>
        <ButtonProgress
          handleClick={() => onhandleSave()}
          handleClose={() => navigate('/staffManagement/staff')}
          loading={loadingApprove}
          buttonText='button.save'
        />
      </Grid>
    </Grid>
  );
}

export default EditStaffSalaryPage;
