import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

import ButtonProgress from 'components/ButtonProgress';
import { unwrap } from 'modules/uitls';
import { callFunction } from 'modules/firebase';
import { firebaseV8 } from 'modules/firebaseV8';

export default function EditSystemPolicy({ handleClose }) {
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)
  const [systemData, setSystemData] = useState({
    annualLeaveType: ''
  })

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('configuration').doc('systems').onSnapshot(snapshot => {
      setSystemData({ ...systemData, ...unwrap(snapshot.data()) })
    }, err => { })
    return () => unsubscribe()
  }, []);

  async function saveSystem() {
    setLoading(true)
    let err = {}
    let newData = systemData

    for (let field of fields) {
      if (field.required && systemData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }

    if (Object.keys(err).length > 0) {
      newData = { ...systemData, ...err }
    }
    if (Object.keys(err).length > 0) {
      setSystemData(newData)
      return setLoading(false)
    }


    const updateData = {
      annualLeaveType: systemData.annualLeaveType
    }

    try {
      await callFunction('saveSystems', { ...updateData })
      setLoading(false)
      handleClose()
    } catch (ex) {
      console.log(ex)
    }
  }

  function updateData(field, value) {
    let newValue = value
    let err = validateField(field, value)

    let newData = {}
    newData = { ...systemData, [field.name]: newValue, [`${field.name}_err`]: err }
    setSystemData(newData)

  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    return ''
  }

  const fields = [
    { name: 'annualLeaveType', required: true, select: true, root: true },
  ].map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  function createField(field) {
    let selectFields = []

    if (field.name === 'annualLeaveType') {
      selectFields = ['anniversary', 'calendar']
    }

    return (
      <Grid key={field.name} item xs={6} sm={4} md={4}>
        <TextField
          multiline={field.multiline}
          type="text"
          select={field.select}
          required={field.required}
          label={field.root ?
            formatMessage({ id: `setAttendance.systemPolicy.table.${field.name}.root` }) :
            formatMessage({ id: `setAttendance.systemPolicy.table.${field.name}` })
          }
          value={systemData[field.name]}
          onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
          fullWidth
          size="small"
          variant="outlined"
          error={systemData[`${field.name}_err`] ? true : false}
          helperText={systemData[`${field.name}_err`]}
        >
          {
            field.select && selectFields.map((select, idx) => {
              if (field.name === 'annualLeaveType') {
                return <MenuItem key={`${idx}`} value={select}>{formatMessage({ id: `setAttendance.systemPolicy.table.${field.name}.${select}` })}</MenuItem>
              } else {
                return <MenuItem key={`${idx}`} value={select}>{select}</MenuItem>
              }
            })
          }
        </TextField>
      </Grid>
    )
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Dialog
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        maxWidth='md'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{formatMessage({ id: 'setAttendance.systemPolicy.edit' })}</DialogTitle>
        <Grid sx={{ margin: '10px' }} container spacing={2}>
          {fields.map(field => createField(field))}
        </Grid>
        <DialogActions>
          <ButtonProgress handleClick={() => saveSystem()} handleClose={handleClose} loading={loading} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditSystemPolicy.propTypes = {
  handleClose: PropTypes.func.isRequired
};
