import React, { useEffect, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import FabAdd from 'components/FabAdd';
import DateRangePickerField from 'components/DateRangePickerField';
import EnhancedTableRow from 'components/EnhancedTableRow';
import EnhancedTableHead from 'components/EnhancedTableHead';
import { getComparator, stableSort } from 'modules/sort';
import ContextStore from 'modules/context';
import EditPunchClockExceptionDialog from './EditPunchClockExceptionDialog';
import DeletePunchClockExceptionDialog from './DeletePunchClockExceptionDialog';
import { useDepartments } from 'hooks/departments';
import { firebaseV8 } from 'modules/firebaseV8';

function PunchClockException() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentCompany } = useContext(ContextStore)
  const location = useLocation()
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(dayjs().add(3, 'months').format('YYYY-MM') + `${dayjs().add(3, 'months').daysInMonth()}`)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')
  const [punchClockException, setPunchClockException] = useState([])
  const vendorMapping = useSelector(state => state.vendors.data)
  const [openDialog, setOpenDialog] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(null)
  const departments = useDepartments()
  const depMapping = departments.reduce((acc, cur) => {
    acc[cur.id] = { ...cur };
    return acc
  }, {})

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.staffManagement.punchClockException' })
    }])
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('punchClockExceptions').where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(snapshot => {
      const punchClockException = []
      snapshot.forEach(doc => {
        punchClockException.push({ ...doc.data(), id: doc.id })
      });
      setPunchClockException(punchClockException)
    }, err => { })

    return () => unsubscribe()
  }, [startDate, endDate, currentCompany]);

  const headerCells = [
    { text: 'date', sort: 'date' },
    { text: 'time', sort: 'time' },
    { text: 'source', sort: 'source' },
    { text: 'type', sort: 'type' },
    { text: 'reason', sort: 'reason' },
    { text: 'department', sort: 'department' },
  ].map(c => { c.text = formatMessage({ id: `punchClockException.table.${c.text}` }); return c })

  const rowCells = [
    { field: 'date' },
    { field: 'time' },
    { field: 'sourceName' },
    { field: 'typeName' },
    { field: 'reason' },
    { field: 'departmentName' },
  ]

  const formatData = (pcException) => {
    const newData = { ...pcException }
    newData.time = `${newData.startTime}-${newData.endTime}`
    newData.typeName = newData.type === 'off' ? '補假' : '補班'
    newData.departmentName = newData.department.filter(d => d !== 'all').map(s => depMapping[s] && depMapping[s].name).join(', ')
    newData.sourceName = newData.source.map(s => vendorMapping[s].name).join(' ,')
    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <div style={{ width: '100%', fontFamily: 'PingFang TC,Roboto,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif', padding: '40px 20px' }}>
      {openDialog && <EditPunchClockExceptionDialog
        handleClose={() => setOpenDialog(null)}
        data={openDialog.data}
        type={openDialog.type}
        departmentMapping={depMapping}
        departments={departments}
        currentCompany={currentCompany}
      />}
      {openDeleteDialog && <DeletePunchClockExceptionDialog
        pcExceptionId={openDeleteDialog.id}
        pcExceptionDate={openDeleteDialog.date}
        handleClose={() => setOpenDeleteDialog(null)}
      />}
      <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Grid item xs={12} sm={5} md={5}>
          <DateRangePickerField
            startDate={startDate}
            endDate={endDate}
            onStartDateChanged={setStartDate}
            onEndDateChanged={setEndDate}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ marginTop: '10px' }}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={punchClockException.length}
                actionButton
              />
              <TableBody>
                {stableSort(punchClockException.map(r => formatData(r)), getComparator(order, orderBy)).map(pcException => (
                  <EnhancedTableRow
                    key={pcException.id}
                    rowCells={rowCells}
                    cellData={pcException}
                    onRowClick={() => { }}
                    actionIcons={<>
                      {!pcException.void && <span>
                        <Tooltip title={formatMessage({ id: 'button.edit' })}>
                          <IconButton
                            onClick={() => setOpenDialog({ type: 'edit', data: pcException })}
                            size="large">
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={formatMessage({ id: 'button.delete' })}>
                          <IconButton onClick={() => setOpenDeleteDialog({ id: pcException.id, date: pcException.date })}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </span>}
                    </>}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <div>
          <FabAdd onClick={() => setOpenDialog({ type: 'add', data: {} })} />
        </div>
      </Grid>
    </div >
  );
}

export default PunchClockException;
