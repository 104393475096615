import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';

import TabContainer from 'containers/TabContainer/TabContainer';
import FabAdd from 'components/FabAdd';
import ContextStore from 'modules/context';
import StockRequisitionTab from './StockRequisitionTab';

function StockRequisition({ formName }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const userRights = useSelector(state => state.userRights)
  const [activeTabIndex, setActiveTabIndex] = useState(undefined)
  const { tabName } = useParams()

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: `sideMenu.stock.${formName}` })
    }])
    const pathname = location.pathname
    if (pathname === `/stock/${formName}/pending` && activeTabIndex !== 0) {
      setActiveTabIndex(0)
    } else if (pathname === `/stock/${formName}/done` && activeTabIndex !== 1) {
      setActiveTabIndex(1)
    } else if (pathname === `/stock/${formName}/void` && activeTabIndex !== 2) {
      setActiveTabIndex(2)
    }
    return () => {
    };
  }, [location.pathname]);

  const onTabSelected = (tabIndex) => {
    if (tabIndex === 0) {
      navigate(`/stock/${formName}/pending`);
    } else if (tabIndex === 1) {
      navigate(`/stock/${formName}/done`);
    } else {
      navigate(`/stock/${formName}/void`);
    }
  };

  const tabs = [
    { label: formatMessage({ id: `${formName}.tab.pending` }), component: <StockRequisitionTab formName={formName} /> },
    { label: formatMessage({ id: `${formName}.tab.done` }), component: <StockRequisitionTab formName={formName} /> },
    { label: formatMessage({ id: `${formName}.tab.void` }), component: <StockRequisitionTab formName={formName} /> },
  ]
  const defaultSelect = tabName === 'void' ? 2 : (tabName === 'done' ? 1 : 0)

  return (
    <Box p={3}>
      {userRights.hasUserRight(`${formName}-create`) && <FabAdd to={`/stock/${formName}/edit/new`} />}
      <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} />
    </Box>
  );
}

export default StockRequisition;
