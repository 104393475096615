const i18n = {
  'requisition': {
    'tab': {
      'pending': '等候中',
      'done': '已完成',
      'void': '已作廢',
    },
    'name': '請購單',
    'table': {
      'pending': {
        'title': '請購單列表 - 待簽核',
      },
      'done': {
        'title': '請購單列表 - 待採購',
      },
      'title': '請購單列表',
      'selected': '已選擇 {count} 筆請購單',
      'header': {
        'sn': '請購單號',
        'source': '請購單位',
        'sourceName': '請購單位',
        'createdBy': '請購人',
        'date': '請購日期',
        'expectedDate': '需求日期',
      },
      'detail': {
        'sn': '請購單號',
        'source': '請購單位',
        'sourceName': '請購單位',
        'createdBy': '請購人',
        'date': '請購日期',
        'expectedDate': '需求日期',
        'note': '備註',
        'merchandise': '商品',
        'merchandiseName': '商品名稱',
        'merchandiseCode': '商品品號',
      },
      'addToShoppingCart': '新增到採購清單',
    },
    'message': {
      'addToShoppingCart': '請購單 [{id}] 的所有商品已新增至採購清單',
    },
    'dialog': {
      'title': {
        'add': '新增請購單',
        'edit': '編輯請購單',
      }
    },
    'merchandise': {
      'code': '品號',
      'name': '品名',
      'unitPrice': '單價',
      'amount': '數量',
      'sku': '庫存單位',
      'orderUnit': '單位',
      'price': '金額',
      'note': '備註',
      'statusText': '狀態',
      'status': {
        'shoppingCart': '在採購清單中',
        'purchaseOrder': '採購單待簽核',
        'purchaseOrderDone': '已採購',
      }
    }
  },
  'editRequisition': {
    'table': {
      'title': '商品清單',
      'selected': '已選擇 {count} 個項目',
    },
    'lockSource': '開始新增商品',
    'addMerchandise': '新增商品',
    'removeMerchandise': '移除商品',
  },
}
export default i18n
