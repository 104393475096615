import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';

import SalesOrderInfoDiablog from 'components/SalesOrderInfoDiablog';
import SelectCustomerDialog from 'components/SelectCustomerDialog';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import EnhancedTable from 'components/EnhancedTable';
import ContextStore from 'modules/context';
import { unique } from 'modules/uitls';
import { addError, removeError } from 'modules/editor';
import { firestoreListener, callFunction } from 'modules/firebase';

function EditTransferServicePage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const navigate = useNavigate()
  const { formId } = useParams()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [salesOrderInfo, setSalesOrderInfo] = useState(null);
  const userMapping = useSelector(state => state.users.data)
  const sourceMapping = useSelector(state => state.internalVendors.data)
  const productMapping = useSelector(state => state.products.data)
  const vendorMapping = useSelector(state => state.vendors.data)

  const [customers, setCustomers] = useState([]);
  const [customerMapping, setCustomerMapping] = useState({});
  const [openDialog, setOpenDialog] = useState('')
  const companyData = currentUser.company?.reduce((acc, cur) => { if (userRights['transferService-create'].includes(cur)) { acc = true } return acc }, false) ?
    unique(currentUser.company?.concat(userRights['transferService-create'])) : userRights['transferService-create']
  const [data, setData] = useState({
    id: formId,
    customer: '',
    newCustomer: '',
    note: '',
    products: [],
    source:  companyData[0]
  });

  useEffect(() => {
    const breadcrumbs = [{
      link: '/services/transfer',
      text: formatMessage({ id: 'sideMenu.services.transfer' })
    }]
    if (formId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'transferService.add' }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: 'transferService.edit' }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      array: true,
      onData: ({ mapping, data }) => {
        setCustomers(data)
        setCustomerMapping(mapping)
      }
    })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = data.customer ? firestoreListener({
      collection: 'purchasedProducts',
      where: [
        ['customer', '==', data.customer],
        ['available', '==', true]
      ],
      onData: (pp) => {
        const newPP = pp.filter(d => d.available).reduce((acc, cur) => {
          acc.push({
            id: cur.id,
            product: cur.product,
            quantity: cur.quantity,
            taken: cur.taken,
            salesOrder: cur.salesOrder,
            salesOrderSn: cur.salesOrderSn,
            salesOrderItem: cur.salesOrderItem,
            amount: 0,
          })
          return acc
        }, [])
        const newData = { ...data, products: newPP }
        setData(newData)
      }
    }) : null
    return () => unsubscribe?.()
  }, [data.customer]);

  function formatData(data) {
    const newData = { ...data }
    newData.name = productMapping[newData.product].name
    newData.nickname = productMapping[newData.product].nickname
    newData.quantityTaken = `${newData.quantity - newData.taken} / ${newData.quantity}`

    return newData
  }

  function updateData(field, value) {
    let newValue = value
    let err = validateField(field, value)
    let newData = { ...data, [field.name]: newValue, [`${field.name}_err`]: err }
    if(field.name === 'customer') {
      newData.newCustomer = ''
    }
    setData(newData)
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    return ''
  }

  function handleClose() {
    navigate('/services/transfer');
  }

  async function handleSave() {
    setLoading(true)
    let err = false;

    const fields = [
      { name: 'customer' },
      { name: 'newCustomer' },
    ]

    for (const field of fields) {
      if (data[`${field.name}_err`] !== undefined && data[`${field.name}_err`] !== '') {
        setLoading(false);
        return
      }
    }

    const errMsg = formatMessage({ id: 'form.field.isRequired' })
    for (const product of data.products) {
      if (!product.amount && parseInt(product.amount) !== 0) {
        err = true
        addError(product, 'amount', errMsg)
      }
    }

    if (err) {
      setData({ ...data })
      setLoading(false);
      return
    }

    const newProducts = data.products.reduce((acc, cur) => {
      if (cur.amount > 0) {
        acc[cur.id] = { productId: cur.product, amount: parseInt(cur.amount) }
      }

      return acc
    }, {})

    const updateData = {
      customer: data.customer,
      newCustomer: data.newCustomer,
      note: data.note,
      products: newProducts,
      source: data.source
    }

    try {
      await callFunction('saveTransferService', { ...updateData })
    } catch (ex) {
      console.log(ex)
    }
    handleClose()
  }

  function onCellValueChanged(cellData, field, value) {
    const m = data.products.find(i => i.id === cellData.id)
    if (!m) {
      return
    }

    if (field === 'amount') {
      m.amount = value
      if (isNaN(value) || value === '') {
        addError(m, field, '數量錯誤')
      } else if (parseInt(value) > (parseInt(cellData.quantity) - parseInt(cellData.taken))) {
        addError(m, field, '可用數量不足')
      } else {
        removeError(m, field)
      }
    } else {
      removeError(m, field)
      m[field] = value
    }
    updateData({ name: cellData.table }, data[cellData.table]);
  }

  const headerCells = [
    { text: 'name' },
    { text: 'quantityTaken' },
    { text: 'sn' },
    { text: 'amount' },
  ].map(c => { c.text = formatMessage({ id: `transferService.service.${c.text}` }); return c })

  const rowCells = [
    { field: 'nickname', tooltip: 'name' },
    { field: 'quantityTaken' },
    { field: 'salesOrderSn', type: 'info', onButtonClick: showSalesOrder },
    {
      field: 'amount',
      type: 'input-number',
      required: true,
      label: '數量',
      onValueChanged: onCellValueChanged
    },
  ];

  function showSalesOrder(key, field, data) {
    setSalesOrderInfo(data)
  }

  return (
    <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
      {['customer', 'newCustomer'].includes(openDialog) && <SelectCustomerDialog
        handleClose={() => setOpenDialog('')}
        handleSave={customer => updateData({ name: openDialog }, customer.id)}
        customers={openDialog === 'customer' ? customers : customers.filter(c => c.id !== data.customer) || []}
        dialogTitle={formatMessage({ id: 'selectCustomerDialog.title' })}
      />}
      {salesOrderInfo && <SalesOrderInfoDiablog
        salesOrderId={salesOrderInfo.salesOrder}
        highlightItems={[salesOrderInfo.salesOrderItem]}
        userMapping={userMapping}
        customerMapping={customerMapping}
        sourceMapping={sourceMapping}
        onClose={() => setSalesOrderInfo(null)}
      />}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            type="text"
            size="small"
            select={userRights['transferService-create']?.length > 1}
            disabled={userRights['transferService-create']?.length <= 1}
            label={formatMessage({ id: 'transferService.table.detail.source' })}
            variant="outlined"
            value={userRights['transferService-create']?.length > 1 ? data.source : vendorMapping[data.source]?.name}
            onChange={(e) => updateData({ name: 'source' }, e.target.value)}
            fullWidth
          >
            {
              companyData.map(c => {
                return <MenuItem key={c} value={c}>{vendorMapping[c].name}</MenuItem>
              })
            }
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            type="text"
            size="small"
            label={formatMessage({ id: 'transferService.table.detail.customer' })}
            variant="outlined"
            value={data.customer ? customerMapping[data.customer].name : ''}
            onClick={() => setOpenDialog('customer')}
            error={data.customer_err ? true : false}
            helperText={data.customer_err}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            type="text"
            size="small"
            label={formatMessage({ id: 'transferService.table.detail.newCustomer' })}
            variant="outlined"
            disabled={!data.customer}
            value={data.newCustomer ? customerMapping[data.newCustomer].name : ''}
            onClick={() => { if (data.customer) { setOpenDialog('newCustomer') } }}
            error={data.newCustomer_err ? true : false}
            helperText={data.newCustomer_err}
            fullWidth
          />
        </Grid>
      </Grid>
      <Divider style={{ margin: '8px 0px' }} />
      <EnhancedTableToolbar
        title="editTransferServicePage.table.title"
      />
      <EnhancedTable
        headerCells={headerCells}
        rowCells={rowCells}
        tableData={data.products.map(i => formatData(i))}
      />
      <Divider style={{ margin: '8px 0px' }} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            type="text"
            label={formatMessage({ id: 'consumedService.table.detail.note' })}
            variant="outlined"
            value={data.note}
            onChange={e => updateData({ name: 'note' }, e.target.value)}
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>
      <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
        <Button variant="contained" color="primary" onClick={handleClose}>
          <FormattedMessage id="button.cancel" />
        </Button>
        {/* {readyForPurchase() && <Button variant="contained" color="primary" onClick={handleClose}>
          <FormattedMessage id="editConsumedServicePage.createSalesOrder" />
        </Button>} */}
        <LoadingButton
          color="primary"
          onClick={handleSave}
          disabled={loading || data.products.length === 0 || (!data.customer || !data.newCustomer)}
          loading={loading}
          loadingPosition="start"
          loadingIndicator={<CircularProgress size={24} />}
          startIcon={<div />}
          variant="contained"
        >
          <FormattedMessage id="button.submit" />
        </LoadingButton>
      </Stack>
    </Box>
  )

}

export default EditTransferServicePage
