import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import SelectItemDialog from './SelectItemDialog';

function SelectUserDialog({ multiSelect, headerCells, rowCells, filterItems, items, handleSave, showCategoryFilter = false, filterSetting = {}, ...props }) {
  const { formatMessage } = useIntl()

  const _headerCells = headerCells.map(c => { c.text = formatMessage({ id: `staff.table.header.${c.name}` }); return c })
  const _filterItems = filterItems.map(i => { i.text = formatMessage({ id: `staff.table.header.${i.name}` }); return i })

  const categoryFilterItems = showCategoryFilter ? [
    { name: 'status', type: 'select', default: '', size: 12, items: getMenuItem() },
  ].map(i => { i.text = formatMessage({ id: `staff.filter.${i.name}` }); return i }) : []

  categoryFilterItems.forEach(i => {
    if (filterSetting[i.name]) {
      i.default = filterSetting[i.name].default
      i.hidden = filterSetting[i.name].hidden
    }
  })

  function getMenuItem() {
    return [
      { value: '' },
      { value: 'enabled' },
      { value: 'disabled' },
    ].map(i => {
      i.label = i.value ? formatMessage({ id: `staff.status.${i.value}` }) :  formatMessage({ id: 'staff.status.all' })
      return i
    })
  }

  function applyFilter(currentFilter, categoryFilter) {
    if (!currentFilter && (!categoryFilter.status === '' || !showCategoryFilter)) {
      return items
    }
    let newItems = [...items]
    if (categoryFilter.status !== '') {
      newItems = newItems.filter(i => i.active === (categoryFilter.status === 'enabled' ? true : false))
    }
    return newItems
  }

  function onSave(selectedItems, params) {
    if (multiSelect) {
      const users = items.filter(u => selectedItems[u.id]).map(u => ({ name: u.displayName, id: u.id }))
      handleSave(users, params)
    } else {
      handleSave(selectedItems, params)
    }
  }

  return (
    <SelectItemDialog
      multiSelect={multiSelect}
      headerCells={_headerCells}
      rowCells={rowCells}
      filterItems={_filterItems}
      categoryFilterItems={categoryFilterItems}
      applyFilter={applyFilter}
      handleSave={onSave}
      {...props}
    />
  );
}

SelectUserDialog.propTypes = {
  headerCells: PropTypes.arrayOf(PropTypes.object.isRequired),
  rowCells: PropTypes.arrayOf(PropTypes.object.isRequired),
  filterItems: PropTypes.arrayOf(PropTypes.object.isRequired),
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
  handleSave: PropTypes.func.isRequired,
};

export default SelectUserDialog;
