const i18n = {
  'schedule': {
    'fieldManager' : '控場人員',
    'anchor': {
      'punchClock': '打卡記錄',
      'add_doctorShift': '新增門診',
      'add_doctorLeave': '新增休診',
      'edit_doctorShift': '編輯門診',
      'edit_doctorLeave': '編輯休診',
      'add_comment': '新增備註',
      'edit_comment': '修改備註',
      'add_weekly_leave': '新增本休',
      'edit_weekly_leave': '編輯本休',
      'add_shift': '新增排班',
      'edit_shift': '編輯排班',
      'start': '開始',
      'end': '結束',
    },
    'doctor': {
      'name': '值班醫師',
      'repeats': '醫師值班週期',
      'leaveType': '醫師休診狀態',
      'startDate': '開始日期',
      'endDate': '結束日期',
      'start': '開始',
      'end': '結束',
      'typeName': {
        'even_weeks': '雙週',
        'every_week': '每週',
        'no': '特約',
        'odd_weeks': '單週',
        'next_weeks': '隔週',
        'leaves': '休診',
        'zhongli': '中離',
      }
    },
    'export': {
      'title': '班表匯出/匯入',
      'table': {
        'date': '日期',
        'departments': '部門',
      }
    }
  },
}
export default i18n