import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

function LeaveCertificateDialog({ intl, url, handleClose }) {
  const { formatMessage } = useIntl()

  return (
    <div style={{ width: '100%', }}>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={true}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {formatMessage({ id: 'leaves.leaveForm.leavecertificate' })}
        </DialogTitle>
        <DialogContent dividers={true} >
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%'
            }}
          >
            <img alt="" src={url} width="768" height="1024" />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary">
            <FormattedMessage id="button.ok" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

LeaveCertificateDialog.propTypes = {
  url: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default LeaveCertificateDialog;
