import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import Typography from '@mui/material/Typography';

import EnhancedTableEx from 'components/EnhancedTableEx';

function QueryPPListTable({ showSalesOrder, customerMapping, data }) {
  const { formatMessage } = useIntl()
  const tableRef = useRef()

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'date' },
    { text: 'customer' },
    { text: 'status' },
  ].map(c => { c.text = formatMessage({ id: `salesOrder.table.header.${c.text}` }); return c })

  const rowCells = [
    { field: 'salesOrderSn', type: 'info', onButtonClick: showSalesOrder },
    { field: 'date' },
    { field: 'customerName' },
    { field: 'statusText' },
  ]

  function formatData(pp) {
    const newData = { ...pp }
    const m = /SO(\d{4})(\d{2})(\d{2})(?:\d{4})/.exec(pp.salesOrderSn)
    newData.statusText = !newData.available ? '已消耗完' : `剩餘數量(${Number(newData.quantity) - Number(newData.taken)})`
    newData.date = `${m[1]}-${m[2]}-${m[3]}`
    newData.customerName = customerMapping[newData.customer]?.name || ''
    return newData
  }

  function exportTable() {
    tableRef.current.saveToFile(`SalesQuery_${dayjs().format('YYYY-MM-DD-HHmmss')}.csv`)

  }

  return (data.length ?
    <EnhancedTableEx
      ref={tableRef}
      defaultOrder="asc"
      defaultOrderField="sn"
      headerCells={headerCells}
      rowCells={rowCells}
      getHeaderActionIcons={() =>
        <IconButton
          onClick={() => exportTable()}
          size="large">
          <GetAppIcon></GetAppIcon>
          <Typography noWrap variant="button">{formatMessage({ id: 'button.export' })}</Typography>
        </IconButton>
      }
      tableData={data.map(i => formatData(i))}
    />
    : <Typography sx={{ flex: '1 1 20%' }} variant="h6" id="tableTitle" component="div">
          查無資料
    </Typography>);
}

export default QueryPPListTable;
