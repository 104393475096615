const i18n = {
  'overtimes': {
    'applyForOvertime': '申請加班',
    'editOvertime': '編輯申請',
    'table': {
      'type': '補償',
      'start': '開始時間',
      'end': '結束時間',
      'hours': '節數',
      'reason': '原因',
      'state': '狀態',
      'pending': '等候中',
      'history': '歷史紀錄',
      'createdByName': '申請人'
    },
    'list': {
      'step': '步驟',
      'balance': '目前額度',
    },
    'overtimeForm': {
      'timeOffType': '假別*',
      'startDateTime': '開始日期',
      'endDateTime': '結束日期',
      'periods': '節數',
      'hours': '小時',
      'reason': '原因',
      'currentBalances': '目前額度',
      'overtimeHour': '時',
      'overtimeMinute': '分',
      'convert': '補償',
      'pay': '轉薪',
      'signPay': '簽核中轉薪',
      'signLeave': '簽核中轉補休',
      'totalOvertime': '總加班節數',
      'allowance': '津貼',
      'leave': '轉補休'
    },
    'overtimeAlert': {
      'title': '缺少欄位',
      'content': '必須填寫原因欄位',
      'timeTitle': '時間錯誤',
      'timeContent': '不得超出實際打卡時間',
      'punchTitle': '缺少打卡時間',
      'punchContent': '未取得選擇日期打卡時間'
    },
    'detail': {
      'note': '備註',
      'rejectReason': '否決原因',
      'editReason': '修改原因',
      'voidReason': '作廢原因'
    }
  },
  'overtimeSent': {
    'overtimeApplicationSent': '加班申請送出',
    'sentMessage': '現正等待審核中，審核完畢後將會通知',
    'newBack': '回到「我的加班」',
  },
  'overtimeType': {
    'overtime_leave': '轉補休',
    'overtime_pay': '轉補薪',
    'overtime_shift': '值夜班'
  },
  'overtimeStatus': {
    'pending': '等候中',
    'done': '同意',
    'reject': '否決',
    'void': '作廢'
  }
}

export default i18n