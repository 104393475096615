import React, { useState, useEffect, useContext, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import SelectCustomerDialog from 'components/SelectCustomerDialog';
import DelectIconButton from 'components/DelectIconButton';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import EnhancedTable from 'components/EnhancedTable';
import ContextStore from 'modules/context';
import { getRandomHash, unique } from 'modules/uitls';
import { addError, removeError } from 'modules/editor';
import { firestoreListener, callFunction } from 'modules/firebase';
import SelectSalesOrderDialog from './SelectSalesOrderDialog';

function EditReferrerPage({ salesOrder, defaultReferrerId, onClose }) {
  const { formatMessage } = useIntl()
  const recordOrder = useRef(0)
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const navigate = useNavigate()
  const { referrerId: _referrerId } = useParams()
  const referrerId = defaultReferrerId ?? _referrerId
  const location = useLocation()
  const [availableStoredValue, setAvailableStoredValue] = useState({});
  const [loading, setLoading] = useState(false)
  const productMapping = useSelector(state => state.products.data)
  const products = useSelector(state => state.products.ordered)
  const vendorMapping = useSelector(state => state.vendors.data)

  const [customers, setCustomers] = useState([]);
  const [customerMapping, setCustomerMapping] = useState({});
  const [openDialog, setOpenDialog] = useState('')
  const companyData = currentUser.company?.reduce((acc, cur) => { if (userRights['referrer-create'].includes(cur)) { acc = true } return acc }, false) ?
    unique(currentUser.company?.concat(userRights['referrer-create'])) : userRights['referrer-create']
  const [data, setData] = useState({
    id: referrerId,
    customer: salesOrder?.customer ?? '',
    salesOrder: salesOrder?.id ?? '',
    salesOrderSn: salesOrder?.sn ?? '',
    subtotal: Object.keys(salesOrder?.products ?? {}).map(k => salesOrder.products[k]).reduce((acc, cur) => acc + (cur.unitPrice * cur.amount), 0),
    note: '',
    records: [],
    source: companyData[0]
  });
  const totalReferralFee = data.records.reduce((acc, cur) => acc + cur.fee, 0)

  useEffect(() => {
    const breadcrumbs = [{
      link: '/sales/referrer',
      text: formatMessage({ id: 'sideMenu.sales.referrer' })
    }]
    if (referrerId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'referrer.add' }) })
    }

    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const unsubscribe = (referrerId !== 'new' && userRights.hasUserRight('referrer-edit')) ? firestoreListener({
      collection: 'referrers',
      doc: referrerId,
      onData: (data) => {
        // 修正一些欄位
        const reData = {
          ...data,
          records: Object.keys(data.records).map(i => ({
            ...data.records[i],
            feeType: data.records[i].feeType === 'storedValue' ? `storedValue*${data.records[i].storedValueCard}` : 'cash',
            lock: true,
            id: i
          })).sort((a, b) => a.order - b.order),
        }
        if (reData.records?.length) {
          recordOrder.current = reData.records[reData.records.length - 1].order + 1
        }
        setData(reData)
      }
    }) : null

    if ((referrerId !== 'new' && !userRights.hasUserRight('referrer-edit')) ||
      !userRights.hasUserRight('referrer-create')) {
      navigate('/');
    }
    return () => unsubscribe?.()
  }, [referrerId]);

  useEffect(() => {
    const unsubscribe = data.customer ? firestoreListener({
      collection: 'storedValueCard',
      doc: data.customer,
      unwrap: true,
      addDocId: false,
      onData: (data) => {
        setAvailableStoredValue(data)
      }
    }) : null
    return () => unsubscribe?.()
  }, [data.customer]);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'customers',
      mapping: true,
      array: true,
      onData: ({ mapping, data }) => {
        setCustomers(data)
        setCustomerMapping(mapping)
      }
    })
    return () => unsubscribe()
  }, []);

  function updateData(field, value) {
    let newValue = value
    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }
    let err = validateField(field, value)
    let newData = { ...data, [field.name]: newValue, [`${field.name}_err`]: err }

    setData(newData)
  }

  function updateDataFields(fields, values) {
    let newData = { ...data }

    for (const field of fields) {
      let newValue = values[field.name]
      if (field.allowCharacter) {
        newValue = newValue.replace(field.allowCharacter, '')
      }
      let err = validateField(field, values[field.name])
      newData[field.name] = newValue
      newData[`${field.name}_err`] = err

      setData(newData)
    }
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    return ''
  }

  function handleClose() {
    if (onClose) {
      onClose()
    } else {
      navigate('/sales/referrer');
    }
  }

  async function handleSave() {
    setLoading(true)
    let err = false;

    const fields = [
      { name: 'source' },
      { name: 'salesOrderSn' },
    ]

    for (const field of fields) {
      if (data[field.name] === '') {
        data[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
        err = true
      }
    }

    if (err) {
      setData({ ...data })
      setLoading(false);
      return
    }

    const updateData = {
      source: data.source,
      salesOrder: data.salesOrder,
      salesOrderSn: data.salesOrderSn,
      note: data.note,
    }

    updateData.records = data.records.reduce((acc, cur) => {
      let feeType = cur.feeType
      let storedValueCard = ''
      if (feeType.startsWith('storedValue*')) {
        const s = feeType.split('*')
        feeType = s[0]
        storedValueCard = s[1]
      }
      const record = {
        customer: cur.customer,
        feeType,
        feeRule: cur.feeRule,
        fee: parseInt(cur.fee),
        note: cur.note,
        order: cur.order,
      }
      if (cur.void) {
        record.void = true
      }
      if (storedValueCard) {
        record.storedValueCard = storedValueCard
      }
      acc[cur.id] = record
      return acc
    }, {})

    updateData.date = dayjs().format('YYYY-MM-DD')

    try {
      // console.log(JSON.stringify({ id: referrerId, ...updateData }, null, 4))
      await callFunction('saveReferrer', { id: referrerId, ...updateData })
    } catch (ex) {
      console.log(ex)
    }
    handleClose()
  }

  function onDeleteItem(item) {
    const index = data.records.findIndex(i => i.id === item.id)
    data.records.splice(index, 1)
    updateData({ name: 'records' }, data.records);
  }

  function onCellValueChanged(cellData, field, value) {
    const m = data.records.find(i => i.id === cellData.id)
    if (!m) {
      return
    }

    if (field === 'feeRule') {
      m.feeRule = value
      const errorMessage = formatMessage({ id: 'form.field.formatError' })
      let err = false;
      if (!(/^[0-9.]+%{0,1}$/).test(value) || value.split('.').length > 2 || value.startsWith('.')) {
        addError(m, field, errorMessage);
        err = true;
      } else {
        if (value.includes('.')) {
          const v2 = value.replaceAll('%', '');
          if (v2.endsWith('0') || v2.endsWith('.') || !(value.startsWith('0.') || !value.startsWith('0')) ) {
            addError(m, field, errorMessage);
            err = true;
          }
        } else {
          if (value.startsWith('0')) {
            addError(m, field, errorMessage);
            err = true;
          }
        }
        if (!err) {
          const poPrice = data.subtotal ?? data.salesOrderPrice ?? 0
          m.fee = 0
          if (m.feeRule.endsWith('%')) {
            m.fee = Math.round((poPrice * parseFloat(m.feeRule.replaceAll('%', ''))) / 100)
          } else if (m.feeRule !== '' && !m.errors?.feeRule) {
            m.fee = Math.round(m.feeRule)
          }
          removeError(m, field)
        } else {
          m.fee = 0
        }
      }
    } else {
      removeError(m, field)
      m[field] = value
    }
    updateDataFields([{ name: 'records' }], { records: data.records });
  }

  const headerCells = [
    { text: 'customer' },
    { text: 'feeType' },
    { text: 'feeRule' },
    { text: 'fee' },
    { text: 'note' },
  ].map(c => { c.text = formatMessage({ id: `referrer.records.${c.text}` }); return c })

  const rowCells = [
    {
      field: 'customer',
      fullWidth: true,
      type: 'popup',
      required: true,
      label: formatMessage({ id: 'referrer.records.customer' }),
      getEnableStatus: getEnableStatus,
      onClick: (referrer) => setOpenDialog(`referrer*${referrer.id}`),
    },
    {
      field: 'feeType',
      fullWidth: true,
      type: 'input-menu',
      required: true,
      label: formatMessage({ id: 'referrer.records.feeType' }),
      getEnableStatus: getEnableStatus,
      getMenuItems: getReferralFeeType,
      onValueChanged: onCellValueChanged
    },
    {
      field: 'feeRule',
      type: 'input-number',
      inputRule: /[^0-9%.]/g,
      required: true,
      label: '金額',
      getEnableStatus: getEnableStatus,
      onValueChanged: onCellValueChanged,
    },
    { field: 'fee' },
    { field: 'note', type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged },
  ]

  const storedValueCards = []
  for (const k of Object.keys(availableStoredValue || {})) {
    if (productMapping[k]) {
      if ((productMapping[k].seller || []).includes(data.source)) {
        storedValueCards.push({
          active: true,
          id: `storedValue*${k}`,
          name: `${productMapping[k].name}`
        })
      }
    }
  }

  function getEnableStatus(data, field) {
    return !data.lock
  }

  function getReferralFeeType() {
    const referralFeeTypes =[{
      active: true,
      label: formatMessage({ id: 'referrer.records.cash' }),
      value: 'cash'
    }];

    const storedValueCards = products.filter(i =>
      i.type === 'storedValue' && (i.seller || []).includes(data.source) // 檢查一下該儲值點數是否可以用在該公司
    ).map(i => ({
      active: true,
      id: `storedValue*${i.id}`,
      name: i.name
    }))

    return referralFeeTypes.concat(storedValueCards.map(i => ({ label: `轉儲值(${i.name})`, value: i.id })))
  }

  function onSelectSource(e) {
    const source = e.target.value
    const fields = [{ name: 'source' }]
    const values = { source: e.target.value }
    if (data.paymentType && data.paymentType.includes('*')) {
      const s = data.paymentType.split('*')
      if (!(productMapping[s[1]].seller || []).includes(source)) {
        fields.push({ name: 'paymentType' })
        values.paymentType = ''
      }
    }
    updateDataFields(fields, values)
  }

  const handleDialogSave = (data) => {
    updateDataFields([
      { name: 'salesOrder' },
      { name: 'salesOrderSn' },
      { name: 'subtotal' },
      { name: 'customer' }
    ], {
      customer: data.customer,
      salesOrder: data.id,
      salesOrderSn: data.sn,
      subtotal: Object.keys(data.products).map(k => data.products[k]).reduce((acc, cur) => acc + (cur.unitPrice * cur.amount), 0)
    })
  }

  function onAddReferrer() {
    const pData = {
      id: getRandomHash(),
      customer: '',
      feeType: '',
      feeRule: '',
      fee: 0,
      note: '',
      order: recordOrder.current,
    }
    recordOrder.current = recordOrder.current + 1
    data.records.push(pData)
    updateDataFields([{ name: 'records' }], { records: [...data.records] })
  }

  function onSelectCustomer(customer, params) {
    const s = params.split('*')
    const p = data.records.find(i => i.id === s[1])
    if (p) {
      removeError(p, 'customer')
      p.customer = customer.id
      updateDataFields([{ name: 'records' }], { records: data.records });
    }
  }

  function formatData(data) {
    const newData = { ...data }
    newData.customer = customerMapping[newData.customer]?.name ?? ''

    if (newData.feeType.startsWith('storedValue*')) {
      const s = newData.feeType.split('*')
      newData.feeType = `轉儲值(${productMapping[s[1]].name})`
    } else if (newData.feeType === 'cash') {
      newData.feeType = formatMessage({ id: 'referrer.records.cash' })
    } else {
      newData.feeType = ''
    }
    return newData
  }

  return (
    <Box p={2} sx={{ minHeight: salesOrder ? 'unset' : 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
      {openDialog === 'salesOrder' && <SelectSalesOrderDialog
        headerCells={[
          { field: 'sn', sort: 'sn' },
          { field: 'date' },
          { field: 'customer' },
          { field: 'agent' },
          { field: 'price' },
          { field: 'status' },
        ]}
        rowCells={[
          { field: 'sn' },
          { field: 'date' },
          { field: 'customerName' },
          { field: 'agentName' },
          { field: 'price' },
          { field: 'statusText' },
        ]}
        filterItems={[{ name: 'sn' }, { name: 'customer' }]}
        source={data.source}
        dialogTitle={formatMessage({ id: 'selectSalesOrderDialog.title' })}
        handleClose={() => setOpenDialog('')}
        handleSave={handleDialogSave}
        ignoreVoid
      />}
      {(openDialog.startsWith('referrer')) && <SelectCustomerDialog
        handleClose={() => setOpenDialog('')}
        handleSave={onSelectCustomer}
        customers={(customers || []).filter(i => i.id !== data.customer)}
        dialogTitle={formatMessage({ id: 'selectCustomerDialog.title' })}
        params={openDialog}
      />}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            type="text"
            size="small"
            select={userRights['referrer-create']?.length > 1}
            disabled={referrerId !== 'new' || userRights['referrer-create']?.length <= 1}
            label={formatMessage({ id: 'referrer.table.detail.source' })}
            variant="outlined"
            value={userRights['referrer-create']?.length > 1 ? data.source : vendorMapping[data.source]?.name}
            onChange={onSelectSource}
            fullWidth
          >
            {
              companyData.map(c => {
                return <MenuItem key={c} value={c}>{vendorMapping[c].name}</MenuItem>
              })
            }
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            type="text"
            size="small"
            disabled={!!salesOrder || referrerId !== 'new' || !!data.records.length}
            label={formatMessage({ id: 'referrer.table.detail.salesOrder' })}
            variant="outlined"
            value={data.salesOrderSn}
            onClick={() => setOpenDialog('salesOrder')}
            error={data.customer_err ? true : false}
            helperText={data.customer_err}
            fullWidth
          />
        </Grid>
      </Grid>
      <Divider style={{ margin: '8px 0px' }} />
      <EnhancedTableToolbar
        title={'editReferrer.title'}
        toolbox={
          <Button
            disabled={!data.salesOrderSn}
            sx={{ m: 1, whiteSpace: 'nowrap' }}
            variant="contained"
            color="primary"
            onClick={onAddReferrer}
          >
            <FormattedMessage id={'editReferrer.addRecord'} />
          </Button>
        }
      />
      <EnhancedTable
        headerCells={headerCells}
        rowCells={rowCells}
        tableData={data.records.map(i => formatData(i))}
        getActionIcons={referrer =>
          referrer.void ? <div style={{ color: 'red' }}>作廢</div> : <DelectIconButton
            text={formatMessage({ id: 'editReferrer.removeRecord' })}
            onClick={() => onDeleteItem('referrers', referrer)}
          />
        }
        extRows={
          <>
            <TableRow>
              <TableCell colSpan={3} />
              <TableCell align="right">
                <Typography variant="subtitle2">
                  <FormattedMessage id="editReferrer.totalReferralFee" />
                </Typography>
              </TableCell>
              <TableCell align="right">{totalReferralFee}</TableCell>
              <TableCell />
            </TableRow>
          </>
        }
      />
      <Divider style={{ margin: '8px 0px' }} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            type="text"
            label={formatMessage({ id: 'referrer.table.detail.note' })}
            variant="outlined"
            value={data.note}
            onChange={e => updateData({ name: 'note' }, e.target.value)}
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>
      <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
        <Button variant="contained" color='inherit' onClick={handleClose}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          disabled={loading || data.records.length === 0}
          onClick={handleSave}
          loading={loading}
          loadingPosition="start"
          loadingIndicator={<CircularProgress size={24} />}
          startIcon={<div />}
          variant="contained"
        >
          <FormattedMessage id="button.submit" />
        </LoadingButton>
      </Stack>
    </Box>
  )
}

export default EditReferrerPage
