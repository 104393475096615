import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

import { callFunction } from 'modules/firebase';
import EditReviewPolicyView from './EditReviewPolicyView';
import EditWorktimePolicyView from './EditWorktimePolicyView';
import EditWeeklyPolicyView from './EditWeeklyPolicyView';

function EditPolicyDialog({ policy, type, handleClose }) {
  const childRef = useRef()
  const [loading, setLoading] = useState(false)

  const handleSave = async () => {
    setLoading(true);
    const data = childRef.current.getContent()
    if (data) {
      try {
        if (type === 'review') {
          await callFunction('saveReviewPolicy', { id: policy.id, ...data })
        } else if (type === 'worktime') {
          await callFunction('saveWorktimePolicy', { id: policy.id, ...data })
        } else {
          await callFunction('saveWeeklyPolicy', { id: policy.id, ...data })
        }
      } catch (ex) {
        console.log(ex)
      }
    } else {
      setLoading(false);
      return
    }
    // setLoading(false);
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={type === 'review' ? 'lg' : 'md'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id={`setAttendance.${type}Policy.dialog.title.${policy.id === 'new' ? 'add' : 'edit'}`} />
      </DialogTitle>
      <DialogContent dividers={true}>
        {type === 'review' ?
          <EditReviewPolicyView ref={childRef} reviewPolicy={policy} /> :
          type === 'worktime' ? <EditWorktimePolicyView ref={childRef} worktimePolicy={policy} /> :
            <EditWeeklyPolicyView ref={childRef} weeklyPolicy={policy} />
        }
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          onClick={handleSave}
          loading={loading}
          loadingPosition="start"
          loadingIndicator={<CircularProgress size={24} />}
          startIcon={<div />}
          variant="contained"
        >
          <FormattedMessage id="button.save" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

EditPolicyDialog.propTypes = {
  policy: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default EditPolicyDialog;
