import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { createUseStyles } from 'react-jss';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AddIcon from '@mui/icons-material/Add';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import EditIcon from '@mui/icons-material/Edit';

import AppointmentStatusDialog from 'components/AppointmentStatusDialog';
import { objectToArray, groupArray } from 'modules/data';
import { minutesToLeft } from 'modules/time';
import { calculateRowHight } from 'modules/appointment';
import ContextStore from 'modules/context';
import { updateFirestoreDoc } from 'modules/firebase';
import { moibleMedia } from 'constants/index';
import TimeBar from './TimeBar';
import DoctorList from './DoctorList';
import SalesRepList from './SalesRepList';
import ScheduleRow from './ScheduleRow';
import AppointmentPopover from './AppointmentPopover';

dayjs.extend(utc)
dayjs.extend(timezone)

const commentStyles = {
  container: {
    // position: 'absolute',
    width: '215px',
    height: 'auto',
    borderRadius: '4px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #ffc107',
    zIndex: 1,
    boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)',
  },
  header: {
    height: '24px',
    lineHeight: '24px',
    backgroundColor: '#ffc107',
    padding: '0 8px',
    fontSize: '15px',
    fontWeight: '500',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    width: '16px',
    height: '16px',
    cursor: 'pointer',
  },
  body: {
    'minHeight': '24px',
    'fontSize': '15px',
    'fontWeight': '500',
    'textAlign': 'left',
    'color': 'rgba(0, 0, 0, 0.54)',
    'padding': '8px',
  },
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '.9px',
    paddingTop: '32px',
    zIndex: 2,
    [moibleMedia]: {
      paddingTop: '0px',
    }
  },
  rowBody: {
    overflow: 'scroll',
    position: 'absolute',
    top: '32px',
    left: '120px',
    width: 'calc(100% - 120px)',
    height: 'calc(100% - 32px)',
    zIndex: 1,
    [moibleMedia]: {
      height: '100%',
      width: 'calc(100% - 50px)',
      top: 0,
      left: '50px',
    }
  },
  timebarContainer: {
    position: 'absolute',
    left: '120px',
    top: 0,
    width: 'calc(100% - 120px)',
    minWidth: 'calc(100% - 120px)',
    // height: '100%',
    zIndex: 1,
    fontSize: '15px',
    textAlign: 'left',
    color: '#6e747d',
    display: 'flex',
    alignItems: 'top',
    pointerEvents: 'none',
    flexShrink: 0,
    overflow: 'hidden',
    [moibleMedia]: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '50px',
      minWidth: '50px',
      // zIndex: 2,
      height: 'calc(100vh - 150px)',
      backgroundColor: '#fff',
      boxShadow: 'none',
      fontSize: '12px',
      fontWeight: 500,
      letterSpacing: '0.8px',
      textAlign: 'center',
      alignContent: 'center',
      color: '#939393',
      display: 'flex',
      flexDirection: 'column',
      padding: '20px 0 0 0',
    }
  },
  wrapper: {
    position: 'absolute',
    overflow: 'hidden',
    top: '32px',
    height: 'calc(100% - 32px)',
    flexShrink: 0,
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.5), inset 0 1px 0 0 rgba(0, 0, 0, 0.1)',
    zIndex: 2,
    backgroundColor: '#efeff4',
    [moibleMedia]: {
      top: 0
    }
  },
  header: {
    position: 'absolute',
    height: '32px',
    width: '120px',
    padding: '8px 0',
    top: 0,
    left: 0,
    borderBottom: '1px solid #dddde7',
    color: '#8F8F91',
    background: '#efeff4',
    zIndex: 3,
    textAlign: 'center',
    [moibleMedia]: {
      display: 'none',
    }
  },
  commentMenu: {
    padding: 0
  }
});

function ScheduleDay({ scheduleMode, salesReps, doctors, doctorShifts, doctorLeaves, appointmentTypes, appointmentData, commentData, customerMapping }) {
  const { formatMessage } = useIntl()
  const classes = useStyles();
  const childRef = useRef()
  const doclist = useRef()
  const timebar = useRef()
  const userRights = useSelector(state => state.userRights)
  const [menuInfo, setMenuInfo] = useState(null)
  const menuOpen = Boolean(menuInfo);
  const [commentMenuInfo, setCommentMenuInfo] = useState(null)
  const commentMenuOpen = Boolean(commentMenuInfo);
  const [dialogData, setDialogData] = useState(null)
  const [currentTimePosition, setCurrentTimePosition] = useState(0)
  const [currentTimeDisplay, setCurrentTimeDisplay] = useState('')
  const { uiState, setUiState } = useContext(ContextStore)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const doctorGroup = groupArray(doctors, 'appointmentType');

  useEffect(() => {
    const timer = dayjs(uiState.date).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? setInterval(updateCurrentTime, 5000) : null
    updateCurrentTime()
    return () => {
      if (timer) { clearInterval(timer) }
    };
  }, [uiState.date]);

  const handleScroll = (scrollTop, scrollLeft) => {
    if (isMobile) {
      timebar.current.scrollTop = scrollTop
    } else {
      if (doclist && doclist.current) {
        doclist.current.scrollTop = scrollTop
      }
      if (timebar && timebar.current) {
        timebar.current.scrollLeft = scrollLeft
      }
    }
  }

  const updateCurrentTime = () => {
    if (dayjs(uiState.date).format('YYYY-MM-DD') !== dayjs().format('YYYY-MM-DD')) {
      setCurrentTimePosition(0)
      setCurrentTimeDisplay('')
    }

    let position
    let currentTWTime = dayjs().tz('Asia/Taipei')

    if (currentTWTime.format('YYYY-MM-DD') === dayjs(uiState.date).format('YYYY-MM-DD')) {
      const minutesSinceStart = currentTWTime.diff(currentTWTime.set('hours', firstHour || 10).set('minutes', 0), 'minutes')
      if (minutesSinceStart < lastHour * 60) {
        position = minutesToLeft(minutesSinceStart)
      }
    }

    if (currentTimePosition !== `${position}px`) {
      setCurrentTimePosition(position ? `${position}px` : false)
      setCurrentTimeDisplay(currentTWTime.format('HH:mm'))
    }
  };

  function selectComment(event, comment) {
    const containerPosition = childRef.current.getBoundingClientRect()
    setUiState({
      ...uiState,
      showClickMenu: true,
      clickMenuPosition: {
        left: `${event.pageX - containerPosition.left + childRef.current.scrollLeft}px`,
        top: `${event.pageY - containerPosition.top + childRef.current.scrollTop}px`,
      },
      clickMenuType: 'comment',
      clickMenuData: comment,
    })

    setCommentMenuInfo({ anchor: event.currentTarget })
  }

  function editComment(comment) {
    setUiState({
      ...uiState,
      showCommentSidebar: true,
      editComment: comment,
      showClickMenu: false,
      clickMenuType: ''
    })

    setCommentMenuInfo(null)
  }

  function selectAppointment(event, appointment) {
    if (isMobile) {
      setUiState({
        ...uiState,
        editAppointment: appointment,
        showAppointmentSidebar: true,
      })
    } else {
      setUiState({
        ...uiState,
        selectedAppointment: appointment,
        selectedAppointmentEl: event.currentTarget
      })
    }
  }

  function selectHour({ event, hour, appointmentType, doctor, salesRep }) {
    const containerPosition = childRef.current.getBoundingClientRect()
    setUiState({
      ...uiState,
      showClickMenu: true,
      clickMenuPosition: {
        left: `${event.pageX - containerPosition.left + childRef.current.scrollLeft}px`,
        top: `${event.pageY - containerPosition.top + childRef.current.scrollTop}px`,
      },
      clickMenuType: 'hour',
      clickMenuData: {
        hour,
        appointmentType,
        doctor: doctor,
        salesRep: salesRep,
      }
    })
    if (userRights.hasUserRight('appointment-edit')) {
      setMenuInfo({ anchor: event.currentTarget })
    }
  }

  function sortByTime(a, b) {
    return (a.hour * 100 + a.minute) -
      (b.hour * 100 + b.minute)
  }

  const currentDateString = dayjs(uiState.date).format('YYYY-MM-DD')
  const appointments = objectToArray(appointmentData)

  const { row: rowAppointments, firstHour, lastHour } = appointments.filter(i => currentDateString === i.date).reduce((acc, appointment) => {
    const { doctor, salesRep, appointmentType: type } = appointment
    const totalDuration = Math.min(objectToArray(appointment.treatments).reduce((acc, treatment) => (
      acc + parseInt(treatment.duration || 0)
    ), 0), 720) // max duration of 12 hours
    acc.firstHour = Math.min(acc.firstHour, appointment.hour)
    acc.lastHour = Math.max(acc.lastHour, Math.ceil(appointment.hour + totalDuration / 60))
    if (['cancelled', 'cancelanotherappointment'].includes(appointment.status)) {
      acc.row.cancelled = acc.row.cancelled || []
      acc.row.cancelled.push(appointment)
    } else {
      if (scheduleMode === 'doctor') {
        acc.row[type] = acc.row[type] || {}
        acc.row[type][doctor] = acc.row[type][doctor] || []
        acc.row[type][doctor].push(appointment)
      } else {
        const sr = salesRep || 'noSalesRep'
        acc.row[sr] = acc.row[sr] || []
        acc.row[sr].push(appointment)
      }
    }
    return acc
  }, { row: {}, firstHour: 9, lastHour: 21 })

  for (let t in rowAppointments) {
    if (Array.isArray(rowAppointments[t])) { // 取消預約
      rowAppointments[t].sort(sortByTime)
    } else {
      for (let d in rowAppointments[t]) { // 一般有醫生的預約
        rowAppointments[t][d].sort(sortByTime)
      }
    }
  }

  const comments = objectToArray(commentData)
  const rowComments = scheduleMode === 'doctor' ? comments.reduce((acc, cur) => {
    for (let key in cur.rows) {
      if (cur.rows[key]) {
        const atDoctors = doctors.filter(d => d.appointmentType === key)
        for (const doctor of atDoctors) {
          acc[doctor.id] = acc[key] || []
          acc[doctor.id].push(cur)
        }
        acc[key] = acc[key] || []
        acc[key].push(cur)
      }
    }
    return acc
  }, {}) : {}

  function positionAppointments() {
    // 計算 每列的高度 和 預約表 item 的位置
    const appointmentPosition = {};
    const rowHeights = {};

    // 計算 無醫生預約 + 各個科別 的部份
    if (scheduleMode === 'doctor') {
      for (const type of appointmentTypes) {
        if (type === 'normalAppointment') {
          rowHeights.normalAppointment = calculateRowHight(rowAppointments[type] ? rowAppointments[type][undefined] || [] : [], appointmentPosition, firstHour, isMobile)
        } else {
          const doctorList = doctors.filter((doctor) => doctor.appointmentType === type)
          for (const doctor of doctorList) {
            rowHeights[doctor.id] = calculateRowHight(rowAppointments[type] ? rowAppointments[type][doctor && doctor.id] || [] : [], appointmentPosition, firstHour, isMobile)
          }
        }
      }
    } else {
      for (const salesRep of salesReps) {
        rowHeights[salesRep.id] = calculateRowHight(rowAppointments[salesRep.id] || [], appointmentPosition, firstHour, isMobile)
      }
    }

    // 計算 已取消的預約 的部份
    if (rowAppointments.cancelled) {
      rowHeights.cancelled = calculateRowHight(rowAppointments.cancelled || [], appointmentPosition, firstHour, isMobile)
    }

    return {
      appointmentPosition,
      rowHeights
    }
  } // positionAppointments

  const handlePopoverClose = () => {
    setUiState({
      ...uiState,
      selectedAppointment: false,
      selectedAppointmentEl: {}
    });
  };

  async function handleAppointmentChange(appointment, field, value, dialog) {
    let promises = []

    handlePopoverClose()

    if (value === 'arrived' || value === 'late') {
      let arrivetime = dayjs().format('DD:HH:mm')
      promises.push(updateFirestoreDoc({ collection: 'appointments', doc: appointment.id, data: { [field]: value, arrivetime } }))
    } else if (value === 'complete') {
      let completetime = dayjs().format('DD:HH:mm')

      let d = '' // stay time(d)
      let h = '' // stay time(h)
      let m = '' // stay time(m)
      let ad, ah, am, bd, bh, bm

      ad = parseInt(completetime.split(':')[0]) // complete time(day)
      ah = parseInt(completetime.split(':')[1]) // complete time(h)
      am = parseInt(completetime.split(':')[2]) // complete time(m)

      if (appointment.arrivetime) {
        bd = parseInt(appointment.arrivetime.split(':')[0]) // arrive time(day)
        bh = parseInt(appointment.arrivetime.split(':')[1]) // arrive time(h)
        bm = parseInt(appointment.arrivetime.split(':')[2]) // arrive time(m)
      }

      if ((ad - bd) < 1) {
        d = 0
      } else if ((ad - bd) > 0) {
        d = ad - bd
      }

      if ((ah - bh) >= 0) {
        h = ah - bh
      } else if ((ah - bh) < 0 && (bd - ad) > 0) {
        h = (ah - bh) + 24
        d = d - 1
      }

      if ((am - bm) < 0) {
        m = (am - bm) + 60
        h = h - 1
      } else if ((am - bm) >= 0) {
        m = am - bm
      }

      let staytime = String(d + '天' + h + '時' + m + '分')
      promises.push(updateFirestoreDoc({ collection: 'appointments', doc: appointment.id, data: { [field]: value, staytime, completetime } }))
    } else if (['cancelanotherappointment', 'cancelled'].includes(value) || dialog) {
      handleDialogOpen(appointment, field, value, 'appointments')
    } else {
      promises.push(updateFirestoreDoc({ collection: 'appointments', doc: appointment.id, data: { [field]: value } }))
    }

    try {
      await Promise.all(promises)
    } catch (ex) {
      console.log(ex)
    }
  }

  function handleDialogOpen(appointment, field, value, type) {
    setDialogData({ appointment, field, value, type })
  }

  const handleClose = () => {
    setCommentMenuInfo(null)
    setMenuInfo(null);
    setDialogData(null)
    if (uiState.clickMenuType === 'hour') {
      setUiState({
        ...uiState,
        showClickMenu: false,
        clickMenuType: '',
      })
    }
  };

  const onEditAppointment = (data, type) => {
    if (type === 'new') {
      if (uiState.clickMenuType === 'hour') {
        setUiState({
          ...uiState,
          showClickMenu: false,
          clickMenuType: '',
          showAppointmentSidebar: true,
          showCommentSidebar: false,
        })
      } else {
        setUiState({
          ...uiState,
          showAppointmentSidebar: true,
          showCommentSidebar: false,
        })
      }
    } else {
      setUiState({
        ...uiState,
        editAppointment: data,
        showAppointmentSidebar: true,
        selectedAppointment: false,
        selectedAppointmentEl: {}
      })
    }

    setMenuInfo(null);
  }

  const onEditComment = (data, type) => {
    if (type === 'new') {
      if (uiState.clickMenuType === 'hour') {
        setUiState({
          ...uiState,
          showClickMenu: false,
          clickMenuType: '',
          showCommentSidebar: true,
          showAppointmentSidebar: false,
          showDoctorSchedule: false
        })
      } else {
        setUiState({
          ...uiState,
          showCommentSidebar: true,
          showAppointmentSidebar: false,
          showDoctorSchedule: false
        })
      }
    } else {
      setUiState({
        ...uiState,
        editComment: data,
        showCommentSidebar: true
      })
    }

    setCommentMenuInfo(null)
    setMenuInfo(null);
  }

  const timeBarState = {
    currentTimePosition: currentTimePosition,
    currentTimeDisplay: currentTimeDisplay
  }

  const scheduleRowProps = {
    firstHour: firstHour,
    lastHour: lastHour,
    customerMapping,
    isMobile,
    selectHour,
    selectAppointment: selectAppointment,
    selectComment: selectComment
  }

  const viewingDoctor = uiState.viewingDoctor || (doctors[0] && doctors[0].id) // 在 mobile 模式下, 切到特定醫生的 tab
  const viewingSalesRep = uiState.viewingSalesRep || (salesReps[0] && salesReps[0].id)

  const { rowHeights, appointmentPosition } = positionAppointments()
  return (
    <div
      style={{
        minWidth: isMobile ? 'calc(100% - 50px)' : 'calc(100% - 120px)'
      }}
      className={classes.container}
    >
      {dialogData && <AppointmentStatusDialog
        data={dialogData}
        dialogTital={formatMessage({ id: `cancelled.${dialogData.value}` })}
        handleClose={handleClose}
        setUiState={setUiState}
        uiState={uiState}
      />}
      {menuInfo && <Menu
        id="menu-appbar"
        anchorEl={menuInfo.anchor}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        <span>
          <MenuItem onClick={() => onEditAppointment({}, 'new')}>
            <AddIcon style={{ marginRight: '10px' }} />
            {formatMessage({ id: 'appointment.anchor.newReservation' })}
          </MenuItem>
          {scheduleMode === 'doctor' && <MenuItem onClick={() => onEditComment({}, 'new')}>
            <InsertCommentIcon style={{ marginRight: '10px' }} />
            {formatMessage({ id: 'appointment.anchor.newComment' })}
          </MenuItem>}
        </span>
      </Menu>}
      {commentMenuOpen && <Menu
        id="menu-appbar"
        anchorEl={commentMenuInfo.anchor}
        classes={{ list: classes.commentMenu }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        open={commentMenuOpen}
        onClose={handleClose}
      >
        {<span>
          <div style={{ ...commentStyles.container, ...uiState.clickMenuPosition }}>
            <div style={commentStyles.header}>
              {formatMessage({ id: 'appointment.popover.comment' })}
              {userRights.hasUserRight('appointment-edit') && <EditIcon onClick={() => editComment(uiState.clickMenuData)} style={commentStyles.icon} />}
            </div>
            <div style={commentStyles.body}>
              {uiState.clickMenuData.content}
            </div>
          </div>
        </span>}
      </Menu>}
      <div className={classes.header} />
      <div className={classes.wrapper} ref={doclist}>
        {scheduleMode === 'doctor' ? <DoctorList
          doctors={doctors}
          doctorShifts={doctorShifts}
          rowHeights={rowHeights}
          appointmentTypes={appointmentTypes}
          ui={uiState}
          setUiState={setUiState}
        /> : <SalesRepList
          doctors={doctors}
          salesReps={salesReps}
          rowHeights={rowHeights}
          ui={uiState}
          setUiState={setUiState}
        />}
      </div>
      <div className={classes.timebarContainer} ref={timebar}>
        <TimeBar isMobile={isMobile} firstHour={firstHour} lastHour={lastHour} {...timeBarState} />
      </div>
      <div
        ref={childRef}
        className={classes.rowBody}
        onScroll={() => handleScroll(childRef.current.scrollTop, childRef.current.scrollLeft)}
      >
        {scheduleMode === 'doctor' && appointmentTypes.map((type) => {
          let rowCount = 0
          const rows = (doctorGroup[type] || []).map((doctor, key) => {
            const row = doctor.active && (!isMobile || viewingDoctor === doctor.id) && (doctorShifts[doctor.id]?.length || uiState.noShifts) ?
              <ScheduleRow
                scheduleMode={scheduleMode}
                comments={rowComments[doctor.id] || []}
                doctor={doctor}
                appointmentType={type}
                doctorShifts={doctorShifts[doctor.id]}
                doctorLeaves={doctorLeaves[doctor.id]}
                rowHeights={rowHeights}
                appointmentPosition={appointmentPosition}
                key={key}
                appointments={rowAppointments[type] ? rowAppointments[type][doctor && doctor.id] || [] : []}
                {...timeBarState}
                {...scheduleRowProps}
              /> : null

            if (row) {
              rowCount++
            }
            return row
          })
          if (!rowCount && (!isMobile || viewingDoctor === type)) {
            rows.push(<ScheduleRow
              scheduleMode={scheduleMode}
              comments={rowComments[type] || []}
              appointmentType={type}
              rowHeights={rowHeights}
              appointmentPosition={appointmentPosition}
              key={type}
              appointments={rowAppointments[type] ? rowAppointments[type][undefined] || [] : []}
              {...timeBarState}
              {...scheduleRowProps}
            />)
          }
          return rows
        })}
        {scheduleMode === 'salesRep' && [{ id: 'noSalesRep' }, ...salesReps].map((salesRep) => {
          const row = (!isMobile || viewingSalesRep === salesRep.id) ?
            <ScheduleRow
              scheduleMode={scheduleMode}
              comments={[]}
              salesRep={salesRep}
              appointmentType={salesRep.id}
              doctorShifts={[]}
              doctorLeaves={[]}
              rowHeights={rowHeights}
              appointmentPosition={appointmentPosition}
              key={salesRep.id}
              appointments={rowAppointments[salesRep.id] || []}
              {...timeBarState}
              {...scheduleRowProps}
            /> : null
          return row
        })}
        {(!isMobile || viewingDoctor === 'cancelled' || viewingSalesRep === 'cancelled') && <ScheduleRow
          scheduleMode={scheduleMode}
          comments={rowComments.cancelled || []}
          appointmentType='cancelled'
          appointments={rowAppointments.cancelled || []}
          rowHeights={rowHeights}
          appointmentPosition={appointmentPosition}
          {...timeBarState}
          {...scheduleRowProps}
        />}
        {uiState.selectedAppointment && !isMobile &&
          <AppointmentPopover
            handleAppointmentChange={handleAppointmentChange}
            handlePopoverClose={handlePopoverClose}
            ui={uiState}
            handleDialogOpen={handleDialogOpen}
            onEditAppointment={onEditAppointment}
          />
        }
      </div>
    </div>
  );
}

ScheduleDay.propTypes = {
  appointmentData: PropTypes.object.isRequired,
  appointmentTypes: PropTypes.array.isRequired,
  doctors: PropTypes.arrayOf(PropTypes.object.isRequired),
  doctorShifts: PropTypes.object.isRequired,
  doctorLeaves: PropTypes.object.isRequired,
  customerMapping: PropTypes.object.isRequired,
  commentData: PropTypes.object.isRequired,
  scheduleMode: PropTypes.string.isRequired,
};

export default ScheduleDay;
