function decodeHistoryLog(log, replacers) {
  let newLog = log
  for (const i of replacers) {
    const matcher = new RegExp(`${i.key}{.+?}`, 'g')
    let matchs = [...new Set((newLog.match(matcher) || []).map(i => i.substring(2, i.length - 1)))]
    for (const m of matchs) {
      newLog = newLog.replace((new RegExp(`${i.key}{${m}}`, 'g')), i.replacer(m))
    }
  }
  return newLog
}

export {
  decodeHistoryLog,
};
