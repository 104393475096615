import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import ApplicationSent from 'components/ApplicationSent';
import DatePickerField from 'components/DatePickerField';
import AlertDialog from 'components/AlertDialog';
import ButtonProgress from 'components/ButtonProgress';
import ContextStore from 'modules/context';
import { callFunction } from 'modules/firebase';
import { firebaseV8 } from 'modules/firebaseV8';

const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
  '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

function EditPunchClockRevisePage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const { punchClockReviseId } = useParams()
  const navigate = useNavigate()
  const [page, setPage] = useState('edit');
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false)
  const [punchClockReviseData, setPunchClockReviseData] = useState({
    date: new Date(),
    hour: '00',
    minute: '00',
    reason: '',
    time: ''
  })

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.leaveOvertimeWork.owner' }),
    }, {
      text: punchClockReviseId !== 'new' ? formatMessage({ id: 'punchClockRevise.editPunchClockRevise' }) : formatMessage({ id: 'punchClockRevise.applyForPunchClockRevise' }),
    }])
    return () => {
    };
  }, [punchClockReviseId]);

  useEffect(() => {
    const unsubscribe = punchClockReviseId !== 'new' ? firebaseV8().collection('punchClockRevise').doc(punchClockReviseId).onSnapshot(snapshot => {
      if (punchClockReviseId) {
        const snapshots = snapshot.data()
        const times = String(snapshots.time).split(':')
        snapshots.date = dayjs(snapshots.date)
        snapshots.hour = times[0]
        snapshots.minute = times[1]
        setPunchClockReviseData(snapshots)
      }
    }, err => { }) : null
    return () => { if (unsubscribe) unsubscribe() }
  }, [punchClockReviseId]);



  function handleClose() {
    setDialogOpen(null)
  }

  async function onPunchClockReviseSave() {
    setLoadingApprove(true)

    if (punchClockReviseData.reason === '') {
      setLoadingApprove(false)
      return setDialogOpen(true)
    }

    const newData = {
      date: dayjs(punchClockReviseData.date).format('YYYY-MM-DD'),
      time: `${punchClockReviseData.hour}:${punchClockReviseData.minute}`,
      reason: punchClockReviseData.reason,
    }

    try {
      await callFunction('savePunchClockRevise', { id: punchClockReviseId, ...newData })
      setLoadingApprove(false)
    } catch (ex) {
      console.log(ex)
    }

    setPage('done')
  }

  function handleDateChange(filed, date) {
    if (date === null || date.toString() === 'Invalid Date') {
      updateData(filed, '')
    } else {
      updateData(filed, date);
    }
  };

  function validateField(field, value) {
    if ((field.name === 'startDate' || field.name === 'endDate') && value === '') {
      return '無效的日期格式'
    }
    return ''
  }

  function updateData(field, value) {
    let newValue = value

    let err = validateField(field, value)

    let newData = { ...punchClockReviseData, [field.name]: newValue, [`${field.name}_err`]: err }
    if (field.name === 'startDate') {
      newData.endDate = value
    }

    setPunchClockReviseData(newData)
  }

  return page === 'edit' ? (
    <div>
      {dialogOpen && <AlertDialog
        dialogTital={formatMessage({ id: 'punchClockRevise.punchClockReviseAlert.title' })}
        dialogMsg={formatMessage({ id: 'punchClockRevise.punchClockReviseAlert.content' })}
        handleClose={handleClose}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <DatePickerField
                  required
                  fullWidth
                  label={formatMessage({ id: 'punchClockRevise.table.date' })}
                  value={punchClockReviseData.date}
                  onChange={date => handleDateChange({ name: 'date' }, date)}
                  invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <TextField
                  select
                  type="text"
                  size="small"
                  variant="outlined"
                  label={formatMessage({ id: 'punchClockRevise.table.hour' })}
                  fullWidth
                  value={punchClockReviseData.hour}
                  onChange={e => updateData({ name: 'hour' }, e.target.value)}
                >
                  {hours.map((hour, idx) => {
                    return <MenuItem key={`${idx}`} value={hour}>{hour}</MenuItem>
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <TextField
                  select
                  type="text"
                  size="small"
                  variant="outlined"
                  label={formatMessage({ id: 'punchClockRevise.table.minute' })}
                  fullWidth
                  value={punchClockReviseData.minute}
                  onChange={e => updateData({ name: 'minute' }, e.target.value)}
                >
                  {minutes.map((minute, idx) => {
                    return <MenuItem key={`${idx}`} value={minute}>{minute}</MenuItem>
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  type="text"
                  size="small"
                  variant="outlined"
                  fullWidth
                  multiline
                  label={formatMessage({ id: 'punchClockRevise.table.reason' })}
                  value={punchClockReviseData.reason}
                  onChange={e => updateData({ name: 'reason' }, e.target.value)}
                >
                </TextField>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} />
        </Grid>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <ButtonProgress
            handleClick={() => onPunchClockReviseSave()}
            handleClose={() => navigate('/leaveOvertimeWork/punchClockRevise')}
            loading={loadingApprove}
            buttonText={'button.submit'}
          />
        </Stack>
      </Box>
    </div>
  ) : (
    <ApplicationSent
      title={'punchClockReviseSent.punchClockReviseApplicationSent'}
      message={'punchClockReviseSent.sentMessage'}
      buttonText={'punchClockReviseSent.newBack'}
      buttonClick={'/leaveOvertimeWork/punchClockRevise'}
    />
  );
}

export default EditPunchClockRevisePage;
