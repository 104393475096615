import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { arrayMove } from '@dnd-kit/sortable';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';

import WarehouseFlowSteps from 'components/WarehouseFlowSteps';
import SelectUserDialog from 'components/SelectUserDialog';
import SimpleTableToolbar from 'components/SimpleTableToolbar';
import DelectIconButton from 'components/DelectIconButton';
import DndContainer from 'components/DndContainer';
import DragHandle from 'components/DragHandle';
import { getRandomHash } from 'modules/uitls';
import { callFunction } from 'modules/firebase';

const rowStyle = {
  borderTop: 'solid 1px #efefef',
  width: '100%',
  height: '80px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#fff',
  padding: '0px 16px 0px 8px',
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
}

const cellStyle = {
  marginLeft: '8px',
  marginRight: '8px',
  flexGrow: 2
}

const StepRow = styled('div')(() => ({
  width: '100%',
  height: '80px',
  backgroundColor: '#fff',
  padding: '0px 68px 0px 40px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}));

function SortableItem({ item, index, onDeleteItem, ...props }) {
  const { formatMessage } = useIntl()

  return (
    <div style={rowStyle}>
      <DragHandle />
      <div style={cellStyle}>
        <StepRowItem
          index={index}
          rowData={item}
          {...props}
        />
      </div>
      <DelectIconButton text={formatMessage({ id: 'setAttendance.editReviewPolicy.deleteStep' })} onClick={onDeleteItem} />
    </div>
  )
}

function StepRowItem({ workflowName, categoryFilter, userRequired, rowData, index, onChange, showSelectUserPopup, showSelectCategoryPopup }) {
  const { formatMessage } = useIntl()
  const categoriesMapping = ['DPO', 'DOI', 'DIV', 'DSC', 'CIN', 'CPR', 'CBD', 'CIP', 'CCO', 'CWI', 'COT']
    .reduce((acc, cur) => {acc[cur] = formatMessage({ id: `merchandise.category.${cur}` }); return acc}, {})

  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <TextField
          required
          type="text"
          label={formatMessage({ id: `editWorkflow.${workflowName}.table.detail.name` })}
          variant="outlined"
          value={rowData.name}
          onChange={e => onChange(index, 'name', e.target.value)}
          error={rowData.name_err ? true : false}
          helperText={rowData.name_err}
          fullWidth
        />
      </Grid>
      <Grid item xs={categoryFilter ? 6 : 9}>
        <TextField
          required={userRequired}
          type="text"
          label={formatMessage({ id: `editWorkflow.${workflowName}.table.detail.approver` })}
          variant="outlined"
          value={rowData.users.map(s => s.name).join(', ')}
          onClick={() => showSelectUserPopup(index)}
          error={rowData.users_err ? true : false}
          helperText={rowData.users_err}
          fullWidth
        />
      </Grid>
      {categoryFilter && <Grid item xs={3}>
        <TextField
          type="text"
          label={formatMessage({ id: `editWorkflow.${workflowName}.table.detail.skipCategories` })}
          variant="outlined"
          value={rowData.skipCategories.map(s => categoriesMapping[s]).join(', ')}
          onClick={() => showSelectCategoryPopup(index)}
          fullWidth
        />
      </Grid>}
    </Grid>
  )
}

StepRowItem.propTypes = {
  rowData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    skipCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
    name_err: PropTypes.string,
    users_err: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  showSelectUserPopup: PropTypes.func.isRequired,
  showSelectCategoryPopup: PropTypes.func.isRequired,
  userRequired: PropTypes.bool
};

function EditWorkflowDialog({ vendor, workflowName, workflow, handleClose }) {
  // const childRef = useRef()
  const modalBody = useRef()
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)
  // const [systemFlags, setSystemFlags] = useState(false)

  const categoryFilter = ['requisitionSteps', 'purchaseOrderSteps', 'stockRequisitionSteps'].includes(workflowName)
  const userRequired = ['requisitionSteps', 'purchaseOrderSteps', 'receiptSteps', 'inventoryCheckSteps', 'scrapFormSteps'].includes(workflowName)
  const additionalStep = [
    'requisitionSteps',
    'purchaseOrderSteps',
    'inventoryCheckSteps',
    'newSupplierSteps',
    'newMerchandiseSteps',
    'newProductSteps',
    'announcementSteps'
  ].includes(workflowName)
  const noFinalStep = ['stockRequisitionSteps', 'scrapFormSteps', 'inventoryChangeSteps'].includes(workflowName)
  const noApproveSteps = ['deliveryOrderSteps'].includes(workflowName)
  let finalStepField = 'overwrite'
  if (workflowName === 'inventoryCheckSteps') {
    finalStepField = 'unlock'
  } else if (workflowName === 'deliveryOrderSteps') {
    finalStepField = 'salesReturn'
  }

  const userMapping = useSelector(state => state.users.data)
  const users = useSelector(state => state.users.ordered).filter(u => u.active)
  // const [customerMapping, customers] = useFirestoreDataAndMapping('customers')
  const [userDialogData, setUserDialogData] = useState(null);
  // const [categoryDialogData, setCategoryDialogData] = useState(null);

  const [approveSteps, setApproveSteps] = useState(workflow.slice(1, workflow.length).map(s => ({
    id: getRandomHash(),
    name: s.name,
    users: s.users.map(u => ({ id: u, name: userMapping[u] ? userMapping[u].displayName : '' })),
    skipCategories: s.skipCategories || []
  })));

  const [firstStep, setFirstStep] = useState({
    name: formatMessage({ id: `editWorkflow.${workflowName}.firstStep` }), //workflow[0].name,
    users: workflow[0].users.map(u => ({ id: u, name: userMapping[u] ? userMapping[u].displayName : '' })),
    skipCategories: [],
  })

  const fs = workflow[0].overwrite || workflow[0].salesReturn || workflow[0].unlock
  const [finalStep, setFinalStep] = useState(fs ? {
    name: formatMessage({ id: `editWorkflow.${workflowName}.finalStep` }),
    users: fs.map(u => ({ id: u, name: userMapping[u] ? userMapping[u].displayName : '' })),
    skipCategories: []
  } : {});

  const allSteps = workflowName === 'inventoryCheckSteps' ?
    [firstStep, { ...firstStep, name: '送出盤點結果' }, ...approveSteps, finalStep] :
    (noFinalStep ? [firstStep, ...approveSteps] : [firstStep, ...approveSteps, finalStep])

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = approveSteps.findIndex(e => e.id === active.id);
      const newIndex = approveSteps.findIndex(e => e.id === over.id);
      setApproveSteps(arrayMove(approveSteps, oldIndex, newIndex))
    }
  }

  function showSelectUserPopup(index) {
    let users = null
    if (index === -1) users = firstStep.users
    else if (index === -2) users = finalStep.users
    else users = approveSteps[index].users
    setUserDialogData({ index, users: users.map(i => i.id) })
  }

  function showSelectCategoryPopup(index) {
    // TODO: 等商品類別改成動態後再來處理這段
    // setCategoryDialogData({ index, categories: approveSteps[index].skipCategories })
  }

  function addStep() {
    const newData = [...approveSteps, { id: getRandomHash(), name: '簽核', users: [], skipCategories: [] }]
    setApproveSteps(newData)
  }

  function onDeleteItem(index) {
    const newData = [...approveSteps]
    newData.splice(index, 1)
    setApproveSteps(newData)
  }

  function onStepUsersChanged(selectedItems, params) {
    const index = parseInt(params)
    const users = selectedItems

    if (index === -1) {
      const newData = { ...firstStep, users: users }
      if (!users.length && userRequired) {
        newData.users_err = formatMessage({ id: 'form.field.isRequired' })
      } else {
        delete newData.users_err
      }
      setFirstStep(newData)
    } else if (index === -2) {
      const newData = { ...finalStep, users: users }
      if (!users.length && userRequired) {
        newData.users_err = formatMessage({ id: 'form.field.isRequired' })
      } else {
        delete newData.users_err
      }
      setFinalStep(newData)
    } else {
      updateApproveStep(index, 'users', users)
    }
  }

  function updateApproveStep(index, field, value) {
    // validate field
    const step = { id: approveSteps[index].id, name: approveSteps[index].name, users: approveSteps[index].users, skipCategories: approveSteps[index].skipCategories }
    if (field === 'name' && value.trim() === '') {
      step.name_err = formatMessage({ id: 'form.field.isRequired' })
    }
    else if (field === 'users' && !value.length && userRequired) {
      step.users_err = formatMessage({ id: 'form.field.isRequired' })
    }

    step[field] = value
    const newData = [...approveSteps]
    newData[index] = step

    setApproveSteps(newData)
  }

  const handleSave = async () => {
    setLoading(true);

    let err = false
    for (const step of approveSteps) {
      if (step.name.trim() === '') {
        step.name_err = formatMessage({ id: 'form.field.isRequired' })
        err = true
      }
      if (step.users.length === 0) {
        step.users_err = formatMessage({ id: 'form.field.isRequired' })
        err = true
      }
    }
    if (err) {
      setApproveSteps([...approveSteps])
      setLoading(false);
      return
    }

    let newSteps = noFinalStep ?
      [{ ...firstStep }, ...(approveSteps.map(i => ({ ...i })))] :
      [{ ...firstStep }, ...(approveSteps.map(i => ({ ...i }))), { ...finalStep }]
    let fields = ['name', 'users', 'skipCategories']
    for (const step of newSteps) {
      for (const field of fields) {
        if (step[`${field}_err`] !== undefined && step[`${field}_err`] !== '') {
          setLoading(false);
          return
        }
      }
    }

    for (const step of newSteps) {
      step.users = step.users.map(s => s.id)
    }

    for (const step of newSteps) {
      if (categoryFilter) {
        step.skipCategories = step.skipCategories || []
      } else {
        delete step.skipCategories
      }
    }

    if (!noFinalStep) {
      const tmp = newSteps.pop()
      newSteps[0][finalStepField] = tmp.users
    }

    // console.log({ id: vendor.id, [workflowName]: newSteps })
    // setLoading(false);
    try {
      await callFunction('updateWorkflow', { id: vendor.id, [workflowName]: newSteps })
    } catch (ex) {
      console.log(ex)
    }

    handleClose()
  }

  function getToolbox() {
    return (
      <div>
        <Button
          sx={{ m: 1, whiteSpace: 'nowrap' }}
          variant="contained"
          color="primary"
          onClick={() => addStep()}
        >
          <FormattedMessage id="editWorkflow.addStep" />
        </Button>
      </div>
    )
  }

  return (
    <Dialog
      ref={modalBody}
      fullWidth={true}
      maxWidth={'lg'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id={`vendor.dialog.title.${vendor.id === 'new' ? 'add' : 'edit'}`} />
      </DialogTitle>
      <DialogContent dividers={true}>
        {userDialogData && <SelectUserDialog
          multiSelect
          headerCells={[{ name: 'displayName', sort: 'displayName' },{ name: 'email' }]}
          rowCells={[{ field: 'displayName' },{ field: 'email' }]}
          filterItems={[{ name: 'displayName' },{ name: 'email' }]}
          dialogTitle={formatMessage({ id: 'selectUserDialog.title' })}
          tableTitle={formatMessage({ id: 'selectUserDialog.table.title' })}
          handleClose={() => setUserDialogData(null)}
          handleSave={onStepUsersChanged}
          defaultSelectedItems={userDialogData.users ?? []}
          items={users}
          params={String(userDialogData.index)}
        />}
        <Box>
          <SimpleTableToolbar
            title={`editWorkflow.${workflowName}.table.title`}
            toolbox={additionalStep ? getToolbox() : null}
          />
          <Paper>
            <StepRow>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    type="text"
                    label={formatMessage({ id: `editWorkflow.${workflowName}.table.detail.name` })}
                    variant="outlined"
                    value={firstStep.name}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    required={userRequired}
                    type="text"
                    label={formatMessage({ id: `editWorkflow.${workflowName}.table.detail.creator` })}
                    variant="outlined"
                    fullWidth
                    // value=""
                    value={firstStep.users.map(s => s.name).join(', ')}
                    onClick={() => showSelectUserPopup(-1)}
                    error={firstStep.users_err ? true : false}
                    helperText={firstStep.users_err}
                  />
                </Grid>
              </Grid>
            </StepRow>
            {additionalStep ?<DndContainer
              dargger
              items={approveSteps}
              handleDragEnd={handleDragEnd}
              ChildItem={SortableItem}
              onDeleteItem={onDeleteItem}
              showSelectUserPopup={showSelectUserPopup}
              updateApproveStep={updateApproveStep}
              workflowName={workflowName}
              categoryFilter={categoryFilter}
              userRequired={userRequired}
              onChange={updateApproveStep}
              showSelectCategoryPopup={showSelectCategoryPopup}
            /> : <div>
              {approveSteps.map((step, index) => (
                <StepRow key={`approve-step-item-${index}`}>
                  <StepRowItem
                    workflowName={workflowName}
                    categoryFilter={categoryFilter}
                    userRequired={userRequired}
                    index={index}
                    rowData={step}
                    onChange={updateApproveStep}
                    showSelectUserPopup={showSelectUserPopup}
                    showSelectCategoryPopup={showSelectCategoryPopup}
                  />
                </StepRow>
              ))}
            </div>}
            <Divider />
            {!noFinalStep && <StepRow>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    type="text"
                    label={formatMessage({ id: `editWorkflow.${workflowName}.table.detail.name` })}
                    variant="outlined"
                    value={finalStep.name}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    required={userRequired}
                    type="text"
                    label="指定使用者"
                    variant="outlined"
                    fullWidth
                    // value=""
                    value={finalStep.users.map(s => s.name).join(', ')}
                    onClick={() => showSelectUserPopup(-2)}
                    error={finalStep.users_err ? true : false}
                    helperText={finalStep.users_err}
                  />
                </Grid>
              </Grid>
            </StepRow>}
          </Paper>
          <Divider style={{ margin: '8px 0px' }} />
          <Paper sx={{ overflow: 'hidden', pt: '16px' }}>
            <WarehouseFlowSteps steps={allSteps} />
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          onClick={handleSave}
          disabled={loading || (approveSteps.length === 0 && !noApproveSteps)}
          loading={loading}
          loadingPosition="start"
          loadingIndicator={<CircularProgress size={24} />}
          startIcon={<div />}
          variant="contained"
        >
          <FormattedMessage id="button.save" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

EditWorkflowDialog.propTypes = {
  vendor: PropTypes.object,
  workflowName: PropTypes.string.isRequired,
  workflow: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EditWorkflowDialog;
