import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import EnhancedTable from 'components/EnhancedTable';
import LoadingIndicator from 'components/LoadingIndicator';
import ConsumedServiceView from 'pages/Services/ConsumedServiceView';
import { firestoreListener } from 'modules/firebase';

function ConsumedServiceFormInfoDiablog({ consumedServiceFormId, userMapping, customerMapping, sourceMapping, onClose }) {
  const { formatMessage } = useIntl()
  const [consumedServiceForm, setConsumedServiceForm] = useState(null)

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'consumedServiceForms',
      doc: consumedServiceFormId,
      onData: (data) => {
        setConsumedServiceForm(data)
      }
    })

    return () => unsubscribe()
  }, []);

  const _headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'date' },
    { text: 'source' },
    { text: 'createdBy' },
    { text: 'customer' },
    { text: 'agent' },
    { text: 'status' }
  ]

  const _rowCells = [
    { field: 'sn', textColor: true },
    { field: 'date', textColor: true },
    { field: 'sourceName', textColor: true  },
    { field: 'createdBy', textColor: true },
    { field: 'customerName', textColor: true },
    { field: 'agentName', textColor: true },
    { field: 'status', textColor: true }
  ]

  const headerCells = _headerCells
    .map(c => { c.text = formatMessage({ id: `consumedService.table.header.${c.text}` }); return c })
    .sort((a, b) => a.order - b.order)
  const rowCells = _rowCells.sort((a, b) => a.order - b.order)

  function formatData(consumedServiceForm) {
    const newData = { ...consumedServiceForm }
    newData.sourceName = sourceMapping[newData.source]?.name || ''
    newData.customerName = customerMapping[newData.customer]?.name || ''
    newData.agentName = userMapping[newData.agent]?.displayName || ''
    newData.createdBy = userMapping[newData.createdBy]?.displayName || ''

    if (newData.void) {
      newData.status = formatMessage({ id: 'consumedService.table.header.void' })
      newData.textColor = '#bab7b7'
    } else {
      newData.status = formatMessage({ id: 'consumedService.table.header.done' })
    }
    return newData
  }

  return <Dialog maxWidth="md" onClose={onClose} open>
    <DialogTitle id="form-dialog-title">{formatMessage({ id: 'consumedService.name' })}</DialogTitle>
    <DialogContent>
      {!!consumedServiceForm ?
        <>
          <EnhancedTable
            headerCells={headerCells}
            rowCells={rowCells}
            tableData={[consumedServiceForm].map(i => formatData(i))}
          />
          <ConsumedServiceView
            userRights={{}}
            form={consumedServiceForm}
          />
        </> : <LoadingIndicator />}

    </DialogContent>
  </Dialog>
}


ConsumedServiceFormInfoDiablog.propTypes = {
  consumedServiceFormId: PropTypes.string.isRequired,
  userMapping: PropTypes.object.isRequired,
  customerMapping: PropTypes.object.isRequired,
  sourceMapping: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConsumedServiceFormInfoDiablog;
