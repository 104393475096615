import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';

import TabContainer from 'containers/TabContainer/TabContainer';
import FabAdd from 'components/FabAdd';
import ContextStore from 'modules/context';
import PurchaseOrderTab from './PurchaseOrderTab';

function PurchaseOrder() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const navigate = useNavigate()
  const location = useLocation()
  const { tabName } = useParams()

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.purchase.purchaseOrder' })
    }])
    return () => {
    };
  }, [location.pathname]);

  const onTabSelected = (tabIndex) => {
    if (tabIndex === 0) {
      navigate('/purchase/purchaseOrder/pending');
    } else if (tabIndex === 1) {
      navigate('/purchase/purchaseOrder/done');
    } else {
      navigate('/purchase/purchaseOrder/void');
    }
  };

  const tabs = [
    { label: formatMessage({ id: 'purchaseOrder.tab.pending' }), component: <PurchaseOrderTab /> },
    { label: formatMessage({ id: 'purchaseOrder.tab.done' }), component: <PurchaseOrderTab /> },
    { label: formatMessage({ id: 'purchaseOrder.tab.void' }), component: <PurchaseOrderTab /> },
  ]
  const defaultSelect = tabName === 'void' ? 2 : (tabName === 'done' ? 1 : 0)

  // NEED FIX:
  // 新增採購單是出貨給外部廠商時才會用到, 因此必需是有出貨權限的人才能建立
  // 但是這樣有可能發生有出貨權限的人沒有任何採購單權限, 因此看不到自己建立的採購單的問題.
  // 以後可能要想辦法修正這個部份
  return (
    <Box p={3}>
      {userRights.hasUserRight('deliveryOrder-create') && <FabAdd to="/purchase/purchaseOrder/edit/new" />}
      <TabContainer defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} />
    </Box>
  );
}

export default PurchaseOrder;
