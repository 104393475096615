import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'

import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

import placeholderImage from 'static/image-placeholder.png';
import { uploadFile } from 'modules/firebase';

// const primaryColor = process.env.BRANCH_ENV === 'reborn' || process.env.BRANCH_ENV === 'lexcellence' ?
//   '#eeccc4' :
//   process.env.BRANCH_ENV === 'santea' ?
//     '#26CAD3' :
//     '#6EDBD5'

const Text = styled('div')(({ theme }) => ({
  fontSize: '15px',
  fontWeight: 500,
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center',
  margin: '10px 0 0 10px',
  cursor: 'pointer',
  color: '#1976d2'
}));

export default function ImageUpload({ storagePath, currentImageUrl, onChange, type }) {
  const [newImageUrl, setNewImageUrl] = useState('')
  const currentImageType = !type ? {
    width: '160px',
    height: '160px',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    backgroundColor: '#F2F2F2',
    borderRadius: '10px'
  } : {
    backgroundColor: '#fff',
  }

  function deleteFile(e) {
    e.stopPropagation()
    setNewImageUrl('')
    if (onChange) {
      onChange(null)
    }
  }

  // Uploads files and push's objects containing metadata to database at dbPath
  function onFilesDrop(files) {
    return uploadFile(storagePath, files[0]).then(url => {
      if (type) {
        setNewImageUrl({ filesname: files[0], url: url })
      } else {
        setNewImageUrl(url)
      }

      if (onChange) {
        if (type) {
          onChange({ filesname: files[0], url: url })
        } else {
          onChange(url)
        }
      }
    })
  }

  let imageUrl
  if (newImageUrl === false) {
    imageUrl = false
  } else if (newImageUrl) {
    if (type) {
      imageUrl = newImageUrl.url
    } else {
      imageUrl = newImageUrl
    }
  } else if (currentImageUrl) {
    imageUrl = currentImageUrl
  }

  return (
    <div>
      <Dropzone onDrop={onFilesDrop}>
        {({ getRootProps, getInputProps }) => (
          <div>
            <input {...getInputProps()} />
            <div
              {...getRootProps({
                style: { width: '160px' },
                onDrop: (event) => { onFilesDrop(event.dataTransfer.files); event.preventDefault(); event.stopPropagation() }
              })}
              style={{ ...currentImageType, backgroundImage: `url(${imageUrl || placeholderImage})` }}
            >
              {imageUrl
                ? <IconButton sx={{ color: 'red' }} onClick={deleteFile} size="large">
                  <DeleteIcon />
                </IconButton>
                : <span style={{ color: '#1976d2' }}>
                  <Text >
                    <CloudUploadIcon sx={{ color: '#1976d2', width: 18, height: 18, margin: '2px 10px 0 0' }} />
                    {!type ? 'Upload Picture' : 'Upload PDF'}
                  </Text>
                </span>
              }
            </div>
            { }
          </div>
        )
        }
      </Dropzone >
    </div >
  );
}

ImageUpload.propTypes = {
  storagePath: PropTypes.string,
  databasePath: PropTypes.string,
  currentImageUrl: PropTypes.string,
  onChange: PropTypes.func,
  horizontal: PropTypes.bool,
  type: PropTypes.string
};
