import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import EnhancedButton from 'components/EnhancedButton';
import EnhancedTable from 'components/EnhancedTable';
import ButtonContainer from 'components/ButtonContainer';
import { firestoreListener } from 'modules/firebase';
import EditReferrerPage from './EditReferrerPage';

function SalesOrderView({ userRights, modules = [], salesOrder, onEditClick, onVoidClick, currentCompany, forReport, highlightItems = [] }) {
  const { formatMessage } = useIntl()
  const [serviceProviderInfo, setServiceProviderInfo] = useState('')
  const [editReferrerDialog, setEditReferrerDialog] = useState(false)
  const [ppData, setPPData] = useState([])
  const [storedValueCard, setStoredValueCard] = useState({})
  const productMapping = useSelector(state => state.products.data)
  const userMapping = useSelector(state => state.users.data)
  const sourceMapping = useSelector(state => state.internalVendors.data)
  const config = useSelector(state => state.config.data)
  const paymentTypes = Object.keys(config.paymentType || {}).map(i => config.paymentType[i])
  const paymentTypeMapping = paymentTypes.reduce((acc, cur) => { acc[cur.id] = cur; return acc }, {})

  const products = Object.keys(salesOrder.products).map(k => ({ ...salesOrder.products[k], id: k })).sort((a, b) => a.order - b.order)
  const payments = Object.keys(salesOrder.payments).map(k => ({ ...salesOrder.payments[k], id: k })).sort((a, b) => a.order - b.order)

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'purchasedProducts',
      where: [['salesOrder', '==', salesOrder.id]],
      onData: (data) => {
        setPPData(data)
      }
    })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firestoreListener({
      collection: 'storedValueCard',
      doc: salesOrder.customer,
      unwrap: true,
      addDocId: false,
      onData: (data) => {
        setStoredValueCard(data || {})
      }
    })
    return () => unsubscribe()
  }, []);

  const productHeaderCells = [
    { text: 'name' },
    { text: 'serviceProvider' },
    { text: 'priceType' },
    { text: 'unitPrice' },
    { text: 'amount' },
    { text: 'totalPrice' },
    { text: 'note' },
  ].map(c => { c.text = formatMessage({ id: `salesOrder.product.${c.text}` }); return c })

  const productRowCells = [
    { field: 'name' },
    { field: 'serviceProviderShort', type: 'info', onButtonClick: showServiceProvider },
    { field: 'priceType' },
    { field: 'unitPrice' },
    { field: 'amount' },
    { field: 'totalPrice' },
    { field: 'note' },
  ]

  const paymentHeaderCells = [
    { text: 'date' },
    { text: 'agent' },
    { text: 'paymentType' },
    { text: 'collected' },
    { text: 'note' },
  ].map(c => { c.text = formatMessage({ id: `salesOrder.payment.${c.text}` }); return c })

  const paymentRowCells = [
    { field: 'date' },
    { field: 'agent' },
    { field: 'paymentType' },
    { field: 'collected' },
    { field: 'note' },
  ]

  function showServiceProvider(key, field, data) {
    setServiceProviderInfo(data.serviceProviderFull)
  }

  function formatProductData(product) {
    const newData = { ...product }
    newData.name = productMapping[product.productId].name
    const serviceProviderFull = product.serviceProvider.map(i => sourceMapping[i].nickname)
    let serviceProviderShort = serviceProviderFull.join(',')
    if (serviceProviderShort.length > 10) {
      serviceProviderShort = serviceProviderShort.substring(0, 10).replace(/,{1}$/, '') + '...'
    }
    newData.serviceProviderFull = serviceProviderFull.join(', ')
    newData.serviceProviderShort = serviceProviderShort
    newData.totalPrice = newData.unitPrice * newData.amount
    if (highlightItems.includes(newData.id)) {
      newData.bgColor = '#FFFF99'
    }
    return newData
  }

  function formatPaymentData(payment) {
    const newData = { ...payment }
    newData.agent = userMapping[newData.agent]?.displayName || ''

    if (newData.paymentType === 'storedValue') {
      newData.paymentType = `儲值(${productMapping[newData.storedValueCard].name})`
    } else {
      newData.paymentType = paymentTypeMapping[newData.paymentType]?.name || ''
    }
    if (newData.void) {
      newData.collected = `${newData.collected}(已作廢)`
    }

    // newData.name = productMapping[product.productId].name
    // newData.totalPrice = newData.unitPrice * newData.amount
    return newData
  }

  function isConsume(salesOrder) {
    for (const pp of ppData) {
      if (Object.keys(pp.consumedServiceForm || {}).length > 0 ||
        Object.keys(pp.transferService || {}).length > 0 ||
        Object.keys(pp.transformService || {}).length > 0) {
        return true
      }
    }

    if (salesOrder.payments) {
      for (const p of Object.keys(salesOrder.products)) {
        const product = productMapping[salesOrder.products[p].productId]
        if (!product) continue;
        let totalPrice = 0
        if (product.type === 'storedValue') {
          totalPrice += (salesOrder.products[p].amount * salesOrder.products[p].unitPrice)
          // let totalPrice = Object.keys(salesOrder.payments).reduce((acc, cur) => {
          //   const payment = salesOrder.payments[cur]
          //   if (!payment) { return false }
          //   if (!payment.void) {
          //     acc += payment.collected
          //   }
          //   return acc
          // }, 0)

          // console.log(totalPrice)
        }

        if ((storedValueCard[product.id] ?? 0) < totalPrice) {
          return true
        }
      }
    }

    return false
  }

  return (
    <div style={{ width: '100%', }}>
      {serviceProviderInfo && <Dialog maxWidth="md" onClose={() => setServiceProviderInfo('')} open>
        <Box p={3}>
          <div style={{ whiteSpace: 'pre' }}>
            {serviceProviderInfo}
          </div>
        </Box>
      </Dialog>}
      {editReferrerDialog && <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={true}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{formatMessage({ id: 'referrer.add' })}</DialogTitle>
        <DialogContent dividers={true}>
          <EditReferrerPage
            salesOrder={salesOrder}
            defaultReferrerId={salesOrder.referrer ?? 'new'}
            onClose={() => setEditReferrerDialog(false)}
          />
        </DialogContent>
      </Dialog>}
      <Box p={0}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 500 }} component="div">
                產品列表:
              </Typography>
            </div>

            <EnhancedTable
              size="small"
              headerCells={productHeaderCells}
              rowCells={productRowCells}
              tableData={products.map(i => formatProductData(i))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 500 }} component="div">
                付款列表:
              </Typography>
            </div>

            <EnhancedTable
              size="small"
              headerCells={paymentHeaderCells}
              rowCells={paymentRowCells}
              tableData={payments.map(i => formatPaymentData(i))}
              extRows={
                <>
                  <TableRow>
                    <TableCell rowSpan={2} colSpan={2} />
                    <TableCell align="right">
                      <Typography variant="subtitle2">
                        <FormattedMessage id="editSalesOrderPage.collected" />
                      </Typography>
                    </TableCell>
                    <TableCell align="right">{salesOrder.collected}</TableCell>
                    <TableCell colSpan={2} />
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">
                      <Typography variant="subtitle2">
                        <FormattedMessage id="editSalesOrderPage.uncollected" />
                      </Typography>
                    </TableCell>
                    <TableCell align="right">{salesOrder.uncollected}</TableCell>
                    <TableCell colSpan={2} />
                  </TableRow>
                </>
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'salesOrder.table.detail.note' })}
              value={salesOrder.note}
              multiline
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          {!salesOrder.void && !forReport && <Grid item xs={12} sm={12} md={12}>
            <ButtonContainer>
              {onVoidClick && userRights.hasUserRightForVendor('salesOrder-void', currentCompany) && <EnhancedButton
                disabled={isConsume(salesOrder)}
                onClick={() => onVoidClick(salesOrder)}
                label={formatMessage({ id: 'button.void' })}
                color='error'
              />}
              {modules.includes('referrer') && userRights.hasUserRightForVendor('referrer-create', currentCompany) && <EnhancedButton
                onClick={() => setEditReferrerDialog(true)}
                label={formatMessage({ id: salesOrder.referrer ? 'referrer.edit' : 'referrer.add' })}
              />}
              {onEditClick && userRights.hasUserRightForVendor('salesOrder-edit', currentCompany) && <EnhancedButton
                onClick={() => onEditClick(salesOrder)}
                label={formatMessage({ id: 'button.edit' })}
              />}
            </ButtonContainer>
          </Grid>}
        </Grid>
      </Box>
    </div>
  )
}

SalesOrderView.propTypes = {
  userRights: PropTypes.object.isRequired,
  salesOrder: PropTypes.object.isRequired,
  onEditClick: PropTypes.func,
  onVoidClick: PropTypes.func,
  forReport: PropTypes.bool,
  highlightItems: PropTypes.arrayOf(PropTypes.string),
};

export default SalesOrderView